import { CSSProperties } from 'react';
import styled from 'styled-components';

interface IProps {
  value: string | number;
  id: string;
  name: string;
  isDefaultChecked?: boolean;
  style?: CSSProperties;
  handleClick: (value: string | number) => any;
}

const RadioButton = ({ value, id, name, isDefaultChecked, style, handleClick }: IProps) => {
  return (
    <StyledRadioContainer style={style}>
      <input type="radio" id={id} name={name} value={value} onClick={() => handleClick(value)} checked={isDefaultChecked} readOnly />
      <StyledLabel htmlFor={id}>{value}</StyledLabel>
    </StyledRadioContainer>
  );
};

const StyledRadioContainer = styled.div`
  display: flex;
  align-items: center;

  max-width: 345px;
  margin-bottom: 18px;

  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #404040;
  font-size: 14px;

  input {
    height: 20px;
    width: 20px;

    cursor: pointer;
  }

  input[type='radio']:checked + label {
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary};
  }
`;

const StyledLabel = styled.label`
  margin-left: 15px;
  text-align: left;
  line-height: 1.2;
  cursor: pointer;
`;

export default RadioButton;
