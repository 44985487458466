import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import GenericDropdown from 'components/GenericDropdown';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import { StyledButton } from 'components/styled-button/StyledButton';
import TextInputCadastralSheet from 'components/TextInputCadastralSheet';
import { IRegistrationForm } from 'model/registration-form';
import { FormProvider, useForm } from 'react-hook-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import registrationFormService from 'services/registration-form-service';
import { yearList } from 'shared/util/date-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import {
  StyledRegistrationFormBackground,
  StyledRegistrationFormMainTitleContainer,
  StyledRegistrationFormSubtitle,
  StyledRegistrationFormTitle,
} from 'styled-components/StyledRegistrationForm';
import * as yup from 'yup';
import mockPropertiesForm from '../../api/mocks/mockPropertiesForm.json';
import { ReactComponent as HouseIconSvg } from '../../images/frisia/icons/blue-house-icon.svg';

interface IForm {
  partnerProducer: string;
}

const Properties = ({ t }: WithTranslation) => {
  const schema = yup.object({
    partnerProducer: yup.string().required(),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const handleUpdateRegistrationForm = (data: IForm) => {
    registrationFormService.updateRegistrationData({ data } as unknown as IRegistrationForm);
  };

  const tableHeaderProperties = [t('table.improvements'), t('table.comments'), t('table.buildingArea'), t('table.value')];

  return (
    <StyledRegistrationFormBackground>
      <StyledRegistrationFormMainTitleContainer>
        <HouseIconSvg />
        <StyledRegistrationFormTitle>{t('registrationForm.properties')}</StyledRegistrationFormTitle>
      </StyledRegistrationFormMainTitleContainer>

      <StyledHeaderContainer>
        <StyledTitleContainer>
          <StyledRegistrationFormSubtitle style={{ marginTop: 0 }}>{mockPropertiesForm.nomeCooperado}</StyledRegistrationFormSubtitle>
          <StyledText>{mockPropertiesForm.areas.map(item => item.area).join(', ')}</StyledText>
        </StyledTitleContainer>
        <StyledFilterContainer filterWidth={330}>
          <GenericDropdown
            label={t('global.field.property')}
            placeholder={t('global.field.filterSelectProperty')}
            listShowDropdown={yearList()}
            getSelectedValue={value => {
              /* empty */
            }}
          />
        </StyledFilterContainer>
      </StyledHeaderContainer>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleUpdateRegistrationForm)}>
          <Grid container columnSpacing={1.5}>
            <Grid item xs={12} lg={4}>
              <TextInputCadastralSheet
                isReadOnly
                inputName="address"
                label={t('registrationForm.address')}
                defaultValue={mockPropertiesForm.endereco}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextInputCadastralSheet
                isReadOnly
                inputName="enrollment"
                label={t('registrationForm.enrollment')}
                defaultValue={mockPropertiesForm.matricula}
              />
            </Grid>
            <Grid item xs={6} lg={2}>
              <TextInputCadastralSheet
                isReadOnly
                inputName="federalRevenuePropertyNumber"
                label={t('registrationForm.federalRevenuePropertyNumber')}
                defaultValue={mockPropertiesForm.nirf}
              />
            </Grid>
            <Grid item xs={6} lg={2}>
              <TextInputCadastralSheet
                isReadOnly
                inputName="proofEnrollmentRegister"
                label={t('registrationForm.proofEnrollmentRegister')}
                defaultValue={mockPropertiesForm.cicad}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextInputCadastralSheet
                isReadOnly
                inputName="nationalInstituteColonizationAgrarianReformRegistration"
                label={t('registrationForm.nationalInstituteColonizationAgrarianReformRegistration')}
                defaultValue={mockPropertiesForm.registroIncra}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInputCadastralSheet
                inputName="partnerProducer"
                label={t('registrationForm.partnerProducer')}
                defaultValue={mockPropertiesForm.produtorParceiro}
                errorText={methods.formState.errors.partnerProducer?.message}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <TextInputCadastralSheet
                isReadOnly
                inputName="encumbranceDegree"
                label={t('registrationForm.encumbranceDegree')}
                defaultValue={mockPropertiesForm.onus}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <TextInputCadastralSheet
                isReadOnly
                inputName="owners"
                label={t('registrationForm.owners')}
                defaultValue={mockPropertiesForm.proprietarios}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextInputCadastralSheet
                isReadOnly
                inputName="individualRegistration"
                label={t('registrationForm.individualRegistration')}
                defaultValue={mockPropertiesForm.cpf}
              />
            </Grid>
          </Grid>

          <GenericTableBlueHeader
            headerItems={tableHeaderProperties}
            tableItems={mockPropertiesForm.detalhesPropriedade}
            dataColor="#787878"
            isInvertLineColor
            isDontHaveMaximumWidth
            style={{ marginTop: '45px' }}
          />

          <StyledButton color="frisiaPrimary" type="submit" style={{ minWidth: '215px', marginTop: '35px' }}>
            <span>{t('global.button.saveData')}</span>
          </StyledButton>
        </form>
      </FormProvider>
    </StyledRegistrationFormBackground>
  );
};

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledText = styled.span`
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: #787878;
  font-size: 14px;
`;

const StyledTitleContainer = styled.div`
  text-align: left;
`;

export default withTranslation()(Properties);
