import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledButton } from 'components/styled-button/StyledButton';
import moment from 'moment';
import { Documento } from 'model/financial-abstract';
import StringUtils from 'shared/util/string-utils';
import { parse } from 'date-fns';
import IconBasedOnString from './IconBasedOnString';
import { useEffect, useState } from 'react';
import FinanciaAbstractApi from 'api/financial-abstract-api';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';

interface IProps extends WithTranslation {
  documents?: Documento[];
  title: string;
  onBack: () => void;
  valueBefore: number;
  startDate: string;
  finalDate: string;
}

const PastAbstractTable = ({ documents, title, startDate, finalDate, valueBefore, t }: IProps) => {
  const filterDatesPayable = [...new Set(documents?.map(item => item.dataDocumento))];

  const [reportPDFUrl, setReportPDFUrl] = useState<string>();
  const [reportXLSUrl, setReportXLSUrl] = useState<string>();

  useEffect(() => {
    setReportPDFUrl(FinanciaAbstractApi.getPastReport('&dld_fmt=pdf', getUserRegistration, startDate, finalDate));
    setReportXLSUrl(FinanciaAbstractApi.getPastReport('&dld_fmt=xls', getUserRegistration, startDate, finalDate));
  }, [title]);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  let accumulator = valueBefore;

  return (
    <div style={{ width: '100%' }}>
      <div style={{ textAlign: 'right' }}>
        <StyledAButton href={reportPDFUrl} target="_blank">
          {'PDF'}
        </StyledAButton>
        <StyledAButton href={reportXLSUrl} target="_blank">
          {'Excel'}
        </StyledAButton>
      </div>
      <TableContainer>
        <StyledTitleThead>
          <TitleTrContainer>
            <th>
              {t('financial.balance')}
              {title}
            </th>
            <th>{'Documento'}</th>
            <th>Negócio</th>
            <th>Lote</th>
            <th>Valor</th>
            <th>Saldo</th>
          </TitleTrContainer>
        </StyledTitleThead>
        <StyledTitleThead2>
          <BeforeTrContainer>
            <th>Saldo Anterior</th>
            <th> </th>
            <th> </th>
            <th> </th>
            <th> </th>
            <th className={valueBefore < 0 ? 'red' : undefined}>{valueBefore === 0 ? '0,00' : StringUtils.maskAmount(valueBefore)}</th>
          </BeforeTrContainer>
        </StyledTitleThead2>
        <>
          {filterDatesPayable
            ?.sort((a, b) => {
              return parse(a, 'yyyy-MM-dd', new Date()).getTime() - parse(b, 'yyyy-MM-dd', new Date()).getTime();
            })
            ?.map((itemData, indexData) => {
              return (
                <>
                  <DateThead key={`tableContainer-${indexData}`}>
                    <tr>
                      <th>{moment(itemData, 'YYYY-MM-DD').format('DD [de] MMMM [de] YYYY')}</th>
                      <th> </th>
                      <th> </th>
                      <th> </th>
                      <th> </th>
                      <th> </th>
                    </tr>
                  </DateThead>
                  <TbodyContainer>
                    {documents?.map((item, index) => {
                      if (itemData === item.dataDocumento) {
                        accumulator = accumulator + item.valorDocumento;
                        return (
                          <>
                            <tr key={`tr-${indexData}-${index}`}>
                              <td>
                                <IconBasedOnString name={item.nomeConta} />
                                <div className="space-bt">
                                  <h3>
                                    <b>{item.nomeConta}</b>
                                  </h3>
                                  <p>{item.historico}</p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <h3>
                                    <b>{item.numeroDocumento}</b>
                                  </h3>
                                </div>
                              </td>
                              <td>
                                <h3>
                                  <b>{item.negocio}</b>
                                </h3>
                              </td>
                              <td>
                                <h3>
                                  <b>{item?.lote ?? ' '}</b>
                                </h3>
                              </td>
                              <td>
                                <h3>
                                  <b className={item.valorDocumento < 0 ? 'red' : undefined}>
                                    {'R$ '}
                                    {item.valorDocumento === 0 ? '0,00' : StringUtils.maskAmount(item.valorDocumento)}
                                  </b>
                                </h3>
                              </td>
                              <td>
                                <h3>
                                  <b className={accumulator < 0 ? 'red' : undefined}>
                                    {'R$ '}
                                    {accumulator === 0 ? '0,00' : StringUtils.maskAmount(accumulator)}
                                  </b>
                                </h3>
                              </td>
                            </tr>
                          </>
                        );
                      }
                    })}
                  </TbodyContainer>
                </>
              );
            })}
        </>
      </TableContainer>
    </div>
  );
};

const TableContainer = styled.table`
  max-height: 700px;
  overflow-y: auto;
  min-height: 100px;
  min-width: 400px;
  width: 100%;
  position: static;
  container-type: size;
  .red {
    color: #d96767;
  }
`;

export const StyledTitleThead = styled.thead`
  width: 160px;
  height: 45px;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #29579c;
  text-transform: capitalize;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  margin-bottom: 10px;
  min-width: 700px;
  background-color: #f1f1f1;
  min-width: 100%;
  align-items: center;
  position: sticky;
  top: 52px;
`;

export const StyledTitleThead2 = styled.thead`
  width: 160px;
  height: 45px;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #29579c;
  text-transform: capitalize;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  margin-bottom: 10px;
  min-width: 700px;
  background-color: #f1f1f1;
  min-width: 100%;
  align-items: center;
`;

const TitleTrContainer = styled.tr`
  width: 100%;
  height: 49px;
  background: #1268a930 0% 0% no-repeat padding-box;
  border-radius: 15px;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0px;
  color: #4e6ba7;

  th {
    padding-right: 5px;
    min-width: 100px;
  }

  th:first-child {
    padding-left: 10px;
    border-top-left-radius: 15px;
  }
  th:last-child {
    border-top-right-radius: 15px;
    padding-right: 5px;
  }

  th:first-child {
    border-bottom-left-radius: 15px;
  }
  th:last-child {
    border-bottom-right-radius: 15px;
  }
`;

const BeforeTrContainer = styled.tr`
  width: 100%;
  height: 41px;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 20px;

  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0px;
  color: #4e6ba7;
  th:first-child {
    padding-left: 10px;
    border-top-left-radius: 15px;
  }
  th:last-child {
    border-top-right-radius: 20px;
    padding-right: 5px;
  }

  th:first-child {
    border-bottom-left-radius: 20px;
  }
  th:last-child {
    border-bottom-right-radius: 20px;
  }
`;

export const StyledTitle = styled.div`
  width: 160px;
  height: 58px;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #29579c;
  text-transform: capitalize;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-width: 700px;
  background-color: #f1f1f1;
  min-width: 100%;
  align-items: center;
`;

const DateThead = styled.thead`
  width: 160px;
  height: 90px;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #29579c;
  text-transform: capitalize;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  margin-bottom: 10px;
  min-width: 700px;
  background-color: #f1f1f1;
  min-width: 100%;
  align-items: center;
  top: 0;
  font-size: 16px;
  text-transform: lowercase;
`;

export const BackStyledButton = styled(StyledButton)`
  max-width: 210px;
  align-self: baseline;
  font-size: 11px;
  min-width: 145px;
  background: #103879 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #d7cc27;
  font-weight: bolder;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border-radius: 15px;
  padding: 0px 15px;
  div {
    width: 100%;
  }
  svg {
    transform: rotate(180deg);
  }
`;

export const TbodyContainer = styled.tbody`
  width: 100%;
  color: #4e6ba7;
  margin-bottom: 18px;
  .red {
    color: #d96767;
  }
  .space-bt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    min-height: 80px;
    /* height: 100cqh; */
  }
  h3:first-child {
    margin-bottom: 10px;
  }
  h3 {
    font-size: 12px;
    letter-spacing: 0px;
    color: #4e6ba7;
    opacity: 1;
    margin: 6px 0;
  }
  tr {
    background: #e8e8e8 0% 0% no-repeat padding-box;
    height: 50px;
  }
  tr:nth-child(even) {
    background-color: #fff;
  }
  tr > th {
    padding-bottom: 18px;
    text-align: left;
  }
  td:first-child {
    display: flex;
    margin-left: 10px;
    align-items: center;
    min-height: 90px;
    max-width: 590px;
  }
  td:last-child {
    padding-right: 10px;
  }
  td {
    text-align: left;
    height: 100%;
    padding-right: 5px;
  }
  p {
    font-size: 10px;
    margin-bottom: 0px;
  }
`;

export const PastAbstractTableContainer = styled.table`
  width: 100%;
  color: #4e6ba7;
  margin-bottom: 18px;
  .red {
    color: #d96767;
  }
  .space-bt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    min-height: 80px;
    /* height: 100cqh; */
  }
  h3:first-child {
    margin-bottom: 10px;
  }
  h3 {
    font-size: 12px;
    letter-spacing: 0px;
    color: #4e6ba7;
    opacity: 1;
    margin: 6px 0;
  }
  tbody > tr {
    background: #e8e8e8 0% 0% no-repeat padding-box;
    height: 50px;
  }
  tbody > tr:nth-child(even) {
    background-color: #fff;
  }
  tr > th {
    padding-bottom: 18px;
    text-align: left;
  }
  td:first-child {
    display: flex;
    margin-left: 10px;
    align-items: center;
    min-height: 90px;
    max-width: 590px;
  }
  td:last-child {
    padding-right: 10px;
  }
  td {
    text-align: left;
    height: 100%;
    /* container-type: inline-size; */
  }
  p {
    font-size: 10px;
    margin-bottom: 0px;
  }
`;

const StyledAButton = styled.a`
  color: #d4a518;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  font-size: 14px;
  cursor: pointer;

  transition: 250ms;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  background: #2a5299;
  color: #e6eaf2;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  line-height: 2.625rem;
  padding: 7px 19px;
  max-width: 172px;
  margin-bottom: 16px;
  height: 41px;
  border-radius: 40px;

  &:hover {
    background: whitesmoke;
  }
`;

export default withTranslation()(PastAbstractTable);
