import { CSSProperties } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IProps extends WithTranslation {
  style?: CSSProperties;
  href: string;
}

const ButtonDownload = ({ style, href, t }: IProps) => {
  return (
    <StyledContainer style={style} href={href} target="_blank">
      {t('global.button.generateReport')}
    </StyledContainer>
  );
};

const StyledContainer = styled.a`
  color: #d4a518;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  font-size: 14px;
  cursor: pointer;
  border: 1px solid;
  align-self: center;
  border-radius: 8px;
  padding: 6px;
`;

export default withTranslation()(ButtonDownload);
