import { AxiosResponse } from 'axios';
import IDocument from 'model/document';
import {
  IDateDataBase,
  INewReportPositionAnalytic,
  IReportOption,
  IReportPositionAnalytic,
  Organizations,
  Providers,
  SuportCfgs,
} from 'model/financial';
import { encryptReport, sendLog } from 'shared/util/api-utils';
import { apiCore, apiExtranetFiles } from './api';

const baseUrlReport = 'https://reports.frisia.coop.br/ReportServer?/EBS/AR';

export const FinancialPositionApi = () => {
  const getDataBase = (): Promise<AxiosResponse<IDateDataBase[]>> => {
    sendLog('Consulta de datas base posição financeira', `/ExtratosPosicaoFinanceira/GetDatasBase`, JSON.stringify([]), 'get');
    return apiCore.get<IDateDataBase[]>(`/ExtratosPosicaoFinanceira/GetDatasBase`);
  };

  const getReportOptions = (): Promise<AxiosResponse<IReportOption[]>> => {
    sendLog(
      'Consulta de opçẽs de relatorio da posição financeira',
      `/ExtratosPosicaoFinanceira/GetListaRelatorios`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IReportOption[]>(`/ExtratosPosicaoFinanceira/GetListaRelatorios`);
  };

  const getReport = (register: string, chosenDate: string, tokenJwt: string): Promise<AxiosResponse<IReportPositionAnalytic>> => {
    sendLog(
      'Consulta historico posição financeira',
      `/ExtratosPosicaoFinanceira/GetExtratoAnteriores?CD_PESSOA=${register}&CD_DATA_COMPLETA=${chosenDate}&JWT_TOKEN=${tokenJwt}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IReportPositionAnalytic>(
      `/ExtratosPosicaoFinanceira/GetExtratoAnteriores?CD_PESSOA=${register}&CD_DATA_COMPLETA=${chosenDate}&JWT_TOKEN=${tokenJwt}`
    );
  };

  const newGetReport = (register: string, chosenDate: string): Promise<AxiosResponse<INewReportPositionAnalytic>> => {
    sendLog(
      'Consulta historico posição financeira',
      `/PosicaoFinanceiraAnalitica/GetGeral?CD_PESSOA=${register}&DT_MES_ANO=${chosenDate}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<INewReportPositionAnalytic>(`/PosicaoFinanceiraAnalitica/GetGeral?CD_PESSOA=${register}&DT_MES_ANO=${chosenDate}`);
  };

  const getLinkMoviment = (register: string, chosenDate: string, indexofRow: number) => {
    const paths = [
      '',
      `${baseUrlReport}/XXFRAR0101R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA MOVIMENTO&p_dt_base=${chosenDate}`,
      `${baseUrlReport}/XXFRAR0102R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA MOVIMENTO`,
      `${baseUrlReport}/XXFRAR0103R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA MOVIMENTO`,
      `${baseUrlReport}/XXFRAR0104R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA MOVIMENTO&p_dt_base=${chosenDate}`,
      `${baseUrlReport}/XXFRAR0105R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA MOVIMENTO&p_ie_exibir_custeios=0&p_dt_base=${chosenDate}&p_dt_base_final=${chosenDate}`,
      `${baseUrlReport}/XXFRAR0106R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA MOVIMENTO`,
      `${baseUrlReport}/XXFRAR0107R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA MOVIMENTO`,
      '',
      `${baseUrlReport}/XXFRAR0109R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA MOVIMENTO`,
      `${baseUrlReport}/XXFRAR0110R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA MOVIMENTO`,
      `${baseUrlReport}/XXFRAR0110R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA MOVIMENTO`,
      '',
      `${baseUrlReport}/XXFRAR0105R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA MOVIMENTO&p_ie_exibir_custeios=0&p_dt_base=${chosenDate}&p_dt_base_final=${chosenDate}`,
    ];
    if (paths[indexofRow] != '') {
      return encryptReport(paths[indexofRow]);
    } else {
      return '';
    }
  };

  const getLinkItems = (
    register: string,
    chosenDate: string,
    indexofRow: number,
    idFinancialAccount: string,
    cdFinancialAccount: string
  ) => {
    const paths = [
      '',
      `${baseUrlReport}/XXFRAR0101R&p_cd_pessoa=${register}&p_id_conta_financeira=${idFinancialAccount}&p_dt_base=${chosenDate}&p_tp_conta_financeira=PRODUCAO`,
      `${baseUrlReport}/XXFRAR0102R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_id_conta_financeira=${idFinancialAccount}`,
      `${baseUrlReport}/XXFRAR0103R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_id_conta_financeira=${idFinancialAccount}`,
      `${baseUrlReport}/XXFRAR0104R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_id_conta_financeira=${idFinancialAccount}&p_dt_base=${chosenDate}`,
      `${baseUrlReport}/XXFRAR0105R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_cd_conta_financeira=${cdFinancialAccount}&p_ie_exibir_custeios=0&p_dt_base=${chosenDate}&p_dt_base_final=${chosenDate}`,
      `${baseUrlReport}/XXFRAR0106R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_cd_conta_financeira=${cdFinancialAccount}`,
      `${baseUrlReport}/XXFRAR0107R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_cd_conta_financeira=${cdFinancialAccount}`,
      '',
      `${baseUrlReport}/XXFRAR0109R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_id_conta_financeira=${idFinancialAccount}`,
      `${baseUrlReport}/XXFRAR0110R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_id_conta_financeira=${idFinancialAccount}`,
      `${baseUrlReport}/XXFRAR0110R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_id_conta_financeira=${idFinancialAccount}`,
      '',
      `${baseUrlReport}/XXFRAR0105R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_cd_conta_financeira=${cdFinancialAccount}&p_ie_exibir_custeios=0&p_dt_base=${chosenDate}&p_dt_base_final=${chosenDate}`,
    ];
    if (paths[indexofRow] != '') {
      return encryptReport(paths[indexofRow]);
    } else {
      return '';
    }
  };

  const getLinkProduction = (register: string, chosenDate: string, indexofRow: number) => {
    const paths = [
      '',
      `${baseUrlReport}/XXFRAR0101R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_dt_base=${chosenDate}`,
      `${baseUrlReport}/XXFRAR0102R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO`,
      `${baseUrlReport}/XXFRAR0103R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO`,
      `${baseUrlReport}/XXFRAR0104R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_dt_base=${chosenDate}`,
      `${baseUrlReport}/XXFRAR0105R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_ie_exibir_custeios=0&p_dt_base=${chosenDate}&p_dt_base_final=${chosenDate}`,
      `${baseUrlReport}/XXFRAR0106R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO`,
      `${baseUrlReport}/XXFRAR0107R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO`,
      '',
      `${baseUrlReport}/XXFRAR0109R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO`,
      `${baseUrlReport}/XXFRAR0110R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO`,
      `${baseUrlReport}/XXFRAR0110R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO`,
      '',
      `${baseUrlReport}/XXFRAR0105R&p_cd_pessoa=${register}&p_tp_conta_financeira=PRODUCAO&p_ie_exibir_custeios=0&p_dt_base=${chosenDate}&p_dt_base_final=${chosenDate}`,
    ];
    if (paths[indexofRow] != '') {
      return encryptReport(paths[indexofRow]);
    } else {
      return '';
    }
  };

  const getLinkTotals = (register: string, chosenDate: string, indexofRow: number) => {
    const paths = [
      '',
      `${baseUrlReport}/XXFRAR0101R&p_cd_pessoa=${register}&p_dt_base=${chosenDate}&p_tp_conta_financeira=CONTA%20MOVIMENTO&p_tp_conta_financeira=PRODUCAO`,
      `${baseUrlReport}/XXFRAR0102R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA%20MOVIMENTO&p_tp_conta_financeira=PRODUCAO`,
      `${baseUrlReport}/XXFRAR0103R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA%20MOVIMENTO&p_tp_conta_financeira=PRODUCAO`,
      `${baseUrlReport}/XXFRAR0104R&p_cd_pessoa=${register}&p_tp_conta_financeira=CONTA%20MOVIMENTO&p_tp_conta_financeira=PRODUCAO&p_dt_base=${chosenDate}`,
      `${baseUrlReport}/XXFRAR0105R&p_cd_pessoa=${register}&p_ie_exibir_custeios=0&p_dt_base=${chosenDate}&p_dt_base_final=${chosenDate}`,
      `${baseUrlReport}/XXFRAR0106R&p_cd_pessoa=${register}`,
      `${baseUrlReport}/XXFRAR0107R&p_cd_pessoa=${register}`,
      '',
      `${baseUrlReport}/XXFRAR0109R&p_cd_pessoa=${register}`,
      `${baseUrlReport}/XXFRAR0110R&p_cd_pessoa=${register}`,
      `${baseUrlReport}/XXFRAR0110R&p_cd_pessoa=${register}`,
      '',
      `${baseUrlReport}/XXFRAR0105R&p_cd_pessoa=${register}&p_ie_exibir_custeios=0&p_dt_base=${chosenDate}&p_dt_base_final=${chosenDate}`,
    ];
    if (paths[indexofRow] != '') {
      return encryptReport(paths[indexofRow]);
    } else {
      return '';
    }
  };

  const getReportByAccountType = (register: string, chosenDate: string, accountType: 'ALL' | 'CGP' | 'CCS') => {
    const path = `https://servicos.frisia.coop.br/Core/api/FinanceiroPosicaoFinanceira/RelatorioPorTipoConta?CD_PESSOA=${register}&CD_TP_CONTA_FINANCEIRA=${accountType}&DT_SALDO=${chosenDate}`;
    console.log(path);
    sendLog('Consulta relatorio por tipo de conta', path, JSON.stringify([]), 'get');

    return apiCore.get<any>(path);
  };

  return {
    getDataBase,
    getReportOptions,
    getReport,
    newGetReport,
    getLinkMoviment,
    getLinkItems,
    getLinkProduction,
    getLinkTotals,
    getReportByAccountType,
  };
};

export default FinancialPositionApi();
