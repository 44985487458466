import FinanciaAbstractApi from 'api/financial-abstract-api';
import { AxiosError, AxiosResponse, CancelToken } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { IAdmission, IFinancialMoviment, IFutureCredits, IFutureDebts, IInfoAccount, ITaxes } from 'model/financial-abstract';

export const FinancialAbstractService = () => {
  const getAdmission = async (register: string): Promise<IAdmission> => {
    try {
      const result = await FinanciaAbstractApi.getAdmission(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getInterestRates = async (): Promise<ITaxes[]> => {
    try {
      const result = await FinanciaAbstractApi.getInterestRates();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAccountInfo = async (register: string, date: string, token: CancelToken): Promise<IInfoAccount> => {
    try {
      const result = await FinanciaAbstractApi.getAccountInfo(register, date, token);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getFutureCredits = async (register: string, date: string): Promise<IFutureCredits> => {
    try {
      const result = await FinanciaAbstractApi.getFutureCredits(register, date);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getFutureDebts = async (register: string, date: string): Promise<IFutureDebts> => {
    try {
      const result = await FinanciaAbstractApi.getFutureDebts(register, date);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getFinancialMoviment = async (
    register: string,
    initialDate: string,
    finalDate: string,
    token: CancelToken
  ): Promise<IFinancialMoviment> => {
    try {
      const result = await FinanciaAbstractApi.getFinancialMoviment(register, initialDate, finalDate, token);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getAdmission,
    getInterestRates,
    getAccountInfo,
    getFutureCredits,
    getFutureDebts,
    getFinancialMoviment,
  };
};

export default FinancialAbstractService();
