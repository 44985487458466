import TextField from '@mui/material/TextField';
import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';
import consultAuthorizationService from 'services/authorization-consult-service';
import { IAuths, IConsults, IFunctions } from 'model/consult-authorization';
import Checkbox from 'components/Checkbox';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import { ReactComponent as ExclamationIcon } from '../../images/frisia/icons/gray-Icon-exclamation-circle.svg';
import GenericInputToTable from 'components/GenericInputToTable';

interface IForm {
  funcOptions: string;
  myConsult: string;
  accepted: boolean;
}

const AuthorizationForConsult = ({ t }: WithTranslation) => {
  const [openThermesModal, setOpenThermesModal] = useState<boolean>(false);
  const [SawTerme, setSawTermes] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [document, setDoc] = useState('');
  const [name, setName] = useState('');
  const [password, setPwd] = useState('');

  const [consultOptions, setconsultOptions] = useState<IConsults[]>([]);
  const [chosenConsult, setChosenConsult] = useState<string>();
  const [chosenConsultToDelete, setChosenConsultToDelete] = useState<IConsults>();
  const [chosenConsultToRecPwd, setChosenConsultToRecPwd] = useState<string>();
  const [funcOptions, setFuncOptions] = useState<IFunctions[]>([]);
  const [chosenFunc, setChosenFunc] = useState<string>();
  const [myAuths, seMyAuths] = useState<IAuths[]>([]);

  const [thermes, setThermes] = useState<string>();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [chosenAuth, setChosenAuth] = useState<IAuths>();

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getUserName = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.NM_PESSOA ?? state.authentication.coreInfo?.nm_usuario;
  });

  const getMyAuths = async () => {
    consultAuthorizationService.getMyAuths(getUserRegistration).then(response => {
      seMyAuths(response);
      setDisableButton(false);
    });
  };

  const cancelAuth = async () => {
    if (chosenAuth)
      consultAuthorizationService.deleteAuth(chosenAuth?.ID.toString(), getUserRegistration).then(response => {
        setOpenModal(false);
        getMyAuths();
      });
  };

  const recoverConsultPwd = async () => {
    if (chosenConsultToRecPwd)
      consultAuthorizationService.recConPwd(chosenConsultToRecPwd, getUserRegistration).then(response => {
        setOpenModal(false);
        getMyAuths();
      });
  };

  const deleteConsult = async () => {
    if (chosenConsultToDelete)
      consultAuthorizationService.deleteConslt(chosenConsultToDelete.NR_CONSULTOR, getUserRegistration).then(response => {
        setOpenModal(false);
        getData();
      });
  };

  const createConsultor = async () => {
    if (document && name) {
      await consultAuthorizationService
        .createConslt({
          CD_PESSOA: getUserRegistration,
          NM_AUTORIZADO: name,
          DOC_AUTORIZADO: document,
          SENHA_AUTORIZADO: password,
        })
        .then(response => getData());
    }
  };

  const getData = async () => {
    consultAuthorizationService.getConsults(getUserRegistration).then(response => {
      setconsultOptions(response);
    });
    consultAuthorizationService.getFunctionsOptions().then(response => {
      setFuncOptions(response);
    });
    consultAuthorizationService.getAcepetTherme().then(response => {
      setThermes(response[0].DS_TEXTO);
    });
  };

  useEffect(() => {
    getData();
    getMyAuths();
  }, []);

  useEffect(() => {
    if (openThermesModal === true) {
      setSawTermes(true);
    }
  }, [openThermesModal]);

  const schema = yup.object({
    funcOptions: yup.string().test('test-harvest', t('global.errorMessage.required'), () => chosenConsult != null),
    myConsult: yup.string().test('test-product', t('global.errorMessage.required'), () => chosenConsult != null),
    accepted: yup.string().test('test-paymentDate', t('global.errorMessage.acceptedTerms'), () => accepted != false),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  const handleSubmit = async data => {
    const myConsult = consultOptions.find(item => item.NM_CONSULTOR === chosenConsult);
    const funcionality = funcOptions.find(item => item.DS_FUNCIONALIDADE === chosenFunc)?.CD_FUNCIONALIDADE;
    setDisableButton(true);
    if (myConsult && funcionality) {
      await consultAuthorizationService
        .authNewConsult({
          CD_FUNCIONALIDADE: funcionality,
          NM_CONSULTOR: myConsult?.NM_CONSULTOR,
          NR_CONSULTOR: myConsult?.NR_CONSULTOR,
          CD_PESSOA: myConsult?.CD_PESSOA,
          NM_AUTORIZADOR: getUserName,
          IE_ACEITOU_TERMOS: 'Y',
        })
        .then(response => getMyAuths());
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {openThermesModal && (
            <ModalContainer>
              <ModalShadow onClick={() => setOpenThermesModal(false)} />
              <CardModalContainer>
                <div style={{ padding: '10px' }}>
                  <SubtitleStyled>{thermes}</SubtitleStyled>
                  <StyledButton
                    color="frisiaPrimary"
                    onClick={() => setOpenThermesModal(false)}
                    type="button"
                    style={{ color: 'white', minWidth: '145px', height: '42px', marginTop: '20px' }}
                  >
                    <span>{t('global.button.ok')}</span>
                  </StyledButton>
                </div>
              </CardModalContainer>
            </ModalContainer>
          )}
          {openModal && (
            <ModalContainer>
              <ModalShadow onClick={() => setOpenThermesModal(false)} />
              <CardModalContainer>
                <div style={{ padding: '10px' }}>
                  <TitleStyled>
                    {t('authConsult.confirmCancel')} {chosenAuth?.USERNAME_AUTORIZADO} {t('authConsult.onFunc')}
                    {': '}
                    {chosenAuth?.DS_FUNCIONALIDADE}?
                  </TitleStyled>
                  <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <CancelButton
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      style={{ width: '65px', height: '40px' }}
                      type="button"
                    >
                      {t('salesAuth.no')}
                    </CancelButton>
                    <StyledButton style={{ height: '47px', maxWidth: 'none' }} onClick={cancelAuth} color="frisiaPrimary" type="button">
                      <span>{t('salesAuth.yes')}</span>
                    </StyledButton>
                  </div>
                </div>
              </CardModalContainer>
            </ModalContainer>
          )}

          <StyledPageTitleContainer margin={'10px 0 25px 0'} style={{ display: 'flex' }}>
            <MainPageTitle fontSize={20} text={t('authConsult.authConsult')} />
          </StyledPageTitleContainer>

          <div style={{ display: 'flex', flexFlow: 'wrap' }}>
            <NewAuthContainer style={{ minWidth: '500px', marginBottom: '14px', marginRight: '15px', overflowY: 'auto' }}>
              <PriceTitle>{t('authConsult.myAuth')}:</PriceTitle>
              <GenericTableBlueHeader
                headerItems={[
                  t('authConsult.id'),
                  t('authConsult.functionality'),
                  t('authConsult.type'),
                  t('authConsult.consultant'),
                  t('authConsult.authorizer'),
                  t('authConsult.authorizationDate'),
                  '',
                ]}
                tableItems={myAuths.map(item => {
                  return {
                    id: item.ID,
                    functionality: item.DS_FUNCIONALIDADE,
                    type: item.DS_TIPO_FUNCIONALIDADE,
                    consultant: item.USERNAME_AUTORIZADO,
                    authorizer: item.NM_AUTORIZADOR,
                    authorizationDate: moment(item.DT_AUTORIZACAO, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY'),
                    button: (
                      <CancelButton
                        type="button"
                        onClick={() => {
                          setOpenModal(true);
                          setChosenAuth(item);
                        }}
                        style={{ width: '95px', height: '35px' }}
                      >
                        {t('global.button.cancel')}
                      </CancelButton>
                    ),
                  };
                })}
                dataColor="#787878"
                isInvertLineColor
                isDontHaveMaximumWidth
              />
            </NewAuthContainer>
            <NewAuthContainer style={{ maxWidth: '350px', minWidth: '200px', margin: '14px' }}>
              <PriceTitle>{t('authConsult.newAuth')}:</PriceTitle>
              <GenericDropdown
                label={t('authConsult.func')}
                placeholder={t('agricultural.selectHere')}
                listShowDropdown={funcOptions.map(item => item.DS_FUNCIONALIDADE)}
                getSelectedValue={value => setChosenFunc(value)}
                errorText={methods.formState.errors.funcOptions?.message}
              />
              <div style={{ margin: '6px 0px' }}>
                <GenericDropdown
                  label={t('authConsult.consult')}
                  placeholder={t('agricultural.selectHere')}
                  listShowDropdown={consultOptions.map(item => item.NM_CONSULTOR)}
                  getSelectedValue={value => setChosenConsult(value)}
                  errorText={methods.formState.errors.myConsult?.message}
                />
              </div>
              <div style={{ width: '100%' }}>
                <StyledButton
                  color="frisiaPrimary"
                  style={{ maxWidth: '100%', maxHeight: '50px', fontSize: '10px', marginBottom: '8px' }}
                  type="button"
                  onClick={() => setOpenThermesModal(true)}
                >
                  <span>{t('authConsult.clickHere')}</span>
                </StyledButton>
              </div>
              <Checkbox
                disabled={!SawTerme ? true : false}
                inputName="onlyFeed"
                handleChecked={() => setAccepted(!accepted)}
                text={t('authConsult.accept')}
                style={{ width: '300px' }}
              />
              <StyledErrorMessage style={{ position: 'initial', transform: 'translateY(-19px)' }}>
                {methods.formState.errors.accepted?.message}
              </StyledErrorMessage>

              <div style={{ width: '100%' }}>
                <StyledButton disabled={disableButton} color="frisiaPrimary" style={{ maxWidth: '150px', maxHeight: '50px' }} type="submit">
                  <span>{t('authConsult.auth')}</span>
                </StyledButton>
              </div>
            </NewAuthContainer>

            <NewAuthContainer
              style={{
                maxWidth: '350px',
                minWidth: '200px',
                margin: '14px',
                marginBottom: '14px',
                marginRight: '15px',
                overflowY: 'auto',
              }}
            >
              <PriceTitle>{t('authConsult.registerNewConsult')}:</PriceTitle>
              <WarningCard style={{ padding: '20px', margin: '11px', width: 'auto' }}>
                <ExclamationIcon />
                <p>
                  <Trans>
                    <span>{{ value: t('authConsult.consultCreateAlert') }}</span>
                  </Trans>
                </p>
              </WarningCard>
              <StyledInputText
                label={t('livestock.name')}
                placeholder={t('livestock.name')}
                onChange={e => {
                  setName(e.target.value);
                }}
                variant="outlined"
                type={'text'}
              />
              <GenericInputToTable
                type="number"
                style={{ marginTop: '20px' }}
                inputName="doc"
                label={t('personalDocuments.cpfCnpj')}
                placeholder={t('personalDocuments.textPlsInformCpfCnpj')}
                onChange={e => {
                  setDoc(e.target.value);
                }}
              />
              <StyledInputText
                style={{ marginTop: '20px' }}
                label={t('authConsult.pwdField')}
                placeholder={t('authConsult.pwdPlaceholder')}
                onChange={e => {
                  setPwd(e.target.value);
                }}
                variant="outlined"
                type={'text'}
              />
              <div style={{ width: '100%', paddingTop: '10px' }}>
                <StyledButton style={{ height: '47px', maxWidth: 'none' }} onClick={createConsultor} color="frisiaPrimary" type="button">
                  <span>{t('authConsult.reg')}</span>
                </StyledButton>
              </div>
            </NewAuthContainer>

            <NewAuthContainer
              style={{
                maxWidth: '350px',
                minWidth: '200px',
                margin: '14px',
                marginBottom: '14px',
                marginRight: '15px',
                overflowY: 'auto',
              }}
            >
              <PriceTitle>{t('authConsult.deleteConsult')}:</PriceTitle>
              <WarningCard style={{ padding: '20px', margin: '11px', width: 'auto' }}>
                <ExclamationIcon />
                <p>
                  <Trans>
                    <span>{{ value: t('authConsult.consultDeleteAlert') }}</span>
                  </Trans>
                </p>
              </WarningCard>
              <div style={{ margin: '6px 0px' }}>
                <GenericDropdown
                  label={t('authConsult.consult')}
                  placeholder={t('agricultural.selectHere')}
                  listShowDropdown={consultOptions}
                  keyToShowInDropdown="NM_CONSULTOR"
                  getSelectedValue={value => setChosenConsultToDelete(value)}
                  errorText={methods.formState.errors.myConsult?.message}
                />
              </div>
              <div style={{ width: '100%', paddingTop: '10px' }}>
                <StyledButton style={{ height: '47px', maxWidth: 'none' }} onClick={deleteConsult} color="frisiaPrimary" type="button">
                  <span>{t('authConsult.del')}</span>
                </StyledButton>
              </div>
            </NewAuthContainer>

            <NewAuthContainer style={{ maxWidth: '350px', minWidth: '200px', margin: '14px' }}>
              <PriceTitle>{t('authConsult.recoverConsultPwd')}:</PriceTitle>
              <WarningCard style={{ padding: '20px', margin: '11px', width: 'auto' }}>
                <ExclamationIcon />
                <p>
                  <Trans>
                    <span>{{ value: t('authConsult.showPwd') }}</span>
                  </Trans>
                </p>
              </WarningCard>
              <div style={{ margin: '6px 0px' }}>
                <GenericDropdown
                  label={t('authConsult.consult')}
                  placeholder={t('agricultural.selectHere')}
                  listShowDropdown={consultOptions}
                  keyToShowInDropdown="NM_CONSULTOR"
                  getSelectedValue={value => setChosenConsultToRecPwd(value.NR_CONSULTOR)}
                  errorText={methods.formState.errors.myConsult?.message}
                />
              </div>
              <div style={{ width: '100%', paddingTop: '10px' }}>
                <StyledButton style={{ height: '47px', maxWidth: 'none' }} onClick={recoverConsultPwd} color="frisiaPrimary" type="button">
                  <span>{t('authConsult.recPwd')}</span>
                </StyledButton>
              </div>
            </NewAuthContainer>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const NewAuthContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  padding: 23px 16px;
  display: flex;
  flex-direction: column;
  text-align: start;
  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 146px;
    padding-left: 6px;
  }
`;

const PriceTitle = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold};
  letter-spacing: 0.01px;
  color: #2a5299;
  opacity: 1;
  margin-bottom: 10px;
`;

const CardModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  min-width: 445px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  b {
    align-self: self-start;
    margin: 20px 0px;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
`;

const TitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const SubtitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 15px;
  letter-spacing: 0px;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratRegular};
`;

const CancelButton = styled.button`
  width: 245px;
  height: 34px;
  font-size: 14px;
  letter-spacing: 0.02px;
  color: #d4a518;
  text-transform: uppercase;
  opacity: 1;
  border: #d4a518 2px solid;
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  cursor: pointer;
`;

const StyledInputText = styled(TextField)`
  div {
    font-family: ${({ theme }) => theme.font.montserratRegular};
    height: 53px;
    min-width: 200px;
    fieldset {
      border: 2px solid #4e6ba7 !important;
    }
  }
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary} !important;
    letter-spacing: 0.4px;
    font-size: 13.2px;
    line-height: 2.2em;
    padding-left: 7px;
  }
  input {
    font-size: 16px;
    color: #656565;
    &::placeholder {
      opacity: 1;
    }
  }
`;
export const WarningCard = styled.div`
  top: 302px;
  left: 379px;
  width: 350px;
  height: 130px;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  margin: 5px;
  padding: 52px 44px;
  text-align: left;
  line-height: normal;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  letter-spacing: 0px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 15px;
    width: 402px;
    min-height: 78px;
  }
  .text-blue {
    color: #2a5299;
  }
`;
export default withTranslation()(AuthorizationForConsult);
