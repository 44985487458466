import { StyledButton } from 'components/styled-button/StyledButton';
import styled from 'styled-components/macro';
import { ReactComponent as ExclamationIcon } from '../../../images/frisia/icons/noun-information-817952.svg';

export const StyledExclamationIcon = styled(ExclamationIcon)`
  align-self: self-start;
  min-height: 18px;
  min-width: 18px;
  height: 18px;
  width: 18px;
  transform: translate(0px, -4px);
`;

export const DaysStyledButton = styled(StyledButton)`
  max-width: 210px;
  align-self: center;
  font-size: 11px;
  min-width: 145px;
`;

export const AnswerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  transform: translateY(-40px);
`;

export const DownloadIconsContainet = styled.div`
  margin-top: 100px;
  margin-right: 74px;
  img {
    cursor: pointer;
  }
`;

export const StyledContainer = styled.div`
  transform: translateY(-12px);
`;

interface LongProps {
  cursorPointer?: boolean;
}

export const StyledLongButton = styled.div<LongProps>`
  height: 40px;
  background: #103879 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #d7cc27;
  padding-left: 44px;
  padding-right: 13px;
  border-radius: 15px;
  margin-bottom: 3px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${props => (props.cursorPointer ? 'pointer' : 'auto')};
  .red {
    color: #d96767;
  }
  .white {
    color: #fff;
  }
  div {
    min-width: 180px;
    display: flex;
    justify-content: space-between;
  }
  div:first-child {
    min-width: unset;
  }
`;

export const StyledPageTitleContainer = styled.div`
  margin: 10px 0 8px 0;
  display: flex;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const FirstParamsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const SecondParamsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
  min-height: 100px;
  align-items: baseline;
`;

export const AccountBlueContainer = styled.div`
  width: 390px;
  height: 122px;
  background: #2a5299 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000029;
  opacity: 1;
  border-radius: 15px;
  color: white;
  font-size: 14px;
  padding: 8px;
  display: grid;
  h1 {
    font-size: 28px;
    font-weight: bolder;
    width: 220px;
    line-height: 20px;
  }
  .flex {
    display: flex;
    justify-content: space-around;
  }
  .yellow {
    color: yellow;
  }
  .red {
    color: #d96767;
  }
  .white {
    color: #fff;
  }
`;

export const StyledFiltersDateContainer = styled.div`
  display: flex;
  column-gap: 5px;
  flex-wrap: wrap;
  max-width: 830px;
  min-width: 205px;
  justify-content: unset;
  padding-top: 15px;
  align-items: center;
`;

export const StyledFirstInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 265px;
  div {
    display: flex;
  }
`;

export const StyledText = styled.div`
  font-size: 13px;
  font-family: ${({ theme }) => theme.font.montserratBold};
  letter-spacing: 0px;
  color: #656565;
  opacity: 1;
  margin: 0px 15px;
  align-items: center;
`;

export const StyledSecondInfo = styled.table`
  width: 280px;
  height: 82px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  opacity: 1;
  font-size: 13px;
  tbody {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    tr {
      display: flex;
      justify-content: space-between;
      line-height: 26px;
      td {
        text-align: left;
        min-width: 110px;
        padding: 6px;
      }
    }
    tr:nth-child(odd) {
      height: 100%;
      .gray-first {
        background-color: #f2f2f2;
        border-top-right-radius: 12px;
      }
      .gray-second {
        background-color: #f2f2f2;
        border-bottom-right-radius: 12px;
      }
    }
  }
`;

export const TaxesTableContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 10px;
  width: 400px;
`;

export const TaxesButton = styled.div`
  width: 150px;
  height: 35px;
  line-height: 35px;
  background: #4e6ba7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  z-index: 2;
  cursor: pointer;
`;

export const TaxesTable = styled.div`
  @keyframes show_up {
    from {
      display: none;
    }
    to {
      display: unset;
    }
  }
  &.dont-show {
    display: none;
  }
  .blue-row {
    color: #2a5299;
  }
  .red-row {
    color: #fb1d52;
  }
  table {
    height: 100%;
    width: 100%;
  }
  td:first-child {
    text-align: left;
    padding-left: 5px;
  }

  animation-duration: show_up 0.5s 0s;
  width: 400px;
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  opacity: 1;
  padding: 4px;
`;
