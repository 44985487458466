import styled from 'styled-components';
import { ReactComponent as PdfIconSvg } from '../images/frisia/icons/blue-doc-download.svg';

interface IProps {
  text: string;
  onClick: () => void;
}

const Document = ({ text, onClick }: IProps) => {
  return (
    <StyledItem onClick={() => onClick()}>
      <PdfIconSvg />
      {text}
    </StyledItem>
  );
};

const StyledItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  text-align: initial;
  svg {
    margin: 8px 25px 8px 15px;
    min-width: 28px;
    min-height: 40px;
  }
`;

export default Document;
