import i18n from 'config/i18n';
import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: i18n.t('global.errorMessage.required'),
  },
  string: {
    email: i18n.t('global.errorMessage.email'),
  },
});
