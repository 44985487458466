import { Grid } from '@mui/material';
import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import {
  IBonification,
  ICollectPoint,
  IDateOption,
  IEconomy,
  IGeneralParams,
  IInspection,
  IReception,
  ISlaughterResult,
  ISummaries,
  ITypefication,
} from 'model/slaughter-report';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import slaughterReportService from 'services/swine/slaughter-report-service';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import moment from 'moment';
import GenericTableAlegraParams from 'components/GenericTableAlegraParams';
import GenericTableSlaughterReport from 'components/GenericTableSlaughterReport';
import styled from 'styled-components';
import CollapsibleTatto from 'components/CollapsibleTatto';

const SlaughterReport = ({ t }: WithTranslation) => {
  const [collectPoints, setCollectPoints] = useState<ICollectPoint[]>([]);
  const [selectedCollectionPoint, setSelectedCollectionPoint] = useState<ICollectPoint>();
  const [datesOptions, setDatesOptions] = useState<IDateOption[]>([]);
  const [initialDate, setInitialDate] = useState<string>();
  const [finalDate, setFinalDate] = useState<string>();
  const [paramsList, setParamsList] = useState<IGeneralParams[]>([]);
  const [slaughterResultList, setSlaughterResultList] = useState<ISlaughterResult[]>([]);
  const [bonificationList, setBonificationList] = useState<IBonification[]>([]);
  const [economyList, setEconomyList] = useState<IEconomy[]>([]);
  const [receptionList, setReceptionList] = useState<IReception[]>([]);
  const [summariesList, setSummariesList] = useState<ISummaries[]>([]);
  const [inspectionList, setInspectionList] = useState<IInspection[]>([]);
  const [typefication, setTypefication] = useState<ITypefication[]>([]);

  const [isResetFilterSlaughterDate, setIsResetFilterSlaughterDate] = useState<boolean>(false);

  const userRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  useEffect(() => {
    if (userRegistration != null) {
      slaughterReportService.getCollectPoints(userRegistration).then(setCollectPoints);
    }
  }, []);

  useEffect(() => {
    if (selectedCollectionPoint) {
      slaughterReportService.getDatesOptions(userRegistration, selectedCollectionPoint.FULL_PONTO_COLETA).then(setDatesOptions);
    }
  }, [selectedCollectionPoint]);

  const getAllData = () => {
    if (selectedCollectionPoint && initialDate && finalDate) {
      slaughterReportService
        .getGeneralParams(userRegistration, selectedCollectionPoint.FULL_PONTO_COLETA, initialDate, finalDate)
        .then(setParamsList);
      slaughterReportService
        .getSlaughterResult(userRegistration, selectedCollectionPoint.FULL_PONTO_COLETA, initialDate, finalDate)
        .then(setSlaughterResultList);
      slaughterReportService
        .getBonification(userRegistration, selectedCollectionPoint.FULL_PONTO_COLETA, initialDate, finalDate)
        .then(setBonificationList);
      slaughterReportService
        .getEconomy(userRegistration, selectedCollectionPoint.FULL_PONTO_COLETA, initialDate, finalDate)
        .then(setEconomyList);
      slaughterReportService
        .getReception(userRegistration, selectedCollectionPoint.FULL_PONTO_COLETA, initialDate, finalDate)
        .then(setReceptionList);
      slaughterReportService
        .getSummaries(userRegistration, selectedCollectionPoint.FULL_PONTO_COLETA, initialDate, finalDate)
        .then(setSummariesList);
      slaughterReportService
        .getInspection(userRegistration, selectedCollectionPoint.FULL_PONTO_COLETA, initialDate, finalDate)
        .then(setInspectionList);
      slaughterReportService
        .getTypefication(userRegistration, selectedCollectionPoint.FULL_PONTO_COLETA, initialDate, finalDate)
        .then(setTypefication);
    }
  };

  useEffect(() => {
    getAllData();
  }, [initialDate, finalDate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledPageTitleContainer margin={'10px 0 25px 0'}>
          <MainPageTitle fontSize={30} text={t('livestock.slaughterReport')} />
        </StyledPageTitleContainer>
      </Grid>

      <Grid item xs={12} style={{ display: 'flex', columnGap: '10px', rowGap: '10px', flexWrap: 'wrap' }}>
        <StyledFilterContainer filterWidth={350}>
          <GenericDropdown
            label={t('livestock.collectPoint')}
            placeholder={t('global.field.selectHere')}
            listShowDropdown={collectPoints}
            keyToShowInDropdown="FULL_PONTO_COLETA"
            getSelectedValue={setSelectedCollectionPoint}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={150}>
          <GenericDropdown
            isResetValue={isResetFilterSlaughterDate}
            label={t('global.field.slaughterDate')}
            placeholder={t('global.field.selectHere')}
            listShowDropdown={datesOptions
              .map(item => moment(item.DT_ABATE, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY'))
              .filter((v, i, a) => a.indexOf(v) === i)}
            getSelectedValue={setInitialDate}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={150}>
          <GenericDropdown
            isResetValue={isResetFilterSlaughterDate}
            label={t('slaughter.until')}
            placeholder={t('global.field.selectHere')}
            listShowDropdown={datesOptions
              .map(item => moment(item.DT_ABATE, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY'))
              .filter((v, i, a) => a.indexOf(v) === i)}
            getSelectedValue={setFinalDate}
          />
        </StyledFilterContainer>
      </Grid>

      {paramsList.length > 0 && (
        <>
          <Grid container lg={12} rowSpacing={2} item>
            <Grid style={{ overflowX: 'auto' }} item xs={12}>
              <GenericTableAlegraParams isDontHaveMaximumWidth tableItems={paramsList} />
            </Grid>
            <Grid style={{ overflowX: 'auto' }} item xs={12}>
              <GenericTableSlaughterReport
                title={t('slaughter.results')}
                paramsRows={[
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: 'ID do Lote',
                    kiloSymbol: 'N/A',
                  },
                  {
                    parameter: 'Carga - Tipo de Entrega',
                    kiloSymbol: 'N/A',
                  },
                  {
                    parameter: 'Carga - Atrasada',
                    kiloSymbol: 'Dias',
                  },
                  {
                    parameter: 'Carga - Antecipada',
                    kiloSymbol: 'Dias',
                  },
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: 'Carga - Score de Limpeza',
                    kiloSymbol: 'N/A',
                  },
                  {
                    parameter: 'Carga - Imunocastrado',
                    kiloSymbol: 'N/A',
                  },
                  {
                    parameter: 'Horário - Carregamento',
                    kiloSymbol: 'Data/Hora',
                  },
                  {
                    parameter: 'Horário - Chegada na UIC',
                    kiloSymbol: 'Data/Hora',
                  },
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: 'N.º Suínos - Descarregados',
                    kiloSymbol: 'Cab',
                  },
                  {
                    parameter: 'Peso Total - SUÍNOS VIVOS',
                    kiloSymbol: 'Kg',
                  },
                  {
                    parameter: 'Peso Total - Carcaças',
                    kiloSymbol: 'Kg',
                  },
                  {
                    parameter: 'Kg Carcaças - PADRÃO',
                    kiloSymbol: 'Kg',
                  },
                  {
                    parameter: 'Kg Carcaças - EMBUTIDO/CONSERVA',
                    kiloSymbol: 'Kg',
                  },
                  {
                    parameter: 'Kg Carcaças - GRAXARIA DIF E RECEPÇÃO',
                    kiloSymbol: 'Kg',
                  },
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: '% Suínos Sequestrados',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: '% de Carcaças Desviadas - DIF',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: 'Rendimento de Carcaça',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: 'Espessura de Toucinho',
                    kiloSymbol: 'mm',
                  },
                  {
                    parameter: 'Peso Suínos Vivos - Média da Carga',
                    kiloSymbol: 'Kg',
                  },
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: '% Suinos peso < 105 kg',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: '% Suinos peso 105 a 140 kg',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: '% Suinos peso > 140 kg',
                    kiloSymbol: '%',
                  },
                ]}
                isDontHaveMaximumWidth
                getValues={slaughterResultList.map(row => [
                  row.NR_TATUAGEM,
                  row.NR_ID_LOTPAR,
                  row.DS_ENTREGA,
                  row.QT_DIA_ENTREGA_ATRASADA,
                  row.QT_DIA_ENTREGA_ANTECIPADA,
                  '',
                  row.DS_TIPO_SCORE_LIMPEZA,
                  row.DS_IMUNOCASTRADO_CARGA_RES,
                  moment(row.DT_HR_CARREGAMENTO, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm'),
                  moment(row.DT_HR_ENTRADA_VEICULO, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm'),
                  '',
                  row.QT_SUINO_REC_TOT,
                  row.QT_PESO_REC_TOT,
                  row.QT_PESO_CARCACA_TOTAL_RES,
                  row.QT_PESO_CARCACA_PADRAO_RES,
                  row.QT_PESO_EMBUT_CAMPO_DIF_TOT,
                  row.QT_PESO_GRAXA_CAMPO_DIF_RES,
                  '',
                  row.PC_SEQUESTRO_REC_TOT,
                  row.TX_CARCACA_DES_DIF_TOT,
                  row.PC_REND_CARCACA_RES,
                  row.QT_GORDURA_MD_TIP_TOT,
                  row.QT_PESO_MEDIO_CONSID_RES,
                  '',
                  row.PC_MENOS_QUE_105,
                  row.PC_ENTRE_105_E_140,
                  row.PC_MAIS_QUE_140,
                ])}
              />
            </Grid>
          </Grid>

          <Grid container lg={12} rowSpacing={2} item>
            <Grid style={{ overflowX: 'auto' }} item xs={12}>
              <GenericTableSlaughterReport
                title={t('slaughter.bonifications')}
                paramsRows={[
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: 'TOTAL Bonificação sobre Carcaça Padrão',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: 'Bonificação - FIXA (4%)',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: 'Bonificação - CARCAÇA (0% A 8%)',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: 'Bonificação - CHECKLIST (0% A 3%)',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: 'Bonificação - PROGRAMA DE GESTÃO (0% A 1%)',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: 'Bonificação - SCORE DE LIMPEZA (0% a 0,5%)',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: 'TOTAL Result. REND. CARCAÇA E SANIDADE',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: 'Resultado - RENDIMENTO CARCAÇA',
                    kiloSymbol: '%',
                  },
                  {
                    parameter: 'Resultado - SANIDADE',
                    kiloSymbol: '%',
                  },
                ]}
                isDontHaveMaximumWidth
                getValues={bonificationList.map(row => [
                  row.NR_TATUAGEM,
                  row.TOTAL_BONIF_CARCACA_PADRAO,
                  row.TX_FIXA_BONIF,
                  row.TX_TOTAL_BONIF_RES,
                  row.TX_CHECKLIST_BONIF_RES,
                  row.TX_PROG_GESTAO_BONIF_RES,
                  row.TX_TIPO_SCORE_LIMPEZA,
                  '',
                  row.TOTAL_REND_CARCACA_SANIDADE,
                  row.TX_REND_CARCACA_BONIF_RES,
                  row.TX_SANIDADE_BONIF_RES,
                ])}
              />
            </Grid>
            <Grid style={{ overflowX: 'auto' }} item xs={12}>
              <GenericTableSlaughterReport
                title={t('slaughter.economy')}
                paramsRows={[
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: 'VALOR TOTAL',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: 'VALOR - CARCAÇAS',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: 'Valor - CARCAÇA PADRÃO',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: 'Valor - CARCAÇA EMB./CONSERVA',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: 'Valor - CARCAÇA GRAXARIA',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: 'VALOR - BONIFICAÇÕES (CARCAÇA PADRÃO)',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: 'Bonificação - FIXA',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: 'Bonificação - CARCAÇA',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: 'Bonificação - CHECKLIST',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: 'Bonificação - PROGRAMA DE GESTÃO',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: 'Bonificação - SCORE DE LIMPEZA',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: 'VALOR - ANTECIPAÇÃO DE ABATE',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: 'VALOR - CONSIDERAÇÃO TÉCNICA',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: 'VALOR - EQUIPE DE CARREGAMENTO',
                    kiloSymbol: 'R$',
                  },
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: 'PREÇO RECEBIDO POR KG VIVO',
                    kiloSymbol: 'R$/Kg',
                  },
                  {
                    parameter: 'PREÇO RECEBIDO POR KG VIVO + COND CUSTO',
                    kiloSymbol: 'R$/Kg',
                  },
                  {
                    parameter: 'PREÇO BASE ALEGRA C/ 10% BONF.',
                    kiloSymbol: 'R$/Kg',
                  },
                  {
                    parameter: '',
                    kiloSymbol: '',
                  },
                  {
                    parameter: 'RESULT - RENDIMENTO CARCAÇA',
                    kiloSymbol: 'Kg',
                  },
                  {
                    parameter: 'DIFERENÇA DE PESO CARCAÇA',
                    kiloSymbol: 'Kg',
                  },
                  {
                    parameter: 'RESULT - SANIDADE',
                    kiloSymbol: 'Kg',
                  },
                  {
                    parameter: 'COND. RECEBIDO NO CUSTO',
                    kiloSymbol: 'Kg',
                  },
                  {
                    parameter: 'COND. DESCONTADOS NA INDÚSTRIA',
                    kiloSymbol: 'Kg',
                  },
                ]}
                isDontHaveMaximumWidth
                getValues={economyList.map(row => [
                  row.NR_TATUAGEM,
                  row.VL_ECONOMICO_TOTAL_RES,
                  '',
                  row.VL_CARCACA_TOTAL_RES,
                  row.VL_CARCACA_PADRAO_RES,
                  row.VL_CARCACA_EMBUT_RES,
                  row.VL_CARCACA_GRAXA_RES,
                  '',
                  row.VL_CARCACA_PADRAO_BONIF_RES,
                  row.VL_FIXA_BONIF_RES,
                  row.VL_CARCACA_BONIF_RES,
                  row.VL_CHECKLIST_BONIF_RES,
                  row.VL_PROG_GESTAO_BONIF_RES,
                  row.VL_SCORE_LIMPEZA_RES,
                  '',
                  row.VL_ANTECIPACAO_ABATE_RES,
                  row.VL_CONS_TECNICA_RES,
                  row.VL_EQUIPE_CARREGAMENTO_RES,
                  '',
                  row.VL_PRECO_KG_RES,
                  row.VL_PRECO_RECEBIDO_RES,
                  row.VL_PRECO_BASE_ALEGRA_RES,
                  '',
                  row.VL_REND_CARCACA_RES,
                  row.QT_PESO_DIFERENCA_CARCACA_RES,
                  row.VL_SANIDADE_RES,
                  row.VL_COND_RECEBIDO_CUSTO_RES,
                  row.VL_TOTAL_PERDA_RES,
                ])}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {summariesList.map((sumItem, sumIndex) => (
              <CollapsibleTatto
                key={`tattos-${sumIndex}`}
                sumItem={sumItem}
                sumIndex={sumIndex}
                receptionList={receptionList}
                inspectionList={inspectionList}
                typefication={typefication}
              />
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default withTranslation()(SlaughterReport);
