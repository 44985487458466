import Grid from '@mui/material/Grid/Grid';
import GenericButtonWhiteWithYellow from 'components/GenericButtonWhiteWithYellow';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import LinkedCards from 'components/LinkedCards';
import QuoteComponentsTable from 'components/QuoteComponentsTable';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getCardsByCategory } from 'shared/util/card';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import MainPageTitle from '../../../components/MainPageTitle';
import CategoryNames from 'model/enums/categorys-names';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import swineService from 'services/swine-service';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';

const Swine = ({ t }: WithTranslation) => {
  const awardTableHeader = [t('table.slaughterSummary'), ''];
  const history = useHistory();

  const [averageInfo, setAverageInfo] = useState([
    {
      analysisType: t('swine.averageLivePig'),
      averagePool: '--',
    },
    {
      analysisType: t('swine.averageCarcassYield'),
      averagePool: '--',
    },
    {
      analysisType: t('swine.totalBonus'),
      averagePool: '--',
    },
    {
      analysisType: t('swine.totalReult'),
      averagePool: '--',
    },
    {
      analysisType: t('swine.priceReceived'),
      averagePool: '--',
    },
  ]);

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const changeToBrNumber = (value: number) => {
    return parseFloat(value.toFixed(2)).toLocaleString('pt-br', { minimumFractionDigits: 2 });
  };

  const getInfos = async () => {
    const result = await swineService.getPrevSummary(getUserRegistration);
    if (result.length > 0) {
      setAverageInfo([
        {
          analysisType: t('swine.averageLivePig'),
          averagePool: changeToBrNumber(result[0]?.A_QT_PESO_MEDIO_CONSID_RES),
        },
        {
          analysisType: t('swine.averageCarcassYield'),
          averagePool: changeToBrNumber(result[0]?.A_PC_REND_CARCACA_RES),
        },
        {
          analysisType: t('swine.totalBonus'),
          averagePool: changeToBrNumber(result[0]?.A_TOTAL_BONIF_CARCACA_PADRAO),
        },
        {
          analysisType: t('swine.totalReult'),
          averagePool: changeToBrNumber(result[0]?.A_TOTAL_REND_CARCACA_SANIDADE),
        },
        {
          analysisType: t('swine.priceReceived'),
          averagePool: changeToBrNumber(result[0]?.A_VL_PRECO_KG_RES),
        },
      ]);
    }
  };

  useEffect(() => {
    getInfos();
  }, []);

  const footerTable = (
    <StyledTableData>
      <GenericButtonWhiteWithYellow
        onClick={() => history.push('/pecuaria-suinos/relatorio-abate')}
        textButton={t('global.button.fullReport')}
        width={265}
      />
    </StyledTableData>
  );

  return (
    <Grid container spacing={2}>
      <Grid item md={12} lg={6}>
        <StyledPageTitleContainer margin={'10px 0 25px 0'}>
          <MainPageTitle fontSize={30} text={t('livestock.livestockSwine')} />
        </StyledPageTitleContainer>

        <StyledCardsContainer>
          <LinkedCards listCardNames={getCardsByCategory(CategoryNames.PORCINE)} spacingBetweenCards={25} />
        </StyledCardsContainer>

        <StyledTableContainer>
          <GenericTableBlueHeader headerItems={awardTableHeader} tableItems={averageInfo} footer={footerTable} />
        </StyledTableContainer>
      </Grid>

      <Grid item md={12} lg={6}>
        <StyleQuotableContainer>
          <QuoteComponentsTable />
        </StyleQuotableContainer>
      </Grid>
    </Grid>
  );
};

const StyledCardsContainer = styled.div`
  max-width: 525px;
  margin: 37px 0;
`;

const StyleQuotableContainer = styled.div`
  display: flex;

  @media (min-width: 1199.98px) {
    justify-content: end;
    margin-top: 90px;
  }
`;

const StyledTableContainer = styled.div`
  max-width: 525px;
  margin-bottom: 30px;
`;

const StyledTableData = styled.td.attrs({
  colSpan: 2,
})`
  border-radius: 0 0 16px 16px;
`;

export default withTranslation()(Swine);
