import { apiCore } from 'api/api';
import { AxiosResponse } from 'axios';
import { IAuthRequest, IDataProductsSalesAuthorization, IHistoryAuthData } from 'model/agricultural-sales-authorization';
import { IntendedPayment } from 'model/intended-payment';
import { sendLog } from 'shared/util/api-utils';

export const salesAuthorizationApi = () => {
  const getMonths = (): Promise<AxiosResponse<string[]>> => {
    sendLog('Consultando opções de meses', `/AgricolaAutorizacao/MesesPossiveisPretendidos`, JSON.stringify({}), 'get');
    return apiCore.get<string[]>(`/AgricolaAutorizacao/MesesPossiveisPretendidos`);
  };

  const getProductsOptions = (register: string): Promise<AxiosResponse<IDataProductsSalesAuthorization[]>> => {
    sendLog('Consultando opções de produtos', `/AgricolaAutorizacao/Produtos_Saldo/?cd_pessoa=${register}`, JSON.stringify({}), 'get');
    return apiCore.get<IDataProductsSalesAuthorization[]>(`/AgricolaAutorizacao/Produtos_Saldo/?cd_pessoa=${register}`);
  };

  const sendSalesAuthorization = (salesAuth: IAuthRequest[]) => {
    sendLog('Criando nova autorizaação de vendas', `/AgricolaAutorizacao/Autorizar/?ie_sistema=A`, JSON.stringify(salesAuth), 'post');
    return apiCore.post<string>(`/AgricolaAutorizacao/Autorizar/?ie_sistema=A`, salesAuth);
  };

  const getHistoryOfSalesAuth = (register: string): Promise<AxiosResponse<IHistoryAuthData[]>> => {
    sendLog(
      'Consultando historrico de autorização de vendas',
      `/AgricolaAutorizacao/Consultar/?cd_pessoa=${register}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IHistoryAuthData[]>(`/AgricolaAutorizacao/Consultar/?cd_pessoa=${register}`);
  };

  const cancelSalesAuth = (
    register: string,
    authNumber: number,
    bindingNumber: number,
    motivation: string
  ): Promise<AxiosResponse<string>> => {
    sendLog(
      'Cancelamento de autorização de vendas',
      `/AgricolaAutorizacao/Cancelar/?cd_pessoa=${register}&nr_opcao_venda=${authNumber}&nr_vinculacao=${bindingNumber}`,
      JSON.stringify({ motivo: motivation }),
      'post'
    );
    return apiCore.post<string>(
      `/AgricolaAutorizacao/Cancelar/?cd_pessoa=${register}&nr_opcao_venda=${authNumber}&nr_vinculacao=${bindingNumber}`,
      { motivo: motivation }
    );
  };

  const getIntendedPayment = (cd_safra: string): Promise<AxiosResponse<IntendedPayment[]>> => {
    sendLog(
      'Consultando opções de meses de pagamentos pretendidos',
      `/AgricolaAutorizacao/MesesPagamentoPretendido?cd_safra=${cd_safra}`,
      JSON.stringify({}),
      'get'
    );

    return apiCore.get<IntendedPayment[]>(`/AgricolaAutorizacao/MesesPagamentoPretendido?cd_safra=${cd_safra}`);
  };

  const getIntendedPayment2 = (cd_safra: string, tp_autorizacao: string): Promise<AxiosResponse<IntendedPayment[]>> => {
    sendLog(
      'Consultando opções de meses de pagamentos pretendidos',
      `/AgricolaAutorizacao/MesesPagamentoPretendido2?cd_safra=${cd_safra}&tp_autorizacao=${tp_autorizacao}`,
      JSON.stringify({}),
      'get'
    );

    return apiCore.get<IntendedPayment[]>(
      `/AgricolaAutorizacao/MesesPagamentoPretendido2?cd_safra=${cd_safra}&tp_autorizacao=${tp_autorizacao}`
    );
  };

  return {
    getMonths,
    getProductsOptions,
    sendSalesAuthorization,
    getHistoryOfSalesAuth,
    cancelSalesAuth,
    getIntendedPayment,
    getIntendedPayment2,
  };
};

export default salesAuthorizationApi();
