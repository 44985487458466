import bcrypt from 'bcryptjs';
import FilterCard from 'components/FilterCard';
import Loading from 'components/loading/loading';
import MainPageTitle from 'components/MainPageTitle';
import { isEmpty } from 'lodash';
import md5 from 'md5';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { apiBiEmbed } from '../../../api/api';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const MilkIndicators = ({ t }: WithTranslation) => {
  const [reports, setReports] = useState<any[]>([]);
  const [selectedCard, setSelectedCard] = useState<string>();
  const [iFrameReport, setIFrameReport] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    apiBiEmbed.get('/BiList/GetBiList').then(response => {
      const filteredReports = response.data.sistemas
        .filter((sistema: any) => sistema.idSistema === 1)
        .flatMap((sistema: any) => sistema.areas.filter((area: any) => area.idSistemaArea === 1).flatMap((area: any) => area.relatorios));
      setReports(filteredReports);
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedCard)) {
      const selectedReport = reports.find(report => report.nomeSistemaAreaReport === selectedCard);
      if (selectedReport) {
        setIFrameReport(selectedReport.reportUrl);
      }
    }
  }, [selectedCard, reports]);

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  return (
    <StyleContainer>
      <StyledPageTitleContainer margin={'10px 0 25px 0'} style={{ display: 'flex' }}>
        <MainPageTitle fontSize={30} text={t('livestock.milkIndicators')} />
      </StyledPageTitleContainer>

      <StyleCardsContainer>
        <StyleInnerContainer>
          {reports.map((report, index) => (
            <FilterCard
              text={report.nomeSistemaAreaReport}
              onClick={() => {
                setSelectedCard(report.nomeSistemaAreaReport);
                setIFrameReport('');
              }}
              isActive={report.nomeSistemaAreaReport === selectedCard}
              key={`card-${index}`}
            />
          ))}
        </StyleInnerContainer>
      </StyleCardsContainer>

      {isLoading && (
        <StyledLoadingContainer>
          <Loading />
        </StyledLoadingContainer>
      )}

      {!isEmpty(iFrameReport) && <StyledIframe src={iFrameReport} onLoad={() => setIsLoading(false)} />}
    </StyleContainer>
  );
};

const StyleContainer = styled.div`
  position: relative;
`;

const StyleCardsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyleInnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 75vh;
  margin-top: 100px;
`;

const StyledLoadingContainer = styled.div`
  position: absolute;
  left: 48.5%;
  top: 190px;
`;

export default withTranslation()(MilkIndicators);
