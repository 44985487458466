import { WithTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { ReactComponent as PauseIconSvg } from '../../images/frisia/icons/blue-and-white-round-pause-icon.svg';

export const Equalizer = styled.div`
  width: 210px;
  #seek-slider {
    top: 0px;
    position: absolute;
    width: 200px;
    max-height: 2px;
    ::-webkit-slider-thumb {
      max-height: 2px;
    }
    ::-webkit-slider-runnable-track {
      width: 100%;
      height: 2px !important;
      cursor: pointer;
    }
    :focus::-webkit-slider-runnable-track {
    }
    ::-ms-track {
      width: 100%;
      height: 2px !important;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      border-width: 16px 0;
      color: transparent;
    }
    ::-ms-fill-lower {
      background: #2a5299;
    }
    :focus::-ms-fill-lower {
      background: #2a5299;
    }
    ::-ms-fill-upper {
      background: #2a5299;
    }
    :focus::-ms-fill-upper {
      background: #367ebd;
    }
  }

  #equalizer-btn {
    display: flex;
    padding: 35px;
    height: 50px;
    width: 200px;
    transition: 0.85s;
  }

  .equalizer-btn:hover {
    cursor: pointer;
    border: 1px solid #2a5299;
  }

  .equalizer {
    width: 200px;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .first-bar,
  .second-bar,
  .third-bar,
  .fourth-bar,
  .fifth-bar,
  .sixth-bar,
  .seventh-bar,
  .eighth-bar,
  .ninth-bar,
  .tenth-bar,
  .eleventh-bar,
  .twelfth-bar,
  .thirteenth-bar,
  .fourteenth-bar,
  .fifteenth-bar,
  .sixteenth-bar,
  .seventeenth-bar,
  .eighteenth-bar,
  .nineteenth-bar,
  .twentieth-bar,
  .twenty-first-bar,
  .twenty-second-bar,
  .twenty-third-bar,
  .twenty-fourth-bar {
    display: inline-block;
    width: 1px;
    transition: 1.6s;
    vertical-align: middle;
    background-image: linear-gradient(#2a5299, #2a5299);
  }

  .playEqualizer {
    li {
      animation: equalize 15s 0s infinite;
      animation-timing-function: linear;
    }
  }

  .first-bar {
    height: 15px;
    width: 0.97869873046875px;
    animation-delay: -0.9s !important;
  }
  .second-bar {
    width: 0.85809326171875px;
    height: 30px;
    animation-delay: -1.9s !important;
  }

  .third-bar {
    width: 0.97869873046875px;
    height: 9px;
    animation-delay: -2.9s !important;
  }
  .fourth-bar {
    width: 0.85809326171875px;
    height: 1px;
    animation-delay: -3.9s !important;
  }
  .fifth-bar {
    width: 0.85809326171875px;
    height: 1px;
    animation-delay: -4.9s !important;
  }

  .sixth-bar {
    width: 0.84881591796875px;
    height: 29px;
    animation-delay: -5.9s !important;
  }

  .seventh-bar {
    width: 0.84881591796875px;
    height: 29px;
    animation-delay: -6.9s !important;
  }
  .eighth-bar {
    width: 1.4573974609375px;
    height: 31px;
    animation-delay: -7.9s !important;
  }
  .ninth-bar {
    width: 0.97869873046875px;
    height: 7px;
    animation-delay: -8.9s !important;
  }

  .tenth-bar {
    width: 0.811309814453125px;
    height: 23px;
    animation-delay: -9.9s !important;
  }

  .eleventh-bar {
    width: 0.811309814453125px;
    height: 23px;
    animation-delay: -10.9s !important;
  }

  .twelfth-bar {
    width: 0.67px;
    height: 7px;
    animation-delay: -11.9s !important;
  }
  .thirteenth-bar {
    width: 0.97869873046875px;
    height: 16px;
    animation-delay: -12.9s !important;
  }
  .fourteenth-bar {
    width: 0.67px;
    height: 7px;
    animation-delay: -13.9s !important;
  }
  .fifteenth-bar {
    width: 0.97869873046875px;
    height: 11px;
    animation-delay: -14.9s !important;
  }
  .sixteenth-bar {
    width: 0.902099609375px;
    height: 27px;
    animation-delay: -15.9s !important;
  }
  .seventeenth-bar {
    width: 1.457489013671875px;
    height: 40px;
    animation-delay: -16.9s !important;
  }
  .eighteenth-bar {
    width: 0.97869873046875px;
    height: 2px;
    animation-delay: -17.9s !important;
  }
  .nineteenth-bar {
    width: 0.811309814453125px;
    height: 23px;
    animation-delay: -18.9s !important;
  }
  .twentieth-bar {
    width: 0.95721435546875px;
    height: 39px;
    animation-delay: -19.9s !important;
  }
  .twenty-first-bar {
    width: 0.933807373046875px;
    height: 34px;
    animation-delay: -20.9s !important;
  }
  .twenty-second-bar {
    width: 0.85809326171875px;
    height: 30px;
    animation-delay: -21.9s !important;
  }
  .twenty-third-bar {
    width: 0.97869873046875px;
    height: 11px;
    animation-delay: -22.9s !important;
  }
  .twenty-fourth-bar {
    width: 0.811309814453125px;
    height: 23px;
    animation-delay: -23.9s !important;
  }

  ul {
    padding: 0;
    margin: 0;
    min-width: 160px;
    width: 1px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  @keyframes equalize {
    0% {
      height: 40px;
    }
    4% {
      height: 34px;
    }
    8% {
      height: 32px;
    }
    12% {
      height: 22px;
    }
    16% {
      height: 12px;
    }
    20% {
      height: 22px;
    }
    24% {
      height: 32px;
    }
    28% {
      height: 0px;
    }
    32% {
      height: 32px;
    }
    36% {
      height: 40px;
    }
    40% {
      height: 12px;
    }
    44% {
      height: 32px;
    }
    48% {
      height: 40px;
    }
    52% {
      height: 22px;
    }
    56% {
      height: 2px;
    }
    60% {
      height: 22px;
    }
    64% {
      height: 40px;
    }
    68% {
      height: 22px;
    }
    72% {
      height: 40px;
    }
    76% {
      height: 12px;
    }
    80% {
      height: 40px;
    }
    84% {
      height: 30px;
    }
    88% {
      height: 40px;
    }
    92% {
      height: 12px;
    }
    96% {
      height: 40px;
    }
    100% {
      height: 40px;
    }
  }
`;

export const TitleContainer = styled.span`
  text-align: left;
  font: normal normal medium 11px/18px Montserrat;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-size: 11px;
  display: flex;
`;

export const VideoCardContainer = styled.div`
  width: 149px;
  max-width: 149px;
  height: 153px;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1d1d1d29;
  border-radius: 8px;
  opacity: 1;
  display: flex !important;
  flex-direction: column;
  margin: 18px;
  margin-bottom: 38px;
  cursor: pointer;
  iframe {
    pointer-events: none;
    svg {
      display: none;
    }
    .play rounded-box state-paused {
      display: none;
    }
  }
  div {
    font-size: 11px;
    color: #2a5299;
    font-weight: bold;
    padding: 4px;
    text-align: left;
    margin: 3px;
  }
`;

export const CategoryCardContainer = styled.div`
  width: 177px;
  height: 65px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #656565;
  :hover {
    background-color: ${({ theme }) => theme.color.frisiaPrimary};
    color: #d7cc27;
  }
  img {
    width: 66px;
    height: 65px;
    border-radius: 8px 0px 0px 8px;
    opacity: 1;
  }
  div {
    width: 83px;
    font-size: 12px;
    letter-spacing: 0px;
    opacity: 1;
    width: 100%;
    text-align: center;
  }
`;

export const PlayerContainer = styled.div`
  width: 490px;
  height: 89px;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  margin-bottom: 31px !important;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 16px;
  margin-right: 31px !important;
  padding: 5px;
  padding-top: 12px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 18px;
  margin-bottom: 7px;
  section {
    height: 40px;
    opacity: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .play,
  .stop {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 34px;
    height: 34px;
    border-radius: 20px;
    box-shadow: 0px 2px 48px #0000000f;
    border: 1px solid #2a5299;
    opacity: 1;
  }
  .play {
    padding-left: 12px;
  }
`;
