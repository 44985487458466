import TextField from '@mui/material/TextField';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import { AccountType } from 'model/account';
import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IRootState } from 'reducer';
import { setSelectedUser } from 'reducer/authentication/actions';
import { compose } from 'redux';
import SearchAccountService from 'services/search-account-service';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const Support = props => {
  const { t } = useTranslation();
  const [register, setRegister] = useState('');
  const [name, setName] = useState('');
  const [selected, setSelected] = useState<AccountType>();

  const [accounts, setAccounts] = useState<AccountType[]>([]);

  const searchAccount = async () => {
    if (name !== '') {
      const response = await SearchAccountService.byName(name);
      setAccounts(response);
    }
    if (register !== '') {
      const response = await SearchAccountService.byRegister(register);
      setAccounts(response);
    }
  };

  useEffect(() => {
    if (selected) {
      props.setSelected(selected);
    }
  }, [selected]);

  return (
    <PageContainer>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('mainMenu.support')} />
      </StyledPageTitleContainer>
      <FiltersContainer>
        <InputsContainer>
          <StyledInputText
            label={t('login.registration')}
            onChange={e => setRegister(e.target.value)}
            placeholder={t('support.placeholder1')}
            variant="outlined"
            type={'text'}
          />
          <StyledInputText
            style={{ width: '350px' }}
            label={t('livestock.name')}
            placeholder={t('support.placeholder2')}
            onChange={e => {
              setName(e.target.value);
            }}
            variant="outlined"
            type={'text'}
          />
          <StyledButton
            color="frisiaPrimary"
            onClick={() => {
              searchAccount();
            }}
            style={{ width: '172px', alignSelf: 'center' }}
          >
            <span>{t('support.search')}</span>
          </StyledButton>
        </InputsContainer>
      </FiltersContainer>
      {accounts.length > 0 && (
        <CardContainer>
          <b>{t('support.cooperator')}</b>
          <div style={{ height: '2px', width: '100%', backgroundColor: 'yellow' }} />
          {accounts.map((item, index) => (
            <StyledRow key={index}>
              <div>
                <div>
                  <span>{t('support.register')}: </span>
                  {item.CD_PESSOA}{' '}
                </div>
                <div>
                  <span>{t('support.name')}: </span>
                  {item.NM_PESSOA}{' '}
                </div>
              </div>
              <div>
                <div>
                  <span>{t('support.cpf')}: </span>
                  {item.CD_CPF}{' '}
                </div>
                <div>
                  <span>{t('support.situation')}: </span>
                  {item.CD_SITUACAO_PESSOA === 'A' ? 'Ativo' : item.CD_SITUACAO_PESSOA}{' '}
                </div>
              </div>
              {(item.CD_SITUACAO_PESSOA === 'Ativo' || item.CD_SITUACAO_PESSOA === 'A') && (
                <StyledButton
                  color="frisiaPrimary"
                  onClick={() => {
                    setSelected(item);
                  }}
                  style={{ color: 'white', width: '172px', alignSelf: 'center', marginTop: '10px', height: '53px' }}
                >
                  <span>{selected === item ? t('global.button.selected') : t('global.button.select')}</span>
                </StyledButton>
              )}
            </StyledRow>
          ))}
        </CardContainer>
      )}
    </PageContainer>
  );
};

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 65px;
  margin: 30px 0px;
  margin-bottom: 42px;
  justify-content: space-between;
  div {
    width: fit-content;
    font-weight: bold;
    color: #404040;
  }
  div > span {
    color: #2a5299;
    font-weight: initial;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  justify-content: space-between;
`;

const InputsContainer = styled.div`
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  max-width: 830px;
  margin-bottom: 15px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  min-width: 445px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  b {
    align-self: self-start;
    margin: 20px 0px;
  }
`;

const StyledInputText = styled(TextField)`
  div {
    font-family: ${({ theme }) => theme.font.montserratRegular};
    height: 53px;
    min-width: 200px;
    fieldset {
      border: 2px solid #4e6ba7 !important;
    }
  }
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary} !important;
    letter-spacing: 0.4px;
    font-size: 13.2px;
    line-height: 2.2em;
    padding-left: 7px;
  }
  input {
    font-size: 16px;
    color: #656565;
    &::placeholder {
      opacity: 1;
    }
  }
`;

const mapStateToProps = ({ authentication }: IRootState) => ({
  selectedInfo: authentication.selectedInfo,
});

const mapDispatchToProps = {
  setSelected: setSelectedUser,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Support) as React.ComponentType<any>;
