import { ITypeTable } from 'model/type-table';
import styled from 'styled-components';
import { StyledTable, StyledTableRow } from 'styled-components/StyledForTables';

const GenericTableWhiteHeader = ({ headerItems, tableItems, isInvertLineColor, isDontHaveMaximumWidth, style }: ITypeTable) => {
  const getValues = tableItems.map(row => Object.values(row));
  const lastLine = getValues.length - 1;

  return (
    <StyledTable style={style} isInvertLineColor={isInvertLineColor} isDontHaveMaximumWidth={isDontHaveMaximumWidth}>
      {headerItems != null && (
        <thead>
          <StyledTableRowHeader>
            {headerItems.map((headerItem, index) => (
              <th key={`header-item-${index}`}>{headerItem}</th>
            ))}
          </StyledTableRowHeader>
        </thead>
      )}
      <tbody>
        {getValues.map((tableRow, indexRow) => (
          <StyledTableRow key={`row-${indexRow}`} className={`${indexRow === lastLine ? 'last-line' : ''}`}>
            {tableRow.map((tableItem, indexItem) => (
              <td key={`item-${indexItem}`}>{tableItem}</td>
            ))}
          </StyledTableRow>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledTableRowHeader = styled.tr`
  height: 50px;
  background-color: white;
  color: #032762;

  th {
    text-align: center;
  }

  th:nth-child(1) {
    text-align: left;
    padding-left: 20px;
    border-radius: 16px 0 0 0;
  }

  th:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    border-radius: 0 16px 0 0;
  }
`;

export default GenericTableWhiteHeader;
