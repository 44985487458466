import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import costsApi from 'api/agricultural/costs-api';
import { IBanks, ICostInfo, ICostOptionSend, IGroupsCost, IHarvestCost, IInfoCost } from 'model/agricultural-costs';
import toastUtils from 'shared/util/toast-utils';
import i18n from 'config/i18n';
import { IReturnMessage } from 'model/financial';

export const costsService = () => {
  const getCrop = async () => {
    try {
      const result: AxiosResponse<IHarvestCost[]> = await costsApi.getCrop();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getInfo = async (register: string, crop: string) => {
    try {
      const result: AxiosResponse<IInfoCost> = await costsApi.getInfo(register, crop);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getGroups = async (register: string, crop: string) => {
    try {
      const result: AxiosResponse<IGroupsCost[]> = await costsApi.getGroups(register, crop);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getBanks = async () => {
    try {
      const result: AxiosResponse<IBanks[]> = await costsApi.getBanks();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getCostInfo = async (agroup: string, purpose: string) => {
    try {
      const result: AxiosResponse<ICostInfo[]> = await costsApi.getCostInfo(agroup, purpose);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const createCostOption = async (costOption: ICostOptionSend): Promise<IReturnMessage> => {
    try {
      const result = await costsApi.createCostOption(costOption);
      toastUtils.emitSuccessToast(i18n.t('global.successMessage.orderCreated'));
      return Promise.resolve(result.data);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.orderCreated'));
      return Promise.reject(response);
    }
  };

  const cancelCostOption = async (planId: string): Promise<IReturnMessage> => {
    try {
      const result = await costsApi.cancelCostOption(planId);
      if (result.status === HttpStatus.OK && result.data != null) {
        toastUtils.emitSuccessToast(result.data.Message);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.orderDeleted'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.orderDeleted'));
      return Promise.reject(response);
    }
  };

  return {
    getCrop,
    getInfo,
    getGroups,
    getBanks,
    getCostInfo,
    createCostOption,
    cancelCostOption,
  };
};

export default costsService();
