import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import AuthUtils, { IdentificationKeyLocalStorage } from 'shared/util/auth-utils';
import { AuthenticationActionTypes, AuthenticationState } from './types';

export const initialState: AuthenticationState = {
  getSessionStatus: HttpRequestStatus.NOOP,
  loginStatus: HttpRequestStatus.NOOP,
  isAuthenticated: false,
};

const reducer: Reducer<AuthenticationState> = (state = initialState, action) => {
  switch (action.type) {
    case AuthenticationActionTypes.LOGIN_REQUEST: {
      return { ...state, loginStatus: HttpRequestStatus.ON_GOING };
    }
    case AuthenticationActionTypes.CORE_REQUEST: {
      return { ...state, coreInfo: action.payload };
    }
    case AuthenticationActionTypes.SELECTED: {
      return { ...state, selectedInfo: action.payload };
    }
    case AuthenticationActionTypes.LIST: {
      return { ...state, listOfPages: action.payload };
    }
    case AuthenticationActionTypes.LOGIN_SUCCESS: {
      return { ...state, loginStatus: HttpRequestStatus.SUCCESS, isAuthenticated: true };
    }
    case AuthenticationActionTypes.LOGIN_ERROR: {
      return { ...state, loginStatus: HttpRequestStatus.ERROR, error: action.payload };
    }
    case AuthenticationActionTypes.GET_SESSION_REQUEST: {
      return { ...state, getSessionStatus: HttpRequestStatus.ON_GOING };
    }
    case AuthenticationActionTypes.GET_SESSION_SUCCESS: {
      return { ...state, getSessionStatus: HttpRequestStatus.SUCCESS, isAuthenticated: true, accountInfo: action.payload };
    }
    case AuthenticationActionTypes.GET_SESSION_ERROR: {
      return { ...state, getSessionStatus: HttpRequestStatus.ERROR, error: action.payload };
    }
    case AuthenticationActionTypes.LOGOUT_REQUEST: {
      AuthUtils.removeCore();
      AuthUtils.removeToken(IdentificationKeyLocalStorage.INDICATORS_TOKEN);
      return { ...state, getSessionStatus: HttpRequestStatus.NOOP, loginStatus: HttpRequestStatus.NOOP, isAuthenticated: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as authenticationReducer };
