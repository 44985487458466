import PigDeliveryReportApi from 'api/swine/pig-delivery-report-api';
import ButtonDownload from 'components/ButtonDownload';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const PigDeliveryReport = ({ t }: WithTranslation) => {
  const [reportUrl, setReportUrl] = useState<string>();

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  useEffect(() => {
    setReportUrl(PigDeliveryReportApi.getReport(getUserRegistration));
  }, []);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('card.pigDeliveryReport')} />
      </StyledPageTitleContainer>
      <LoadingContainerScreen isLoading={reportUrl === undefined}>
        {reportUrl && (
          <>
            <ButtonDownload style={{ marginLeft: 'auto' }} href={reportUrl} />
            <iframe
              sandbox="allow-scripts allow-popups allow-forms allow-top-navigation"
              style={{ width: '100%', height: '800px' }}
              src={reportUrl}
            />
          </>
        )}
      </LoadingContainerScreen>
    </>
  );
};

export default withTranslation()(PigDeliveryReport);
