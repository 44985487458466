import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import MyStockCard from 'components/MyStockCard';
import WhiteAndBlueFilterCard from 'components/WhiteAndBlueFilterCard';
import FilterCardType from 'model/enums/FilterCardType';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import agriculturalService from 'services/agricultural/agricultural-service';
import styled from 'styled-components';
import StyledCardsFilterContainer from 'styled-components/StyledCardsFilterContainer';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { ReactComponent as WheatIconSvg } from '../../images/frisia/icons/trigo.svg';
import { ReactComponent as BarleyIconSvg } from '../../images/frisia/icons/blue-barley-icon.svg';
import { ReactComponent as BeanIconSvg } from '../../images/frisia/icons/bean-svgrepo-com.svg';
import { ReactComponent as CornIconSvg } from '../../images/frisia/icons/blue-corn-icon.svg';
import { ReactComponent as ForagerIconSvg } from '../../images/frisia/icons/blue-forager-icon.svg';
import { ReactComponent as SoyIconSvg } from '../../images/frisia/icons/blue-soy-icon.svg';
import { ReactComponent as ThreeDotsIconSvg } from '../../images/frisia/icons/blue-three-dots-icon.svg';
import { ReactComponent as OneDotIconSvg } from '../../images/frisia/icons/blue-checked-checkbox-icon.svg';
import { ITypeMyStockCard, ITypeProductGroup, ITypeProducts } from 'model/product-my-stock';
import { ICropStock } from 'model/schedule-balance';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import ButtonDownload from 'components/ButtonDownload';
import agriculturalApi from 'api/agricultural/agricultural-api';

const MyStock = ({ t }: WithTranslation) => {
  const [selectedFilterCard, setSelectedFilterCard] = useState<ITypeMyStockCard>();
  const [chosenYear, setChosenYear] = useState<number>();
  const [dataList, setDataList] = useState<ITypeProductGroup[]>([]);
  const [cards, setCards] = useState<ITypeMyStockCard[]>([]);

  const [productsListsByYears, setProductsListsByYears] = useState<ICropStock[]>([]);
  const [reportLink, setReportLink] = useState<string>('');

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getData = (registration: number, years: string) => {
    agriculturalService.getMyStockData(registration.toString(), years).then(response => {
      setDataList(response);
    });
  };

  useEffect(() => {
    setSelectedFilterCard(undefined);
    if (chosenYear && getUserRegistration != null) {
      getData(getUserRegistration, chosenYear.toString());
    }
  }, [chosenYear]);

  useEffect(() => {
    agriculturalService.getCropForMyStock().then(response => {
      setProductsListsByYears(response);
    });
  }, []);

  const generateCards = async () => {
    setCards([]);
    let productsTogether: ITypeProducts[] = [];
    dataList.map(item => {
      let icon = <OneDotIconSvg style={{ width: '40px' }} />;
      if (item.DS_LINHA_PRODUTO.includes(FilterCardType.BEAN)) {
        icon = <BeanIconSvg style={{ width: '40px' }} />;
      }
      if (item.DS_LINHA_PRODUTO.includes(FilterCardType.WHEAT) || item.DS_LINHA_PRODUTO.includes(FilterCardType.WHEATGRASS)) {
        icon = <WheatIconSvg style={{ width: '40px' }} />;
      }
      if (item.DS_LINHA_PRODUTO.includes(FilterCardType.SOY)) {
        icon = <SoyIconSvg />;
      }
      if (item.DS_LINHA_PRODUTO.includes(FilterCardType.CORN)) {
        icon = <CornIconSvg />;
      }
      if (item.DS_LINHA_PRODUTO.includes(FilterCardType.BARLEY)) {
        icon = <BarleyIconSvg />;
      }
      if (item.DS_LINHA_PRODUTO.includes(FilterCardType.FORAGERS)) {
        icon = <ForagerIconSvg style={{ width: '40px' }} />;
      }
      // let novoCard = { icon: icon, text: item.DS_LINHA_PRODUTO, productsList: item?.CULTURAS[0]?.PRODUTOS };
      let novoCard = { icon: icon, text: item.DS_LINHA_PRODUTO, productsList: item?.CULTURAS.map(cultuta => cultuta.PRODUTOS).flat() };
      setCards(cards => [...cards, novoCard]);
      productsTogether.push(...item?.CULTURAS.map(cultuta => cultuta.PRODUTOS).flat());
    });
    setCards(cards => [{ icon: <ThreeDotsIconSvg />, text: t('agricultural.all'), productsList: productsTogether }, ...cards]);
  };

  useEffect(() => {
    if (dataList.length > 0) {
      generateCards();
    }
  }, [dataList, chosenYear]);

  const handleMyStockReport = () => {
    if (chosenYear) {
      const reportLinkPath = agriculturalApi.getMyStockReportLink(getUserRegistration.toString(), chosenYear.toString());
      setReportLink(reportLinkPath);
    }
  };

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('agricultural.myAgriculturalStock')} />
      </StyledPageTitleContainer>

      <StyledFilterContainer filterWidth={330}>
        <GenericDropdown
          label={t('agricultural.crop')}
          placeholder={t('livestock.selectHarvest')}
          listShowDropdown={productsListsByYears.map(item => item.NM_SAFRA)}
          getSelectedValue={value => setChosenYear(productsListsByYears.find(item => item.NM_SAFRA === value)?.CD_SAFRA)}
        />
      </StyledFilterContainer>

      <StyledCardsFilterContainer>
        <LoadingContainerScreen isLoading={cards.length === 0 && chosenYear !== undefined}>
          {chosenYear && (
            <StyledButtonDownloadContainer onClick={() => handleMyStockReport()}>
              <ButtonDownload href={reportLink} />
            </StyledButtonDownloadContainer>
          )}
          {cards.map((item, index) => (
            <WhiteAndBlueFilterCard
              isCardActive={item === selectedFilterCard}
              icon={item.icon}
              text={item.text}
              onClick={() => setSelectedFilterCard(item)}
              key={`card-${index}`}
            />
          ))}
        </LoadingContainerScreen>
      </StyledCardsFilterContainer>

      <StyledProductsContainer>
        {selectedFilterCard &&
          selectedFilterCard?.productsList.map((product, index) => <MyStockCard data={product} key={`my-product-${index}`} />)}
      </StyledProductsContainer>
    </>
  );
};

const StyledProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  column-gap: 10px;
  row-gap: 35px;
  margin: 35px 0;
`;

const StyledButtonDownloadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -17px;
  width: 100%;
  justify-content: center;
`;

export default withTranslation()(MyStock);
