import styled from 'styled-components';

interface IProps {
  icon: JSX.Element;
  text: string;
  isCardActive?: boolean;
  onClick: () => void;
}

interface IStyledProps {
  isActive?: boolean;
}

const WhiteAndBlueFilterCard = ({ icon, text, isCardActive, onClick }: IProps) => {
  return (
    <StyledCard onClick={() => onClick()} isActive={isCardActive}>
      {icon}
      <StyledText>{text}</StyledText>
    </StyledCard>
  );
};

const StyledCard = styled.div<IStyledProps>`
  height: 157px;
  width: 157px;
  min-width: 157px;
  padding-top: 17px;
  background-color: ${({ isActive, theme }) => (isActive ? theme.color.frisiaPrimary : '#fcfcfc')};
  font-family: ${({ theme }) => theme.font.arialBold};
  color: ${({ isActive }) => (isActive ? '#D7CC27' : '#183c96')};
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0px 5px 10px #1d1d1d33;
  svg {
    height: 65px;
    ${({ isActive }) => isActive && 'path { fill: #d7cc27; stroke: #d7cc27; } ellipse { fill: #d7cc27;} circle { fill: #d7cc27;}'};
  }
`;

const StyledText = styled.div`
  max-width: 119px;
  margin-top: 17px;
  margin: 11px auto 0 auto;
  line-height: 1.3;
  font-size: 12px;
`;

export default WhiteAndBlueFilterCard;
