import { CSSProperties } from 'react';
import styled from 'styled-components';

interface IProps {
  text: string;
  fontSize: number;
  style?: CSSProperties;
}

interface IStyledProps {
  fontSize: number;
}

const MainPageTitle = ({ text, fontSize, style }: IProps) => {
  return (
    <StyledContainer fontSize={fontSize} style={style}>
      {text}
    </StyledContainer>
  );
};

export default MainPageTitle;

const StyledContainer = styled.div<IStyledProps>`
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ theme }) => theme.color.frisiaPrimaryDark};

  width: 100%;

  text-align: initial;
`;
