import { Grid } from '@mui/material';
import GenericButtonWhiteWithYellow from 'components/GenericButtonWhiteWithYellow';
import GenericDropdown from 'components/GenericDropdown';
import { StyledButton } from 'components/styled-button/StyledButton';
import OptionsCadastralSheet from 'components/OptionsCadastralSheet';
import { IEnvInfo, IHeaderCooperative, IHeaderProperty, IMyProperty, IPropertiesAreas, ISprings } from 'model/registration-form';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import registrationFormService from 'services/registration-form-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import {
  StyledPropertySubtitle,
  StyledRegistrationFormBackground,
  StyledRegistrationFormMainTitleContainer,
  StyledRegistrationFormSubtitle,
  StyledRegistrationFormTitle,
} from 'styled-components/StyledRegistrationForm';
import { ReactComponent as LeavesIconSvg } from '../../images/frisia/icons/blue-leaves-icon.svg';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, ChartOptions, TooltipItem } from 'chart.js';
import ListOfSprings from './listOfSprings';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IForm {
  biodigesterItHas: string;
  capacity: string;
  certificate: string;
  composteiraQuantity: string;
  concreteFloor: string;
  dairyFarming: string;
  dairyFarmingTrat: string;
  deadAnimalComposterItHas: string;
  directWeirRio: string;
  emptyPack: string;
  fullPack: string;
  haveRiver: string;
  inShed: string;
  installationType: string;
  itHas: string;
  localEmptyPack: string;
  localFullPack: string;
  lubricantsPacking: string;
  milkFarming: string;
  mobileWater: string;
  ownPigFarming: string;
  pigFarming: string;
  pigFarmingLicensed: string;
  rio: string;
  runwayTrapiche: string;
  separatorBox: string;
  strand: string;
  useEnergy: string;
  waterBox: string;
  well: string;
  riverName: string;
}

const EnvironmentalProperties = ({ t }: WithTranslation) => {
  const [myProperties, setMyProperties] = useState<IMyProperty[]>([]);
  const [seletectedProperty, setSeletectedProperty] = useState<IMyProperty>();
  const [envInfo, setEnvInfo] = useState<IEnvInfo>();
  const [springsList, setSpringsList] = useState<ISprings[]>([]);
  const [headerInfo, setHeaderInfo] = useState<IHeaderCooperative[]>();
  const [propertyInfo, setPropertyInfo] = useState<IHeaderProperty[]>();
  const [propertyAreaInfo, setPropertyAreaInfo] = useState<IPropertiesAreas[]>([]);

  const [editPage, setEditPage] = useState<boolean>(false);

  const correctWord = (word: string) => {
    if (word.includes('PecuÃ¡ria')) {
      return word.replaceAll('PecuÃ¡ria', 'Pecuária');
    }
    return word;
  };

  useEffect(() => {
    if (getUserRegistration != null) {
      registrationFormService.myProperties(getUserRegistration).then(setMyProperties);
    }
  }, []);

  useEffect(() => {
    if (seletectedProperty) {
      registrationFormService.getEnvInfo(getUserRegistration, seletectedProperty.NR_PROPRIEDADE).then(response => setEnvInfo(response[0]));
      registrationFormService.getSprings(seletectedProperty.NR_PROPRIEDADE).then(setSpringsList);
      registrationFormService
        .getHeaderCooperativeMember(getUserRegistration.toString(), seletectedProperty.NR_PROPRIEDADE)
        .then(setHeaderInfo);
      registrationFormService.getHeaderProperties(getUserRegistration.toString(), seletectedProperty.NR_PROPRIEDADE).then(setPropertyInfo);
      registrationFormService
        .getPropertiesAreas(getUserRegistration.toString(), seletectedProperty.NR_PROPRIEDADE)
        .then(setPropertyAreaInfo);
    }
  }, [seletectedProperty]);

  const methods = useForm<IForm>({
    mode: 'all',
  });

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const handleSubmit = (data: IForm) => {
    if (seletectedProperty) {
      registrationFormService.updateEnvInfo(
        getUserRegistration.toString(),
        seletectedProperty.NR_PROPRIEDADE,
        data.installationType === 'Sim' ? `S` : 'N',
        data.capacity,
        data.inShed === 'Sim' ? `S` : 'N',
        data.concreteFloor === 'Sim' ? `S` : 'N',
        data.lubricantsPacking === 'Q' ? 'Queima' : 'Armazena',
        data.runwayTrapiche === 'Sim' ? `S` : 'N',
        data.separatorBox === 'Sim' ? `S` : 'N',
        data.directWeirRio === 'Sim' ? `S` : 'N',
        data.waterBox === 'Sim' ? `S` : 'N',
        data.mobileWater === 'Sim' ? `S` : 'N',
        data.rio === 'Sim' ? `S` : 'N',
        data.well === 'Sim' ? `S` : 'N',
        data.strand === 'Sim' ? `S` : 'N',
        data.fullPack === 'Sim' ? `S` : 'N',
        data.emptyPack === 'Sim' ? `S` : 'N',
        data.localFullPack === 'Alvenaria' ? 'A' : 'M',
        data.localEmptyPack === 'Alvenaria' ? 'A' : 'M',
        data.itHas === 'Sim' ? `S` : 'N',
        data.certificate === 'Sim' ? `S` : 'N',
        data.ownPigFarming === 'Sim' ? `S` : 'N',
        data.pigFarmingLicensed === 'Sim' ? `S` : 'N',
        data.dairyFarming === 'Sim' ? `S` : 'N',
        data.dairyFarmingTrat === 'Sim' ? `S` : 'N',
        data.biodigesterItHas === 'Sim' ? `S` : 'N',
        data.useEnergy === 'Sim' ? `S` : 'N',
        data.pigFarming === 'Sim' ? `S` : 'N',
        data.milkFarming === 'Sim' ? `S` : 'N',
        data.deadAnimalComposterItHas === 'Sim' ? `S` : 'N',
        data.composteiraQuantity,
        data.haveRiver === 'Sim' ? `S` : 'N',
        data.riverName === 'Não Possui' ? `N` : data.riverName
      );
    }
  };

  const labelsName = [
    t('environment.entered'),
    t('environment.livestock'),
    t('environment.reforestation'),
    t('environment.SISLEG'),
    t('environment.others'),
    t('environment.totalArea'),
  ];
  const labels = ['', '', '', '', '', ''];

  const generateOptions = areaInfo => {
    const options: ChartOptions<'bar'> = {
      indexAxis: 'y' as const,
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: { display: false },
        datalabels: { display: false },
        title: {
          display: true,
          text: t('environment.geralVision'),
        },
      },
      scales: {
        x: {
          grid: { display: false },
          ticks: {
            display: false,
          },
        },
        y: {
          grid: { display: false },
          ticks: {
            display: false,
          },
        },
        A: {
          position: 'left',
          ticks: { color: 'rgba(42, 82, 153)' },
          grid: { display: false },
        },
        B: {
          position: 'right',
          ticks: {
            color: 'rgba(42, 82, 153)',
            callback: function (label, index) {
              const property = [
                areaInfo.MATRICULA_HA_AGRICULTURA ?? 0,
                areaInfo.MATRICULA_HA_PECUARIA ?? 0,
                areaInfo.MATRICULA_HA_REFLORESTAMENTO ?? 0,
                areaInfo.MATRICULA_HA_SISLEG ?? 0,
                areaInfo.MATRICULA_HA_OUTROS ?? 0,
                areaInfo.MATRICULA_HA_AREA_TOTAL ?? 0,
              ];
              return property[index];
            },
          },
          grid: { display: false },
        },
      },
    };
    return options;
  };

  const generateData = areaInfo => {
    const data = {
      labels,
      datasets: [
        {
          yAxisID: 'A',
          data: [
            areaInfo?.MATRICULA_HA_AGRICULTURA ?? 0,
            areaInfo?.MATRICULA_HA_PECUARIA ?? 0,
            areaInfo?.MATRICULA_HA_REFLORESTAMENTO ?? 0,
            areaInfo?.MATRICULA_HA_SISLEG ?? 0,
            areaInfo?.MATRICULA_HA_OUTROS ?? 0,
            areaInfo?.MATRICULA_HA_AREA_TOTAL ?? 0,
          ],
          borderColor: 'rgba(42, 82, 153, 0.001)',
          backgroundColor: 'rgba(42, 82, 153)',
          datalabels: {
            formatter: function (value, context) {
              return value.toString().replace('.', ',');
            },
          },
        },
      ],
    };

    return data;
  };

  return (
    <StyledRegistrationFormBackground>
      <StyledRegistrationFormMainTitleContainer>
        <LeavesIconSvg />
        <StyledRegistrationFormTitle>{t('agricultural.environmental')}</StyledRegistrationFormTitle>
      </StyledRegistrationFormMainTitleContainer>

      {headerInfo && (
        <StyledHeaderContainer>
          <StyledTitleContainer>
            <StyledRegistrationFormSubtitle style={{ marginTop: 0 }}>{headerInfo[0].COOPERADO}</StyledRegistrationFormSubtitle>
            <StyledText>{correctWord(headerInfo[0].TIPO_ATIVIDADE)}</StyledText>
          </StyledTitleContainer>
          <GenericButtonWhiteWithYellow
            onClick={() => setEditPage(!editPage)}
            textButton={t('global.button.updateEnvironmentalRegistration')}
            width={255}
          />
        </StyledHeaderContainer>
      )}

      <StyledFiltersContainer>
        <StyledFilterContainer>
          <GenericDropdown
            label={t('global.field.property')}
            placeholder={t('global.field.filterSelectProperty')}
            listShowDropdown={myProperties}
            keyToShowInDropdown="NM_PROPRIEDADE"
            getSelectedValue={setSeletectedProperty}
          />
        </StyledFilterContainer>
      </StyledFiltersContainer>

      {propertyInfo && (
        <StyledHeaderContainer>
          <StyledTitleContainer>
            <StyledPropertySubtitle style={{ marginTop: 0 }}>{propertyInfo[0]?.PROPRIEDADE}</StyledPropertySubtitle>
            <StyledPropertySubtitle style={{ marginTop: 0 }}>{propertyInfo[0]?.PROPRIEDADE_ENDERECO}</StyledPropertySubtitle>
          </StyledTitleContainer>
        </StyledHeaderContainer>
      )}

      {envInfo && (
        <>
          {!editPage
            ? propertyAreaInfo.map((area, index) => {
                const spring = springsList.find(item => area.MATRICULA === item.DS_REGISTRO_IMOVEL);
                return (
                  <div key={`spring-${index}`}>
                    <StyledProperties>
                      <StyledTitleSpring>
                        {t('environment.register')}
                        <div> {spring?.DS_REGISTRO_IMOVEL}</div>
                      </StyledTitleSpring>
                      <div style={{ display: 'flex' }}>
                        <StyledInfo>
                          {t('environment.owner')}: <div>{area.MATRICULA_PROPRIETARIO}</div>
                        </StyledInfo>
                        <StyledInfo>
                          {t('environment.producer')}: <div>{area.MATRICULA_HA_PRODUTOR}</div>
                        </StyledInfo>
                        <StyledInfo>
                          {t('environment.endorsed')}?: <div>{spring?.IE_RESERVA_LEGAL_AVERBADA === 'S' ? `Sim` : 'Não'}</div>
                        </StyledInfo>
                        <StyledInfo>
                          {t('environment.numberOfSprings')}: <div>{spring?.QT_NASCENTES}</div>
                        </StyledInfo>
                      </div>
                    </StyledProperties>
                    <div key={`labelBarChart-${index}`} style={{ padding: '30px', display: 'flex', justifyContent: 'space-around' }}>
                      <StyledlabelsChart>
                        {labelsName.map((item, interLabelsIndex) => (
                          <div key={`interLabel-${interLabelsIndex}`}>{item}</div>
                        ))}
                      </StyledlabelsChart>
                      <div
                        key={`barChart-${index}`}
                        style={{ borderLeft: 'blue 1px solid', borderBottom: 'blue 1px solid', flex: 0.75, maxHeight: '100%' }}
                      >
                        <Bar options={generateOptions(area)} data={generateData(area)} />
                      </div>
                    </div>
                  </div>
                );
              })
            : propertyAreaInfo.map((area, index) => {
                const spring = springsList.find(item => area.MATRICULA === item.DS_REGISTRO_IMOVEL);
                return <ListOfSprings area={area} spring={spring} key={`spring-editable-${index}`} />;
              })}

          <StyledSubtitle>{t('registrationForm.overviewPropertyAreas')}</StyledSubtitle>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <Grid container columnSpacing={1.5}>
                <Grid item xs={12}>
                  <StyledSubtitle>{t('registrationForm.fuelTank')}</StyledSubtitle>
                </Grid>
                <Grid item xs={6}>
                  <OptionsCadastralSheet
                    isReadOnly={!editPage}
                    numberInput
                    listShowDropdown={[]}
                    inputName="capacity"
                    label={t('registrationForm.capacity')}
                    defaultValue={envInfo.QT_TANQUE_CAPACIDADE.toLocaleString('pt-br')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Subterrâneo', 'Aéreo']}
                    isReadOnly={!editPage}
                    inputName="installationType"
                    label={t('registrationForm.installationType')}
                    defaultValue={envInfo.DS_TANQUE_TIPO}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledSubtitle>{t('registrationForm.machineMaintenance')}</StyledSubtitle>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="concreteFloor"
                    label={t('registrationForm.concreteFloor')}
                    defaultValue={envInfo.IE_MANUTENCAO_PISO_CONCRETO === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="inShed"
                    label={t('registrationForm.inShed')}
                    defaultValue={envInfo.IE_MANUTENCAO_GALPAO === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Queima', 'Armazena']}
                    isReadOnly={!editPage}
                    inputName="lubricantsPacking"
                    label={t('registrationForm.lubricantsPacking')}
                    defaultValue={
                      envInfo.IE_MANUTENCAO_EMBALAGEM === 'Q'
                        ? 'Queima'
                        : envInfo.IE_MANUTENCAO_EMBALAGEM === 'A'
                        ? 'Armazena'
                        : envInfo.IE_MANUTENCAO_EMBALAGEM
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledSubtitle>{t('registrationForm.machineWasher')}</StyledSubtitle>
                </Grid>
                <Grid item xs={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="separatorBox"
                    label={t('registrationForm.separatorBox')}
                    defaultValue={envInfo.IE_LAVADOR_CAIXA === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="runwayTrapiche"
                    label={t('registrationForm.runwayTrapiche')}
                    defaultValue={envInfo.IE_LAVADOR_TRAPICHE === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledSubtitle>{t('registrationForm.sprayerSupply')}</StyledSubtitle>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="directWeirRio"
                    label={t('registrationForm.directWeirRio')}
                    defaultValue={envInfo.IE_ABASTECIMENTO_PULV_RIO === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="waterBox"
                    label={t('registrationForm.waterBox')}
                    defaultValue={envInfo.IE_ABASTECIMENTO_PULV_CAIXA === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="mobileWater"
                    label={t('registrationForm.mobileWater')}
                    defaultValue={envInfo.IE_ABASTECIMENTO_PULV_MOVEL === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledSubtitle>{t('registrationForm.waterAbstractionProperty')}</StyledSubtitle>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="rio"
                    label={t('registrationForm.rio')}
                    defaultValue={envInfo.IE_CAPTACAO_AGUA_RIO === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="well"
                    label={t('registrationForm.well')}
                    defaultValue={envInfo.IE_CAPTACAO_AGUA_POCO === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="strand"
                    label={t('registrationForm.strand')}
                    defaultValue={envInfo.IE_CAPTACAO_AGUA_VERTENTE === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledSubtitle>{t('registrationForm.chemicals')}</StyledSubtitle>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="fullPack"
                    label={t('registrationForm.fullPack')}
                    defaultValue={envInfo.IE_AGROT_EMB_CHEIA_PROP === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="emptyPack"
                    label={t('registrationForm.emptyPack')}
                    defaultValue={envInfo.IE_AGROT_EMB_VAZIA_PROP === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Alvenaria', 'Madeira']}
                    isReadOnly={!editPage}
                    inputName="localFullPack"
                    label={t('registrationForm.localStorageFull')}
                    defaultValue={
                      envInfo.IE_AGROT_EMB_CHEIA_ARM === 'A'
                        ? 'Alvenaria'
                        : envInfo.IE_AGROT_EMB_CHEIA_ARM === 'M'
                        ? 'Madeira'
                        : envInfo.IE_AGROT_EMB_CHEIA_ARM
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Alvenaria', 'Madeira']}
                    isReadOnly={!editPage}
                    inputName="localEmptyPack"
                    label={t('registrationForm.localStorageEmpty')}
                    defaultValue={
                      envInfo.IE_AGROT_EMB_VAZIA_ARM === 'A'
                        ? 'Alvenaria'
                        : envInfo.IE_AGROT_EMB_VAZIA_ARM === 'M'
                        ? 'Madeira'
                        : envInfo.IE_AGROT_EMB_VAZIA_ARM
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledSubtitle>{t('registrationForm.grainStorageUnit')}</StyledSubtitle>
                </Grid>
                <Grid item xs={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="itHas"
                    label={t('registrationForm.itHas')}
                    defaultValue={envInfo.IE_UNIDADE_GRAO === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="certificate"
                    label={t('registrationForm.certificate')}
                    defaultValue={envInfo.IE_UNIDADE_CERTIFICADA === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledSubtitle>{t('registrationForm.wasteTreatment')}</StyledSubtitle>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="ownPigFarming"
                    label={t('registrationForm.ownPigFarming')}
                    defaultValue={envInfo.IE_TRATAM_DEJETO_SUINO === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="pigFarmingLicensed"
                    label={t('registrationForm.pigFarmingLicensed')}
                    defaultValue={envInfo.IE_TRATAM_DEJ_SUI_LIC === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="dairyFarming"
                    label={t('registrationForm.dairyFarming')}
                    defaultValue={envInfo.IE_TRATAM_DEJETO_BOVINO === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="dairyFarmingTrat"
                    label={t('registrationForm.traitDairyFarming')}
                    defaultValue={envInfo.IE_TRATAM_DEJ_BOV_TRAT === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledSubtitle>{t('registrationForm.biodigester')}</StyledSubtitle>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="biodigesterItHas"
                    label={t('registrationForm.itHas')}
                    defaultValue={envInfo.IE_BIODIGESTOR === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="useEnergy"
                    label={t('registrationForm.useEnergy')}
                    defaultValue={envInfo.IE_BIODIGESTOR_ENERGIA === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="pigFarming"
                    label={t('registrationForm.pigFarming')}
                    defaultValue={envInfo.IE_BIODIGESTOR_SUINO === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="milkFarming"
                    label={t('registrationForm.milkFarming')}
                    defaultValue={envInfo.IE_BIODIGESTOR_BOVINO === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledSubtitle>{t('registrationForm.deadAnimalComposter')}</StyledSubtitle>
                </Grid>
                <Grid item xs={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="deadAnimalComposterItHas"
                    label={t('registrationForm.itHas')}
                    defaultValue={envInfo.IE_COMPOSTEIRA === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    numberInput
                    isReadOnly={!editPage}
                    inputName="composteiraQuantity"
                    label={t('registrationForm.composteiraQuantity')}
                    defaultValue={envInfo.QT_COMPOSTEIRA.toLocaleString('pt-br')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledSubtitle>{t('registrationForm.waterResource')}</StyledSubtitle>
                </Grid>
                <Grid item xs={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={['Sim', 'Não']}
                    isReadOnly={!editPage}
                    inputName="haveRiver"
                    label={t('registrationForm.haveRiver')}
                    defaultValue={envInfo.IE_POSSUI_RIO === 'S' ? `Sim` : 'Não'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <OptionsCadastralSheet
                    listShowDropdown={[]}
                    stringInput
                    isReadOnly={!editPage}
                    inputName="riverName"
                    label={t('registrationForm.riverName')}
                    defaultValue={envInfo.NM_RIO === 'N' ? `Não Possui` : envInfo.NM_RIO}
                  />
                </Grid>
              </Grid>

              {editPage && (
                <StyledButton color="frisiaPrimary" type="submit" style={{ minWidth: '331px', marginTop: '35px' }}>
                  <span>{t('global.button.updateEnvironmentalRegistration')}</span>
                </StyledButton>
              )}
            </form>
          </FormProvider>
        </>
      )}
    </StyledRegistrationFormBackground>
  );
};

const StyledInfo = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
  flex-direction: column;
  padding: 5px 8px;
  div {
    text-align: left;
    font-size: 16px/19px;
    font-family: ${({ theme }) => theme.font.montserratMedium};
    letter-spacing: 0.02px;
    color: #656565;
    opacity: 1;
  }
`;

const StyledTitleSpring = styled.div`
  display: flex;
  div {
    margin-left: 15px;
    color: #656565;
  }
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitleContainer = styled.div`
  text-align: left;
`;

const StyledText = styled.span`
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: #787878;
  font-size: 14px;
`;

const StyledFiltersContainer = styled.div`
  display: flex;
  column-gap: 15px;
  margin: 26px 0;
`;

const StyledSubtitle = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 16px;
  text-align: left;
  margin-top: 15px;
`;

const StyledProperties = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 16px;
  text-align: left;
  margin-top: 15px;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  min-height: 80px;
  text-align: left;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  padding: 15px;
  margin: 0 -30px;
  margin-top: 10px;
`;

const StyledlabelsChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 31px;
  padding-bottom: 12px;
  text-align: left;
  margin-right: 26px;
`;

export default withTranslation()(EnvironmentalProperties);
