import styled from 'styled-components';

interface IStyledLi {
  isActive?: boolean;
}

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  height: 100vh;
  background-color: #f1f1f1;
`;

export const StyledMenuContainer = styled.div`
  position: relative;
  min-height: 950px;
  padding: 44px 0 40px 0;
  transition: all 300ms ease-in-out;
  background-color: ${({ theme }) => theme.color.frisiaPrimary};
  font-family: ${({ theme }) => theme.font.montserratMedium};
  font-size: 16px;
  color: white;

  svg {
    min-width: 30px;
    min-height: 30px;
  }

  #sidebarMenu {
    height: 100%;
    position: fixed;
    left: 0;
    width: 80px;
    margin-top: 20px;
    transition: all 300ms ease-in-out;
    margin-left: -7px;
  }

  input[type='checkbox'] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
  }

  .sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 22px;
    left: 15px;
    height: 22px;
    width: 22px;
    margin: 14px;
  }

  .spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
  }
  .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
  }
  .diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
  }
  .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
  }

  zoom: 128%;

  @media (max-height: 1200px) {
    zoom: 115%;
  }

  @media (max-height: 1125px) {
    zoom: 98%;
  }
  @media (max-height: 992px) {
    zoom: 95%;
    @-moz-document url-prefix() {
      #sidebarMenu {
        overflow-y: scroll;
      }
    }
  }
  @media (max-height: 928px) {
    zoom: 90%;
  }
  @media (max-height: 890px) {
    zoom: 86%;
  }
  @media (max-height: 838px) {
    zoom: 79%;
  }
  @media (max-height: 800px) {
    zoom: 70%;
  }
  @media (max-height: 710px) {
    zoom: 66%;
  }
  @media (max-height: 665px) {
    zoom: 60%;
  }
  @media (max-height: 615px) {
    ::-webkit-scrollbar {
      max-width: 8px;
    }
  }
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 29px;
  padding-left: 32px;
`;

export const StyledLogo = styled.a`
  width: 100%;
  margin: 0 60px;
  transform: translate(-10px, -16px);
  cursor: pointer;
`;

export const StyledList = styled.ul`
  list-style-type: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-align: left;
`;

export const StyledListItem = styled.li<IStyledLi>`
  display: flex;
  align-items: center;
  overflow: hidden;

  padding: 10px 0 10px 32px;
  background-color: ${({ isActive }) => (isActive ? '#6E85B5' : 'none')};
  transition: width 300ms ease-in-out;

  div {
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  }

  :hover {
    background-color: ${({ theme }) => theme.color.frisiaPrimaryDark};
  }
`;

export const StyledText = styled.span`
  margin-left: 24px;
  margin-right: auto;
`;

export const StyledMenuFooter = styled.div`
  position: absolute;
  width: 100%;
`;

export const StyledListItemFooter = styled.li`
  background-color: #194185;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 17px;
  :hover {
    background-color: ${({ theme }) => theme.color.frisiaPrimaryDark};
  }
`;

export const StyledContainerSocialAndLogout = styled.div`
  display: flex;
  justify-content: space-between;
  height: 54px;
  margin: 0 25px;
  adding-bottom: 25px;
  margin-bottom: 54px;
  a {
    margin: 0 7px;
  }
`;

export const StyledContainerSocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 80px;
`;

export const StyledContainerLogout = styled(StyledContainerSocialIcons)`
  width: 60px;
  cursor: pointer;
  margin-right: -9px;

  span {
    font-size: 12px;
  }
`;

export const StyledContainerHeaderAndPage = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-width: calc(100vw - 314px);
  @media (max-height: 928px) {
    max-width: calc(100vw - 299px);
  }
  @media (max-height: 890px) {
    max-width: calc(100vw - 283px);
  }
  @media (max-height: 838px) {
    max-width: calc(100vw - 270px);
  }
  @media (max-height: 800px) {
    max-width: calc(100vw - 248px);
  }
  @media (max-height: 710px) {
    max-width: calc(100vw - 220px);
  }
  @media (max-height: 665px) {
    max-width: calc(100vw - 206px);
  }
  @media (max-height: 615px) {
    max-width: calc(100vw - 189px);
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;

  background-color: #fcfcfc;
  min-height: 96px;
  @media (max-width: 1200px) {
    zoom: 87%;
  }
  @media (max-width: 1100px) {
    zoom: 77%;
  }
  @media (max-width: 980px) {
    zoom: 67%;
  }
  @media (max-width: 915px) {
    zoom: 62%;
  }
`;

export const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  max-width: 500px;
  min-width: 230px;
  height: 37px;
  padding: 0 20px;
  margin-left: 65px;
  margin-right: 10px;

  background-color: #e6eaf2;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  font-size: 16px;
  color: #656565;
  border-radius: 20px;

  .MuiAutocomplete-root {
    width: 100%;

    background-color: #e6eaf2;
    border: none;
    svg {
      visibility: hidden;
      z-index: 10;
    }
  }
  .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before {
    border-bottom: none;
  }
`;

export const StyledContainerUser = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 35px;
  min-width: 275px;
`;

export const StyledContainerIcon = styled.div`
  position: relative;
  margin-right: 39px;
  margin-top: 3px;
  max-height: 27px;

  cursor: pointer;
`;

export const StyledNumberNotifications = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 11px;
  left: 8px;

  height: 22px;
  width: 22px;

  background-color: #f11a1a;
  border: 2px solid #ffffff;
  border-radius: 50%;
  color: #fefefe;
  font-size: 10px;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

export const StyledWelcomeText = styled.span`
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #404040;
  font-size: 20px;
  cursor: pointer;
  margin: 8px 0;
  margin-left: 19px;
`;

export const StyledUserName = styled.span`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 20px;
`;

export const StyledProfileChosen = styled.div`
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 14px;
  border-radius: 10px;
  background-color: #e7ecf0;
  margin: 6px;
  padding: 6px;
`;

export const StyledContainerPage = styled.div`
  min-height: 786px;
  height: 100%;
  background-color: #f1f1f1;
  padding: 0 30px 55px 30px;
  min-height: calc(100vh - 96px);
  overflow-y: scroll;
`;

export const StyleInnerPageContainer = styled.div`
  margin: 0 auto;
  max-width: 1250px;

  @media screen and (min-width: 1975px) {
    /* transform: scale(118%); */
    transform-origin: top center;
  }

  @media screen and (min-width: 2400px) {
    /* transform: scale(130%); */
    transform-origin: top center;
  }
`;

export const StyledContainerArrow = styled.div`
  display: none;
`;
