import milkCollectionsApi from 'api/cattle/milk-collections-api';
import ButtonDownload from 'components/ButtonDownload';
import CollapsibleTable from 'components/CollapsibleTable';
import CollapsibleTableMilkCollections from 'components/CollapsibleTableMilkCollections';
import GenericDropdown from 'components/GenericDropdown';
import GenericTableWhiteHeader from 'components/GenericTableWhiteHeader';
import MainPageTitle from 'components/MainPageTitle';
import { isValid, parse } from 'date-fns';
import { toNumber } from 'lodash';
import IMilkCollections from 'model/milk-collections';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import milkCollectionsService from 'services/cattle/milk-collections-service';
import { dateList } from 'shared/util/date-utils';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const complementDateFilter = '01';

const MilkCollections = ({ t }: WithTranslation) => {
  const [milkCollections, setMilkCollections] = useState<IMilkCollections>();
  const [selectedDateFilter, setSelectedDateFilter] = useState<string>('');
  const [dateConvertedValidFormat, setDateConvertedValidFormat] = useState<Date>();
  const [reportLink, setReportLink] = useState<string>('');

  useEffect(() => {
    setDateConvertedValidFormat(parse(selectedDateFilter, 'dd/MM/yyyy', new Date()));
  }, [selectedDateFilter]);

  const getCollectionsReport = () => {
    if (selectedDateFilter != null && isValid(dateConvertedValidFormat) && getUserRegistration != null) {
      const reportLinkPath = milkCollectionsApi.getCollectionsReport(selectedDateFilter, getUserRegistration);
      setReportLink(reportLinkPath);
    }
  };
  useEffect(() => {
    if (isValid(dateConvertedValidFormat) && getUserRegistration != null) {
      getCollectionsReport();
      milkCollectionsService.getMilkCollections(selectedDateFilter, getUserRegistration).then(response => setMilkCollections(response[0]));
    }
  }, [dateConvertedValidFormat]);

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  return (
    <>
      <StyledPageTitleContainer margin={'30px 0 15px 0'}>
        <MainPageTitle fontSize={30} text={t('livestock.milkCollections')} />
      </StyledPageTitleContainer>

      <StyledContainer>
        <div>
          <StyledPageTitleContainer margin={'15px 0 20px 0'}>
            {milkCollections?.NR_PONTO_COLETA != null && milkCollections?.DS_PONTO_COLETA != null ? (
              <MainPageTitle fontSize={16} text={`${milkCollections.NR_PONTO_COLETA ?? ''} - ${milkCollections.DS_PONTO_COLETA}`} />
            ) : (
              <MainPageTitle fontSize={16} text="&nbsp;" />
            )}
          </StyledPageTitleContainer>
          <StyledFilterContainer filterWidth={200}>
            <GenericDropdown
              label={t('livestock.filterDate')}
              placeholder={t('livestock.filterSelectDate')}
              listShowDropdown={dateList()}
              getSelectedValue={value => {
                setSelectedDateFilter(`${complementDateFilter}/${value}`);
              }}
            />
          </StyledFilterContainer>
        </div>
        <StyledTableReportsContainer>
          <GenericTableWhiteHeader
            isInvertLineColor
            tableItems={[
              { fieldIdentification: t('livestock.sumCurrentyear'), value: StringUtils.maskAmount(milkCollections?.QT_SOMA_ANO) },
              {
                ...(milkCollections != undefined && toNumber(milkCollections?.COTA_A2A2) != 0
                  ? { fieldIdentification: t('livestock.A2A2Quota'), value: StringUtils.maskAmount(milkCollections?.COTA_A2A2) }
                  : null),
              },
            ]}
          />
        </StyledTableReportsContainer>
        <StyledTableReportsContainer>
          <GenericTableWhiteHeader
            isInvertLineColor
            tableItems={[
              { fieldIdentification: t('livestock.totalPeriod'), value: StringUtils.maskAmount(milkCollections?.QT_LITROS_TOTAL) },
              { fieldIdentification: t('livestock.averagePeriod'), value: StringUtils.maskAmount(milkCollections?.QT_MEDIA) },
            ]}
          />
        </StyledTableReportsContainer>

        {milkCollections?.COLETAS != null && milkCollections.COLETAS.length > 0 && (
          <StyledButtonDownloadContainer href={reportLink}>{t('global.button.generateMounthReport')}</StyledButtonDownloadContainer>
        )}
      </StyledContainer>

      {milkCollections?.COLETAS != null && milkCollections.COLETAS.length > 0 && (
        <StyledContainer>
          <StyledPageTitleContainer margin={'15px 0 0 0'} style={{ width: '100%' }}>
            <MainPageTitle fontSize={16} text={t('livestock.reports')} />
          </StyledPageTitleContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              padding: '10px 42px',
            }}
          >
            {milkCollections.COLETAS.map((collect, index) => (
              <div key={`collect-${index}`}>
                <CollapsibleTableMilkCollections
                  style={{ minWidth: '100px', marginTop: '10px' }}
                  title={`${collect.DT_ENTREGA} ${StringUtils.maskAmount(collect.QT_TOTAL_DIA)} L`}
                  headerItems={[collect.DT_ENTREGA, `${StringUtils.maskAmount(collect.QT_TOTAL_DIA)} L`]}
                  arrayOfData={collect.DETALHES.map(item => {
                    return [
                      item.HORA,
                      item.NR_TANQUE,
                      item.NR_BOLETO,
                      item.CD_PLACA,
                      item.QT_TEMPERATURA,
                      StringUtils.maskAmount(item.QT_LITROS_SOBRA),
                      StringUtils.maskAmount(item.QT_LITROS),
                    ];
                  })}
                  isInvertLineColor
                  isDontHaveMaximumWidth
                />
              </div>
            ))}
          </div>
        </StyledContainer>
      )}
    </>
  );
};

const StyledTableReportsContainer = styled.div`
  width: 375px;
  margin-top: auto;
  margin-bottom: 5px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
  justify-content: flex-start;
`;

const StyledButtonDownloadContainer = styled.a`
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 15px;
  background-color: white;
  cursor: pointer;
  border-radius: 15px;
  height: fit-content;
  padding: 16px;
  align-self: center;
  margin-top: 37px;
  &:hover {
    background-color: ${({ theme }) => theme.color.frisiaPrimary};
    color: white;
  }
`;

export default withTranslation()(MilkCollections);
