import { SxProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

interface IProps extends WithTranslation {
  label: string;
  errorText?: FieldError | string;
  setDate: Dispatch<SetStateAction<Date | null>>;
  disableWeekends?: boolean;
  disabled?: boolean;
  date: Date | null;
}

const InputDateWithSetting = ({ label, errorText, setDate, date, disableWeekends, disabled = false, t }: IProps) => {
  const theme = useTheme();

  const popperSx: SxProps = {
    '& .MuiPaper-root': {
      height: '350px',
      boxShadow: 'none',
    },
    '& .MuiCalendarPicker-root': {
      backgroundColor: '#E6EAF2',
    },
    '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
      color: 'white',
      backgroundColor: '#D4A518',
    },
    '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:focus': {
      color: 'white',
      backgroundColor: '#D4A518',
    },
    '& .MuiPickersDay-dayWithMargin': {
      fontFamily: theme.font.montserratMedium,
      color: theme.color.frisiaPrimary,
      backgroundColor: '#E6EAF2',
      fontSize: '14px',
    },
    '& .css-1w13o7u-MuiTypography-root': {
      fontFamily: theme.font.montserratSemiBold,
      color: '#4E6BA7',
      opacity: 0.6,
      fontSize: '10px',
    },
    '& .css-l0iinn': {
      fontFamily: theme.font.montserratMedium,
      color: theme.color.frisiaPrimary,
      fontSize: '12px',
      textTransform: 'uppercase',
    },
    '& .MuiIconButton-sizeSmall': {
      color: '#183C96',
    },
    '& .PrivatePickersYear-yearButton': {
      fontFamily: theme.font.montserratMedium,
      color: theme.color.frisiaPrimary,
      fontSize: '14px',
    },
    '& .css-m1gykc.Mui-selected:focus': {
      backgroundColor: '#D4A518',
      color: 'white',
    },
    '& .css-k008qs': {
      backgroundColor: '#FCFCFC',
      borderRadius: '17px',
      boxShadow: '0px 3px 6px #00000012',
    },
  };

  const disableWeekendsFunction = date => {
    if (disableWeekends) {
      return date.getDay() === 0 || date.getDay() === 6;
    } else {
      return false;
    }
  };

  return (
    <StyledContainer>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
        <DesktopDatePicker
          allowSameDateSelection
          label={label}
          value={date}
          shouldDisableDate={disableWeekendsFunction}
          disabled={disabled}
          onChange={value => {
            if (isValid(value)) {
              setDate(value);
            }
          }}
          renderInput={params => (
            <StyledInputText
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: t('livestock.selectDateFilter'),
              }}
              focused
            />
          )}
          PopperProps={{
            sx: popperSx,
          }}
        />
      </LocalizationProvider>

      <StyledErrorMessage>{errorText}</StyledErrorMessage>
    </StyledContainer>
  );
};

const StyledInputText = styled(TextField)`
  div {
    font-family: ${({ theme }) => theme.font.montserratRegular};
    height: 53px;
    max-width: 200px;

    fieldset {
      border: 2px solid #4e6ba7 !important;
    }
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary} !important;
    letter-spacing: 0.4px;
    font-size: 13.2px;
    line-height: 2.2em;
    padding-left: 6px;
    z-index: 0;
  }

  .css-u9osun.Mui-focused {
    color: ${({ theme }) => theme.color.frisiaPrimary} !important;
    z-index: 0;
  }

  input {
    font-size: 16px;
    color: #656565;

    &::placeholder {
      opacity: 1;
    }
  }
`;

const StyledContainer = styled.div`
  height: 70px;
`;

const StyledErrorMessage = styled.span`
  position: absolute;
  bottom: 0;
  left: 1px;

  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 11px;
  color: red;
`;

export default withTranslation()(InputDateWithSetting);
