import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';
import GenericDropdown from './GenericDropdown';

interface IProps {
  inputName: string;
  label: string;
  defaultValue: string;
  isReadOnly?: boolean;
  numberInput?: boolean;
  stringInput?: boolean;
  listShowDropdown: string[];
  errorText?: string;
}

interface IStyledProps {
  readOnly?: boolean;
  isErrorStatus?: boolean;
}

const OptionsCadastralSheet = ({
  inputName,
  label,
  defaultValue,
  isReadOnly,
  numberInput,
  stringInput,
  listShowDropdown,
  errorText,
}: IProps) => {
  const { register } = useFormContext();

  const [value, setValue] = useState<string>(defaultValue);

  return (
    <StyledContainer isErrorStatus={errorText != null}>
      <StyledLabel htmlFor={`inputId${inputName}`}>{label}</StyledLabel>
      {isReadOnly || numberInput || stringInput ? (
        <StyledInput
          {...register(inputName)}
          type={numberInput && !stringInput ? 'number' : 'text'}
          id={`inputId${inputName}`}
          value={value}
          maxLength={255}
          onChange={e => setValue(e.target.value)}
          readOnly={isReadOnly}
          style={
            !isReadOnly
              ? {
                  height: '50px',
                  maxWidth: '124px',
                  fontSize: '20px',
                  border: '2px solid rgb(78, 107, 167)',
                  borderRadius: '4px',
                  textAlign: 'center',
                  marginBottom: '20px',
                }
              : undefined
          }
        />
      ) : (
        <GenericDropdown {...register(inputName)} defaultValue={value} listShowDropdown={listShowDropdown} getSelectedValue={console.log} />
      )}
      <StyledErrorMessage verticalPosition="-17px">{errorText}</StyledErrorMessage>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<IStyledProps>`
  display: flex;
  width: 100%;
  position: relative;

  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 16px;
  border-bottom: ${({ theme, isErrorStatus }) => (isErrorStatus ? '1px solid red' : `1px solid ${theme.color.frisiaPrimary}`)};
  text-align: left;
  margin: 15px 0;
`;

const StyledLabel = styled.label`
  margin-right: 10px;
  white-space: nowrap;
`;

const StyledInput = styled.input<IStyledProps>`
  width: 100%;
  border: none;
  color: #787878;
  font-size: 14px;
  padding: 0;
`;

export default OptionsCadastralSheet;
