import profitabilityApi from 'api/agricultural/profitability-api';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IAverageInputCosts,
  IAverageProductivity,
  IAverageSellPrice,
  ICropandStates,
  ICultureProfitability,
  IInputCosts,
  IProductivity,
  IProducts,
  IProfitability,
  IProfitabilityAverage,
  ISellPrice,
} from 'model/agricultural-profitability';
import HttpStatus from 'model/enums/http-status';

export const profitabilityService = () => {
  const getCropandStates = async () => {
    try {
      const result: AxiosResponse<ICropandStates[]> = await profitabilityApi.getCropandStates();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getCulture = async (crop: number, state: number) => {
    try {
      const result: AxiosResponse<ICultureProfitability[]> = await profitabilityApi.getCulture(crop, state);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getProductive = async (crop: number, state: number, culture: number) => {
    try {
      const result: AxiosResponse<IProductivity[]> = await profitabilityApi.getProductive(crop, state, culture);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAverageProductive = async (crop: number, state: number, culture: number) => {
    try {
      const result: AxiosResponse<IAverageProductivity[]> = await profitabilityApi.getAverageProductive(crop, state, culture);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getProducts = async (crop: number, state: number, culture: number) => {
    try {
      const result: AxiosResponse<IProducts[]> = await profitabilityApi.getProducts(crop, state, culture);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getSellPrice = async (crop: number, state: number, culture: number, product: number) => {
    try {
      const result: AxiosResponse<ISellPrice[]> = await profitabilityApi.getSellPrice(crop, state, culture, product);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAverageSellPrice = async (crop: number, state: number, culture: number, product: number) => {
    try {
      const result: AxiosResponse<IAverageSellPrice[]> = await profitabilityApi.getAverageSellPrice(crop, state, culture, product);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getInputCosts = async (crop: number, state: number, culture: number, inputType: string) => {
    try {
      const result: AxiosResponse<IInputCosts[]> = await profitabilityApi.getInputCosts(crop, state, culture, inputType);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAverageInputCosts = async (crop: number, state: number, culture: number, inputType: string) => {
    try {
      const result: AxiosResponse<IAverageInputCosts[]> = await profitabilityApi.getAverageInputCosts(crop, state, culture, inputType);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getProfitability = async (crop: number, culture: number) => {
    try {
      const result: AxiosResponse<IProfitability[]> = await profitabilityApi.getProfitability(crop, culture);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAverageProfitability = async (crop: number, culture: number) => {
    try {
      const result: AxiosResponse<IProfitabilityAverage[]> = await profitabilityApi.getAverageProfitability(crop, culture);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getCropandStates,
    getCulture,
    getProductive,
    getAverageProductive,
    getProducts,
    getSellPrice,
    getAverageSellPrice,
    getInputCosts,
    getAverageInputCosts,
    getProfitability,
    getAverageProfitability,
  };
};

export default profitabilityService();
