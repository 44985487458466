import styled from 'styled-components/macro';

interface StyledButtonProps {
  color: string;
  marginAdjust?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  transition: 250ms;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  padding: 0 5px;
  text-transform: uppercase;

  box-shadow: ${props => props.theme.buttons[props.color].boxShadow};
  font-size: ${props => props.theme.buttons[props.color].fontSize};
  background: ${props => props.theme.buttons[props.color].background};
  color: ${props => props.theme.buttons[props.color].color};
  border: ${props => props.theme.buttons[props.color].border};
  border-radius: ${props => props.theme.buttons[props.color].borderRadius};
  line-height: ${props => props.theme.buttons[props.color].lineHeight};
  min-width: ${props => props.theme.buttons[props.color].minWidth};
  max-width: ${props => props.theme.buttons[props.color].maxWidth};
  min-height: ${props => props.theme.buttons[props.color].minHeight};
  font-weight: ${props => props.theme.buttons[props.color].fontWeight};
  letter-spacing: ${props => props.theme.buttons[props.color].letterSpacing};

  & > * {
    vertical-align: middle;
  }

  &:hover {
    background: ${props => props.theme.buttons[props.color].backgroundHover};
    color: ${props => props.theme.buttons[props.color].colorHover};
    border: ${props => props.theme.buttons[props.color].borderHover};
    box-shadow: ${props => props.theme.buttons[props.color].dropShadowHover};
    text-decoration: ${props => props.theme.buttons[props.color].textDecorationHover};
  }
  &:active {
    background: ${props => props.theme.buttons[props.color].backgroundActive};
    color: ${props => props.theme.buttons[props.color].colorActive};
    border: ${props => props.theme.buttons[props.color].borderActive};
    box-shadow: ${props => props.theme.buttons[props.color].boxShadowActive};
    font-weight: ${props => props.theme.buttons[props.color].fontWeightActive};
  }

  &:disabled {
    opacity: 0.5;
    &,
    & * {
      pointer-events: none;
    }
  }

  i.icon-select:before {
    color: white;
  }

  i:first-child {
    margin-right: 10px;
  }
  i:last-child {
    margin-left: 10px;
  }

  margin-bottom: ${props => (props.marginAdjust ? '60px' : 'none')};
`;
