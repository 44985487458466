import { TFunctionResult } from 'i18next';
import { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled, { CSSProperties } from 'styled-components';
import { StyledTable, StyledTableRow } from 'styled-components/StyledForTables';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/white-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/white-plus-round-icon.svg';

interface SlaughterObject {
  parameter: string;
  kiloSymbol: string;
}

export interface ITypeTable extends WithTranslation {
  headerInfos?: string[];
  headerItems: string[];
  title: string;
  arrayOfData: (string | number | TFunctionResult)[][];
  isInvertLineColor?: boolean;
  isDontHaveMaximumWidth?: boolean;
  style?: CSSProperties;
}

const CollapsibleTableMilkCollections = ({
  arrayOfData,
  title,
  headerItems,
  isInvertLineColor,
  isDontHaveMaximumWidth,
  style,
  t,
}: ITypeTable) => {
  const [isShowGraphic, setIsShowGraphic] = useState<boolean>(false);
  const generalParametersTableHeader = [t('table.parameters'), t('table.unit'), t('table.tattoo')];

  return (
    <StyledTable style={style} isInvertLineColor={isInvertLineColor} isDontHaveMaximumWidth={isDontHaveMaximumWidth}>
      {generalParametersTableHeader != null && (
        <thead>
          <StyledTitleRowHeader onClick={() => setIsShowGraphic(!isShowGraphic)}>
            {headerItems?.map((headerItem, index) => (
              <th key={`header-item-${index}`} colSpan={4}>
                {index === 0 && (isShowGraphic ? <MinusRoundIconSvg /> : <PlusRoundIconSvg />)}
                <StyledTextHeader className={`${index === 0 ? 'firstCell' : ''}`}>{headerItem}</StyledTextHeader>
              </th>
            ))}
          </StyledTitleRowHeader>
          {isShowGraphic && (
            <StyledTableRowHeader>
              <th> {t('livestock.collectionDate')}</th>
              <th> {t('livestock.tank')}</th>
              <th> {t('livestock.ticketNumber')}</th>
              <th> {t('livestock.board')}</th>
              <th> {t('livestock.temperature')}</th>
              <th> {t('livestock.leftovers')}</th>
              <th> {t('livestock.volume')}</th>
            </StyledTableRowHeader>
          )}
        </thead>
      )}
      {isShowGraphic && (
        <tbody>
          {arrayOfData.map((rowItem, rowItemIndex) => (
            <StyledTableRow key={`row-item-${rowItemIndex}`} className={`${rowItemIndex === arrayOfData.length - 1 ? 'last-line' : ''}`}>
              {rowItem.map((item, indexItem) => (
                <td key={`column-${indexItem}`}>{item}</td>
              ))}
            </StyledTableRow>
          ))}
        </tbody>
      )}
    </StyledTable>
  );
};

const StyledTitleRowHeader = styled.tr`
  height: 50px;
  color: white;
  background: #032762;
  font-size: 16px;
  border-radius: 16px;

  th {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 16px;
  }

  th:first-child {
    border-radius: 16px 0 0 16px;
  }

  th:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    border-radius: 16px 0px;
    border-radius: 0 16px 16px 0;
  }
`;

const StyledTextHeader = styled.span`
  font-family: ${({ theme }) => theme.font.montserratRegular};

  &.firstCell {
    margin-left: 10px;
    color: #d7cc27;
  }
`;

const StyledTableRowHeader = styled.tr`
  height: 50px;
  background-color: white;
  color: #032762;

  th {
    text-align: center;
  }

  th:nth-child(1) {
    text-align: left;
    padding-left: 20px;
  }

  th:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
  }
`;

export default withTranslation()(CollapsibleTableMilkCollections);
