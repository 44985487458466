import { apiCore } from 'api/api';
import { AxiosResponse } from 'axios';
import IPacking, { IItens, IPackingBody } from 'model/packing';
import { sendLog } from 'shared/util/api-utils';

export const packingApi = () => {
  const getPackagingControlData = (registration: number): Promise<AxiosResponse<IPacking[]>> => {
    sendLog(
      'Consultando informaçãoes de embalagens',
      `/Embalagens/GetEmbalagensInfos?CD_PESSOA=${registration}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IPacking[]>(`/Embalagens/GetEmbalagensInfos?CD_PESSOA=${registration}`);
  };

  const giveBackPackages = (packages: IItens): Promise<AxiosResponse<IPacking[]>> => {
    sendLog('Devolver Embalagens', `/Embalagens/Devolver`, JSON.stringify(packages), 'put');
    return apiCore.put<IPacking[]>('/Embalagens/Devolver', packages);
  };

  return {
    getPackagingControlData,
    giveBackPackages,
  };
};

export default packingApi();
