import mySquadApi from 'api/cattle/my-squad-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import IMyAnimal from 'model/my-animal';

export const mySquadService = () => {
  const getMyAnimals = async () => {
    try {
      const result: AxiosResponse<IMyAnimal[]> = await mySquadApi.getMyAnimals();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAnimalDetails = async (animalId: number) => {
    try {
      const result: AxiosResponse<IMyAnimal> = await mySquadApi.getAnimalDetails(animalId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getMyAnimals,
    getAnimalDetails,
  };
};

export default mySquadService();
