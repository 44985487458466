import CardInfo from 'model/card-info';
import CardNames from 'model/enums/card-names';
import CategoryNames from 'model/enums/categorys-names';
import { ReactComponent as TransferIconSvg } from '../../images/frisia/icons/blue-arrows-right-left-icon.svg';
import { ReactComponent as BarnSvg } from '../../images/frisia/icons/blue-barn-icon.svg';
import { ReactComponent as CalendarIconSvg } from '../../images/frisia/icons/blue-calendar-icon.svg';
import { ReactComponent as CarSvg } from '../../images/frisia/icons/blue-charge-icon.svg';
import { ReactComponent as TruckSvg } from '../../images/frisia/icons/noun-truck-1307328.svg';
import { ReactComponent as ClassifiedSvg } from '../../images/frisia/icons/blue-classified-icon.svg';
import { ReactComponent as SlaughterReportSvg } from '../../images/frisia/icons/blue-clipboard-icon.svg';
import { ReactComponent as CowPlusIconSvg } from '../../images/frisia/icons/blue-cow-plus-icon.svg';
import { ReactComponent as PaperSvg } from '../../images/frisia/icons/blue-enrollment-registration-sheet-icon.svg';
import { ReactComponent as FolderIconSvg } from '../../images/frisia/icons/blue-folder-icon.svg';
import { ReactComponent as FoodOrdersIconSvg } from '../../images/frisia/icons/blue-food-orders-icon.svg';
import { ReactComponent as ForestSvg } from '../../images/frisia/icons/blue-forest-icon.svg';
import { ReactComponent as FuelPumpIconSvg } from '../../images/frisia/icons/blue-fuel-pump-icon.svg';
import { ReactComponent as HandSvg } from '../../images/frisia/icons/blue-hand-and-leaves-icon.svg';
import { ReactComponent as TaxSvg } from '../../images/frisia/icons/blue-income-tax-icon.svg';
import { ReactComponent as IndicatorsIconSvg } from '../../images/frisia/icons/blue-indicators-icon.svg';
import { ReactComponent as FolderRoomIconSvg } from '../../images/frisia/icons/open-folder-big-blue.svg';
import { ReactComponent as InputsBudgetSvg } from '../../images/frisia/icons/orcamento-insumos.svg';
import { ReactComponent as MapIconSvg } from '../../images/frisia/icons/blue-map-icon.svg';
import { ReactComponent as MilkAnalysisIconSvg } from '../../images/frisia/icons/blue-milk-analysis-icon.svg';
import { ReactComponent as MilkCollectionIconSvg } from '../../images/frisia/icons/blue-milk-collection-icon.svg';
import { ReactComponent as PackageIconSvg } from '../../images/frisia/icons/blue-package-icon.svg';
import { ReactComponent as PigPlusIconSvg } from '../../images/frisia/icons/blue-pig-plus-icon.svg';
import { ReactComponent as PillsIconSvg } from '../../images/frisia/icons/blue-pills-icon.svg';
import { ReactComponent as ProfitabilitySvg } from '../../images/frisia/icons/blue-profitability-icon.svg';
import { ReactComponent as DollarWithGraphSvg } from '../../images/frisia/icons/blue-round-dollar-sign-icon-with-graph.svg';
import { ReactComponent as AccountBalanceIcon } from '../../images/frisia/icons/blue-abstract-icon.svg';
import { ReactComponent as RoundDollarIconSvg } from '../../images/frisia/icons/blue-round-dollar-sign-icon.svg';
import { ReactComponent as SalesSvg } from '../../images/frisia/icons/blue-sales-graphic-icon.svg';
import { ReactComponent as NounAutorizedSalesSvg } from '../../images/frisia/icons/noun-autorized-4504732.svg';
import { ReactComponent as ProductionIcon } from '../../images/frisia/icons/blue-savings-account-icon.svg';
import { ReactComponent as SquadManagementIconSvg } from '../../images/frisia/icons/blue-squad-management-icon.svg';
import { ReactComponent as TransferEnrollmentSvg } from '../../images/frisia/icons/blue-transfer-between-enrollment-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../images/frisia/icons/blue-youtube-icon.svg';
import { ReactComponent as NounFinanceIcon } from '../../images/frisia/icons/noun-finance-4956777.svg';
import { ReactComponent as LogoAlegra } from '../../images/frisia/img/logo-alegra.svg';

interface Card {
  [key: string]: CardInfo;
}

export const card: Card = {
  FINANCIAL: {
    icon: <CalendarIconSvg style={{ width: '78px', height: '59px' }} />,
    i18nText: 'mainMenu.financial',
    pathRedirected: '/financeiro',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.PRINCIPAL,
  },
  FINANCIAL_POSITION: {
    icon: <RoundDollarIconSvg style={{ width: '78px', height: '59px' }} />,
    i18nText: 'card.financialPosition',
    pathRedirected: '/financeiro/posicao-financeira',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.FINANCIAL,
  },
  ACCOUNT_BALANCE: {
    icon: <AccountBalanceIcon style={{ width: '78px', height: '59px' }} />,
    i18nText: 'card.accountBalance',
    pathRedirected: '/financeiro/saldo',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.FINANCIAL,
  },
  TRANSFER_BETWEEN_ACCOUNTS: {
    icon: <TransferIconSvg style={{ width: '78px', height: '59px' }} />,
    i18nText: 'card.transferBetweenAccounts',
    pathRedirected: '/financeiro/transferencia-entre-contas',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.FINANCIAL,
  },
  TRANSFER_BETWEEN_ENROLLMENTS: {
    icon: <TransferEnrollmentSvg style={{ width: '78px', height: '59px' }} />,
    i18nText: 'card.transferBetweenEnrollments',
    pathRedirected: '/financeiro/transferencia-entre-matriculas',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.FINANCIAL,
  },
  MONTHLY_TRANSACTION: {
    icon: <CalendarIconSvg style={{ width: '78px', height: '59px' }} />,
    i18nText: 'card.monthlyTransaction',
    pathRedirected: '/financeiro/movimento-mensal',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.FINANCIAL,
  },
  MONTHLY_TRANSACTION_INVOICE: {
    icon: <CalendarIconSvg style={{ width: '78px', height: '59px' }} />,
    i18nText: 'financial.invoice',
    pathRedirected: '/financeiro/movimento-mensal-notas-fiscais',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.FINANCIAL,
  },
  MONTHLY_TRANSACTION_BY_PROVIDER: {
    icon: <CalendarIconSvg style={{ width: '78px', height: '59px' }} />,
    i18nText: 'financial.monthlyTransactionByProvider',
    pathRedirected: '/financeiro/movimento-mensal-por-fornecedor',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.FINANCIAL,
  },
  COMPLEMENTARY_SEED_BONUS: {
    icon: <ProductionIcon style={{ width: '78px', height: '59px' }} />,
    i18nText: 'card.complementarySeedBonus',
    pathRedirected: '/financeiro/bonificacao-sementes',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.FINANCIAL,
  },
  INCOME_TAX: {
    icon: <TaxSvg style={{ width: '78px', height: '59px' }} />,
    i18nText: 'card.incomeTax',
    pathRedirected: '/financeiro/imposto-de-renda',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.FINANCIAL,
  },
  CAPITAL_ACCOUNT: {
    icon: <DollarWithGraphSvg style={{ width: '78px', height: '59px' }} />,
    i18nText: 'card.distributionOfLeftovers',
    pathRedirected: '/financeiro/cota-capital',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.FINANCIAL,
  },
  REGISTER: {
    icon: <PaperSvg />,
    i18nText: 'card.register',
    pathRedirected: '/ficha-cadastral',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.institutional',
  },

  PERSONAL_DATA: {
    icon: <PaperSvg />,
    i18nText: 'card.personalData',
    pathRedirected: '/ficha-cadastral/dados-pessoais',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.institutional',
  },

  EQUIPMENT: {
    icon: <PaperSvg />,
    i18nText: 'card.equipmentData',
    pathRedirected: '/ficha-cadastral/equipamentos',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.institutional',
  },

  PLANTEL: {
    icon: <PaperSvg />,
    i18nText: 'card.plantel',
    pathRedirected: '/ficha-cadastral/plantel',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.institutional',
  },

  PROPERTIES: {
    icon: <PaperSvg />,
    i18nText: 'card.properties',
    pathRedirected: '/ficha-cadastral/propriedades',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.institutional',
  },
  AGRICULTURAL_PROPERTIES: {
    icon: <PaperSvg />,
    i18nText: 'card.agriculturalProperties',
    pathRedirected: '/ficha-cadastral/propriedades-ambiental',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.institutional',
  },
  FRISIA_CANAL: {
    icon: <YoutubeIcon />,
    i18nText: 'card.frisiaCanal',
    pathRedirected: '/canal-frisia',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.institutional',
  },
  FRISIA_CANAL_PLAY: {
    icon: <YoutubeIcon />,
    i18nText: 'card.frisiaCanal',
    pathRedirected: '/canal-frisia/play',
    category: CategoryNames.DETAILED,
    categoryName: 'card.details',
  },
  FORESTRY: {
    icon: <ForestSvg />,
    i18nText: 'card.forestry',
    pathRedirected: '/florestal',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.institutional',
  },
  ENVIRONMENTAL: {
    icon: <HandSvg />,
    i18nText: 'card.environmental',
    pathRedirected: '/ambiental',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.ENVIRONMENTAL',
    cdMenu: 94,
  },
  CLASSIFIED: {
    icon: <ClassifiedSvg />,
    i18nText: 'card.classified',
    pathRedirected: '/classificados',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.institutional',
  },
  CLASSIFIED_DETAILS: {
    icon: <CalendarIconSvg />,
    i18nText: 'card.details',
    pathRedirected: '/classificados/detalhes',
    category: CategoryNames.DETAILED,
    categoryName: 'card.details',
  },
  SUPPORT: {
    icon: <ClassifiedSvg />,
    i18nText: 'mainMenu.support',
    pathRedirected: '/suporte',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.institutional',
    cdMenu: 56,
  },
  COOPERATIVE_ROOM: {
    icon: <FolderRoomIconSvg />,
    i18nText: 'mainMenu.cooperativeRoom',
    pathRedirected: '/sala-do-cooperado',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.institutional',
  },
  AGRICULTURAL: {
    icon: <BarnSvg />,
    i18nText: 'mainMenu.agricultural',
    pathRedirected: '/agricola',
    category: CategoryNames.PRINCIPAL,
    categoryName: 'mainMenu.agricultural',
  },
  STOCK: {
    icon: <BarnSvg />,
    i18nText: 'card.stock',
    pathRedirected: '/agricola/meu-estoque',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
    cdMenu: 42,
  },
  LOAD_LOAD: {
    icon: <CarSvg />,
    i18nText: 'card.loadLoad',
    pathRedirected: '/agricola/carga-a-carga',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
    cdMenu: 41,
  },
  PRODUCTION_DISCHARGE: {
    icon: <CarSvg />,
    i18nText: 'agricultural.productionDischarge',
    pathRedirected: '/agricola/descarga-de-producao',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
  },
  INPUTS: {
    icon: <InputsBudgetSvg />,
    i18nText: 'card.inputs',
    pathRedirected: '/agricola/orcamento-insumos',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
  },
  COSTING_OPTIONS: {
    icon: <NounFinanceIcon />,
    i18nText: 'card.costingOptions',
    pathRedirected: '/agricola/opcoes-de-custeio',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
  },
  SALES_STATEMENT: {
    icon: <SalesSvg />,
    i18nText: 'card.salesStatement',
    pathRedirected: '/agricola/demonstrativo-de-vendas',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
    cdMenu: 43,
  },
  SALES_AUTHORIZATION: {
    icon: <NounAutorizedSalesSvg />,
    i18nText: 'card.salesAuthorization',
    pathRedirected: '/agricola/autorizacao-de-vendas',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
    cdMenu: 44,
  },
  CONSULT_SALES_AUTHORIZATION: {
    icon: <SalesSvg />,
    i18nText: 'card.consultSalesAuthorization',
    pathRedirected: '/agricola/consulta-autorizacao-de-vendas',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
    cdMenu: 45,
  },
  PRODUCT_WITHDRAW_AUTHORIZATION: {
    icon: <FoodOrdersIconSvg />,
    i18nText: 'card.productWithdrawAuthorizations',
    pathRedirected: '/agricola/autorizacao-de-retirada-de-produtos',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
  },
  PROFITABILITY: {
    icon: <ProfitabilitySvg />,
    i18nText: 'card.profitability',
    pathRedirected: '/agricola/rentabilidade',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
  },
  PACKAGE: {
    icon: <PackageIconSvg />,
    i18nText: 'card.package',
    pathRedirected: '/agricola/embalagens',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
  },
  TRR_REQUESTS_AGRICULTURAL: {
    icon: <FuelPumpIconSvg />,
    i18nText: 'card.trrRequests',
    pathRedirected: '/agricola/pedido-trr',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
  },
  GRAIN_RECEIPT_QUEUE: {
    icon: <TruckSvg />,
    i18nText: 'agricultural.grainReceiptQueue',
    pathRedirected: '/agricola/fila-recebimento-grao',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
  },
  PROGRAMMING_BALANCE: {
    icon: <CalendarIconSvg />,
    i18nText: 'card.programmingBalance',
    pathRedirected: '/agricola/saldo-de-programacao',
    category: CategoryNames.AGRICULTURAL,
    categoryName: 'mainMenu.agricultural',
    cdMenu: 60,
  },
  AGRICULTURAL_QUOTES: {
    icon: <SalesSvg />,
    i18nText: 'card.agriculturalQuotes',
    pathRedirected: '/cotacoes-agricola',
    category: CategoryNames.INSTITUTIONAL,
    categoryName: 'mainMenu.agricultural',
    cdMenu: 58,
  },

  MILK_LIVESTOCK: {
    icon: <MapIconSvg />,
    i18nText: 'mainMenu.livestockCattle',
    pathRedirected: '/pecuaria-bovinos',
    category: CategoryNames.PRINCIPAL,
    categoryName: 'mainMenu.livestockCattle',
  },
  MILK_MAP: {
    icon: <MapIconSvg />,
    i18nText: 'card.milkMap',
    pathRedirected: '/pecuaria-bovinos/mapa-leite',
    category: CategoryNames.CATTLE,
    categoryName: 'mainMenu.livestockCattle',
    cdMenu: 48,
  },
  MILK_COLLECTIONS: {
    icon: <MilkCollectionIconSvg />,
    i18nText: 'card.milkCollections',
    pathRedirected: '/pecuaria-bovinos/coletas-leite',
    category: CategoryNames.CATTLE,
    categoryName: 'mainMenu.livestockCattle',
    cdMenu: 46,
  },
  MILK_ANALYSIS: {
    icon: <MilkAnalysisIconSvg />,
    i18nText: 'card.milkAnalysis',
    pathRedirected: '/pecuaria-bovinos/analise-leite',
    category: CategoryNames.CATTLE,
    categoryName: 'mainMenu.livestockCattle',
    cdMenu: 47,
  },
  MILK_DOCUMENTS: {
    icon: <FolderIconSvg />,
    i18nText: 'card.milkDocuments',
    pathRedirected: '/pecuaria-bovinos/documentos',
    category: CategoryNames.CATTLE,
    categoryName: 'mainMenu.livestockCattle',
    cdMenu: 64,
  },
  MILK_ADVANCED_PAYMENT: {
    icon: <AccountBalanceIcon />,
    i18nText: 'card.milkAdvancedPayment',
    pathRedirected: '/pecuaria-bovinos/adiantamento-leite',
    category: CategoryNames.CATTLE,
    categoryName: 'mainMenu.livestockCattle',
    cdMenu: 65,
  },
  TRR_REQUESTS_CATTLE: {
    icon: <FuelPumpIconSvg />,
    i18nText: 'card.trrRequests',
    pathRedirected: '/pecuaria-bovinos/pedido-trr',
    category: CategoryNames.CATTLE,
    categoryName: 'mainMenu.livestockCattle',
    cdMenu: 85,
  },
  FEED_ORDER_CATTLE: {
    icon: <FoodOrdersIconSvg />,
    i18nText: 'card.feedOrder',
    pathRedirected: '/pecuaria-bovinos/pedido-racao',
    category: CategoryNames.CATTLE,
    categoryName: 'mainMenu.livestockCattle',
    cdMenu: 49,
  },
  DRUG_BALANCES_CATTLE: {
    icon: <PillsIconSvg />,
    i18nText: 'card.drugBalances',
    pathRedirected: '/pecuaria-bovinos/saldo-farmacos',
    category: CategoryNames.CATTLE,
    categoryName: 'mainMenu.livestockCattle',
    cdMenu: 67,
  },
  RAW_MATERIAL_PROGRAMMING_CATTLE: {
    icon: <CalendarIconSvg />,
    i18nText: 'card.rawMaterialProgramming',
    pathRedirected: '/pecuaria-bovinos/programacao-materia-prima',
    category: CategoryNames.CATTLE,
    categoryName: 'mainMenu.livestockCattle',
    cdMenu: 103,
  },
  BULLS_CATALOG: {
    icon: <CalendarIconSvg />,
    i18nText: 'card.bullsCatalog',
    pathRedirected: '/pecuaria-bovinos/catalogo-touros',
    category: CategoryNames.DETAILED,
    categoryName: 'mainMenu.livestockCattle',
    cdMenu: 62,
  },
  BULLS_CATALOG_DETAILS: {
    icon: <CalendarIconSvg />,
    i18nText: 'card.details',
    pathRedirected: '/pecuaria-bovinos/catalogo-touros/detalhes',
    category: CategoryNames.DETAILED,
    categoryName: 'card.details',
  },
  SQUAD_MANAGEMENT: {
    icon: <SquadManagementIconSvg />,
    i18nText: 'card.squadManagement',
    pathRedirected: '/pecuaria-bovinos/meu-plantel',
    category: CategoryNames.CATTLE,
    categoryName: 'mainMenu.livestockCattle',
  },

  LIVESTOCK_PORCINE: {
    icon: <SlaughterReportSvg />,
    i18nText: 'mainMenu.livestockPigs',
    pathRedirected: '/pecuaria-suinos',
    category: CategoryNames.PRINCIPAL,
    categoryName: 'mainMenu.livestockPigs',
  },
  SLAUGHTER_REPORT_PORCINE: {
    icon: <SlaughterReportSvg />,
    i18nText: 'card.slaughterReport',
    pathRedirected: '/pecuaria-suinos/relatorio-abate',
    category: CategoryNames.PORCINE,
    categoryName: 'mainMenu.livestockPigs',
    cdMenu: 50,
  },
  FEED_ORDER_PORCINE: {
    icon: <FoodOrdersIconSvg />,
    i18nText: 'card.feedOrder',
    pathRedirected: '/pecuaria-suinos/pedido-racao',
    category: CategoryNames.PORCINE,
    categoryName: 'mainMenu.livestockPigs',
    cdMenu: 51,
  },
  TRR_REQUESTS_PORCINE: {
    icon: <FuelPumpIconSvg />,
    i18nText: 'card.trrRequests',
    pathRedirected: '/pecuaria-suinos/pedido-trr',
    category: CategoryNames.PORCINE,
    categoryName: 'mainMenu.livestockPigs',
    cdMenu: 86,
  },
  DRUG_BALANCES_PORCINE: {
    icon: <PillsIconSvg />,
    i18nText: 'card.drugBalances',
    pathRedirected: '/pecuaria-suinos/saldo-farmacos',
    category: CategoryNames.PORCINE,
    categoryName: 'mainMenu.livestockPigs',
    cdMenu: 68,
  },
  RAW_MATERIAL_PROGRAMMING_PORCINE: {
    icon: <CalendarIconSvg />,
    i18nText: 'card.rawMaterialProgramming',
    pathRedirected: '/pecuaria-suinos/programacao-materia-prima',
    category: CategoryNames.PORCINE,
    categoryName: 'mainMenu.livestockPigs',
    cdMenu: 105,
  },
  PORCINE_DOCUMENTS: {
    icon: <FolderIconSvg />,
    i18nText: 'card.porcineDocuments',
    pathRedirected: '/pecuaria-suinos/documentos',
    category: CategoryNames.PORCINE,
    categoryName: 'mainMenu.livestockPigs',
    cdMenu: 69,
  },
  PIG_DELIVERY_REPORT: {
    icon: <FolderIconSvg />,
    i18nText: 'card.pigDeliveryReport',
    pathRedirected: '/pecuaria-suinos/relatorio-entrega-de-suinos',
    category: CategoryNames.PORCINE,
    categoryName: 'mainMenu.livestockPigs',
  },
  ALEGRA: {
    icon: <LogoAlegra style={{ width: '80px', marginBottom: '10px' }} />,
    i18nText: 'card.alegra',
    pathRedirected: '/alegra',
    category: CategoryNames.PORCINE,
    categoryName: 'mainMenu.livestockPigs',
  },
  INDICATORS: {
    icon: <IndicatorsIconSvg />,
    i18nText: 'card.indicators',
    pathRedirected: '/pecuaria-bovinos/indicadores-leite',
    category: CategoryNames.CATTLE,
    categoryName: 'mainMenu.livestockCattle',
  },
  NEW_RATION_ORDER_CATTLE: {
    icon: <CowPlusIconSvg />,
    i18nText: 'card.newRationOrder',
    pathRedirected: '/pecuaria-bovinos/pedido-racao/novo-pedido',
    category: CategoryNames.DETAILED,
    categoryName: 'mainMenu.livestockCattle',
  },
  NEW_RATION_ORDER_PORCINE: {
    icon: <PigPlusIconSvg />,
    i18nText: 'card.newRationOrder',
    pathRedirected: '/pecuaria-suinos/pedido-racao/novo-pedido',
    category: CategoryNames.DETAILED,
    categoryName: 'mainMenu.livestockCattle',
  },
  PIG_FARM: {
    icon: <PigPlusIconSvg />,
    i18nText: 'card.newRationOrder',
    pathRedirected: '/pecuaria-suinos/pedido-racao/novo-pedido',
    category: CategoryNames.DETAILED,
    categoryName: 'mainMenu.livestockPigs',
  },
  FARM: {
    icon: <BarnSvg />,
    i18nText: 'mainMenu.agricultural',
    pathRedirected: '/agricola',
    category: CategoryNames.PRINCIPAL,
    categoryName: 'mainMenu.agricultural',
  },
  FINANCING: {
    icon: <CalendarIconSvg style={{ width: '78px', height: '59px' }} />,
    i18nText: 'mainMenu.financial',
    pathRedirected: '/financeiro',
    categoryName: 'mainMenu.financial',
    category: CategoryNames.PRINCIPAL,
  },
  LIVESTOCK_CATTLE: {
    icon: <CowPlusIconSvg />,
    i18nText: 'card.newRationOrder',
    pathRedirected: '/pecuaria-bovinos/pedido-racao/novo-pedido',
    category: CategoryNames.DETAILED,
    categoryName: 'mainMenu.livestockCattle',
  },
  SLAUGHTER_REPORT: {
    icon: <SlaughterReportSvg />,
    i18nText: 'card.slaughterReport',
    pathRedirected: '/pecuaria-suinos/relatorio-abate',
    category: CategoryNames.PORCINE,
    categoryName: 'mainMenu.livestockPigs',
    cdMenu: 50,
  },
  ENVIRONMENTAL_DOCUMENTS: {
    icon: <FolderIconSvg />,
    i18nText: 'card.environmentalDocument',
    pathRedirected: '/ambiental/documento-ambiental',
    category: CategoryNames.ENVIRONMENTAL,
    categoryName: 'mainMenu.environmental',
    cdMenu: 94,
  },
  ENVIRONMENTAL_LICENSE: {
    icon: <HandSvg />,
    i18nText: 'card.environmentalLicense',
    pathRedirected: '/ambiental/licenca-ambiental',
    category: CategoryNames.ENVIRONMENTAL,
    categoryName: 'mainMenu.environmental',
    cdMenu: 94,
  },
};

export const getCardInformation = (cardName: CardNames) => {
  return card[cardName];
};

export const getCardNamesByCdMenu = arrayOfCdMenus => {
  const asArray = Object.entries(card);
  const filtered = asArray.map(([key, value]) => {
    if (arrayOfCdMenus.includes(value.cdMenu)) {
      return CardNames[key];
    }
  });
  return filtered.filter(item => item);
};

export const getCardsByCategory = (category: CategoryNames) => {
  const asArray = Object.entries(card);
  const filtered = asArray.map(([key, value]) => {
    if (value.category === category) {
      return CardNames[key];
    }
  });
  return filtered.filter(item => typeof item === 'string');
};

export default card;
