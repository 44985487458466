import milkGraphicApi from 'api/cattle/milk-graphic-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { IGraphicinfo } from 'model/milk-collections';

export const milkGraphicService = () => {
  const getGraphic = async (): Promise<IGraphicinfo[]> => {
    try {
      const result = await milkGraphicApi.getGraphic();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getGraphic,
  };
};

export default milkGraphicService();
