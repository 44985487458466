import CollapsibleTableLoadLoad from 'components/CollapsibleTableLoadLoad';
import GenericDropdown from 'components/GenericDropdown';
import InputDateWithSetting from 'components/InputDateWithSetting';
import MainPageTitle from 'components/MainPageTitle';
import dayjs from 'dayjs';
import { BranchType, DischargeType } from 'model/discharge';
import { LoadOptionsProduct } from 'model/load';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import LoadService from 'services/load-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const ProductionDischarge = ({ t }: WithTranslation) => {
  const [branchList, setbranchList] = useState<BranchType[]>([]);
  const [chosenBranch, setChosenBranch] = useState<string>('');
  const [productsList, setProductsList] = useState<LoadOptionsProduct[]>([]);
  const [chosenProduct, setChosenProduct] = useState<string>('');

  const [selectedStartDateFilter, setSelectedStartDateFilter] = useState<Date | null>(null);
  const [selectedEndDateFilter, setSelectedEndDateFilter] = useState<Date | null>(null);

  const [dischargeInfo, setDischargeInfo] = useState<DischargeType[]>([]);
  const [showDischarge, setshowDischarge] = useState<boolean>(false);

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getBranchsAndProducts = async (registration: number) => {
    await LoadService.getBranchs(registration.toString()).then(response => {
      setbranchList(response);
    });
    await LoadService.getProducts(registration.toString()).then(response => {
      setProductsList(response);
    });
  };

  useEffect(() => {
    getBranchsAndProducts(getUserRegistration);
  }, []);

  const getInfo = async (registration: number) => {
    if (chosenProduct && chosenBranch) {
      let branchNumber = branchList.find(item => item.NM_FILIAL === chosenBranch)?.CD_FILIAL ?? 1;
      let product = productsList.find(item => item.DS_PRODUTO === chosenProduct)?.CD_PRODUTO ?? 0;
      let startDate = dayjs(selectedStartDateFilter).format('YYYY-MM-DDThh:mm:ss.sssZ');
      let endDate = dayjs(selectedEndDateFilter).format('YYYY-MM-DDThh:mm:ss.sssZ');
      await LoadService.getProductionDischarge(registration.toString(), branchNumber, product.toString(), startDate, endDate).then(
        response => {
          setshowDischarge(true);
          setDischargeInfo(response.filter(item => item.DESCARGA));
        }
      );
    }
  };

  useEffect(() => {
    if (chosenProduct && chosenBranch && selectedStartDateFilter && selectedEndDateFilter) {
      getInfo(getUserRegistration);
    }
  }, [chosenProduct, chosenBranch, selectedStartDateFilter, selectedEndDateFilter]);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('agricultural.productionDischarge')} />
      </StyledPageTitleContainer>

      <StyledFiltersContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('agricultural.branch')}
            placeholder={t('agricultural.selectBranch')}
            listShowDropdown={branchList.map(item => item.NM_FILIAL)}
            getSelectedValue={value => setChosenBranch(value)}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('agricultural.product')}
            placeholder={t('agricultural.selectProduct')}
            listShowDropdown={productsList.map(item => item.DS_PRODUTO)}
            getSelectedValue={value => setChosenProduct(value)}
          />
        </StyledFilterContainer>
        <StyledFiltersDateContainer>
          <InputDateWithSetting date={selectedStartDateFilter} label={t('livestock.startFilter')} setDate={setSelectedStartDateFilter} />
          <InputDateWithSetting date={selectedEndDateFilter} label={t('livestock.endFilter')} setDate={setSelectedEndDateFilter} />
        </StyledFiltersDateContainer>
      </StyledFiltersContainer>
      {dischargeInfo.length === 0 && showDischarge && t('agricultural.noData')}
      {dischargeInfo.map((item, index) => {
        return (
          <CollapsibleTableLoadLoad
            key={index}
            headerItems={[`${item.DATA}`, `${t('agricultural.discharge')}: ${item.DESCARGA}`]}
            analysisDate=""
            tableItems={[
              { fieldIdentification: t('agricultural.discharge'), value: item.DESCARGA },
              { fieldIdentification: t('agricultural.time'), value: item.DESCARGA_TEMPO },
              { fieldIdentification: t('agricultural.entry'), value: item.DESCARGA_ENTRADA },
              { fieldIdentification: t('agricultural.exit'), value: item.DESCARGA_SAIDA },
              { fieldIdentification: t('agricultural.driver'), value: item.DESCARGA_MOTORISTA },
              { fieldIdentification: t('agricultural.board'), value: item.DESCARGA_PLACA },
              { fieldIdentification: t('agricultural.vehicleType'), value: item.DESCARGA_TIPO_VEICULO },
            ]}
            isInvertLineColor
            isDontHaveMaximumWidth
            style={{ margin: '25px 0' }}
          />
        );
      })}
    </>
  );
};

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
`;

const StyledFiltersDateContainer = styled.div`
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  max-width: 830px;
`;

export default withTranslation()(ProductionDischarge);
