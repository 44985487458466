import { AxiosResponse } from 'axios';
import { apiCurrency } from './api';

export const currencyApi = () => {
  const getCurrencies = (): Promise<AxiosResponse<string>> => {
    return apiCurrency.get<string>('');
  };

  return {
    getCurrencies,
  };
};

export default currencyApi();
