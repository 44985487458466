import MainPageTitle from 'components/MainPageTitle';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import documentsService from 'services/documents-service';
import StyledDocumentsContainer from 'styled-components/StyledDocumentsContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import Document from '../../components/Document';
import { IDirectory } from 'model/document';
import { EnvironmentalRestrictFile } from 'model/enrironmental-restrict-files';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';

const EnvironmentalDocument = ({ t }: WithTranslation) => {
  const [directories, setDirectories] = useState<IDirectory>({});
  const [environmentalFiles, setEnvironmentalFiles] = useState<EnvironmentalRestrictFile[]>([]);
  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const restrictFolder = 'AMBIENTAL';

  useEffect(() => {
    documentsService.getDocuments().then(response => {
      setDirectories(response?.directory['Sala do Associado'].directory);
    });
  }, []);

  useEffect(() => {
    documentsService.getRestrictFilesBase64(restrictFolder, getUserRegistration).then(response => {
      setEnvironmentalFiles(response);
    });
  }, []);

  const handleDownloadDocument = (filename: string, fileUrl: string) => {
    documentsService
      .generateLinkDownloadDocument(fileUrl)
      .then(response => {
        try {
          // Decodifica o conteúdo base64
          const byteCharacters = atob(response);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Determina o tipo MIME do arquivo
          const contentType = getContentTypeFromFilename(filename);

          // Cria um Blob com o conteúdo decodificado
          const blob = new Blob([byteArray], { type: contentType });

          // Cria uma URL para o Blob e cria um link de download
          const url = URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.download = filename;

          // Simula o clique no link para iniciar o download
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          // Limpa a URL criada para o Blob
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Erro ao processar o arquivo:', error);
        }
      })
      .catch(error => {
        console.error('Erro ao obter o documento:', error);
      });
  };

  const handleDownloadDocumentBase64 = (fileName: string, fileBase64: string) => {
    if (fileBase64) {
      try {
        // Decodifica o conteúdo base64
        const byteCharacters = atob(fileBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Determina o tipo MIME do arquivo
        const contentType = 'application/pdf';

        // Cria um Blob com o conteúdo decodificado
        const blob = new Blob([byteArray], { type: contentType });

        // Cria uma URL para o Blob e cria um link de download
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;

        // Simula o clique no link para iniciar o download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        // Limpa a URL criada para o Blob
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Erro ao processar o arquivo:', error);
      }
    }
  };

  const getContentTypeFromFilename = (filename: string): string => {
    // Exemplos de determinação do tipo MIME com base na extensão do arquivo
    const extension = filename.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      // Adicione mais casos conforme necessário para outros tipos de arquivo
      default:
        return 'application/octet-stream';
    }
  };

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('environmental.personal')} />
      </StyledPageTitleContainer>
      <StyledDocumentsContainer>
        {environmentalFiles.map((item, index) => (
          <Document
            text={item.fileName}
            key={`document-swine-${index}`}
            onClick={() => handleDownloadDocumentBase64(item.fileName, item.fileBase64)}
          />
        ))}
      </StyledDocumentsContainer>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('environmental.public')} />
      </StyledPageTitleContainer>
      <StyledDocumentsContainer>
        {directories['AMBIENTAL']?.file.map((item, index) => (
          <Document
            text={item.filename}
            key={`document-swine-${index}`}
            onClick={() => handleDownloadDocument(item.filename, item.fileurl)}
          />
        ))}
      </StyledDocumentsContainer>
    </>
  );
};

export default withTranslation()(EnvironmentalDocument);
