import FinancialPositionApi from 'api/financial-position-analytic-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { IDateDataBase, INewReportPositionAnalytic, IReportOption, IReportPositionAnalytic } from 'model/financial';

export const FinancialPositionService = () => {
  const getDataBase = async (): Promise<IDateDataBase[]> => {
    try {
      const result = await FinancialPositionApi.getDataBase();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getReport = async (register: string, chosenDate: string, tokenJwt: string): Promise<IReportPositionAnalytic> => {
    try {
      const result = await FinancialPositionApi.getReport(register, chosenDate, tokenJwt);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getReportOptions = async (): Promise<IReportOption[]> => {
    try {
      const result = await FinancialPositionApi.getReportOptions();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const newGetReport = async (register: string, chosenDate: string): Promise<INewReportPositionAnalytic> => {
    try {
      const result = await FinancialPositionApi.newGetReport(register, chosenDate);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getDataBase,
    getReportOptions,
    getReport,
    newGetReport,
  };
};

export default FinancialPositionService();
