import LinkedCards from 'components/LinkedCards';
import MainPageTitle from 'components/MainPageTitle';
import CardNames from 'model/enums/card-names';
import CategoryNames from 'model/enums/categorys-names';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getCardsByCategory } from 'shared/util/card';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const Financial = ({ t }: WithTranslation) => {
  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('mainMenu.financial')} />
      </StyledPageTitleContainer>

      <StyledCardsContainer>
        <LinkedCards listCardNames={getCardsByCategory(CategoryNames.FINANCIAL)} spacingBetweenCards={37} />
      </StyledCardsContainer>
    </>
  );
};

const StyledCardsContainer = styled.div`
  max-width: 1130px;
  margin: 37px auto 0 auto;
`;

export default withTranslation()(Financial);
