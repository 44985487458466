import loadApi from 'api/load-api';
import { AxiosError, AxiosResponse } from 'axios';
import { OptionsSafraProductsResponse } from 'model/agriculturalSalesStatement';
import { BranchType, DischargeType } from 'model/discharge';
import HttpStatus from 'model/enums/http-status';
import { LoadOptionsProduct, LoadOResponse } from 'model/load';

export const LoadService = () => {
  const getHarvestAndProducts = async (registration: string) => {
    try {
      const result: AxiosResponse<OptionsSafraProductsResponse[]> = await loadApi.getHarvestAndProducts(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getBranchs = async (registration: string) => {
    try {
      const result: AxiosResponse<BranchType[]> = await loadApi.getBranchs(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getProducts = async (registration: string) => {
    try {
      const result: AxiosResponse<LoadOptionsProduct[]> = await loadApi.getProducts(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getInfo = async (register: string, harvest: string, product: string, plate: string) => {
    try {
      const result: AxiosResponse<LoadOResponse> = await loadApi.getInfo(register, harvest, product, plate);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getProductionDischarge = async (register: string, branchNumber: number, product: string, initDate: string, endDate: string) => {
    try {
      const result: AxiosResponse<DischargeType[]> = await loadApi.getProductionDischarge(
        register,
        branchNumber,
        product,
        initDate,
        endDate
      );
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getHarvestAndProducts,
    getInfo,
    getBranchs,
    getProducts,
    getProductionDischarge,
  };
};

export default LoadService();
