import { IAuthorization } from 'model/purchasingAuthorization';
import moment from 'moment';
import { CSSProperties, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledTable } from 'styled-components/StyledForTables';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/blue-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/blue-plus-round-icon.svg';
import { CancelButton, SubtitleStyled } from './CollapsibleCostTable';
import { StyledButton } from './styled-button/StyledButton';

interface IProps extends WithTranslation {
  analysisDate: string;
  auth: IAuthorization;
  cancelAuth: (string) => void;
  headerItems?: string[];
  headerInfos?: string[];
  isInvertLineColor?: boolean;
  isDontHaveMaximumWidth?: boolean;
  style?: CSSProperties;
}

const CollapsibleThirdParty = ({
  analysisDate,
  headerItems,
  auth,
  cancelAuth,
  isInvertLineColor,
  isDontHaveMaximumWidth,
  style,
  t,
}: IProps) => {
  const [cancelQuest, setCancelQuest] = useState<boolean>(false);

  const getValues: (string | JSX.Element)[][] = [
    [t('agricultural.creation'), moment(auth.DT_CRIACAO).format('DD/MM/YYYY')],
    [t('agricultural.cpf'), auth.NR_CPF],
    [t('agricultural.name'), auth.NM_AUTORIZADO],
    [
      t('agricultural.term'),
      `${moment(auth.DT_INICIO_VIGENCIA).format('DD/MM/YYYY')} - ${moment(auth.DT_FIM_VIGENCIA).format('DD/MM/YYYY')}`,
    ],
    [t('agricultural.local'), auth.NM_LOCAL_FATURAMENTO],
    [t('agricultural.paymentMethods'), auth.DS_FORMA_PAGAMENTO],
    auth.IE_STATUS === 'VIGENTE'
      ? [
          '',
          !cancelQuest ? (
            <StyledButton style={{ padding: '8px', lineHeight: '11px' }} color="orange" type="button" onClick={() => setCancelQuest(true)}>
              <span>{t('global.button.cancel')}</span>
            </StyledButton>
          ) : (
            <div style={{ height: '98px' }}>
              <SubtitleStyled>{`${t('purchasingAuthorization.cancelAuth')} ${auth.ID_PROCURACAO}?`}</SubtitleStyled>
              <CancelButton
                onClick={() => {
                  setCancelQuest(false);
                }}
                style={{ width: '145px', height: '40px' }}
              >
                {t('global.field.no')}
              </CancelButton>
              <StyledButton
                color="frisiaPrimary"
                onClick={() => {
                  cancelAuth(auth.ID_PROCURACAO);
                }}
                type="button"
                style={{ minWidth: '145px', height: '42px', marginLeft: '23px', marginTop: '10px' }}
              >
                <span>{t('global.field.yes')}</span>
              </StyledButton>
            </div>
          ),
        ]
      : ['', ''],
  ];
  const [isShowTableItems, setIsShowTableItems] = useState<boolean>(false);

  return (
    <StyledTable isInvertLineColor={isInvertLineColor} isDontHaveMaximumWidth={isDontHaveMaximumWidth} style={style}>
      <table style={{ minWidth: '100%' }}>
        <thead>
          <StyledTableRowHeader onClick={() => setIsShowTableItems(!isShowTableItems)}>
            {headerItems?.map((headerItem, index) => (
              <th key={`header-item-${index}`}>
                {index === 0 && (isShowTableItems ? <MinusRoundIconSvg /> : <PlusRoundIconSvg />)}
                <StyledTextHeader className={`${index === 0 ? 'firstCell' : ''}`}>{headerItem}</StyledTextHeader>
              </th>
            ))}
          </StyledTableRowHeader>
        </thead>
      </table>
      <table>
        <tbody>
          {isShowTableItems && (
            <>
              <StyledTableRow>
                <StyledTableData>{analysisDate}</StyledTableData>
              </StyledTableRow>
              {getValues.map((tableRow, indexRow) => (
                <StyledTableRow key={`row-${indexRow}`}>
                  {tableRow.map((tableItem, indexItem) => (
                    <td key={`item-${indexItem}`}>{tableItem}</td>
                  ))}
                </StyledTableRow>
              ))}
            </>
          )}
        </tbody>
      </table>
    </StyledTable>
  );
};

const StyledTableRowHeader = styled.tr`
  height: 40px;
  color: #4c70aa;
  cursor: pointer;

  th {
    background-color: #e6eaf2;
    min-height: 40px;
    height: 100%;
  }

  th:nth-child(1) {
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 9px;
    border-radius: 16px 0 0 16px;
  }

  th:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    border-radius: 0 16px 16px 0;
  }
  td {
    letter-spacing: var(--unnamed-character-spacing-0-02);
    color: var(--unnamed-color-656565);
    text-align: left;
    font-family: ${({ theme }) => theme.font.montserratRegular} !important;
    letter-spacing: 0.02px;
    color: #656565 !important;
    text-transform: capitalize;
    opacity: 1;
  }
`;

export const StyledTableRow = styled.tr`
  height: 40px;
  color: #4e6ba7;

  td {
    text-align: left;
    padding-left: 19px;
  }

  td:nth-child(2) {
    text-align: center;
    padding-left: 0;
  }

  td:nth-child(1) {
    color: #404040;
    font-family: ${({ theme }) => theme.font.montserratRegular};
    font-size: 18px;
  }

  td:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    color: #404040;
    font-family: ${({ theme }) => theme.font.montserratRegular};
    font-size: 18px;
    font-weight: bold;
  }
`;

const StyledTextHeader = styled.span`
  font-family: ${({ theme }) => theme.font.montserratRegular};

  &.firstCell {
    margin-left: 10px;
    font-family: ${({ theme }) => theme.font.montserratBold} !important;
  }
`;

const StyledTableData = styled.td`
  font-family: ${({ theme }) => theme.font.montserratBold} !important;
  color: ${({ theme }) => theme.color.frisiaPrimaryDark} !important;
  text-align: left !important;
`;
export default withTranslation()(CollapsibleThirdParty);
