import SalesOrderStatus from 'model/enums/sales-order-status';
import { IHystoryFeedOrder, MyFeedOrders } from 'model/feed-orders';
import moment from 'moment';
import { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import feedOrdersService from 'services/cattle/feed-order-service';
import styled from 'styled-components';
import CowHeadIconSvg from '../images/frisia/icons/blue-cow-head-icon.svg';
import PigHeadIconSvg from '../images/frisia/icons/blue-pig-head-icon.svg';
import SmallCowHeadIconSvg from '../images/frisia/icons/blue-small-cow-head-icon.svg';
import SmallPigHeadIconSvg from '../images/frisia/icons/blue-small-pig-head-icon.svg';
import { ReactComponent as CirclePlusIconSvg } from '../images/frisia/icons/white-circle-plus-icon.svg';
import { ReactComponent as CircleMinusIconSvg } from '../images/frisia/icons/white-minus-round-icon.svg';
import FeedOrderStatus from './FeedOrderStatus';
import { StyledButton } from './styled-button/StyledButton';

interface IStyledProps {
  isCardForPig?: boolean;
  isExpandCard?: boolean;
}
interface IProps extends WithTranslation, IStyledProps {
  data: IHystoryFeedOrder;
  feedOrderHistory: () => void;
}

const FeedOrdersCard = ({ data, isCardForPig, feedOrderHistory, t }: IProps) => {
  const [isExpandCard, setIsExpandCard] = useState<boolean>(false);
  const [cancelQuest, setCancelQuest] = useState<boolean>(false);

  const checkStatus = () => {
    if (data.DS_STATUS_PROCESSO.toLowerCase().includes('pendente')) {
      return SalesOrderStatus.WAITING;
    }
    if (data.DS_STATUS_PROCESSO.toLowerCase().includes('cancelado')) {
      return SalesOrderStatus.CANCELED;
    }
    return SalesOrderStatus.OK;
  };

  const cancelFeedOrder = () => {
    feedOrdersService.deleteNewFeedOrder(data.ID_PEDIDO.toString()).then(response => {
      feedOrderHistory();
    });
  };

  return (
    <StyledContainer isExpandCard={isExpandCard}>
      <StyledCard isCardForPig={isCardForPig} isExpandCard={isExpandCard} onClick={() => setIsExpandCard(!isExpandCard)}>
        <FeedOrderStatus status={data.DS_STATUS_PROCESSO} applyAnimation={isExpandCard} />
        <StyledHeader isExpandCard={isExpandCard}>
          <StyledInnerContainer>{isExpandCard ? <CircleMinusIconSvg /> : <CirclePlusIconSvg />}</StyledInnerContainer>
          <StyledInnerContainer>
            {t('card.deliveryDate', { deliveryDate: moment(data.DT_PREVISAO_ENVIO).format('DD/MM/YYYY') })}
          </StyledInnerContainer>
          <StyledInnerContainer>
            {t('card.quantity', { quantity: ` ${data.QUANTIDADE} ${data.CD_PRODUTO.includes('SC25KG') ? 'SC25KG' : 'KG'}` })}
          </StyledInnerContainer>
          <StyledInnerContainer>{t('card.salesOrderNumber', { orderNumber: ` N.º${data.ID_PEDIDO}` })}</StyledInnerContainer>
        </StyledHeader>
      </StyledCard>

      {isExpandCard && (
        <StyledDataContainer isExpandCard={isExpandCard}>
          <StyledTextContainer style={{ textAlign: 'left', fontSize: '18px' }}>
            <StyledText>{data.CD_PRODUTO}</StyledText>
          </StyledTextContainer>
          <StyledTextContainer style={{ minWidth: '315px' }}>
            {t('livestock.requestDate')}
            <StyledText>{moment(data.DT_REGISTRO).format('DD/MM/YYYY')}</StyledText>
          </StyledTextContainer>
          <StyledTextContainer>{t('livestock.deliveryProperty')}</StyledTextContainer>
          <StyledTextContainer>
            <StyledText>{data.CD_PROPRIEDADE_ENTREGA}</StyledText>
          </StyledTextContainer>
          <StyledTextContainer>{t('livestock.billingProperty')}</StyledTextContainer>
          <StyledTextContainer>
            <StyledText>{data.CD_PROPRIEDADE_FATURA}</StyledText>
          </StyledTextContainer>

          <SpecialStyledTextContainer>
            <span>{data.DS_CONDICAO_PAGAMENTO}</span>
            <span>{moment(data.DT_PREVISAO_ENVIO).format('DD/MM/YYYY')}</span>
            <StyledText>
              {data.QUANTIDADE} {data.CD_PRODUTO.includes('SC25KG') ? 'SC25KG' : 'KG'}
            </StyledText>
          </SpecialStyledTextContainer>
          {checkStatus() === SalesOrderStatus.WAITING && (
            <>
              <StyledContainerButton>
                {!cancelQuest ? (
                  <StyledButtonInCard onClick={() => setCancelQuest(true)}>{t('global.button.cancelOrder')}</StyledButtonInCard>
                ) : (
                  <>
                    <SubtitleStyled>{`${t('trr.cancelOrder')} ${data.ID_PEDIDO}?`}</SubtitleStyled>
                    <CancelButton
                      onClick={() => {
                        setCancelQuest(false);
                      }}
                      style={{ width: '145px', height: '40px' }}
                    >
                      {t('global.field.no')}
                    </CancelButton>
                    <StyledButton
                      color="frisiaPrimary"
                      onClick={() => {
                        cancelFeedOrder();
                      }}
                      type="button"
                      style={{ minWidth: '145px', height: '42px', marginLeft: '23px', marginTop: '10px' }}
                    >
                      <span>{t('global.field.yes')}</span>
                    </StyledButton>
                  </>
                )}
              </StyledContainerButton>
            </>
          )}
        </StyledDataContainer>
      )}
    </StyledContainer>
  );
};

const StyledCard = styled.div<IStyledProps>`
  @keyframes card_animation {
    from {
      width: 157px;
    }
    to {
      width: 390px;
    }
  }
  position: relative;
  height: ${({ isExpandCard }) => (isExpandCard ? '60px' : '157px')};
  width: ${({ isExpandCard }) => (isExpandCard ? '390px' : '157px')};
  min-width: 157px;
  font-family: ${({ theme }) => theme.font.montserratBold};
  background: ${({ isCardForPig, isExpandCard }) =>
    `url(${
      isCardForPig ? (isExpandCard ? SmallPigHeadIconSvg : PigHeadIconSvg) : isExpandCard ? SmallCowHeadIconSvg : CowHeadIconSvg
    }) no-repeat right center`};
  background-color: ${({ theme }) => theme.color.frisiaPrimary};
  color: white;
  cursor: pointer;
  border-radius: 16px;
  font-size: 11px;
  padding: 0 10px;
  padding-top: ${({ isExpandCard }) => (isExpandCard ? '0' : '17.5px')};
  animation: ${({ isExpandCard }) => (isExpandCard ? ' card_animation 0.3s 0s' : '')};
`;

const StyledHeader = styled.div<IStyledProps>`
  display: flex;
  flex-direction: ${({ isExpandCard }) => (isExpandCard ? 'row' : 'column')};
  row-gap: 5px;
  column-gap: 12px;
  align-items: ${({ isExpandCard }) => (isExpandCard ? 'center' : 'left')};
  max-width: 333px;
  height: 60px;
`;

const StyledInnerContainer = styled.div`
  text-align: left;
`;

const StyledContainer = styled.div<IStyledProps>`
  @keyframes card_animation {
    from {
      width: 157px;
    }
    to {
      width: 390px;
    }
  }
  background-color: white;
  border-radius: 16px;
  max-width: 390px;
  height: ${({ isExpandCard }) => !isExpandCard && '157px'};
  animation: ${({ isExpandCard }) => (isExpandCard ? ' card_animation 0.3s 0s' : '')};
`;

const StyledDataContainer = styled.div<IStyledProps>`
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #404040;
  font-size: 12px;
  span {
    margin-right: 5px;
  }
`;

const StyledTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 22px;
  min-width: 390px;
`;

const SpecialStyledTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 15px;
  min-width: 315px;
  align-items: center;
  text-align: left;
  background-color: #f1efbc;
`;

const StyledText = styled.span`
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const StyledContainerButton = styled.div`
  text-align: end;
  padding: 20px 15px;
`;

const StyledButtonInCard = styled.button`
  font-family: ${({ theme }) => theme.font.montserratMedium};
  background-color: white;
  border: none;
  color: #d96767;
  letter-spacing: 1.05px;
`;

const SubtitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const CancelButton = styled.button`
  width: 245px;
  height: 34px;
  font-size: 14px;
  letter-spacing: 0.02px;
  color: #d4a518;
  text-transform: uppercase;
  opacity: 1;
  border: #d4a518 2px solid;
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  cursor: pointer;
`;

export default withTranslation()(FeedOrdersCard);
