import FinancialApi from 'api/financial-api';
import ButtonDownload from 'components/ButtonDownload';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import FinancialService from 'services/financial-service';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const Taxes = ({ t }: WithTranslation) => {
  const [reportUrl, setReportUrl] = useState<string>();
  const [calendarIRYear, setCalendarIRYear] = useState<number>();

  const getReport = async () => {
    const result = await FinancialService.getTaxCalendarYear();
    setCalendarIRYear(result.filter(item => item.NM_CONFIGURACAO === 'ANO_CALENDARIO_IR')[0].IE_PARAMETRO_INT);
  };

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  useEffect(() => {
    getReport();
  }, []);

  useEffect(() => {
    if (calendarIRYear) {
      setReportUrl(FinancialApi.getTaxReport(calendarIRYear.toString(), getUserRegistration.toString()));
    }
  }, [calendarIRYear]);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('financial.taxes')} />
      </StyledPageTitleContainer>
      <LoadingContainerScreen isLoading={reportUrl === undefined}>
        {reportUrl && (
          <>
            <ButtonDownload style={{ marginLeft: 'auto' }} href={reportUrl} />
            <iframe
              sandbox="allow-scripts allow-popups allow-forms allow-top-navigation"
              style={{ width: '100%', height: '800px' }}
              src={reportUrl}
            />
          </>
        )}
      </LoadingContainerScreen>
    </>
  );
};

export default withTranslation()(Taxes);
