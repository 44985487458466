import styled from 'styled-components';

interface IStyledProp {
  filterWidth?: number;
}

const StyledFilterContainer = styled.div<IStyledProp>`
  position: relative;
  width: ${({ filterWidth }) => (filterWidth ? `${filterWidth}px` : '100%')};
  min-height: 53px;
  max-height: 190px;
`;

export default StyledFilterContainer;
