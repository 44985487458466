import CardNames from 'model/enums/card-names';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getCardInformation } from 'shared/util/card';
import styled from 'styled-components';
import LinkCard from './LinkCard';
import SmallLinkCard from './SmallLinkCard';
interface IProps extends WithTranslation {
  listCardNames: CardNames[];
  spacingBetweenCards: number;
  small?: boolean;
}

interface StyledProps {
  spacingBetweenCards: number;
}

const LinkedCards = ({ listCardNames, spacingBetweenCards, small, t }: IProps) => {
  const cardList = listCardNames
    .filter(element => {
      return !!element;
    })
    .map(cardName => {
      const cardInfomation = getCardInformation(cardName);
      if (small) {
        return (
          <SmallLinkCard
            icon={cardInfomation?.icon}
            text={t(cardInfomation?.i18nText)}
            pathRedirected={cardInfomation?.pathRedirected}
            key={`CARD_${cardName}`}
          />
        );
      }
      return (
        <LinkCard
          icon={cardInfomation.icon}
          text={t(cardInfomation.i18nText)}
          pathRedirected={cardInfomation.pathRedirected}
          key={`CARD_${cardName}`}
        />
      );
    });

  return <StyledContainer spacingBetweenCards={spacingBetweenCards}>{cardList}</StyledContainer>;
};

const StyledContainer = styled.div<StyledProps>`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ spacingBetweenCards }) => `${spacingBetweenCards}px`};
  row-gap: ${({ spacingBetweenCards }) => `${spacingBetweenCards}px`};
`;

export default withTranslation()(LinkedCards);
