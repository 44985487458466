import { AxiosResponse } from 'axios';
import { AccountType } from 'model/account';
import { AccountPages } from 'model/account-allow-pages';
import { sendLog } from 'shared/util/api-utils';
import { apiCore } from './api';

export const searchAccountApi = () => {
  const byName = (name: string): Promise<AxiosResponse<AccountType[]>> => {
    sendLog('Pesquisa de cooperado por nome', `/Coop/GetCoopLikeName?nm_pessoa=${name}`, JSON.stringify([]), 'get');
    return apiCore.get<AccountType[]>(`/Coop/GetCoopLikeName?nm_pessoa=${name}`);
  };

  const byRegister = (register: string): Promise<AxiosResponse<AccountType[]>> => {
    sendLog('Pesquisar cooperado por matricula', `/Suporte/ConsultaCooperado/?cd_pessoa=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<AccountType[]>(`/Suporte/ConsultaCooperado/?cd_pessoa=${register}`);
  };

  const accountPagesList = (array: string[]): Promise<AxiosResponse<AccountPages>> => {
    sendLog(
      'Consulta informações da conta',
      `App/GetAppMenu/?username=${array[0]}&version=17&personId=${array[1]}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<AccountPages>(`/App/GetAppMenu/?username=${array[0]}&version=17&personId=${array[1]}`);
  };

  return {
    byName,
    byRegister,
    accountPagesList,
  };
};

export default searchAccountApi();
