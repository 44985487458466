import GenericDropdown from 'components/GenericDropdown';
import { StyledButton } from 'components/styled-button/StyledButton';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import registrationFormService from 'services/registration-form-service';
import styled from 'styled-components/macro';

const ListOfSprings = ({ area, spring }) => {
  const { t } = useTranslation();

  const [springValue, setSpringValue] = useState<number>(spring?.QT_NASCENTES);
  const [reserve, setReserve] = useState<string>(spring?.IE_RESERVA_LEGAL_AVERBADA);

  const update = () => {
    registrationFormService.updateSpring(spring.NR_PROPRIEDADE, spring?.NR_SEQUENCIA, springValue, reserve);
  };

  useEffect(() => {
    if (springValue && reserve) {
      update();
    }
  }, [springValue, reserve]);

  return (
    <>
      <StyledProperties>
        <StyledTitleSpring>
          {t('environment.register')}
          <div> {spring?.DS_REGISTRO_IMOVEL}</div>
        </StyledTitleSpring>
        <div style={{ display: 'flex' }}>
          <StyledInfo>
            {t('environment.endorsed')}?:
            <GenericDropdown
              defaultValue={reserve === 'S' ? `Sim` : 'Não'}
              listShowDropdown={['Sim', 'Não']}
              getSelectedValue={setReserve}
            />
          </StyledInfo>
          <StyledInfo>
            {t('environment.numberOfSprings')}:{' '}
            <StyledInput
              type={'number'}
              value={springValue}
              maxLength={255}
              onChange={e => setSpringValue(parseInt(e.target.value))}
              style={{
                height: '50px',
                maxWidth: '124px',
                fontSize: '20px',
                border: '2px solid rgb(78, 107, 167)',
                borderRadius: '4px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            />
          </StyledInfo>
        </div>
        <StyledButton color="frisiaPrimary" type="submit" style={{ minWidth: '231px', fontSize: 'smaller' }}>
          <span>{t('environment.updateRegister')}</span>
        </StyledButton>
      </StyledProperties>
    </>
  );
};

const StyledProperties = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 16px;
  text-align: left;
  margin-top: 15px;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  min-height: 80px;
  text-align: left;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  padding: 15px;
  margin: 0 -30px;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const StyledTitleSpring = styled.div`
  display: flex;
  div {
    margin-left: 15px;
    color: #656565;
  }
`;

const StyledInfo = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
  flex-direction: column;
  padding: 5px 8px;
  div {
    text-align: left;
    font-size: 16px/19px;
    font-family: ${({ theme }) => theme.font.montserratMedium};
    letter-spacing: 0.02px;
    color: #656565;
    opacity: 1;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  color: #787878;
  font-size: 14px;
  padding: 0;
`;

export default ListOfSprings;
