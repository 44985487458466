import salesAuthorizationApi from 'api/agricultural/sales-authorization-api';
import { AxiosError, AxiosResponse } from 'axios';
import { IAuthRequest, IDataProductsSalesAuthorization, IHistoryAuthData } from 'model/agricultural-sales-authorization';
import HttpStatus from 'model/enums/http-status';
import i18n from 'config/i18n';
import toastUtils from 'shared/util/toast-utils';
import { IntendedPayment } from 'model/intended-payment';

export const salesAuthorizationService = () => {
  const getMonths = async (cd_safra: string) => {
    try {
      const result: AxiosResponse<IntendedPayment[]> = await salesAuthorizationApi.getIntendedPayment(cd_safra);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getMonths2 = async (cd_safra: string, tp_autorizacao: string) => {
    try {
      const result: AxiosResponse<IntendedPayment[]> = await salesAuthorizationApi.getIntendedPayment2(cd_safra, tp_autorizacao);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getProductsOptions = async (register: string) => {
    try {
      const result: AxiosResponse<IDataProductsSalesAuthorization[]> = await salesAuthorizationApi.getProductsOptions(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const sendSalesAuthorization = async (salesAuth: IAuthRequest[]) => {
    try {
      const result: AxiosResponse<string> = await salesAuthorizationApi.sendSalesAuthorization(salesAuth);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast(result.data.replace('<br/>', ''));
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.Message);
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getHistoryOfSalesAuth = async (register: string) => {
    try {
      const result: AxiosResponse<IHistoryAuthData[]> = await salesAuthorizationApi.getHistoryOfSalesAuth(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const cancelSalesAuth = async (register: string, authNumber: number, bindingNumber: number, motivation: string) => {
    try {
      const result: AxiosResponse<string> = await salesAuthorizationApi.cancelSalesAuth(register, authNumber, bindingNumber, motivation);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast(result.data);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.Message);
      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getMonths,
    getMonths2,
    getProductsOptions,
    sendSalesAuthorization,
    getHistoryOfSalesAuth,
    cancelSalesAuth,
  };
};

export default salesAuthorizationService();
