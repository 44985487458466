import { TFunctionResult } from 'i18next';
import { Fragment, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledTable, StyledTableRow } from 'styled-components/StyledForTables';
import { StyledButton } from './styled-button/StyledButton';

interface IProps extends WithTranslation {
  arrayOfHeaders: string[];
  arrayOfData: (string | number | TFunctionResult)[][];
  isShowFooter?: boolean;
  isDontHaveMaximumWidth?: boolean;
}

interface IStyledProps {
  isApplyBorderRadius: boolean;
}

const TableMultipleHeaders = ({ arrayOfHeaders, arrayOfData, isShowFooter, isDontHaveMaximumWidth, t }: IProps) => {
  return (
    <StyledTable isDontHaveMaximumWidth={isDontHaveMaximumWidth}>
      <Fragment key={`table-MultipleHeaders`}>
        <thead>
          <StyledTableRowHeader isApplyBorderRadius={true}>
            {arrayOfHeaders.map((headerItem, headerItemIndex) => (
              <th key={`header-item-${headerItemIndex}`}>{headerItem}</th>
            ))}
          </StyledTableRowHeader>
        </thead>

        <tbody>
          {arrayOfData.map((rowItem, rowItemIndex) => (
            <StyledTableRow key={`row-item-${rowItemIndex}`} className={`${rowItemIndex === arrayOfData.length - 1 ? 'last-line' : ''}`}>
              {rowItem.map((item, indexItem) => (
                <td key={`column-${indexItem}`}>{item}</td>
              ))}
            </StyledTableRow>
          ))}
        </tbody>

        {isShowFooter && (
          <StyledTableFooter>
            <StyleTableRowFooter>
              <StyledTableData>
                <StyledButton color="frisiaPrimary" type="button" style={{ minWidth: '275px', alignSelf: 'center', fontSize: '8px' }}>
                  <span>{t('global.button.advancedSearch')}</span>
                </StyledButton>
              </StyledTableData>
            </StyleTableRowFooter>
          </StyledTableFooter>
        )}
      </Fragment>
    </StyledTable>
  );
};

const StyledTableRowHeader = styled.tr<IStyledProps>`
  height: 50px;
  background-color: white;
  color: #032762;

  th {
    text-align: center;
  }

  th:nth-child(1) {
    text-align: left;
    padding-left: 20px;
    border-radius: ${({ isApplyBorderRadius }) => (isApplyBorderRadius ? '16px 0 0 0' : '')};
  }

  th:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    border-radius: ${({ isApplyBorderRadius }) => (isApplyBorderRadius ? '0 16px 0 0' : '')};
  }
`;

const StyledTableFooter = styled.tfoot`
  background-color: white;
`;

const StyleTableRowFooter = styled.tr`
  height: 70px;
`;

const StyledTableData = styled.td.attrs({
  colSpan: 2,
})`
  border-radius: 0 0 16px 16px;
`;

export default withTranslation()(TableMultipleHeaders);
