import { AxiosResponse } from 'axios';
import { sendLog } from 'shared/util/api-utils';
import { apiCore, apiCurrency } from './api';

export const swineApi = () => {
  const getPrevSummary = (registration: number): Promise<AxiosResponse<any[]>> => {
    sendLog('Consultar resumo suinos', `/PecuariaSuino/GetResumoPrevio?CD_PESSOA=${registration}`, JSON.stringify([]), 'get');
    return apiCore.get<any[]>(`/PecuariaSuino/GetResumoPrevio?CD_PESSOA=${registration}`);
  };

  return {
    getPrevSummary,
  };
};

export default swineApi();
