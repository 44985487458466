import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Checkbox from 'components/Checkbox';
import { CancelButton, SubtitleStyled } from 'components/CollapsibleCostTable';
import CollapsibleTableLoadLoad from 'components/CollapsibleTableLoadLoad';
import CollapsibleThirdParty from 'components/CollapsibleThirdParty';
import GenericDropdown from 'components/GenericDropdown';
import GenericInputToTable from 'components/GenericInputToTable';
import InputDateWithSetting from 'components/InputDateWithSetting';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import { parse } from 'date-fns';
import { StyledIconContainer } from 'features/livestock/NewFeedOrder';
import { IAuthorization, ILocals, INewAuth, IPayments, IPerson } from 'model/purchasingAuthorization';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import purchasingAuthorizationService from 'services/purchasingAuthorization-service';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import * as yup from 'yup';
import { ReactComponent as ArrowDownSvg } from '../../images/frisia/icons/yellow-icon-arrow-down.svg';

interface IForm {
  cpf: string;
  name: string;
  initDate: string;
  endDate: string;
  local: string;
  itens: string;
}

const useStyles = makeStyles((theme?: any) => ({
  paper: {
    backgroundColor: '#e6eaf2 !important',
    fontSize: '18px !important',
    transition: '2s all ease !important',
  },
}));

const ThirdPartyAuthorization = ({ t }: WithTranslation) => {
  const classes = useStyles();

  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [authorizations, setAuthorizations] = useState<IAuthorization[]>([]);
  const [vigAuthorizations, setVigAuthorizations] = useState<IAuthorization[]>([]);
  const [payments, setPayments] = useState<IPayments[]>([]);
  const [persons, setPersons] = useState<IPerson[]>([]);
  const [chosenPayments, setChosenPayments] = useState<string[]>([]);
  const [locals, setLocals] = useState<ILocals[]>([]);
  const [chosenLocal, setChosenLocal] = useState<ILocals>();
  const [cancelQuest, setCancelQuest] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [onlyVig, setOnlyVig] = useState<boolean>(false);
  const [selectedInitDate, setSelectedInitDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getAuthorizations = async () => {
    await purchasingAuthorizationService.getAll(getUserRegistration).then(response => {
      setAuthorizations(response);
    });
    await purchasingAuthorizationService.getVig(getUserRegistration).then(response => {
      setVigAuthorizations(response);
    });
  };

  const getPayments = async () => {
    await purchasingAuthorizationService.getPaymentForms().then(setPayments);
  };

  const getLocals = async () => {
    await purchasingAuthorizationService.getLocals().then(setLocals);
  };

  useEffect(() => {
    getAuthorizations();
    getPayments();
    getLocals();
    purchasingAuthorizationService.getPersonsByName('').then(setPersons);
  }, []);

  const cancelAuth = (procuration: number) => {
    purchasingAuthorizationService.cancelAuth(getUserRegistration, procuration.toString()).then(_ => getAuthorizations());
  };

  const renderAuth = auths => {
    return auths
      .sort((a, b) => new Date(b.DT_CRIACAO).getTime() - new Date(a.DT_CRIACAO).getTime())
      .map((item, index) => {
        return (
          <CollapsibleThirdParty
            key={index}
            headerItems={[`Autorização: ${item.ID_PROCURACAO}`, `Autorizado: ${item.NM_AUTORIZADO}`, `${item.IE_STATUS}`]}
            analysisDate=""
            auth={item}
            cancelAuth={cancelAuth}
            isInvertLineColor
            isDontHaveMaximumWidth
            style={{ margin: '25px 0' }}
          />
        );
      });
  };

  const addPayment = payment => {
    if (chosenPayments.includes(payment)) {
      setChosenPayments(oldchosen => oldchosen.filter(item => item != payment));
    } else {
      setChosenPayments(oldchosen => [...oldchosen, payment]);
    }
  };

  const schema = yup.object({
    cpf: yup
      .string()
      .required()
      .test('test-invoice', t('global.errorMessage.cpfInvalid'), value =>
        StringUtils.testCPF(value?.replaceAll('.', '').replaceAll('-', ''))
      ),
    name: yup.string().required(),
    initDate: yup.string().test('test-invoice', t('global.errorMessage.required'), () => selectedInitDate != null),
    endDate: yup.string().test('test-invoice', t('global.errorMessage.required'), () => selectedEndDate != null),
    local: yup.string().test('test-invoice', t('global.errorMessage.required'), () => chosenLocal != null),
    itens: yup.string().test('test-invoice', t('global.errorMessage.required'), () => chosenPayments.length > 0),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const handleSubmit = (data: IForm) => {
    if (chosenPayments.length > 0 && selectedInitDate && selectedEndDate && chosenLocal) {
      const newAuth: INewAuth = {
        CD_MATRICULA: getUserRegistration,
        CPF_AUTORIZADO: data.cpf.replaceAll('.', '').replaceAll('-', ''),
        NOME_AUTORIZADO: data.name.toUpperCase(),
        DT_INICIO_VIGENCIA: moment(selectedInitDate.toString()).format('YYYY-MM-DDT00:00:00'),
        DT_FIM_VIGENCIA: moment(selectedEndDate.toString()).format('YYYY-MM-DDT23:59:59'),
        LOCAL_FATURAMENTO: chosenLocal.CD_ORGANIZACAO,
        itens: chosenPayments.map(item => {
          return {
            TIPO_PAGAMENTO: item,
          };
        }),
      };
      purchasingAuthorizationService.sendNewAuth(newAuth);
    }
  };

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 35px 0'}>
        <MainPageTitle fontSize={30} text={t('agricultural.purchasingAuthorization')} />
      </StyledPageTitleContainer>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {showForm && (
            <Grid container margin={'0px 0 35px 0'}>
              <StyledPageTitleContainer margin={'0px 0 15px 0'}></StyledPageTitleContainer>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <GenericInputToTable
                    mask={'999.999.999-99'}
                    inputName="cpf"
                    label={t('livestock.cpf')}
                    placeholder={t('livestock.setcpf')}
                  />
                  <StyledErrorMessage style={{ position: 'initial' }}>{methods.formState.errors.cpf?.message}</StyledErrorMessage>
                </Grid>
                <Grid item xs={6}>
                  <GenericInputToTable inputName="name" label={t('livestock.name')} placeholder={t('livestock.setName')} />
                  <StyledErrorMessage style={{ position: 'initial' }}>{methods.formState.errors.name?.message}</StyledErrorMessage>
                </Grid>
              </Grid>
              <StyledPageTitleContainer margin={'2px 0 6px 2px'} style={{ width: '100%' }}>
                <MainPageTitle fontSize={16} text={t('purchasingAuthorization.validity')} />
              </StyledPageTitleContainer>
              <Grid item xs={6}>
                <InputDateWithSetting
                  disableWeekends
                  label={t('purchasingAuthorization.initDate')}
                  setDate={setSelectedInitDate}
                  date={selectedInitDate}
                />
              </Grid>
              <Grid item xs={6}>
                <InputDateWithSetting
                  disableWeekends
                  label={t('purchasingAuthorization.endDate')}
                  setDate={setSelectedEndDate}
                  date={selectedEndDate}
                />
              </Grid>
              <StyledErrorMessage>
                {methods.formState.errors.initDate?.message || methods.formState.errors.endDate?.message}
              </StyledErrorMessage>

              <Grid item xs={12}>
                <StyledPageTitleContainer margin={'2px 0 6px 2px'} style={{ width: '100%' }}>
                  <MainPageTitle fontSize={16} text={t('purchasingAuthorization.local')} />
                </StyledPageTitleContainer>
                <StyledFilterContainer>
                  <GenericDropdown
                    label={t('purchasingAuthorization.local')}
                    placeholder={t('livestock.selectDestination')}
                    listShowDropdown={locals}
                    keyToShowInDropdown="FULL_ORGANIZACAO"
                    getSelectedValue={setChosenLocal}
                    errorText={methods.formState.errors.local?.message}
                  />
                </StyledFilterContainer>
              </Grid>
              <StyledPageTitleContainer margin={'2px 0 6px 2px'} style={{ width: '100%' }}>
                <MainPageTitle fontSize={16} text={t('purchasingAuthorization.paymentMethods')} />
              </StyledPageTitleContainer>
              <Grid container spacing={1}>
                {payments.map((paymentType, index) => (
                  <Grid item xs={3} key={`payment-${index}`} style={{ paddingTop: '0px' }}>
                    <StyledCheckBoxContainer>
                      <StyledCheckbox
                        type="checkbox"
                        id={paymentType.TIPO_PAGAMENTO}
                        onChange={() => addPayment(paymentType.TIPO_PAGAMENTO)}
                      />
                      <label htmlFor={paymentType.TIPO_PAGAMENTO}>{paymentType.TIPO_PGTO_EXIBIR}</label>
                    </StyledCheckBoxContainer>
                  </Grid>
                ))}
              </Grid>
              <StyledErrorMessage>{methods.formState.errors.itens?.message}</StyledErrorMessage>
            </Grid>
          )}

          {showForm ? (
            <div style={{ height: '98px' }}>
              <CancelButton type="button" onClick={() => setShowForm(!showForm)} style={{ width: '145px', height: '40px' }}>
                {t('global.button.cancel')}
              </CancelButton>
              <StyledButton
                color="frisiaPrimary"
                type="submit"
                style={{ minWidth: '145px', height: '42px', marginLeft: '23px', marginTop: '10px' }}
              >
                <span>{t('purchasingAuthorization.send')}</span>
              </StyledButton>
            </div>
          ) : (
            <StyledButton color="frisiaPrimary" type="button" onClick={() => setShowForm(!showForm)} style={{ minWidth: '400px' }}>
              <span>{t('global.button.newAuthorization')}</span>
            </StyledButton>
          )}
        </form>
      </FormProvider>

      <StyledCheckContainer>
        <input type={'checkbox'} onChange={() => setOnlyVig(!onlyVig)} />
        <div>{t('purchasingAuthorization.showAuth')}</div>
      </StyledCheckContainer>
      {!onlyVig ? renderAuth(authorizations) : renderAuth(vigAuthorizations)}
    </>
  );
};

const StyledCheckBoxContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  min-height: 53px;
  max-height: 190px;
`;

const StyledCheckContainer = styled.div`
  display: flex;
  margin: 25px;
  align-items: baseline;

  div {
    margin-left: 15px;
  }
`;

const StyledCheckbox = styled.input`
  height: 15px;
  width: 15px;
  margin: 0 12px 0 25px;
`;

const StyledSearch = styled.div`
  display: flex;
  position: absolute;
  min-height: 53px;
  min-width: 100%;
  text-align: left;
  border: 2px solid #4e6ba7;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f1f1f1;
  z-index: 1;
  padding-left: 9px;
  label {
    transform: translate(0, 3.5px) scale(0.75);
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: #2a5299 !important;
  }
  button {
    margin-right: -37px;
    margin-top: -19px;
    height: 52px;
    border-radius: 0px;
    width: 45px;
  }
  .MuiAutocomplete-root {
    svg {
      visibility: hidden;
      z-index: 10;
    }
  }
`;

const StyledErrorMessage = styled.span`
  position: relative;
  bottom: 0;
  left: 1px;

  font-family: ${({ theme }) => theme.font.montserratRegular};
  font-size: 12px;
  color: red;
`;

export default withTranslation()(ThirdPartyAuthorization);
