import rawMaterialScheduleApi from 'api/swine/raw-material-schedule-api';
import { AxiosError, AxiosResponse } from 'axios';
import { ICampaignsType, IRawMaterials } from 'model/material-schedule';

export const rawMaterialScheduleService = () => {
  const getCampaigns = async (registration: number) => {
    try {
      const result: AxiosResponse<ICampaignsType[]> = await rawMaterialScheduleApi.getCampaigns(registration);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getRawMaterials = async (registration: number, campaign: number) => {
    try {
      const result: AxiosResponse<IRawMaterials[]> = await rawMaterialScheduleApi.getRawMaterials(registration, campaign);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getCampaigns,
    getRawMaterials,
  };
};

export default rawMaterialScheduleService();
