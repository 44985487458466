import IMyAnimal from 'model/my-animal';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import GenericButtonWhiteWithYellow from './GenericButtonWhiteWithYellow';

interface IProps extends WithTranslation {
  animalInformation: IMyAnimal;
  list: boolean;
}

const AnimalDetailsCard = ({ animalInformation, list, t }: IProps) => {
  const history = useHistory();
  if (list) {
    return (
      <ListStyledContainer>
        <StyledPhoto src={animalInformation.image} alt={`foto do animal ${animalInformation.name}`} />
        <ListStyledDataContainer>
          <div>
            <span>
              <b>{t('livestock.name')}</b>
            </span>
            <span className="highlight">{animalInformation.name}</span>
          </div>
          <div>
            <span>
              <b>{t('livestock.birth')}</b>
            </span>
            <span className="data-style">{animalInformation.birth}</span>
          </div>
          <div>
            <span>
              <b>{t('livestock.property')}</b>
            </span>
            <span className="data-style">{animalInformation.property}</span>
          </div>
          <div>
            <span>
              <b>{t('livestock.sex')}</b>
            </span>
            <span className="data-style">{animalInformation.sex}</span>
          </div>
          <div>
            <span>
              <b>{t('livestock.registry')}</b>
            </span>
            <span className="data-style">{animalInformation.registry}</span>
          </div>
        </ListStyledDataContainer>
        <Lastcontents>
          <ListStyledCategory>{animalInformation.category}</ListStyledCategory>
          <GenericButtonWhiteWithYellow
            textButton={t('global.button.viewMore')}
            width={35}
            onClick={() => history.push('/pecuaria-bovinos/meu-plantel/detalhes')}
          />
        </Lastcontents>
      </ListStyledContainer>
    );
  } else {
    return (
      <StyledContainer>
        <StyledCategory>{animalInformation.category}</StyledCategory>
        <StyledPhoto src={animalInformation.image} alt={`foto do animal ${animalInformation.name}`} />
        <StyledInnerContainer>
          <StyledDataContainer>
            <StyledDescription>
              <span>{t('livestock.name')}</span>
              <span>{t('livestock.birth')}</span>
              <span>{t('livestock.property')}</span>
              <span>{t('livestock.sex')}</span>
              <span>{t('livestock.registry')}</span>
            </StyledDescription>
            <StyledDescription className="data-style">
              <span className="highlight">{animalInformation.name}</span>
              <span>{animalInformation.birth}</span>
              <span>{animalInformation.property}</span>
              <span>{animalInformation.sex}</span>
              <span>{animalInformation.registry}</span>
            </StyledDescription>
          </StyledDataContainer>
          <div>
            <GenericButtonWhiteWithYellow
              textButton={t('global.button.viewMore')}
              width={35}
              onClick={() => history.push('/pecuaria-bovinos/meu-plantel/detalhes')}
            />
          </div>
        </StyledInnerContainer>
      </StyledContainer>
    );
  }
};

const ListStyledContainer = styled.div`
  display: flex;
  position: relative;
  min-height: 80px;
  width: 100%;
  flex-flow: wrap;
  background-color: #fcfcfc;
  box-shadow: 0px 5px 10px #1d1d1d33;
  border-radius: 15px;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  align-items: center;
  justify-content: space-between;
  &.highlight {
    font-family: ${({ theme }) => theme.font.montserratBold};
  }
  &.data-style {
    font-family: ${({ theme }) => theme.font.montserratRegular};
    font-size: 12px;
    color: #656565;
  }
  img {
    width: 80px;
  }
`;

const ListStyledDataContainer = styled.div`
  flex: 1;
  max-height: 100px;
  min-width: 50px;
  display: flex;
  flex-flow: wrap;
  align-content: center;
  span {
    margin: 10px;
  }
`;

const ListStyledCategory = styled.span`
  width: 115px;
  height: 19px;

  font-family: ${({ theme }) => theme.font.montserratBold};
  color: #fcfcfc;
  font-size: 12px;
  border-radius: 16px;
  background-color: #32d175;
`;

const Lastcontents = styled.span`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-right: 20px;
`;

const StyledContainer = styled.div`
  display: flex;
  position: relative;

  height: 220px;
  width: 390px;

  background-color: #fcfcfc;
  box-shadow: 0px 5px 10px #1d1d1d33;
  border-radius: 15px;
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 12px;
  color: ${({ theme }) => theme.color.frisiaPrimary};
`;

const StyledPhoto = styled.img`
  height: 100%;
  width: 115px;

  border-radius: 15px 0 0 15px;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledDataContainer = styled.div`
  display: flex;
  column-gap: 30px;

  padding: 18px 18px 15px 18px;
  text-align: left;
`;

const StyledDescription = styled.div`
  span {
    display: block;
    margin-bottom: 8px;

    &.highlight {
      font-family: ${({ theme }) => theme.font.montserratBold};
    }
  }

  &.data-style {
    font-family: ${({ theme }) => theme.font.montserratRegular};
    font-size: 12px;
    color: #656565;
  }
`;

const StyledCategory = styled.span`
  position: absolute;
  top: -9px;
  left: 250px;

  width: 115px;
  height: 19px;

  font-family: ${({ theme }) => theme.font.montserratBold};
  color: #fcfcfc;
  font-size: 12px;
  border-radius: 16px;
  background-color: #32d175;
`;

export default withTranslation()(AnimalDetailsCard);
