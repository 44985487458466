import { ITypeProducts } from 'model/product-my-stock';
import { Trans, withTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IProps {
  data: ITypeProducts;
}

const MyStockCard = ({ data }: IProps) => {
  const totalEntries = data.DETALHES.filter(item => item.DS_TIPO_SALDO === 'Total das Entradas')[0];
  const availableStock = data.DETALHES.filter(item => item.DS_TIPO_SALDO === 'Estoque Disponível')[0];
  const branch = data.NM_REDUZIDO;

  return (
    <StyledContainer>
      <StyledTitle>{data.DS_PRODUTO}</StyledTitle>
      <StyledMiddleContainer>
        <StyledDataContainer>
          <span>
            <Trans i18nKey="agricultural.totalEntries">
              <span className="style-text-value" key={'totalEntries'}>
                {{ value: totalEntries.QT_SALDO_EGR }}
              </span>
            </Trans>
          </span>
          <span>
            <Trans i18nKey="agricultural.inventory">
              <span className="style-text-value">{{ value: availableStock.QT_SALDO_EGR }}</span>
            </Trans>
          </span>
        </StyledDataContainer>
        <StyledDataContainer>
          <span>
            <Trans i18nKey="agricultural.branchValue">
              <span className="style-text-value">{{ value: branch }}</span>
            </Trans>
          </span>
        </StyledDataContainer>
        <StyledDataContainer>
          <span>
            <Trans i18nKey="agricultural.productValue">
              <span className="style-text-value">{{ value: `${data.CD_PRODUTO} - ${data.DS_PRODUTO}` }}</span>
            </Trans>
          </span>
        </StyledDataContainer>
      </StyledMiddleContainer>

      {data.DETALHES.map((item, index) => (
        <StyledDataContainer key={index}>
          <span key={`${index}1`}>
            {item.DS_TIPO_SALDO} <span className="text-orange">({item.IE_TIPO_OPERACAO})</span>
            {':'}
          </span>
          <span className="style-text-value" key={`${index}2`}>
            {item.QT_SALDO_EGR}
          </span>
        </StyledDataContainer>
      ))}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 400px;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 16px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  padding: 20px;
  span {
    margin: 7px 0;
  }
  .style-text-value {
    color: #404040;
  }
  .style-text-red-value {
    color: #d96767;
  }
  .text-orange {
    color: #d4a518;
  }
`;

const StyledTitle = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: #032762;
  text-align: left;
`;

const StyledMiddleContainer = styled.div`
  border-top: 1.9px solid #d7cc27;
  border-bottom: 1.9px solid #d7cc27;
  margin: 10px 0;
`;

const StyledDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default withTranslation()(MyStockCard);
