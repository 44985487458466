import { AxiosResponse } from 'axios';
import { IBull } from '../../model/bull';
import Mocker from '../mock-api';
import bullJson from '../mocks/bull.json';
import bullCatalogJson from '../mocks/bullCatalog.json';

export const bullApi = () => {
  const getListBulls = (): Promise<AxiosResponse<IBull[]>> => {
    return new Mocker<IBull[]>().mock(bullCatalogJson as unknown as string);
  };

  const getBull = (bullId: number): Promise<AxiosResponse<IBull>> => {
    return new Mocker<IBull>().mock(bullJson as unknown as string);
  };

  return {
    getListBulls,
    getBull,
  };
};

export default bullApi();
