import { ITypeTable } from 'model/type-table';
import { useState } from 'react';
import styled from 'styled-components';
import { StyledBreakTableRow, StyledTable, StyledTableRow } from 'styled-components/StyledForTables';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/white-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/white-plus-round-icon.svg';

interface IProps extends ITypeTable {
  analysisDate: string;
}

const CollapsibleTable = ({ analysisDate, headerItems, tableItems, isInvertLineColor, isDontHaveMaximumWidth, style }: IProps) => {
  const getValues = tableItems.map(row => Object.values(row));
  const [isShowTableItems, setIsShowTableItems] = useState<boolean>(false);

  return (
    <StyledTable isInvertLineColor={isInvertLineColor} isDontHaveMaximumWidth={isDontHaveMaximumWidth} style={style}>
      <thead>
        <StyledTableRowHeader onClick={() => setIsShowTableItems(!isShowTableItems)}>
          {headerItems?.map((headerItem, index) => (
            <th key={`header-item-${index}`}>
              {index === 0 && (isShowTableItems ? <MinusRoundIconSvg /> : <PlusRoundIconSvg />)}
              <StyledTextHeader className={`${index === 0 ? 'firstCell' : ''}`}>{headerItem}</StyledTextHeader>
            </th>
          ))}
        </StyledTableRowHeader>
      </thead>

      <tbody>
        {isShowTableItems && (
          <>
            {analysisDate && (
              <StyledTableRow>
                <StyledTableData>{analysisDate}</StyledTableData>
              </StyledTableRow>
            )}
            {getValues.map((tableRow, indexRow) => {
              if (tableRow[0] === 'break') {
                return <StyledBreakTableRow key={`row-${indexRow}`}></StyledBreakTableRow>;
              } else if (tableRow[0] === '' && tableRow[1] === '') {
                return;
              } else {
                return (
                  <StyledTableRow key={`row-${indexRow}`}>
                    {tableRow.map((tableItem, indexItem) => (
                      <td key={`item-${indexItem}`}>{tableItem}</td>
                    ))}
                  </StyledTableRow>
                );
              }
            })}
          </>
        )}
      </tbody>
    </StyledTable>
  );
};

const StyledTableRowHeader = styled.tr`
  height: 40px;
  color: #fcfcfc;
  cursor: pointer;

  th {
    background-color: ${({ theme }) => theme.color.frisiaPrimaryDark};
  }

  th:nth-child(1) {
    display: flex;
    align-items: center;

    height: 40px;
    text-align: left;
    padding-left: 9px;
    border-radius: 16px 0 0 16px;
  }

  th:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    border-radius: 0 16px 16px 0;
  }
`;

const StyledTextHeader = styled.span`
  font-family: ${({ theme }) => theme.font.montserratRegular};

  &.firstCell {
    margin-left: 10px;
    color: #d7cc27;
  }
`;

const StyledTableData = styled.td`
  font-family: ${({ theme }) => theme.font.montserratBold} !important;
  color: ${({ theme }) => theme.color.frisiaPrimaryDark} !important;
  text-align: left !important;
`;
export default CollapsibleTable;
