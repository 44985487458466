import { AxiosResponse } from 'axios';
import { IEnvStatus } from 'model/environmental';
import { encryptReport, sendLog } from 'shared/util/api-utils';
import { apiCore } from './api';

export const environmentalApi = () => {
  const getEnvStatus = (): Promise<AxiosResponse<IEnvStatus[]>> => {
    sendLog('Consulta de documentos ambientais', `/IRA/StatusPossiveisIRA`, JSON.stringify([]), 'get');
    return apiCore.get(`/IRA/StatusPossiveisIRA`);
  };
  const getIRADocListReport = (register: string, status: string) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/IRA/IRA&CD_PESSOA=${register}&DS_STATUS=${status}`;
    sendLog('Relatorio IRA', path, JSON.stringify({}), 'get');
    return encryptReport(path);
  };
  const getIRADocListWithoutStatusReport = (register: string) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/IRA/IRA&CD_PESSOA=${register}`;
    sendLog('Relatorio IRA', path, JSON.stringify({}), 'get');
    return encryptReport(path);
  };
  return {
    getEnvStatus,
    getIRADocListReport,
    getIRADocListWithoutStatusReport,
  };
};

export default environmentalApi();
