import CollapsibleContract from 'components/CollapsibleContract';
import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import WhiteAndBlueFilterCard from 'components/WhiteAndBlueFilterCard';
import FilterCardType from 'model/enums/FilterCardType';
import { ITypeMyStockCard } from 'model/product-my-stock';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import StyledCardsFilterContainer from 'styled-components/StyledCardsFilterContainer';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { ReactComponent as BarleyIconSvg } from '../../images/frisia/icons/blue-barley-icon.svg';
import { ReactComponent as BeanIconSvg } from '../../images/frisia/icons/bean-svgrepo-com.svg';
import { ReactComponent as CornIconSvg } from '../../images/frisia/icons/blue-corn-icon.svg';
import { ReactComponent as ForagerIconSvg } from '../../images/frisia/icons/blue-forager-icon.svg';
import { ReactComponent as SoyIconSvg } from '../../images/frisia/icons/blue-soy-icon.svg';
import { ReactComponent as OneDotIconSvg } from '../../images/frisia/icons/blue-checked-checkbox-icon.svg';
import agriculturalService from 'services/agricultural/agricultural-service';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import CollapsibleInvoice from 'components/CollapsibleInvoice';
import CollapsibleLiquidatedCredited from 'components/CollapsibleLiquidatedCredited';
import { OptionsSafraProductsResponse, SalesStatmentsResponses } from 'model/agriculturalSalesStatement';
import ButtonDownload from 'components/ButtonDownload';
import agriculturalApi from 'api/agricultural/agricultural-api';

const SalesStatement = ({ t }: WithTranslation) => {
  const [selectedFilterCard, setSelectedFilterCard] = useState<ITypeMyStockCard>();
  const [dateInYears, setDateInYears] = useState<string[]>([]);
  const [productsListByYear, setProductsListByYear] = useState<OptionsSafraProductsResponse[]>([]);
  const [chosenYear, setChosenYear] = useState<string>('');
  const [salesByContract, setSalesByContract] = useState<SalesStatmentsResponses>();
  const [liquidatedCredited, setLiquidatedCredited] = useState<SalesStatmentsResponses>();
  const [salesInvoice, setSalesInvoice] = useState<SalesStatmentsResponses>();
  const [cards, setCards] = useState<ITypeMyStockCard[]>([]);

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getDataOptions = async (registration: number) => {
    await agriculturalService.getHarvestAndProducts(registration.toString()).then(response => {
      response.map(item => {
        setDateInYears(data => [...data, item.CD_SAFRA_TEXT]);
      });
      setProductsListByYear(response);
    });
  };

  const generateCards = () => {
    const dataList: OptionsSafraProductsResponse[] = productsListByYear.filter(item => item.CD_SAFRA_TEXT === chosenYear);

    dataList[0]?.PRODUTOS.map(item => {
      let icon = <OneDotIconSvg style={{ width: '40px' }} />;
      if (item.DS_PRODUTO.toLowerCase().includes(FilterCardType.BEAN.toLowerCase())) {
        icon = <BeanIconSvg style={{ width: '40px' }} />;
      }
      if (item.DS_PRODUTO.toLowerCase().includes(FilterCardType.SOY.toLowerCase())) {
        icon = <SoyIconSvg />;
      }
      if (item.DS_PRODUTO.toLowerCase().includes(FilterCardType.CORN.toLowerCase())) {
        icon = <CornIconSvg />;
      }
      if (item.DS_PRODUTO.toLowerCase().includes(FilterCardType.BARLEY.toLowerCase())) {
        icon = <BarleyIconSvg />;
      }
      if (item.DS_PRODUTO.toLowerCase().includes(FilterCardType.FORAGERS.toLowerCase())) {
        icon = <ForagerIconSvg style={{ width: '40px' }} />;
      }
      let novoCard = { icon: icon, text: item.DS_PRODUTO, productsList: [], otherInfos: item };
      setCards(cards => [...cards, novoCard]);
    });
  };

  useEffect(() => {
    getDataOptions(getUserRegistration);
  }, []);

  useEffect(() => {
    setCards([]);
    if (chosenYear) {
      generateCards();
    }
  }, [chosenYear]);

  const getSalesByContract = async (registration: number) => {
    await agriculturalService
      .getSalesByContracts(registration.toString(), chosenYear.replace('/', ''), selectedFilterCard?.otherInfos?.CD_PRODUTO)
      .then(response => {
        console.log('selectedsalesByContractProductInfo', response);
        setSalesByContract(response);
      });
  };

  const getLiquidatedCreditedAndSummary = async (registration: number) => {
    await agriculturalService
      .getLiquidatedCreditedAndSummary(
        registration.toString(),
        chosenYear.replace('/', ''),
        selectedFilterCard?.otherInfos?.CD_PRODUTO,
        selectedFilterCard?.otherInfos?.CD_PADRAO,
        selectedFilterCard?.otherInfos?.CD_TIPAGEM
      )
      .then(response => {
        console.log('getLiquidatedCreditedAndSummary', response);
        setLiquidatedCredited(response);
      });
  };

  const getSalesInvoice = async (registration: number) => {
    await agriculturalService
      .getSalesInvoice(registration.toString(), chosenYear.replace('/', ''), selectedFilterCard?.otherInfos?.CD_PRODUTO)
      .then(response => {
        console.log('getSalesInvoice', response);
        setSalesInvoice(response);
      });
  };

  useEffect(() => {
    if (selectedFilterCard) {
      getLiquidatedCreditedAndSummary(getUserRegistration);
      getSalesByContract(getUserRegistration);
      getSalesInvoice(getUserRegistration);
    }
  }, [selectedFilterCard]);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={20} text={t('agricultural.salesStatement')} />
      </StyledPageTitleContainer>

      <StyledPageTitleContainer margin={'25px 0 14px 0'}>
        <MainPageTitle fontSize={20} text={t('agricultural.crop')} />
      </StyledPageTitleContainer>
      <StyledFilterContainer filterWidth={330}>
        <GenericDropdown
          label={t('global.field.filterYear')}
          placeholder={t('global.field.filterSelectYear')}
          listShowDropdown={dateInYears}
          getSelectedValue={setChosenYear}
        />
      </StyledFilterContainer>

      <StyledCardsFilterContainer>
        {cards.map((item, index) => (
          <WhiteAndBlueFilterCard
            isCardActive={item === selectedFilterCard}
            icon={item.icon}
            text={item.text}
            onClick={() => setSelectedFilterCard(item)}
            key={`card-${index}`}
          />
        ))}
        {selectedFilterCard && (
          <ButtonDownload
            href={agriculturalApi.generateSalesReport(
              getUserRegistration,
              selectedFilterCard?.otherInfos?.CD_PRODUTO,
              chosenYear.replace('/', ''),
              chosenYear.replace('/', '')
            )}
            style={{ width: '174px', marginTop: '5px' }}
          />
        )}
      </StyledCardsFilterContainer>
      <ContainerInfo>
        <MainPageTitle fontSize={20} text={t('agricultural.creditedSales')} />
        {liquidatedCredited?.Dados.length === 0 && <SubtitleInfo>{t('agricultural.subtitle')}</SubtitleInfo>}
      </ContainerInfo>

      <ContainerTables>
        <div style={{ width: '50%' }}>
          {liquidatedCredited?.Total.map((item, index) => (
            <StyledSalesTable key={index}>
              <Subtitle>{t('agricultural.totais')}</Subtitle>
              <table>
                <tbody>
                  <tr>
                    <td>{t('agricultural.weight')}</td>
                    <td>{item.QT_PESO_VENDA.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.price')}</td>
                    <td>{item.VL_TOTAL_NF_VENDA.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.capital')}</td>
                    <td>{item.VL_CAP.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.fei')}</td>
                    <td>{item.VL_FEI.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.admin')}</td>
                    <td>{item.VL_ADM.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.inss')}</td>
                    <td>{item.VL_INSS.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.senar')}</td>
                    <td>{item.VL_SENAR.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.otherDiscounts')}</td>
                    <td>{item.VL_OUTROS_DESC.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.shipping')}</td>
                    <td>{item.VL_FRETE_NF.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.finished')}</td>
                    <td>{item.VL_LIQUIDO_CREDITO.toLocaleString('pt-BR')}</td>
                  </tr>
                </tbody>
              </table>
            </StyledSalesTable>
          ))}
        </div>
        <ListofContracts>
          {liquidatedCredited?.Dados.map((item, index) => (
            <CollapsibleLiquidatedCredited key={index} contractData={item} />
          ))}
        </ListofContracts>
      </ContainerTables>

      <ContainerInfo>
        <MainPageTitle fontSize={20} text={t('agricultural.contractSales')} />
        {salesByContract?.Dados.length === 0 && <SubtitleInfo>{t('agricultural.subtitle2')}</SubtitleInfo>}
      </ContainerInfo>

      <ContainerTables>
        <div style={{ width: '50%' }}>
          {salesByContract?.Total.map((item, index) => (
            <StyledSalesTable key={index}>
              <Subtitle>{t('agricultural.totais')}</Subtitle>
              <table>
                <tbody>
                  <tr>
                    <td>{t('agricultural.theAmount')}</td>
                    <td>{item.QT_VINCULADA.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.grossValue')}</td>
                    <td>{item.VL_TOTAL_S_DESCONTO.toLocaleString('pt-BR')}</td>
                  </tr>
                </tbody>
              </table>
            </StyledSalesTable>
          ))}
          {liquidatedCredited?.Resumo?.map((item, index) => (
            <StyledSalesTable key={index}>
              <Subtitle>{t('agricultural.priceSummary')}</Subtitle>
              <table>
                <tbody>
                  <tr>
                    <td>{t('agricultural.min')}</td>
                    <td>{t('agricultural.med')}</td>
                    <td>{t('agricultural.max')}</td>
                  </tr>
                  <tr>
                    <td>{item.VL_MINIMO}</td>
                    <td>{item.VL_MEDIA}</td>
                    <td>{item.VL_MAXIMO}</td>
                  </tr>
                </tbody>
              </table>
            </StyledSalesTable>
          ))}
        </div>
        <ListofContracts>
          {salesByContract?.Dados.map((item, index) => (
            <CollapsibleContract key={index} contractData={item} />
          ))}
        </ListofContracts>
      </ContainerTables>

      <ContainerInfo>
        <MainPageTitle fontSize={20} text={t('agricultural.invoice')} />
        {salesInvoice?.Dados.length === 0 && <SubtitleInfo>{t('agricultural.subtitle3')}</SubtitleInfo>}
      </ContainerInfo>
      <ContainerTables>
        <div style={{ width: '50%' }}>
          {salesInvoice?.Total.map((item, index) => (
            <StyledSalesTable key={index}>
              <Subtitle>{t('agricultural.totais')}</Subtitle>
              <table>
                <tbody>
                  <tr>
                    <td>{t('agricultural.theAmount')}</td>
                    <td>{item.QT_SALDO.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.price')}</td>
                    <td>{item.VL_LIQUIDO.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.admin')}</td>
                    <td>{item.VL_ADM.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.capital')}</td>
                    <td>{item.VL_CAPITAL.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.senar')}</td>
                    <td>{item.VL_SENAR.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.inss')}</td>
                    <td>{item.VL_INSS.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.rat')}</td>
                    <td>{item.VL_GILRAT.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.shipping')}</td>
                    <td>{item.VL_FRETE_TOTAL.toLocaleString('pt-BR')}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.liquid')}</td>
                    <td>{item.VL_LIQUIDO_FINAL.toLocaleString('pt-BR')}</td>
                  </tr>
                </tbody>
              </table>
            </StyledSalesTable>
          ))}
        </div>
        <ListofContracts>
          {salesInvoice?.Dados.map((item, index) => (
            <CollapsibleInvoice key={index} contractData={item} />
          ))}
        </ListofContracts>
      </ContainerTables>
    </>
  );
};

const ContainerTables = styled.div`
  display: flex;
  flex: 1 1 0px;
`;

const ListofContracts = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
`;

const ContainerInfo = styled.div`
  display: flex;
  height: fit-content;
  flex-flow: column;
  margin-top: 32px;
  justify-content: space-between;
`;

const SubtitleInfo = styled.div`
  text-align: left;
`;

const Subtitle = styled.div`
  margin: 20px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0px;
  height: 22px;
  text-align: left;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimaryDark};
`;

const StyledSalesTable = styled.div`
  display: flex;
  flex-flow: column;
  margin: 30px 0px;
  margin-right: 15px;
  font-size: 16px;
  min-height: 132px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  height: fit-content;
  min-width: 300px;
  width: 300px;
  table {
    width: 100%;
  }
  td,
  th {
    padding: 8px;
    text-align: left;
    padding-left: 23px;
    height: 48px;
  }
  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  tr:hover {
    background-color: #ddd;
  }
`;

export default withTranslation()(SalesStatement);
