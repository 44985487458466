import LinkedCards from 'components/LinkedCards';
import MainPageTitle from 'components/MainPageTitle';
import QuoteDashboardTable from 'components/QuoteDashboardTable';
import CardNames from 'model/enums/card-names';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import fastWatchIcon from '../../images/frisia/icons/blue-moving-clock-icon.svg';
import { ReactComponent as PlusSvg } from '../../images/frisia/icons/blue-plus-icon.svg';
import ScheduleTable from 'components/ScheduleTable';
import WeatherTable from 'components/WeatherTable';
import NewsTable from 'components/NewsTable';
import PodcastPlayer from 'components/podcast-player/PodcastPlayer';
import Slider from 'react-slick';
import contentfulService from 'services/contentful-service';
import shorcutService from 'services/shortcuts-service';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { Asset, ContentfullItem } from 'model/contentful-results';

const StyledTablesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 37px;
  margin-top: -85px;
  zoom: 100%;
  width: 100%;
  @media (max-width: 1300px) {
    zoom: 95%;
  }
  @media (max-width: 1250px) {
    zoom: 90%;
  }
  @media (max-width: 1200px) {
    zoom: 85%;
  }
  @media (max-width: 1120px) {
    zoom: 80%;
  }
  @media (max-width: 1059px) {
    zoom: 70%;
  }
  @media (max-width: 945px) {
    zoom: 60%;
  }
  @media (max-width: 770px) {
    zoom: 55%;
  }
`;

const StyledCardsContainer = styled.div`
  max-width: 1130px;
  margin: 19px auto 37px auto;
  display: flex;
  align-self: flex-start;
`;

const StyledTablesLeftContainer = styled.div`
  min-width: 584px;
  width: unset;
  margin: auto;
  text-align: -webkit-center;
  @media (max-width: 787px) {
    flex-direction: column;
    align-items: center;
    margin: none;
  }

  /* fix arrows slider safari */
  .slick-next {
    width: 30px;
    height: 30px;
  }

  .slick-prev {
    width: 65px;
    height: 30px;
  }
`;

const StyledTablesRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 65px;
  width: 49%;
  min-width: 375px;
  width: unset;
  margin: 0px auto;
  margin-top: 65px;
`;

const StyledCard = styled.div`
  width: 104px;
  height: 104px;
  padding: 19px;
  cursor: pointer;
  font-size: 11px;
  display: flex;
  flex-flow: column;
  align-items: center;
  svg {
    max-height: 30px;
    max-width: 30px;
  }
`;

const FastWatchIconStyled = styled.img`
  width: 28px;
  height: 20px;
  margin-right: 6px;
`;

const PlusIconStyled = styled.div`
  border-radius: 16px;
  border: 2px dashed #2a5299;
  width: 56px;
  height: 52px;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FavoritesContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

const CarouselNewsStyle = {
  marginTop: '71px',
  minWidth: '520px',
  marginLeft: '-10px',
  maxWidth: '545px',
  width: '520px',
};

const Dashboard = ({ t }: WithTranslation) => {
  const [favorites, setFavorites] = useState<CardNames[]>([]);
  const history = useHistory();
  const [newsArray, setNewsArray] = useState<ContentfullItem[]>([]);
  const [newsImages, setNewsImages] = useState<Asset[]>([]);
  const [contentAudios, setContentAudios] = useState<Asset[]>([]);
  const [checkState, setCheckState] = useState<boolean>(true);

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getFavorites = async () => {
    if (getUserRegistration != null) {
      const resultPages = await shorcutService.getFavorite(getUserRegistration);
      const favoritesPages = resultPages.map(item => {
        if (CardNames[item.DS_FAVORITO]) {
          return CardNames[item.DS_FAVORITO];
        }
      });
      setFavorites(favoritesPages);
    }
  };

  const getContent = async () => {
    const resultNews = await contentfulService.getContentInFocusLimited();
    setNewsArray(resultNews.items);
    setNewsImages(resultNews.includes.Asset);

    const podcastsResult = await contentfulService.getLimitedAudios();
    setContentAudios(
      podcastsResult.includes.Asset.sort(function (a, b) {
        return new Date(b.sys.updatedAt).getTime() - new Date(a.sys.updatedAt).getTime();
      })
    );
  };

  const getImage = file => {
    const imgID = file?.fields?.summaryImage?.sys?.id ?? '';
    const arrayWithUrlImg = newsImages.filter(item => item.sys.id === imgID);
    return `https:${arrayWithUrlImg[0]?.fields?.file?.url}`;
  };

  useEffect(() => {
    async function callAsync() {
      await getFavorites();
      await getContent();
    }
    callAsync();
  }, []);

  const settingsAudio = {
    dots: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: true,
  };

  const settingsNews = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 0.98,
    slidesToScroll: 1,
    arrows: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
  };

  const handlePlayerClick = async (stateOfPlay, updateStatePlay, indexOfPlay) => {
    const audioElement: HTMLAudioElement = (await document.getElementById(`audio-${indexOfPlay}`)) as HTMLAudioElement;
    const pauseOtherAudios = async () => {
      for (let i = 0; i < contentAudios.length; i++) {
        if (indexOfPlay != i) {
          let otherAudioElement: HTMLAudioElement = (await document.getElementById(`audio-${i}`)) as HTMLAudioElement;
          await otherAudioElement?.pause();
          let ulElement = document.getElementById(`bares-container-${i}`);
          ulElement?.setAttribute('class', '');
        }
      }
    };

    if (stateOfPlay === `play-${indexOfPlay}`) {
      pauseOtherAudios();
      audioElement?.play();
      updateStatePlay('pause');
    } else {
      audioElement?.pause();
      updateStatePlay(`play-${indexOfPlay}`);
    }
    setCheckState(!checkState);
  };

  return (
    <>
      <StyledTablesContainer>
        <StyledTablesLeftContainer>
          <div style={CarouselNewsStyle}>
            <Slider {...settingsNews}>
              {newsArray.map((item, index) => (
                <div key={index}>
                  <NewsTable
                    backgroundImage={getImage(item)}
                    link={item?.fields?.link ?? ''}
                    title={item?.fields?.title ?? ''}
                    subtitle={item?.fields?.subtitle ?? ''}
                    summary={item?.fields?.summary ?? ''}
                    key={index}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div style={{ width: '520px', marginBottom: '12px', marginTop: '12px', marginLeft: '-10px' }}>
            {contentAudios.length > 0 && (
              <Slider {...settingsAudio}>
                {contentAudios.map((item, index) => (
                  <div key={index}>
                    <PodcastPlayer
                      checkState={checkState}
                      handlePlayerClick={handlePlayerClick}
                      index={index}
                      createdAt={item.sys.createdAt ?? ''}
                      song={item.fields.file.url ?? ''}
                      fileName={item.fields.file.fileName ?? ''}
                      title={item.fields.title ?? ''}
                    />
                  </div>
                ))}
              </Slider>
            )}
          </div>
          <QuoteDashboardTable />
        </StyledTablesLeftContainer>

        <StyledTablesRightContainer>
          <FavoritesContainerStyled>
            <StyledPageTitleContainer style={{ display: 'flex' }} margin={'0px'}>
              <FastWatchIconStyled src={fastWatchIcon} />
              <MainPageTitle fontSize={16} text={t('dashboard.quickAccess')} />
            </StyledPageTitleContainer>
            <StyledCardsContainer>
              <div style={{ maxWidth: '395px' }}>
                <LinkedCards small listCardNames={favorites} spacingBetweenCards={17} />
              </div>
              <StyledCard onClick={() => history.push('/atalhos')}>
                <PlusIconStyled>
                  <PlusSvg />
                </PlusIconStyled>
                <span>{t('dashboard.addShortcuts')}</span>
              </StyledCard>
            </StyledCardsContainer>
          </FavoritesContainerStyled>
          {/*<ScheduleTable />*/}
          <WeatherTable />
        </StyledTablesRightContainer>
      </StyledTablesContainer>
    </>
  );
};

export default withTranslation()(Dashboard);
