import { Grid } from '@mui/material';
import BullCard from 'components/BullCard';
import { IBull } from 'model/bull';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import bullCatalogService from 'services/cattle/bull-catalog-service';
import styled from 'styled-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import GenericInputToTable from 'components/GenericInputToTable';
import { StyledButton } from 'components/styled-button/StyledButton';

interface Ibull {
  bullId: number;
}

interface IForm {
  memberName: string;
  registrationNumber: string;
  contact: string;
  valueOffer: string;
  payday: string;
}

const BullDetails = ({ t }: WithTranslation) => {
  const location = useLocation<Ibull>();
  const { bullId } = location.state;

  const [bull, setBull] = useState<IBull>();

  const schema = yup.object({
    memberName: yup.string().required(),
    registrationNumber: yup.string().required(),
    contact: yup.string().required(),
    valueOffer: yup.string().required(),
    payday: yup.string().required(),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  useEffect(() => {
    bullCatalogService.getBull(bullId).then(setBull);
  }, []);

  const cardBullDescription = `${bull?.weight} - ${t('livestock.birthDate')} - ${bull?.birthDate} - ${t('livestock.litersSemen')} - ${
    bull?.litersSemen
  }`;

  return (
    <>
      <StyledTitleContainer>
        <span>{bull?.name}</span>
        <StyledBullRace>{` - ${bull?.race}`}</StyledBullRace>
      </StyledTitleContainer>

      <BullCard id={bull?.id!} image={bull?.images[0]!} name={bull?.name!} description={cardBullDescription} />

      <StyledPhotoContainer>
        {bull?.images.map((image, index) => (
          <StyledBullPhoto src={image} alt={`imagem ${index} touro ${bull.name}`} key={`imagem-touro-${index}`} />
        ))}
      </StyledPhotoContainer>

      <Grid container>
        <Grid item xs={12} md={6} spacing={20}>
          <StyledTitle>{t('livestock.moreInformation')}</StyledTitle>
          <StyledField>
            <Trans i18nKey="livestock.moreInformationModel">
              <span className="text-value">{{ text: bull?.model }}</span>
            </Trans>
          </StyledField>
          <StyledField>
            <Trans i18nKey="livestock.moreInformationRace">
              <span className="text-value">{{ text: bull?.race }}</span>
            </Trans>
          </StyledField>
          <StyledField>
            <Trans i18nKey="livestock.moreInformationAge">
              <span className="text-value">{{ text: bull?.age }}</span>
            </Trans>
          </StyledField>
          <StyledField style={{ margin: '30px 30px 30px 0' }}>
            <Trans i18nKey="livestock.moreInformationGeneral">
              <span className="text-value">{{ text: bull?.generalInformation }}</span>
            </Trans>
          </StyledField>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTitle>{t('livestock.sendProposal')}</StyledTitle>

          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(data => {
                console.log('data', data);
              })}
            >
              <GenericInputToTable
                inputName="memberName"
                label={t('livestock.memberName')}
                errorText={methods.formState.errors.memberName?.message}
              />
              <GenericInputToTable
                inputName="registrationNumber"
                label={t('livestock.registrationNumber')}
                errorText={methods.formState.errors.registrationNumber?.message}
              />
              <GenericInputToTable
                inputName="contact"
                label={t('livestock.contact')}
                errorText={methods.formState.errors.contact?.message}
              />
              <GenericInputToTable
                inputName="valueOffer"
                label={t('livestock.valueOffer')}
                errorText={methods.formState.errors.valueOffer?.message}
              />
              <GenericInputToTable inputName="payday" label={t('livestock.payday')} errorText={methods.formState.errors.payday?.message} />

              <StyledButton color="frisiaPrimary" type="submit" style={{ minWidth: '200px', alignSelf: 'center', whiteSpace: 'nowrap' }}>
                <span>{t('livestock.sendProposal')}</span>
              </StyledButton>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
};

const StyledTitleContainer = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 30px;

  text-align: left;
  margin: 35px 0;
`;

const StyledBullRace = styled.span`
  color: #404040;
`;

const StyledPhotoContainer = styled.div`
  display: flex;
  column-gap: 15px;

  margin: 35px 0;
  overflow-y: auto;
`;

const StyledBullPhoto = styled.img`
  height: 170px;
  min-width: 180px;
  border-radius: 16px;
`;

const StyledTitle = styled.div`
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 20px;
  text-align: left;

  margin-bottom: 20px;
`;

const StyledField = styled.div`
  max-width: 470px;

  font-family: ${({ theme }) => theme.font.montserratBold};
  color: #404040;
  font-size: 16px;
  text-align: left;

  .text-value {
    font-family: ${({ theme }) => theme.font.montserratRegular};
  }
`;

export default withTranslation()(BullDetails);
