import agriculturalApi from 'api/agricultural/agricultural-api';
import { AxiosError, AxiosResponse } from 'axios';
import { OptionsSafraProductsResponse, SalesStatmentsResponses } from 'model/agriculturalSalesStatement';
import { BIReportType } from 'model/bi';
import HttpStatus from 'model/enums/http-status';
import { IInputOptions, ISession } from 'model/input-report';
import { ITypeProductGroup } from 'model/product-my-stock';
import IScheduleBalance, { ICrop, ICropStock, ICulture } from 'model/schedule-balance';

export const agriculturalService = () => {
  const getMyStockData = async (registration: string, years: string) => {
    try {
      const result: AxiosResponse<ITypeProductGroup[]> = await agriculturalApi.getMyStockData(registration, years);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getCrop = async (registration: number) => {
    try {
      const result: AxiosResponse<ICrop[]> = await agriculturalApi.getCrop(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getCulture = async (registration: number, crop: string) => {
    try {
      const result: AxiosResponse<ICulture[]> = await agriculturalApi.getCulture(registration, crop);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getScheduleBalance = async (registration: number, crop: string, culture: string) => {
    try {
      const result: AxiosResponse<IScheduleBalance[]> = await agriculturalApi.getScheduleBalance(registration, crop, culture);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getHarvestAndProducts = async (registration: string) => {
    try {
      const result: AxiosResponse<OptionsSafraProductsResponse[]> = await agriculturalApi.getHarvestAndProducts(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getSalesByContracts = async (register: string, years: string, cdProduct: string) => {
    try {
      const result: AxiosResponse<SalesStatmentsResponses> = await agriculturalApi.getSalesByContracts(register, years, cdProduct);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getLiquidatedCreditedAndSummary = async (register: string, years: string, cdProduct: string, pattern: string, type: string) => {
    try {
      const result: AxiosResponse<SalesStatmentsResponses> = await agriculturalApi.getLiquidatedCreditedAndSummary(
        register,
        years,
        cdProduct,
        pattern,
        type
      );
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getSalesInvoice = async (register: string, years: string, cdProduct: string) => {
    try {
      const result: AxiosResponse<SalesStatmentsResponses> = await agriculturalApi.getSalesInvoice(register, years, cdProduct);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getBIReportsLink = async (registration: string) => {
    try {
      const result: AxiosResponse<BIReportType[]> = await agriculturalApi.getBIReportsLink(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getCropForMyStock = async () => {
    try {
      const result: AxiosResponse<ICropStock[]> = await agriculturalApi.getCropForMyStock();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getInputOptions = async (register: string) => {
    try {
      const result: AxiosResponse<IInputOptions[]> = await agriculturalApi.getInputOptions(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getSession = async (register: string, cropNumber: string, cultureNumber: string) => {
    try {
      const result: AxiosResponse<ISession> = await agriculturalApi.getSession(register, cropNumber, cultureNumber);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getMyStockData,
    getCrop,
    getCulture,
    getScheduleBalance,
    getHarvestAndProducts,
    getSalesByContracts,
    getLiquidatedCreditedAndSummary,
    getSalesInvoice,
    getBIReportsLink,
    getCropForMyStock,
    getInputOptions,
    getSession,
  };
};

export default agriculturalService();
