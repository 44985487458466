import { AccountType } from 'model/account';
import { Authentication } from 'model/authentication';
import { CoreUserInfoReponse } from 'model/coreResponse';
import { action } from 'typesafe-actions';
import { AuthenticationActionTypes } from './types';

export const loginRequest = (authenticate: Authentication) => action(AuthenticationActionTypes.LOGIN_REQUEST, authenticate);
export const loginSuccess = () => action(AuthenticationActionTypes.LOGIN_SUCCESS);
export const loginError = (message: string) => action(AuthenticationActionTypes.LOGIN_ERROR, message);

export const setCoreSuccess = (coreInfo: CoreUserInfoReponse) => action(AuthenticationActionTypes.CORE_REQUEST, coreInfo);
export const setSelectedUser = (selected: AccountType) => action(AuthenticationActionTypes.SELECTED, selected);
export const setListOfPages = (list: any[]) => action(AuthenticationActionTypes.LIST, list);
export const setIndicatorsSuccess = (indicatorsInfo: CoreUserInfoReponse) =>
  action(AuthenticationActionTypes.INDICATORS_REQUEST, indicatorsInfo);
export const setExtranetFilesSuccess = (extranetFilesInfo: CoreUserInfoReponse) =>
  action(AuthenticationActionTypes.EXTRANET_FILES_REQUEST, extranetFilesInfo);

export const getSessionRequest = () => action(AuthenticationActionTypes.GET_SESSION_REQUEST);
export const getSessionSuccess = (user: CoreUserInfoReponse) => action(AuthenticationActionTypes.GET_SESSION_SUCCESS, user);
export const getSessionError = (message: string) => action(AuthenticationActionTypes.GET_SESSION_ERROR, message);

export const logoutRequest = () => action(AuthenticationActionTypes.LOGOUT_REQUEST);
