import { AxiosResponse } from 'axios';
import { IAccountResponse, ICalendarResponse } from 'model/calendar';
import { sendLog } from 'shared/util/api-utils';
import { apiOracle } from './api';

export const CalendarApi = () => {
  const getActivitiesByPartyId = async (PartyId: number): Promise<AxiosResponse<ICalendarResponse>> => {
    sendLog(
      'Consultando atividades pela API Oracle por PartyId',
      `activities?q=AccountId=${PartyId};ActivityFunctionCode=APPOINTMENT`,
      JSON.stringify([]),
      'get'
    );
    return apiOracle.get<ICalendarResponse>(`activities?q=AccountId=${PartyId};ActivityFunctionCode=APPOINTMENT`);
  };
  const getAccountBycdAndName = async (cd_pessoa: string, nm_pessoa): Promise<AxiosResponse<IAccountResponse>> => {
    sendLog(
      'Consultando atividades pela API Oracle por nome',
      `accounts?q=OrganizationName=${cd_pessoa} - ${nm_pessoa}`,
      JSON.stringify([]),
      'get'
    );
    return apiOracle.get<IAccountResponse>(`accounts?q=OrganizationName=${cd_pessoa} - ${nm_pessoa}`);
  };

  const getGeneralEventsActivities = async (): Promise<AxiosResponse<ICalendarResponse>> => {
    sendLog('Consultando atividades de evento geral', `activities?q=ActivityTypeCode=MEETING_ALL`, JSON.stringify([]), 'get');
    return apiOracle.get<ICalendarResponse>(`activities?q=ActivityTypeCode=MEETING_ALL`);
  };

  return {
    getActivitiesByPartyId,
    getAccountBycdAndName,
    getGeneralEventsActivities,
  };
};

export default CalendarApi();
