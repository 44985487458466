import agriculturalApi from 'api/agricultural/agricultural-api';
import ButtonDownload from 'components/ButtonDownload';
import CollapsibleScheduleBalance from 'components/CollapsibleScheduleBalance';
import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import { isEmpty } from 'lodash';
import IScheduleBalance, { ICrop, ICulture } from 'model/schedule-balance';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import agriculturalService from 'services/agricultural/agricultural-service';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

interface IFilter {
  crop: ICrop;
  culture: ICulture;
}

const ScheduleBalance = ({ t }: WithTranslation) => {
  const [crop, setCrop] = useState<ICrop[]>([]);
  const [culture, setCulture] = useState<ICulture[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<IFilter>({} as IFilter);
  const [scheduleBalance, setScheduleBalance] = useState<IScheduleBalance[]>([]);
  const [reportLink, setReportLink] = useState<string>('');

  useEffect(() => {
    if (userRegistration != null) {
      agriculturalService.getCrop(userRegistration).then(setCrop);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedFilter.crop?.CD_SAFRA)) {
      agriculturalService.getCulture(userRegistration, selectedFilter.crop.CD_SAFRA).then(setCulture);
    }
  }, [selectedFilter.crop?.CD_SAFRA]);

  useEffect(() => {
    if (!isEmpty(selectedFilter.crop?.CD_SAFRA) && !isEmpty(selectedFilter.culture?.CD_CULTURA)) {
      agriculturalService
        .getScheduleBalance(userRegistration, selectedFilter.crop.CD_SAFRA, selectedFilter.culture.CD_CULTURA)
        .then(setScheduleBalance);
    }
  }, [selectedFilter.crop?.CD_SAFRA, selectedFilter.culture?.CD_CULTURA]);

  const handleScheduleBalanceReport = () => {
    if (!isEmpty(selectedFilter.crop?.CD_SAFRA) && userRegistration != null) {
      const reportLinkPath = agriculturalApi.getScheduleBalanceReportLink(
        selectedFilter.crop?.CD_SAFRA,
        userRegistration,
        selectedFilter.culture.CD_CULTURA
      );
      setReportLink(reportLinkPath);
    }
  };

  const userRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={20} text={t('agricultural.balance')} />
      </StyledPageTitleContainer>
      <StyledPageTitleContainer margin={'25px 0 14px 0'}>
        <MainPageTitle fontSize={16} text={t('agricultural.crop')} />
      </StyledPageTitleContainer>
      <StyledFilters>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('global.field.filterSafra')}
            placeholder={t('global.field.filterSelectSafra')}
            listShowDropdown={crop}
            keyToShowInDropdown="CD_SAFRA_TEXT"
            getSelectedValue={value => setSelectedFilter({ ...selectedFilter, crop: value })}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('agricultural.culture')}
            placeholder={t('global.field.filterSelectCulture')}
            listShowDropdown={culture}
            keyToShowInDropdown="NM_CULTURA"
            getSelectedValue={value => setSelectedFilter({ ...selectedFilter, culture: value })}
          />
        </StyledFilterContainer>

        {scheduleBalance.length > 0 && (
          <StyledButtonDownloadContainer onClick={() => handleScheduleBalanceReport()}>
            <ButtonDownload href={reportLink} />
          </StyledButtonDownloadContainer>
        )}
      </StyledFilters>
      {scheduleBalance?.map((item, index) => (
        <CollapsibleScheduleBalance
          title={item.DS_PRODUTO}
          quantity={`${StringUtils.maskAmount(item.TOTAL_DISPONIVEL)} ${item.CD_UNIDADE_PADRAO}`}
          details={item.DETALHES}
          key={`table-schedule-balance${index}`}
        />
      ))}
    </>
  );
};

const StyledFilters = styled.div`
  display: flex;
  column-gap: 15px;
`;

const StyledButtonDownloadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -17px;
  margin-left: auto;
`;

export default withTranslation()(ScheduleBalance);
