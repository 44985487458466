import forestApi from 'api/forest-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { IForestData, ITypeFilter } from 'model/forest';

export const forestService = () => {
  const getDataFilters = async (registration: number): Promise<ITypeFilter[]> => {
    try {
      const result = await forestApi.getDataFilters(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getForestData = async (registration: number, harvestCode: string, productCode: number, plateCode: string): Promise<IForestData> => {
    try {
      const result = await forestApi.getForestData(registration, harvestCode, productCode, plateCode);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getDataFilters,
    getForestData,
  };
};

export default forestService();
