import { encryptReport, sendLog } from 'shared/util/api-utils';

export const PigDeliveryReportApi = () => {
  const getReport = (register: string) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/PEC/PEC0700A&va_nr_matricula=${register}&va_cd_filial=1`;

    sendLog('consultando relatorio entrega suinos', path, JSON.stringify({}), 'get');

    return encryptReport(path);
  };

  return {
    getReport,
  };
};

export default PigDeliveryReportApi();
