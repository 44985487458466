import { AxiosResponse } from 'axios';
import {
  IFeedOrder,
  IHystoryFeedOrder,
  TypeActivities,
  TypeFeedProducts,
  TypeFeedStockProducts,
  TypeNewFeedOrder,
  TypePayments,
  TypeProperties,
} from 'model/feed-orders';
import { IReturnMessage } from 'model/financial';
import { sendLog } from 'shared/util/api-utils';
import { apiCore } from './api';
import newFeedOrderJson from './mocks/newFeedOrder.json';

export const feedOrdersApi = () => {
  const getMyFeedOrders = async (register: string): Promise<AxiosResponse<IHystoryFeedOrder[]>> => {
    sendLog('Consulta pedidos de ração', `/FabricaRacao/GetPedidosRacao?CD_PESSOA=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<IHystoryFeedOrder[]>(`/FabricaRacao/GetPedidosRacao?CD_PESSOA=${register}`);
  };

  const deleteNewFeedOrder = async (orderId: string) => {
    sendLog('Cancelar pedido de ração', `/FabricaRacao/CancelaPedidoRacao?ID_PEDIDO=${orderId}`, JSON.stringify([]), 'put');
    return apiCore.put<IReturnMessage>(`/FabricaRacao/CancelaPedidoRacao?ID_PEDIDO=${orderId}`);
  };

  const getPropertiesOptions = async (register: string) => {
    sendLog(
      'Consulta opções de propriedades por cooperado',
      `/Prop/GetPropriedadesByCoopMatricula?nr_matricula=${register}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<TypeProperties[]>(`/Prop/GetPropriedadesByCoopMatricula?nr_matricula=${register}`);
  };

  const getFeedProducts = async (bagged: string, firstTypeActivity: string, secondTypeActivity: string) => {
    sendLog(
      'Consulta de itens de ração',
      `/FabricaRacao/GetItensFabRacao?IE_ENSACADO=${bagged}&CD_TIPO_ATIVIDADE1=${firstTypeActivity}&CD_TIPO_ATIVIDADE2=${secondTypeActivity}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<TypeFeedProducts[]>(
      `/FabricaRacao/GetItensFabRacao?IE_ENSACADO=${bagged}&CD_TIPO_ATIVIDADE1=${firstTypeActivity}&CD_TIPO_ATIVIDADE2=${secondTypeActivity}`
    );
  };

  const getActivities = async (register: string) => {
    sendLog('Consulta areas do cooperado', `/Area/GetAreasCooperado?nr_matricula=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<TypeActivities[]>(`/Area/GetAreasCooperado?nr_matricula=${register}`);
  };

  const getPayments = async (register: string, activity: string) => {
    sendLog(
      'Consulta de condições de pagamento',
      `/FabricaRacao/GetCondicoesPagamento?CD_PESSOA=${register}&CD_TIPO_ATIVIDADE=${activity}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<TypePayments[]>(`/FabricaRacao/GetCondicoesPagamento?CD_PESSOA=${register}&CD_TIPO_ATIVIDADE=${activity}`);
  };

  const getOnlyFeedProducts = async (bagged: string, firstTypeActivity: string, secondTypeActivity: string) => {
    sendLog(
      'Consulta de produtos apenas ração',
      `FabricaRacao/GetSomenteRacaoFabRacao?IE_ENSACADO=${bagged}&CD_TIPO_ATIVIDADE1=${firstTypeActivity}&CD_TIPO_ATIVIDADE2=${secondTypeActivity}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<TypeFeedProducts[]>(
      `FabricaRacao/GetSomenteRacaoFabRacao?IE_ENSACADO=${bagged}&CD_TIPO_ATIVIDADE1=${firstTypeActivity}&CD_TIPO_ATIVIDADE2=${secondTypeActivity}`
    );
  };

  const getLastProducts = async (register: string) => {
    sendLog('Consulta de ultimos itens', `/FabricaRacao/GetUltimosItensFabRacao?CD_PESSOA=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<TypeFeedProducts[]>(`/FabricaRacao/GetUltimosItensFabRacao?CD_PESSOA=${register}`);
  };

  const sendOrder = async (orderList: { itens: IFeedOrder[] }) => {
    sendLog('Envio de pedido', `/FabricaRacao/EnviarPedidoSync`, JSON.stringify(orderList), 'post');
    return apiCore.post<IReturnMessage>(`/FabricaRacao/EnviarPedidoSync`, orderList);
  };

  const getFeedStockProduct = async (register: string, product: string, deliveryDate: string) => {
    sendLog(
      'Consulta Produto Materia-Prima',
      `/FabricaRacao/GetProdutoMateriaPrima?NR_MATRICULA=${register}&CD_PRODUTO=${product}&DT_ENTREGA=${deliveryDate}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<TypeFeedStockProducts>(
      `/FabricaRacao/GetProdutoMateriaPrima?NR_MATRICULA=${register}&CD_PRODUTO=${product}&DT_ENTREGA=${deliveryDate}`
    );
  };

  return {
    getMyFeedOrders,
    deleteNewFeedOrder,
    getPropertiesOptions,
    getFeedProducts,
    getActivities,
    getPayments,
    getOnlyFeedProducts,
    getLastProducts,
    sendOrder,
    getFeedStockProduct,
  };
};

export default feedOrdersApi();
