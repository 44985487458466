import { AxiosResponse } from 'axios';
import { IReturnMessage } from 'model/financial';
import { IAuthorization, ILocals, INewAuth, IPayments, IPerson } from 'model/purchasingAuthorization';
import { sendLog } from 'shared/util/api-utils';
import { apiCore } from './api';

export const purchasingAuthorizationApi = () => {
  const getAll = (register: string): Promise<AxiosResponse<IAuthorization[]>> => {
    sendLog(
      'Consultando autorização de compras',
      `/AutorizacaoComprasTerceiro/GetHistorico?CD_PESSOA=${register}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IAuthorization[]>(`/AutorizacaoComprasTerceiro/GetHistorico?CD_PESSOA=${register}`);
  };

  const getVig = (register: string): Promise<AxiosResponse<IAuthorization[]>> => {
    sendLog('Consulta autorizações vigentes', `/AutorizacaoComprasTerceiro/GetVigentes?CD_PESSOA=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<IAuthorization[]>(`/AutorizacaoComprasTerceiro/GetVigentes?CD_PESSOA=${register}`);
  };

  const cancelAuth = (register: string, auth: string): Promise<AxiosResponse<IReturnMessage>> => {
    sendLog(
      'Cancelar autorização',
      `/AutorizacaoComprasTerceiro/Cancelar?CD_PESSOA=${register}&NR_AUTORIZACAO=${auth}`,
      JSON.stringify([]),
      'post'
    );
    return apiCore.post<IReturnMessage>(`/AutorizacaoComprasTerceiro/Cancelar?CD_PESSOA=${register}&NR_AUTORIZACAO=${auth}`);
  };

  const sendNewAuth = (newAuth: INewAuth): Promise<AxiosResponse<IReturnMessage>> => {
    sendLog('Criar nova autorização', `/AutorizacaoComprasTerceiro/EnviarPedido`, JSON.stringify(newAuth), 'post');
    return apiCore.post<IReturnMessage>(`/AutorizacaoComprasTerceiro/EnviarPedido`, newAuth);
  };

  const getPaymentForms = (): Promise<AxiosResponse<IPayments[]>> => {
    sendLog('Consulta formas de pagamento', `/AutorizacaoComprasTerceiro/GetFormasPagamento`, JSON.stringify([]), 'get');
    return apiCore.get<IPayments[]>(`/AutorizacaoComprasTerceiro/GetFormasPagamento`);
  };

  const getLocals = (): Promise<AxiosResponse<ILocals[]>> => {
    sendLog('Consulta locais para compras de terceiro', `/AutorizacaoComprasTerceiro/GetOrganizacoes`, JSON.stringify([]), 'get');
    return apiCore.get<ILocals[]>(`/AutorizacaoComprasTerceiro/GetOrganizacoes`);
  };

  const getPersonsByName = (name: string): Promise<AxiosResponse<IPerson[]>> => {
    sendLog(
      'Consulta nome de pessoas para autorização de compras',
      `/AutorizacaoComprasTerceiro/GetPessoasLikeNome?NOME=${name}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IPerson[]>(`/AutorizacaoComprasTerceiro/GetPessoasLikeNome?NOME=${name}`);
  };

  const getPersonsByExactCPF = (cpf: string): Promise<AxiosResponse<IPerson[]>> => {
    sendLog(
      'Consulta autorização de compras de terceiros',
      `/AutorizacaoComprasTerceiro/GetPessoasByCPF?NR_CPF==${cpf}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IPerson[]>(`/AutorizacaoComprasTerceiro/GetPessoasByCPF?NR_CPF==${cpf}`);
  };

  return {
    getAll,
    getVig,
    cancelAuth,
    sendNewAuth,
    getPaymentForms,
    getLocals,
    getPersonsByName,
    getPersonsByExactCPF,
  };
};

export default purchasingAuthorizationApi();
