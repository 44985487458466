import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import toastUtils from 'shared/util/toast-utils';
import consultAuthorizationApi from 'api/consult-authorization-api';
import { IAuths, IAuthsTherme, IConsults, IFunctions, INewConsult, ICreateConsult } from 'model/consult-authorization';
import i18n from 'config/i18n';
import { IMessage, IPassword } from 'model/account-allow-pages';

export const consultAuthorizationService = () => {
  const getMyAuths = async (register: string) => {
    try {
      const result: AxiosResponse<IAuths[]> = await consultAuthorizationApi.getMyAuths(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getFunctionsOptions = async () => {
    try {
      const result: AxiosResponse<IFunctions[]> = await consultAuthorizationApi.getFunctionsOptions();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getConsults = async (register: string) => {
    try {
      const result: AxiosResponse<IConsults[]> = await consultAuthorizationApi.getConsults(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAcepetTherme = async () => {
    try {
      const result: AxiosResponse<IAuthsTherme[]> = await consultAuthorizationApi.getAcepetTherme();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const authNewConsult = async (newConsult: INewConsult): Promise<IMessage> => {
    try {
      const result = await consultAuthorizationApi.authNewConsult(newConsult);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast(result.data.message);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.Message);
      return Promise.reject(response);
    }
  };

  const deleteAuth = async (authId: string, cd_pessoa: string): Promise<IMessage> => {
    try {
      const result = await consultAuthorizationApi.deleteAuth(authId, cd_pessoa);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast(result.data.message);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.Message);
      return Promise.reject(response);
    }
  };

  const recConPwd = async (SamAccountName: string, cd_pessoa: string): Promise<IPassword> => {
    try {
      const result = await consultAuthorizationApi.recConPwd(SamAccountName, cd_pessoa);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast(`A senha da Conta ${SamAccountName} é:\n ${result.data.senha}`);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.Message);
      return Promise.reject(response);
    }
  };

  const deleteConslt = async (SamAccountName: string, cd_pessoa: string): Promise<string> => {
    try {
      const result = await consultAuthorizationApi.deleteConslt(SamAccountName, cd_pessoa);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast(result.data);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.Message);
      return Promise.reject(response);
    }
  };

  const createConslt = async (createCons: ICreateConsult): Promise<string> => {
    try {
      const result = await consultAuthorizationApi.createConslt(createCons);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast(result.data);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.Message);
      return Promise.reject(response);
    }
  };

  return {
    getMyAuths,
    getFunctionsOptions,
    getConsults,
    getAcepetTherme,
    authNewConsult,
    deleteAuth,
    createConslt,
    recConPwd,
    deleteConslt,
  };
};

export default consultAuthorizationService();
