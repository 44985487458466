import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import WhiteAndBlueFilterCard from 'components/WhiteAndBlueFilterCard';
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import StyledCardsFilterContainer from 'styled-components/StyledCardsFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { ReactComponent as LeftIconSvg } from '../../images/frisia/icons/arrow-left.svg';
import { ReactComponent as RightIconSvg } from '../../images/frisia/icons/arrow-right.svg';
import { ReactComponent as BarleyIconSvg } from '../../images/frisia/icons/blue-barley-icon.svg';
import { ReactComponent as BeanIconSvg } from '../../images/frisia/icons/bean-svgrepo-com.svg';
import { ReactComponent as CornIconSvg } from '../../images/frisia/icons/blue-corn-icon.svg';
import { ReactComponent as ForagerIconSvg } from '../../images/frisia/icons/blue-forager-icon.svg';
import { ReactComponent as SoyIconSvg } from '../../images/frisia/icons/blue-soy-icon.svg';
import { ReactComponent as OneDotIconSvg } from '../../images/frisia/icons/blue-checked-checkbox-icon.svg';
import { ReactComponent as OatIconSvg } from '../../images/frisia/icons/oat.svg';
import { ReactComponent as SorghumIconSvg } from '../../images/frisia/icons/sorghum.svg';
import { ReactComponent as WheatIconSvg } from '../../images/frisia/icons/trigo.svg';
import FilterCardType from 'model/enums/FilterCardType';
import styled from 'styled-components';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import { QuoteOptions } from 'model/enums/quote-options';
import { StyledTable, Subtitle, WarningStyled } from 'components/QuoteDashboardTable';
import { ReactComponent as UpIconSvg } from '../../images/frisia/icons/yellow-up-arrow-icon.svg';
import { ReactComponent as DownIconSvg } from '../../images/frisia/icons/red-down-arrow-icon.svg';
import currencyApi from 'api/currency-api';
import frisiaQuotationApi from 'api/frisia-cotation-api';
import CollapsibleQuotationGraphic from 'components/CollapsibleQuotationGraphic';
import { CurrencyProps, LocationType } from 'model/quotation-info';
import InputDateWithSetting from 'components/InputDateWithSetting';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

interface QuotationCard {
  icon: ReactElement;
  text: string;
}

const AgriculturalQuotation = ({ t }: WithTranslation) => {
  const [cards, setCards] = useState<QuotationCard[]>([]);
  const [selectedFilterCard, setSelectedFilterCard] = useState<QuotationCard>();
  const [selectedDate, setSelectedDate] = useState<string>(moment().format('DD/MM/YYYY'));
  const [lastUpdate, setLastUpdate] = useState<string>();
  const [onLoading, setOnLoading] = useState(false);
  const history = useHistory();

  const [date, setDate] = useState<Date | null>(new Date());

  const [chosenQuotation, setChosenQuotation] = useState<QuoteOptions>(QuoteOptions.SOY);
  const [usd, setUsd] = useState<CurrencyProps>({});
  const [ibov, setIbov] = useState<CurrencyProps>({});
  const [eur, setEur] = useState<CurrencyProps>({});
  const [apiFrisiaInfo, setapiFrisiaInfo] = useState<LocationType[]>([]);

  const [ParanaInfo, setParanaInfo] = useState<LocationType[]>([]);
  const [ChicagoInfo, setChicagoInfo] = useState<LocationType[]>([]);
  const [TocantinsInfo, setTocantinsInfo] = useState<LocationType[]>([]);

  const [infoFilteredByGroup, setInfoFilteredByGroup] = useState<LocationType[]>([]);

  const [paranaSelectedState, setParanaSelectedState] = useState<boolean>(true);

  const generateCards = infoArray => {
    setCards([]);
    setInfoFilteredByGroup([]);
    const dataList: string[] = infoArray.map(item => item.grupo);
    dataList
      .filter((c, index) => {
        return dataList.indexOf(c) === index;
      })
      .map(item => {
        let icon = <OneDotIconSvg style={{ width: '40px' }} />;
        if (item.toLowerCase().includes(FilterCardType.BEAN.toLowerCase())) {
          icon = <BeanIconSvg style={{ width: '40px' }} />;
        }
        if (item.toLowerCase().includes(FilterCardType.SOY.toLowerCase())) {
          icon = <SoyIconSvg />;
        }
        if (item.toLowerCase().includes(FilterCardType.CORN.toLowerCase())) {
          icon = <CornIconSvg />;
        }
        if (item.toLowerCase().includes(FilterCardType.BARLEY.toLowerCase())) {
          icon = <BarleyIconSvg />;
        }
        if (item.toLowerCase().includes(FilterCardType.FORAGERS.toLowerCase())) {
          icon = <ForagerIconSvg style={{ width: '40px' }} />;
        }
        if (item.toLowerCase().includes(FilterCardType.BEAN.toLowerCase())) {
          icon = <BeanIconSvg style={{ width: '40px' }} />;
        }
        if (item.toLowerCase().includes(FilterCardType.OAT.toLowerCase())) {
          icon = <OatIconSvg style={{ width: '40px' }} />;
        }
        if (item.toLowerCase().includes(FilterCardType.WHEAT.toLowerCase())) {
          icon = <WheatIconSvg style={{ width: '40px' }} />;
        }
        if (item.toLowerCase().includes(FilterCardType.SORGHUM.toLowerCase())) {
          icon = <SorghumIconSvg style={{ width: '40px' }} />;
        }
        let novoCard: QuotationCard = { icon: icon, text: item };
        setCards(cards => [...cards, novoCard]);
      });
  };

  const getCurrencies = async () => {
    var currencyResult = await currencyApi.getCurrencies();
    setEur(currencyResult.data['EURBRL']);
  };

  const processingCurrency = item => {
    const pastValue = item.exchangesRate[0].locations[0].historicos.slice(-2)[0].valor;
    const value = item.exchangesRate[0].locations[0].valor;
    return {
      bid: value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).toString(),
      pctChange: ((value * 100) / pastValue - 100).toFixed(2).toString(),
    };
  };

  const processingIBOV = item => {
    const value = item.exchangesRate[0].locations[0].valor;
    const percentage = item.exchangesRate[0].locations[0].indicador;

    var numero = value.toFixed(2).split('.');
    numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
    return {
      bid: numero.join(',').toLocaleString('pt-BR'),
      pctChange: percentage,
    };
  };

  const getFrisiaQuotation = async () => {
    setapiFrisiaInfo([]);
    const frisiaResult = await frisiaQuotationApi.getQuotation(selectedDate);
    setOnLoading(false);

    if (frisiaResult.data.currencies.length > 0) {
      frisiaResult.data.currencies.map(item => {
        if (item.name === 'COTAÇÃO DÓLAR') {
          setUsd(processingCurrency(item));
        }
        if (item.name === 'IBOVESPA') {
          setIbov(processingIBOV(item));
        }
        setLastUpdate(item.lastUpdate);
      });
    } else {
      return setUsd({});
    }

    let locationsArray: LocationType[] = [];

    frisiaResult.data.commodities.map(item => {
      locationsArray.push(...item.agriculturalQuotes.map(item => item.locations));
    });

    setParanaInfo(locationsArray.flat().filter(item => item.regiao === 'PARANÁ'));
    setChicagoInfo(locationsArray.flat().filter(item => item.regiao === 'CHICAGO'));
    setTocantinsInfo(locationsArray.flat().filter(item => item.regiao === 'TOCANTINS'));
  };

  useEffect(() => {
    async function callAsync() {
      await getCurrencies();
      await getFrisiaQuotation();
    }
    callAsync();
  }, []);

  useEffect(() => {
    setOnLoading(true);
    async function callAsync() {
      setParanaInfo([]);
      setChicagoInfo([]);
      setTocantinsInfo([]);
      await getFrisiaQuotation();
    }
    callAsync();
  }, [selectedDate]);

  useEffect(() => {
    if (paranaSelectedState) {
      generateCards(ParanaInfo);
    } else {
      generateCards(TocantinsInfo);
    }
  }, [paranaSelectedState, selectedDate, ParanaInfo]);

  useEffect(() => {
    if (date != null) {
      setSelectedDate(format(date, 'dd/MM/yyyy'));
    }
  }, [date]);

  const renderByProducts = () => {
    const ProductsList = infoFilteredByGroup.map(item => item.produto);
    return (
      <>
        {ProductsList.filter((c, index) => {
          return ProductsList.indexOf(c) === index;
        }).map((productName, index) => (
          <div key={`product-${index}`}>
            <StyledProductTitle>
              <div>
                {selectedFilterCard?.icon} {productName}
              </div>
              <StyledDate>
                {t('quotes.update')}: {lastUpdate}
              </StyledDate>
            </StyledProductTitle>
            {infoFilteredByGroup
              .filter(a => a.produto === productName)
              .map((item, index) => (
                <CollapsibleQuotationGraphic key={`graphic-${productName}-${index}`} quotationInfo={item} />
              ))}
          </div>
        ))}
      </>
    );
  };

  useEffect(() => {
    setInfoFilteredByGroup([]);
    if (paranaSelectedState) {
      setInfoFilteredByGroup(ParanaInfo.filter(item => item.grupo === selectedFilterCard?.text));
    } else {
      setInfoFilteredByGroup(TocantinsInfo.filter(item => item.grupo === selectedFilterCard?.text));
    }
  }, [selectedFilterCard]);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('agricultural.quotation')} />
      </StyledPageTitleContainer>
      <StyledButtonsContainer>
        <div>
          <StyledButton color="frisiaPrimary" type="button" onClick={() => setParanaSelectedState(true)} disabled={paranaSelectedState}>
            <span>{t('quotes.PARANA')}</span>
          </StyledButton>
          <StyledButton color="frisiaPrimary" type="button" onClick={() => setParanaSelectedState(false)} disabled={!paranaSelectedState}>
            <span>{t('quotes.TOCANTINS')}</span>
          </StyledButton>
        </div>
        <LoadingContainerScreen isLoading={onLoading}>
          <div style={{ marginLeft: '18%' }}>
            <LeftIconSvg
              style={{ cursor: 'pointer', margin: '8px' }}
              onClick={() => {
                if (date != null) {
                  let d = new Date(date);
                  d.setDate(d.getDate() - 1);
                  setDate(d);
                }
              }}
            />
            <InputDateWithSetting date={date} setDate={setDate} disableWeekends label={t('dashboard.filterDate')} />
            <RightIconSvg
              style={{ cursor: 'pointer', margin: '8px' }}
              onClick={() => {
                if (date != null) {
                  let d = new Date(date);
                  d.setDate(d.getDate() + 1);
                  setDate(d);
                }
              }}
            />
          </div>
        </LoadingContainerScreen>
        <StyledButton
          style={{ height: '42px', marginLeft: '12%', width: '200px', maxWidth: '200px' }}
          color="orange"
          type="button"
          onClick={() => history.push('/agricola/autorizacao-de-vendas')}
        >
          <span>{t('agricultural.auths')}</span>
        </StyledButton>
      </StyledButtonsContainer>
      <StyledCardsFilterContainer>
        {cards
          .filter((c, index) => {
            return cards.indexOf(c) === index;
          })
          .map((item, index) => (
            <WhiteAndBlueFilterCard
              isCardActive={item === selectedFilterCard}
              icon={item.icon}
              text={item.text}
              onClick={() => setSelectedFilterCard(item)}
              key={`card-${index}`}
            />
          ))}
      </StyledCardsFilterContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {ChicagoInfo.filter(item => item.grupo === selectedFilterCard?.text).length > 0 && (
          <StyledChicagoTable>
            <div>
              <p>ORIGEM</p>
              <p>VOLUME</p>
              <p>VALOR</p>
              <p>VARIAÇÃO</p>
            </div>
            {ChicagoInfo.filter(item => item.grupo === selectedFilterCard?.text).map((item, index) => {
              return (
                <div key={index}>
                  <p>Chicago</p>
                  <p>{item.embalagem}</p>
                  <p>
                    {item.moeda}
                    {item.valor}
                  </p>
                  <p>
                    {parseInt(item.mercado) > 0 ? <UpIconSvg /> : <DownIconSvg />}
                    {item.indicador}
                  </p>
                </div>
              );
            })}
          </StyledChicagoTable>
        )}
        <StyledContainer>
          <LoadingContainerScreen isLoading={onLoading}>
            {usd.bid ? (
              <>
                <Subtitle>
                  {t('quotes.oneDollarEquals')}
                  <div>{usd.bid}</div>
                </Subtitle>
                <StyledTable>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        {parseFloat(usd.pctChange ?? '0') > 0 ? <UpIconSvg /> : <DownIconSvg />}
                        {usd.pctChange}%
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>Euro</td>
                      <td>
                        R${' '}
                        {parseFloat(eur.bid ?? '0')
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')}
                      </td>
                      <td>
                        {parseFloat(eur.pctChange ?? '0') > 0 ? <UpIconSvg /> : <DownIconSvg />}
                        {eur.pctChange}%
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>Ibovespa</td>
                      <td>{ibov.bid}</td>
                      <td>
                        {parseFloat(ibov.pctChange?.replace(',', '.') ?? '0') > 0 ? <UpIconSvg /> : <DownIconSvg />}
                        {ibov.pctChange}
                      </td>
                    </tr>
                  </tbody>
                </StyledTable>
              </>
            ) : (
              <WarningStyled>{t('quotes.noQuotes')}</WarningStyled>
            )}
          </LoadingContainerScreen>
        </StyledContainer>
      </div>
      {renderByProducts()}
    </>
  );
};

const StyledChicagoTable = styled.div`
  min-width: 448px;
  width: 501px;
  margin: 20px;
  padding: 6px;
  div:first-child {
    background: #2a5299 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  div {
    border-radius: 10px;
    opacity: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: white;
  }
  p {
    margin: 14px;
  }
`;

const StyledContainer = styled.div`
  text-align: left;
  min-width: 448px;
  width: 501px;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 20px;
  padding: 6px;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
  }
`;

const StyledProductTitle = styled.div`
  min-width: 284px;
  margin: 15px;
  text-align: left;
  font: normal normal bold 18px/24px Arial;
  letter-spacing: 0.22px;
  color: #d1cf50;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  path {
    fill: #d1cf50;
    stroke: #d1cf50;
  }
`;
const StyledDate = styled.div`
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.14px;
  color: #183c96;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratRegular};
`;

export default withTranslation()(AgriculturalQuotation);
