import drugScheduleApi from 'api/drug-schedule-api';
import ButtonDownload from 'components/ButtonDownload';
import GenericDropdown from 'components/GenericDropdown';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import Loading from 'components/loading/loading';
import MainPageTitle from 'components/MainPageTitle';
import { ITypeCampaigns, ITypeCampaignsFilter } from 'model/bovine-drug-schedule';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import drugScheduleService from 'services/drug-schedule-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const DrugProgrammingBalance = ({ t }: WithTranslation) => {
  const [allCampaignsFilter, setAllCampaignsFilter] = useState<ITypeCampaignsFilter[]>();
  const [selectedCampaigns, setSelectedCampaigns] = useState<ITypeCampaignsFilter>();
  const [campaigns, setCampaigns] = useState<ITypeCampaigns[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (getUserRegistration != null) {
      drugScheduleService.getCampaignsFilter(getUserRegistration).then(response => {
        const orderedList = sortListByDate(response);
        setAllCampaignsFilter(orderedList);
        setSelectedCampaigns(orderedList?.[response.length - 1]);
      });
    }
  }, []);

  const getData = async () => {
    if (selectedCampaigns?.CD_CAMPANHA != null && getUserRegistration != null) {
      setIsLoading(true);
      const result = await drugScheduleService.getCampaigns(selectedCampaigns?.CD_CAMPANHA, getUserRegistration);
      setCampaigns(result);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [selectedCampaigns]);

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const sortListByDate = (list?: ITypeCampaignsFilter[]) => {
    list?.sort((a, b) => a.CD_CAMPANHA - b.CD_CAMPANHA);
    return list?.map(item => item);
  };

  const tableHeader = [
    t('drugBalance.product'),
    t('drugBalance.value'),
    t('drugBalance.schedule'),
    t('drugBalance.answered'),
    t('drugBalance.balance'),
  ];

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'} style={{ display: 'flex', alignItems: 'center' }}>
        <MainPageTitle fontSize={30} text={t('livestock.drugProgrammingBalance')} />

        {selectedCampaigns?.CD_CAMPANHA != null && getUserRegistration != null && (
          <ButtonDownload
            href={drugScheduleApi.generateReport(selectedCampaigns?.CD_CAMPANHA, getUserRegistration)}
            style={{ width: '174px', marginTop: '5px' }}
          />
        )}
      </StyledPageTitleContainer>

      <StyledPageTitleContainer margin={'25px 0 14px 0'}>
        <MainPageTitle fontSize={16} text={t('livestock.filter')} />
      </StyledPageTitleContainer>

      <StyledFilterContainer>
        <GenericDropdown
          listShowDropdown={allCampaignsFilter ?? []}
          keyToShowInDropdown="NM_CAMPANHA"
          defaultValue={selectedCampaigns}
          getSelectedValue={setSelectedCampaigns}
        />
      </StyledFilterContainer>

      <StyledContainer>
        {isLoading ? (
          <StyledLoadingContainer>
            <Loading />
          </StyledLoadingContainer>
        ) : campaigns.length > 0 ? (
          <StyledCardsContainer>
            <GenericTableBlueHeader
              headerItems={tableHeader}
              tableItems={campaigns.map(item => {
                return {
                  animalType: `${item.CD_PRODUTO} - ${item.DS_PRODUTO}`,
                  unitario: item.VL_PRECO_PRATICADO,
                  programado: item.QT_PRODUTO,
                  atendidaa: item.QT_ATENDIDA,
                  saldo: item.QT_SALDO,
                };
              })}
              dataColor="#787878"
              isInvertLineColor
              isDontHaveMaximumWidth
            />
          </StyledCardsContainer>
        ) : (
          <h3>{t('drugBalance.warning')}</h3>
        )}
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled.div`
  position: relative;
`;

const StyledCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 25px;
  margin-top: 15px;

  @media (min-width: 1617.98px) {
    padding-left: 20px;
  }
`;

const StyledLoadingContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 100px;
`;

export default withTranslation()(DrugProgrammingBalance);
