import { IBanks, ICostOptionSend, IGroupsCost } from 'model/agricultural-costs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import costsService from 'services/agricultural/costs-service';
import styled from 'styled-components';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import CurrencyInput from './CurrencyInput';
import GenericDropdown from './GenericDropdown';
import InputDate from './InputDate';
import { StyledButton } from './styled-button/StyledButton';

interface IProps extends WithTranslation {
  groupCost: IGroupsCost;
}

const CollapsibleCostTable = ({ groupCost, t }: IProps) => {
  const [isShowTableItems, setIsShowTableItems] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>();

  const [errorText, setErrorText] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [ownResource, setOwnResource] = useState<string>('0');
  const [duplicate, setDuplicate] = useState<string>('0');
  const [workingCapital, setWorkingCapital] = useState<string>('0');
  const [bank, setBank] = useState<string>('0');
  const [withLabor, setWithLabor] = useState<string>('0');
  const [withoutLabor, setWithoutLabor] = useState<string>('0');
  const [directStore, setDirectStore] = useState<string>('0');
  const [sumOfValues, setSumOfValues] = useState<string>('0,00');

  const [banksList, setBanksList] = useState<IBanks[]>([]);
  const [chosenBank, setChosenBank] = useState<IBanks>();
  const [fullBank, setFullBank] = useState<string>();

  const [sendProjectBank, setSendProjectBank] = useState<boolean>(false);
  const [financeLabor, setFinanceLabor] = useState<boolean>(false);
  const [diesel, setDiesel] = useState<boolean>(false);

  const [cancelQuest, setCancelQuest] = useState<boolean>(false);

  const getPercentage = (value: string) => {
    const totalValue = groupCost.VL_OPTADO !== 0 ? groupCost.VL_OPTADO : groupCost.VL_INSUMO;
    let percentage =
      (parseFloat(
        value
          .replaceAll('.', '')
          .replaceAll(',', '.')
          .replace(/[^0-9.-]+/g, '')
      ) *
        100) /
      totalValue;
    return percentage.toLocaleString('pt-br');
  };

  const convertNumber = value =>
    parseFloat(
      value
        .replaceAll('.', '')
        .replaceAll(',', '.')
        .replace(/[^0-9.-]+/g, '')
    );

  const getSumOfValues = (arrayOfValue: string[]) => {
    let sum = arrayOfValue
      .reduce((previousValue, currentValue) => {
        const previus = typeof previousValue === 'number' ? previousValue : convertNumber(previousValue);
        return previus + convertNumber(currentValue);
      }, 0)
      .toFixed(2);
    setSumOfValues(sum.replaceAll(',', '').replaceAll('.', ','));
  };

  useEffect(() => {
    getSumOfValues([ownResource, duplicate, workingCapital, bank, withLabor, withoutLabor, directStore]);
  }, [isShowTableItems, ownResource, duplicate, workingCapital, bank, withLabor, withoutLabor, directStore]);

  useEffect(() => {
    if (convertNumber(sumOfValues) - groupCost.VL_INSUMO == 0) {
      setErrorText(false);
    } else {
      setErrorText(true);
    }
  }, [sumOfValues]);

  useEffect(() => {
    if (groupCost.VL_OPTADO == 0) {
      setShowButton(true);
    }
  }, []);

  const cancelCostOption = () => {
    costsService.cancelCostOption(groupCost.ID_PLANEJAMENTO.toString());
  };

  useEffect(() => {
    if (groupCost.VL_OPTADO !== 0) {
      costsService.getCostInfo(groupCost.CD_AGRUPADOR.toString(), groupCost.IE_PROPOSITO).then(response => {
        if (response.length > 0) {
          setSendProjectBank(response[0]?.IE_PROJETO_BANCO);
          setFinanceLabor(response[0].IE_FINANCIA_MO);
          setDiesel(response[0].IE_INCLUI_DIESEL);
          response.forEach(item => {
            if (item.VL_CAPITAL > 0) {
              switch (item.OPCAO_CUSTEIO) {
                case 'BANCO':
                  setChosenBank(banksList.find(bank => bank.FULL_BANCO === item.FULL_BANCO));
                  setFullBank(item.FULL_BANCO);
                  item.DT_PREVISAO_BANCO && setSelectedDate(item.DT_PREVISAO_BANCO);
                  setBank(item.VL_CAPITAL.toString().replaceAll('.', ','));
                  break;
                case 'CAPITAL DE GIRO PRÓPRIO':
                  setWorkingCapital(item.VL_CAPITAL.toString().replaceAll('.', ','));
                  break;
                case 'RECURSO PRÓPRIO':
                  setOwnResource(item.VL_CAPITAL.toString().replaceAll('.', ','));
                  break;
                case 'DUPLICATA (PRAZO SAFRA)':
                  setDuplicate(item.VL_CAPITAL.toString().replaceAll('.', ','));
                  break;
                case 'CUSTEIO FRÍSIA COM MÃO DE OBRA':
                  setWithLabor(item.VL_CAPITAL.toString().replaceAll('.', ','));
                  break;
                case 'CUSTEIO FRÍSIA SEM MÃO DE OBRA':
                  setWithoutLabor(item.VL_CAPITAL.toString().replaceAll('.', ','));
                  break;
                case 'DIRETO LOJA':
                  setDirectStore(item.VL_CAPITAL.toString().replaceAll('.', ','));
                  break;
              }
            }
          });
        }
      });
    }
    costsService.getBanks().then(setBanksList);
  }, []);

  const submitCostOption = () => {
    const costOptionOrder: ICostOptionSend = {
      Id: groupCost.ID_PLANEJAMENTO,
      Projeto: sendProjectBank ? 1 : 0,
      Dia: parseFloat(moment(selectedDate, 'DD/MM/YYYY').format('DD')),
      Mes: parseFloat(moment(selectedDate, 'DD/MM/YYYY').format('MM')),
      FinanciaMO: financeLabor ? 1 : 0,
      IncluiDiesel: diesel ? 1 : 0,
      itens: [
        {
          Opcao: 'RECURSO PRÓPRIO',
          CGP: 0,
          Banco: '',
          FEBRABAN: null,
          Capital: convertNumber(ownResource),
          Percentual: convertNumber(getPercentage(ownResource)),
          IdPlanejamento: groupCost.ID_PLANEJAMENTO,
          Actived: 1,
          CreatedBy: 'sys',
          Created: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          Opcao: 'DUPLICATA (PRAZO SAFRA)',
          CGP: 0,
          Banco: '',
          FEBRABAN: null,
          Capital: convertNumber(duplicate),
          Percentual: convertNumber(getPercentage(duplicate)),
          IdPlanejamento: groupCost.ID_PLANEJAMENTO,
          Actived: 1,
          CreatedBy: 'sys',
          Created: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          Opcao: 'CAPITAL DE GIRO PRÓPRIO',
          CGP: 1,
          Banco: '',
          FEBRABAN: null,
          Capital: convertNumber(workingCapital),
          Percentual: convertNumber(getPercentage(workingCapital)),
          IdPlanejamento: groupCost.ID_PLANEJAMENTO,
          Actived: 1,
          CreatedBy: 'sys',
          Created: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          Opcao: 'BANCO',
          CGP: 0,
          Banco: chosenBank?.FULL_BANCO ?? '',
          FEBRABAN: chosenBank?.CD_FEBRABAN ?? 1,
          Capital: convertNumber(bank),
          Percentual: convertNumber(getPercentage(bank)),
          IdPlanejamento: groupCost.ID_PLANEJAMENTO,
          Actived: 1,
          CreatedBy: 'sys',
          Created: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          Opcao: 'CUSTEIO FRÍSIA COM MÃO DE OBRA',
          CGP: 0,
          Banco: '',
          FEBRABAN: null,
          Capital: convertNumber(withLabor),
          Percentual: convertNumber(getPercentage(withLabor)),
          IdPlanejamento: groupCost.ID_PLANEJAMENTO,
          Actived: 1,
          CreatedBy: 'sys',
          Created: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          Opcao: 'CUSTEIO FRÍSIA SEM MÃO DE OBRA',
          CGP: 0,
          Banco: '',
          FEBRABAN: null,
          Capital: convertNumber(withoutLabor),
          Percentual: convertNumber(getPercentage(withoutLabor)),
          IdPlanejamento: groupCost.ID_PLANEJAMENTO,
          Actived: 1,
          CreatedBy: 'sys',
          Created: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          Opcao: 'DIRETO LOJA',
          CGP: 0,
          Banco: '',
          FEBRABAN: null,
          Capital: convertNumber(directStore),
          Percentual: convertNumber(getPercentage(directStore)),
          IdPlanejamento: groupCost.ID_PLANEJAMENTO,
          Actived: 1,
          CreatedBy: 'sys',
          Created: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
        },
      ],
    };
    costsService.createCostOption(costOptionOrder);
  };

  return (
    <>
      <StyledTable style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>
              {groupCost.VL_OPTADO === 0 ? (
                <StyledCostButtonGreen onClick={() => setIsShowTableItems(!isShowTableItems)}>{t('cost.costOption')}</StyledCostButtonGreen>
              ) : (
                <StyledCostButtonOrange onClick={() => setIsShowTableItems(!isShowTableItems)}>{t('cost.toView')}</StyledCostButtonOrange>
              )}
            </td>
            <td>{groupCost.NM_CULTURA}</td>
            <td>{groupCost.NM_TIPO_SAFRA}</td>
            <td>{groupCost.FULL_PROPOSITO}</td>
            <td>{groupCost.FULL_PROPRIEDADE}</td>
            <td>{groupCost.NM_MUNICIPIO}</td>
            <td>{groupCost.QT_AREA}</td>
            <td>{`R$ ${groupCost.VL_MO.toLocaleString('pt-br')}`}</td>
            <td>{`R$ ${groupCost.VL_INSUMO.toLocaleString('pt-br')}`}</td>
            <td>{`R$ ${groupCost.VL_TOTAL.toLocaleString('pt-br')}`}</td>
            <td>{`R$ ${groupCost.VL_OPTADO.toLocaleString('pt-br')}`}</td>
          </tr>
        </tbody>
      </StyledTable>
      {isShowTableItems && (
        <NewCostOption>
          <StyledTableData>{t('cost.costOption')}</StyledTableData>
          {(groupCost.L_RCP === 'Y' || groupCost.VL_OPTADO !== 0) && (
            <StyledRow>
              <label>{t('cost.ownResource')}</label>
              <aside>
                <MyStyledInput>
                  <CurrencyInput
                    placeholder="0,00"
                    disabled={groupCost.VL_OPTADO !== 0 && true}
                    defaultValue={groupCost.VL_OPTADO !== 0 && ownResource}
                    onChange={e => setOwnResource(e.target.value)}
                  />
                </MyStyledInput>
                <div>{getPercentage(ownResource)} %</div>
              </aside>
            </StyledRow>
          )}
          {(groupCost.L_DUP === 'Y' || groupCost.VL_OPTADO !== 0) && (
            <StyledRow>
              <label>{t('cost.duplicate')}</label>
              <aside>
                <MyStyledInput>
                  <CurrencyInput
                    disabled={groupCost.VL_OPTADO !== 0 && true}
                    defaultValue={groupCost.VL_OPTADO !== 0 && duplicate}
                    placeholder="0,00"
                    onChange={e => setDuplicate(e.target.value)}
                  />
                </MyStyledInput>
                <div>{getPercentage(duplicate)} %</div>
              </aside>
            </StyledRow>
          )}
          {(groupCost.L_CGP === 'Y' || groupCost.VL_OPTADO !== 0) && (
            <StyledRow>
              <label>{t('cost.workingCapital')}</label>
              <aside>
                <MyStyledInput>
                  <CurrencyInput
                    disabled={groupCost.VL_OPTADO !== 0 && true}
                    defaultValue={groupCost.VL_OPTADO !== 0 && workingCapital}
                    placeholder="0,00"
                    onChange={e => setWorkingCapital(e.target.value)}
                  />
                </MyStyledInput>
                <div>{getPercentage(workingCapital)} %</div>
              </aside>
            </StyledRow>
          )}
          {(groupCost.L_BCO === 'Y' || groupCost.VL_OPTADO !== 0) && (
            <StyledRow>
              <label>{t('cost.bank')}</label>
              <aside>
                <MyStyledInput>
                  <CurrencyInput
                    disabled={groupCost.VL_OPTADO !== 0 && true}
                    defaultValue={groupCost.VL_OPTADO !== 0 && bank}
                    placeholder="0,00"
                    onChange={e => setBank(e.target.value)}
                  />
                </MyStyledInput>
                <div>{getPercentage(bank)} %</div>
              </aside>
            </StyledRow>
          )}
          {(groupCost.L_FCM === 'Y' || groupCost.VL_OPTADO !== 0) && (
            <StyledRow>
              <label>{t('cost.withLabor')}</label>
              <aside>
                <MyStyledInput>
                  <CurrencyInput
                    disabled={groupCost.VL_OPTADO !== 0 && true}
                    defaultValue={groupCost.VL_OPTADO !== 0 && withLabor}
                    placeholder="0,00"
                    onChange={e => setWithLabor(e.target.value)}
                  />
                </MyStyledInput>
                <div>{getPercentage(withLabor)} %</div>
              </aside>
            </StyledRow>
          )}
          {(groupCost.L_FSM === 'Y' || groupCost.VL_OPTADO !== 0) && (
            <StyledRow>
              <label>{t('cost.withoutLabor')}</label>
              <aside>
                <MyStyledInput>
                  <CurrencyInput
                    disabled={groupCost.VL_OPTADO !== 0 && true}
                    defaultValue={groupCost.VL_OPTADO !== 0 && withoutLabor}
                    placeholder="0,00"
                    onChange={e => setWithoutLabor(e.target.value)}
                  />
                </MyStyledInput>
                <div>{getPercentage(withoutLabor)} %</div>
              </aside>
            </StyledRow>
          )}
          {(groupCost.L_DLJ === 'Y' || groupCost.VL_OPTADO !== 0) && (
            <StyledRow>
              <label>{t('cost.directStore')}</label>
              <aside>
                <MyStyledInput>
                  <CurrencyInput
                    disabled={groupCost.VL_OPTADO !== 0 && true}
                    placeholder="0,00"
                    defaultValue={groupCost.VL_OPTADO !== 0 && directStore}
                    onChange={e => setDirectStore(e.target.value)}
                  />
                </MyStyledInput>
                <div>{getPercentage(directStore)} %</div>
              </aside>
            </StyledRow>
          )}
          <StyledRow>
            <label>{t('cost.total')}</label>
            <div>R$ {sumOfValues}</div>
          </StyledRow>
          {groupCost.VL_OPTADO == 0 && (
            <>
              <StyledRow>
                <label>{t('cost.inputValue')}</label>
                <div>R$ {groupCost.VL_INSUMO.toLocaleString('pt-br')}</div>
              </StyledRow>
              <StyledRow>
                <label>{t('cost.mustBeDistributed')}</label>
                {errorText && (
                  <StyledErrorMessage style={{ position: 'unset', fontSize: '15px' }}>
                    {`${t('cost.costOption')}: (R$ ${sumOfValues}), ${t('cost.divergence')} (R$ ${groupCost.VL_INSUMO.toLocaleString(
                      'pt-br'
                    )})!`}
                  </StyledErrorMessage>
                )}
              </StyledRow>
            </>
          )}

          {convertNumber(bank) > 0 && (
            <>
              <StyledRow>
                <StyledQuest>{t('cost.selectABank')}:</StyledQuest>
                <StyledFilterContainer filterWidth={350}>
                  {groupCost.VL_OPTADO !== 0 && fullBank ? (
                    <GenericDropdown
                      defaultValue={fullBank}
                      disabled={true}
                      listShowDropdown={banksList}
                      getSelectedValue={setChosenBank}
                    />
                  ) : (
                    <GenericDropdown listShowDropdown={banksList} getSelectedValue={setChosenBank} keyToShowInDropdown={'FULL_BANCO'} />
                  )}
                </StyledFilterContainer>
              </StyledRow>
              <StyledRow>
                <StyledQuest>{t('cost.plantingDate')}</StyledQuest>
                <StyledFilterContainer filterWidth={250}>
                  {groupCost.VL_OPTADO == 0 ? (
                    <InputDate defaultValue={new Date()} disableWeekends label={t('cost.prediction')} getDate={setSelectedDate} />
                  ) : (
                    selectedDate && <div>{selectedDate}</div>
                  )}
                </StyledFilterContainer>
              </StyledRow>
            </>
          )}
          <StyledRow>
            <StyledQuest>{t('cost.sendProject')} </StyledQuest>
            <StyledFilterContainer filterWidth={100}>
              <GenericDropdown
                defaultValue={groupCost.VL_OPTADO !== 0 && (sendProjectBank ? 'Sim' : 'Não')}
                disabled={groupCost.VL_OPTADO !== 0 && true}
                listShowDropdown={['Sim', 'Não']}
                getSelectedValue={value => {
                  if (groupCost.VL_OPTADO === 0) {
                    if (value === 'Sim') {
                      setSendProjectBank(true);
                    } else {
                      setSendProjectBank(false);
                    }
                  }
                }}
              />
            </StyledFilterContainer>
          </StyledRow>
          <StyledRow>
            <StyledQuest>{t('cost.financeLabor')}</StyledQuest>
            <StyledFilterContainer filterWidth={100}>
              <GenericDropdown
                defaultValue={groupCost.VL_OPTADO !== 0 && (financeLabor ? 'Sim' : 'Não')}
                disabled={groupCost.VL_OPTADO !== 0 && true}
                listShowDropdown={['Sim', 'Não']}
                getSelectedValue={value => {
                  if (groupCost.VL_OPTADO === 0) {
                    if (value === 'Sim') {
                      setFinanceLabor(true);
                    } else {
                      setFinanceLabor(false);
                    }
                  }
                }}
              />
            </StyledFilterContainer>
          </StyledRow>
          {groupCost.L_DPS === 'Y' ||
            (groupCost.VL_OPTADO !== 0 && (
              <StyledRow>
                <StyledQuest>{t('cost.diesel')}</StyledQuest>
                <StyledFilterContainer filterWidth={100}>
                  <GenericDropdown
                    defaultValue={groupCost.VL_OPTADO !== 0 && (diesel ? 'Sim' : 'Não')}
                    disabled={groupCost.VL_OPTADO !== 0 && true}
                    listShowDropdown={['Sim', 'Não']}
                    getSelectedValue={value => {
                      if (groupCost.VL_OPTADO === 0) {
                        if (value === 'Sim') {
                          setDiesel(true);
                        } else {
                          setDiesel(false);
                        }
                      }
                    }}
                  />
                </StyledFilterContainer>
              </StyledRow>
            ))}
          {showButton ? (
            <StyledButton
              disabled={
                sumOfValues.replace('.', '').replace(',', '') !=
                  groupCost.VL_INSUMO.toLocaleString('pt-br').replace('.', '').replace(',', '') ||
                (convertNumber(bank) > 0 && chosenBank?.FULL_BANCO == null)
              }
              onClick={submitCostOption}
              color="frisiaPrimary"
              style={{ maxWidth: '210px', alignSelf: 'center', whiteSpace: 'nowrap', fontSize: '11px' }}
            >
              <span>{t('cost.sendingCost')}</span>
            </StyledButton>
          ) : !cancelQuest ? (
            <StyledButton style={{ padding: '8px', lineHeight: '11px' }} color="orange" type="button" onClick={() => setCancelQuest(true)}>
              <span>{t('global.button.cancel')}</span>
            </StyledButton>
          ) : (
            <>
              <SubtitleStyled>{`${t('trr.cancelCosts')} ${groupCost.ID_PLANEJAMENTO}?`}</SubtitleStyled>
              <CancelButton
                onClick={() => {
                  setCancelQuest(false);
                }}
                style={{ width: '145px', height: '40px' }}
              >
                {t('global.field.no')}
              </CancelButton>
              <StyledButton
                color="frisiaPrimary"
                onClick={() => {
                  cancelCostOption();
                }}
                type="button"
                style={{ minWidth: '145px', height: '42px', marginLeft: '23px', marginTop: '10px' }}
              >
                <span>{t('global.field.yes')}</span>
              </StyledButton>
            </>
          )}
        </NewCostOption>
      )}
    </>
  );
};

const NewCostOption = styled.div`
  margin: 20px;
  label {
    font-size: 14px;
    width: 50%;
    font-family: ${({ theme }) => theme.font.montserratRegular};
    color: #404040;
  }
  aside {
    display: flex;
    font-size: 14px;
    width: 40%;
  }
`;

const StyledRow = styled.div`
  margin: 10px 0px;
  display: flex;
`;

const StyledQuest = styled.div`
  margin: 10px 0px;
  font-size: 15px !important;
  width: 50%;
  color: #404040 !important;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const StyledCostButtonGreen = styled.button`
  width: 100%;
  font-size: min(max(11px, 0.85vw), 22px);
  background: #32d175 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  letter-spacing: 0px;
  color: #032762;
  opacity: 1;
  border: none;
  margin-right: 5px;
`;

const StyledCostButtonOrange = styled.button`
  width: 100%;
  background: #cb6800 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  font-size: 0.85vw;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  border: none;
  margin-right: 5px;
`;

const StyledTable = styled.table`
  height: 40px;
  color: #4c70aa;
  cursor: pointer;
  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  td {
    align-self: center;
    width: 8.55%;
    letter-spacing: var(--unnamed-character-spacing-0-02);
    color: var(--unnamed-color-656565);
    text-align: left;
    font-family: ${({ theme }) => theme.font.montserratRegular} !important;
    letter-spacing: 0.02px;
    color: #656565 !important;
    text-transform: capitalize;
    opacity: 1;
    font-size: min(max(11px, 0.9vw), 21px);
    font-weight: bold;
    padding-left: 2px;
  }
`;

const MyStyledInput = styled.div`
  margin-right: 24px;
  font-size: 15px;
  input {
    max-width: 196px;
    letter-spacing: 0px;
    color: #2a5299;
    opacity: 1;
    text-align: center;
    border: 1px #8080806b solid;
    border-radius: 10px;
  }
`;

const StyledTableData = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold} !important;
  color: ${({ theme }) => theme.color.frisiaPrimaryDark} !important;
  text-align: left !important;
  margin-bottom: 15px;
`;

const StyledButtonInCard = styled.button`
  font-family: ${({ theme }) => theme.font.montserratMedium};
  background-color: white;
  border: none;
  color: #d96767;
  letter-spacing: 1.05px;
`;

export const SubtitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

export const CancelButton = styled.button`
  width: 245px;
  height: 34px;
  font-size: 14px;
  letter-spacing: 0.02px;
  color: #d4a518;
  text-transform: uppercase;
  opacity: 1;
  border: #d4a518 2px solid;
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  cursor: pointer;
`;

export default withTranslation()(CollapsibleCostTable);
