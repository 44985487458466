import { TFunctionResult } from 'i18next';
import { Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledTable, StyledTableRow } from 'styled-components/StyledForTables';

interface IProps extends WithTranslation {
  arrayOfHeaders: string[][];
  arrayOfData: (string | number | TFunctionResult)[][];
  isDontHaveMaximumWidth?: boolean;
  colsplan?: number;
}

interface IStyledProps {
  isApplyBorderRadius: boolean;
}

const Table2HeadersLines = ({ arrayOfHeaders, arrayOfData, isDontHaveMaximumWidth, colsplan, t }: IProps) => {
  return (
    <StyledTable isDontHaveMaximumWidth={isDontHaveMaximumWidth}>
      <Fragment key={`table-2HeadersLines`}>
        <thead>
          <StyledTableRowHeader isApplyBorderRadius style={{ width: '100%' }}>
            {arrayOfHeaders[0].map((headerItem, headerItemIndex) => (
              <th colSpan={colsplan ? colsplan : 1} key={`header-item-${headerItemIndex}`}>
                {headerItem}
              </th>
            ))}
          </StyledTableRowHeader>
          <StyledTableRowHeader isApplyBorderRadius={false}>
            {arrayOfHeaders[1].map((headerItem, headerItemIndex) => (
              <th key={`header-item-${headerItemIndex}`}>{headerItem}</th>
            ))}
          </StyledTableRowHeader>
        </thead>

        <tbody>
          {arrayOfData.map((rowItem, rowItemIndex) => (
            <StyledTableRow
              key={`row-item-${rowItemIndex}`}
              className={`${rowItemIndex === arrayOfHeaders[1].length - 1 ? 'last-line' : ''}`}
            >
              {rowItem.map((item, indexItem) => (
                <td key={`column-${indexItem}`}>{item}</td>
              ))}
            </StyledTableRow>
          ))}
        </tbody>
      </Fragment>
    </StyledTable>
  );
};

const StyledTableRowHeader = styled.tr<IStyledProps>`
  height: 50px;
  background-color: white;
  color: #032762;

  th {
    text-align: center;
  }

  th:nth-child(1) {
    text-align: left;
    padding-left: 20px;
    border-radius: ${({ isApplyBorderRadius }) => (isApplyBorderRadius ? '16px 0 0 0' : '')};
  }

  th:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    border-radius: ${({ isApplyBorderRadius }) => (isApplyBorderRadius ? '0 16px 0 0' : '')};
  }
`;

const StyledTableFooter = styled.tfoot`
  background-color: white;
`;

const StyleTableRowFooter = styled.tr`
  height: 70px;
`;

const StyledTableData = styled.td.attrs({
  colSpan: 2,
})`
  border-radius: 0 0 16px 16px;
`;

export default withTranslation()(Table2HeadersLines);
