import GenericDropdown from 'components/GenericDropdown';
import { StyledButton } from 'components/styled-button/StyledButton';
import environmentalApi from 'api/environmental-api';
import { IEnvStatus } from 'model/environmental';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import environmentalService from 'services/environmental-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import Document from '../components/Document';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import ButtonDownload from 'components/ButtonDownload';

const Environmental = ({ t }: WithTranslation) => {
  const [status, setStatus] = useState<IEnvStatus[]>([]);
  const [reportUrl, setReportUrl] = useState<string>();
  const getStatus = () => {
    environmentalService.getEnvStatus().then(response => {
      setStatus(response);
    });
  };
  const [selectedStatus, setSelectedStatus] = useState<IEnvStatus>();
  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    if (selectedStatus?.CD_STATUS !== null && selectedStatus?.CD_STATUS !== undefined) {
      setReportUrl(environmentalApi.getIRADocListReport(getUserRegistration.toString(), selectedStatus?.CD_STATUS ?? 'TODAS'));
      console.log('status 1' + selectedStatus?.CD_STATUS);
    } else {
      setReportUrl(environmentalApi.getIRADocListWithoutStatusReport(getUserRegistration.toString()));
      console.log('status 2');
    }
  }, [selectedStatus]);
  return (
    <>
      <StyledFiltersContainer>
        <StyledFilterContainer filterWidth={340}>
          <GenericDropdown
            label={t('global.field.situation')}
            placeholder={t('global.field.selectHere')}
            listShowDropdown={status}
            keyToShowInDropdown="DS_STATUS"
            getSelectedValue={setSelectedStatus}
          />
        </StyledFilterContainer>
      </StyledFiltersContainer>
      <LoadingContainerScreen isLoading={reportUrl === undefined}>
        {reportUrl && (
          <>
            <ButtonDownload style={{ marginLeft: 'auto' }} href={reportUrl} />
            <iframe
              sandbox="allow-scripts allow-popups allow-forms allow-top-navigation"
              style={{ width: '100%', height: '800px' }}
              src={reportUrl}
            />
          </>
        )}
      </LoadingContainerScreen>
    </>
  );
};

const DocumentCard = styled.div`
  top: 342px;
  left: 355px;
  width: 389px;
  height: 64px;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  opacity: 1;
  font-size: 12px;
  color: #2a5299;
`;

const StyledFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  row-gap: 30px;
  flex-wrap: wrap;
`;

const StyledDocument = styled.div`
  display: flex;
  align-items: center;

  width: 390px;
  min-width: 390px;
  height: 65px;

  padding: 0 5px;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 12px;
  background-color: white;
  cursor: pointer;

  svg {
    min-height: 40px;
    min-width: 30px;
    margin-right: 15px;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledDocumentsContainer = styled.div`
  display: flex;
  -ms-flex-pack: justify;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  row-gap: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 535px;
  overflow-y: auto;
  justify-content: flex-start;
  margin-top: 20px;
`;

export default withTranslation()(Environmental);
