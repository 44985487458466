import { apiCore } from 'api/api';
import { AxiosResponse } from 'axios';
import {
  IAverageInputCosts,
  IAverageProductivity,
  IAverageSellPrice,
  ICropandStates,
  ICultureProfitability,
  IInputCosts,
  IProductivity,
  IProducts,
  IProfitability,
  IProfitabilityAverage,
  ISellPrice,
} from 'model/agricultural-profitability';
import { sendLog } from 'shared/util/api-utils';

export const profitabilityApi = () => {
  const getCropandStates = (): Promise<AxiosResponse<ICropandStates[]>> => {
    sendLog('Consultando safras e estados', `/AgricolaRentabilidade/GetSafrasEEstados`, JSON.stringify({}), 'get');
    return apiCore.get<ICropandStates[]>(`/AgricolaRentabilidade/GetSafrasEEstados`);
  };

  const getCulture = (crop: number, state: number): Promise<AxiosResponse<ICultureProfitability[]>> => {
    sendLog(
      'Consultando culturas e insumos',
      `/AgricolaRentabilidade/GetCulturasEInsumos?CD_SAFRA=${crop}&CD_ESTADO=${state}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ICultureProfitability[]>(`/AgricolaRentabilidade/GetCulturasEInsumos?CD_SAFRA=${crop}&CD_ESTADO=${state}`);
  };

  const getProductive = (crop: number, state: number, culture: number): Promise<AxiosResponse<IProductivity[]>> => {
    sendLog(
      'Consultando produtividade',
      `/AgricolaRentabilidade/GetProdutividade?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IProductivity[]>(
      `/AgricolaRentabilidade/GetProdutividade?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}`
    );
  };

  const getAverageProductive = (crop: number, state: number, culture: number): Promise<AxiosResponse<IAverageProductivity[]>> => {
    sendLog(
      'Consultando produtividade Media da Frísia',
      `/AgricolaRentabilidade/GetProdutividadeMediaFrisia?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IAverageProductivity[]>(
      `/AgricolaRentabilidade/GetProdutividadeMediaFrisia?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}`
    );
  };

  const getProducts = (crop: number, state: number, culture: number): Promise<AxiosResponse<IProducts[]>> => {
    sendLog(
      'Consultando produtos venda',
      `/AgricolaRentabilidade/GetProdutosVenda?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IProducts[]>(`/AgricolaRentabilidade/GetProdutosVenda?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}`);
  };

  const getSellPrice = (crop: number, state: number, culture: number, product: number): Promise<AxiosResponse<ISellPrice[]>> => {
    sendLog(
      'Consultando preço médio de venda',
      `/AgricolaRentabilidade/GetPrecoMedioVenda?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}&CD_PRODUTO=${product}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ISellPrice[]>(
      `/AgricolaRentabilidade/GetPrecoMedioVenda?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}&CD_PRODUTO=${product}`
    );
  };

  const getAverageSellPrice = (
    crop: number,
    state: number,
    culture: number,
    product: number
  ): Promise<AxiosResponse<IAverageSellPrice[]>> => {
    sendLog(
      'Consultando preço médio de venda',
      `/AgricolaRentabilidade/GetPrecoMedioVendaFrisia?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}&CD_PRODUTO=${product}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IAverageSellPrice[]>(
      `/AgricolaRentabilidade/GetPrecoMedioVendaFrisia?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}&CD_PRODUTO=${product}`
    );
  };

  const getInputCosts = (crop: number, state: number, culture: number, inputType: string): Promise<AxiosResponse<IInputCosts[]>> => {
    sendLog(
      'Consultando custos de insumos',
      `/AgricolaRentabilidade/GetCustosInsumos?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}&IE_TIPO_INSUMO=${inputType}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IInputCosts[]>(
      `/AgricolaRentabilidade/GetCustosInsumos?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}&IE_TIPO_INSUMO=${inputType}`
    );
  };

  const getAverageInputCosts = (
    crop: number,
    state: number,
    culture: number,
    inputType: string
  ): Promise<AxiosResponse<IAverageInputCosts[]>> => {
    sendLog(
      'Consultando media de custos de insumo Frisia',
      `/AgricolaRentabilidade/GetCustosInsumosMedioFrisia?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}&IE_TIPO_INSUMO=${inputType}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IAverageInputCosts[]>(
      `/AgricolaRentabilidade/GetCustosInsumosMedioFrisia?CD_SAFRA=${crop}&CD_ESTADO=${state}&CD_CULTURA=${culture}&IE_TIPO_INSUMO=${inputType}`
    );
  };

  const getProfitability = (crop: number, culture: number): Promise<AxiosResponse<IProfitability[]>> => {
    sendLog(
      'Consultando rentabilidade',
      `/AgricolaRentabilidade/GetRentabilidade?CD_SAFRA=${crop}&CD_CULTURA=${culture}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IProfitability[]>(`/AgricolaRentabilidade/GetRentabilidade?CD_SAFRA=${crop}&CD_CULTURA=${culture}`);
  };

  const getAverageProfitability = (crop: number, culture: number): Promise<AxiosResponse<IProfitabilityAverage[]>> => {
    sendLog(
      'Consultando rentabilidade média frisia',
      `/AgricolaRentabilidade/GetRentabilidadeMediaFrisia?CD_SAFRA=${crop}&CD_CULTURA=${culture}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IProfitabilityAverage[]>(
      `/AgricolaRentabilidade/GetRentabilidadeMediaFrisia?CD_SAFRA=${crop}&CD_CULTURA=${culture}`
    );
  };

  return {
    getCropandStates,
    getCulture,
    getProductive,
    getAverageProductive,
    getProducts,
    getSellPrice,
    getAverageSellPrice,
    getInputCosts,
    getAverageInputCosts,
    getProfitability,
    getAverageProfitability,
  };
};

export default profitabilityApi();
