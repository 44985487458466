import { HttpRequestStatus } from 'model/enums/http-request-status';
import { User } from 'model/user';

export type ApiResponse = Record<string, any>;

export enum AuthenticationActionTypes {
  LOGIN_REQUEST = '@@authentication/LOGIN_REQUEST',
  CORE_REQUEST = '@@authentication/CORE_REQUEST',
  SELECTED = '@@authentication/SELECTED',
  LIST = '@@authentication/LIST',
  LOGIN_SUCCESS = '@@authentication/LOGIN_SUCCESS',
  LOGIN_ERROR = '@@authentication/LOGIN_ERROR',
  GET_SESSION_REQUEST = '@@authentication/GET_SESSION_REQUEST',
  GET_SESSION_SUCCESS = '@@authentication/GET_SESSION_SUCCESS',
  GET_SESSION_ERROR = '@@authentication/GET_SESSION_ERROR',
  LOGOUT_REQUEST = '@@authentication/LOGOUT_REQUEST',
  INDICATORS_REQUEST = '@@authentication/INDICATORS_REQUEST',
  EXTRANET_FILES_REQUEST = '@@authentication/INDICATORS_REQUEST',
}

export interface AuthenticationState {
  readonly loginStatus: HttpRequestStatus;
  readonly getSessionStatus: HttpRequestStatus;
  readonly isAuthenticated: boolean;
  readonly account?: User;
  readonly error?: string;
  readonly accountInfo?: any;
  readonly coreInfo?: any;
  readonly indicatorsInfo?: any;
  readonly extranetFilesInfo?: any;
  readonly selectedInfo?: any;
  readonly listOfPages?: any[];
}
