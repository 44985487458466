import MainPageTitle from 'components/MainPageTitle';
import { useCallback, useEffect, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { VideoCardContainer } from 'components/podcast-player/StyledComponents';
import contentfulService from 'services/contentful-service';
import { ContentfullItem, ICategoriesList, IContentTypeCategories } from 'model/contentful-results';

interface IStyledProps {
  isActive?: boolean;
}

const StyledTablesContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  margin: auto;
`;

const StyledCardsContainer = styled.div`
  max-width: 1130px;
  margin: 19px auto 37px auto;
  display: flex;
  flex-flow: wrap;
  row-gap: 15px;
  column-gap: 15px;
`;

const StyledCard = styled.div<IStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 170px;
  height: 65px;
  padding: 0 12px;
  background-color: #fcfcfc;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px;
  background-color: ${({ isActive, theme }) => (isActive ? theme.color.frisiaPrimary : '#fcfcfc')};
  color: ${({ isActive }) => (isActive ? '#D7CC27' : '#656565')};
  :hover {
    background-color: #d8deeb;
  }
`;

const FavoritesContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

const StyledText = styled.div`
  max-width: 119px;
  text-align: center;
`;

const Videos = ({ t }: WithTranslation) => {
  const history = useHistory();
  const [contentVideos, setContentVideos] = useState<ContentfullItem[]>([]);
  const [categories, setCategories] = useState<ICategoriesList[]>([]);
  const [categoryShowing, setcategoryShowing] = useState<ICategoriesList[]>([]);
  const [selectedFilterCard, setSelectedFilterCard] = useState<string>(t('agricultural.all'));

  const getContent = async () => {
    const videosResult = await contentfulService.getAllVideos();
    setContentVideos(videosResult.items);
  };

  const getCategories = async () => {
    const result: IContentTypeCategories = await contentfulService.getAllCategories();

    setCategories(
      result.items.map(category => {
        return { name: category.fields.title, id: category.sys.id };
      })
    );
    const categoryName = result.items.find(item => item.sys.id === window.location.href.split('#')[1])?.fields.title;
    if (categoryName) {
      categoryName && setSelectedFilterCard(categoryName);
    } else {
      setcategoryShowing(
        result.items.map(category => {
          return { name: category.fields.title, id: category.sys.id };
        })
      );
    }
  };

  useEffect(() => {
    setcategoryShowing(categories.filter(item => item.name == selectedFilterCard));
    if (selectedFilterCard === t('agricultural.all')) {
      setcategoryShowing(categories);
    }
  }, [selectedFilterCard]);

  useEffect(() => {
    async function callAsync() {
      await getContent();
      await getCategories();
    }
    callAsync();
  }, []);

  const pushToPlayer = videoId => {
    const location = {
      pathname: `/canal-frisia/play/${videoId}`,
    };
    history.push(location);
  };

  const renderCategoryShowing = useCallback(() => {
    return categoryShowing.map((item, index) => {
      return (
        <div key={index}>
          <StyledPageTitleContainer style={{ display: 'flex' }} margin={'0px'} id={item.id}>
            <MainPageTitle fontSize={16} text={item?.name} />
          </StyledPageTitleContainer>
          <div style={{ display: 'flex', width: '100%', flexFlow: 'wrap' }}>
            {contentVideos.map((video, index) => {
              if (item.id === video.fields.category?.sys.id) {
                return (
                  <VideoCardContainer key={`videocontainer-${index}`} onClick={() => pushToPlayer(video.sys.id)}>
                    <iframe
                      key={`videocontainer-iframe-${index}`}
                      id="video"
                      width="150"
                      height="99"
                      sandbox="allow-scripts allow-popups allow-forms allow-top-navigation allow-same-origin"
                      src={video.fields.link}
                    ></iframe>
                    <div>{video.fields.title}</div>
                  </VideoCardContainer>
                );
              }
            })}
            {contentVideos.filter((video, index) => item.id === video.fields.category?.sys.id).length === 0 && t('channel.noVideos')}
          </div>
        </div>
      );
    });
  }, [categoryShowing]);

  return (
    <>
      <StyledTablesContainer>
        <FavoritesContainerStyled>
          <StyledPageTitleContainer style={{ display: 'flex' }} margin={'0px'} id={'top'}>
            <MainPageTitle fontSize={16} text={'CATEGORIAS'} />
          </StyledPageTitleContainer>
          <StyledCardsContainer>
            {[...categories, { name: t('agricultural.all') }].map((card, index) => (
              <StyledCard
                key={`document-category-${index}`}
                isActive={card.name === selectedFilterCard}
                onClick={() => {
                  setSelectedFilterCard(card.name);
                }}
              >
                <StyledText>{card.name}</StyledText>
              </StyledCard>
            ))}
          </StyledCardsContainer>
        </FavoritesContainerStyled>

        <div>{renderCategoryShowing()}</div>
      </StyledTablesContainer>
    </>
  );
};

export default withTranslation()(Videos);
