import { AxiosResponse, CancelToken } from 'axios';
import { apiCore } from './api';
import { IAdmission, IFinancialMoviment, IFutureCredits, IFutureDebts, IInfoAccount, ITaxes } from 'model/financial-abstract';
import { encryptReport } from 'shared/util/api-utils';

export const FinanciaAbstractApi = () => {
  const getAdmission = (register: string): Promise<AxiosResponse<IAdmission>> => {
    return apiCore.get<IAdmission>(`/PosicaoFinanceiraSintetica/DadosIniciais?CD_PESSOA=${register}`);
  };

  const getInterestRates = (): Promise<AxiosResponse<ITaxes[]>> => {
    return apiCore.get<ITaxes[]>(`/PosicaoFinanceiraSintetica/TaxasJuros`);
  };

  const getAccountInfo = (register: string, date: string, token: CancelToken): Promise<AxiosResponse<IInfoAccount>> => {
    return apiCore.get<IInfoAccount>(`/PosicaoFinanceiraSintetica/SaldoContaFinanceira?p_nr_matricula=${register}&p_dt_saldo=${date}`, {
      cancelToken: token,
    });
  };

  const getFutureCredits = (register: string, date: string): Promise<AxiosResponse<IFutureCredits>> => {
    return apiCore.get<IFutureCredits>(`/PosicaoFinanceiraSintetica/ExtratoCreditosFuturos?p_nr_matricula=${register}&p_dt_limite=${date}`);
  };

  const getFutureDebts = (register: string, date: string): Promise<AxiosResponse<IFutureDebts>> => {
    return apiCore.get<IFutureDebts>(`/PosicaoFinanceiraSintetica/ExtratoDebitosFuturos?p_nr_matricula=${register}&p_dt_limite=${date}`);
  };

  const getFinancialMoviment = (
    register: string,
    initialDate: string,
    finalDate: string,
    token: CancelToken
  ): Promise<AxiosResponse<IFinancialMoviment>> => {
    return apiCore.get<IFinancialMoviment>(
      `/PosicaoFinanceiraSintetica/MovimentacaoFinanceira?p_nr_matricula=${register}&p_dt_inicial=${initialDate}&p_dt_final=${finalDate}`,
      { cancelToken: token }
    );
  };

  const getToPayReport = (params: string, register: string, startDate: string, finalDate: string) => {
    let path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0122R&p_cd_pessoa=${register}&p_dt_base=${startDate}&p_dt_base_final=${finalDate}${params}`;

    return encryptReport(path);
  };

  const getToReceiveReport = (params: string, register: string, startDate: string, finalDate: string) => {
    let path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0123R&p_cd_pessoa=${register}&p_dt_base=${startDate}&p_dt_base_final=${finalDate}${params}`;

    return encryptReport(path);
  };

  const getReport = (params: string, register: string, startDate: string, finalDate: string) => {
    let path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0124R&p_cd_pessoa=${register}&p_dt_base=${startDate}&p_dt_base_final=${finalDate}${params}`;

    return encryptReport(path);
  };

  const getPastReport = (params: string, register: string, startDate: string, finalDate: string) => {
    let path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0125R&p_cd_pessoa=${register}&p_dt_base=${startDate}&p_dt_base_final=${finalDate}${params}`;

    return encryptReport(path);
  };

  return {
    getInterestRates,
    getAccountInfo,
    getAdmission,
    getFutureCredits,
    getFutureDebts,
    getFinancialMoviment,
    getToPayReport,
    getToReceiveReport,
    getReport,
    getPastReport,
  };
};

export default FinanciaAbstractApi();
