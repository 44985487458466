import authenticationApi from 'api/authentication-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';

export const authenticationService = () => {
  const checkPageAcess = async (register: string, path: string) => {
    try {
      const result: AxiosResponse<null> = await authenticationApi.checkPageAcess(register, path);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve({ Message: 'ok' });
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };
  return {
    checkPageAcess,
  };
};

export default authenticationService();
