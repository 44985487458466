import ITypeIconsWithLinks from 'model/type-icons-with-links';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const SmallLinkCard = ({ icon, text, pathRedirected }: ITypeIconsWithLinks) => {
  const history = useHistory();

  return (
    <StyledCard onClick={() => history.push(pathRedirected)}>
      {icon}
      <StyledText>{text}</StyledText>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  width: 104px;
  height: 104px;
  padding: 14px;
  background-color: #fcfcfc;
  font-family: ${({ theme }) => theme.font.arialBold};
  color: #183c96;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0px 5px 10px #1d1d1d33;
  font-size: 11px;

  svg {
    max-height: 30px;
    max-width: 30px;
  }
`;

const StyledText = styled.div`
  max-width: 119px;
  margin-top: 17px;
  margin: 11px auto 0 auto;
  line-height: 1;
  font-size: 11px;
`;

export default SmallLinkCard;
