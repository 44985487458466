import fundacaoApi, { fundacaoResponse } from 'api/fundation-api';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';

export const fundacaoService = () => {
  const getToken = async (register: string): Promise<fundacaoResponse> => {
    try {
      const result: AxiosResponse<fundacaoResponse> = await fundacaoApi.getToken(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  return {
    getToken,
  };
};

export default fundacaoService();
