import TextField from '@mui/material/TextField';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import { AccountType } from 'model/account';
import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import SearchAccountService from 'services/search-account-service';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/CurrencyInput';
import GenericInputTextAreaToTable from 'components/GenericInputTextAreaToTable';
import InputDateWithSetting from 'components/InputDateWithSetting';
import moment from 'moment';
import RadioButton from 'components/RadioButton';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import FinancialService from 'services/financial-service';
import { ReactComponent as LeftIconSvg } from '../../images/frisia/icons/arrow-left.svg';
import { ReactComponent as RightIconSvg } from '../../images/frisia/icons/arrow-right.svg';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';
import { ITranseferHistorybyRegister, TranseferHistory } from 'model/financial';

interface IForm {
  transferValue: string;
  finality: string;
}

interface historyListType {
  [key: string]: any;
}

const TransferByRegister = () => {
  const { t } = useTranslation();
  const [register, setRegister] = useState('');
  const [name, setName] = useState('');
  const [selected, setSelected] = useState<AccountType>();
  const [value, setValue] = useState<string>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [date, setDate] = useState<Date | null>(tomorrow);
  const [schedule, setSchedule] = useState<boolean>(false);
  const [historyList, setHistoryList] = useState<historyListType[]>([]);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [paginatedHistoryList, setPaginatedHistoryList] = useState<historyListType[]>([]);
  const [chosenForCancel, setChosenForCancel] = useState<ITranseferHistorybyRegister>();
  const [openDeletTransferModal, setOpenDeletTransferModal] = useState<boolean>(false);
  const [APIResponse, setAPIResponse] = useState<string>();
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getUserName = useSelector<IRootState, string>(state => {
    return state.authentication.coreInfo?.nm_usuario;
  });

  const [accounts, setAccounts] = useState<AccountType[]>([]);

  const searchAccount = async () => {
    if (name !== '') {
      const response = await SearchAccountService.byName(name);
      setAccounts(response);
    }
    if (register !== '') {
      const response = await SearchAccountService.byRegister(register);
      setAccounts(response);
    }
  };

  useEffect(() => {
    if (selected) {
      setOpenModal(false);
    }
  }, [selected]);

  useEffect(() => {
    if (accounts.length > 0) {
      setOpenModal(true);
    }
  }, [accounts]);

  const schema = yup.object({
    transferValue: yup
      .string()
      .default(value)
      .test('test-value', t('global.errorMessage.required'), () => {
        if (value != null) {
          return (
            parseFloat(
              value
                .replaceAll('.', '')
                .replaceAll(',', '.')
                .replace(/[^0-9.-]+/g, '')
            ) > 0
          );
        } else {
          return false;
        }
      }),
    finality: yup.string(),
    register: yup.string().default(getUserRegistration.toString()),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  const handleSubmit = (formData: IForm) => {
    if (value && openTransferModal) {
      setDisableButton(true);
      FinancialService.makeTransfer({
        Data: moment().format('YYYY-MM-DD HH:mm:ss'),
        Origem: 'Extranet',
        Matricula: getUserRegistration,
        Cooperado: selected?.CD_PESSOA,
        Nome: selected?.NM_PESSOA,
        Valor: parseFloat(
          formData.transferValue
            .replaceAll('.', '')
            .replaceAll(',', '.')
            .replace(/[^0-9.-]+/g, '')
        ),
        Deposito: schedule && date !== null ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
        Status: 'Aguardando análise',
        Usuario: getUserName,
        Finalidade: formData.finality,
      })
        .then(response => {
          setAPIResponse(response.Message);
        })
        .catch(response => setAPIResponse(response.data.Message))
        .finally(() => {
          setDisableButton(false);
        });
    } else {
      setOpenTransferModal(true);
    }
  };

  useEffect(() => {
    if (date != null) {
      let mutableDate = new Date(date);
      let today = new Date();
      if (mutableDate.getTime() < today.getTime()) {
        setDate(tomorrow);
      }
    }
  }, [date]);

  const tableHeaderMachinesAndEquipment = [
    t('financial.Requested'),
    t('financial.Ident.'),
    t('financial.destiny'),
    t('financial.Purpose'),
    t('financial.depositDate'),
    t('financial.Amount'),
    t('financial.Situation'),
    t('financial.Origin'),
    t('financial.Protocol'),
  ];

  const getHistory = async () => {
    const result = await FinancialService.getTransferHistoryBetweenRegisters(getUserRegistration.toString());
    setHistoryList(
      result.map(item => {
        return {
          [t('financial.Requested')]: moment(item.Data, 'DD-MM-YYYY kk:mm').format('DD/MM/YY kk:mm'),
          [t('financial.Ident.')]: <div style={{ minWidth: '46px' }}>{item.Id}</div>,
          [t('financial.destiny')]: `${item.Cooperado}`,
          [t('financial.Purpose')]: item.Finalidade,
          [t('financial.depositDate')]: moment(item.Deposito, 'DD-MM-YYYY').format('DD/MM/YY'),
          [t('financial.Amount')]: item.Valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
          [t('financial.Situation')]: item.Status?.includes('análise') ? (
            <div style={{ margin: '6px 0px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {t('financial.underAnalysis')}
              <StyledButton
                style={{ padding: '8px', lineHeight: '11px' }}
                color="orange"
                type="button"
                onClick={() => {
                  setChosenForCancel(item);
                  setOpenDeletTransferModal(true);
                }}
              >
                <span>{t('global.button.cancel')}</span>
              </StyledButton>
            </div>
          ) : (
            item.Status
          ),
          [t('financial.Origin')]: item.Origem,
          [t('financial.Protocol')]: <div style={{ minWidth: '46px' }}>{item.Protocolo}</div>,
        };
      })
    );
  };

  useEffect(() => {
    if (showHistory) {
      getHistory();
    }
  }, [showHistory]);

  const paginate = array => {
    setPaginatedHistoryList(array.slice((pageNumber - 1) * 10, pageNumber * 10));
  };

  useEffect(() => {
    paginate(historyList);
    setTotalPages(Math.ceil(historyList.length / 10));
  }, [pageNumber, historyList]);

  const cancelTransfer = async () => {
    if (chosenForCancel) {
      const result = await FinancialService.cancelTransferRegister(getUserRegistration.toString(), chosenForCancel?.Id.toString());
      if (result) {
        setOpenDeletTransferModal(false);
        getHistory();
      }
    }
  };

  return (
    <PageContainer>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('card.transferBetweenEnrollments')} />
      </StyledPageTitleContainer>
      <StyledButton
        onClick={() => setShowHistory(!showHistory)}
        style={{ height: '42px', position: 'relative', marginBottom: '10px' }}
        color="orange"
        type="button"
      >
        <span>{t('agricultural.history')}</span>
      </StyledButton>
      {paginatedHistoryList.length > 0 && showHistory && (
        <>
          <GenericTableBlueHeader
            headerItems={tableHeaderMachinesAndEquipment}
            tableItems={paginatedHistoryList}
            dataColor="#787878"
            isInvertLineColor
            isDontHaveMaximumWidth
            style={{ fontSize: '12px !important' }}
          />
          <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
            <LeftIconSvg
              style={{ cursor: 'pointer', margin: '8px' }}
              onClick={() => {
                if (pageNumber > 1) {
                  setPageNumber(pageNumber - 1);
                }
              }}
            />
            <div>
              {pageNumber}/{totalPages}
            </div>
            <RightIconSvg
              style={{ cursor: 'pointer', margin: '8px' }}
              onClick={() => {
                if (pageNumber < historyList.length / 10) {
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          </div>
        </>
      )}
      {openDeletTransferModal && (
        <ModalContainer>
          <ModalShadow onClick={() => setOpenDeletTransferModal(false)} />
          <CardContainer>
            <SubtitleStyled>{`${t('Cancelar transferência')} ${chosenForCancel?.Id}?`}</SubtitleStyled>
            <SubtitleStyled>{t('financial.valueOf')}:</SubtitleStyled>
            R$ {chosenForCancel?.Valor}
            <SubtitleStyled>{t('financial.destiny')}:</SubtitleStyled>
            {chosenForCancel && `${chosenForCancel?.Cooperado} - ${chosenForCancel?.Nome}`}
            <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
              <CancelButton
                onClick={() => {
                  setOpenDeletTransferModal(false);
                }}
                style={{ width: '145px', height: '40px' }}
              >
                {t('financial.no')}
              </CancelButton>
              <StyledButton
                color="frisiaPrimary"
                onClick={() => {
                  cancelTransfer();
                }}
                type="button"
                style={{ color: 'white', minWidth: '145px', height: '42px' }}
              >
                <span>{t('financial.yes')}</span>
              </StyledButton>
            </div>
          </CardContainer>
        </ModalContainer>
      )}

      {!showHistory && (
        <FiltersContainer>
          <span>{t('financial.ChoseCooper')}</span>
          <InputsContainer>
            <StyledInputText
              label={t('login.registration')}
              onChange={e => setRegister(e.target.value)}
              placeholder={t('support.placeholder1')}
              variant="outlined"
              type={'text'}
            />
            <StyledInputText
              style={{ width: '350px' }}
              label={t('livestock.name')}
              placeholder={t('support.placeholder2')}
              onChange={e => {
                setName(e.target.value);
              }}
              variant="outlined"
              type={'text'}
            />
            <StyledButton
              color="frisiaPrimary"
              onClick={() => {
                searchAccount();
              }}
              style={{ width: '172px', alignSelf: 'center' }}
            >
              <span>{t('support.search')}</span>
            </StyledButton>
          </InputsContainer>
        </FiltersContainer>
      )}
      {openModal && (
        <ModalContainer>
          <ModalShadow onClick={() => setOpenModal(false)} />
          <CardContainer>
            <b>{t('financial.chose')}</b>
            <div style={{ height: '2px', width: '100%', backgroundColor: 'yellow' }} />
            {accounts.map((item, index) => (
              <StyledRow key={index}>
                <div>
                  <div>
                    <span>{t('support.register')}: </span>
                    {item.CD_PESSOA}{' '}
                  </div>
                  <div>
                    <span>{t('support.name')}: </span>
                    {item.NM_PESSOA}{' '}
                  </div>
                </div>
                <div>
                  <div>
                    <span>{t('support.cpf')}: </span>
                    {item.CD_CPF}{' '}
                  </div>
                  <div>
                    <span>{t('support.situation')}: </span>
                    {item.CD_SITUACAO_PESSOA}{' '}
                  </div>
                </div>
                {(item.CD_SITUACAO_PESSOA === 'Ativo' || item.CD_SITUACAO_PESSOA === 'A') && (
                  <StyledButton
                    color="frisiaPrimary"
                    onClick={() => {
                      setSelected(item);
                    }}
                    style={{ color: 'white', width: '172px', alignSelf: 'center', marginTop: '10px', height: '53px' }}
                  >
                    <span>{selected === item ? t('global.button.selected') : t('global.button.select')}</span>
                  </StyledButton>
                )}
              </StyledRow>
            ))}
          </CardContainer>
        </ModalContainer>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {openTransferModal && (
            <ModalContainer>
              <ModalShadow onClick={() => setOpenTransferModal(false)} />
              <CardContainer>
                {APIResponse ? (
                  <div style={{ margin: '20px' }}>
                    <div> {APIResponse}</div>
                    <StyledButton
                      color="frisiaPrimary"
                      onClick={() => {
                        setAPIResponse(undefined);
                        setOpenTransferModal(false);
                      }}
                      type="button"
                      style={{ color: 'white', minWidth: '145px', height: '42px', marginTop: '20px' }}
                    >
                      <span>{t('global.button.ok')}</span>
                    </StyledButton>
                  </div>
                ) : (
                  <div style={{ padding: '10px' }}>
                    <SubtitleStyled>{t('financial.valueOf')}:</SubtitleStyled>
                    {value}
                    <SubtitleStyled>{t('financial.destiny')}:</SubtitleStyled>
                    {selected?.CD_PESSOA}
                    {' - '}
                    {selected?.NM_PESSOA}
                    <SubtitleStyled>{t('financial.in')}:</SubtitleStyled>
                    {schedule && date !== null ? moment(date).format('DD/MM/YY') : moment().format('DD/MM/YY')}
                    <SubtitleStyled>{t('financial.confirmSolicitation')}</SubtitleStyled>
                    <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                      <CancelButton
                        onClick={() => {
                          setOpenTransferModal(false);
                        }}
                        style={{ width: '145px', height: '40px' }}
                      >
                        {t('financial.cancel')}
                      </CancelButton>
                      <StyledButton
                        color="frisiaPrimary"
                        onClick={() => {
                          setOpenTransferModal(true);
                        }}
                        type="submit"
                        disabled={disableButton}
                        style={{ color: 'white', minWidth: '145px', height: '42px' }}
                      >
                        <span>{t('financial.confirm')}</span>
                      </StyledButton>
                    </div>
                  </div>
                )}
              </CardContainer>
            </ModalContainer>
          )}
          {selected && (
            <div>
              <div>
                <SubtitleStyled>{t('financial.newTransfer')}</SubtitleStyled>
                <MyStyledInput>
                  <CurrencyInput placeholder="R$ 0,00" onChange={e => setValue(e.target.value)} />
                </MyStyledInput>
                <StyledErrorMessage style={{ position: 'initial' }}>{methods.formState.errors.transferValue?.message}</StyledErrorMessage>
              </div>
              <div>
                <SubtitleStyled>{t('financial.favored')}:</SubtitleStyled>
                <SelectedContainer>
                  {selected.CD_PESSOA}
                  {' - '}
                  {selected.NM_PESSOA}
                </SelectedContainer>
              </div>
              <div style={{ margin: '15px 0px' }}>
                <SubtitleStyled>{t('financial.transferDate')}</SubtitleStyled>
                <StyledContainer>
                  <RadioButton
                    isDefaultChecked={!schedule}
                    name="orderType"
                    value={String(t('financial.transferToday'))}
                    id="bulk"
                    handleClick={() => setSchedule(false)}
                    style={{ display: 'inline-flex', marginRight: '40px', marginBottom: '0' }}
                  />
                  <RadioButton
                    name="orderType"
                    value={String(t('financial.schedule'))}
                    id="bagged"
                    handleClick={() => setSchedule(true)}
                    style={{ display: 'inline-flex', marginBottom: '0' }}
                  />
                </StyledContainer>
                {schedule && <InputDateWithSetting date={date} setDate={setDate} disableWeekends label={t('dashboard.filterDate')} />}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '225px',
                  justifyContent: 'space-around',
                }}
              >
                <SubtitleStyled>{t('financial.finalityDestination')}</SubtitleStyled>
                <GenericInputTextAreaToTable inputName="finality" label={''} placeholder={t('livestock.addObservation')} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <StyledButton
                  color="frisiaPrimary"
                  style={{ color: 'white', minWidth: '270px', alignSelf: 'center', margin: '10px', height: '53px' }}
                  type="submit"
                >
                  <span>{t('financial.transferConclude')}</span>
                </StyledButton>
                <CancelButton
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  {t('financial.transferCancel')}
                </CancelButton>
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </PageContainer>
  );
};

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 65px;
  margin: 30px 0px;
  margin-bottom: 42px;
  justify-content: space-between;
  div {
    width: fit-content;
    font-weight: bold;
    color: #404040;
  }
  div > span {
    color: #2a5299;
    font-weight: initial;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  justify-content: space-between;
  span {
    margin-bottom: 10px;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  max-width: 830px;
  margin-bottom: 15px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  min-width: 445px;
  max-height: 85vh;
  max-width: 85vw;
  width: 100%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  overflow-y: auto;
  b {
    align-self: self-start;
    margin: 20px 0px;
  }
`;

const StyledInputText = styled(TextField)`
  div {
    font-family: ${({ theme }) => theme.font.montserratRegular};
    height: 53px;
    min-width: 200px;
    fieldset {
      border: 2px solid #4e6ba7 !important;
    }
  }
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary} !important;
    letter-spacing: 0.4px;
    font-size: 13.2px;
    line-height: 2.2em;
    padding-left: 7px;
  }
  input {
    font-size: 16px;
    color: #656565;
    &::placeholder {
      opacity: 1;
    }
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
`;

export const ModalItems = styled.div`
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 278px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
`;

const SubtitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const SelectedContainer = styled.div`
  background-color: #a3e5f1;
  font-size: 21px;
  padding: 10px;
  width: fit-content;
  margin: 10px;
  border-radius: 10px;
`;

const MyStyledInput = styled.div`
  input {
    text-align: left;
    font-size: 48px;
    letter-spacing: 0px;
    color: #2a5299;
    opacity: 1;
    text-align: center;
    border: 1px #8080806b solid;
    border-radius: 10px;
  }
`;

const CancelButton = styled.button`
  width: 245px;
  height: 34px;
  font-size: 14px;
  letter-spacing: 0.02px;
  color: #d4a518;
  text-transform: uppercase;
  opacity: 1;
  border: #d4a518 2px solid;
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  cursor: pointer;
`;

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export default withTranslation()(TransferByRegister);
