import { AxiosResponse } from 'axios';
import IMyAnimal from 'model/my-animal';
import Mocker from '../mock-api';
import myAnimalDetailsJson from '../mocks/myAnimalDetails.json';
import myAnimalsJson from '../mocks/myAnimals.json';

export const mySquadApi = () => {
  const getMyAnimals = (): Promise<AxiosResponse<IMyAnimal[]>> => {
    return new Mocker<IMyAnimal[]>().mock(myAnimalsJson as unknown as string);
  };

  const getAnimalDetails = (animalId: number): Promise<AxiosResponse<IMyAnimal>> => {
    return new Mocker<IMyAnimal>().mock(myAnimalDetailsJson as unknown as string);
  };

  return {
    getMyAnimals,
    getAnimalDetails,
  };
};

export default mySquadApi();
