import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowDownSvg } from '../images/frisia/icons/yellow-icon-arrow-down.svg';

interface IProps {
  placeholder?: string;
  listShowDropdown: any[];
  keyToShowInDropdown?: string;
  getSelectedValue: (value: any) => any;
}

interface IStyledContainerProps {
  isShowDropdown: boolean;
}

const WhiteDropdownFilter = ({ placeholder, listShowDropdown, keyToShowInDropdown, getSelectedValue }: IProps) => {
  const [selectedItem, setSelectedItem] = useState<string>(placeholder ?? '');
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);

  useEffect(() => {
    if (selectedItem != null && selectedItem !== placeholder) {
      getSelectedValue(selectedItem);
    }
  }, [selectedItem]);

  return (
    <StyledContainer>
      <StyledDiv
        onClick={() => setIsShowDropdown(!isShowDropdown)}
        onBlur={() => setIsShowDropdown(false)}
        isShowDropdown={isShowDropdown}
        tabIndex={0}
      >
        <StyledInnerContainer>
          <div>
            <StyledText className={`${selectedItem !== placeholder ? '' : 'style-placeholder'}`}>
              {keyToShowInDropdown != null && selectedItem !== placeholder ? selectedItem[keyToShowInDropdown] : selectedItem}
            </StyledText>
          </div>

          <StyledIconContainer className={`${isShowDropdown ? 'rotate' : ''}`}>
            <ArrowDownSvg />
          </StyledIconContainer>
        </StyledInnerContainer>

        {isShowDropdown && (
          <StyledList>
            {listShowDropdown.map((item, index) => (
              <StyledItem key={`${item}-${index}`} onClick={() => setSelectedItem(item)}>
                {keyToShowInDropdown != null ? item[keyToShowInDropdown] : item}
              </StyledItem>
            ))}
          </StyledList>
        )}
      </StyledDiv>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  height: 40px;
`;

const StyledDiv = styled.div<IStyledContainerProps>`
  position: absolute;
  min-height: 40px;
  max-height: 140px;
  width: 100%;
  text-align: left;
  box-shadow: 0px 3px 6px #00000012;
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  z-index: ${({ isShowDropdown }) => (isShowDropdown ? 1 : 0)};
`;

const StyledInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 7px 10px 0 10px;
`;

const StyledText = styled.span`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 12px;

  &.style-placeholder {
    font-size: 12px;
    opacity: 0.5;
  }
`;

const StyledIconContainer = styled.div`
  @keyframes arrow_animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  &.rotate {
    animation: arrow_animation 0.3s 0s;
    transform: rotate(180deg);
  }
`;

const StyledList = styled.ul`
  @keyframes list_animation {
    from {
      height: 0;
    }
    to {
      height: 95px;
    }
  }

  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 12px;
  list-style-type: none;
  padding: 0;
  overflow: auto;
  background-color: white;

  li:nth-child(2n) {
    background-color: #f3f3f3;

    &:hover {
      background-color: #faf9e4;
    }
  }

  animation: list_animation 0.4s 0s;
  height: 95px;
`;

const StyledItem = styled.li`
  padding: 3px 0;
  padding-left: 10px;

  &:hover {
    background-color: #faf9e4;
  }
`;

export default WhiteDropdownFilter;
