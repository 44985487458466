import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import { IPlantelGroup } from 'model/registration-form';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import registrationFormService from 'services/registration-form-service';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const Squad = ({ t }: WithTranslation) => {
  const [plantelData, setPlantelData] = useState<IPlantelGroup[]>([]);
  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getPlantelGroup = async () => {
    const result = await registrationFormService.getPlantelGroup(getUserRegistration);
    setPlantelData(result);
  };

  useEffect(() => {
    getPlantelGroup();
  }, []);

  const tableHeader = [
    t('registrationForm.animalType'),
    t('registrationForm.quantity'),
    t('registrationForm.grade'),
    t('registrationForm.color'),
    t('registrationForm.finality'),
    t('registrationForm.medianAge'),
    t('registrationForm.value'),
    t('registrationForm.lien'),
    t('registrationForm.owner'),
    t('registrationForm.localization'),
  ];

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('registrationForm.squad')} />
      </StyledPageTitleContainer>
      <LoadingContainerScreen isLoading={plantelData.length == 0}>
        {plantelData.length > 0 && (
          <>
            <GenericTableBlueHeader
              headerItems={tableHeader}
              tableItems={plantelData.map(item => {
                return {
                  animalType: item.DS_TIPO_ANIMAL,
                  quantity: item.QT_PLANTEL,
                  grade: item.DS_GRAU_MESTICAGEM,
                  color: item.DS_COR_PREDOMINANTE,
                  finality: item.DS_FINALIDADE,
                  medianAge: item.DS_IDADE_MEDIA,
                  value: item.VL_ANIMAL,
                  lien: item.IE_GRAVAME,
                  owner: item.PC_PROPRIETARIO,
                  localization: item.NM_PROPRIEDADE_PLANTEL,
                };
              })}
              dataColor="#787878"
              isInvertLineColor
              isDontHaveMaximumWidth
            />
          </>
        )}
      </LoadingContainerScreen>
    </>
  );
};

export default withTranslation()(Squad);
