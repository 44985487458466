import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { StyledButton } from 'components/styled-button/StyledButton';
import redX from 'images/frisia/icons/red-x-icon.svg';
import greenCheck from 'images/frisia/icons/green-Icon-check.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Background, LoginContainer, LogoEye, LogoImage, Message, Message2, MessageContainer, WhiteContainer } from './StyledComponents';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const NewPassword = () => {
  const { t } = useTranslation();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [values, setValues] = useState({
    confirmPassword: true,
    minCharacters: false,
    capitalLetter: false,
    symbol: false,
    lettersAndNumbers: false,
  });

  const schema = yup.object({
    password: yup.number().required(t('global.errorMessage.required')).min(8).max(8),
    confirmPassword: yup.number().required(t('global.errorMessage.required')).min(8).max(8),
  });

  //   TODO when api send newpassword
  const onSubmit = (data: any) => {
    console.log(data);
  };
  const sendNewPassword = () => {
    if (Object.values(values).every(value => value === true)) {
      console.log(password);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setValues({
      ...values,
      confirmPassword: confirmPassword !== '' && confirmPassword !== password ? false : true,
      minCharacters: password?.length < 8 ? false : true,
      capitalLetter: /[A-Z]/.test(password),
      symbol: /[^a-zA-Z0-9\-\/]/.test(password),
      lettersAndNumbers: /[0-9]/.test(password),
    });
  }, [password, confirmPassword]);

  const methods = useForm({ resolver: yupResolver(schema), mode: 'all' });

  const handleSubmit = data => {
    methods.handleSubmit(onSubmit)(data);
  };

  return (
    <Background>
      <LoginContainer>
        <FormProvider {...methods}>
          <form onSubmit={e => handleSubmit(e)} id="myform">
            <WhiteContainer>
              <LogoImage />
              <div style={{ marginTop: '55px' }} />
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">{t('login.password')}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={e => setPassword(e.target.value)}
                  required
                  label={t('login.password')}
                  name="password"
                  inputProps={{
                    disableUnderline: true,
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <LogoEye onClick={handleClickShowPassword} className="icon-eye" />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div style={{ marginTop: '15px' }} />
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-conform-password">{t('login.confirmPassword')}</InputLabel>
                <OutlinedInput
                  id="outlined-conform-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={e => setConfirmPassword(e.target.value)}
                  label={t('login.confirmPassword')}
                  name="confirmPassword"
                  endAdornment={
                    <InputAdornment position="end">
                      <LogoEye onClick={handleClickShowPassword} className="icon-eye" />
                    </InputAdornment>
                  }
                />
                <span style={{ color: 'red', marginTop: '8px', fontSize: '15px', alignSelf: 'center' }}>{t('login.error1')}</span>
                {!values.confirmPassword && (
                  <span style={{ color: 'red', marginTop: '8px', fontSize: '15px', alignSelf: 'center' }}>{t('login.error2')}</span>
                )}
                <div style={{ marginTop: '55px' }} />
                <StyledButton
                  color="frisiaPrimary"
                  type="submit"
                  onClick={sendNewPassword}
                  form="myform"
                  style={{ minWidth: '270px', alignSelf: 'center' }}
                >
                  <span>{t('global.button.savePassword')}</span>
                </StyledButton>
              </FormControl>
            </WhiteContainer>
          </form>
        </FormProvider>
        <MessageContainer>
          <Message>{t('login.safeFirst')}</Message>
          <Message2>{t('login.shouldHave')}</Message2>
          <div style={{ marginTop: '15px' }} />
          <div style={{ display: 'flex' }}>
            {values.minCharacters ? (
              <>
                <Message2>{t('login.8caracters')} </Message2>
                <img style={{ width: '24px', height: '17px' }} src={greenCheck} />
              </>
            ) : (
              <>
                <Message2 style={{ fontWeight: 'bolder' }}>{t('login.8caracters')} </Message2>
                <img style={{ width: '24px', height: '17px' }} src={redX} />
              </>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            {values.capitalLetter ? (
              <>
                <Message2>{t('login.capitalLetter')}</Message2>
                <img style={{ width: '24px', height: '17px' }} src={greenCheck} />
              </>
            ) : (
              <>
                <Message2 style={{ fontWeight: 'bolder' }}>{t('login.capitalLetter')}</Message2>
                <img style={{ width: '24px', height: '17px' }} src={redX} />
              </>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            {values.symbol ? (
              <>
                <Message2>{t('login.symbol')}</Message2>
                <img style={{ width: '24px', height: '17px' }} src={greenCheck} />
              </>
            ) : (
              <>
                <Message2 style={{ fontWeight: 'bolder' }}>{t('login.symbol')}</Message2>
                <img style={{ width: '24px', height: '17px' }} src={redX} />
              </>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            {values.lettersAndNumbers ? (
              <>
                <Message2>{t('login.lettersAndNumbers')}</Message2>
                <img style={{ width: '24px', height: '17px' }} src={greenCheck} />
              </>
            ) : (
              <>
                <Message2 style={{ fontWeight: 'bolder' }}>{t('login.lettersAndNumbers')}</Message2>
                <img style={{ width: '24px', height: '17px' }} src={redX} />
              </>
            )}
          </div>
        </MessageContainer>
      </LoginContainer>
    </Background>
  );
};

export default NewPassword;
