import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { authenticationReducer } from './authentication/reducer';
import authenticationSaga from './authentication/sagas';
import { AuthenticationState } from './authentication/types';
import { globalModalReducer } from './globalModal/reducer';
import { GlobalModalState } from './globalModal/types';
import { registerReducer } from './register/reducer';
import registerSaga from './register/sagas';
import { RegisterState } from './register/types';
import { searchReducer } from './search/reducer';
import { SearchState } from './search/types';

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly register: RegisterState;
  readonly search: SearchState;
  readonly globalModal: GlobalModalState;
}

const rootReducer = combineReducers<IRootState>({
  authentication: authenticationReducer,
  register: registerReducer,
  search: searchReducer,
  globalModal: globalModalReducer,
});

export function* rootSaga() {
  yield all([fork(authenticationSaga), fork(registerSaga)]);
}

export default rootReducer;
