import { ITypeTable } from 'model/type-table';
import { useState } from 'react';
import styled from 'styled-components';
import { StyledTable } from 'styled-components/StyledForTables';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/blue-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/blue-plus-round-icon.svg';

interface IProps extends ITypeTable {
  analysisDate: string;
}

const CollapsibleTableLoadLoad = ({ analysisDate, headerItems, tableItems, isInvertLineColor, isDontHaveMaximumWidth, style }: IProps) => {
  const getValues = tableItems.map(row => Object.values(row));
  const [isShowTableItems, setIsShowTableItems] = useState<boolean>(false);

  return (
    <StyledTable isInvertLineColor={isInvertLineColor} isDontHaveMaximumWidth={isDontHaveMaximumWidth} style={style}>
      <thead>
        <StyledTableRowHeader onClick={() => setIsShowTableItems(!isShowTableItems)}>
          {headerItems?.map((headerItem, index) => (
            <th key={`header-item-${index}`}>
              {index === 0 && (isShowTableItems ? <MinusRoundIconSvg /> : <PlusRoundIconSvg />)}
              <StyledTextHeader className={`${index === 0 ? 'firstCell' : ''}`}>{headerItem}</StyledTextHeader>
            </th>
          ))}
        </StyledTableRowHeader>
      </thead>

      <tbody>
        {isShowTableItems && (
          <>
            <StyledTableRow>
              <StyledTableData>{analysisDate}</StyledTableData>
            </StyledTableRow>
            {getValues.map((tableRow, indexRow) => (
              <StyledTableRow key={`row-${indexRow}`}>
                {tableRow.map((tableItem, indexItem) => (
                  <td key={`item-${indexItem}`}>{tableItem}</td>
                ))}
              </StyledTableRow>
            ))}
          </>
        )}
      </tbody>
    </StyledTable>
  );
};

const StyledTableRowHeader = styled.tr`
  height: 40px;
  color: #4c70aa;
  cursor: pointer;

  th {
    background-color: #e6eaf2;
  }

  th:nth-child(1) {
    display: flex;
    align-items: center;

    height: 40px;
    text-align: left;
    padding-left: 9px;
    border-radius: 16px 0 0 16px;
  }

  th:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    border-radius: 0 16px 16px 0;
  }
  td {
    letter-spacing: var(--unnamed-character-spacing-0-02);
    color: var(--unnamed-color-656565);
    text-align: left;
    font-family: ${({ theme }) => theme.font.montserratRegular} !important;
    letter-spacing: 0.02px;
    color: #656565 !important;
    text-transform: capitalize;
    opacity: 1;
  }
`;

export const StyledTableRow = styled.tr`
  height: 40px;
  color: #4e6ba7;

  td {
    text-align: left;
    padding-left: 19px;
  }

  td:nth-child(2) {
    text-align: center;
    padding-left: 0;
  }

  td:nth-child(1) {
    color: #404040;
    font-family: ${({ theme }) => theme.font.montserratRegular};
    font-size: 18px;
  }

  td:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    color: #404040;
    font-family: ${({ theme }) => theme.font.montserratRegular};
    font-size: 18px;
    font-weight: bold;
  }
`;

const StyledTextHeader = styled.span`
  font-family: ${({ theme }) => theme.font.montserratRegular};

  &.firstCell {
    margin-left: 10px;
    font-family: ${({ theme }) => theme.font.montserratBold} !important;
  }
`;

const StyledTableData = styled.td`
  font-family: ${({ theme }) => theme.font.montserratBold} !important;
  color: ${({ theme }) => theme.color.frisiaPrimaryDark} !important;
  text-align: left !important;
`;
export default CollapsibleTableLoadLoad;
