import rawMaterialScheduleApi from 'api/swine/raw-material-schedule-api';
import ButtonDownload from 'components/ButtonDownload';
import CollapsibleTableLoadLoad from 'components/CollapsibleTableLoadLoad';
import GenericDropdown from 'components/GenericDropdown';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import { ICampaignsType, IRawMaterials } from 'model/material-schedule';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import rawMaterialScheduleService from 'services/swine/raw-material-schedule-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const RawMaterialSchedule = ({ t }: WithTranslation) => {
  const [campaign, setCampaign] = useState<string>();
  const [campaignList, setCampaignList] = useState<ICampaignsType[]>([]);

  const [materials, setMaterials] = useState<IRawMaterials[]>([]);

  const [reportUrl, setReportUrl] = useState<string>();

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getInfo = () => {
    rawMaterialScheduleService
      .getRawMaterials(getUserRegistration, campaignList.find(item => item.NM_CAMPANHA === campaign)?.CD_CAMPANHA ?? 0)
      .then(response => {
        setMaterials(response);
      });
  };

  useEffect(() => {
    rawMaterialScheduleService.getCampaigns(getUserRegistration).then(response => {
      setCampaignList(response);
    });
  }, []);

  useEffect(() => {
    if (campaign) {
      setReportUrl(
        rawMaterialScheduleApi.getReport(getUserRegistration, campaignList.find(item => item.NM_CAMPANHA === campaign)?.CD_CAMPANHA ?? 0)
      );
      getInfo();
    }
  }, [campaign]);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('card.rawMaterialProgramming')} />
      </StyledPageTitleContainer>
      <FiltersContainer>
        <StyledFilterContainer filterWidth={800}>
          <GenericDropdown
            label={t('livestock.campaign')}
            listShowDropdown={campaignList.map(item => item.NM_CAMPANHA)}
            getSelectedValue={value => setCampaign(value)}
          />
        </StyledFilterContainer>
      </FiltersContainer>
      {reportUrl && (
        <>
          <ButtonDownload style={{ marginLeft: 'auto' }} href={reportUrl} />
        </>
      )}
      {materials.map((material, index) => (
        <CollapsibleTableLoadLoad
          key={index}
          headerItems={[`${material.CD_PRODUTO} - ${material.DS_PRODUTO}`, `${t('livestock.coach')}: ${material.NM_TECNICO}`]}
          analysisDate={`${t('livestock.property')}: ${material.NR_PROPRIEDADE} - ${material.NM_PROPRIEDADE}`}
          tableItems={material.PROGRAMACOES.map(item => {
            return { fieldIdentification: moment(item.DT_RETIRADA).format('MMMM/YYYY'), value: `${item.QT_RETIRADA} KG` };
          })}
          isInvertLineColor
          isDontHaveMaximumWidth
          style={{ margin: '25px 0' }}
        />
      ))}
    </>
  );
};

const FiltersContainer = styled.div``;

export default withTranslation()(RawMaterialSchedule);
