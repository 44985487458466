import { apiCore } from 'api/api';
import { AxiosResponse } from 'axios';
import { IBanks, ICostInfo, ICostOptionSend, IGroupsCost, IHarvestCost, IInfoCost } from 'model/agricultural-costs';
import { IReturnMessage } from 'model/financial';
import { sendLog } from 'shared/util/api-utils';

export const costsApi = () => {
  const getCrop = (): Promise<AxiosResponse<IHarvestCost[]>> => {
    sendLog('Consulta safras custeios', `/Custeios/GetSafrasSelecionaveis`, JSON.stringify({}), 'get');
    return apiCore.get<IHarvestCost[]>(`/Custeios/GetSafrasSelecionaveis`);
  };

  const getInfo = (register: string, crop: string): Promise<AxiosResponse<IInfoCost>> => {
    sendLog('Consulta informações de custeio', `/Custeios/GetInfosCGP?CD_PESSOA=${register}&CD_SAFRA=${crop}`, JSON.stringify({}), 'get');
    return apiCore.get<IInfoCost>(`/Custeios/GetInfosCGP?CD_PESSOA=${register}&CD_SAFRA=${crop}`);
  };

  const getGroups = (register: string, crop: string): Promise<AxiosResponse<IGroupsCost[]>> => {
    sendLog('Consultando detalhes custeio', `/Custeios/GetAgrupDetalhes?CD_PESSOA=${register}&CD_SAFRA=${crop}`, JSON.stringify({}), 'get');
    return apiCore.get<IGroupsCost[]>(`/Custeios/GetAgrupDetalhes?CD_PESSOA=${register}&CD_SAFRA=${crop}`);
  };

  const getBanks = (): Promise<AxiosResponse<IBanks[]>> => {
    sendLog('Consultando bancos', `/Custeios/GetBancos`, JSON.stringify({}), 'get');
    return apiCore.get<IBanks[]>(`/Custeios/GetBancos`);
  };

  const getCostInfo = (agroup: string, purpose: string): Promise<AxiosResponse<ICostInfo[]>> => {
    sendLog(
      'Consultando opção de custeio por agrupador',
      `/Custeios/GetOpcaoCusteio?CD_AGRUPADOR=${agroup}&IE_PROPOSITO=${purpose}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ICostInfo[]>(`/Custeios/GetOpcaoCusteio?CD_AGRUPADOR=${agroup}&IE_PROPOSITO=${purpose}`);
  };

  const createCostOption = (costOption: ICostOptionSend): Promise<AxiosResponse<IReturnMessage>> => {
    sendLog('Criando nova opção de custeio', `/Custeios/CriarOpcaoCusteio`, JSON.stringify(costOption), 'post');
    return apiCore.post<IReturnMessage>(`/Custeios/CriarOpcaoCusteio`, costOption);
  };

  const cancelCostOption = (planId: string): Promise<AxiosResponse<IReturnMessage>> => {
    sendLog('Deletando Opção de custeio', `/Custeios/DeleteOpcaoCusteio?ID_PLANEJAMENTO=${planId}`, JSON.stringify({}), 'delete');
    return apiCore.delete<IReturnMessage>(`/Custeios/DeleteOpcaoCusteio?ID_PLANEJAMENTO=${planId}`);
  };

  return {
    getCrop,
    getInfo,
    getGroups,
    getBanks,
    getCostInfo,
    createCostOption,
    cancelCostOption,
  };
};

export default costsApi();
