import milkAnalysisApi from 'api/cattle/milk-analysis-api';
import ButtonDownload from 'components/ButtonDownload';
import CollapsibleGraphic from 'components/CollapsibleGraphic';
import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import { isValid, parse } from 'date-fns';
import { IMilkAnalysis } from 'model/milk-analysis';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import milkAnalysisService from 'services/cattle/milk-analysis-service';
import { dateList } from 'shared/util/date-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const complementDateFilter = '01';

const MilkAnalysis = ({ t }: WithTranslation) => {
  const [milkAnalysis, setMilkAnalysis] = useState<IMilkAnalysis>();
  const [selectedDateFilter, setSelectedDateFilter] = useState<string>('');
  const [reportLink, setReportLink] = useState<string>('');

  useEffect(() => {
    const dateConvertedValidFormat = parse(selectedDateFilter, 'dd/MM/yyyy', new Date());

    if (isValid(dateConvertedValidFormat) && getUserRegistration != null) {
      milkAnalysisService.getMilkCollections(selectedDateFilter, getUserRegistration).then(response => setMilkAnalysis(response[0]));
    }
  }, [selectedDateFilter]);

  const getMilkAnalysisReport = () => {
    if (getUserRegistration != null) {
      const reportLinkPath = milkAnalysisApi.getMilkAnalysisReport(selectedDateFilter, getUserRegistration);
      setReportLink(reportLinkPath);
    }
  };

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  return (
    <>
      <StyledPageTitleContainer margin={'30px 0 15px 0'}>
        <MainPageTitle fontSize={30} text={t('livestock.milkAnalysis')} />
      </StyledPageTitleContainer>

      <StyledFiltersContainer>
        <StyledFilterContainer filterWidth={329}>
          <GenericDropdown
            label={t('livestock.filterDate')}
            placeholder={t('livestock.filterSelectDate')}
            listShowDropdown={dateList()}
            getSelectedValue={value => {
              setSelectedDateFilter(`${complementDateFilter}/${value}`);
            }}
          />
        </StyledFilterContainer>
        {milkAnalysis?.TIPODESCONTO != null && (
          <StyledButtonDownloadContainer onClick={() => getMilkAnalysisReport()}>
            <ButtonDownload href={reportLink} />
          </StyledButtonDownloadContainer>
        )}
      </StyledFiltersContainer>

      <StyledGraphicsContainer>
        {milkAnalysis?.TIPODESCONTO.map((item, index) => (
          <CollapsibleGraphic
            title={item.DS_TIPO_DESCONTO}
            collectionData={item}
            selectedDateFilter={selectedDateFilter}
            key={`graphic-${index}`}
          />
        ))}
      </StyledGraphicsContainer>
    </>
  );
};

const StyledGraphicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 20px;
`;

const StyledFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  row-gap: 30px;
  flex-wrap: wrap;
`;

const StyledButtonDownloadContainer = styled.div`
  margin-top: -17px;
`;

export default withTranslation()(MilkAnalysis);
