import { AxiosResponse } from 'axios';
import { ForecastResponse, WeatherResponse } from 'model/weather-response';
import { sendLog } from 'shared/util/api-utils';
import { apiWeather } from './api';

export const weatherApi = () => {
  const forecast6days = (lat: string, lon: string): Promise<AxiosResponse<ForecastResponse>> => {
    sendLog(
      'Consulta de tempo dos ultimos 6 dias',
      `forecast?lat=${lat}&lon=${lon}&appid=36a3a5808df66460746e3f432ab5310e&units=metric`,
      JSON.stringify([]),
      'get'
    );
    return apiWeather.get<ForecastResponse>(`forecast?lat=${lat}&lon=${lon}&appid=36a3a5808df66460746e3f432ab5310e&units=metric`);
  };
  const weatherToday = (lat: string, lon: string): Promise<AxiosResponse<WeatherResponse>> => {
    sendLog(
      'Consulta tempo hoje',
      `weather?lat=${lat}&lon=${lon}&appid=36a3a5808df66460746e3f432ab5310e&units=metric`,
      JSON.stringify([]),
      'get'
    );
    return apiWeather.get<WeatherResponse>(`weather?lat=${lat}&lon=${lon}&appid=36a3a5808df66460746e3f432ab5310e&units=metric`);
  };
  const weatherByCity = (city: string): Promise<AxiosResponse<WeatherResponse>> => {
    sendLog(
      'Consulta de temperatura por cidade',
      `weather/?q=${city}&appid=36a3a5808df66460746e3f432ab5310e&units=metric`,
      JSON.stringify([]),
      'get'
    );
    return apiWeather.get<WeatherResponse>(`weather/?q=${city}&appid=36a3a5808df66460746e3f432ab5310e&units=metric`);
  };
  return {
    forecast6days,
    weatherToday,
    weatherByCity,
  };
};

export default weatherApi();
