import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { ThemeProvider } from 'styled-components';
import LoadingContainerScreen from './components/loading-container-screen/loading-container-screen';
import Toast from './components/Toast';
import './i18n/yup-translate';
import { HttpRequestStatus } from './model/enums/http-request-status';
import { IRootState } from './reducer';
import { getSessionRequest, logoutRequest } from './reducer/authentication/actions';
import AppRoutes from './shared/routes/routes';
import AuthUtils from './shared/util/auth-utils';
import theme from './themes/default';

const baseHref = '/';

interface IAppProps extends StateProps, DispatchProps {
  // TODO
}

interface IAppState {
  isLoading: boolean;
}

const App = props => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (AuthUtils.isAuthenticated()) {
      props.getSession();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (props.getSessionStatus === HttpRequestStatus.SUCCESS) {
      setIsLoading(false);
    }

    if (props.getSessionStatus === HttpRequestStatus.ERROR) {
      props.logout();
      setIsLoading(false);
    }
  }, [props.getSessionStatus]);

  const renderApp = (appProps: RouteComponentProps<any>) => {
    const { isAuthenticated } = props;
    const notAllowedPathname: string[] = ['/register', '/'];
    const checkIfIsAuthenticated = isAuthenticated && !notAllowedPathname.includes(appProps.location.pathname);
    return (
      <div className={'App'}>
        <LoadingContainerScreen isLoading={isLoading}>
          <Route path={'/'} component={() => <AppRoutes {...appProps} isAuthenticated={checkIfIsAuthenticated} />} />
        </LoadingContainerScreen>
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={'page-container'}>
        <Toast />
        <BrowserRouter basename={baseHref}>
          <Route render={renderApp} />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: IRootState) => ({
  account: state.authentication.account,
  isAuthenticated: state.authentication.isAuthenticated,
  getSessionStatus: state.authentication.getSessionStatus,
});

const mapDispatchToProps = {
  logout: logoutRequest,
  getSession: getSessionRequest,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(connect(mapStateToProps, mapDispatchToProps))(App) as React.ComponentType<any>;
