import { IProductSummary } from 'model/forest';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import { StyledTable, StyledTableRow } from 'styled-components/StyledForTables';

interface IProps extends WithTranslation {
  tableData: IProductSummary[];
}

const SummaryTableProduct = ({ tableData, t }: IProps) => {
  return (
    <StyledTable isInvertLineColor>
      <thead>
        <StyledMainTableRowHeader>
          <th colSpan={2}>{t('table.summaryByProduct')}</th>
        </StyledMainTableRowHeader>
      </thead>

      {tableData.map(data => (
        <React.Fragment key={`table-data-${data.CD_PRODUTO}`}>
          <thead>
            <StyledTableRowHeader>
              <th colSpan={2}>{`${data.CD_PRODUTO} - ${data.DS_PRODUTO_RE}`}</th>
            </StyledTableRowHeader>
          </thead>

          <tbody>
            <StyledTableRow>
              <td>{t('table.loadQuantity')}</td>
              <td>{data.QT_CARGAS}</td>
            </StyledTableRow>
            <StyledTableRow>
              <td>{t('table.grossWeight')}</td>
              <td>{StringUtils.maskAmount(data.QT_PESO_BRUTO)}</td>
            </StyledTableRow>
            <StyledTableRow>
              <td>{t('table.discount')}</td>
              <td>{StringUtils.maskAmount(data.DESCONTOS[0].VL_DESCONTADO)}</td>
            </StyledTableRow>
            <StyledTableRow>
              <td>{t('table.finalProduct')}</td>
              <td>{StringUtils.maskAmount(data.QT_PESO_LIQUIDO)}</td>
            </StyledTableRow>
          </tbody>
        </React.Fragment>
      ))}
    </StyledTable>
  );
};

const StyledMainTableRowHeader = styled.tr`
  height: 50px;
  color: white;
  background-color: ${({ theme }) => theme.color.frisiaPrimary};

  th {
    text-align: left;
    border-radius: 16px 16px 0 0;
    padding-left: 20px;
  }
`;

const StyledTableRowHeader = styled.tr`
  height: 50px;
  background-color: white;
  color: #032762;

  th {
    text-align: left;
    padding-left: 20px;
  }
`;

export default withTranslation()(SummaryTableProduct);
