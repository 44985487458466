import { useCallback, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';
import { ReactComponent as ArrowDownSvg } from '../images/frisia/icons/yellow-icon-arrow-down.svg';

interface IProps {
  label?: string;
  placeholder?: string;
  listShowDropdown: any[];
  errorText?: FieldError | string;
  isResetValue?: boolean;
  keyToShowInDropdown: string;
  keyGroup: string;
  className?: string;
  disabled?: boolean;
  defaultValue?: any;
  getSelectedValue: (value: any) => any;
}

interface IStyledContainerProps {
  isShowDropdown: boolean;
}

const GenericGroupingDropdown = ({
  label,
  placeholder,
  listShowDropdown,
  errorText,
  isResetValue,
  keyToShowInDropdown,
  keyGroup,
  className,
  disabled,
  defaultValue,
  getSelectedValue,
}: IProps) => {
  const [selectedItem, setSelectedItem] = useState<any>(placeholder ?? '');
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);

  useEffect(() => {
    if (selectedItem != null && selectedItem !== placeholder) {
      getSelectedValue(selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (isResetValue) {
      setSelectedItem(placeholder ?? '');
    }
  }, [isResetValue]);

  useEffect(() => {
    if (defaultValue != null) {
      setSelectedItem(defaultValue);
    }
  }, [defaultValue]);

  const renderOptions = useCallback(() => {
    return (
      <StyledList>
        {listShowDropdown
          .map(groupItem => groupItem[keyGroup])
          .filter((v, i, a) => a.indexOf(v) === i)
          .map(groupName => (
            <>
              <StyledOptgroup key={groupName}>
                {groupName}
                {listShowDropdown
                  .filter(item => item[keyGroup] === groupName)
                  .map((item, index) => (
                    <StyledItem key={`${item}-${index}`} onClick={() => setSelectedItem(item)}>
                      {item[keyToShowInDropdown]}
                    </StyledItem>
                  ))}
              </StyledOptgroup>
            </>
          ))}
      </StyledList>
    );
  }, [listShowDropdown]);

  return (
    <StyledContainer className={className}>
      <StyledDiv
        onClick={() => {
          if (!disabled) {
            setIsShowDropdown(!isShowDropdown);
          }
        }}
        isShowDropdown={isShowDropdown}
        tabIndex={0}
      >
        <StyledInnerContainer className={`${label != null ? '' : 'margin-top'}`}>
          <div>
            <StyledLabel>{label}</StyledLabel>
            <StyledText className={`${selectedItem !== placeholder ? '' : 'style-placeholder'}`}>
              {keyToShowInDropdown != null && selectedItem !== placeholder ? selectedItem[keyToShowInDropdown] : selectedItem}
            </StyledText>
          </div>

          <StyledIconContainer className={`${isShowDropdown ? 'rotate' : ''}`}>
            <ArrowDownSvg />
          </StyledIconContainer>
        </StyledInnerContainer>

        {isShowDropdown && renderOptions()}
      </StyledDiv>

      <StyledErrorMessage>{errorText}</StyledErrorMessage>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  height: 70px;
`;

const StyledDiv = styled.div<IStyledContainerProps>`
  position: absolute;
  min-height: 53px;
  min-width: 100%;
  text-align: left;
  border: 2px solid #4e6ba7;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f1f1f1;
  z-index: ${({ isShowDropdown }) => (isShowDropdown ? 1 : 0)};
`;

const StyledInnerContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  margin: 5px 10px 0 10px;

  &.margin-top {
    margin-top: 13px;
  }
`;

const StyledLabel = styled.span`
  display: block;

  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 10px;
  text-transform: uppercase;
`;

const StyledText = styled.div`
  overflow-y: auto;

  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #656565;
  font-size: 16px;

  &.style-placeholder {
    font-size: 12px;
    opacity: 0.5;
  }
`;

const StyledIconContainer = styled.div`
  margin-left: 3px;
  @-webkit-keyframes arrow_animation {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  @keyframes arrow_animation {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  &.rotate {
    -webkit-animation: arrow_animation 0.3s 0s;
    animation: arrow_animation 0.3s 0s;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
`;

const StyledList = styled.ul`
  @-webkit-keyframes list_animation {
    from {
      height: 0;
    }
    to {
      height: 80px;
    }
  }
  @keyframes list_animation {
    from {
      height: 0;
    }
    to {
      height: 80px;
    }
  }

  color: #656565;
  font-size: 16px;
  list-style-type: none;
  padding: 0;
  margin-left: 7px;
  overflow: auto;
  z-index: 10;

  li:nth-last-child(1) {
    border: none;
  }

  -webkit-animation: list_animation 0.3s 0s;
  animation: list_animation 0.3s 0s;
  max-height: 200px;
`;

const StyledOptgroup = styled.div`
  padding-top: 4px;
  font-weight: 600;
`;

const StyledItem = styled.div`
  max-width: 70%;
  padding: 0px 10px;
  padding-top: 4px;
  font-weight: 500;
`;

const StyledErrorMessage = styled.span`
  position: absolute;
  bottom: 0;
  left: 1px;

  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 12px;
  color: red;
`;

export default GenericGroupingDropdown;
