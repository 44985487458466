import backgroundImage from 'images/frisia/img/large-frisia-building.svg';
import { color } from 'shared/util/styled-component-utils';
import HelpText from 'styled-components/HelpText';
import styled from 'styled-components/macro';
import { responsive } from '_media-query';
import LogoImg from 'images/frisia/img/frisia-blue-logo.svg';

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  width: 1440px;
  margin: auto;

  ${HelpText} {
    position: absolute;
    top: calc(100% - 2px);
    z-index: 1;
  }
  img {
    width: 202px;
    margin-bottom: 26px;
  }

  @media (max-height: 1000px), (max-width: 1400px) {
    zoom: 1;
  }
  @media (max-height: 800px), (max-width: 1200px) {
    zoom: 70%;
  }
  @media (max-height: 600px), (max-width: 900px) {
    zoom: 56%;
  }
`;

export const Background = styled.div`
  background-origin: padding-box;
  background-clip: padding-box;
  height: 100vh;

  &:before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('${backgroundImage}');
    filter: sepia(100%) hue-rotate(190deg) saturate(180%);
    @media (max-height: 1000px), (max-width: 1400px) {
      width: 112%;
    }
    @media (max-height: 800px), (max-width: 1200px) {
      background-size: auto auto;
    }
    @media (max-height: 600px), (max-width: 900px) {
      width: 152%;
    }
  }
`;

export const LogoImage = styled.div`
  background-image: url('${LogoImg}');
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 8px;
  margin-left: 106px;
  width: 141px;
  min-height: 68px;
  opacity: 1;
  position: sticky;
  z-index: 2;
  transition: 'top 0.3s, left 0.3s, width 0.3s, height 0.3s';
`;

export const LogoEye = styled.i`
  cursor: pointer;
  z-index: 1;
  width: 50px;
`;

export const StyledInput = styled.input`
  top: 464px;
  left: 329px;
  padding-left: 16px;
  width: 328px;
  min-height: 56px;
  border: 1px solid #0000001f;
  border-radius: 4px;
  opacity: 1;
`;

export const MessageContainer = styled.div`
  top: 411px;
  left: 493px;
  transform: translateX(-140px);
  z-index: 1;
  width: 947px;
  height: 258px;
  background: #102b5acf 0% 0% no-repeat padding-box;
  background-blend-mode: multiply;
  opacity: 1;
  padding: 73px 0px;
`;

export const Message = styled.div`
  /* top: 484px; */
  left: 874px;
  width: 341px;
  height: 48px;
  color: var(--500);
  text-align: left;
  margin-left: 381px;
  margin-bottom: 40px;
  font: normal normal 36px/44px Montserrat-Bold;
  letter-spacing: 0.01px;
  color: #d7cc27;
  opacity: 1;
  font-size: 48px;
  mix-blend-mode: initial;
  position: sticky;
`;

export const Message2 = styled.div`
  margin-left: 381px;
  font-size: 48px;
  mix-blend-mode: initial;
  top: 532px;
  left: 880px;
  width: 306px;
  height: 64px;
  text-align: left;
  font: normal normal normal 24px/34px Montserrat-Regular;
  letter-spacing: 0px;
  color: #e6eaf2;
  opacity: 1;
  position: sticky;
`;

export const ForgotPassword = styled.a`
  font-size: 12px;
  color: ${color`laranja`};
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
  margin: 36px 0 9px 0;

  &:hover {
    color: ${color`laranja`};
  }
`;

export const WhiteContainer = styled.div`
  z-index: 3;
  .normal {
    background: white;
    border-radius: 8px;
    padding: 64px 84px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 522px;
    height: 600px;
    z-index: 4;

    top: 276px;
    left: 232px;
    transform: translateX(150px);

    background: var(--surface) 0% 0% no-repeat padding-box;
    background: #fcfcfc 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #1d1d1d33;
    border-radius: 20px;
    opacity: 1;

    legend {
      float: none;
    }
  }

  .forgotPassword {
    background: white;
    border-radius: 8px;
    padding: 64px 84px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: 256px;
    left: 738px;
    width: 522px;
    height: 549px;
    z-index: 1;

    background: var(--surface) 0% 0% no-repeat padding-box;
    background: #fcfcfc 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #1d1d1d33;
    border-radius: 20px;
    opacity: 1;

    legend {
      float: none;
    }
  }
`;

export const Items = styled.div`
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 500px;
  max-width: 488px;
  padding: 65px 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemLink = styled.div`
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #df6518;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 20px;
`;

export const TitleLogin = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.43;
  letter-spacing: normal;
  color: #4a4a4a;
  ${responsive('s')} {
    display: none;
  }
`;
