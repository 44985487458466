import CardsShortcuts from 'components/CardsShortcuts';
import MainPageTitle from 'components/MainPageTitle';
import CardNames from 'model/enums/card-names';
import CategoryNames from 'model/enums/categorys-names';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import shorcutService from 'services/shortcuts-service';
import { getCardsByCategory } from 'shared/util/card';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { useHistory } from 'react-router-dom';
import { StyledButton } from 'components/styled-button/StyledButton';

const StyledCardsContainer = styled.div`
  max-width: 936px;
  margin: 37px auto 37px auto;
`;

const Shortcuts = ({ t }: WithTranslation) => {
  const [favorites, setFavorites] = useState<CardNames[]>([]);

  const history = useHistory();

  const getFavorites = async () => {
    if (getUserRegistration != null) {
      const resultPages = await shorcutService.getFavorite(getUserRegistration);
      const favoritesPages = resultPages.map(item => {
        return CardNames[item.DS_FAVORITO];
      });
      setFavorites(favoritesPages);
    }
  };

  useEffect(() => {
    async function callAsync() {
      await getFavorites();
    }
    callAsync();
  }, []);

  useEffect(() => {
    if (favorites.length >= 0) {
      localStorage.setItem('favoritesList', JSON.stringify(favorites));
    }
  }, [favorites]);

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const addFavorites = async favoriteName => {
    if (getUserRegistration != null && favoriteName) {
      const resultAddFavorite = await shorcutService.addFavorite(getUserRegistration, favoriteName);
    }
  };

  const deleteFavorite = async favoriteName => {
    if (getUserRegistration != null && favoriteName) {
      const resultAddFavorite = await shorcutService.deleteFavorite(getUserRegistration, favoriteName);
    }
  };

  const updateFavorite = cardName => {
    if (favorites.includes(cardName)) {
      const newArray = favorites.filter(e => e !== cardName);
      setFavorites(newArray);
      deleteFavorite(cardName);
    } else {
      setFavorites([...favorites, cardName]);
      addFavorites(cardName);
    }
  };

  return (
    <>
      <StyledPageTitleContainer style={{ display: 'flex' }} margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('mainMenu.financial')} />
        <div style={{ position: 'fixed', zIndex: '10', right: '25px' }}>
          <StyledButton color="frisiaPrimary" type="button" onClick={() => history.push('inicial')} form="myform">
            <span>{t('global.button.save')}</span>
          </StyledButton>
        </div>
      </StyledPageTitleContainer>

      <StyledCardsContainer>
        <CardsShortcuts
          listCardNames={getCardsByCategory(CategoryNames.FINANCIAL)}
          spacingBetweenCards={37}
          key={JSON.stringify(favorites)}
          favorites={favorites}
          updateFavorite={updateFavorite}
        />
      </StyledCardsContainer>

      <StyledPageTitleContainer style={{ display: 'flex' }} margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('mainMenu.institutional')} />
      </StyledPageTitleContainer>

      <StyledCardsContainer>
        <CardsShortcuts
          key={JSON.stringify(favorites)}
          listCardNames={getCardsByCategory(CategoryNames.INSTITUTIONAL)}
          spacingBetweenCards={37}
          favorites={favorites}
          updateFavorite={updateFavorite}
        />
      </StyledCardsContainer>

      <StyledPageTitleContainer style={{ display: 'flex' }} margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('mainMenu.agricultural')} />
      </StyledPageTitleContainer>

      <StyledCardsContainer>
        <CardsShortcuts
          key={JSON.stringify(favorites)}
          listCardNames={getCardsByCategory(CategoryNames.AGRICULTURAL)}
          spacingBetweenCards={37}
          favorites={favorites}
          updateFavorite={updateFavorite}
        />
      </StyledCardsContainer>

      <StyledPageTitleContainer style={{ display: 'flex' }} margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('mainMenu.livestockCattle')} />
      </StyledPageTitleContainer>

      <StyledCardsContainer>
        <CardsShortcuts
          key={JSON.stringify(favorites)}
          listCardNames={getCardsByCategory(CategoryNames.CATTLE)}
          spacingBetweenCards={37}
          favorites={favorites}
          updateFavorite={updateFavorite}
        />
      </StyledCardsContainer>

      <StyledPageTitleContainer style={{ display: 'flex' }} margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('mainMenu.livestockPigs')} />
      </StyledPageTitleContainer>

      <StyledCardsContainer>
        <CardsShortcuts
          key={JSON.stringify(favorites)}
          listCardNames={getCardsByCategory(CategoryNames.PORCINE)}
          spacingBetweenCards={37}
          favorites={favorites}
          updateFavorite={updateFavorite}
        />
      </StyledCardsContainer>
    </>
  );
};

export default withTranslation()(Shortcuts);
