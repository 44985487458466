import MainPageTitle from 'components/MainPageTitle';
import { IDirectory } from 'model/document';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import documentsService from 'services/documents-service';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import Document from '../components/Document';

interface IStyledProps {
  isActive?: boolean;
}

const CooperativeRoom = ({ t }: WithTranslation) => {
  const [directories, setDirectories] = useState<IDirectory>({});
  const [allDirectories, setAllDirectories] = useState<IDirectory>({});
  const [cardsOptions, setCardsOptions] = useState<string[]>([t('agricultural.all').toUpperCase()]);
  const [selectedFilterCard, setSelectedFilterCard] = useState<string>(t('agricultural.all').toUpperCase());

  useEffect(() => {
    documentsService.getDocuments().then(response => {
      setDirectories(response.directory['Sala do Associado'].directory);
      setAllDirectories(response.directory['Sala do Associado'].directory);
      setCardsOptions(oldValue => [...oldValue, ...Object.keys(response.directory['Sala do Associado'].directory)]);
    });
  }, []);

  const handleDownloadDocument = (filename: string, fileUrl: string) => {
    documentsService
      .generateLinkDownloadDocument(fileUrl)
      .then(response => {
        try {
          // Decodifica o conteúdo base64
          const byteCharacters = atob(response);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Determina o tipo MIME do arquivo
          const contentType = getContentTypeFromFilename(filename);

          // Cria um Blob com o conteúdo decodificado
          const blob = new Blob([byteArray], { type: contentType });

          // Cria uma URL para o Blob e cria um link de download
          const url = URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.download = filename;

          // Simula o clique no link para iniciar o download
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          // Limpa a URL criada para o Blob
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Erro ao processar o arquivo:', error);
        }
      })
      .catch(error => {
        console.error('Erro ao obter o documento:', error);
      });
  };

  // Função auxiliar para determinar o tipo MIME com base no nome do arquivo
  const getContentTypeFromFilename = (filename: string): string => {
    // Exemplos de determinação do tipo MIME com base na extensão do arquivo
    const extension = filename.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      // Adicione mais casos conforme necessário para outros tipos de arquivo
      default:
        return 'application/octet-stream';
    }
  };

  return (
    <StyledContainer>
      <StyledPageTitleContainer margin={'18px 0'}>
        <MainPageTitle fontSize={18} text={t('mainMenu.categories')} />
      </StyledPageTitleContainer>
      <StyledCardsContainer>
        {cardsOptions.map((card, index) => (
          <StyledCard
            key={`document-category-${index}`}
            isActive={card === selectedFilterCard}
            onClick={() => {
              setSelectedFilterCard(card);
              if (card === t('agricultural.all').toUpperCase()) {
                setDirectories(allDirectories);
              } else {
                setDirectories({ [card]: allDirectories[card] });
              }
            }}
          >
            <StyledText>{card}</StyledText>
          </StyledCard>
        ))}
      </StyledCardsContainer>

      <StyledDocumentsContainer>
        {Object.keys(directories).map((directoryName, directoryIndex) => {
          return (
            <ContainerRports key={`category-${directoryIndex}`}>
              <StyledCategoryName>{directoryName}</StyledCategoryName>
              {directories[directoryName]?.file.map((item, index) => (
                <DocumentCard key={`document-cooperative-room-${index}`}>
                  <Document text={item.filename} onClick={() => handleDownloadDocument(item.filename, item.fileurl)} />
                </DocumentCard>
              ))}
            </ContainerRports>
          );
        })}
      </StyledDocumentsContainer>
    </StyledContainer>
  );
};

const ContainerRports = styled.div`
  width: 100%;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  row-gap: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const StyledContainer = styled.div`
  margin-top: -44px;
`;

const DocumentCard = styled.div`
  top: 342px;
  left: 355px;
  width: 389px;
  height: 64px;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  opacity: 1;
  font-size: 12px;
  color: #2a5299;
  :hover {
    background-color: #d8deeb;
  }
`;

const StyledCategoryName = styled.div`
  width: 100%;
  text-align: left;
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 15px;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
`;

const StyledText = styled.div`
  max-width: 119px;
  text-align: center;
`;

const StyledCard = styled.div<IStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 170px;
  height: 65px;
  padding: 0 12px;
  background-color: #fcfcfc;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px;
  background-color: ${({ isActive, theme }) => (isActive ? theme.color.frisiaPrimary : '#fcfcfc')};
  color: ${({ isActive }) => (isActive ? '#D7CC27' : '#656565')};
  :hover {
    background-color: #d8deeb;
  }
`;

const StyledCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 30px;
`;

const StyledDocumentsContainer = styled.div`
  display: flex;
  -ms-flex-pack: justify;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  row-gap: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 535px;
  overflow-y: auto;
  justify-content: flex-start;
`;

export default withTranslation()(CooperativeRoom);
