import { IGeneralParams } from 'model/slaughter-report';
import { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled, { CSSProperties } from 'styled-components';
import { StyledTable, StyledTableRow } from 'styled-components/StyledForTables';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/white-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/white-plus-round-icon.svg';

export interface ITypeTable extends WithTranslation {
  headerInfos?: string[];
  tableItems: IGeneralParams[];
  isInvertLineColor?: boolean;
  isDontHaveMaximumWidth?: boolean;
  style?: CSSProperties;
}

const GenericTableAlegraParams = ({ tableItems, isInvertLineColor, isDontHaveMaximumWidth, style, t }: ITypeTable) => {
  const [isShowGraphic, setIsShowGraphic] = useState<boolean>(false);
  const generalParametersTableHeader = [t('table.parameters'), t('table.unit'), t('table.tattoo')];

  const paramsRows = [
    {
      parameter: '',
      kiloSymbol: '',
    },
    {
      parameter: 'Média Bonificação - CARCAÇA',
      kiloSymbol: '%',
    },
    {
      parameter: 'Média Bonificação - CHECKLIST',
      kiloSymbol: '%',
    },

    {
      parameter: 'Média Bonificação - PROGRAMA DE GESTÃO',
      kiloSymbol: '%',
    },

    {
      parameter: 'Média Rend. Carcaça - PADRÃO',
      kiloSymbol: '%',
    },

    {
      parameter: 'Média Perdas Sanitárias - CUSTO PADRÃO',
      kiloSymbol: '%',
    },

    {
      parameter: ' ',
      kiloSymbol: ' ',
    },
    {
      parameter: 'Media Bonificação - SUINO LIMPO',
      kiloSymbol: '%',
    },
    {
      parameter: 'Preço base alegra - Suíno vivo sem bonif.',
      kiloSymbol: 'R$/kg vivo',
    },
    {
      parameter: 'Preço base alegra - Carcaça s/ Bonif. (Rend. 76,40%)',
      kiloSymbol: 'R$/kg carcaça',
    },
    {
      parameter: 'Preço base alegra - Graxaria (5%)',
      kiloSymbol: 'R$/kg carcaça',
    },
    {
      parameter: 'Preço base alegra - Suíno vivo sem bonif.',
      kiloSymbol: 'R$/kg vivo',
    },
    {
      parameter: 'Preço base alegra - Antecipação de Abate',
      kiloSymbol: 'R$/cab/dia',
    },
  ];

  const getValues = tableItems.map(row => [
    row.NR_TATUAGEM,
    row.TX_MEDIA_CARCACA_BONIF,
    row.TX_MEDIA_CHECKLIST_BONIF,
    row.TX_MEDIA_PROG_GESTAO_BONIF,
    row.TX_RENDIMENTO_PADRAO,
    row.TX_MEDIA_PERDA_SANITARIA,
    '',
    row.TX_MEDIA_SCORE_LIMPEZA_BONIF,
    row.VL_CUSTO_DIRETO,
    row.VL_CARCACA_PADRAO_BASE,
    row.VL_CARCACA_EMBUT_BASE,
    row.VL_CARCACA_GRAXA_BASE,
    row.VL_PRECO_BASE_ANTECIPACAO,
  ]);
  const lastLine = getValues.length - 1;

  return (
    <StyledTable style={style} isInvertLineColor={isInvertLineColor} isDontHaveMaximumWidth={isDontHaveMaximumWidth}>
      {generalParametersTableHeader != null && (
        <thead>
          <StyledTableRowHeader>
            <th
              key={`header-item-title`}
              style={
                isShowGraphic
                  ? {
                      borderRadius: '16px 16px 0px 0px',
                    }
                  : {
                      borderRadius: '16px',
                    }
              }
              colSpan={getValues.length + 2}
            >
              {isShowGraphic ? (
                <MinusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
              ) : (
                <PlusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
              )}{' '}
              {t('table.generalParameters')}
            </th>
          </StyledTableRowHeader>
          {isShowGraphic && (
            <StyledTableRowHeader2>
              <th key={`header-item-para}`}>{t('table.parameters')}</th>
              <th key={`header-item-unid`}>{t('table.unit')}</th>
              <th key={`header-item-tatto`} style={{ textAlign: 'center' }} colSpan={getValues.length}>
                {t('table.tattoo')}
              </th>
            </StyledTableRowHeader2>
          )}
        </thead>
      )}
      {isShowGraphic && (
        <tbody>
          {paramsRows.map((tableRow, indexRow) => (
            <StyledTableRow key={`row-${indexRow}`} className={`${indexRow === lastLine ? 'last-line' : ''}`}>
              <td key={`item-parameter-${indexRow}`}>{tableRow.parameter}</td>
              <td key={`item-kiloSymbol-${indexRow}`}>{tableRow.kiloSymbol}</td>
              {getValues.map((tableItem, indexItem) => (
                <td key={`item-${indexItem}-${indexRow}`}>
                  {indexRow === 0
                    ? tableItem[indexRow]
                    : tableItem[indexRow].toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 6 })}
                </td>
              ))}
            </StyledTableRow>
          ))}
        </tbody>
      )}
    </StyledTable>
  );
};

const StyledTableRowHeader = styled.tr`
  height: 50px;
  color: white;
  background: #032762;
  font-size: 16px;

  th {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledTableRowHeader2 = styled.tr`
  height: 50px;
  background-color: white;
  color: #032762;

  th {
    text-align: center;
  }

  th:nth-child(1) {
    text-align: left;
    padding-left: 20px;
  }

  th:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
  }
`;

export default withTranslation()(GenericTableAlegraParams);
