import dayjs from 'dayjs';
import { ContentfulFrisiaResult, IUnreadContent } from 'model/contentful-results';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import contentfulService from 'services/contentful-service';
import styled from 'styled-components/macro';

interface StyledModalProps {
  onClick: () => void;
  hideCloseButton?: boolean;
  style?: any;
  numberOfnotifications?: number;
  numberUnread?: number;
}

const ModalContainer = styled.div`
  position: fixed;
  top: 60px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
`;

const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

export const ModalItems = styled.div`
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 278px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
`;

const StyledTitle = styled.div`
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  opacity: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  b {
    align-self: flex-start;
  }
`;

export const LinesStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 4px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  width: 24px;
  position: absolute;
  top: 18px;
  right: 28px;
`;

const StyledDate = styled.div`
  font-family: ${({ theme }) => theme.font.montserratRegular};
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
  margin: 8px;
`;

const ComunicationModal: React.FC<StyledModalProps> = ({ onClick, hideCloseButton, numberUnread }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [announcements, setAnnouncements] = useState<IUnreadContent[]>([]);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getData = async () => {
    contentfulService.getNotReadAnnouncements(getUserRegistration).then(response => {
      let arrayOf3 = response;
      arrayOf3.length = 3;
      setAnnouncements(arrayOf3);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ModalContainer>
      <ModalShadow onClick={onClick} />
      <ModalItems>
        {!hideCloseButton && <CloseButton onClick={onClick} />}
        <ContentContainer>
          <b>
            {t('mainMenu.forYou')} ({numberUnread})
          </b>
          <div style={{ height: '2px', width: '100%', backgroundColor: 'yellow', marginBottom: '18px' }} />
          {announcements.map((name, index) => (
            <LinesStyled key={index} onClick={() => history.push('/comunicados')}>
              <StyledTitle>{name.fields.title}</StyledTitle>
              <div>
                <StyledDate>{dayjs(name.fields.publicationDate).format('DD/MM')}</StyledDate>
              </div>
            </LinesStyled>
          ))}
          {announcements.length === 0 ? <StyledTitle>Carregando...</StyledTitle> : <StyledTitle>...</StyledTitle>}
        </ContentContainer>
      </ModalItems>
    </ModalContainer>
  );
};

export default ComunicationModal;
