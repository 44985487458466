import Tooltip from '@mui/material/Tooltip';
import FinancialPositionApi from 'api/financial-position-analytic-api';
import { IPositionTableItems } from 'features/financial/FinancialPositionAnalytic';
import { INewCodeIdType } from 'model/financial';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import styled from 'styled-components';
import { ReactComponent as ExclamationIcon } from '../images/frisia/icons/noun-information-817952.svg';

export interface ITypeTable {
  headerItems?: string[];
  headerInfos?: string[];
  tableItems: IPositionTableItems[];
  isInvertLineColor?: boolean;
  isDontHaveMaximumWidth?: boolean;
  style?: CSSProperties;
  chosenPeriod?: string;
  codeAndIds: INewCodeIdType[];
}

const GenericTableFinancialPositionAnalytic = ({
  codeAndIds,
  chosenPeriod,
  headerItems,
  headerInfos,
  tableItems,
  isInvertLineColor,
  isDontHaveMaximumWidth,
  style,
}: ITypeTable) => {
  const getValues = tableItems.map(row => Object.values(row));
  const lastLine = getValues.length - 1;
  const { t } = useTranslation();
  const warninTerminator = t('financialPosition.warningTerminator');

  const [agriculturalItems, setAgriculturalItems] = useState<string[][]>([]);
  const [livestockItems, setLivestockItems] = useState<string[][]>([]);
  const [movimentItem, setMovimentItem] = useState<string[][]>([]);
  const [silvItems, setSilvItems] = useState<string[][]>([]);
  const [totalItem, setTotaltems] = useState<string[][]>([]);
  const [productionItems, setProductionItems] = useState<string[][]>([]);

  useEffect(() => {
    tableItems.map(item => {
      if (item.financialAccount.toLowerCase().includes('pecuaria')) {
        return setLivestockItems(oldItems => [...oldItems, Object.values(item)]);
      } else if (item.financialAccount.toLowerCase().includes('movimento')) {
        return setMovimentItem(oldItems => [...oldItems, Object.values(item)]);
      } else if (item.financialAccount.toLowerCase().includes('silvicultura') || item.financialAccount.toLowerCase().includes('biomassa')) {
        return setSilvItems(oldItems => [...oldItems, Object.values(item)]);
      } else if (item.financialAccount.toLowerCase().includes('soma')) {
        return setProductionItems(oldItems => [...oldItems, Object.values(item)]);
      } else if (item.financialAccount.toLowerCase().includes('totais')) {
        return setTotaltems(oldItems => [...oldItems, Object.values(item)]);
      } else {
        return setAgriculturalItems(oldItems => [...oldItems, Object.values(item)]);
      }
    });
  }, [tableItems]);

  const correctWord = (word: string) => {
    if (word.includes('Feijao')) {
      return word.replaceAll('Feijao', 'Feijão');
    }
    if (word.includes('Grao Umido')) {
      return word.replaceAll('Grao Umido', 'Grão Úmido');
    }
    if (word.includes('Pecuaria Suinos')) {
      return word.replaceAll('Pecuaria Suinos', 'Pecuária Suínos');
    }
    if (word.includes('Pecuaria')) {
      return word.replaceAll('Pecuaria', 'Pecuária');
    }
    return word;
  };

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const MovimentLinkedDiv = (stringItem: string, indexOfRow: number) => {
    const link = FinancialPositionApi.getLinkMoviment(getUserRegistration, chosenPeriod ?? '', indexOfRow);
    if (link != '') {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={
            parseFloat(stringItem) < 0
              ? { color: '#ED1717', textDecoration: 'underline' }
              : stringItem === ''
              ? { background: 'white' }
              : { textDecoration: 'underline' }
          }
        >
          {stringItem}
        </a>
      );
    } else {
      return stringItem;
    }
  };

  const LinkedItemsDiv = (stringItem: string, indexOfRow: number, name: string) => {
    const chosen = codeAndIds.find(item => item.nome === name);
    const link = FinancialPositionApi.getLinkItems(
      getUserRegistration,
      chosenPeriod ?? '',
      indexOfRow,
      chosen?.idContaFinanceira ?? '',
      chosen?.codigo ?? ''
    );
    if (link != '') {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={
            parseFloat(stringItem) < 0
              ? { color: '#ED1717', textDecoration: 'underline' }
              : stringItem === ''
              ? { background: 'white' }
              : { textDecoration: 'underline' }
          }
        >
          {correctWord(stringItem)}
        </a>
      );
    } else {
      return correctWord(stringItem);
    }
  };

  const totalLinkedDiv = (stringItem: string, indexOfRow: number) => {
    const link = FinancialPositionApi.getLinkTotals(getUserRegistration, chosenPeriod ?? '', indexOfRow);
    if (link != '') {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={
            parseFloat(stringItem) < 0
              ? { color: '#ED1717', textDecoration: 'underline' }
              : stringItem === ''
              ? { background: 'white' }
              : { textDecoration: 'underline' }
          }
        >
          {stringItem}
        </a>
      );
    } else {
      return stringItem;
    }
  };

  const productionLinkedDiv = (stringItem: string, indexOfRow: number) => {
    const link = FinancialPositionApi.getLinkProduction(getUserRegistration, chosenPeriod ?? '', indexOfRow);
    if (link != '') {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={
            parseFloat(stringItem) < 0
              ? { color: '#ED1717', textDecoration: 'underline' }
              : stringItem === ''
              ? { background: 'white' }
              : { textDecoration: 'underline' }
          }
        >
          {stringItem}
        </a>
      );
    } else {
      return stringItem;
    }
  };

  return (
    <div>
      <StyledContainer>
        <StyledTable style={style} isInvertLineColor={isInvertLineColor} isDontHaveMaximumWidth={isDontHaveMaximumWidth}>
          {headerItems != null && (
            <thead>
              <StyledTableRowHeader>
                {headerItems.map((headerItem, index) => {
                  if (index == 0) {
                    return (
                      <th
                        key={`header-item-${index}`}
                        style={{
                          zIndex: '10',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            height: '90px',
                            minHeight: '100%',
                            minWidth: '',
                            alignItems: 'center',
                            paddingLeft: '20px',
                          }}
                        >
                          {headerItem}
                          {headerInfos && headerInfos[index] != '' && (
                            <Tooltip title={<div style={{ whiteSpace: 'pre' }}>{headerInfos[index]} </div>} arrow placement="top">
                              <ExclamationIcon
                                style={{
                                  alignSelf: 'self-start',
                                  minHeight: '18px',
                                  minWidth: '18px',
                                  height: '18px',
                                  width: '18px',
                                  transform: 'translate(-15px, 5px)',
                                  position: 'absolute',
                                  right: '-21px',
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </th>
                    );
                  }

                  return (
                    <th key={`header-item-${index}`} style={{ border: '2px solid #0327623e' }}>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          height: '90px',
                          minHeight: '100%',
                          alignItems: 'center',
                          paddingLeft: '4px',
                        }}
                      >
                        {headerItem}
                        {headerInfos && headerInfos[index] != '' && (
                          <Tooltip title={<div style={{ whiteSpace: 'pre' }}>{headerInfos[index]} </div>} arrow placement="top">
                            <ExclamationIcon
                              style={{
                                alignSelf: 'self-start',
                                minHeight: '18px',
                                minWidth: '18px',
                                height: '18px',
                                width: '18px',
                                transform: 'translate(-15px, 5px)',
                                position: 'absolute',
                                right: '-21px',
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </th>
                  );
                })}
              </StyledTableRowHeader>
            </thead>
          )}
          <tbody>
            {movimentItem.map((tableRow, indexRow) => (
              <StyledTableRow key={`row-${indexRow}`}>
                {[...tableRow].map((tableItem, indexItem) => {
                  if (indexItem == 0) {
                    return (
                      <div
                        key={`item-${indexItem}`}
                        style={{
                          height: '30px',
                          paddingTop: '10px',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            left: '-20px',
                            top: '-159px',
                            zIndex: '-2',
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '20px',
                            lineHeight: '2.5',
                          }}
                        >
                          <SpecialGroupColumn
                            style={{
                              height: `${157 + 28 * movimentItem.length + 28 * productionItems.length}px`,
                              backgroundColor: 'white',
                              color: '#032762 !important',
                              writingMode: 'vertical-rl',
                              transform: 'rotate(180deg)',
                              fontSize: '10px !important',
                              verticalAlign: 'center !important',
                              borderBottomRightRadius: '24px',
                            }}
                          >
                            {''}
                          </SpecialGroupColumn>
                          {agriculturalItems.length > 0 && (
                            <SpecialGroupColumn
                              style={{
                                height: `${28 * agriculturalItems.length}px`,
                                wordWrap: 'break-word',
                                backgroundColor: 'white',
                                color: '#032762 !important',
                                writingMode: 'vertical-rl',
                                transform: 'rotate(180deg)',
                                fontSize: '10px !important',
                                verticalAlign: 'center !important',
                                width: '20px',
                              }}
                            >
                              <div className="left" />
                              {agriculturalItems.length > 1 && t('financialPosition.agricultural')}
                              <div className="right" />
                            </SpecialGroupColumn>
                          )}
                          {livestockItems.length > 0 && (
                            <SpecialGroupColumn
                              style={{
                                height: `${28 * livestockItems.length}px`,
                                wordWrap: 'break-word',
                                backgroundColor: 'white',
                                color: '#032762 !important',
                                writingMode: 'vertical-rl',
                                transform: 'rotate(180deg)',
                                fontSize: '10px !important',
                                verticalAlign: 'center !important',
                              }}
                            >
                              <div className="left" />
                              {livestockItems.length > 1 && t('financialPosition.livestock')}
                            </SpecialGroupColumn>
                          )}

                          <SpecialGroupColumn
                            style={{
                              height: `${2 + 28 * totalItem.length + 28 * silvItems.length}px`,
                              backgroundColor: 'white',
                              color: '#032762 !important',
                              writingMode: 'vertical-rl',
                              transform: 'rotate(180deg)',
                              fontSize: '10px !important',
                              verticalAlign: 'center !important',
                            }}
                          >
                            {''}
                          </SpecialGroupColumn>
                        </div>
                        <td
                          style={
                            parseFloat(tableItem) < 0
                              ? { color: '#ED1717', zIndex: '-2' }
                              : tableItem === ''
                              ? { background: 'white', zIndex: '-2' }
                              : { zIndex: '-2', fontWeight: 'bold', border: 0 }
                          }
                        >
                          {correctWord(tableItem)}
                        </td>
                      </div>
                    );
                  }
                  return (
                    <td
                      key={`item-${indexItem}`}
                      className={`${indexRow == 0 && indexItem == 0 ? 'account-style' : ''}`}
                      style={
                        parseFloat(tableItem) < 0
                          ? { color: '#ED1717' }
                          : Number.isNaN(parseFloat(tableItem))
                          ? undefined
                          : { color: '#0d6efd' }
                      }
                    >
                      {MovimentLinkedDiv(tableItem, indexItem)}
                    </td>
                  );
                })}
              </StyledTableRow>
            ))}
            {productionItems.map((tableRow, indexRow) => (
              <StyledTableRow key={`row-${indexRow}`}>
                {[...tableRow].map((tableItem, indexItem) => (
                  <td
                    key={`item-${indexItem}`}
                    className={`${indexRow == 0 && indexItem == 0 ? 'account-style' : ''}`}
                    style={
                      parseFloat(tableItem) < 0
                        ? { color: '#ED1717' }
                        : Number.isNaN(parseFloat(tableItem))
                        ? undefined
                        : { color: '#0d6efd' }
                    }
                  >
                    {productionLinkedDiv(tableItem, indexItem)}
                  </td>
                ))}
              </StyledTableRow>
            ))}
            {agriculturalItems.map((tableRow, indexRow) => (
              <StyledTableRow key={`row-${indexRow}`} className={`${indexRow === lastLine ? 'last-line' : ''}`}>
                {tableRow.map((tableItem, indexItem) => (
                  <td
                    key={`item-${indexItem}`}
                    className={`${indexRow == 0 && indexItem == 0 ? 'account-style' : ''}`}
                    style={
                      parseFloat(tableItem) < 0
                        ? { color: '#ED1717' }
                        : Number.isNaN(parseFloat(tableItem))
                        ? undefined
                        : { color: '#0d6efd' }
                    }
                  >
                    {LinkedItemsDiv(tableItem, indexItem, tableRow[0])}
                    {tableItem.includes('Terminadores') && (
                      <Tooltip title={<div style={{ whiteSpace: 'pre' }}>{warninTerminator} </div>} arrow placement="top">
                        <ExclamationIcon
                          style={{
                            alignSelf: 'self-start',
                            minHeight: '18px',
                            minWidth: '18px',
                            height: '18px',
                            width: '18px',
                            transform: 'translate(0px, -4px)',
                          }}
                        />
                      </Tooltip>
                    )}
                  </td>
                ))}
              </StyledTableRow>
            ))}
            {livestockItems.map((tableRow, indexRow) => (
              <StyledTableRow key={`row-${indexRow}`} className={`${indexRow === lastLine ? 'last-line' : ''}`}>
                {tableRow.map((tableItem, indexItem) => (
                  <td
                    key={`item-${indexItem}`}
                    className={`${indexItem == 0 ? 'account-style' : ''}`}
                    style={
                      parseFloat(tableItem) < 0
                        ? { color: '#ED1717' }
                        : Number.isNaN(parseFloat(tableItem))
                        ? undefined
                        : { color: '#0d6efd' }
                    }
                  >
                    {LinkedItemsDiv(tableItem, indexItem, tableRow[0])}
                    {tableItem.includes('Terminadores') && (
                      <Tooltip title={<div style={{ whiteSpace: 'pre' }}>{warninTerminator} </div>} arrow placement="top">
                        <ExclamationIcon
                          style={{
                            alignSelf: 'self-start',
                            minHeight: '18px',
                            minWidth: '18px',
                            height: '18px',
                            width: '18px',
                            transform: 'translate(0px, -4px)',
                          }}
                        />
                      </Tooltip>
                    )}
                  </td>
                ))}
              </StyledTableRow>
            ))}
            {silvItems.map((tableRow, indexRow) => (
              <StyledTableRow key={`row-${indexRow}`}>
                {[...tableRow].map((tableItem, indexItem) => (
                  <td
                    key={`item-${indexItem}`}
                    className={`${indexItem === 0 ? 'account-style' : ''}`}
                    style={
                      parseFloat(tableItem) < 0
                        ? { color: '#ED1717' }
                        : Number.isNaN(parseFloat(tableItem))
                        ? undefined
                        : { color: '#0d6efd' }
                    }
                  >
                    {LinkedItemsDiv(tableItem, indexItem, tableRow[0])}
                  </td>
                ))}
              </StyledTableRow>
            ))}

            {totalItem.map((tableRow, indexRow) => (
              <StyledTableRow key={`row-${indexRow}`}>
                {[...tableRow].map((tableItem, indexItem) => (
                  <td
                    key={`item-${indexItem}`}
                    className={`${indexRow == 0 && indexItem == 0 ? 'account-style' : ''}`}
                    style={
                      parseFloat(tableItem) < 0
                        ? { color: '#ED1717' }
                        : Number.isNaN(parseFloat(tableItem))
                        ? undefined
                        : { color: '#0d6efd' }
                    }
                  >
                    {totalLinkedDiv(tableItem, indexItem)}
                  </td>
                ))}
              </StyledTableRow>
            ))}
          </tbody>
        </StyledTable>
      </StyledContainer>
    </div>
  );
};

const StyledContainer = styled.div`
  display: flex;
  max-width: 1500px;
  position: relative;
  overflow-x: auto;
  table {
    transform: translate(18px, 0px);
    font-size: 10px !important;
    thead > :first-child {
      position: -webkit-sticky !important;
      position: sticky !important;
      top: 0;
      z-index: 2;
    }
    tr > :first-child {
      position: -webkit-sticky;
      position: sticky;
      background: white;
      left: 0;
      text-align: left;
    }
    td {
      font-family: ${({ theme }) => theme.font.montserratMedium} !important;
      text-align: right;
      padding-left: 7px !important;
    }
    td:nth-child(1) {
      padding-right: 7px;
    }
    tr:nth-child(2) > td:first-child {
      font-weight: bold;
    }
    tr:last-child {
      font-weight: bold;
      font-family: ${({ theme }) => theme.font.montserratBold};
      max-width: 102px;
    }
  }
  .left {
    top: 0px;
    border-top: 1px solid black;
    width: 100%;
  }

  .right {
    top: 0px;
    border-bottom: 1px solid black;
    width: 100%;
  }

  .borders-th {
    top: 0px;
    border-left: 1px solid black;
    width: 100%;
    div {
      width: 100%;
    }
  }

  .account-style {
    color: #404040;
    font-family: Montserrat-Regular;
    font-size: 10px !important;
    padding-left: 9px !important;
  }
`;

const StyledTableRowHeader = styled.tr`
  height: 35px;
  background-color: white;
  color: #032762;
  z-index: 20;
  th {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    margin-top: 35px;
    max-width: 75px;
    background-color: white;
    font-size: 9px;
    text-align: center;
    padding: 4px;
    position: sticky;
  }

  div {
    position: sticky;
  }

  th:nth-last-child(1) {
    text-align: center;
    padding-right: 20px;
  }

  th:first-child {
    text-align: right;
    padding-right: 20px;
  }

  .first-border {
    text-align: right;
    padding-right: 20px;
    border-radius: 16px 0 0 0;
  }

  .last-border {
    text-align: right;
    padding-right: 20px;
    border-radius: 16px 16px 0 0;
  }
`;

const SpecialGroupColumn = styled.div`
  height: 50px;
  background-color: white;
  color: #032762 !important;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 10px !important;
  text-align: -webkit-center !important;
  text-align: center !important;
`;

interface IStyledTableProps {
  isInvertLineColor?: boolean;
  isDontHaveMaximumWidth?: boolean;
}

const StyledTable = styled.table<IStyledTableProps>`
  min-width: 275px;
  width: calc(100% - 20px);
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 14px;
  border-radius: 16px;
  background-color: ${({ isInvertLineColor }) => (isInvertLineColor ? 'white' : '#f1f1f1')};

  tr:nth-child(2n) {
    background-color: ${({ isInvertLineColor }) => (isInvertLineColor ? '#f1f1f1' : 'white')};
  }

  border-collapse: collapse;
  td {
    border: 2px solid #0327623e;
  }
  tr:first-child td {
    border-top: 0;
  }
  tr td:first-child {
    border-left: 0;
  }
  tr:last-child td {
    border-bottom: 0;
  }
  tr td:last-child {
    border-right: 0;
  }
`;

export const StyledTableRow = styled.tr`
  height: 28px;

  td {
    text-align: left;
    padding-left: 9px;
  }

  td:nth-child(1) {
    color: #404040;
    font-family: ${({ theme }) => theme.font.montserratRegular};
  }

  td:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    padding-left: 2px;
  }

  &.last-line {
    td:nth-child(1) {
      border-radius: 0 0 0 16px;
    }

    td:nth-last-child(1) {
      border-radius: 0 0 16px 0;
    }
  }
`;

export default GenericTableFinancialPositionAnalytic;
