import documentApi from 'api/documents-api';
import { AxiosError, AxiosResponse } from 'axios';
import IDocument from 'model/document';
import { EnvironmentalRestrictFile } from 'model/enrironmental-restrict-files';
import httpStatus from 'model/enums/http-status';

export const documentService = () => {
  const getDocuments = async () => {
    try {
      const result: AxiosResponse<IDocument> = await documentApi.getDocuments();
      if (result.status === httpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const generateLinkDownloadDocument = async (fileUrl: string) => {
    try {
      const result: AxiosResponse<string> = await documentApi.generateLinkDownloadDocument(fileUrl);
      if (result.status === httpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getRestrictFilesBase64 = async (folder: string, registration: string): Promise<EnvironmentalRestrictFile[]> => {
    try {
      const result: AxiosResponse<EnvironmentalRestrictFile[]> = await documentApi.getRestrictFilesBase64(folder, registration);
      if (result.status === httpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getDocuments,
    generateLinkDownloadDocument,
    getRestrictFilesBase64,
  };
};

export default documentService();
