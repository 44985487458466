import { useFormContext } from 'react-hook-form';
import styled, { CSSProperties } from 'styled-components';

interface IProps {
  text: string;
  inputName: string;
  isChecked?: boolean;
  handleChecked?: (isChecked: boolean) => void;
  disabled?: boolean;
  style?: CSSProperties;
}

const Checkbox = ({ text, inputName, isChecked, handleChecked, disabled, style }: IProps) => {
  const { register } = useFormContext();

  const handleIsActive = (inputState: boolean) => {
    if (handleChecked != null) {
      handleChecked(inputState);
    }
  };

  return (
    <StyledContainer style={style}>
      <StyledCheckboxContainer>
        <StyledCheckbox
          disabled={disabled}
          {...register(inputName)}
          type="checkbox"
          onClick={e => handleIsActive((e.target as HTMLInputElement).checked)}
          defaultChecked={isChecked}
        />
      </StyledCheckboxContainer>
      <span>{text}</span>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: baseline;

  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #656565;
  font-size: 16px;
  width: 100%;
  margin-bottom: 15px;
`;

const StyledCheckboxContainer = styled.div`
  display: flex;
  margin: auto 0;
  margin-bottom: 5px;
`;

const StyledCheckbox = styled.input`
  height: 15px;
  width: 15px;
  margin: 0 12px 0 25px;
`;

export default Checkbox;
