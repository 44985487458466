import TextField from '@mui/material/TextField';
import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import { IAuthRequest, IBalance, ICurrency, IDataProductsWithdrawAuthorization } from 'model/agricultural-product-withdraw-authorization';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import productWithdrawAuthorizationService from 'services/agricultural/product-withdraw-authorization-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';
import GenericInputToTable from 'components/GenericInputToTable';
import { format } from 'date-fns';
import InputDateWithSetting from 'components/InputDateWithSetting';

interface IForm {
  harvest: string;
  product: string;
  authStartDate: string;
  authEndDate: string;
  observation: string;
  responsible: string;
  stocksWithAmount: { [x: number]: IAuthRequest };
}

const ProductWithdrawAuthorization = ({ t }: WithTranslation) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
  const history = useHistory();
  const [options, setOptions] = useState<IDataProductsWithdrawAuthorization[]>([]);
  const [harvestOptions, setHarvestOptions] = useState<string[]>([]);
  const [chosenHarvest, setChosenHarvest] = useState<string>();

  const [productOptions, setProductOptions] = useState<string[]>([]);
  const [chosenProduct, setChosenProduct] = useState<string>();

  const [available, setAvailable] = useState<number>();
  let [totalAuth, setTotalAuth] = useState<number>();

  const [balances, setBalances] = useState<IBalance[]>([]);
  const [stocksWithAmount, setStocksWithAmount] = useState<{ [x: number]: IAuthRequest }>();

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });
  const getProductInfos = async () => {
    productWithdrawAuthorizationService.getProductsOptions(getUserRegistration).then(response => {
      setHarvestOptions(response.map(item => item.CD_SAFRA_TEXT));
      setOptions(response);
    });
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      if (!selectedEndDate || selectedEndDate < date) {
        setSelectedEndDate(date);
      }
      setSelectedStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      if (!selectedStartDate || date < selectedStartDate) {
        setSelectedStartDate(date);
      }
      setSelectedEndDate(date);
    }
  };

  function setSaldoAut(
    Id: string,
    CD_FILIAL: number,
    CD_PESSOA: number,
    CD_PRODUTO: string,
    CD_EMPRESA: number,
    CD_PADRAO: number,
    CD_TIPAGEM: number,
    CD_CULTURA: number,
    CD_LOCAL_ARMAZENAGEM: number,
    CD_MUNICIPIO: number,
    NM_REDUZIDO: string,
    CD_TIPO_ROMANEIO: number,
    DS_TIPO_ROMANEIO: string,
    QT_SALDO: number
  ) {
    var elemento = document.getElementById(Id) as HTMLInputElement;
    if (elemento) {
      elemento.value = QT_SALDO.toString();
    }
    setStocksWithAmount({
      ...stocksWithAmount,
      [CD_FILIAL]: {
        CD_PESSOA: CD_PESSOA,
        CD_PRODUTO: CD_PRODUTO,
        CD_EMPRESA: CD_EMPRESA,
        CD_PADRAO: CD_PADRAO,
        CD_TIPAGEM: CD_TIPAGEM,
        CD_CULTURA: CD_CULTURA,
        CD_FILIAL: CD_FILIAL,
        CD_LOCAL_ARMAZENAGEM: CD_LOCAL_ARMAZENAGEM,
        CD_MUNICIPIO: CD_MUNICIPIO,
        NM_REDUZIDO: NM_REDUZIDO,
        CD_TIPO_ROMANEIO: CD_TIPO_ROMANEIO,
        DS_TIPO_ROMANEIO: DS_TIPO_ROMANEIO,
        QT_SALDO: QT_SALDO,
        QT_AUTORIZADO: QT_SALDO,
      } as IAuthRequest,
    });
  }

  useEffect(() => {
    getProductInfos();
  }, []);
  useEffect(() => {
    const element = document.getElementById('frmm');
    if (element) {
      element.scrollIntoView({ block: 'start' });
    }
  }, []);

  useEffect(() => {
    if (chosenHarvest) {
      const products = options.find(item => item.CD_SAFRA_TEXT === chosenHarvest)?.PRODUTOS?.map(item => item.DS_PRODUTO) ?? [];
      setProductOptions(products);
    }
  }, [chosenHarvest]);

  useEffect(() => {
    if (chosenProduct) {
      const balanceOptions =
        options.find(item => item.CD_SAFRA_TEXT === chosenHarvest)?.PRODUTOS?.find(item => item.DS_PRODUTO === chosenProduct)?.SALDOS ?? [];
      setBalances(balanceOptions);

      const ava = options
        .find(item => item.CD_SAFRA_TEXT === chosenHarvest)
        ?.PRODUTOS?.find(item => item.DS_PRODUTO === chosenProduct)?.DISP;
      setAvailable(ava);
    }
  }, [chosenProduct]);

  const schema = yup.object({
    harvest: yup.string().test('test-harvest', t('global.errorMessage.required'), () => chosenHarvest != null),
    product: yup.string().test('test-product', t('global.errorMessage.required'), () => chosenProduct != null),
    stocksWithAmount: yup.object().test('test-stocksWithAmount', t('global.errorMessage.required'), () => stocksWithAmount != null),
    authStartDate: yup.string().test('test-authStartDate', t('global.errorMessage.required'), () => selectedStartDate != null),
    authEndDate: yup.string().test('test-authEndDate', t('global.errorMessage.required'), () => selectedEndDate != null),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  const handleSubmit = async data => {
    if (stocksWithAmount && openTransferModal) {
      setDisableButton(true);
      const arrayOfStocks = Object.values(stocksWithAmount).filter(item => item.QT_AUTORIZADO && item.QT_AUTORIZADO > 0);

      // Imprime o objeto no console ao invés de chamar sendSalesAuthorization
      const processedData = arrayOfStocks.map(item => {
        return {
          ...item,
          CD_SAFRA: options.find(item => item.CD_SAFRA_TEXT === chosenHarvest)?.CD_SAFRA,
          OBSERVACAO: data.observation,
          RESPONSAVEL: data.responsible,
          DT_INICIO_AUT: selectedStartDate ? format(selectedStartDate, 'dd/MM/yyyy') : '',
          DT_FIM_AUT: selectedEndDate ? format(selectedEndDate, 'dd/MM/yyyy') : '',
        };
      });

      console.log(processedData);

      // Simula um atraso para demonstrar o comportamento do finally
      await new Promise(resolve => setTimeout(resolve, 1000)).finally(() => {
        setDisableButton(false);
      });
    } else {
      setOpenTransferModal(true);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} id="frmm">
          {openTransferModal && (
            <ModalContainer>
              <ModalShadow onClick={() => setOpenTransferModal(false)} />
              <CardModalContainer>
                <div style={{ padding: '10px' }}>
                  <TitleStyled>{t('productWithdrawAuthorization.confirmAuth')}</TitleStyled>
                  <SubtitleStyled>{t('productWithdrawAuthorization.subtitle')}</SubtitleStyled>
                  <div style={{ padding: '10px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <StyledTableSummary>
                      <tbody>
                        <tr>
                          <td>{t('agricultural.crop')}:</td>
                          <td>{chosenHarvest}</td>
                        </tr>
                        <tr>
                          <td>{t('agricultural.product')}:</td>
                          <td>{chosenProduct}</td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </StyledTableSummary>
                    <StyledTableSummary>
                      <thead>
                        <tr>
                          <th>{t('productWithdrawAuthorization.branch')}</th>
                          <th>{t('agricultural.quantity')} - KG</th>
                        </tr>
                      </thead>
                      {stocksWithAmount && (
                        <tbody>
                          {Object.values(stocksWithAmount)?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.NM_REDUZIDO}</td>
                              <td>{item.QT_AUTORIZADO}</td>
                            </tr>
                          ))}
                          <tr>
                            <td>
                              <b>{t('productWithdrawAuthorization.total')}:</b>
                            </td>
                            <td>
                              <b>
                                {Object.values(stocksWithAmount)
                                  .map(item => item.QT_AUTORIZADO)
                                  .reduce((acc, value) => {
                                    let sum = (acc ?? 0) + (value ?? 0);
                                    return sum;
                                  }, 0)}
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </StyledTableSummary>
                  </div>
                  <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <CancelButton
                      onClick={() => {
                        setOpenTransferModal(false);
                      }}
                      style={{ width: '145px', height: '40px' }}
                    >
                      {t('financial.cancel')}
                    </CancelButton>
                    <StyledButton
                      color="frisiaPrimary"
                      disabled={disableButton}
                      type="submit"
                      style={{ color: 'white', minWidth: '145px', height: '42px' }}
                    >
                      <span>{t('financial.confirm')}</span>
                    </StyledButton>
                  </div>
                </div>
              </CardModalContainer>
            </ModalContainer>
          )}
          <StyledPageTitleContainer margin={'10px 0 25px 0'} style={{ display: 'flex' }}>
            <MainPageTitle fontSize={20} text={t('agricultural.productWithdrawAuthorization')} />
            <StyledButton
              style={{ height: '42px', position: 'relative' }}
              color="orange"
              type="button"
              onClick={() => history.push('/agricola/consulta-autorizacao-de-vendas')}
            >
              <span>{t('agricultural.history')}</span>
            </StyledButton>
          </StyledPageTitleContainer>

          <StyledFiltersContainer>
            <StyledFilterContainer filterWidth={180}>
              <GenericDropdown
                label={t('agricultural.harvest')}
                placeholder={t('agricultural.selectHarvest')}
                listShowDropdown={harvestOptions}
                getSelectedValue={value => setChosenHarvest(value)}
                errorText={methods.formState.errors.harvest?.message}
              />
            </StyledFilterContainer>
            <StyledFilterContainer filterWidth={550}>
              <GenericDropdown
                label={t('agricultural.product')}
                placeholder={t('agricultural.selectProduct')}
                listShowDropdown={productOptions}
                getSelectedValue={value => setChosenProduct(value)}
                errorText={methods.formState.errors.product?.message}
              />
            </StyledFilterContainer>
          </StyledFiltersContainer>

          <WarningContainer>{t('productWithdrawAuthorization.warning')}</WarningContainer>

          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around' }}>
            <div>
              {chosenProduct && (
                <>
                  <MainPageTitle fontSize={14} text={t('agricultural.product')} />
                  <StyledTable style={{ width: '390px' }}>
                    <tbody>
                      <tr>
                        <td>{t('agricultural.cropValue')}:</td>
                        <td>{chosenHarvest}</td>
                      </tr>
                      <tr>
                        <td>{t('agricultural.product')}:</td>
                        <td>{chosenProduct}</td>
                      </tr>
                      <tr>
                        <td>{t('productWithdrawAuthorization.available')}:</td>
                        <td>{available?.toLocaleString('pt-BR')} KG</td>
                      </tr>
                    </tbody>
                  </StyledTable>
                </>
              )}
              <PriceContainer>
                <PriceTitle>{t('productWithdrawAuthorization.values')}:</PriceTitle>

                <StyledFilterContainer style={{ margin: '10px 15px' }}>
                  <StyledDateOptions>
                    <InputDateWithSetting
                      errorText={methods.formState.errors.authStartDate?.message}
                      label={t('productWithdrawAuthorization.authStartDate')}
                      setDate={date => handleStartDateChange(date as Date | null)}
                      date={selectedStartDate}
                    />
                  </StyledDateOptions>
                </StyledFilterContainer>
                <StyledFilterContainer style={{ margin: '10px 15px' }}>
                  <StyledDateOptions>
                    <InputDateWithSetting
                      errorText={methods.formState.errors.authEndDate?.message}
                      label={t('productWithdrawAuthorization.authEndDate')}
                      setDate={date => handleEndDateChange(date as Date | null)}
                      date={selectedEndDate}
                    />
                  </StyledDateOptions>
                </StyledFilterContainer>
                <div style={{ margin: '10px 0px' }}>{t('productWithdrawAuthorization.observation')} :</div>
                <StyledTextArea {...methods.register('observation')} />
                <div style={{ margin: '10px 0px' }}></div>
                <GenericInputToTable
                  {...methods.register('responsible')}
                  inputName="responsible"
                  label={t('productWithdrawAuthorization.responsible')}
                  placeholder={t('productWithdrawAuthorization.responsible')}
                  type={'text'}
                />
              </PriceContainer>
            </div>
            <CardContainer>
              {balances.length > 0 && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <MainPageTitle fontSize={14} style={{ width: '322px' }} text={t('agricultural.stocks')} />
                </div>
              )}
              {balances.map((item, index) => (
                <StyledTable key={index}>
                  <tbody>
                    <tr>
                      <td>{t('productWithdrawAuthorization.branch')}:</td>
                      <td>
                        {item.CD_FILIAL} - {item.NM_REDUZIDO}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('productWithdrawAuthorization.modality')}:</td>
                      <td>{item.DS_TIPO_ROMANEIO}</td>
                    </tr>
                    <tr>
                      <td>{t('productWithdrawAuthorization.balance')}:</td>
                      <td style={item.QT_SALDO == 0 ? { color: '#D96767' } : {}}>{item.QT_SALDO} KG</td>
                    </tr>
                    <tr>
                      <td></td>
                      <StyledButton
                        style={{
                          height: '55px',
                          position: 'relative',
                          marginLeft: '20px',
                          marginRight: '20px',
                          fontSize: '15px',
                          width: '150px',
                          lineHeight: '20px',
                        }}
                        color="frisiaSecondary"
                        type="button"
                        onClick={() => {
                          setSaldoAut(
                            `amount-${item.CD_FILIAL}`,
                            item.CD_FILIAL,
                            item.CD_PESSOA,
                            item.CD_PRODUTO,
                            item.CD_EMPRESA,
                            item.CD_PADRAO,
                            item.CD_TIPAGEM,
                            item.CD_CULTURA,
                            item.CD_LOCAL_ARMAZENAGEM,
                            item.CD_MUNICIPIO,
                            item.NM_REDUZIDO,
                            item.CD_TIPO_ROMANEIO,
                            item.DS_TIPO_ROMANEIO,
                            item.QT_SALDO
                          );
                        }}
                      >
                        <span>{t('productWithdrawAuthorization.totalAuth')}</span>
                      </StyledButton>
                    </tr>
                    <tr>
                      <td>{t('agricultural.quantity')} - KG:</td>
                      <td>
                        <StyledInputInTable
                          placeholder={'0'}
                          type="number"
                          id={`amount-${item.CD_FILIAL}`}
                          max={item.QT_SALDO}
                          onChange={e => {
                            setStocksWithAmount({
                              ...stocksWithAmount,
                              [item.CD_FILIAL]: {
                                CD_PESSOA: item.CD_PESSOA,
                                CD_PRODUTO: item.CD_PRODUTO,
                                CD_EMPRESA: item.CD_EMPRESA,
                                CD_PADRAO: item.CD_PADRAO,
                                CD_TIPAGEM: item.CD_TIPAGEM,
                                CD_CULTURA: item.CD_CULTURA,
                                CD_FILIAL: item.CD_FILIAL,
                                CD_LOCAL_ARMAZENAGEM: item.CD_LOCAL_ARMAZENAGEM,
                                CD_MUNICIPIO: item.CD_MUNICIPIO,
                                NM_REDUZIDO: item.NM_REDUZIDO,
                                CD_TIPO_ROMANEIO: item.CD_TIPO_ROMANEIO,
                                DS_TIPO_ROMANEIO: item.DS_TIPO_ROMANEIO,
                                QT_SALDO: item.QT_SALDO,
                                QT_AUTORIZADO: parseFloat(e.target.value),
                              } as IAuthRequest,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </StyledTable>
              ))}
              {methods.formState.errors.stocksWithAmount && (
                <StyledErrorMessage style={{ position: 'initial', width: '100%' }}>
                  {t('productWithdrawAuthorization.reportQuantity')}
                </StyledErrorMessage>
              )}
              <div style={{ width: '100%' }}>
                <StyledButton color="frisiaPrimary" style={{ maxWidth: '150px', maxHeight: '50px' }} type="submit">
                  <span>{t('agricultural.confirm')}</span>
                </StyledButton>
              </div>
            </CardContainer>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export const StyledTable = styled.table`
  text-align: left;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 20px 0px;
  margin-right: 20px;
  padding: 6px;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 300px;
  font-size: 16px;

  text-align: right;
  font-family: ${({ theme }) => theme.font.montserratBold};
  letter-spacing: 0px;
  color: #4e6ba7;
  opacity: 1;
  td,
  th {
    height: 41px;
    padding: 12px;
    text-align: center;
    padding-left: 23px;
  }
  td:first-child {
    text-align: left;
    font: normal normal normal 16px/19px Montserrat;
    font-family: ${({ theme }) => theme.font.montserratRegular};
    letter-spacing: 0.01px;
    color: #404040;
    opacity: 1;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  tr:hover {
    background-color: #ddd;
  }
  svg {
    vertical-align: initial;
  }
  thead > tr {
    color: #2a5299;
    font-size: 17px;
    text-transform: uppercase;
  }
`;

export const StyledTableSummary = styled.table`
  text-align: left;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 20px 0px;
  margin-right: 20px;
  padding: 6px;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  font-size: 16px;

  text-align: right;
  letter-spacing: 0px;
  opacity: 1;
  td,
  th {
    height: 41px;
    padding: 12px;
    text-align: center;
    padding-left: 23px;
  }
  tr:hover {
    background-color: #ddd;
  }
  svg {
    vertical-align: initial;
  }
  thead > tr {
    color: #2a5299;
    font-size: 17px;
    text-transform: uppercase;
  }
`;

const PriceContainer = styled.div`
  width: 390px;
  height: 500px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: start;
`;

const PriceTitle = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold};
  letter-spacing: 0.01px;
  color: #2a5299;
  opacity: 1;
  margin-bottom: 10px;
`;

const CardContainer = styled.div`
  justify-self: stretch;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  justify-content: space-evenly;
`;

const CardModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  min-width: 445px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  b {
    align-self: self-start;
    margin: 20px 0px;
  }
`;

const StyledTextArea = styled.textarea`
  width: 350px;
  height: 186px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
`;

const StyledInputInTable = styled.input`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
  /* border: 1px solid #5A5A5A; */
  border-radius: 4px;
  opacity: 1;
  width: 153px;
  height: 42px;
  background: none;
  text-align: center;
`;

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  justify-content: space-between;
`;

const WarningContainer = styled.div`
  margin: 10px 20px 20px 20px;
  padding: 5px;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
`;

const StyledInputText = styled(TextField)`
  width: 100%;
  div {
    font-family: ${({ theme }) => theme.font.montserratRegular};
    height: 53px;
    width: 100%;
    fieldset {
      border: 2px solid #4e6ba7 !important;
    }
  }
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary} !important;
    letter-spacing: 0.4px;
    font-size: 13.2px;
    line-height: 2.2em;
    padding-left: 7px;
  }
  input {
    font-size: 16px;
    color: #656565;
    &::placeholder {
      opacity: 1;
    }
  }
  label {
    z-index: 0;
  }
`;
const TitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const SubtitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const CancelButton = styled.button`
  width: 245px;
  height: 34px;
  font-size: 14px;
  letter-spacing: 0.02px;
  color: #d4a518;
  text-transform: uppercase;
  opacity: 1;
  border: #d4a518 2px solid;
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  cursor: pointer;
`;

const StyledDateOptions = styled.div`
  padding: auto;
  margin-left: -13px;
  div {
    max-width: 316px;
  }
`;

export default withTranslation()(ProductWithdrawAuthorization);
