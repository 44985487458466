import { AxiosResponse } from 'axios';
import { CustomerRegister } from 'model/customer-register';
import { sendLog } from 'shared/util/api-utils';
import { api } from './api';

export const register = () => {
  const registerFree = (customer: CustomerRegister): Promise<AxiosResponse<CustomerRegister>> => {
    sendLog('Registro de conta', `/account/register`, JSON.stringify(customer), 'post');
    return api.post<CustomerRegister>('/account/register', customer);
  };

  const checkIfEmailAlreadyRegistered = (email: string): Promise<AxiosResponse<boolean>> => {
    sendLog('Checar se e-mail já existe', `/account/check-email-availability?email=${email}`, JSON.stringify([]), 'get');
    return api.get<boolean>(`/account/check-email-availability?email=${email}`);
  };

  return {
    registerFree,
    checkIfEmailAlreadyRegistered,
  };
};

export default register();
