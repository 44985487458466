import MainPageTitle from 'components/MainPageTitle';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { HashLink } from 'react-router-hash-link';
import { useState } from 'react';

const RegistrationForm = ({ t }: WithTranslation) => {
  const categories = [
    { name: 'Visualizar Dados Pessoais', link: '/ficha-cadastral/dados-pessoais' },
    { name: 'Equipamentos', link: '/ficha-cadastral/equipamentos' },
    { name: 'Benfeitorias', link: '/ficha-cadastral/benfeitorias' },
    { name: 'Dados pessoais específicos', link: '/ficha-cadastral/contacts' },
    { name: 'Plantel', link: '/ficha-cadastral/plantel' },
    { name: 'Cadastro Ambiental', link: '/ficha-cadastral/propriedades-ambiental' },
  ];

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('mainMenu.registrationForm')} />
      </StyledPageTitleContainer>

      <StyledCardsContainer>
        {categories.map((item, index) => (
          <div key={index}>
            <HashLink to={item.link}>
              <CategoryCardContainer>
                <div>{item.name}</div>
              </CategoryCardContainer>
            </HashLink>
          </div>
        ))}
      </StyledCardsContainer>
    </>
  );
};

const StyledCardsContainer = styled.div`
  max-width: 1130px;
  margin: 37px auto 0 auto;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  div {
    margin: 6px;
  }
`;

export const CategoryCardContainer = styled.div`
  width: 257px;
  height: 105px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  color: #183c96;
  font-weight: bold;
  :hover {
    background-color: ${({ theme }) => theme.color.frisiaPrimary};
    color: #d7cc27;
  }
  div {
    font-size: 19px;
    letter-spacing: 0px;
    opacity: 1;
    width: 100%;
    text-align: center;
    :hover {
      background-color: #d8deeb;
      background-color: ${({ theme }) => theme.color.frisiaPrimary};
      color: #d7cc27;
    }
  }
`;

export default withTranslation()(RegistrationForm);
