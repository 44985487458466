import { AxiosResponse } from 'axios';
import { sendLog } from 'shared/util/api-utils';
import { apiCore } from './api';

export interface fundacaoResponse {
  ContentEncoding?: string;
  ContentType?: string;
  Data: {
    token: string;
  };
  JsonRequestBehavior?: number;
  MaxJsonLength?: string;
  RecursionLimit?: string;
}

export const fundacaoApi = () => {
  const getToken = (register: string): Promise<AxiosResponse<fundacaoResponse>> => {
    sendLog('Acesso fundação ABC', `/FundacaoABC?matricula=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<fundacaoResponse>(`/FundacaoABC?matricula=${register}`);
  };

  return {
    getToken,
  };
};

export default fundacaoApi();
