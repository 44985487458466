import milkMapApi from 'api/cattle/milk-map-api';
import ButtonDownload from 'components/ButtonDownload';
import CollapsibleTable from 'components/CollapsibleTable';
import GenericDropdown from 'components/GenericDropdown';
import GenericTableWhiteHeader from 'components/GenericTableWhiteHeader';
import Loading from 'components/loading/loading';
import MainPageTitle from 'components/MainPageTitle';
import WhiteDropdownFilter from 'components/WhiteDropdownFilter';
import { isValid, parse } from 'date-fns';
import ICollectPoint from 'model/collectPoint';
import { IMilkMap, ITank } from 'model/milkMap';
import { ITypeGenericTable } from 'model/type-table';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { dateList } from 'shared/util/date-utils';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import milkMapService from '../../../services/cattle/milk-map-service';

const MilkMap = ({ t }: WithTranslation) => {
  const datesFilter = dateList(true);
  const [collectPoint, setCollectPoint] = useState<ICollectPoint[]>([]);
  const [milkMapData, setMilkMapData] = useState<IMilkMap>();
  const [selectedTank, setSelectedTank] = useState<ITank>();
  const [allTanks, setAllTanks] = useState<ITank>({
    NR_TANQUE: 'TODOS',
    ANALISES: [],
  });
  const [summary, setSummary] = useState<ITypeGenericTable[]>([]);
  const [awards, setAwards] = useState<ITypeGenericTable[]>([]);
  const [reportLink, setReportLink] = useState<string>('');

  const [selectedDateFilter, setSelectedDateFilter] = useState<string>('');
  const [dateConvertedValidFormat, setDateConvertedValidFormat] = useState<Date>();
  const complementDateFilter = '01';
  const [selectedCollectionPointFilter, setSelectedCollectionPointFilter] = useState<ICollectPoint>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const correctWord = (word: string) => {
    if (word.includes('Producao')) {
      return word.replaceAll('Producao', 'Produção');
    }
    if (word.includes('Proteina')) {
      return word.replaceAll('Proteina', 'Proteína');
    }
    if (word.includes('Solido')) {
      return word.replaceAll('Solido', 'Sólido');
    }
    return word;
  };

  useEffect(() => {
    if (getUserRegistration != null) {
      milkMapService.getCollectPoint(getUserRegistration).then(setCollectPoint);
    }
  }, []);

  useEffect(() => {
    console.log('useEffect triggered');
    setDateConvertedValidFormat(parse(selectedDateFilter, 'dd/MM/yyyy', new Date()));

    if (selectedCollectionPointFilter != null && isValid(dateConvertedValidFormat) && getUserRegistration != null) {
      setIsLoading(true);

      milkMapService.getMilkMap(selectedCollectionPointFilter.NR_PONTO_COLETA, selectedDateFilter, getUserRegistration).then(response => {
        setMilkMapData(response);
        setSelectedTank(allTanks);

        if (response.Resumo.length > 0) {
          setSummary([
            {
              fieldIdentification: t('livestock.basePrice'),
              value: StringUtils.maskAmount(response.Resumo[response.Resumo.length - 1]?.PRECO_BASE),
            },
            {
              fieldIdentification: t('livestock.dailyProduction'),
              value: StringUtils.maskAmount(response.Resumo[response.Resumo.length - 1]?.PRODUCAO_DIARIA),
            },
            {
              fieldIdentification: t('livestock.productionMonth'),
              value: StringUtils.maskAmount(response.Resumo[response.Resumo.length - 1]?.PRODUCAO_MES),
            },
            {
              fieldIdentification: t('livestock.accumulatedProduction'),
              value: StringUtils.maskAmount(response.Resumo[response.Resumo.length - 1]?.PRODUCAO_ACUMULADA),
            },
            {
              fieldIdentification: t('livestock.productionBase'),
              value: StringUtils.maskAmount(response.Resumo[response.Resumo.length - 1]?.VALOR_BASE_PRODUCAO),
            },
            {
              fieldIdentification: t('livestock.totalProduction'),
              value: StringUtils.maskAmount(response.Resumo[response.Resumo.length - 1]?.VALOR_TOTAL_PRODUCAO),
            },
            {
              fieldIdentification: t('livestock.advanceAmount'),
              value: StringUtils.maskAmount(response.Resumo[response.Resumo.length - 1]?.VALOR_ADIANTAMENTO),
            },
            {
              fieldIdentification: t('livestock.finalPriceLiter'),
              value: StringUtils.maskAmount(response.Resumo[response.Resumo.length - 1]?.PRECO_LITRO),
            },
          ]);

          const formattedRewardsTableData = response.Resumo[response.Resumo.length - 1].DADOS?.map(data => ({
            fieldIdentification: correctWord(data.DS_TIPO_DESCONTO),
            value: StringUtils.maskAmount(data.VL_RESULTADO),
          }));

          formattedRewardsTableData?.push({
            fieldIdentification: t('livestock.totalAwards'),
            value: StringUtils.maskAmount(response.Resumo[response.Resumo.length - 1].TOTAL_PREMIACAO),
          });

          formattedRewardsTableData?.push({
            fieldIdentification: '',
            value: StringUtils.maskAmount(response.Resumo[response.Resumo.length - 1].COTA_A2A2),
          });

          setAwards(formattedRewardsTableData ?? []);
        }

        setIsLoading(false);
      });
    }
  }, [selectedCollectionPointFilter, selectedDateFilter]);

  const getMilkMapReport = () => {
    if (selectedCollectionPointFilter != null && isValid(dateConvertedValidFormat) && getUserRegistration != null) {
      const reportLinkPath = milkMapApi.getMilkMapReport(
        selectedCollectionPointFilter.NR_PONTO_COLETA,
        selectedDateFilter,
        getUserRegistration
      );

      setReportLink(reportLinkPath);
    }
  };

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const averagesAnalysisTableHeader = [t('table.analysisType'), t('table.averagePayment'), t('table.averagePool')];
  const awardsTableHeader = [t('table.indicator'), t('table.award')];

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('livestock.milkMap')} />
      </StyledPageTitleContainer>

      <StyledFiltersContainer>
        <StyledFilterContainer filterWidth={329}>
          <GenericDropdown
            label={t('livestock.filterDate')}
            placeholder={t('livestock.filterSelectDate')}
            listShowDropdown={datesFilter}
            getSelectedValue={value => {
              setSelectedDateFilter(`${complementDateFilter}/${value}`);
            }}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={445}>
          <GenericDropdown
            label={t('livestock.collectPoint')}
            placeholder={t('livestock.filterSelectCollectPoint')}
            listShowDropdown={collectPoint}
            keyToShowInDropdown="DS_DESCRICAO"
            getSelectedValue={setSelectedCollectionPointFilter}
          />
        </StyledFilterContainer>

        {milkMapData != null && (
          <StyledButtonDownloadContainer onClick={() => getMilkMapReport()}>
            <ButtonDownload href={reportLink} />
          </StyledButtonDownloadContainer>
        )}
      </StyledFiltersContainer>

      {isLoading ? (
        <StyledLoadingContainer>
          <Loading />
        </StyledLoadingContainer>
      ) : (
        <StyledTablesContainer>
          {milkMapData?.Analises?.Medias != null && milkMapData.Analises.Medias.length > 0 && (
            <StyledTablesLeftContainer>
              <StyledPageTitleContainer margin={'25px 0 14px 0'}>
                <MainPageTitle fontSize={16} text={t('livestock.averageStatementAnalysis')} />
              </StyledPageTitleContainer>
              <GenericTableWhiteHeader
                headerItems={averagesAnalysisTableHeader}
                tableItems={milkMapData.Analises.Medias.map(averages => ({
                  typeOfAnalysis: averages.DS_REDUZIDA,
                  resultMap: StringUtils.maskAmount(averages.RESULTADO_MAPA),
                  mediaPool: StringUtils.maskAmount(averages.RESULTADO_POOL),
                }))}
              />

              <StyledPageTitleContainer margin={'25px 0 14px 0'}>
                <MainPageTitle fontSize={16} text={selectedCollectionPointFilter?.DS_DESCRICAO ?? ''} />
              </StyledPageTitleContainer>
              <GenericTableWhiteHeader isInvertLineColor tableItems={summary} />

              {milkMapData?.Resumo != null && milkMapData?.Resumo?.length > 0 && (
                <>
                  <StyledPageTitleContainer margin={'50px 0 14px 0'} style={{ width: '100%', maxWidth: '570px' }}>
                    <MainPageTitle fontSize={16} text={t('livestock.awards')} />
                  </StyledPageTitleContainer>
                  <GenericTableWhiteHeader headerItems={awardsTableHeader} tableItems={awards} />
                </>
              )}
            </StyledTablesLeftContainer>
          )}

          <StyledTablesRightContainer>
            {selectedTank != null && (
              <>
                <StyledPageTitleContainer margin={'25px 0 14px 0'} style={{ width: '100%', maxWidth: '570px' }}>
                  <MainPageTitle fontSize={16} text={`${t('livestock.tankIdentification')}${selectedTank?.NR_TANQUE}`} />
                </StyledPageTitleContainer>

                <StyledPageTitleContainer margin={'0 0 14px 0'} style={{ width: '100%', maxWidth: '570px' }}>
                  <WhiteDropdownFilter
                    listShowDropdown={milkMapData ? [...milkMapData.Analises.Tanques, allTanks] : []}
                    placeholder={t('livestock.changeTank')}
                    keyToShowInDropdown="NR_TANQUE"
                    getSelectedValue={setSelectedTank}
                  />
                </StyledPageTitleContainer>

                <StyleCollapsibleTableContainer>
                  {selectedTank?.ANALISES.map((analysis, index) => (
                    <CollapsibleTable
                      headerItems={[analysis.DS_REDUZIDA, '', '']}
                      analysisDate={analysis.DETALHES[analysis.DETALHES.length - 1].MES_ANALISE}
                      tableItems={analysis.DETALHES[analysis.DETALHES.length - 1].LINHAS.map(line => ({
                        period: line.DEZENA,
                        liters: `${line.QT_LITROS_PERIODO} L`,
                        resultTen: line.RESULTADO_DEZENA,
                      }))}
                      key={`tabel-collapsible-${index}`}
                      isInvertLineColor
                    />
                  ))}
                </StyleCollapsibleTableContainer>
              </>
            )}

            {selectedTank?.NR_TANQUE == 'TODOS' &&
              milkMapData?.Analises.Tanques.map((tank, index) => (
                <>
                  <StyledPageTitleContainer margin={'25px 0 14px 0'} style={{ width: '100%', maxWidth: '570px' }}>
                    <MainPageTitle fontSize={16} text={`${t('livestock.tankIdentification')}${tank?.NR_TANQUE}`} />
                  </StyledPageTitleContainer>

                  <StyleCollapsibleTableContainer>
                    {tank?.ANALISES.map((analysis, index) => (
                      <CollapsibleTable
                        headerItems={[analysis.DS_REDUZIDA, '', '']}
                        analysisDate={analysis.DETALHES[analysis.DETALHES.length - 1].MES_ANALISE}
                        tableItems={analysis.DETALHES[analysis.DETALHES.length - 1].LINHAS.map(line => ({
                          period: line.DEZENA,
                          liters: `${line.QT_LITROS_PERIODO} L`,
                          resultTen: line.RESULTADO_DEZENA,
                        }))}
                        key={`tabel-collapsible-${index}`}
                        isInvertLineColor
                      />
                    ))}
                  </StyleCollapsibleTableContainer>
                </>
              ))}
          </StyledTablesRightContainer>
        </StyledTablesContainer>
      )}
    </>
  );
};

const StyledFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  row-gap: 30px;
  flex-wrap: wrap;
`;

const StyledButtonDownloadContainer = styled.div`
  margin-top: -17px;
`;

const StyledTablesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  flex-wrap: wrap;
`;

const StyledTablesLeftContainer = styled.div`
  width: 49%;
  min-width: 375px;
`;

const StyledTablesRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  width: 49%;
  min-width: 375px;
`;

const StyleCollapsibleTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  row-gap: 16px;
  width: 100%;
`;

const StyledLoadingContainer = styled.div`
  position: absolute;
  left: 55%;
  top: 55%;
`;

export default withTranslation()(MilkMap);
