import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import FinancialApi from 'api/financial-api';
import ButtonDownload from 'components/ButtonDownload';
import GenericDropdown from 'components/GenericDropdown';
import InputDateWithSetting from 'components/InputDateWithSetting';
import MainPageTitle from 'components/MainPageTitle';
import dayjs from 'dayjs';
import { Organizations } from 'model/financial';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import FinancialService from 'services/financial-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { ReactComponent as ArrowDownSvg } from '../../images/frisia/icons/yellow-icon-arrow-down.svg';

const useStyles = makeStyles((theme?: any) => ({
  paper: {
    backgroundColor: '#e6eaf2 !important',
    fontSize: '18px !important',
    transition: '2s all ease !important',
  },
}));

const MonthlyTransaction = ({ t }: WithTranslation) => {
  const [showWarning, setShowMiniWarning] = useState(false);
  const classes = useStyles();
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);

  const [selectedStartDateFilter, setSelectedStartDateFilter] = useState<Date | null>(null);
  const [selectedEndDateFilter, setSelectedEndDateFilter] = useState<Date | null>(null);
  const [organizationName, setOrganizationName] = useState<string>('All');
  const [productType, setProductType] = useState<string>('All');
  const [organizationNameList, setOrganizationNameList] = useState<Organizations[]>([]);
  const [plate, setplate] = useState<string>('ALL');

  const [reportUrl, setReportUrl] = useState<string>();

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  useEffect(() => {
    if (selectedStartDateFilter && selectedEndDateFilter) {
      let start = moment(selectedStartDateFilter);
      let end = moment(selectedEndDateFilter);
      if (end.diff(start, 'days') > 59) {
        setShowMiniWarning(true);
      } else {
        setShowMiniWarning(false);
      }
    }
  }, [selectedStartDateFilter, selectedEndDateFilter]);

  useEffect(() => {
    if (selectedStartDateFilter !== null && selectedEndDateFilter !== null && organizationName) {
      let start = dayjs(selectedStartDateFilter).format('YYYY-MM-DD');
      let end = dayjs(selectedEndDateFilter).format('YYYY-MM-DD');
      let orgName = organizationNameList.find(item => item.NOME === organizationName)?.CODIGO;
      let plateName = plate;
      let prodType = productType;
      if (organizationName === 'TODAS') {
        orgName = 'ALL';
      }
      if (productType === 'TODOS') {
        prodType = 'ALL';
      }
      if (plate === 'TODAS') {
        plateName = 'ALL';
      }
      setReportUrl(
        FinancialApi.getMonthlyMovimentReport(
          start.toString(),
          end.toString(),
          getUserRegistration.toString(),
          orgName ?? '',
          plateName,
          prodType
        )
      );
    }
  }, [selectedStartDateFilter, selectedEndDateFilter, organizationName, plate, productType]);

  const [loadingOrganizations, setloadingOrganizations] = useState<boolean>(false);

  useEffect(() => {
    setloadingOrganizations(true);
    FinancialService.getOrganizations().then(response => {
      setOrganizationNameList(response);
      setloadingOrganizations(false);
    });
  }, []);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('financial.monthlyTransaction')} />
      </StyledPageTitleContainer>
      <FiltersContainer>
        <StyledFiltersDateContainer>
          <InputDateWithSetting date={selectedStartDateFilter} label={t('livestock.startFilter')} setDate={setSelectedStartDateFilter} />
          <InputDateWithSetting date={selectedEndDateFilter} label={t('livestock.endFilter')} setDate={setSelectedEndDateFilter} />
          <InputsContainer>
            <StyledFilterContainer filterWidth={150}>
              <StyledInputText
                inputProps={{ disableUnderline: true }}
                label={t('financial.plate')}
                onChange={e => setplate(e.target.value)}
                placeholder="Ex: AAAA9999"
                variant="outlined"
                type={'text'}
              />
            </StyledFilterContainer>
            <StyledFilterContainer filterWidth={250}>
              <StyledSearch>
                <Autocomplete
                  style={{ flex: '1' }}
                  id="custom-input-demo"
                  autoComplete
                  options={['TODAS', ...organizationNameList.map(item => item.NOME)]}
                  classes={{ paper: classes.paper }}
                  defaultChecked
                  onFocus={e => setIsShowDropdown(true)}
                  onBlur={e => setIsShowDropdown(false)}
                  onChange={(e, value) => {
                    value && setOrganizationName(value);
                  }}
                  noOptionsText={loadingOrganizations && t('global.field.loading')}
                  renderInput={params => (
                    <TextField
                      label={t('financial.organization')}
                      variant="standard"
                      focused
                      {...params}
                      placeholder={t('agricultural.allFemale')}
                    />
                  )}
                />
                <StyledIconContainer className={`${isShowDropdown ? 'rotate' : ''}`}>
                  <ArrowDownSvg />
                </StyledIconContainer>
              </StyledSearch>
            </StyledFilterContainer>
            <StyledFilterContainer
              filterWidth={200}
              style={{
                visibility: organizationName.startsWith('LOJA') ? 'visible' : 'hidden',
              }}
            >
              <GenericDropdown
                label={t('financial.productType')}
                defaultValue="TODOS"
                listShowDropdown={['TODOS', 'MEDICAMENTOS', 'PECAS']}
                getSelectedValue={value => setProductType(value)}
              />
            </StyledFilterContainer>
          </InputsContainer>
        </StyledFiltersDateContainer>
      </FiltersContainer>
      {showWarning && <p>{t('financial.dateWarning')}</p>}
      {reportUrl && (
        <>
          <ButtonDownload style={{ marginLeft: 'auto' }} href={reportUrl} />
          <iframe
            sandbox="allow-scripts allow-popups allow-forms allow-top-navigation"
            style={{ width: '100%', height: '800px' }}
            src={reportUrl}
          />
        </>
      )}
      {!reportUrl && (
        <WarningContainer>
          <div>!</div>
          {t('financial.filterWarning')}
        </WarningContainer>
      )}
    </>
  );
};

const StyledFiltersDateContainer = styled.div`
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InputsContainer = styled.div`
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  max-width: 830px;
  height: 78px;
  width: 640px;
`;

const WarningContainer = styled.div`
  top: 444px;
  left: 602px;
  height: 39px;
  text-align: left;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  font-size: 32px;
  letter-spacing: 0px;
  color: #656565;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
  z-index: -1;
  div {
    border-radius: 50%;
    top: 427px;
    left: 502px;
    width: 72px;
    height: 72px;
    border: 3px solid #707070;
    font-size: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }
`;

const StyledSearch = styled.div`
  display: flex;
  position: absolute;
  min-height: 53px;
  min-width: 100%;
  text-align: left;
  border: 2px solid #4e6ba7;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f1f1f1;
  padding-left: 9px;
  label {
    transform: translate(0, 3.5px) scale(0.75);
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: #2a5299 !important;
  }
  button {
    margin-right: -37px;
    margin-top: -19px;
    height: 52px;
    border-radius: 0px;
    width: 45px;
  }
  .MuiAutocomplete-root {
    svg {
      visibility: hidden;
      z-index: 10;
    }
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    padding-right: 0px;
  }
`;

export const StyledInputText = styled(TextField)`
  div {
    font-family: ${({ theme }) => theme.font.montserratRegular};
    height: 53px;
    max-width: 200px;
    fieldset {
      border: 2px solid #4e6ba7 !important;
    }
  }
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary} !important;
    letter-spacing: 0.4px;
    font-size: 13.2px;
    line-height: 2.2em;
    padding-left: 7px;
  }
  input {
    font-size: 16px;
    color: #656565;
    &::placeholder {
      opacity: 1;
    }
  }
`;

export const StyledIconContainer = styled.div`
  margin-right: 9px;
  align-self: center;
  margin-left: 3px;
  @keyframes arrow_animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  &.rotate {
    animation: arrow_animation 0.3s 0s;
    transform: rotate(180deg);
  }
`;

export default withTranslation()(MonthlyTransaction);
