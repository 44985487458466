import { AxiosResponse } from 'axios';
import { Search } from 'model/enums/search';
import { SearchResult } from 'model/search-result';
import { api } from './api';

export const searchApi = () => {
  const search = (params: Search): Promise<AxiosResponse<SearchResult[]>> => {
    const urlParams: string = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');

    return api.get<SearchResult[]>(`/general-search?${urlParams}`);
  };

  return {
    search,
  };
};

export default searchApi();
