import { apiTermosAutorizacao } from 'api/api';
import { AxiosResponse } from 'axios';
import { IMessage } from 'model/account-allow-pages';
import { IAuths, IAuthsTherme, INew, IFunctions } from 'model/fuel-authorization';
import { sendLog } from 'shared/util/api-utils';

export const fuelAuthorizationApi = () => {
  const getMyAuths = (register: string): Promise<AxiosResponse<IAuths[]>> => {
    return apiTermosAutorizacao.get<IAuths[]>(`/ConsultaExistente?cdPessoaAutorizador=${register}&dsTipoAutorizacao=CONVENIO_POSTO`);
  };

  const getFunctionsOptions = (): Promise<AxiosResponse<IFunctions[]>> => {
    sendLog('Consultando opções de funcoes autorizaveis', `/Cooperado/GetFuncAutorizaveis`, JSON.stringify({}), 'get');
    return apiTermosAutorizacao.get<IFunctions[]>(`/Cooperado/GetFuncAutorizaveis`);
  };

  const getAcepetTherme = (dstipoautorizacao: string): Promise<AxiosResponse<IAuthsTherme>> => {
    sendLog('Consultando opções de consultores disponiveis', `/PreVisualizarTermos`, JSON.stringify({}), 'get');
    return apiTermosAutorizacao.get<IAuthsTherme>(`/PreVisualizarTermos?dsTipoAutorizacao=${dstipoautorizacao}`);
  };

  const authNew = (newAut: INew): Promise<AxiosResponse<IMessage>> => {
    sendLog('Autorizando novo desconto em postos', `/Emitir`, JSON.stringify({}), 'post');
    return apiTermosAutorizacao.post<IMessage>(`/Emitir`, newAut);
  };

  return {
    getMyAuths,
    getFunctionsOptions,
    getAcepetTherme,
    authNew,
  };
};

export default fuelAuthorizationApi();
