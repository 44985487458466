import milkAnalysisApi from 'api/cattle/milk-analysis-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { IMilkAnalysis } from 'model/milk-analysis';

export const milkAnalysisService = () => {
  const getMilkCollections = async (filterDate: string, registration: number): Promise<IMilkAnalysis[]> => {
    try {
      const result = await milkAnalysisApi.getMilkAnalysis(filterDate, registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getMilkCollections,
  };
};

export default milkAnalysisService();
