import styled from 'styled-components';

interface IStyledProp {
  margin: string;
}

const StyledPageTitleContainer = styled.div<IStyledProp>`
  margin: ${({ margin }) => margin};
`;

export default StyledPageTitleContainer;
