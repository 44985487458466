import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';

interface IProps {
  inputName: string;
  label: string;
  defaultValue: string;
  isReadOnly?: boolean;
  errorText?: string;
  withBorder?: boolean;
}

interface IStyledProps {
  readOnly?: boolean;
  isErrorStatus?: boolean;
}

const TextInputCadastralSheet = ({ inputName, label, defaultValue, isReadOnly, errorText, withBorder }: IProps) => {
  const { register } = useFormContext();

  const [value, setValue] = useState<string>(defaultValue);

  return (
    <StyledContainer isErrorStatus={errorText != null}>
      <StyledLabel htmlFor={`inputId${inputName}`}>{label}</StyledLabel>
      <StyledInput
        {...register(inputName)}
        type="text"
        id={`inputId${inputName}`}
        value={value}
        maxLength={255}
        onChange={e => setValue(e.target.value)}
        readOnly={isReadOnly}
        style={
          withBorder
            ? {
                border: '1px solid #2A5299',
                borderRadius: '6px',
                padding: '0px 10px',
              }
            : {}
        }
      />
      <StyledErrorMessage verticalPosition="-17px">{errorText}</StyledErrorMessage>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<IStyledProps>`
  display: flex;
  width: 100%;
  position: relative;

  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 16px;
  border-bottom: ${({ theme, isErrorStatus }) => (isErrorStatus ? '1px solid red' : `1px solid ${theme.color.frisiaPrimary}`)};
  text-align: left;
  margin: 15px 0;
`;

const StyledLabel = styled.label`
  margin-right: 10px;
  white-space: nowrap;
`;

const StyledInput = styled.input<IStyledProps>`
  width: 100%;
  border: none;
  color: #787878;
  font-size: 14px;
  padding: 0;
`;

export default TextInputCadastralSheet;
