import { Grid } from '@mui/material';
import forestApi from 'api/forest-api';
import GenericDropdown from 'components/GenericDropdown';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import Loading from 'components/loading/loading';
import MainPageTitle from 'components/MainPageTitle';
import SummaryTableProduct from 'components/SummaryTableProduct';
import { IBoard, IForestData, IProduct, ITypeFilter } from 'model/forest';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import forestService from 'services/forest-service';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

enum TypeFilter {
  ALL_PRODUCTS = 'TODOS OS PRODUTOS',
  ALL_PLATES = 'TODAS AS PLACAS',
}

const Forest = ({ t }: WithTranslation) => {
  const [dataFilters, setDataFilters] = useState<ITypeFilter[]>([]);
  const [forestData, setForestData] = useState<IForestData>();

  const [selectedCrop, setSelectedCrop] = useState<ITypeFilter | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const [selectedBoard, setSelectedBoard] = useState<IBoard | null>(null);
  const [isResetFilterProduct, setIsResetFilterProduct] = useState<boolean>(false);
  const [isResetFilterBoard, setIsResetFilterBoard] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userRegistration != null) {
      forestService.getDataFilters(userRegistration).then(setDataFilters);
    }
  }, []);

  useEffect(() => {
    if (userRegistration != null && selectedCrop != null && selectedProduct != null && selectedBoard != null) {
      setIsLoading(true);

      forestService
        .getForestData(userRegistration, selectedCrop.CD_SAFRA, selectedProduct.CD_PRODUTO, selectedBoard.CD_PLACA)
        .then(response => {
          setForestData(response);
          setIsLoading(false);
        });
    }
  }, [selectedBoard]);

  const handleGenerateReport = () => {
    if (userRegistration != null && selectedCrop != null && selectedProduct != null && selectedBoard != null) {
      window.open(
        forestApi.getForestReport(userRegistration, selectedCrop.CD_SAFRA, selectedProduct.CD_PRODUTO, selectedBoard.CD_PLACA),
        '_blank'
      );
    }
  };

  const tableHeader = [t('table.plate'), t('table.grossWeight'), t('table.netWeight')];

  const userRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const handleSelectCrop = (value: ITypeFilter) => {
    setSelectedBoard(null);
    setSelectedProduct(null);
    setIsResetFilterProduct(true);
    setIsResetFilterBoard(true);
    setSelectedCrop(value);
  };

  const handleSelectProduct = (value: IProduct) => {
    setSelectedProduct(null);
    setSelectedBoard(null);
    setIsResetFilterProduct(false);
    setIsResetFilterBoard(true);

    if (value.DS_PRODUTO === TypeFilter.ALL_PRODUCTS) {
      value.PLACAS = [];
      const allPlates: IBoard[] = [] as IBoard[];

      selectedCrop?.PRODUTOS.map(product => {
        if (product.PLACAS != null) {
          product.PLACAS.map(plate => {
            !allPlates.map(plateData => plateData.CD_PLACA).includes(plate.CD_PLACA) && allPlates.push(plate);
          });
        }
      });

      value.PLACAS = allPlates;
    }

    if (value?.PLACAS != null && !value.PLACAS.map(plate => plate.CD_PLACA_TEXT).includes(TypeFilter.ALL_PLATES)) {
      value.PLACAS = [{ CD_PLACA: '0', CD_PLACA_TEXT: TypeFilter.ALL_PLATES }, ...value.PLACAS];
    }

    setSelectedProduct(value);
  };

  const handleSelectBoard = (value: IBoard) => {
    setIsResetFilterBoard(false);
    setSelectedBoard(value);
  };

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('card.forestry')} />
      </StyledPageTitleContainer>
      <StyledFiltersContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('global.field.filterYear')}
            placeholder={t('global.field.selectHere')}
            listShowDropdown={dataFilters}
            keyToShowInDropdown="CD_SAFRA_TEXT"
            getSelectedValue={handleSelectCrop}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={350}>
          <GenericDropdown
            isResetValue={isResetFilterProduct}
            label={t('global.field.product')}
            placeholder={t('global.field.selectHere')}
            listShowDropdown={selectedCrop?.PRODUTOS ?? []}
            keyToShowInDropdown="DS_PRODUTO"
            getSelectedValue={handleSelectProduct}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            isResetValue={isResetFilterBoard}
            label={t('global.field.board')}
            placeholder={t('global.field.selectHere')}
            listShowDropdown={selectedProduct?.PLACAS ?? []}
            keyToShowInDropdown="CD_PLACA_TEXT"
            getSelectedValue={handleSelectBoard}
          />
        </StyledFilterContainer>
      </StyledFiltersContainer>

      {isLoading ? (
        <StyledLoadingContainer>
          <Loading />
        </StyledLoadingContainer>
      ) : (
        <>
          {forestData != null && (
            <>
              <Grid container spacing="20px">
                <Grid item lg={6}>
                  <SummaryTableProduct tableData={forestData.ResumoProdutos} />
                </Grid>
                <Grid item lg={6}>
                  <GenericTableBlueHeader
                    headerItems={tableHeader}
                    tableItems={forestData.ResumoPlaca.map(plate => ({
                      plate: plate.CD_PLACA,
                      grossWeight: StringUtils.maskAmount(plate.QT_PESO_BRUTO),
                      netWeight: StringUtils.maskAmount(plate.QT_PESO_LIQUIDO),
                    }))}
                    isInvertLineColor
                    style={{ marginLeft: 'auto' }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

const StyledFiltersContainer = styled.div`
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const StyledGraphicContainer = styled.div`
  height: 450px;
  background-color: white;
  padding: 30px;
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px #15223214;
`;

const StyledTiltleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled.span`
  font-family: ${({ theme }) => theme.font.poppinsSemiBold};
  color: #131523;
  font-size: 17px;
`;

const StyledButton = styled.button`
  width: 120px;
  height: 35px;
  border: 1px solid #d7dbec;
  border-radius: 4px;
  background-color: white;
  color: #5a607f;
  font-family: ${({ theme }) => theme.font.poppinsRegular};
  font-size: 14px;

  svg {
    margin-right: 8px;
  }
`;

const StyledLoadingContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 350px;
`;

export default withTranslation()(Forest);
