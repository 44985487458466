import { useEffect, useState, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';
import { ReactComponent as ArrowDownSvg } from '../images/frisia/icons/yellow-icon-arrow-down.svg';

interface IProps<T> {
  label?: string;
  placeholder?: string;
  listShowDropdown: T[];
  errorText?: FieldError | string;
  isResetValue?: boolean;
  keyToShowInDropdown?: string;
  className?: string;
  disabled?: boolean;
  defaultValue?: T | string;
  getSelectedValue: (value: T) => void;
  white?: boolean;
  displayKey?: string; // Adicione displayKey aqui
}

interface IStyledContainerProps {
  isShowDropdown: boolean;
  white?: boolean;
}

const GenericDropdown = <T,>({
  label,
  placeholder,
  listShowDropdown,
  errorText,
  isResetValue,
  keyToShowInDropdown,
  displayKey,
  className,
  disabled,
  defaultValue,
  getSelectedValue,
  white,
}: IProps<T>) => {
  const [selectedItem, setSelectedItem] = useState<T | string>(defaultValue || placeholder || '');
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        handleDropdownClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isResetValue) {
      setSelectedItem(placeholder || '');
    } else if (defaultValue != null) {
      setSelectedItem(defaultValue);
    }
  }, [isResetValue, defaultValue]);

  useEffect(() => {
    if (selectedItem !== placeholder) {
      getSelectedValue(selectedItem as T);
    }
  }, [selectedItem, placeholder, getSelectedValue]);

  const handleItemClick = (item: T) => {
    setSelectedItem(item);
    handleDropdownClose(); // Fecha o dropdown e reseta o item selecionado
  };

  const handleDropdownClose = () => {
    setIsShowDropdown(false);
  };

  const handleDropdownToggle = () => {
    if (!disabled) {
      setIsShowDropdown(!isShowDropdown);
    }
  };

  const isObject = (item: any): item is Record<string, any> => {
    return typeof item === 'object' && item !== null;
  };

  const displayText = () => {
    if (isObject(selectedItem) && displayKey) {
      // Acessa a propriedade do objeto usando displayKey se selectedItem for um objeto
      const displayValue = (selectedItem as Record<string, any>)[displayKey];
      return displayValue !== undefined ? displayValue : ''; // Retorna o valor ou uma string vazia
    }

    // Se selectedItem não for um objeto ou displayKey não estiver definido, trata como string ou retorna placeholder
    return typeof selectedItem === 'string' ? selectedItem : placeholder || '';
  };

  return (
    <StyledContainer ref={containerRef} className={className}>
      <StyledDiv white={white} onClick={handleDropdownToggle} isShowDropdown={isShowDropdown} tabIndex={0}>
        <StyledInnerContainer hasLabel={!!label}>
          {label && <StyledLabel>{label}</StyledLabel>}
          <StyledText isPlaceholder={selectedItem === placeholder}>{displayText()}</StyledText>
          <StyledIconContainer isShowDropdown={isShowDropdown}>
            <ArrowDownSvg />
          </StyledIconContainer>
        </StyledInnerContainer>

        <div ref={dropdownRef}>
          {isShowDropdown && (
            <StyledList isShowDropdown={isShowDropdown}>
              {listShowDropdown.map((item, index) => (
                <StyledItem key={index} onClick={() => handleItemClick(item)}>
                  {displayKey ? ((item as any)[displayKey] as string) : (item as unknown as string)}
                </StyledItem>
              ))}
            </StyledList>
          )}
        </div>
      </StyledDiv>

      {errorText && <StyledErrorMessage>{errorText}</StyledErrorMessage>}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative; /* Garante que o z-index funcione para os filhos */
  height: 70px;
`;

const StyledDiv = styled.div<{ isShowDropdown: boolean; white?: boolean }>`
  position: relative;
  min-height: 53px;
  width: 100%;
  text-align: left;
  border: 2px solid #4e6ba7;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ white }) => (white ? 'white' : '#f1f1f1')};
  z-index: ${({ isShowDropdown }) => (isShowDropdown ? 1 : 0)}; /* z-index para o container do dropdown */
`;

const StyledInnerContainer = styled.div<{ hasLabel: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ hasLabel }) => (hasLabel ? '20px 10px 0 10px' : '13px 10px 0 10px')};
`;

const StyledLabel = styled.span`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 2;
`;

const StyledText = styled.div<{ isPlaceholder: boolean }>`
  overflow-y: auto;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: ${({ isPlaceholder }) => (isPlaceholder ? '#656565' : '#000')};
  font-size: ${({ isPlaceholder }) => (isPlaceholder ? '12px' : '16px')};
  opacity: ${({ isPlaceholder }) => (isPlaceholder ? 0.75 : 1)};
  margin-top: ${({ isPlaceholder }) => (isPlaceholder ? '10px' : '0')};
`;

const StyledIconContainer = styled.div<{ isShowDropdown: boolean }>`
  margin-left: 3px;
  transition: transform 0.3s;
  transform: ${({ isShowDropdown }) => (isShowDropdown ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const StyledList = styled.ul<{ isShowDropdown: boolean }>`
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #656565;
  font-size: 16px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  max-height: 200px;
  border: 1px solid #4e6ba7;
  border-top: none;
  position: absolute;
  width: 100%;
  top: 100%;
  background-color: white;
  z-index: 9999; /* Define um z-index alto para o dropdown */
`;

const StyledItem = styled.li`
  max-width: 100%;
  border-bottom: 1px solid #4e6ba7;
  padding: 8px 10px;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const StyledErrorMessage = styled.span`
  position: absolute;
  bottom: 0;
  left: 1px;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: red;
  font-size: 12px;
`;

export default GenericDropdown;
