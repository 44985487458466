import { AxiosResponse } from 'axios';
import bcrypt from 'bcryptjs';
import md5 from 'md5';
import IMilkCollections from 'model/milk-collections';
import { sendLog } from 'shared/util/api-utils';
import StringUtils from 'shared/util/string-utils';
import { apiCore } from '../api';

export const milkCollectionsApi = () => {
  const getMilkCollections = (filterDate: string, registration: number): Promise<AxiosResponse<IMilkCollections[]>> => {
    sendLog(
      'Consultando coletas do Leite',
      `/PecuariaLeite/Coletas_Leites/?cd_pessoa=${registration}&dt_entrega=${filterDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IMilkCollections[]>(`/PecuariaLeite/Coletas_Leites/?cd_pessoa=${registration}&dt_entrega=${filterDate}`);
  };

  const getCollectionsReport = (filterDate: string, registration: number) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/LEI/LEI0275&CD_PESSOA=${registration}&DT_ENTREGA=${filterDate}`;

    sendLog('Consultando relatorio coletas do Leite', path, JSON.stringify({}), 'get');

    const urlConvertCharcode = StringUtils.convertToCharCode(path);
    const md5EncryptedUrl = md5(urlConvertCharcode);
    const bcryptEncrypted = bcrypt.hashSync(md5EncryptedUrl);
    const charCodeUrlReportAuth = StringUtils.convertToCharCode(bcryptEncrypted);

    return `https://servicos.frisia.coop.br/RPT/Report/ReportTemplate?ReportId=${urlConvertCharcode}&ReportAuth=${charCodeUrlReportAuth}`;
  };

  return {
    getMilkCollections,
    getCollectionsReport,
  };
};

export default milkCollectionsApi();
