import { format, parse } from 'date-fns';
import { isEmpty } from 'lodash';
import IFilterCompare from 'model/filter-compare';
import { ICollect, IDiscountType, IMilkAnalysis } from 'model/milk-analysis';
import { useEffect, useState } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import milkAnalysisService from 'services/cattle/milk-analysis-service';
import { yearList } from 'shared/util/date-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import { ReactComponent as CheckedIconSvg } from '../images/frisia/icons/blue-checked-checkbox-icon.svg';
import { ReactComponent as UncheckedIconSvg } from '../images/frisia/icons/blue-unchecked-checkbox-icon.svg';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/white-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/white-plus-round-icon.svg';
import GenericDropdown from './GenericDropdown';
import MilkAnalysisChart from './MilkAnalysisChart';

interface IProps extends WithTranslation {
  title: string;
  collectionData: IDiscountType;
  selectedDateFilter: string;
}

const CollapsibleGraphic = ({ title, collectionData, selectedDateFilter, t }: IProps) => {
  const [isShowGraphic, setIsShowGraphic] = useState<boolean>(false);
  const [isShouldCompare, setIsShouldCompare] = useState<boolean>(false);
  const [filterCopare, setFilterCopare] = useState<string>('');
  const [dateCompare, setDateCompare] = useState<IFilterCompare>({ year: '', discountName: '' });
  const [milkAnalysisCompare, setMilkAnalysisCompare] = useState<IMilkAnalysis>();

  const [isTankSelected, setIsTankSelected] = useState<boolean>(false);
  const [selectedTank, setSelectedTank] = useState<number | string>();
  const [listTanks, setListTanks] = useState<Array<number | string>>(['Todos']);

  useEffect(() => {
    if (isShouldCompare && !isEmpty(filterCopare)) {
      setDateCompare({ year: filterCopare, discountName: title });
    } else {
      setDateCompare({ year: '', discountName: '' });
    }
  }, [isShouldCompare, filterCopare]);

  useEffect(() => {
    if (selectedTank === 'Todos') {
      setIsTankSelected(false);
    }
  }, [selectedTank]);

  useEffect(() => {
    if (isTankSelected === false) {
      setSelectedTank('Todos');
    }
  }, [isTankSelected]);

  useEffect(() => {
    if (!isEmpty(dateCompare.year) && getUserRegistration != null) {
      const dateConvertedValidFormat = parse(selectedDateFilter, 'dd/MM/yyyy', new Date());
      const formattedDate = format(dateConvertedValidFormat, 'dd/MM');
      milkAnalysisService
        .getMilkCollections(`${formattedDate}/${dateCompare.year}`, getUserRegistration)
        .then(response => setMilkAnalysisCompare(response[0]));
    }
  }, [dateCompare]);

  useEffect(() => {
    setListTanks(['Todos']);
    let tanks: Array<number | string> = ['Todos'];

    collectionData.COLETAS.map(collect => {
      if (tanks.indexOf(collect.NR_TANQUE) === -1) {
        tanks.push(collect.NR_TANQUE);
      }
    });

    milkAnalysisCompare?.TIPODESCONTO.filter(
      collectCompare =>
        collectCompare.DS_TIPO_DESCONTO === collectionData.DS_TIPO_DESCONTO && collectCompare.DS_TIPO_DESCONTO === dateCompare.discountName
    )?.[0]?.COLETAS.map(collect => {
      if (tanks.indexOf(collect.NR_TANQUE) === -1) {
        tanks.push(collect.NR_TANQUE);
      }
    });

    tanks = sortListTanks(tanks);
    setListTanks(tanks);
  }, [milkAnalysisCompare, collectionData.COLETAS]);

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const sortListByDate = (list?: ICollect[]) => {
    list?.sort((a, b) => parse(a.DT_COLETA, 'dd/MM/yyyy', new Date()).getTime() - parse(b.DT_COLETA, 'dd/MM/yyyy', new Date()).getTime());
    return list?.map(item => item);
  };

  const sortListTanks = (list: Array<number | string>) => {
    list?.sort();
    return list?.map(item => item);
  };

  const correctWord = (word: string) => {
    if (word.includes('PROTEINA')) {
      return word.replaceAll('PROTEINA', 'PROTEÍNA');
    }
    if (word.includes('SOLIDO')) {
      return word.replaceAll('SOLIDO', 'SÓLIDO');
    }
    return word;
  };

  return (
    <StyledContainer>
      <StyledHeader>
        {isShowGraphic ? (
          <MinusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
        ) : (
          <PlusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
        )}
        <Trans i18nKey="livestock.discount">
          <span>{{ text: correctWord(title) }}</span>
        </Trans>
      </StyledHeader>

      {isShowGraphic && (
        <StyledGraphicContainer>
          <StyledGraphicHeader>
            <StyledSpan>{t('global.field.result')}</StyledSpan>
            <StyledFilterMainContainer>
              <StyledText>{t('global.field.compare')}</StyledText>
              <StyledFilterContainer filterWidth={115} style={{ margin: '0 10px' }}>
                <GenericDropdown className="dropdown-container" listShowDropdown={yearList()} getSelectedValue={setFilterCopare} />
              </StyledFilterContainer>
              <StyledRadioButtonContainer>
                <StyleRadioButton onClick={() => setIsShouldCompare(true)}>
                  {t('global.field.yes')}
                  {isShouldCompare ? <CheckedIconSvg /> : <UncheckedIconSvg />}
                </StyleRadioButton>
                <StyleRadioButton onClick={() => setIsShouldCompare(false)}>
                  {t('global.field.no')}
                  {!isShouldCompare ? <CheckedIconSvg /> : <UncheckedIconSvg />}
                </StyleRadioButton>
              </StyledRadioButtonContainer>
            </StyledFilterMainContainer>
          </StyledGraphicHeader>
          <StyledFilterMainContainer style={{ justifyContent: 'end' }}>
            <StyledText>{t('livestock.changeTank')}</StyledText>
            <StyledFilterContainer filterWidth={115} style={{ margin: '0 10px' }}>
              <GenericDropdown
                className="dropdown-container"
                defaultValue={'Todos'}
                listShowDropdown={listTanks ?? []}
                getSelectedValue={value => setSelectedTank(value)}
              />
            </StyledFilterContainer>
            <StyledRadioButtonContainer>
              <StyleRadioButton onClick={() => setIsTankSelected(true)}>
                {t('global.field.yes')}
                {isTankSelected ? <CheckedIconSvg /> : <UncheckedIconSvg />}
              </StyleRadioButton>
              <StyleRadioButton onClick={() => setIsTankSelected(false)}>
                {t('global.field.no')}
                {!isTankSelected ? <CheckedIconSvg /> : <UncheckedIconSvg />}
              </StyleRadioButton>
            </StyledRadioButtonContainer>
          </StyledFilterMainContainer>

          <MilkAnalysisChart
            collectionData={
              sortListByDate(collectionData.COLETAS.filter(collect => (isTankSelected ? collect.NR_TANQUE === selectedTank : true))) ?? []
            }
            collectionDataCompare={sortListByDate(
              milkAnalysisCompare?.TIPODESCONTO.filter(
                collectCompare =>
                  collectCompare.DS_TIPO_DESCONTO === collectionData.DS_TIPO_DESCONTO &&
                  collectCompare.DS_TIPO_DESCONTO === dateCompare.discountName
              )?.[0]?.COLETAS.filter(collect => (isTankSelected ? collect.NR_TANQUE === selectedTank : true))
            )}
            title={title}
          />
        </StyledGraphicContainer>
      )}
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  height: 60px;
  padding: 0 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.frisiaPrimaryDark};
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 16px;
  color: #d7cc27;
`;

const StyledContainer = styled.div`
  width: 100%;
  min-width: 500px;
  border-radius: 20px;
  background-color: white;
`;

const StyledGraphicHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 30px 0 15px 0;
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.color.frisiaPrimaryDark};
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 12px;
`;

const StyledGraphicContainer = styled.div`
  padding: 0 25px;
  padding-bottom: 25px;
  overflow-x: auto;
`;

const StyledFilterMainContainer = styled.div`
  display: flex;
  align-items: center;

  .dropdown-container {
    max-height: 53px;
  }
`;

const StyledText = styled.span`
  color: ${({ theme }) => theme.color.frisiaPrimaryDark};
  font-family: ${({ theme }) => theme.font.montserratRegular};
  font-size: 14px;
`;

const StyledRadioButtonContainer = styled.div`
  display: flex;

  color: ${({ theme }) => theme.color.frisiaPrimaryDark};
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 14px;
`;

const StyleRadioButton = styled.span`
  display: flex;
  align-items: end;
  cursor: pointer;

  svg {
    margin: 0 5px;
  }
`;

export default withTranslation()(CollapsibleGraphic);
