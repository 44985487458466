import { AxiosResponse } from 'axios';
import bcrypt from 'bcryptjs';
import md5 from 'md5';
import { IMilkAnalysis } from 'model/milk-analysis';
import { sendLog } from 'shared/util/api-utils';
import StringUtils from 'shared/util/string-utils';
import { apiCore } from '../api';

export const milkAnalysisApi = () => {
  const getMilkAnalysis = (filterDate: string, registration: number): Promise<AxiosResponse<IMilkAnalysis[]>> => {
    sendLog(
      'Consultando análises do Leite',
      `/PecuariaLeite/Analise_Leites/?cd_pessoa=${registration}&dt_entrega=${filterDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IMilkAnalysis[]>(`/PecuariaLeite/Analise_Leites/?cd_pessoa=${registration}&dt_entrega=${filterDate}`);
  };

  const getMilkAnalysisReport = (filterDate: string, registration: number) => {
    const path = `http://reports/ReportServer?/SIF/LEI/LEI0940&DT_ANALISE=${filterDate}&CD_PESSOA=${registration}`;

    sendLog('Consultando relatorio de análises do Leite', path, JSON.stringify({}), 'get');

    const urlConvertCharcode = StringUtils.convertToCharCode(path);
    const md5EncryptedUrl = md5(urlConvertCharcode);
    const bcryptEncrypted = bcrypt.hashSync(md5EncryptedUrl);
    const charCodeUrlReportAuth = StringUtils.convertToCharCode(bcryptEncrypted);

    return `https://servicos.frisia.coop.br/RPT/Report/ReportTemplate?ReportId=${urlConvertCharcode}&ReportAuth=${charCodeUrlReportAuth}`;
  };

  return {
    getMilkAnalysis,
    getMilkAnalysisReport,
  };
};

export default milkAnalysisApi();
