import { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled, { CSSProperties } from 'styled-components';
import { StyledTable, StyledTableRow } from 'styled-components/StyledForTables';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/white-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/white-plus-round-icon.svg';

interface SlaughterObject {
  parameter: string;
  kiloSymbol: string;
}

export interface ITypeTable extends WithTranslation {
  headerInfos?: string[];
  title: string;
  getValues: (string | number)[][];
  paramsRows: SlaughterObject[];
  isInvertLineColor?: boolean;
  isDontHaveMaximumWidth?: boolean;
  style?: CSSProperties;
}

const GenericTableSlaughterReport = ({ getValues, paramsRows, title, isInvertLineColor, isDontHaveMaximumWidth, style, t }: ITypeTable) => {
  const [isShowGraphic, setIsShowGraphic] = useState<boolean>(false);
  const generalParametersTableHeader = [t('table.parameters'), t('table.unit'), t('table.tattoo')];

  const lastLine = paramsRows.length - 1;

  return (
    <StyledTable style={style} isInvertLineColor={isInvertLineColor} isDontHaveMaximumWidth={isDontHaveMaximumWidth}>
      {generalParametersTableHeader != null && (
        <thead>
          <StyledTitleRowHeader>
            <th
              key={`header-item-title`}
              style={
                isShowGraphic
                  ? {
                      borderRadius: '16px 16px 0px 0px',
                    }
                  : {
                      borderRadius: '16px',
                    }
              }
              colSpan={getValues.length + 3}
            >
              {isShowGraphic ? (
                <MinusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
              ) : (
                <PlusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
              )}{' '}
              {title}
            </th>
          </StyledTitleRowHeader>
          {isShowGraphic && (
            <StyledTableRowHeader>
              <th key={`header-item-para}`}>{t('table.parameters')}</th>
              <th key={`header-item-unid`}>{t('table.unit')}</th>
              <th key={`header-item-average`}>{t('table.average')}</th>
              <th key={`header-item-tatto`} style={{ textAlign: 'center' }} colSpan={getValues.length}>
                {t('table.tattoo')}
              </th>
            </StyledTableRowHeader>
          )}
        </thead>
      )}
      {isShowGraphic && (
        <tbody>
          {paramsRows.map((tableRow, indexRow) => (
            <StyledTableRow key={`row-${indexRow}`} className={`${indexRow === lastLine ? 'last-line' : ''}`}>
              <td key={`item-parameter-${indexRow}`}>{tableRow.parameter}</td>
              <td key={`item-kiloSymbol-${indexRow}`}>{tableRow.kiloSymbol}</td>

              <td key={`item-media-${indexRow}`}>
                {tableRow.parameter !== '' &&
                  (getValues.reduce(
                    (previousValue, currentValue) => (previousValue[indexRow] ?? previousValue) + currentValue[indexRow],
                    0
                  ) >= 0 ||
                  getValues.reduce(
                    (previousValue, currentValue) => (previousValue[indexRow] ?? previousValue) + currentValue[indexRow],
                    0
                  ) <= 0
                    ? tableRow.kiloSymbol.includes('R$') ||
                      tableRow.kiloSymbol.includes('Kg') ||
                      tableRow.kiloSymbol.includes('mm') ||
                      tableRow.kiloSymbol.includes('%')
                      ? (
                          getValues.reduce(
                            (previousValue, currentValue) => (previousValue[indexRow] ?? previousValue) + currentValue[indexRow],
                            0
                          ) / getValues.length
                        ).toLocaleString('pt-BR', { maximumFractionDigits: 2 })
                      : (
                          getValues.reduce(
                            (previousValue, currentValue) => (previousValue[indexRow] ?? previousValue) + currentValue[indexRow],
                            0
                          ) / getValues.length
                        ).toLocaleString('pt-BR', { maximumFractionDigits: 0 })
                    : '-')}
              </td>

              {getValues.map((tableItem, indexItem) => (
                <td key={`item-${indexItem}-${indexRow}`}>
                  {tableRow.kiloSymbol.includes('R$') ||
                  tableRow.kiloSymbol.includes('Kg') ||
                  tableRow.kiloSymbol.includes('mm') ||
                  tableRow.kiloSymbol.includes('%')
                    ? tableItem[indexRow].toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 6 })
                    : tableItem[indexRow]}
                </td>
              ))}
            </StyledTableRow>
          ))}
        </tbody>
      )}
    </StyledTable>
  );
};

const StyledTitleRowHeader = styled.tr`
  height: 50px;
  color: white;
  background: #032762;
  font-size: 16px;

  th {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledTableRowHeader = styled.tr`
  height: 50px;
  background-color: white;
  color: #032762;

  th {
    text-align: center;
  }

  th:nth-child(1) {
    text-align: left;
    padding-left: 20px;
  }

  th:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
  }
`;

export default withTranslation()(GenericTableSlaughterReport);
