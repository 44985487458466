import MainPageTitle from 'components/MainPageTitle';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as MinusRoundIconSvg } from '../../../images/frisia/icons/white-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../../../images/frisia/icons/white-plus-round-icon.svg';
import { ReactComponent as WhiteFilterIcon } from '../../../images/frisia/icons/filter_icon-white.svg';
import { ReactComponent as BlueFilterIcon } from '../../../images/frisia/icons/filter_icon-blue.svg';
import { ReactComponent as OpenEyeIcon } from '../../../images/frisia/icons/yellow-eye-thin.svg';
import { ReactComponent as CloseEyeIcon } from '../../../images/frisia/icons/yellow-eye-off-thin.svg';
import { ReactComponent as YellowCalendarIcon } from '../../../images/frisia/icons/yellow-calendar-icon.svg';
import { ReactComponent as ArrowIcon } from '../../../images/frisia/icons/orange-arrow-icon.svg';
import moment from 'moment';
import InputDateWithSetting from 'components/InputDateWithSetting';
import {
  AccountBlueContainer,
  AnswerContainer,
  ButtonsContainer,
  DaysStyledButton,
  FirstParamsContainer,
  SecondParamsContainer,
  StyledContainer,
  StyledExclamationIcon,
  StyledFiltersDateContainer,
  StyledLongButton,
  StyledPageTitleContainer,
  StyledSecondInfo,
  TaxesButton,
  TaxesTable,
  TaxesTableContainer,
} from './StyledComponents';
import FutureAbstractTable from './components/FutureAbstractTable';
import StringUtils from 'shared/util/string-utils';
import { IFinancialMoviment, IFutureCredits, IFutureDebts, IInfoAccount, ITaxes } from 'model/financial-abstract';
import FinancialAbstractService from 'services/financial-abstract-service';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import PastAbstractTable from './components/PastAbstractTable';
import axios from 'axios';

const FinancialAbstract = ({ t }: WithTranslation) => {
  const [showPrice, setShowPrice] = useState<boolean>(false);
  const [showTaxesTable, setShowTaxesTable] = useState<boolean>(false);
  const [selectedStartDateFilter, setSelectedStartDateFilter] = useState<Date | null>(new Date());
  const [selectedEndDateFilter, setSelectedEndDateFilter] = useState<Date | null>(null);
  const [nextDays, setNextDays] = useState<7 | 15 | 30 | 60>(7);
  const [showTable, setShowTable] = useState<string>('Extrato Futuro');
  const [pastData, setPastData] = useState<string>('future');
  const [taxesData, setTaxesData] = useState<ITaxes[]>([]);
  const [accountInfo, setAccountInfo] = useState<IInfoAccount>();
  const [accountInfoDayBefore, setAccountInfoDayBefore] = useState<IInfoAccount>();
  const [credits, setCredits] = useState<IFutureCredits>();
  const [debts, setDebts] = useState<IFutureDebts>();
  const [total, setTotal] = useState<number>(0);
  const today = moment().format('DD/MM/YYYY');
  const [showFuture, setShowFuture] = useState<boolean>(false);
  const [financialMoviment, setFinancialMoviment] = useState<IFinancialMoviment>();

  const [isLoadCreditAndDebt, setIsLoadCreditAndDebt] = useState(false);
  const [isLoadAbstracts, setIsLoadAbstracts] = useState(false);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  function changeTheDays() {
    const todayDate = new Date();
    if (nextDays) {
      if (pastData === 'past') {
        setSelectedStartDateFilter(new Date(todayDate.setDate(todayDate.getDate() - nextDays)));
        setSelectedEndDateFilter(new Date());
      } else if (pastData === 'future') {
        setSelectedStartDateFilter(new Date());
        setSelectedEndDateFilter(new Date(todayDate.setDate(todayDate.getDate() + nextDays)));
      }
    }
  }

  useEffect(() => {
    changeTheDays();
  }, [nextDays, pastData]);

  useEffect(() => {
    if (credits && debts && accountInfo) {
      setTotal(credits?.valorTotalReceber + debts?.valorTotalPagar + accountInfo?.saldoContaProducao + accountInfo?.saldoContaMovimento);
    }
  }, [credits, debts, accountInfo]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    FinancialAbstractService.getInterestRates().then(setTaxesData);
    FinancialAbstractService.getAccountInfo(getUserRegistration, today, source.token).then(setAccountInfo);
  }, []);

  useEffect(() => {
    if (selectedEndDateFilter) {
      setIsLoadCreditAndDebt(true);
      const callAsync = async () => {
        await FinancialAbstractService.getFutureCredits(getUserRegistration, moment(selectedEndDateFilter).format('DD/MM/YYYY')).then(
          setCredits
        );
        await FinancialAbstractService.getFutureDebts(getUserRegistration, moment(selectedEndDateFilter).format('DD/MM/YYYY')).then(
          setDebts
        );
        setIsLoadCreditAndDebt(false);
      };
      callAsync();
    }
  }, [selectedEndDateFilter]);

  useEffect(() => {
    if (selectedStartDateFilter && selectedEndDateFilter) {
      const source = axios.CancelToken.source();

      setIsLoadAbstracts(true);
      const callAsync = async () => {
        const dayBefore = new Date(selectedStartDateFilter);
        new Date(dayBefore.setDate(dayBefore.getDate() - 1));
        await FinancialAbstractService.getFinancialMoviment(
          getUserRegistration,
          moment(selectedStartDateFilter).format('DD/MM/YYYY'),
          moment(selectedEndDateFilter).format('DD/MM/YYYY'),
          source.token
        ).then(setFinancialMoviment);
        await FinancialAbstractService.getAccountInfo(getUserRegistration, moment(dayBefore).format('DD/MM/YYYY'), source.token).then(
          res => {
            setAccountInfoDayBefore(res);
            console.log(`saldo do dia ${moment(dayBefore).format('DD/MM/YYYY')}`, res.saldoContaMovimento + res.saldoContaProducao);
          }
        );

        setIsLoadAbstracts(false);
      };
      callAsync();
      return () => {
        source.cancel('Nova requisição feita');
      };
    }
  }, [selectedStartDateFilter, selectedEndDateFilter]);

  return (
    <StyledContainer>
      <StyledPageTitleContainer>
        <MainPageTitle fontSize={30} text={t('financial.abstract')} />
      </StyledPageTitleContainer>
      <FirstParamsContainer>
        <div style={{ minHeight: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <ButtonsContainer>
            <DaysStyledButton
              onClick={() => {
                setPastData('future');
                if (!nextDays) {
                  setNextDays(7);
                }
                setShowFuture(true);
              }}
              color={pastData === 'future' ? 'frisiaPrimary' : 'white'}
            >
              <span>
                {pastData === 'future' ? (
                  <WhiteFilterIcon style={{ maxWidth: '22px', maxHeight: '17px' }} />
                ) : (
                  <BlueFilterIcon style={{ maxWidth: '22px', maxHeight: '17px' }} />
                )}
                {t('financial.futureAbstract')}
              </span>
            </DaysStyledButton>
            <DaysStyledButton
              onClick={() => {
                setPastData('past');
                setNextDays(7);
              }}
              color={pastData === 'past' ? 'frisiaPrimary' : 'white'}
            >
              <span>
                {pastData === 'past' ? (
                  <WhiteFilterIcon style={{ maxWidth: '22px', maxHeight: '17px' }} />
                ) : (
                  <BlueFilterIcon style={{ maxWidth: '22px', maxHeight: '17px' }} />
                )}
                {t('financial.pastAbstract')}
              </span>
            </DaysStyledButton>
          </ButtonsContainer>
          {pastData !== undefined && (
            <ButtonsContainer>
              <DaysStyledButton onClick={() => setNextDays(7)} color={nextDays === 7 ? 'frisiaPrimary' : 'white'}>
                {pastData === 'past' ? (
                  <span>{t('financial-abstract.lastDays', { count: 7 })}</span>
                ) : (
                  <span>{t('financial-abstract.nextDays', { count: 7 })}</span>
                )}
              </DaysStyledButton>
              <DaysStyledButton onClick={() => setNextDays(15)} color={nextDays === 15 ? 'frisiaPrimary' : 'white'}>
                {pastData === 'past' ? (
                  <span>{t('financial-abstract.lastDays', { count: 15 })}</span>
                ) : (
                  <span>{t('financial-abstract.nextDays', { count: 15 })}</span>
                )}
              </DaysStyledButton>
              <DaysStyledButton onClick={() => setNextDays(30)} color={nextDays === 30 ? 'frisiaPrimary' : 'white'}>
                {pastData === 'past' ? (
                  <span>{t('financial-abstract.lastDays', { count: 30 })}</span>
                ) : (
                  <span>{t('financial-abstract.nextDays', { count: 30 })}</span>
                )}
              </DaysStyledButton>
              <DaysStyledButton onClick={() => setNextDays(60)} color={nextDays === 60 ? 'frisiaPrimary' : 'white'}>
                {pastData === 'past' ? (
                  <span>{t('financial-abstract.lastDays', { count: 60 })}</span>
                ) : (
                  <span>{t('financial-abstract.nextDays', { count: 60 })}</span>
                )}
              </DaysStyledButton>
            </ButtonsContainer>
          )}
        </div>
        {accountInfo && (
          <StyledSecondInfo>
            <tbody>
              <tr className="red-row">
                <td>
                  {t('financial.ownCapital')}
                  <Tooltip
                    title={<div style={{ whiteSpace: 'pre' }}>{`${t('financialPosition.infoOwnCapital')}`}</div>}
                    arrow
                    placement="top"
                  >
                    <StyledExclamationIcon />
                  </Tooltip>
                </td>
                <td className="gray-first">
                  <a rel="noopener noreferrer">
                    {'R$ '}
                    {accountInfo.saldoContaCapitalGiro === 0 ? '0,00' : StringUtils.maskAmount(accountInfo.saldoContaCapitalGiro)}
                  </a>
                </td>
              </tr>
              <tr className="red-row">
                <td>
                  {t('financial.socialCapital')}
                  <Tooltip
                    title={<div style={{ whiteSpace: 'pre' }}>{`${t('financialPosition.infoSocialCapital')}`}</div>}
                    arrow
                    placement="top"
                  >
                    <StyledExclamationIcon />
                  </Tooltip>
                </td>
                <td>
                  <a rel="noopener noreferrer">
                    {'R$ '}
                    {accountInfo.saldoContaCapitalSocial === 0 ? '0,00' : StringUtils.maskAmount(accountInfo.saldoContaCapitalSocial)}
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {t('financial.totalCapital')}
                  <Tooltip
                    title={<div style={{ whiteSpace: 'pre' }}>{`${t('financialPosition.infoTotalCapital')}`}</div>}
                    arrow
                    placement="top"
                  >
                    <StyledExclamationIcon />
                  </Tooltip>
                </td>
                <td className="gray-second">
                  <a rel="noopener noreferrer">
                    {'R$ '}
                    {accountInfo?.saldoContaCapitalGiro + accountInfo?.saldoContaCapitalSocial === 0
                      ? '0,00'
                      : StringUtils.maskAmount(accountInfo?.saldoContaCapitalGiro + accountInfo?.saldoContaCapitalSocial)}
                  </a>
                </td>
              </tr>
            </tbody>
          </StyledSecondInfo>
        )}
      </FirstParamsContainer>
      <FirstParamsContainer></FirstParamsContainer>

      <StyledFiltersDateContainer>
        <InputDateWithSetting
          setDate={setSelectedStartDateFilter}
          disabled={pastData !== 'past'}
          date={selectedStartDateFilter}
          label={t('livestock.startFilter')}
        />
        <InputDateWithSetting
          date={selectedEndDateFilter}
          label={t('livestock.endFilter')}
          disabled={pastData === 'past'}
          setDate={setSelectedEndDateFilter}
        />
        {pastData === 'past' && <p>{t('financial.maxInterval')}</p>}
      </StyledFiltersDateContainer>

      <SecondParamsContainer>
        <LoadingContainerScreen isLoading={!accountInfo}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {accountInfo && (
              <AccountBlueContainer>
                <div className="flex">
                  {t('financial.accountPlusProd')}
                  <b>{moment(accountInfo.dataSaldo, 'YYYY-MM-DD').format('DD/MM/YYYY')}</b>
                  <YellowCalendarIcon />
                </div>
                <div>
                  <div className="flex">
                    <div className="yellow">{'R$ '}</div>
                    {showPrice ? (
                      <>
                        <h1 className={accountInfo?.saldoContaProducao + accountInfo?.saldoContaMovimento < 0 ? 'red' : 'white'}>
                          {accountInfo?.saldoContaProducao + accountInfo?.saldoContaMovimento === 0
                            ? '0,00'
                            : StringUtils.maskAmount(accountInfo?.saldoContaProducao + accountInfo?.saldoContaMovimento)}
                        </h1>
                        <OpenEyeIcon onClick={() => setShowPrice(!showPrice)} />
                      </>
                    ) : (
                      <>
                        <h1></h1> <CloseEyeIcon onClick={() => setShowPrice(!showPrice)} />
                      </>
                    )}
                    {t('financialPosition.atualBalance')}
                  </div>
                </div>
              </AccountBlueContainer>
            )}
            {taxesData.length > 1 && accountInfo && (
              <TaxesTableContainer>
                <TaxesButton onClick={() => setShowTaxesTable(!showTaxesTable)}>
                  {showTaxesTable ? (
                    <MinusRoundIconSvg style={{ cursor: 'pointer', marginRight: '6px' }} />
                  ) : (
                    <PlusRoundIconSvg style={{ cursor: 'pointer', marginRight: '6px' }} />
                  )}
                  {t('financial.interest-rates')}
                </TaxesButton>
                <TaxesTable className={showTaxesTable ? '' : 'dont-show'}>
                  <table>
                    <tbody>
                      {taxesData.map((item, indexItem) => (
                        <tr className={item.descricao.includes('(-)') ? 'red-row' : 'blue-row'} key={`trTaxes-${indexItem}`}>
                          <td>{item.descricao}</td>
                          <td>{`${item.percentual === 0 ? '0,00' : StringUtils.maskAmount(item.percentual)}% ${item.periodo}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TaxesTable>
              </TaxesTableContainer>
            )}
          </div>
        </LoadingContainerScreen>

        {pastData === 'future' && (
          <LoadingContainerScreen isLoading={isLoadCreditAndDebt}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <StyledLongButton
                style={showTable === 'A Pagar' ? { background: '#2a5299' } : {}}
                onClick={() => selectedEndDateFilter && setShowTable('A Pagar')}
                cursorPointer={true}
              >
                <div>
                  {t('financial.toPay')}
                  <Tooltip title={<div style={{ whiteSpace: 'pre' }}>{`${t('financialPosition.futureDebts')}`}</div>} arrow placement="top">
                    <StyledExclamationIcon />
                  </Tooltip>
                </div>
                <div className="red">
                  {'R$ '}
                  {debts?.valorTotalPagar === 0 ? '0,00' : StringUtils.maskAmount(debts?.valorTotalPagar)}
                  <ArrowIcon />
                </div>
              </StyledLongButton>
              <StyledLongButton
                style={showTable === 'A Receber' ? { background: '#2a5299' } : {}}
                onClick={() => selectedEndDateFilter && setShowTable('A Receber')}
                cursorPointer={true}
              >
                <div>
                  {t('financial.receive')}
                  <Tooltip
                    title={<div style={{ whiteSpace: 'pre' }}>{`${t('financialPosition.futureCredts')}`}</div>}
                    arrow
                    placement="top"
                  >
                    <StyledExclamationIcon />
                  </Tooltip>
                </div>
                <div className="white">
                  {'R$ '}
                  {credits?.valorTotalReceber === 0 ? '0,00' : StringUtils.maskAmount(credits?.valorTotalReceber)}
                  <ArrowIcon />
                </div>
              </StyledLongButton>
              <StyledLongButton
                style={showTable === 'Extrato Futuro' ? { background: '#2a5299' } : {}}
                onClick={() => selectedEndDateFilter && setShowTable('Extrato Futuro')}
                cursorPointer={true}
              >
                <div>{t('financial.abstractTotal')}</div>
                <div className={total < 0 ? 'red' : 'white'}>
                  {'R$ '}
                  {total === 0 ? '0,00' : StringUtils.maskAmount(total)}
                </div>
              </StyledLongButton>
            </div>
          </LoadingContainerScreen>
        )}
      </SecondParamsContainer>
      {pastData === 'past' ? (
        <LoadingContainerScreen isLoading={isLoadAbstracts}>
          <AnswerContainer>
            <PastAbstractTable
              title={'PASSADO'}
              onBack={() => {
                setShowFuture(false);
                setPastData('future');
              }}
              valueBefore={(accountInfoDayBefore?.saldoContaProducao ?? 0) + (accountInfoDayBefore?.saldoContaMovimento ?? 0)}
              documents={financialMoviment?.documentos}
              startDate={moment(selectedStartDateFilter).format('DD/MM/YYYY')}
              finalDate={moment(selectedEndDateFilter).format('DD/MM/YYYY')}
            />
          </AnswerContainer>
          {/* <StyledLongButton>
            <div>{t('financial.abstractFinal')}</div>
            <div className={financialMoviment && financialMoviment?.valorTotalDocumentos < 0 ? 'red' : 'white'}>
              {'R$ '}
              {StringUtils.maskAmount(
                (financialMoviment?.valorTotalDocumentos ?? 0) +
                  (accountInfoDayBefore?.saldoContaProducao ?? 0) +
                  (accountInfoDayBefore?.saldoContaMovimento ?? 0)
              )}
            </div>
          </StyledLongButton> */}
        </LoadingContainerScreen>
      ) : (
        <LoadingContainerScreen isLoading={!accountInfo || isLoadCreditAndDebt}>
          <AnswerContainer>
            <FutureAbstractTable
              DocumentsReceivable={credits?.documentosReceber}
              DocumentsPayable={debts?.documentosPagar}
              title={showTable}
              valueBefore={(accountInfo?.saldoContaProducao ?? 0) + (accountInfo?.saldoContaMovimento ?? 0)}
              startDate={moment(selectedStartDateFilter).format('DD/MM/YYYY')}
              finalDate={moment(selectedEndDateFilter).format('DD/MM/YYYY')}
            />
          </AnswerContainer>
        </LoadingContainerScreen>
      )}
      {showTable === 'A Pagar' && pastData === 'future' && !showFuture && (
        <StyledLongButton>
          <div>{t('financial.totalToPay')}</div>
          <div className="red">
            {'R$ '}
            {debts?.valorTotalPagar === 0 ? '0,00' : StringUtils.maskAmount(debts?.valorTotalPagar)}
          </div>
        </StyledLongButton>
      )}
      {showTable === 'A Receber' && pastData === 'future' && !showFuture && (
        <StyledLongButton>
          <div>{t('financial.totalToReceive')}</div>
          <div className="white">
            {'R$ '}
            {credits?.valorTotalReceber === 0 ? '0,00' : StringUtils.maskAmount(credits?.valorTotalReceber)}
          </div>
        </StyledLongButton>
      )}
    </StyledContainer>
  );
};

export default withTranslation()(FinancialAbstract);
