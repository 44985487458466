import slaughterReportApi from 'api/swine/slaughter-report-api';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IBonification,
  ICollectPoint,
  IDateOption,
  IEconomy,
  IGeneralParams,
  IInspection,
  IMedians,
  IReception,
  ISlaughterReportData,
  ISlaughterReportFilterType,
  ISlaughterResult,
  ISummaries,
  ITypefication,
} from 'model/slaughter-report';

export const slaughterReportService = () => {
  const getDataFilters = async (registration: number) => {
    try {
      const result: AxiosResponse<ISlaughterReportFilterType[]> = await slaughterReportApi.getDataFilters(registration);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getDataReport = async (registration: number, collectionPoint: number, slaughterDate: string, tattoo: number) => {
    try {
      const result: AxiosResponse<ISlaughterReportData> = await slaughterReportApi.getDataReport(
        registration,
        collectionPoint,
        slaughterDate,
        tattoo
      );
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getCollectPoints = async (registration: number) => {
    try {
      const result: AxiosResponse<ICollectPoint[]> = await slaughterReportApi.getCollectPoints(registration);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getDatesOptions = async (registration: number, collectionPoint: string) => {
    try {
      const result: AxiosResponse<IDateOption[]> = await slaughterReportApi.getDatesOptions(registration, collectionPoint);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getGeneralParams = async (registration: number, collectionPoint: string, initialDate: string, finalDate: string) => {
    try {
      const result: AxiosResponse<IGeneralParams[]> = await slaughterReportApi.getGeneralParams(
        registration,
        collectionPoint,
        initialDate,
        finalDate
      );
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getSlaughterResult = async (registration: number, collectionPoint: string, initialDate: string, finalDate: string) => {
    try {
      const result: AxiosResponse<ISlaughterResult[]> = await slaughterReportApi.getSlaughterResult(
        registration,
        collectionPoint,
        initialDate,
        finalDate
      );
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getBonification = async (registration: number, collectionPoint: string, initialDate: string, finalDate: string) => {
    try {
      const result: AxiosResponse<IBonification[]> = await slaughterReportApi.getBonification(
        registration,
        collectionPoint,
        initialDate,
        finalDate
      );
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getEconomy = async (registration: number, collectionPoint: string, initialDate: string, finalDate: string) => {
    try {
      const result: AxiosResponse<IEconomy[]> = await slaughterReportApi.getEconomy(registration, collectionPoint, initialDate, finalDate);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getReception = async (registration: number, collectionPoint: string, initialDate: string, finalDate: string) => {
    try {
      const result: AxiosResponse<IReception[]> = await slaughterReportApi.getReception(
        registration,
        collectionPoint,
        initialDate,
        finalDate
      );
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getInspection = async (registration: number, collectionPoint: string, initialDate: string, finalDate: string) => {
    try {
      const result: AxiosResponse<IInspection[]> = await slaughterReportApi.getInspection(
        registration,
        collectionPoint,
        initialDate,
        finalDate
      );
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getSummaries = async (registration: number, collectionPoint: string, initialDate: string, finalDate: string) => {
    try {
      const result: AxiosResponse<ISummaries[]> = await slaughterReportApi.getSummaries(
        registration,
        collectionPoint,
        initialDate,
        finalDate
      );
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getTypefication = async (registration: number, collectionPoint: string, initialDate: string, finalDate: string) => {
    try {
      const result: AxiosResponse<ITypefication[]> = await slaughterReportApi.getTypefication(
        registration,
        collectionPoint,
        initialDate,
        finalDate
      );
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getMedians = async (registration: number, collectionPoint: string, initialDate: string, finalDate: string) => {
    try {
      const result: AxiosResponse<IMedians[]> = await slaughterReportApi.getMedians(registration, collectionPoint, initialDate, finalDate);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getDataFilters,
    getCollectPoints,
    getDataReport,
    getDatesOptions,
    getGeneralParams,
    getSlaughterResult,
    getBonification,
    getEconomy,
    getReception,
    getInspection,
    getSummaries,
    getTypefication,
    getMedians,
  };
};

export default slaughterReportService();
