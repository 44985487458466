import { AxiosResponse } from 'axios';
import { QuotationResponse } from 'model/quotation-info';
import { sendLog } from 'shared/util/api-utils';
import { apiCore } from './api';

export const frisiaQuotationApi = () => {
  const getQuotation = (date: string): Promise<AxiosResponse<any>> => {
    sendLog('Consulta cotação Frisia', `/AgricolaCotacao/Dados_II/?dt_cotacao=${date}`, JSON.stringify([]), 'get');
    return apiCore.get<QuotationResponse>(`/AgricolaCotacao/Dados_II/?dt_cotacao=${date}`);
  };

  return {
    getQuotation,
  };
};

export default frisiaQuotationApi();
