import environmentalApi from 'api/environmental-api';
import { AxiosError, AxiosResponse } from 'axios';
import { IEnvStatus } from 'model/environmental';

export const environmentalService = () => {
  const getEnvStatus = async () => {
    try {
      const result: AxiosResponse<IEnvStatus[]> = await environmentalApi.getEnvStatus();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getEnvStatus,
  };
};

export default environmentalService();
