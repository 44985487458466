import agriculturalApi from 'api/agricultural/agricultural-api';
import GenericDropdown from 'components/GenericDropdown';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import { isEmpty } from 'lodash';
import { IInputOptions, ISession } from 'model/input-report';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import agriculturalService from 'services/agricultural/agricultural-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const Inputs = ({ t }: WithTranslation) => {
  const [options, setOptions] = useState<IInputOptions[]>([]);
  const [chosenHarvest, setChosenHarvest] = useState<string>();
  const [cultureList, setCultureList] = useState<IInputOptions[]>();
  const [chosenCulture, setChosenCulture] = useState<string>();
  const [purposeList, setPurposeList] = useState<IInputOptions[]>();
  const [chosenPurpose, setChosenPurpose] = useState<string>();
  const [cropTypeList, setCropTypeList] = useState<IInputOptions[]>();
  const [chosenCropType, setChosenCropType] = useState<string>();
  const [reportLink, setReportLink] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getReport = async () => {
    if (chosenHarvest) {
      setIsLoading(true);
      const session = await agriculturalService.getSession(
        getUserRegistration,
        chosenHarvest?.replace('/', ''),
        options.find(item => item.NM_CULTURA === chosenCulture)?.CD_CULTURA.toString() ?? '0'
      );
      const reportLinkPath = await agriculturalApi.getInputReportLink(
        getUserRegistration,
        chosenHarvest?.replace('/', ''),
        options.find(item => item.NM_CULTURA === chosenCulture)?.CD_CULTURA.toString() ?? '0',
        options.find(item => item.NM_PROPOSITO === chosenPurpose)?.IE_PROPOSITO ?? '0',
        options.find(item => item.NM_TIPO_SAFRA === chosenCropType)?.IE_TIPO_SAFRA ?? '0',
        session?.va_nr_sessao.toString()
      );
      setReportLink(reportLinkPath);
      setIsLoading(false);
    }
  };

  const getOptions = () => {
    agriculturalService.getInputOptions(getUserRegistration).then(setOptions);
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (chosenHarvest) {
      setCultureList(options.filter(item => item.NM_SAFRA === chosenHarvest));
    }
  }, [chosenHarvest]);

  useEffect(() => {
    if (chosenHarvest && chosenCulture) {
      setPurposeList(cultureList?.filter(item => item.NM_CULTURA === chosenCulture));
    }
  }, [chosenCulture]);

  useEffect(() => {
    if (chosenPurpose) {
      setCropTypeList(purposeList?.filter(item => item.NM_PROPOSITO === chosenPurpose));
    }
  }, [chosenPurpose]);

  useEffect(() => {
    if (chosenCropType) {
      getReport();
    }
  }, [chosenCulture, chosenPurpose, chosenCropType]);

  useEffect(() => {
    setChosenCulture(undefined);
    setChosenPurpose(undefined);
    setChosenCropType(undefined);
    setReportLink('');
  }, [chosenHarvest]);

  useEffect(() => {
    setChosenPurpose(undefined);
    setChosenCropType(undefined);
    setReportLink('');
  }, [chosenCulture]);

  useEffect(() => {
    setChosenCropType(undefined);
    setReportLink('');
  }, [chosenPurpose]);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('agricultural.budgetsSuppliesAndWithdrawal')} />
      </StyledPageTitleContainer>

      <StyledFiltersContainer>
        <StyledFilterContainer filterWidth={230}>
          <GenericDropdown
            label={t('agricultural.crop')}
            placeholder={t('global.field.filterSelectYear')}
            listShowDropdown={
              options.length > 0
                ? options
                    .map(item => item.NM_SAFRA)
                    .filter((v, i, a) => a.indexOf(v) === i)
                    .reverse()
                : [t('global.field.loading')]
            }
            getSelectedValue={setChosenHarvest}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={230}>
          <GenericDropdown
            isResetValue={chosenCulture == undefined}
            label={t('livestock.culture')}
            placeholder={t('global.field.filterSelectCulture')}
            listShowDropdown={cultureList?.map(item => item.NM_CULTURA).filter((v, i, a) => a.indexOf(v) === i) ?? []}
            getSelectedValue={setChosenCulture}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={230}>
          <GenericDropdown
            isResetValue={chosenPurpose == undefined}
            label={t('livestock.purpose')}
            placeholder={t('livestock.selectPurpose')}
            listShowDropdown={purposeList?.map(item => item.NM_PROPOSITO).filter((v, i, a) => a.indexOf(v) === i) ?? []}
            getSelectedValue={setChosenPurpose}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={230}>
          <GenericDropdown
            isResetValue={chosenCropType == undefined}
            label={t('global.field.cropType')}
            placeholder={t('global.field.selectCropType')}
            listShowDropdown={cropTypeList?.map(item => item.NM_TIPO_SAFRA).filter((v, i, a) => a.indexOf(v) === i) ?? []}
            getSelectedValue={setChosenCropType}
          />
        </StyledFilterContainer>
      </StyledFiltersContainer>
      {chosenCropType && (
        <LoadingContainerScreen isLoading={isLoading}>
          <StyledButtonContainer>
            <StyledButton
              onClick={() => window.open(reportLink, '_blank')}
              color="frisiaPrimary"
              type="button"
              style={{ minWidth: '210px' }}
            >
              {t('global.button.generateReport')}
            </StyledButton>
          </StyledButtonContainer>
        </LoadingContainerScreen>
      )}
    </>
  );
};

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 5px;
  div {
    font-size: 12px !important;
  }
`;

const StyledButtonContainer = styled.div`
  margin-top: 10px;
`;

const StyledCardContainer = styled.div`
  width: 100%;
  min-width: 490px;
  font-size: 16px;

  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  padding: 20px;
  margin: 20px 0 15px 0;
`;

const StyledTitle = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: #032762;
  text-align: center;
  border-bottom: 1.9px solid #d7cc27;
  padding-bottom: 10px;
`;

const StyledDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledDataInnerContainer = styled.div`
  width: 50%;
  max-width: 535px;
  min-width: 465px;

  .style-text-value {
    margin-left: 10%;
    font-family: ${({ theme }) => theme.font.montserratMedium};
    color: #404040;

    &-bold {
      margin-left: 10%;
      font-family: ${({ theme }) => theme.font.montserratBold};
      color: #404040;
    }
  }

  div {
    text-align: left;
    margin: 5px 0;
  }
`;

const StyledData = styled.div`
  display: flex;

  .style-text {
    display: block;
    min-width: 160px;
  }
`;

const StyledBudgetMessage = styled.div`
  color: #d96767;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  font-size: 20px;

  text-align: left;
  margin-left: 20px;
`;

export default withTranslation()(Inputs);
