import GenericDropdown from 'components/GenericDropdown';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import {
  ICropandStates,
  ICultureProfitability,
  IInputCosts,
  IProductivity,
  IProducts,
  IProfitability,
  ISellPrice,
} from 'model/agricultural-profitability';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import profitabilityService from 'services/agricultural/profitability-service';
import styled, { useTheme } from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { ReactComponent as GraphicIconSvg } from '../../images/frisia/icons/white-graphic-line-icon.svg';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CategoryScale, Chart as ChartJS, ChartOptions, LinearScale, LineElement, PointElement, Tooltip, TooltipItem } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, ChartDataLabels);

const Profitability = ({ t }: WithTranslation) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showGraphs, setShowGraphs] = useState<boolean>(false);

  const [cropsLists, setcropsLists] = useState<ICropandStates[]>([]);
  const [chosenYear, setChosenYear] = useState<string>('');
  const [cultureLists, setCultureLists] = useState<ICultureProfitability[]>([]);
  const [chosenCulture, setChosenCulture] = useState<string>('');
  const [chosenRegion, setChosenRegion] = useState<string>('');
  const [productsInfo, setProductsInfo] = useState<IProducts[]>([]);

  const [productivityInfo, setProductivityInfo] = useState<IProductivity[]>([]);
  const [productivityAverage, setProductivityAverage] = useState<number>();

  const [profitabilityInfo, setProfitabilityInfo] = useState<IProfitability[]>([]);
  const [profitabilityAverage, setProfitabilityAverage] = useState<number>();

  const [inputCostInfo, setInputCostInfo] = useState<IInputCosts[]>([]);
  const [inputCostAverage, setInputCostAverage] = useState<number>();

  const [sellPriceInfo, setSellPriceInfo] = useState<ISellPrice[]>([]);
  const [sellPriceAverage, setSellPriceAverage] = useState<number>();

  const [chosenProduct, setChosenProduct] = useState<string>('TOTAL');
  const [chosenInputType, setChosenInputType] = useState<string>('TOTAL');

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getDataOptions = async () => {
    const cropResult = await profitabilityService
      .getCropandStates()
      .then(response => response.sort((a, b) => b.CD_SAFRA - a.CD_SAFRA).sort((a, b) => a.CD_ESTADO - b.CD_ESTADO));
    setcropsLists(cropResult);
    setChosenYear(cropResult[0].NM_SAFRA);
    setChosenRegion(cropResult[0].NM_ESTADO);
    await profitabilityService.getCulture(cropResult[0].CD_SAFRA, cropResult[0].CD_ESTADO).then(response => {
      setCultureLists(response);
      setChosenCulture(response[0].NM_CULTURA);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getDataOptions();
  }, []);

  const changeToBrNumber = (value: number) => {
    return parseFloat(value.toFixed(2)).toLocaleString('pt-br', { minimumFractionDigits: 2 });
  };

  const getInfo = async () => {
    if (chosenCulture && chosenYear && chosenRegion && cropsLists) {
      profitabilityService
        .getProductive(
          cropsLists.find(item => item.NM_SAFRA === chosenYear)?.CD_SAFRA ?? 20212022,
          cropsLists.find(item => item.NM_ESTADO === chosenRegion)?.CD_ESTADO ?? 17,
          cultureLists.find(item => item.NM_CULTURA === chosenCulture)?.CD_CULTURA ?? 17
        )
        .then(response => setProductivityInfo(response.sort((a, b) => b.RANKING - a.RANKING)));

      profitabilityService
        .getAverageProductive(
          cropsLists.find(item => item.NM_SAFRA === chosenYear)?.CD_SAFRA ?? 20212022,
          cropsLists.find(item => item.NM_ESTADO === chosenRegion)?.CD_ESTADO ?? 17,
          cultureLists.find(item => item.NM_CULTURA === chosenCulture)?.CD_CULTURA ?? 17
        )
        .then(response => setProductivityAverage(response[0].VL_PRODUTIV_MEDIO_FRISIA));

      profitabilityService
        .getProducts(
          cropsLists.find(item => item.NM_SAFRA === chosenYear)?.CD_SAFRA ?? 20212022,
          cropsLists.find(item => item.NM_ESTADO === chosenRegion)?.CD_ESTADO ?? 17,
          cultureLists.find(item => item.NM_CULTURA === chosenCulture)?.CD_CULTURA ?? 17
        )
        .then(setProductsInfo);

      profitabilityService
        .getProfitability(
          cropsLists.find(item => item.NM_SAFRA === chosenYear)?.CD_SAFRA ?? 20212022,
          cultureLists.find(item => item.NM_CULTURA === chosenCulture)?.CD_CULTURA ?? 17
        )
        .then(response => setProfitabilityInfo(response));

      profitabilityService
        .getAverageProfitability(
          cropsLists.find(item => item.NM_SAFRA === chosenYear)?.CD_SAFRA ?? 20212022,
          cultureLists.find(item => item.NM_CULTURA === chosenCulture)?.CD_CULTURA ?? 17
        )
        .then(response => setProfitabilityAverage(response[0].MEDIA_FRISIA));

      getSellPrice();
      InputCosts();
    }
  };

  const InputCosts = async () => {
    if (chosenCulture && chosenYear && chosenRegion && cropsLists) {
      profitabilityService
        .getInputCosts(
          cropsLists.find(item => item.NM_SAFRA === chosenYear)?.CD_SAFRA ?? 20212022,
          cropsLists.find(item => item.NM_ESTADO === chosenRegion)?.CD_ESTADO ?? 17,
          cultureLists.find(item => item.NM_CULTURA === chosenCulture)?.CD_CULTURA ?? 17,
          chosenInputType === 'TOTAL' ? '0' : chosenInputType
        )
        .then(response => setInputCostInfo(response));

      profitabilityService
        .getAverageInputCosts(
          cropsLists.find(item => item.NM_SAFRA === chosenYear)?.CD_SAFRA ?? 20212022,
          cropsLists.find(item => item.NM_ESTADO === chosenRegion)?.CD_ESTADO ?? 17,
          cultureLists.find(item => item.NM_CULTURA === chosenCulture)?.CD_CULTURA ?? 17,
          chosenInputType === 'TOTAL' ? '0' : chosenInputType
        )
        .then(response => setInputCostAverage(response[0]?.VL_INSUMOS_MEDIO_FRISIA));
    }
  };

  const getSellPrice = async () => {
    if (chosenCulture && chosenYear && chosenRegion && cropsLists) {
      profitabilityService
        .getSellPrice(
          cropsLists.find(item => item.NM_SAFRA === chosenYear)?.CD_SAFRA ?? 20212022,
          cropsLists.find(item => item.NM_ESTADO === chosenRegion)?.CD_ESTADO ?? 17,
          cultureLists.find(item => item.NM_CULTURA === chosenCulture)?.CD_CULTURA ?? 17,
          productsInfo.find(item => item.DS_PRODUTO === chosenProduct)?.CD_PRODUTO ?? 0
        )
        .then(response => setSellPriceInfo(response));

      profitabilityService
        .getAverageSellPrice(
          cropsLists.find(item => item.NM_SAFRA === chosenYear)?.CD_SAFRA ?? 20212022,
          cropsLists.find(item => item.NM_ESTADO === chosenRegion)?.CD_ESTADO ?? 17,
          cultureLists.find(item => item.NM_CULTURA === chosenCulture)?.CD_CULTURA ?? 17,
          productsInfo.find(item => item.DS_PRODUTO === chosenProduct)?.CD_PRODUTO ?? 0
        )
        .then(response => setSellPriceAverage(response[0].VL_VENDA_MEDIO_FRISIA));
    }
  };

  useEffect(() => {
    if (chosenCulture && chosenYear && chosenRegion) {
      getInfo();
    }
  }, [chosenRegion, chosenCulture, chosenYear]);

  useEffect(() => {
    getSellPrice();
  }, [chosenProduct]);

  useEffect(() => {
    InputCosts();
  }, [chosenInputType]);

  const theme = useTheme();

  const generateOption = (footerCallback: (tooltipItem: TooltipItem<'line'>[]) => string) => {
    const options: ChartOptions<'line'> = {
      responsive: true,
      interaction: {
        intersect: false,
      },
      plugins: {
        legend: { display: false },
        datalabels: {
          align: 'top',
          display: false,
        },
        tooltip: {
          footerFont: {
            size: 16,
          },
          displayColors: false,
          backgroundColor: '#fff',
          titleColor: '#678',
          bodyColor: '#111',
          footerColor: '#111',
          multiKeyBackground: theme.color.frisiaPrimary,
          caretSize: 5,
          borderWidth: 1,
          borderColor: theme.color.frisiaPrimary,
          usePointStyle: false,
          yAlign: 'bottom',
          padding: 10,
          boxPadding: 5,
          boxHeight: 10,
          callbacks: {
            footer: footerCallback,
          },
        },
      },
      layout: {
        padding: {
          right: 10,
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            autoSkip: false,
          },
        },
      },
    };
    return options;
  };

  const generateData = (info, key, average) => {
    const label = info.map(item => item[key]);
    const data = {
      labels: info.map(item => ''),
      datasets: [
        {
          data: info.map(item => {
            if (item.CD_PESSOA === getUserRegistration) {
              return item[key];
            } else {
              return;
            }
          }),
          fill: true,
          pointBorderColor: 'rgba(11, 05, 134)',
          backgroundColor: '#2a5299',
          pointBackgroundColor: '#2a5299',
          label: t('profitability.averageProfitabilityOfCooperator'),
          pointBorderWidth: 24,
        },
        {
          data: label,
          backgroundColor: '#2a5299',
          borderWidth: 0,
          fill: true,
          pointBorderWidth: 10,
          pointBorderColor: '#2a5299',
        },
        {
          data: info.map(item => average),
          borderWidth: 2,
          borderColor: '#25785A',
          backgroundColor: '#25785A',
          datalabels: { display: false },
          label: t('profitability.averageFrisia'),
        },
      ],
    };

    return data;
  };

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('agricultural.profitability')} />
      </StyledPageTitleContainer>

      <LoadingContainerScreen isLoading={isLoading}>
        <StyledFiltersContainer>
          <StyledFilterContainer filterWidth={300}>
            <GenericDropdown
              label={t('agricultural.harvest')}
              placeholder={t('agricultural.selectHarvest')}
              listShowDropdown={cropsLists.map(item => item.NM_SAFRA).filter((value, index, self) => self.indexOf(value) === index)}
              getSelectedValue={value => setChosenYear(value)}
              defaultValue={chosenYear}
            />
          </StyledFilterContainer>
          <StyledFilterContainer filterWidth={300}>
            <GenericDropdown
              label={t('agricultural.culture')}
              placeholder={t('livestock.selectCulture')}
              listShowDropdown={cultureLists.map(item => item.NM_CULTURA).filter((value, index, self) => self.indexOf(value) === index)}
              getSelectedValue={value => setChosenCulture(value)}
              defaultValue={chosenCulture}
            />
          </StyledFilterContainer>
          <StyledFilterContainer filterWidth={300}>
            <GenericDropdown
              label={t('agricultural.region')}
              placeholder={t('agricultural.selectRegion')}
              listShowDropdown={cropsLists.map(item => item.NM_ESTADO).filter((value, index, self) => self.indexOf(value) === index)}
              getSelectedValue={value => setChosenRegion(value)}
              defaultValue={chosenRegion}
            />
          </StyledFilterContainer>
        </StyledFiltersContainer>
        <StyledButton
          onClick={() => setShowGraphs(true)}
          color="frisiaPrimary"
          type="button"
          style={{ minWidth: '400px', alignSelf: 'center', marginTop: '16px' }}
        >
          <span>{t('support.search')}</span>
        </StyledButton>
        {showGraphs && (
          <>
            <StyledGraphicContainer>
              <StyledHeader>
                <GraphicIconSvg style={{ margin: '0 9px 0 23px' }} />
                {`${t('profitability.frisiaProfitability')} - ${chosenYear} - ${chosenCulture}`}
              </StyledHeader>
              <StyledLegends>
                <StyledSquareBlue /> {chosenCulture}
                <StyledSquareGreen /> {t('agricultural.averageFrisia')}
              </StyledLegends>
              <div style={{ margin: '12px', marginTop: '-12px' }}>
                <Line
                  options={generateOption(tooltipItems => {
                    const itemProfitabilityInfo = profitabilityInfo[tooltipItems[0].dataIndex];
                    return `${t('profitability.averageProfitability')}: ${changeToBrNumber(
                      itemProfitabilityInfo?.VL_RENTABILIDADE
                    )} R$/ha\n${t('profitability.ranking')}: ${itemProfitabilityInfo?.RANKING}°`;
                  })}
                  data={generateData(profitabilityInfo, 'VL_RENTABILIDADE', profitabilityAverage)}
                />
              </div>
            </StyledGraphicContainer>
            <StyledGraphicContainer>
              <StyledHeader style={{ justifyContent: 'space-between' }}>
                <div>
                  <GraphicIconSvg style={{ margin: '0 9px 0 23px' }} />
                  {`${t('profitability.costOfInputs')} (R$/ha) - ${chosenYear} - ${chosenCulture}`}
                </div>
                <StyledFilterContainer filterWidth={340} style={{ marginRight: '32px' }}>
                  <GenericDropdown
                    placeholder={t('livestock.selecione um tipo de Insumo')}
                    listShowDropdown={[
                      ...cultureLists.filter(item => item.NM_CULTURA === chosenCulture).map(item => item.IE_TIPO_INSUMO),
                      'TOTAL',
                    ]}
                    getSelectedValue={setChosenInputType}
                    defaultValue={chosenInputType}
                  />
                </StyledFilterContainer>
              </StyledHeader>
              <StyledLegends>
                <StyledSquareBlue /> {chosenCulture}
                <StyledSquareGreen /> {t('agricultural.averageFrisia')}
              </StyledLegends>
              <div style={{ margin: '12px', marginTop: '-12px' }}>
                <Line
                  options={generateOption(tooltipItems => {
                    const iteminputCostInfo = inputCostInfo[tooltipItems[0].dataIndex];
                    return `${t('profitability.cost')}: R$ ${changeToBrNumber(iteminputCostInfo?.VL_REAIS_HECT)}\n${t(
                      'profitability.ranking'
                    )}: ${iteminputCostInfo?.RANKING}°`;
                  })}
                  data={generateData(inputCostInfo, 'VL_REAIS_HECT', inputCostAverage)}
                />
              </div>
            </StyledGraphicContainer>
            <StyledGraphicContainer>
              <StyledHeader>
                <GraphicIconSvg style={{ margin: '0 9px 0 23px' }} />
                {`${t('profitability.productivity')} (kg/ha) - ${chosenYear} - ${chosenCulture}`}
              </StyledHeader>
              <StyledLegends>
                <StyledSquareBlue /> {chosenCulture}
                <StyledSquareGreen /> {t('agricultural.averageFrisia')}
              </StyledLegends>
              <div style={{ margin: '12px', marginTop: '-12px' }}>
                <Line
                  options={generateOption(tooltipItems => {
                    const itemProductivityInfo = productivityInfo[tooltipItems[0].dataIndex];
                    return `${t('profitability.productivity')}: ${changeToBrNumber(
                      itemProductivityInfo?.QT_PRODUTIVIDADE_LAUDO
                    )} (kg/ha)\n${t('profitability.ranking')}: ${itemProductivityInfo?.RANKING}°`;
                  })}
                  data={generateData(productivityInfo, 'QT_PRODUTIVIDADE_LAUDO', productivityAverage)}
                />
              </div>
            </StyledGraphicContainer>
            <StyledGraphicContainer>
              <StyledHeader style={{ justifyContent: 'space-between' }}>
                <div>
                  <GraphicIconSvg style={{ margin: '0 9px 0 23px' }} />
                  {`${t('profitability.productionSalesPrice')} - ${chosenYear} - ${chosenCulture}`}
                </div>
                <StyledFilterContainer filterWidth={340} style={{ marginRight: '32px' }}>
                  <GenericDropdown
                    placeholder={t('profitability.selectAProduct')}
                    getSelectedValue={setChosenProduct}
                    listShowDropdown={productsInfo.map(item => item.DS_PRODUTO)}
                    defaultValue={chosenProduct}
                  />
                </StyledFilterContainer>
              </StyledHeader>
              <StyledLegends>
                <StyledSquareBlue /> {chosenCulture}
                <StyledSquareGreen /> {t('agricultural.averageFrisia')}
              </StyledLegends>
              <div style={{ margin: '12px', marginTop: '-12px' }}>
                <Line
                  options={generateOption(tooltipItems => {
                    const itemSellPriceInfo = sellPriceInfo[tooltipItems[0].dataIndex];
                    return `${t('profitability.averageSalePrice')}: R$ ${changeToBrNumber(itemSellPriceInfo?.VL_MEDIA_PRECO)}\n${t(
                      'profitability.ranking'
                    )}: ${itemSellPriceInfo?.RANKING}°`;
                  })}
                  data={generateData(sellPriceInfo, 'VL_MEDIA_PRECO', sellPriceAverage)}
                />
              </div>
            </StyledGraphicContainer>
          </>
        )}
      </LoadingContainerScreen>
    </>
  );
};

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
`;

const StyledLegends = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;
`;

const StyledSquareBlue = styled.div`
  width: 24px;
  height: 20px;
  background: #2a5299;
  margin: 5px;
`;

const StyledSquareGreen = styled.div`
  width: 24px;
  height: 20px;
  background: #25785a;
  margin: 5px;
`;

const StyledGraphicContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 26px;
  min-width: 500px;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.poppinsSemiBold};
  letter-spacing: 0.1px;
  color: #5a607f;
  opacity: 1;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  background-color: ${({ theme }) => theme.color.frisiaPrimary};
  border-radius: 21px 21px 0 0;
  font-family: ${({ theme }) => theme.font.montserratSemiBold};
  color: #ffffff;
  font-size: 14px;
`;

export default withTranslation()(Profitability);
