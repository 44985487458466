import { AxiosError, AxiosResponse } from 'axios';
import ICollectPoint from 'model/collectPoint';
import HttpStatus from 'model/enums/http-status';
import { IMilkMap } from 'model/milkMap';
import milkMapApi from '../../api/cattle/milk-map-api';

export const milkMapService = () => {
  const getCollectPoint = async (registration: number): Promise<ICollectPoint[]> => {
    try {
      const result = await milkMapApi.getCollectPoint(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getMilkMap = async (collectionPointFilter: number, filterDate: string, registration: number): Promise<IMilkMap> => {
    try {
      const result = await milkMapApi.getMilkMap(collectionPointFilter, filterDate, registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getCollectPoint,
    getMilkMap,
  };
};

export default milkMapService();
