import { apiCore } from 'api/api';
import { AxiosResponse } from 'axios';
import md5 from 'md5';
import { OptionsSafraProductsResponse, SalesStatmentsResponses } from 'model/agriculturalSalesStatement';
import { BIReportType } from 'model/bi';
import { IInputOptions, ISession } from 'model/input-report';
import { ITypeProductGroup } from 'model/product-my-stock';
import IScheduleBalance, { ICrop, ICropStock, ICulture } from 'model/schedule-balance';
import { encryptReport, sendLog } from 'shared/util/api-utils';
import StringUtils from 'shared/util/string-utils';
import bcrypt from 'bcryptjs';

export const agriculturalApi = () => {
  const getMyStockData = (registration: string, years: string): Promise<AxiosResponse<ITypeProductGroup[]>> => {
    sendLog(
      'Consulta estoque agricola',
      `/AgricolaEstoque/Dados/?cd_pessoa=${registration}&cd_safra=${years}&cd_linha_produto=0`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ITypeProductGroup[]>(`/AgricolaEstoque/Dados/?cd_pessoa=${registration}&cd_safra=${years}&cd_linha_produto=0`);
  };

  const getCrop = (registration: number): Promise<AxiosResponse<ICrop[]>> => {
    sendLog('Consulta safras agricola', `/AgricolaSaldoProgramacao/SafrasProdutos/?cd_pessoa=${registration}`, JSON.stringify({}), 'get');
    return apiCore.get<ICrop[]>(`/AgricolaSaldoProgramacao/SafrasProdutos/?cd_pessoa=${registration}`);
  };

  const getCulture = (registration: number, crop: string): Promise<AxiosResponse<ICulture[]>> => {
    sendLog(
      'Consultando estoque agricola',
      `/AgricolaSaldoProgramacao/CulturasProdutos/?cd_pessoa=${registration}&cd_safra=${crop}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ICulture[]>(`/AgricolaSaldoProgramacao/CulturasProdutos/?cd_pessoa=${registration}&cd_safra=${crop}`);
  };

  const getScheduleBalance = (registration: number, crop: string, culture: string): Promise<AxiosResponse<IScheduleBalance[]>> => {
    sendLog(
      'Consulta dados safras e culturas',
      `/AgricolaSaldoProgramacao/dadosSafrasECulturas/?cd_pessoa=${registration}&cd_safra=${crop}&cd_cultura=${culture}`,
      JSON.stringify({}),
      'get'
    );

    return apiCore.get<IScheduleBalance[]>(
      `/AgricolaSaldoProgramacao/dadosSafrasECulturas/?cd_pessoa=${registration}&cd_safra=${crop}&cd_cultura=${culture}`
    );
  };

  const getHarvestAndProducts = (register: string): Promise<AxiosResponse<OptionsSafraProductsResponse[]>> => {
    sendLog('Safras e Produtos', `/v2/AgricolaVendas/GetSafrasEProdutos/?cd_pessoa=${register}`, JSON.stringify({}), 'get');
    return apiCore.get<OptionsSafraProductsResponse[]>(`/v2/AgricolaVendas/GetSafrasEProdutos/?cd_pessoa=${register}`);
  };

  const getLiquidatedCreditedAndSummary = (
    register: string,
    years: string,
    cdProduct: string,
    pattern: string,
    type: string
  ): Promise<AxiosResponse<SalesStatmentsResponses>> => {
    sendLog(
      'Consulta vendas agricolas liquidadas e creditadas',
      `/v2/AgricolaVendas/GetLiquidadasECreditadas/?cd_pessoa=${register}&cd_safra=${years}&cd_produto=${cdProduct}&cd_padrao=${pattern}&cd_tipagem=${type}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<SalesStatmentsResponses>(
      `/v2/AgricolaVendas/GetLiquidadasECreditadas/?cd_pessoa=${register}&cd_safra=${years}&cd_produto=${cdProduct}&cd_padrao=${pattern}&cd_tipagem=${type}`
    );
  };

  const getSalesByContracts = (register: string, years: string, cdProduct: string): Promise<AxiosResponse<SalesStatmentsResponses>> => {
    sendLog(
      'Consulta vendas por contratos',
      `/v2//AgricolaVendas/GetVendasPorContratos/?cd_pessoa=${register}&cd_safra=${years}&cd_produto=${cdProduct}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<SalesStatmentsResponses>(
      `/v2//AgricolaVendas/GetVendasPorContratos/?cd_pessoa=${register}&cd_safra=${years}&cd_produto=${cdProduct}`
    );
  };

  const getSalesInvoice = (register: string, years: string, cdProduct: string): Promise<AxiosResponse<SalesStatmentsResponses>> => {
    sendLog(
      'Consultando notas fiscais de venda',
      `/v2//AgricolaVendas/GetNotasFiscaisVendas/?cd_pessoa=${register}&cd_safra=${years}&cd_produto=${cdProduct}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<SalesStatmentsResponses>(
      `/v2//AgricolaVendas/GetNotasFiscaisVendas/?cd_pessoa=${register}&cd_safra=${years}&cd_produto=${cdProduct}`
    );
  };

  const getScheduleBalanceReportLink = (harvestCode: string, registration: number, culture: string) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/DAT/DAT1610&va_cd_safra=${harvestCode}&va_nr_matricula=${registration}&va_cd_cultura=${culture}`;
    sendLog('Gerar relatorio saldo de programação', path, JSON.stringify({}), 'get');
    return encryptReport(path);
  };

  const getBIReportsLink = (register: string): Promise<AxiosResponse<BIReportType[]>> => {
    sendLog(
      'Consulta indicadores por matricula',
      `/Indic/GetIndicadoresByCoopMatricula?cd_matricula=${register}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<BIReportType[]>(`/Indic/GetIndicadoresByCoopMatricula?cd_matricula=${register}`);
  };

  const getCropForMyStock = (): Promise<AxiosResponse<ICropStock[]>> => {
    sendLog('Consulta safra por estoque agricola', `/AgricolaEstoque/GetSafrasSelecEstoqAgric`, JSON.stringify({}), 'get');
    return apiCore.get<ICropStock[]>(`/AgricolaEstoque/GetSafrasSelecEstoqAgric`);
  };

  const getMyStockReportLink = (register: string, years: string) => {
    sendLog(
      'Gerando relatorio meu estoque',
      `https://reports.frisia.coop.br/ReportServer?/SIF/EGR/PosicaoEstoque&CD_PESSOA=${register}&CD_SAFRA=${years}&CD_LINHA_PRODUTO=0`,
      JSON.stringify({}),
      'get'
    );
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/EGR/PosicaoEstoque&CD_PESSOA=${register}&CD_SAFRA=${years}&CD_LINHA_PRODUTO=0`;
    return encryptReport(path);
  };

  const getInputOptions = (register: string): Promise<AxiosResponse<IInputOptions[]>> => {
    sendLog(
      'Consulta informações de safras agricola',
      `/Agri/GetInfoSafrasByMatricula?cd_matricula=${register}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IInputOptions[]>(`/Agri/GetInfoSafrasByMatricula?cd_matricula=${register}`);
  };

  const getSession = (register: string, cropNumber: string, cultureNumber: string): Promise<AxiosResponse<ISession>> => {
    sendLog(
      'Gerando sessão',
      `/Sessao/GetSessaoDAT0490H?CD_MATRICULA=${register}&CD_SAFRA=${cropNumber}&CD_CULTURA=${cultureNumber}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ISession>(`/Sessao/GetSessaoDAT0490H?CD_MATRICULA=${register}&CD_SAFRA=${cropNumber}&CD_CULTURA=${cultureNumber}`);
  };

  const getInputReportLink = (
    register: string,
    crop: string,
    cultureNumber: string,
    purpose: string,
    cropType: string,
    session: string
  ) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/DAT/DAT0490H&va_nr_matricula=${register}&va_cd_safra=${crop}&va_cd_cultura=${cultureNumber}&va_ie_proposito=${purpose}&va_ie_tipo_safra=${cropType}&va_nr_sessao=${session}`;
    sendLog(
      'Gerando relatorio de Insumos',
      `https://reports.frisia.coop.br/ReportServer?/SIF/DAT/DAT0490H&va_nr_matricula=${register}&va_cd_safra=${crop}&va_cd_cultura=${cultureNumber}&va_ie_proposito=${purpose}&va_ie_tipo_safra=${cropType}&va_nr_sessao=${session}`,
      JSON.stringify({}),
      'get'
    );
    return encryptReport(path);
  };

  const generateSalesReport = (registration: number, productCode: string, cropInit: string, cropEnd: string) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/EGR/XXFREGR2450R&CD_PESSOA=${registration}&CD_SAFRA_INI=${cropInit}&CD_SAFRA_FIM=${cropEnd}&CD_PRODUTO=${productCode}`;
    sendLog('Consulta relatorio de farmacos', path, JSON.stringify([]), 'get');

    const urlConvertCharcode = StringUtils.convertToCharCode(path);
    const md5EncryptedUrl = md5(urlConvertCharcode);
    const bcryptEncrypted = bcrypt.hashSync(md5EncryptedUrl);
    const charCodeUrlReportAuth = StringUtils.convertToCharCode(bcryptEncrypted);

    return `https://servicos.frisia.coop.br/RPT/Report/ReportTemplate?ReportId=${urlConvertCharcode}&ReportAuth=${charCodeUrlReportAuth}`;
  };

  return {
    getMyStockData,
    getCrop,
    getCulture,
    getScheduleBalance,
    getHarvestAndProducts,
    getSalesByContracts,
    getLiquidatedCreditedAndSummary,
    getSalesInvoice,
    getScheduleBalanceReportLink,
    getBIReportsLink,
    getCropForMyStock,
    getMyStockReportLink,
    getInputOptions,
    getSession,
    getInputReportLink,
    generateSalesReport,
  };
};

export default agriculturalApi();
