import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import backgroundImageDefault from 'images/frisia/img/large-frisia-building.svg';
import { StyledButton } from 'components/styled-button/StyledButton';
import { useHistory } from 'react-router-dom';

const ClassifiedAds = ({ t }: WithTranslation) => {
  const history = useHistory();
  const [chosencategoryOptions, setChosenCategoryOptions] = useState<string>();
  const [chosenequipmentsOptions, setChosenEquipmentsOptions] = useState<string>();
  const [chosenPublicationOptions, setChosenPublicationOptions] = useState<string>();
  const [categoryOptions, setCategoryOptions] = useState<string[]>(['Todas', 'op2']);
  const [equipmentsOptions, setEquipmentsOptions] = useState<string[]>(['op1', 'op2']);
  const [publicationOptions, setPublicationOptions] = useState<string[]>(['Ultimos 7 Dias', 'op2']);
  const [products, setProducts] = useState<any[]>([
    { name: 'Trator Case II', year: 'Ano 2017', id: '123' },
    { name: 'Trator Case II', year: 'Ano 2017', id: '123' },
    { name: 'Trator Case II', year: 'Ano 2017', id: '123' },
    { name: 'Trator Case II', year: 'Ano 2017', id: '123' },
    { name: 'Trator Case II', year: 'Ano 2017', id: '123' },
    { name: 'Trator Case II', year: 'Ano 2017', id: '123' },
    { name: 'Trator Case II', year: 'Ano 2017', id: '123' },
    { name: 'Trator Case II', year: 'Ano 2017', id: '123' },
    { name: 'Trator Case II', year: 'Ano 2017', id: '123' },
  ]);

  const pushToDetails = itemId => {
    const location = {
      pathname: `/classificados/detalhes/${itemId}`,
    };
    history.push(location);
  };

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('classified.machines')} />
        <StyledFiltersContainer>
          <StyledFilterContainer filterWidth={300}>
            <GenericDropdown
              label={t('classified.category')}
              defaultValue={'Todas'}
              listShowDropdown={categoryOptions}
              getSelectedValue={setChosenCategoryOptions}
            />
          </StyledFilterContainer>
          <StyledFilterContainer filterWidth={350}>
            <GenericDropdown
              label={t('classified.equipment')}
              placeholder={t('classified.select')}
              listShowDropdown={equipmentsOptions}
              getSelectedValue={setChosenEquipmentsOptions}
            />
          </StyledFilterContainer>
          <StyledFilterContainer filterWidth={300}>
            <GenericDropdown
              label={t('classified.publicationDate')}
              listShowDropdown={publicationOptions}
              getSelectedValue={setChosenPublicationOptions}
              defaultValue={'Ultimos 7 Dias'}
            />
          </StyledFilterContainer>
        </StyledFiltersContainer>
        <CardsContainer>
          {products.map((item, index) => (
            <StyledAdCardContainer key={index} onClick={() => pushToDetails(item.id)}>
              <img src={backgroundImageDefault} />
              <div>
                <div>{item.name}</div>
                <div>{item.year}</div>
              </div>
              <StyledButton color="frisiaPrimary" style={{ minWidth: '120px', alignSelf: 'center', whiteSpace: 'nowrap' }}>
                <span>{t('global.button.viewMore')}</span>
              </StyledButton>
            </StyledAdCardContainer>
          ))}
        </CardsContainer>
      </StyledPageTitleContainer>
    </>
  );
};

const StyledFiltersContainer = styled.div`
  position: relative;
  width: '100%';
  min-height: 53px;
  max-height: 140px;
  display: flex;
  flex-direction: row;
  div {
    margin-right: 5px;
  }
`;

const CardsContainer = styled.div`
  flex-flow: wrap;
  display: flex;
  flex-direction: row;
`;

const StyledAdCardContainer = styled.div`
  width: 344px;
  height: 239px;
  background: var(--surface) 0% 0% no-repeat padding-box;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1d1d1d29;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  margin: 5px;
  justify-content: space-around;
  flex-flow: wrap;
  img {
    width: 344px;
    height: 152px;
    object-fit: cover;
  }
  div:first-child {
    width: 129px;
    height: 22px;
    font-size: 18px;
    letter-spacing: 0px;
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary};
  }
  div {
    text-align: left;
  }
`;

export default withTranslation()(ClassifiedAds);
