import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Modal } from '@mui/material';
import mySquadApi from 'api/my-squad-api';
import AnimalDetailsCard from 'components/AnimalDetailsCard';
import ButtonDownload from 'components/ButtonDownload';
import GenericButtonOragenNoBorder from 'components/GenericButtonOragenNoBorder';
import GenericButtonWhiteWithYellow from 'components/GenericButtonWhiteWithYellow';
import GenericDropdown from 'components/GenericDropdown';
import GenericInputToTable from 'components/GenericInputToTable';
import MainPageTitle from 'components/MainPageTitle';
import RadioButton from 'components/RadioButton';
import { StyledButton } from 'components/styled-button/StyledButton';
import WhiteAndBlueFilterCard from 'components/WhiteAndBlueFilterCard';
import WhiteDropdownFilter from 'components/WhiteDropdownFilter';
import FilterCardType from 'model/enums/FilterCardType';
import IMyAnimal from 'model/my-animal';
import ITypeFilterCard from 'model/TypeFilterCard';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import mySquadService from 'services/cattle/my-squad-service';
import { dateList } from 'shared/util/date-utils';
import styled from 'styled-components';
import StyledCardsFilterContainer from 'styled-components/StyledCardsFilterContainer';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import * as yup from 'yup';
import { ReactComponent as HeifersIconSvg } from '../../../images/frisia/icons/blue-heifers-icon.svg';
import { ReactComponent as MaleIconSvg } from '../../../images/frisia/icons/blue-males-icon.svg';
import { ReactComponent as NewbornIconSvg } from '../../../images/frisia/icons/blue-newborn-icon.svg';
import { ReactComponent as PhotographicCameraIconSvg } from '../../../images/frisia/icons/blue-photographic-camera.svg';
import { ReactComponent as PlusSvg } from '../../../images/frisia/icons/blue-plus-icon.svg';
import { ReactComponent as SquadManagementIconSvg } from '../../../images/frisia/icons/blue-squad-management-icon.svg';
import { ReactComponent as ThreeDotsIconSvg } from '../../../images/frisia/icons/blue-three-dots-icon.svg';

interface IForm {
  property: string;
  animalName: string;
  animalNumber: string;
  birthDate: string;
  animalSex: AnimalSex;
  animalBreed: string;
  animalMotherName: string;
  animalFatherName: string;
  motherNecklaceNumber: string;
  motherEarringNumber: string;
  typeOfAnimal: TypeOfAnimal;
}

enum TypeOfAnimal {
  NEWBORN = 'NEWBORN',
  HEIFER = 'HEIFER',
  COW = 'COW',
  MALE = 'MALE',
}

enum AnimalSex {
  MALE = 'Macho',
  FEMALE = 'Fêmea',
}

const dropdownSexItems: AnimalSex[] = [AnimalSex.MALE, AnimalSex.FEMALE];

const MySquad = ({ t }: WithTranslation) => {
  const [dropdownValues, setDropdownValues] = useState<IForm>({ typeOfAnimal: TypeOfAnimal.NEWBORN } as IForm);
  const [selectedFilterCard, setSelectedFilterCard] = useState<FilterCardType>(FilterCardType.ALL);

  const [isResetInputValue, setIsResetInputValue] = useState<boolean>(false);
  const [isShowUploadModal, setIsShowUploadModal] = useState<boolean>(false);
  const [isShowRegisterModal, setIsShowRegisterModal] = useState<boolean>(false);

  const [showAsList, setShowAsList] = useState<boolean>(false);

  const [myAnimals, setMyAnimals] = useState<IMyAnimal[]>([]);

  const [reportMySquad, setReportMySquad] = useState<string>();

  const mockDropdown = dateList();

  const schema = yup.object({
    property: yup.string().test('test-property', t('global.errorMessage.required'), () => dropdownValues.property != null),
    animalName: yup.string().required(),
    animalNumber: yup.string().required(),
    birthDate: yup.string().test('test-birthDate', t('global.errorMessage.required'), () => dropdownValues.birthDate != null),
    animalSex: yup.string().test('test-animalSex', t('global.errorMessage.required'), () => dropdownValues.animalSex != null),
    animalBreed: yup.string().required(),
    animalMotherName: yup.string().required(),
    animalFatherName: yup.string().required(),
    motherNecklaceNumber: yup.string().required(),
    motherEarringNumber: yup.string().required(),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getReport = () => {
    setReportMySquad(mySquadApi.getMySquadReport(getUserRegistration));
  };

  useEffect(() => {
    mySquadService.getMyAnimals().then(setMyAnimals);
    getReport();
  }, []);

  const handleClearForm = () => {
    setIsResetInputValue(true);
    setDropdownValues({ typeOfAnimal: TypeOfAnimal.NEWBORN } as IForm);
    methods.reset({
      animalName: '',
      animalNumber: '',
      animalBreed: '',
      animalMotherName: '',
      animalFatherName: '',
      motherNecklaceNumber: '',
      motherEarringNumber: '',
    });
    setTimeout(() => setIsResetInputValue(false), 500);
  };

  const handleSubmit = (data: IForm) => {
    setIsShowRegisterModal(true);
  };

  const cards: ITypeFilterCard[] = [
    { icon: <ThreeDotsIconSvg />, text: t('livestock.all'), value: FilterCardType.ALL },
    { icon: <NewbornIconSvg />, text: t('livestock.newborn'), value: FilterCardType.NEWBORNS },
    { icon: <HeifersIconSvg />, text: t('livestock.heifers'), value: FilterCardType.HEIFERS },
    { icon: <SquadManagementIconSvg />, text: t('livestock.cows'), value: FilterCardType.COWS },
    { icon: <MaleIconSvg />, text: t('livestock.males'), value: FilterCardType.MALES },
    { icon: <PlusSvg style={{ width: '40px' }} />, text: t('livestock.bovineRegister'), value: FilterCardType.NEW },
  ];

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('livestock.mySquad')} />
      </StyledPageTitleContainer>

      <StyledFilterContainer>
        <GenericDropdown
          listShowDropdown={mockDropdown}
          label={t('global.field.propertie')}
          placeholder={t('global.field.selectHere')}
          getSelectedValue={value => {
            /* empty */
          }}
        />
      </StyledFilterContainer>

      <WhiteDropdownFilter
        listShowDropdown={mockDropdown}
        getSelectedValue={value => {
          /* empty */
        }}
      />

      <div style={{ marginTop: '15px' }}>
        {reportMySquad && <ButtonDownload href={reportMySquad} style={{ width: '174px', marginTop: '15px' }} />}
      </div>
      <StyledCardsFilterContainer>
        {cards.map((item, index) => (
          <WhiteAndBlueFilterCard
            isCardActive={item.value === selectedFilterCard}
            icon={item.icon}
            text={item.text}
            onClick={() => setSelectedFilterCard(item.value)}
            key={`card-${index}`}
          />
        ))}
      </StyledCardsFilterContainer>

      {selectedFilterCard !== FilterCardType.NEW && (
        <>
          <StyledPageTitleContainer margin={'35px 0 25px 0'}>
            <div style={{ display: 'flex' }}>
              <MainPageTitle fontSize={30} text={t('livestock.myAnimals')} />
              <StyledButton
                color="frisiaPrimary"
                type="button"
                style={{ minWidth: '60px', alignSelf: 'center' }}
                onClick={() => setShowAsList(!showAsList)}
              >
                <span>{showAsList ? t('livestock.cards') : t('livestock.list')}</span>
              </StyledButton>
            </div>
          </StyledPageTitleContainer>

          <StyledAnimalsContaienr>
            {myAnimals.map(animal => (
              <AnimalDetailsCard list={showAsList} animalInformation={animal} key={`my-animal-${animal.id}`} />
            ))}
          </StyledAnimalsContaienr>
        </>
      )}

      {selectedFilterCard === FilterCardType.NEW && (
        <>
          <StyledPageTitleContainer margin={'35px 0 25px 0'}>
            <MainPageTitle fontSize={30} text={t('livestock.newBovineRegister')} />
          </StyledPageTitleContainer>

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <Grid container columnSpacing="17px">
                <Grid item xs={12} lg={6}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('global.field.property')}
                      placeholder={t('global.field.selectHere')}
                      listShowDropdown={mockDropdown}
                      getSelectedValue={value => setDropdownValues({ ...dropdownValues, property: value })}
                      isResetValue={isResetInputValue}
                      errorText={methods.formState.errors.property?.message}
                    />
                  </StyledFilterContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledRadioButtonContainer>
                    <RadioButton
                      isDefaultChecked={dropdownValues.typeOfAnimal === TypeOfAnimal.NEWBORN}
                      name="typeOfAnimal"
                      value={String(t('global.field.newborn'))}
                      id="newborn"
                      handleClick={() => setDropdownValues({ ...dropdownValues, typeOfAnimal: TypeOfAnimal.NEWBORN })}
                      style={{ display: 'inline-flex', marginBottom: '0' }}
                    />
                    <RadioButton
                      name="typeOfAnimal"
                      value={String(t('global.field.heifer'))}
                      id="heifer"
                      handleClick={() => setDropdownValues({ ...dropdownValues, typeOfAnimal: TypeOfAnimal.HEIFER })}
                      style={{ display: 'inline-flex', marginBottom: '0' }}
                    />
                    <RadioButton
                      name="typeOfAnimal"
                      value={String(t('global.field.cow'))}
                      id="cow"
                      handleClick={() => setDropdownValues({ ...dropdownValues, typeOfAnimal: TypeOfAnimal.COW })}
                      style={{ display: 'inline-flex', marginBottom: '0' }}
                    />
                    <RadioButton
                      name="typeOfAnimal"
                      value={String(t('global.field.male'))}
                      id="male"
                      handleClick={() => setDropdownValues({ ...dropdownValues, typeOfAnimal: TypeOfAnimal.MALE })}
                      style={{ display: 'inline-flex', marginBottom: '0' }}
                    />
                  </StyledRadioButtonContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledInputContainer>
                    <GenericInputToTable
                      inputName="animalName"
                      label={t('livestock.animalName')}
                      placeholder={t('livestock.enterAnimalName')}
                      isResetValue={isResetInputValue}
                      errorText={methods.formState.errors.animalName?.message}
                      style={{ width: '85%' }}
                    />
                    <PhotographicCameraIconSvg
                      onClick={() => setIsShowUploadModal(true)}
                      style={{ margin: '0 auto 15px auto', cursor: 'pointer' }}
                    />
                  </StyledInputContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledInputContainer>
                    <GenericInputToTable
                      inputName="animalNumber"
                      label={t('livestock.animalNumber')}
                      placeholder={t('livestock.enterAnimalNumber')}
                      isResetValue={isResetInputValue}
                      errorText={methods.formState.errors.animalNumber?.message}
                      style={{ width: '70%' }}
                    />
                    <GenericButtonOragenNoBorder
                      textButton={t('global.button.generateCode')}
                      type="button"
                      style={{ marginTop: '-18px' }}
                    />
                  </StyledInputContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('livestock.birthDate')}
                      placeholder={t('global.field.selectHere')}
                      listShowDropdown={mockDropdown}
                      getSelectedValue={value => setDropdownValues({ ...dropdownValues, birthDate: value })}
                      isResetValue={isResetInputValue}
                      errorText={methods.formState.errors.birthDate?.message}
                    />
                  </StyledFilterContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('livestock.animalSex')}
                      placeholder={t('global.field.selectHere')}
                      listShowDropdown={dropdownSexItems}
                      getSelectedValue={(value: AnimalSex) => setDropdownValues({ ...dropdownValues, animalSex: value })}
                      isResetValue={isResetInputValue}
                      errorText={methods.formState.errors.animalSex?.message}
                    />
                  </StyledFilterContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <GenericInputToTable
                    inputName="animalBreed"
                    label={t('livestock.animalBreed')}
                    placeholder={t('livestock.enterAnimalBreed')}
                    isResetValue={isResetInputValue}
                    errorText={methods.formState.errors.animalBreed?.message}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <GenericInputToTable
                    inputName="animalMotherName"
                    label={t('livestock.animalMother')}
                    placeholder={t('livestock.enterAnimalMotherName')}
                    isResetValue={isResetInputValue}
                    errorText={methods.formState.errors.animalMotherName?.message}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <GenericInputToTable
                    inputName="animalFatherName"
                    label={t('livestock.animalFatherName')}
                    placeholder={t('livestock.enterAnimalFatherName')}
                    isResetValue={isResetInputValue}
                    errorText={methods.formState.errors.animalFatherName?.message}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledInputContainer>
                    <GenericInputToTable
                      inputName="motherNecklaceNumber"
                      label={t('livestock.motherNecklaceNumber')}
                      placeholder={t('livestock.enterMotherNecklaceNumber')}
                      isResetValue={isResetInputValue}
                      errorText={methods.formState.errors.motherNecklaceNumber?.message}
                      style={{ width: '85%' }}
                    />
                    <PhotographicCameraIconSvg
                      onClick={() => setIsShowUploadModal(true)}
                      style={{ margin: '0 auto 15px auto', cursor: 'pointer' }}
                    />
                  </StyledInputContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <GenericInputToTable
                    inputName="motherEarringNumber"
                    label={t('livestock.motherEarringNumber')}
                    placeholder={t('livestock.enterMotherEarringNumber')}
                    isResetValue={isResetInputValue}
                    errorText={methods.formState.errors.motherEarringNumber?.message}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledButtonsContainer>
                    <GenericButtonWhiteWithYellow
                      textButton={t('global.button.clearData')}
                      width={185}
                      type="button"
                      onClick={() => handleClearForm()}
                    />
                    <StyledButton color="frisiaPrimary" type="submit" style={{ minWidth: '205px', alignSelf: 'center' }}>
                      <span>{t('global.button.register')}</span>
                    </StyledButton>
                  </StyledButtonsContainer>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </>
      )}

      <Modal open={isShowUploadModal} onClose={() => setIsShowUploadModal(false)}>
        <StyledContainerModal>
          <StyledPageTitleContainer margin={'0 0 50px 0'}>
            <MainPageTitle fontSize={30} text={t('livestock.selectPhoto')} />
          </StyledPageTitleContainer>

          <StyledInputFile type="file" />
        </StyledContainerModal>
      </Modal>

      <Modal open={isShowRegisterModal} onClose={() => setIsShowRegisterModal(false)}>
        <StyledContainerModal>
          <StyledPageTitleContainer margin={'0'}>
            <MainPageTitle fontSize={30} text={t('livestock.registeredAnimal')} style={{ textAlign: 'center' }} />
          </StyledPageTitleContainer>

          <StyleIdentificationContainer>
            <Trans i18nKey="livestock.identification">
              <span>{{ text: '' }}</span>
            </Trans>
          </StyleIdentificationContainer>

          <StyledButtonContainer>
            <StyledButton color="frisiaPrimary" type="submit" style={{ minWidth: '280px' }}>
              <span>{t('global.button.showDetails')}</span>
            </StyledButton>
          </StyledButtonContainer>
        </StyledContainerModal>
      </Modal>
    </>
  );
};

const StyledAnimalsContaienr = styled.div`
  display: flex;
  column-gap: 2.1%;
  row-gap: 35px;
  flex-wrap: wrap;
`;

const StyledRadioButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 35px;
  row-gap: 10px;
  margin-bottom: 15px;
`;

const StyledButtonsContainer = styled.div`
  height: 75%;
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

const StyledInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledContainerModal = styled.div`
  position: absolute;
  top: 25%;
  left: 40%;
  width: 685px;
  height: 175px;
  padding: 15px 35px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
`;

const StyledInputFile = styled.input`
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: #656565;
`;

const StyledButtonContainer = styled.div`
  text-align: center;
`;

const StyleIdentificationContainer = styled.div`
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: #656565;
  font-size: 20px;
  margin: 10px 0 11px 0;
`;

export default withTranslation()(MySquad);
