import authenticationApi from 'api/authentication-api';
import searchAccountApi from 'api/seach-account-api';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { AccountType } from 'model/account';
import { CoreBodyRequest, CoreUserInfoReponse } from 'model/coreResponse';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import AuthUtils, { IdentificationKeyLocalStorage } from 'shared/util/auth-utils';
import {
  getSessionError,
  getSessionRequest,
  getSessionSuccess,
  loginError,
  loginRequest,
  loginSuccess,
  logoutRequest,
  setCoreSuccess,
  setExtranetFilesSuccess,
  setIndicatorsSuccess,
  setListOfPages,
  setSelectedUser,
} from './actions';
import { AuthenticationActionTypes } from './types';

function* handleLogin(action: ReturnType<typeof loginRequest>) {
  try {
    const base64Password = action.payload.base64_password;
    const userName = action.payload.username;
    delete action.payload.base64_password;

    const body: CoreBodyRequest = {
      NM_USUARIO_AD: userName,
      DS_SENHA: base64Password,
      NM_APLICATIVO: 'Extranet',
      CD_VERSAO: 0,
    };

    const bodyIndicators = 'grant_type=password&username=frisia&password=Senh@111';

    const resultCore: AxiosResponse<CoreUserInfoReponse> = yield call(authenticationApi.coreUserInfo, body);
    const tokenIndicators: AxiosResponse<CoreUserInfoReponse> = yield call(authenticationApi.getTokenIndicators, bodyIndicators);

    if (resultCore.status !== 200 || tokenIndicators.status !== 200) {
      const errorMessage: string = resultCore.status === 401 ? 'badCredentials' : 'An unknown error occured.';
      yield put(loginError(errorMessage));
      return;
    } else {
      const currentTime = Math.floor(new Date().getTime() / 1000.0);
      if (resultCore.data.expires_in) {
        resultCore.data.expires_in = resultCore.data.expires_in + currentTime;
      }
      if (tokenIndicators.data.expires_in != null) {
        tokenIndicators.data.expires_in = tokenIndicators.data.expires_in + currentTime;
      }
      AuthUtils.setCore(JSON.stringify(resultCore.data));
      AuthUtils.setToken(IdentificationKeyLocalStorage.INDICATORS_TOKEN, JSON.stringify(tokenIndicators.data));
      yield put(setCoreSuccess(resultCore.data));
      yield put(getSessionRequest());
      yield put(loginSuccess());
      yield put(setIndicatorsSuccess(tokenIndicators.data));
      yield put(setExtranetFilesSuccess(tokenIndicators.data));

      return;
    }
  } catch (err: any) {
    if (err) {
      yield put(loginError(err.response.data.ExceptionMessage));
    } else {
      yield put(loginError('An unknown error occured.'));
    }
  }
}

function* handleGetSession() {
  try {
    const core: string = AuthUtils.getToken(IdentificationKeyLocalStorage.CORE_RESPONSE) ?? '';
    const jsonCore: any = JSON.parse(core);
    const selected = AuthUtils.getSelectedinLocal() ?? '';
    const currentTime = Math.floor(new Date().getTime() / 1000.0);
    const indicatorsToken: string = AuthUtils.getToken(IdentificationKeyLocalStorage.INDICATORS_TOKEN) ?? '';
    const jsonIndicators: any = JSON.parse(indicatorsToken);
    const extranetFilesToken: string = AuthUtils.getToken(IdentificationKeyLocalStorage.CORE_RESPONSE) ?? '';
    const jsonExtranetFiles: any = JSON.parse(extranetFilesToken);

    if (
      !isEmpty(core) &&
      jsonCore.expires_in > currentTime &&
      !isEmpty(indicatorsToken) &&
      jsonIndicators.expires_in > currentTime &&
      !isEmpty(extranetFilesToken)
    ) {
      yield put(setCoreSuccess(jsonCore));
      yield put(getSessionSuccess(jsonCore));
      yield put(setIndicatorsSuccess(jsonIndicators));
      yield put(setExtranetFilesSuccess(jsonExtranetFiles));
      if (selected !== '' && jsonCore.cd_perfil === 3) {
        yield put(setSelectedUser(JSON.parse(selected)));
      }
    } else {
      yield put(getSessionError('Error: token Timedout'));
      yield put(logoutRequest());
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getSessionError(err.stack));
    } else {
      yield put(getSessionError('An unknown error occured.'));
    }
    yield put(logoutRequest());
  }
}

function* handleSelected(action: ReturnType<typeof setSelectedUser>) {
  const selected: AccountType = action.payload;
  const state = yield select();
  console.log(state.authentication.accountInfo);
  if (state.authentication.accountInfo.cd_perfil === 3) {
    AuthUtils.setSelectedinLocal(JSON.stringify(selected));
  }
  const params = [state.authentication.accountInfo.nm_usuario, action.payload.CD_PESSOA];
  const result: AxiosResponse<any> = yield call(searchAccountApi.accountPagesList, params);
  if (result.status === 200) {
    yield put(setListOfPages(result.data));
    return;
  }
}

function* handleLogout() {
  try {
    localStorage.clear();
  } catch (err) {
    console.log({ 'Logout Error': err });
  }
}

function* watchLoginRequest() {
  yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
}

function* watchGetSessionRequest() {
  yield takeEvery(AuthenticationActionTypes.GET_SESSION_REQUEST, handleGetSession);
}

function* watchLogoutRequest() {
  yield takeEvery(AuthenticationActionTypes.LOGOUT_REQUEST, handleLogout);
}

function* watchSelected() {
  yield takeEvery(AuthenticationActionTypes.SELECTED, handleSelected);
}

function* authenticationSaga() {
  yield all([fork(watchLoginRequest), fork(watchGetSessionRequest), fork(watchLogoutRequest), fork(watchSelected)]);
}

export default authenticationSaga;
