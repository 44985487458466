import { AxiosResponse } from 'axios';
import { BranchType, DischargeType } from 'model/discharge';
import { LoadOptionsProduct, LoadOptionsSafraProductsResponse, LoadOResponse } from 'model/load';
import { encryptReport, sendLog } from 'shared/util/api-utils';
import { apiCore } from './api';

export const loadApi = () => {
  const getHarvestAndProducts = (register: string): Promise<AxiosResponse<LoadOptionsSafraProductsResponse[]>> => {
    sendLog('Consulta de safras e produtso', `/AgricolaCargaCarga/SafrasProdutos/?cd_pessoa=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<LoadOptionsSafraProductsResponse[]>(`/AgricolaCargaCarga/SafrasProdutos/?cd_pessoa=${register}`);
  };

  const getBranchs = (register: string): Promise<AxiosResponse<BranchType[]>> => {
    sendLog('Consulta de filiais', `/AgricolaDescargaProducao/Filiais/?cd_pessoa=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<BranchType[]>(`/AgricolaDescargaProducao/Filiais/?cd_pessoa=${register}`);
  };

  const getProducts = (register: string): Promise<AxiosResponse<LoadOptionsProduct[]>> => {
    sendLog(
      'Consulta produtos descarga de produtos',
      `/AgricolaDescargaProducao/Produtos/?cd_pessoa=${register}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<LoadOptionsProduct[]>(`/AgricolaDescargaProducao/Produtos/?cd_pessoa=${register}`);
  };

  const getInfo = (register: string, harvest: string, product: string, plate: string): Promise<AxiosResponse<LoadOResponse>> => {
    sendLog(
      'Consulta dados de carga a carga',
      `/AgricolaCargaCarga/Dados/?cd_pessoa=${register}&cd_safra=${harvest}&cd_produto=${product}&cd_placa=${plate}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<LoadOResponse>(
      `/AgricolaCargaCarga/Dados/?cd_pessoa=${register}&cd_safra=${harvest}&cd_produto=${product}&cd_placa=${plate}`
    );
  };

  const getLoadLoadReport = (register: string, harvest: string, product: string, plate: string) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/EGR/XXFREGR8582R&CD_PESSOA=${register}&CD_SAFRA=${harvest}&CD_PRODUTO=${product}&CD_PLACA=${plate}`;
    sendLog('Consulta relatorio de carga a carga', path, JSON.stringify([]), 'get');
    return encryptReport(path);
  };

  const getProductionDischarge = (
    register: string,
    branchNumber: number,
    product: string,
    initDate: string,
    endDate: string
  ): Promise<AxiosResponse<DischargeType[]>> => {
    sendLog(
      'Consulta descarga de produção',
      `/AgricolaDescargaProducao/Dados/?nr_matricula=${register}&cd_filial=${branchNumber}&cd_produto=${product}&dt_inicio=${initDate}&dt_termino=${endDate}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<DischargeType[]>(
      `/AgricolaDescargaProducao/Dados/?nr_matricula=${register}&cd_filial=${branchNumber}&cd_produto=${product}&dt_inicio=${initDate}&dt_termino=${endDate}`
    );
  };

  return {
    getHarvestAndProducts,
    getInfo,
    getBranchs,
    getProducts,
    getProductionDischarge,
    getLoadLoadReport,
  };
};

export default loadApi();
