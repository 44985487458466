export const WeatherIcons = {
  '01d': '☀️',
  '01n': '☀️',
  '02d': '🌤️',
  '02n': '🌤️',
  '03d': '⛅',
  '03n': '⛅',
  '04n': '☁️',
  '04d': '☁️',
  '09n': '🌧️',
  '09d': '🌧️',
  '10d': '🌦️',
  '10n': '🌦️',
  '11d': '⛈️',
  '11n': '⛈️',
  '13d': '❄️',
  '13n': '❄️',
  '50d': '🌫️',
  '50n': '🌫️',
};
