import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import toastUtils from 'shared/util/toast-utils';
import fuelAuthorizationApi from 'api/fuel-authorization-api';
import { IAuths, IAuthsTherme, INew } from 'model/fuel-authorization';
import i18n from 'config/i18n';
import { IMessage } from 'model/account-allow-pages';

export const fuelAuthorizationService = () => {
  const getMyAuths = async (register: string) => {
    try {
      const result: AxiosResponse<IAuths[]> = await fuelAuthorizationApi.getMyAuths(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAcepetTherme = async () => {
    try {
      const result: AxiosResponse<IAuthsTherme> = await fuelAuthorizationApi.getAcepetTherme('CONVENIO_POSTO');
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const authNew = async (newAuth: INew): Promise<IMessage> => {
    try {
      const result = await fuelAuthorizationApi.authNew(newAuth);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast(result.data.message);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.Message);
      return Promise.reject(response);
    }
  };

  return {
    getMyAuths,
    getAcepetTherme,
    authNew,
  };
};

export default fuelAuthorizationService();
