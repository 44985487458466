import MainPageTitle from 'components/MainPageTitle';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import NewsTable from 'components/NewsTable';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PodcastPlayer from 'components/podcast-player/PodcastPlayer';
import { CategoryCardContainer, VideoCardContainer } from 'components/podcast-player/StyledComponents';
import contentfulService from 'services/contentful-service';
import { HashLink } from 'react-router-hash-link';
import { ICategoriesList, IContentTypeCategories } from 'model/contentful-results';

const StyledTablesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 37px;
  width: 100%;
  margin: auto;
  zoom: 122%;
  @media (max-width: 1775px) {
    zoom: 105%;
  }
  @media (max-width: 1580px) {
    zoom: 95%;
  }
  @media (max-width: 1349px) {
    zoom: 90%;
    justify-content: center;
  }
  @media (max-width: 1069px) {
    zoom: 80%;
  }
`;

const StyledCardsContainer = styled.div`
  max-width: 1130px;
  margin-top: 10px;
  display: flex;
  flex-flow: wrap;
  align-content: space-between;
  width: 400px;
  height: 300px;
  justify-content: space-between;
`;

const StyledTablesLeftContainer = styled.div`
  width: 49%;
  width: unset;
  min-width: 375px;
  margin-right: 62px;

  /* fix arrows slider safari */
  .slick-next {
    width: 30px;
    height: 30px;
  }

  .slick-prev {
    width: 65px;
    height: 30px;
  }
`;

const StyledTablesRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: 375px;
`;

const CarouselStyled = styled.div`
  width: 1080px;

  /* fix arrows slider safari */
  .slick-next {
    width: 30px;
    height: 30px;
  }

  .slick-prev {
    width: 45px;
    height: 30px;
  }

  @media (max-width: 787px) {
    min-width: initial;
    max-width: 750px;
    align-items: center;
    flex-wrap: nowrap;
  }
`;

const FavoritesContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

const StyledText = styled.div`
  max-width: 119px;
  text-align: center;
`;

const Channel = ({ t }: WithTranslation) => {
  const history = useHistory();

  const [checkState, setCheckState] = useState<boolean>(true);
  const [newsArray, setNewsArray] = useState<any[]>([]);
  const [newsImages, setNewsImages] = useState<any[]>([]);
  const [contentVideos, setContentVIdeos] = useState<any[]>([]);
  const [contentAudios, setContentAudios] = useState<any[]>([]);
  const [categories, setCategories] = useState<ICategoriesList[]>([]);

  const getContent = async () => {
    const result = await contentfulService.getContentInFocusLimited();
    setNewsArray(result.items);
    setNewsImages(result.includes.Asset);

    const videosResult = await contentfulService.getAllVideos();
    setContentVIdeos(videosResult.items);

    const podcastsResult = await contentfulService.getAllAudios();
    setContentAudios(
      podcastsResult.includes.Asset.sort((a, b) => new Date(b.sys.createdAt).getTime() - new Date(a.sys.createdAt).getTime())
    );
  };

  const getImage = file => {
    const imgID = file?.fields?.summaryImage?.sys?.id ?? '';
    const arrayWithUrlImg = newsImages.filter(item => item.sys.id === imgID);
    return `https:${arrayWithUrlImg[0]?.fields?.file?.url}`;
  };

  const getCategories = async () => {
    const result: IContentTypeCategories = await contentfulService.getAllCategories();
    setCategories(
      result.items.map(category => {
        return { name: category.fields.title, id: category.sys.id };
      })
    );
  };

  useEffect(() => {
    async function callAsync() {
      await getContent();
      await getCategories();
    }
    callAsync();
  }, []);

  const settingsNews = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 0.95,
    slidesToScroll: 1,
  };

  const settingsVideos = {
    autoplay: true,
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 5,
  };

  const returnSettingsAudio = Audiolength => {
    const settingsAudio = {
      centerMode: false,
      dots: false,
      speed: 500,
      slidesToShow: Audiolength > 2 ? 2 : Audiolength,
      slidesToScroll: 2,
      pauseOnFocus: true,
    };
    return settingsAudio;
  };

  const CarouselNewsStyle = {
    marginTop: '71px',
    minWidth: '520px',
    maxWidth: '545px',
    width: '542px',
  };

  const pushToPlayer = videoId => {
    const location = {
      pathname: `/canal-frisia/play/${videoId}`,
    };
    history.push(location);
  };

  const handlePlayerClick = async (stateOfPlay, updateStatePlay, indexOfPlay) => {
    const audioElement: HTMLAudioElement = (await document.getElementById(`audio-${indexOfPlay}`)) as HTMLAudioElement;
    const pauseOtherAudios = async () => {
      for (let i = 0; i < contentAudios.length; i++) {
        if (indexOfPlay != i) {
          let otherAudioElement: HTMLAudioElement = (await document.getElementById(`audio-${i}`)) as HTMLAudioElement;
          await otherAudioElement?.pause();
          let ulElement = document.getElementById(`bares-container-${i}`);
          ulElement?.setAttribute('class', '');
        }
      }
    };

    if (stateOfPlay === `play-${indexOfPlay}`) {
      pauseOtherAudios();
      audioElement?.play();
      updateStatePlay('pause');
    } else {
      audioElement?.pause();
      updateStatePlay(`play-${indexOfPlay}`);
    }
    setCheckState(!checkState);
  };

  return (
    <>
      <StyledTablesContainer>
        <StyledTablesLeftContainer>
          <div style={CarouselNewsStyle}>
            <Slider {...settingsNews}>
              {newsArray.map((item, index) => (
                <div key={`sliderNews-${index}`}>
                  <NewsTable
                    backgroundImage={getImage(item)}
                    link={item?.fields?.link ?? ''}
                    title={item?.fields?.title ?? ''}
                    subtitle={item?.fields?.subtitle ?? ''}
                    summary={item?.fields?.summary ?? ''}
                    key={index}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </StyledTablesLeftContainer>
        <StyledTablesRightContainer>
          <FavoritesContainerStyled>
            <StyledPageTitleContainer style={{ display: 'flex' }} margin={'0px'}>
              <MainPageTitle fontSize={16} text={'CATEGORIAS'} />
            </StyledPageTitleContainer>
            <StyledCardsContainer>
              {[...categories, { name: t('agricultural.all'), id: '' }].map((item, index) => (
                <div key={`categories-${index}`}>
                  <HashLink to={`/canal-frisia/videos/#${item.id}`}>
                    <CategoryCardContainer>
                      <StyledText>{item.name}</StyledText>
                    </CategoryCardContainer>
                  </HashLink>
                </div>
              ))}
            </StyledCardsContainer>
          </FavoritesContainerStyled>
        </StyledTablesRightContainer>

        <div style={{ margin: 'auto' }}>
          <StyledPageTitleContainer style={{ display: 'flex' }} margin={'0px'}>
            <MainPageTitle fontSize={16} text={'Vídeos'} />
          </StyledPageTitleContainer>
          <CarouselStyled>
            <Slider {...settingsVideos}>
              {contentVideos.map((video, index) => (
                <VideoCardContainer key={`contentvideo-${index}`} onClick={() => pushToPlayer(video.sys.id)}>
                  <iframe id="video" width="150" height="99" src={video.fields.link}></iframe>
                  <div>{video.fields.title}</div>
                </VideoCardContainer>
              ))}
            </Slider>
          </CarouselStyled>
        </div>
        <div style={{ margin: 'auto' }}>
          <StyledPageTitleContainer style={{ display: 'flex' }} margin={'0px'}>
            <MainPageTitle fontSize={16} text={'Podcasts'} />
          </StyledPageTitleContainer>

          <CarouselStyled>
            {contentAudios.length > 0 && (
              <Slider {...returnSettingsAudio(contentAudios.length)}>
                {contentAudios.map((item, index) => (
                  <PodcastPlayer
                    checkState={checkState}
                    handlePlayerClick={handlePlayerClick}
                    key={`audio-${index}`}
                    index={index}
                    createdAt={item.sys.createdAt ?? ''}
                    song={item.fields.file.url ?? ''}
                    fileName={item.fields.file.fileName ?? ''}
                    title={item.fields.title ?? ''}
                  />
                ))}
              </Slider>
            )}
          </CarouselStyled>
        </div>
      </StyledTablesContainer>
    </>
  );
};

export default withTranslation()(Channel);
