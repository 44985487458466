import MainPageTitle from 'components/MainPageTitle';
import { IUnreadContent } from 'model/contentful-results';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import contentfulService from 'services/contentful-service';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { dateIsGreaterThanToday } from 'shared/util/date-utils';
import GenericDropdown from 'components/GenericDropdown';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';

const Announcements = props => {
  const { t } = useTranslation();
  const [newsAnnouncements, setNewsAnnouncements] = useState<IUnreadContent[]>([]);
  const [thisMonth, setThisMonth] = useState<IUnreadContent[]>([]);
  const [filterBy, setFilterBy] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [readContent, setReadContent] = useState<IUnreadContent[]>([]);
  const [notReadContent, setNotReadContent] = useState<IUnreadContent[]>([]);

  const [read, setRead] = useState<string[]>([]);

  const [markedAsRead, setMarkedAsRead] = useState<string[]>([]);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getData = async () => {
    setLoading(true);
    const readResult = await contentfulService.getReadAnnouncements(getUserRegistration);
    setReadContent(readResult);
    setRead(readResult.map(item => item.sys.id));
    const notReadresult = await contentfulService.getNotReadAnnouncements(getUserRegistration);
    setNotReadContent(notReadresult);
    setNewsAnnouncements([...notReadresult, ...readResult]);
    setLoading(false);
    setThisMonth(
      [...notReadresult, ...readResult].filter(item => moment(item.fields.publicationDate).format('MMMM') === moment().format('MMMM'))
    );
  };

  const markRead = async id => {
    await contentfulService.markAsRead(getUserRegistration, id).then(response => {
      setMarkedAsRead(oldValues => [...oldValues, id]);
      getData();
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    switch (filterBy) {
      case 'Não-Lidas':
        setNewsAnnouncements(notReadContent);
        break;
      case 'Lidas':
        setNewsAnnouncements(readContent);
        break;
      case 'Este Mês':
        setNewsAnnouncements(thisMonth);
        break;
      default:
        setNewsAnnouncements([...readContent, ...notReadContent]);
    }
  }, [filterBy]);

  return (
    <PageContainer>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('Comunicados')} />
      </StyledPageTitleContainer>
      <LoadingContainerScreen isLoading={loading}>
        <CardContainer>
          <StyledFilterContainer filterWidth={300}>
            <GenericDropdown
              label={t('Filtrar por')}
              listShowDropdown={['Todos', 'Não-Lidas', 'Lidas', 'Este Mês']}
              getSelectedValue={value => setFilterBy(value)}
            />
          </StyledFilterContainer>
          <div style={{ height: '2px', width: '100%', backgroundColor: 'yellow' }} />
          {newsAnnouncements
            .filter(item => {
              if (item.fields.finish) {
                return dateIsGreaterThanToday(item.fields.finish);
              } else {
                return true;
              }
            })
            .sort((a, b) => new Date(b.fields.publicationDate).getTime() - new Date(a.fields.publicationDate).getTime())
            .map((item, index) => (
              <StyledRow key={index}>
                <TitleContainer>
                  <StyledTitle>{item.fields.title}</StyledTitle>
                  <div>
                    <StyledDate> {moment(item.fields.publicationDate).format('DD/MM/YY')}</StyledDate>
                    Lida:
                    {readContent.includes(item) || markedAsRead.includes(item.sys.id) || filterBy === 'Lidas' ? (
                      <StyledCheckbox type="checkbox" defaultChecked={true} disabled />
                    ) : (
                      <StyledCheckbox onChange={() => markRead(item.sys.id)} type="checkbox" checked={false} />
                    )}
                  </div>
                </TitleContainer>
                {item.fields.summary?.content.map((contentItem, contentIndex) => (
                  <StyledSummary key={`content-${index}-${contentIndex}`}> {ReactHtmlParser(contentItem.content[0]?.value)} </StyledSummary>
                ))}
                <StyledLink href={item.fields.link}>{item.fields.link}</StyledLink>
                <div style={{ height: '2px', width: '100%', backgroundColor: '#8080804a', margin: 'auto', marginTop: '20px' }} />
              </StyledRow>
            ))}
        </CardContainer>
      </LoadingContainerScreen>
    </PageContainer>
  );
};

const StyledTitle = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.montserratBold};
  top: 247px;
  left: 378px;
  width: 129px;
  height: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #404040;
  margin-bottom: 22px;
`;

const StyledLink = styled.a`
  font-size: 13px;
  font-family: ${({ theme }) => theme.font.montserratBold};
  top: 247px;
  left: 378px;
  width: 100%;
  height: 14px;
  text-align: left;
  letter-spacing: 0px;
  margin-top: 12px;
  margin-bottom: 22px;
`;

const StyledSummary = styled.div`
  width: 680px;
  text-align: left;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-size: small;
`;

const StyledDate = styled.div`
  font-family: ${({ theme }) => theme.font.montserratRegular};
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
`;

const StyledCheckbox = styled.input`
  height: 20px;
  width: 20px;
  margin: 0 12px 0 25px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  align-content: space-between;
  flex-direction: row;
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 65px;
  margin: 30px 0px;
  margin-bottom: 42px;
  justify-content: space-between;
  div {
    width: fit-content;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (max-width: 1200px) {
    zoom: 100%;
  }
  @media (max-width: 1000px) {
    zoom: 80%;
  }
  @media (max-width: 800px) {
    zoom: 67%;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  min-width: 445px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  b {
    align-self: self-start;
    margin: 20px 0px;
  }
`;

export default withTranslation()(Announcements);
