import { AxiosResponse } from 'axios';
import searchApi from 'api/search-api';
import HttpStatus from 'model/enums/http-status';
import { Search } from 'model/enums/search';
import { SearchResult } from 'model/search-result';

export const SearchService = () => {
  const search = async (params: Search): Promise<SearchResult[]> => {
    try {
      const result: AxiosResponse<SearchResult[]> = await searchApi.search(params);
      console.log('result ', result);
      if (result.status === HttpStatus.OK) {
        const fullResult: SearchResult[] = result.data.filter(it => it.title.trim().length > 0);
        return Promise.resolve(fullResult);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  return {
    search,
  };
};

export default SearchService();
