enum CategoryNames {
  FINANCIAL = 'FINANCIAL',
  INSTITUTIONAL = 'INSTITUTIONAL',
  AGRICULTURAL = 'AGRICULTURAL',
  CATTLE = 'CATTLE',
  PORCINE = 'PORCINE',
  PRINCIPAL = 'PRINCIPAL',
  DETAILED = 'DETAILED',
  ENVIRONMENTAL = 'ENVIRONMENTAL',
}

export default CategoryNames;
