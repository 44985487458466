import drugScheduleApi from 'api/drug-schedule-api';
import { AxiosError, AxiosResponse } from 'axios';
import { ITypeCampaigns, ITypeCampaignsFilter } from 'model/bovine-drug-schedule';
import HttpStatus from 'model/enums/http-status';

export const drugScheduleService = () => {
  const getCampaignsFilter = async (registration: number): Promise<ITypeCampaignsFilter[]> => {
    try {
      const result = await drugScheduleApi.getCampaignsFilter(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getCampaigns = async (campaignId: number, registration: number): Promise<ITypeCampaigns[]> => {
    try {
      const result = await drugScheduleApi.getCampaigns(campaignId, registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getCampaignsFilter,
    getCampaigns,
  };
};

export default drugScheduleService();
