const lDevice = '1200px';
const mDevice = '1170px';
const sDevice = '760px';
const ssDevice = '320px';

export function responsive($size: string): string {
  switch ($size) {
    case 'ss':
      return `@media only screen and (max-width: ${ssDevice})`;
    case 's':
      return `@media only screen and (max-width: ${sDevice})`;
    case 'm':
      return `@media only screen and (min-width: ${sDevice} + 1) and (max-width: ${mDevice})`;
    case 'l':
      return `@media only screen and (min-width: ${mDevice} + 1)`;
    case 'bl':
      return `@media only screen and (min-width: ${lDevice})`;
    default:
      return `@media only screen and (min-width: ${sDevice})`;
  }
  return '@media screen';
}
