import backgroundImage from 'images/frisia/img/large-frisia-building.svg';
import { color } from 'shared/util/styled-component-utils';
import HelpText from 'styled-components/HelpText';
import styled from 'styled-components/macro';
import { responsive } from '_media-query';
import LogoImg from 'images/frisia/img/frisia-blue-logo.svg';

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  ${HelpText} {
    position: absolute;
    top: calc(100% - 2px);
    z-index: 1;
  }

  img {
    width: 202px;
    margin-bottom: 26px;
  }
`;

export const Background = styled.div`
  background-origin: padding-box;
  background-clip: padding-box;
  height: 100vh;

  &:before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('${backgroundImage}');
    filter: sepia(100%) hue-rotate(190deg) saturate(150%);
  }
`;

export const LogoImage = styled.div`
  background-image: url('${LogoImg}');
  background-repeat: no-repeat;
  top: 367px;
  left: 923px;
  width: 141px;
  height: 61px;
  opacity: 1;
  position: sticky;
  z-index: 2;
`;

export const LogoEye = styled.i`
  cursor: pointer;
  z-index: 1;
  width: 50px;
`;

export const StyledInput = styled.input`
  top: 464px;
  left: 329px;
  padding-left: 16px;
  width: 328px;
  height: 56px;
  border: 1px solid #0000001f;
  border-radius: 4px;
  opacity: 1;
`;

export const MessageContainer = styled.div`
  top: 320px;
  left: 181px;
  width: 1031px;
  height: 420px;
  background: #102b5acf 0% 0% no-repeat padding-box;
  background-blend-mode: multiply;
  opacity: 1;
  position: absolute;
  padding: 73px 0px;
`;

export const Message = styled.div`
  top: 390px;
  left: 257px;
  width: 291px;
  height: 89px;
  color: var(--500);
  text-align: left;
  margin-left: 76px;
  font: normal normal 36px/44px Montserrat-Bold;
  letter-spacing: 0.01px;
  color: #d7cc27;
  opacity: 1;
  mix-blend-mode: initial;
`;

export const Message2 = styled.div`
  margin-left: 76px;
  font-size: 48px;
  mix-blend-mode: initial;
  top: 485px;
  left: 257px;
  width: 314px;
  text-align: left;
  font: normal normal normal 24px/34px Montserrat-Regular;
  letter-spacing: 0px;
  color: #e6eaf2;
  opacity: 1;
`;

export const ForgotPassword = styled.a`
  font-size: 12px;
  color: ${color`laranja`};
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
  margin: 36px 0 9px 0;

  &:hover {
    color: ${color`laranja`};
  }
`;

export const WhiteContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 64px 84px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 256px;
  left: 738px;
  width: 522px;
  height: 549px;
  z-index: 1;

  background: var(--surface) 0% 0% no-repeat padding-box;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1d1d1d33;
  border-radius: 20px;
  opacity: 1;

  .subtitle {
    margin: 18px 0 32px 0;
  }

  .subtitle2 {
    margin: 58px 0 20px 0;
  }
  legend {
    float: none;
  }
`;

export const Items = styled.div`
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 500px;
  max-width: 488px;
  padding: 65px 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemLink = styled.div`
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #df6518;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 20px;
`;

export const TitleLogin = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.43;
  letter-spacing: normal;
  color: #4a4a4a;
  ${responsive('s')} {
    display: none;
  }
`;
