export interface Search {
  search?: string;
  type?: SearchType;
  limit?: number | null;
}

export enum SearchType {
  GENERAL = 'GENERAL',
  SMART_BULAS = 'SMART_BULAS',
  CID = 'CID',
  SUS = 'SUS',
  SPECIALTY = 'SPECIALTY',
}
