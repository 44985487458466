import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid/Grid';
import GenericDropdown from 'components/GenericDropdown';
import GenericInputToTable from 'components/GenericInputToTable';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import InputDateWithSetting from 'components/InputDateWithSetting';
import MainPageTitle from 'components/MainPageTitle';
import RadioButton from 'components/RadioButton';
import { StyledButton } from 'components/styled-button/StyledButton';
import { parse } from 'date-fns';
import dayjs from 'dayjs';
import {
  IActivity,
  IAreaType,
  IBalanceCrop,
  ICropType,
  ICulture,
  ICultureType,
  IHarvest,
  IHistoryTrr,
  IItensTrr,
  ILotsTrr,
  IOrder,
  IPaymentConditions,
  IProperty,
  IPropertyType,
  IPurposeType,
} from 'model/trr-order';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import trrOrderService from 'services/trr-order-service';
import styled from 'styled-components';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import * as yup from 'yup';
import { ReactComponent as LeftIconSvg } from '../../images/frisia/icons/arrow-left.svg';
import { ReactComponent as RightIconSvg } from '../../images/frisia/icons/arrow-right.svg';

interface IForm {
  invoicing: IProperty;
  delivery: IProperty;
  harvest: string;
  harvestType: string;
  amount: string;
  product: string;
  activity: IActivity;
  culture: ICulture;
  purpose: string;
  paymentTerms: string;
  deliveryDate: string | Date;
  complement: string;
  harvestTerm: string;
}

interface historyListType {
  [key: string]: any;
}

const TrrRequestsSwine = ({ t }: WithTranslation) => {
  const [dropdownValues, setDropdownValues] = useState<IForm>({} as IForm);
  const [showHistory, setShowHistory] = useState<boolean>(false);

  const [harvestList, setHarvestList] = useState<IHarvest[]>([]);
  const [chosenHarvest, setChosenHarvest] = useState<IHarvest>();
  const [propertyOptions, setPropertyOptions] = useState<IPropertyType[]>([]);
  const [chosenProperty, setChosenProperty] = useState<IPropertyType>();
  const [chosenDeliveryProperty, setChosenDeliveryProperty] = useState<IPropertyType>();
  const [cropTypesOptions, setCropTypesOptions] = useState<ICropType[]>([]);
  const [chosenCropType, setChosenCropType] = useState<ICropType>();

  const [productsItens, setProductsItens] = useState<IItensTrr[]>([]);
  const [activitiesAreasOptions, setActivitiesAreasOptions] = useState<IAreaType[]>([]);
  const [chosenActivitiesArea, setChosenActivitiesArea] = useState<IAreaType>();
  const [cultureOptions, setCultureOptions] = useState<ICultureType[]>([]);
  const [chosenCulture, setChosenCulture] = useState<ICultureType>();
  const [purposeOptions, setPurposeOptions] = useState<IPurposeType[]>([]);
  const [paymentTermsOptions, setPaymentTermsOptions] = useState<IPaymentConditions[]>([]);
  const [chosenPayment, setChosenPayment] = useState<IPaymentConditions>();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [selectedDate, setselectedDate] = useState<Date | null>(tomorrow);

  const [balanceCropItens, setBalanceCropItens] = useState<IBalanceCrop[]>([]);
  const [harvestTerm, setHarvestTerm] = useState<IBalanceCrop>();

  const [lotsOptions, setLotsOptions] = useState<ILotsTrr[]>([]);
  const [chosenLot, setChosenLot] = useState<ILotsTrr>();

  const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
  const [paginatedHistoryList, setPaginatedHistoryList] = useState<historyListType[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [historyList, setHistoryList] = useState<historyListType[]>([]);
  const [chosenForCancel, setChosenForCancel] = useState<IHistoryTrr>();
  const [openDeletTransferModal, setOpenDeletTransferModal] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const schema = yup.object({
    invoicing: yup.string().test('test-invoicing', t('global.errorMessage.required'), () => chosenProperty != null),
    delivery: yup.string().test('test-delivery', t('global.errorMessage.required'), () => chosenDeliveryProperty != null),
    harvest: yup.string().test('test-harvest', t('global.errorMessage.required'), () => chosenHarvest != null),
    harvestType: yup.string().test('test-harvestType', t('global.errorMessage.required'), () => chosenCropType != null),
    amount: yup
      .string()
      .required()
      .test(
        'test-harvestType',
        `${t('trr.exceededVolume')} (${t('trr.balanceAvailable')}: ${harvestTerm?.SALDO_DISPONIVEL.toLocaleString('pt-br')} L). \n${t(
          'trr.changeAmount'
        )} `,
        value => {
          if (chosenPayment?.CD_CONDICAO_PAGAMENTO === 'ULT DIA M+DTDEB' && harvestTerm && value) {
            return parseInt(value) < harvestTerm.SALDO_DISPONIVEL;
          } else {
            return true;
          }
        }
      ),
    complement: yup.string(),
    activity: yup.string().test('test-activity', t('global.errorMessage.required'), () => chosenActivitiesArea != null),
    culture: yup.string().test('test-culture', t('global.errorMessage.required'), () => chosenCulture != null),
    paymentTerms: yup.string().test('test-paymen-terms', t('global.errorMessage.required'), () => chosenPayment != null),
    product: yup.string().test('test-product', t('global.errorMessage.required'), () => dropdownValues.product != null),
    purpose: yup.string().test('test-purpose', t('global.errorMessage.required'), () => dropdownValues.purpose != null),
    deliveryDate: yup.string().test('test-delivery-date', t('global.errorMessage.required'), () => selectedDate != null),
    harvestTerm: yup.string().test('test-delivery-date', t('global.errorMessage.required'), () => {
      if (chosenPayment?.CD_CONDICAO_PAGAMENTO === 'ULT DIA M+DTDEB') {
        return harvestTerm != null;
      } else {
        return true;
      }
    }),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  useEffect(() => {
    async function callAsync() {
      await trrOrderService.getItensTRR().then(setProductsItens);
      if (getUserRegistration != null) {
        await trrOrderService.getProperties(getUserRegistration).then(setPropertyOptions);
        await trrOrderService.getAreas(getUserRegistration).then(setActivitiesAreasOptions);
        await trrOrderService.getBalanceCrop(getUserRegistration).then(setBalanceCropItens);
      }
      await trrOrderService.getHarvestOptions().then(setHarvestList);
      await trrOrderService.getCultureOptions().then(setCultureOptions);
    }
    callAsync();
  }, []);

  useEffect(() => {
    if (chosenProperty) {
      trrOrderService.getCropTypesOptions(chosenProperty.CD_ESTADO.toString()).then(setCropTypesOptions);
    }
    if (chosenProperty && getUserRegistration != null) {
      trrOrderService.getLotsTRR(getUserRegistration, chosenProperty.NR_PROPRIEDADE.toString()).then(setLotsOptions);
    }
  }, [chosenProperty]);

  useEffect(() => {
    if (chosenCulture) {
      trrOrderService.getPurposeOptions(chosenCulture.CD_CULTURA.toString()).then(setPurposeOptions);
    }
  }, [chosenCulture]);

  useEffect(() => {
    if (getUserRegistration != null && chosenHarvest && chosenActivitiesArea) {
      trrOrderService
        .getPaymentConditions(getUserRegistration.toString(), chosenHarvest.CD_SAFRA.toString(), chosenActivitiesArea.CD_TIPO_ATIVIDADE)
        .then(response => {
          setPaymentTermsOptions(response);
        });
    }
  }, [chosenHarvest, chosenActivitiesArea]);

  const handleSubmit = (data: IForm) => {
    if (
      openTransferModal &&
      chosenCulture &&
      chosenProperty &&
      chosenPayment &&
      chosenDeliveryProperty &&
      chosenHarvest &&
      chosenCropType &&
      chosenActivitiesArea
    ) {
      setDisableButton(true);

      const order: IOrder = {
        CD_MATRICULA: getUserRegistration,
        CD_PROPRIEDADE_ENTREGA: chosenDeliveryProperty.NR_PROPRIEDADE.toString(),
        CD_PROPRIEDADE_FATURA: chosenProperty.NR_PROPRIEDADE.toString(),
        DT_REGISTRO: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        CD_CONDICAO_PAGAMENTO: chosenPayment.CD_CONDICAO_PAGAMENTO,
        DS_COMPLEM_ENTREGA: data.complement,
        CD_PRODUTO: dropdownValues.product,
        QUANTIDADE: parseFloat(data.amount),
        DT_PREVISAO_ENVIO: dayjs(selectedDate).format('YYYY-MM-DD 00:00:00'),
        CD_SAFRA: chosenPayment.CD_CONDICAO_PAGAMENTO === 'ULT DIA M+DTDEB' && harvestTerm ? harvestTerm.CD_SAFRA : chosenHarvest.CD_SAFRA,
        CD_ATIVIDADE: chosenActivitiesArea.CD_TIPO_ATIVIDADE,
        CD_CULTURA:
          chosenPayment.CD_CONDICAO_PAGAMENTO === 'ULT DIA M+DTDEB' && harvestTerm ? harvestTerm?.CD_CULTURA : chosenCulture.CD_CULTURA,
        CD_PROPOSITO:
          chosenPayment.CD_CONDICAO_PAGAMENTO === 'ULT DIA M+DTDEB' && harvestTerm ? harvestTerm?.IE_PROPOSITO : dropdownValues.purpose,
        DS_TIPO_SAFRA:
          chosenPayment.CD_CONDICAO_PAGAMENTO === 'ULT DIA M+DTDEB' && harvestTerm
            ? harvestTerm?.DS_TIPO_SAFRA
            : chosenCropType.DS_TIPO_SAFRA,
        CD_LOTE_SUINO: chosenLot?.ID_LOTE.toString(),
        CD_FASE_TERM_SUINOS: chosenLot?.IE_TIPO_LOTE.toString(),
      };
      trrOrderService
        .sendOrder(order)
        .then(_ => {
          setOpenTransferModal(false);
          getHistory();
        })
        .finally(() => {
          setDisableButton(false);
        });
      getHistory();
    } else {
      setOpenTransferModal(true);
    }
  };

  const tableHeader = [
    t('trr.n'),
    t('trr.solicitation'),
    t('trr.deliveryProperty'),
    t('trr.billingProperty'),
    t('trr.product'),
    t('trr.status'),
  ];

  const paginate = array => {
    setPaginatedHistoryList(array.slice((pageNumber - 1) * 10, pageNumber * 10));
  };

  useEffect(() => {
    paginate(historyList);
    setTotalPages(Math.ceil(historyList.length / 10));
  }, [pageNumber, historyList]);

  const getHistory = async () => {
    const result = await trrOrderService.getHistory(getUserRegistration.toString());
    setHistoryList(
      result.map(item => {
        return {
          [t('trr.n')]: item.ID_PEDIDO,
          [t('trr.solicitation')]: dayjs(item.DT_REGISTRO).format('DD/MM/YY'),
          [t('trr.deliveryProperty')]:
            propertyOptions?.find(property => property.NR_PROPRIEDADE.toString() === item.CD_PROPRIEDADE_ENTREGA)?.FULL_PROPRIEDADE ??
            item.CD_PROPRIEDADE_ENTREGA,
          [t('trr.billingProperty')]:
            propertyOptions?.find(property => property.NR_PROPRIEDADE.toString() === item.CD_PROPRIEDADE_FATURA)?.FULL_PROPRIEDADE ??
            item.CD_PROPRIEDADE_FATURA,
          [t('trr.product')]: `${item.CD_PRODUTO} - ${item.QUANTIDADE} L `,
          [t('trr.status')]: item.DS_STATUS_PROCESSO?.includes('análise') ? (
            <div style={{ margin: '6px 0px', display: 'flex', flexDirection: 'column' }}>
              {t('financial.underAnalysis')}
              <StyledButton
                style={{ padding: '6px', lineHeight: '11px' }}
                color="orange"
                type="button"
                onClick={() => {
                  setChosenForCancel(item);
                  setOpenDeletTransferModal(true);
                }}
              >
                <span style={{ fontSize: '10px' }}>{t('global.button.cancel')}</span>
              </StyledButton>
            </div>
          ) : (
            item.DS_STATUS_PROCESSO
          ),
        };
      })
    );
  };

  useEffect(() => {
    if (showHistory) {
      getHistory();
    }
  }, [showHistory]);

  const cancelTransfer = async () => {
    if (chosenForCancel) {
      const result = await trrOrderService.cancelTrrOrder(chosenForCancel.ID_PEDIDO.toString());
      if (result) {
        setOpenDeletTransferModal(false);
        getHistory();
      }
    }
  };

  const sortListByDate = (list: historyListType[]) => {
    list.sort(
      (a, b) => parse(a.DT_REGISTRO, 'dd/MM/yyyy', new Date()).getTime() - parse(b.DT_REGISTRO, 'dd/MM/yyyy', new Date()).getTime()
    );
    return list.map(item => item);
  };

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('livestock.trrRequest')} />
      </StyledPageTitleContainer>
      <div style={{ width: '42px' }}>
        <StyledButton
          onClick={() => setShowHistory(!showHistory)}
          style={{ height: '42px', position: 'relative', marginBottom: '10px' }}
          color="orange"
          type="button"
        >
          <span>{t('agricultural.history')}</span>
        </StyledButton>
      </div>
      {showHistory && (
        <>
          <GenericTableBlueHeader
            headerItems={tableHeader}
            tableItems={sortListByDate(paginatedHistoryList)}
            dataColor="#787878"
            isInvertLineColor
            isDontHaveMaximumWidth
            style={{ fontSize: '12px !important' }}
            isAlignLastColumnToLeft
          />
          {paginatedHistoryList.length === 0 && (
            <SubtitleStyled style={{ width: '100%', textAlign: 'center' }}>{t('trr.emptyHistory')}</SubtitleStyled>
          )}
          <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}>
            <LeftIconSvg
              style={{ cursor: 'pointer', margin: '8px' }}
              onClick={() => {
                if (pageNumber > 1) {
                  setPageNumber(pageNumber - 1);
                }
              }}
            />
            <div>
              {pageNumber}/{totalPages}
            </div>
            <RightIconSvg
              style={{ cursor: 'pointer', margin: '8px' }}
              onClick={() => {
                if (pageNumber < historyList.length / 10) {
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          </div>
        </>
      )}
      {openDeletTransferModal && (
        <ModalContainer>
          <ModalShadow onClick={() => setOpenDeletTransferModal(false)} />
          <CardContainer>
            <SubtitleStyled>{`${t('trr.cancelTransfer')} ${chosenForCancel?.ID_PEDIDO}?`}</SubtitleStyled>
            <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
              <CancelButton
                onClick={() => {
                  setOpenDeletTransferModal(false);
                }}
                style={{ width: '145px', height: '40px' }}
              >
                {t('trr.no')}
              </CancelButton>
              <StyledButton
                color="frisiaPrimary"
                onClick={() => {
                  cancelTransfer();
                }}
                type="button"
                style={{ color: 'white', minWidth: '145px', height: '42px' }}
              >
                <span>{t('trr.yes')}</span>
              </StyledButton>
            </div>
          </CardContainer>
        </ModalContainer>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {openTransferModal && (
            <ModalContainer>
              <ModalShadow onClick={() => setOpenTransferModal(false)} />
              <CardContainer>
                <div style={{ padding: '10px' }}>
                  <SubtitleStyled>{t('global.field.confirmSolicitation')}</SubtitleStyled>
                  <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <CancelButton
                      onClick={() => {
                        setOpenTransferModal(false);
                      }}
                      style={{ width: '145px', height: '40px' }}
                    >
                      {t('financial.cancel')}
                    </CancelButton>
                    <StyledButton
                      color="frisiaPrimary"
                      onClick={() => {
                        setOpenTransferModal(true);
                      }}
                      disabled={disableButton}
                      type="submit"
                      style={{ color: 'white', minWidth: '145px', height: '42px' }}
                    >
                      <span>{t('financial.confirm')}</span>
                    </StyledButton>
                  </div>
                </div>
              </CardContainer>
            </ModalContainer>
          )}
          <Grid container columnSpacing="17px">
            <Grid item xs={12} lg={6}>
              <fieldset>
                <Grid container spacing="2px">
                  <Grid item xs={12}>
                    <StyledPageTitleContainer margin={'25px 0 14px 0'}>
                      <MainPageTitle fontSize={16} text={t('livestock.properties')} />
                    </StyledPageTitleContainer>

                    <StyledFilterContainer>
                      <GenericDropdown
                        label={t('livestock.invoicing')}
                        placeholder={t('livestock.selectInvoicing')}
                        listShowDropdown={propertyOptions.length > 0 ? propertyOptions : [t('global.field.loading')]}
                        keyToShowInDropdown={propertyOptions.length > 0 ? 'FULL_PROPRIEDADE' : undefined}
                        getSelectedValue={setChosenProperty}
                        errorText={methods.formState.errors.invoicing?.message}
                      />
                    </StyledFilterContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledFilterContainer>
                      <GenericDropdown
                        label={t('livestock.delivery')}
                        placeholder={t('livestock.selectDelivery')}
                        listShowDropdown={propertyOptions.length > 0 ? propertyOptions : [t('global.field.loading')]}
                        keyToShowInDropdown={propertyOptions.length > 0 ? 'FULL_PROPRIEDADE' : undefined}
                        getSelectedValue={setChosenDeliveryProperty}
                        errorText={methods.formState.errors.delivery?.message}
                      />
                    </StyledFilterContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <GenericInputToTable inputName="complement" label={t('livestock.complement')} placeholder={t('livestock.enterAddon')} />
                  </Grid>
                </Grid>
              </fieldset>
            </Grid>

            <Grid item xs={12} lg={6}>
              <fieldset>
                <Grid container spacing="2px">
                  <Grid item xs={12}>
                    <StyledPageTitleContainer margin={'25px 0 14px 0'}>
                      <MainPageTitle fontSize={16} text={t('livestock.harvest')} />
                    </StyledPageTitleContainer>
                    <StyledFilterContainer>
                      {chosenPayment?.CD_CONDICAO_PAGAMENTO === 'ULT DIA M+DTDEB' && harvestTerm != undefined ? (
                        <GenericDropdown
                          label={t('livestock.harvest')}
                          listShowDropdown={[harvestList.find(item => item.CD_SAFRA == harvestTerm.CD_SAFRA)]}
                          keyToShowInDropdown={'NM_SAFRA'}
                          getSelectedValue={setChosenHarvest}
                          errorText={methods.formState.errors.harvest?.message}
                          disabled={true}
                          placeholder={harvestList.find(item => item.CD_SAFRA == harvestTerm.CD_SAFRA)?.NM_SAFRA}
                        />
                      ) : (
                        <GenericDropdown
                          label={t('livestock.harvest')}
                          placeholder={t('livestock.selectHarvest')}
                          listShowDropdown={harvestList.length > 0 ? harvestList : [t('global.field.loading')]}
                          keyToShowInDropdown={harvestList.length > 0 ? 'NM_SAFRA' : undefined}
                          getSelectedValue={setChosenHarvest}
                          errorText={methods.formState.errors.harvest?.message}
                        />
                      )}
                    </StyledFilterContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledFilterContainer>
                      <GenericDropdown
                        label={t('livestock.harvestType')}
                        placeholder={t('livestock.selectHarvestType')}
                        listShowDropdown={cropTypesOptions.length > 0 ? cropTypesOptions : [t('global.field.loading')]}
                        keyToShowInDropdown={cropTypesOptions.length > 0 ? 'DS_TIPO_SAFRA' : undefined}
                        getSelectedValue={setChosenCropType}
                        errorText={methods.formState.errors.harvestType?.message}
                        disabled={harvestTerm != undefined}
                        defaultValue={harvestTerm}
                      />
                    </StyledFilterContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <GenericInputToTable
                      isKilogram
                      inputName="amount"
                      label={t('livestock.amount')}
                      placeholder={t('livestock.setAmountL')}
                    />
                    <StyledErrorMessage style={{ position: 'initial' }}>{methods.formState.errors.amount?.message}</StyledErrorMessage>
                  </Grid>
                </Grid>
              </fieldset>
            </Grid>

            <StyledPageTitleContainer margin={'25px 0 14px 17px'} style={{ width: '100%' }}>
              <MainPageTitle fontSize={16} text={t('livestock.products')} />
            </StyledPageTitleContainer>
            <Grid item xs={12} lg={6}>
              <Grid container spacing="2px">
                <Grid item xs={12}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('livestock.product')}
                      placeholder={t('agricultural.selectProduct')}
                      listShowDropdown={productsItens.length > 0 ? productsItens : [t('global.field.loading')]}
                      keyToShowInDropdown={productsItens.length > 0 ? 'FULL_PRODUTO' : undefined}
                      getSelectedValue={value => setDropdownValues({ ...dropdownValues, product: value.CD_PRODUTO })}
                      errorText={methods.formState.errors.product?.message}
                    />
                  </StyledFilterContainer>
                </Grid>
                <Grid item xs={12}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('livestock.activity')}
                      placeholder={t('livestock.selectActivity')}
                      listShowDropdown={activitiesAreasOptions.length > 0 ? activitiesAreasOptions : [t('global.field.loading')]}
                      keyToShowInDropdown={activitiesAreasOptions.length > 0 ? 'FULL_TIPO_ATIVIDADE' : undefined}
                      getSelectedValue={setChosenActivitiesArea}
                      errorText={methods.formState.errors.activity?.message}
                    />
                  </StyledFilterContainer>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container spacing="2px">
                <Grid item xs={12}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('livestock.culture')}
                      placeholder={t('livestock.selectCulture')}
                      listShowDropdown={cultureOptions.length > 0 ? cultureOptions : [t('global.field.loading')]}
                      keyToShowInDropdown={cultureOptions.length > 0 ? 'FULL_CULTURA' : undefined}
                      getSelectedValue={setChosenCulture}
                      errorText={methods.formState.errors.culture?.message}
                      disabled={harvestTerm != undefined && chosenPayment?.CD_CONDICAO_PAGAMENTO === 'ULT DIA M+DTDEB'}
                      defaultValue={harvestTerm}
                    />
                  </StyledFilterContainer>
                </Grid>
                <Grid item xs={12}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('livestock.purpose')}
                      placeholder={t('livestock.selectPurpose')}
                      listShowDropdown={purposeOptions.length > 0 ? purposeOptions : [{ DS_PROPOSITO: t('global.field.loading') }]}
                      keyToShowInDropdown={'DS_PROPOSITO'}
                      getSelectedValue={value => setDropdownValues({ ...dropdownValues, purpose: value.CD_PROPOSITO })}
                      errorText={methods.formState.errors.purpose?.message}
                      disabled={harvestTerm != undefined && chosenPayment?.CD_CONDICAO_PAGAMENTO === 'ULT DIA M+DTDEB'}
                      defaultValue={purposeOptions.find(item => harvestTerm?.FULL_PROPOSITO === item.FULL_PROPOSITO)}
                    />
                  </StyledFilterContainer>
                </Grid>
              </Grid>
            </Grid>

            <StyledPageTitleContainer margin={'25px 0 14px 17px'} style={{ width: '100%' }}>
              <MainPageTitle fontSize={16} text={t('livestock.payment')} />
            </StyledPageTitleContainer>
            <Grid item xs={12}>
              <Grid container columnSpacing="17px" spacing="2px">
                <Grid item xs={12} lg={6}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('livestock.paymentTerms')}
                      placeholder={t('livestock.selectPaymentTerms')}
                      listShowDropdown={paymentTermsOptions.length > 0 ? paymentTermsOptions : [t('global.field.loading')]}
                      keyToShowInDropdown={paymentTermsOptions.length > 0 ? 'DS_CONDICAO_PAGAMENTO' : undefined}
                      getSelectedValue={setChosenPayment}
                      errorText={methods.formState.errors.paymentTerms?.message}
                    />
                  </StyledFilterContainer>
                </Grid>
                {balanceCropItens.length > 0 && chosenPayment?.CD_CONDICAO_PAGAMENTO === 'ULT DIA M+DTDEB' && (
                  <>
                    <StyledPageTitleContainer margin={'25px 0 14px 17px'} style={{ width: '100%' }}>
                      <MainPageTitle fontSize={16} text={t('trr.termHarvest')} />
                      {t('trr.subtitle')}
                    </StyledPageTitleContainer>
                    <Grid item xs={12} style={{ marginBottom: '35px' }}>
                      <StyledFilterContainer style={{ maxHeight: 'fit-content' }}>
                        {balanceCropItens.map((item, index) => (
                          <RadioButton
                            key={index}
                            name="harvestTerm"
                            value={`${item.FULL_CULTURA} / ${item.FULL_PROPOSITO} / ${item.CD_SAFRA.toString()} / ${item.DS_TIPO_SAFRA} / 
                            ${item.SALDO_DISPONIVEL} (${t('trr.limit')}: ${item.TOTAL_INICIAL} - ${t('trr.requested')}: 
                            ${item.SOLICITADO})`}
                            id={`harvestTerm-${index}`}
                            handleClick={() => {
                              setHarvestTerm(item);
                            }}
                            style={{ display: 'inline-flex', width: '100%', margin: '12px 0px', maxWidth: 'none' }}
                          />
                        ))}
                        <StyledErrorMessage style={{ position: 'initial' }}>
                          {methods.formState.errors.harvestTerm?.message}
                        </StyledErrorMessage>
                      </StyledFilterContainer>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} lg={6}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('livestock.lots')}
                      placeholder={t('livestock.selectLots')}
                      listShowDropdown={lotsOptions.length > 0 ? lotsOptions : ['Sem opções']}
                      keyToShowInDropdown={lotsOptions.length > 0 ? 'LOTE' : undefined}
                      getSelectedValue={setChosenLot}
                    />
                  </StyledFilterContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledFilterContainer style={{ textAlign: 'left' }}>
                    <InputDateWithSetting date={selectedDate} label={t('livestock.deliveryDate')} setDate={setselectedDate} />
                  </StyledFilterContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <StyledButton color="frisiaPrimary" type="submit" style={{ minWidth: '400px', alignSelf: 'center', marginTop: '15px' }}>
            <span>{t('global.button.sendRequest')}</span>
          </StyledButton>
        </form>
      </FormProvider>
    </>
  );
};

const ModalContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
`;

const SubtitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const CancelButton = styled.button`
  width: 245px;
  height: 34px;
  font-size: 14px;
  letter-spacing: 0.02px;
  color: #d4a518;
  text-transform: uppercase;
  opacity: 1;
  border: #d4a518 2px solid;
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  cursor: pointer;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  min-width: 445px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  b {
    align-self: self-start;
    margin: 20px 0px;
  }
`;

export default withTranslation()(TrrRequestsSwine);
