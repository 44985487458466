import CardNames from 'model/enums/card-names';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import plus from '../images/frisia/icons/yellow-big-circle-plus-icon.svg';
import minus from '../images/frisia/icons/red-minus-round-icon.svg';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { getCardInformation } from '../shared/util/card';

interface IProps extends WithTranslation {
  listCardNames: CardNames[];
  favorites: CardNames[];
  updateFavorite: (string) => void;
  spacingBetweenCards: number;
}

interface StyledProps {
  spacingBetweenCards: number;
}

const StyledCard = styled.div`
  min-height: 157px;
  height: 157px;
  width: 157px;
  min-width: 157px;
  padding-top: 17px;
  background-color: #fcfcfc;
  font-family: ${({ theme }) => theme.font.arialBold};
  color: #183c96;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0px 5px 10px #1d1d1d33;

  svg {
    height: 65px;
  }
`;

const StyledText = styled.div`
  margin: 11px auto;
  line-height: 1.3;

  width: 126px;
  height: 46px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.22px;
  color: #183c96;
  opacity: 1;
`;

const StyledContainer = styled.div<StyledProps>`
  display: flex;
  flex-wrap: wrap;

  column-gap: ${({ spacingBetweenCards }) => `${spacingBetweenCards}px`};
  row-gap: ${({ spacingBetweenCards }) => `${spacingBetweenCards}px`};
`;

const CardsShortcuts = ({ listCardNames, favorites, updateFavorite, spacingBetweenCards, t }: IProps) => {
  const history = useHistory();

  const cardList = listCardNames.map(cardName => {
    const cardInfomation = getCardInformation(cardName);
    const [favorite, setFavorite] = useState(favorites.includes(cardName));
    return (
      <StyledCard key={`CARD-${cardName}`}>
        <div onClick={() => (window.location.pathname = cardInfomation.pathRedirected)}>
          {cardInfomation.icon}
          <StyledText>{t(cardInfomation.i18nText)}</StyledText>
        </div>
        <div>
          <img
            src={favorite ? minus : plus}
            onClick={() => {
              setFavorite(!favorite);
              updateFavorite(cardName);
            }}
            style={{ position: 'relative', left: '67px', top: '-21px' }}
          />
        </div>
      </StyledCard>
    );
  });

  return <StyledContainer spacingBetweenCards={spacingBetweenCards}>{cardList}</StyledContainer>;
};

export default withTranslation()(CardsShortcuts);
