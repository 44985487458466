import TextField from '@mui/material/TextField';
import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';
import fuelAuthorizationService from 'services/authorization-fuel-service';
import { IAuths, IConsults, IFunctions } from 'model/fuel-authorization';
import Checkbox from 'components/Checkbox';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import { ReactComponent as ExclamationIcon } from '../../images/frisia/icons/gray-Icon-exclamation-circle.svg';
import GenericInputToTable from 'components/GenericInputToTable';

interface IForm {
  funcOptions: string;
  myConsult: string;
  accepted: boolean;
}

const AuthorizationFuelStation = ({ t }: WithTranslation) => {
  const [openThermesModal, setOpenThermesModal] = useState<boolean>(false);
  const [SawTerme, setSawTermes] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);

  const [consultOptions, setconsultOptions] = useState<IConsults[]>([]);
  const [chosenConsult, setChosenConsult] = useState<string>();
  const [chosenConsultToRecPwd, setChosenConsultToRecPwd] = useState<string>();
  const [funcOptions, setFuncOptions] = useState<IFunctions[]>([]);
  const [chosenFunc, setChosenFunc] = useState<string>();
  const [myAuths, seMyAuths] = useState<IAuths[]>([]);

  const [thermes, setThermes] = useState<string>();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [chosenAuth, setChosenAuth] = useState<IAuths>();

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getUserName = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.NM_PESSOA ?? state.authentication.coreInfo?.nm_usuario;
  });

  const getMyAuths = async () => {
    fuelAuthorizationService.getMyAuths(getUserRegistration).then(response => {
      seMyAuths(response);
      setDisableButton(false);
    });
  };

  const getData = async () => {
    fuelAuthorizationService.getAcepetTherme().then(response => {
      setThermes(response.textoTermos);
    });
  };

  useEffect(() => {
    getData();
    getMyAuths();
  }, []);

  useEffect(() => {
    if (openThermesModal === true) {
      setSawTermes(true);
    }
  }, [openThermesModal]);

  const schema = yup.object({
    accepted: yup.string().test('test-paymentDate', t('global.errorMessage.acceptedTerms'), () => accepted != false),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  const handleSubmit = async data => {
    setDisableButton(true);
    await fuelAuthorizationService
      .authNew({
        cdPessoaAutorizador: getUserRegistration.toString(),
        dsSistemaOrigem: 'portal do cooperado',
        dsTipoAutorizacao: 'CONVENIO_POSTO',
        cdPessoaCriador: getUserRegistration.toString(),
      })
      .then(response => getMyAuths());
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {openThermesModal && (
            <ModalContainer>
              <ModalShadow onClick={() => setOpenThermesModal(false)} />
              <CardModalContainer>
                <div style={{ padding: '10px' }}>
                  <SubtitleStyled>{thermes}</SubtitleStyled>
                  <StyledButton
                    color="frisiaPrimary"
                    onClick={() => setOpenThermesModal(false)}
                    type="button"
                    style={{ color: 'white', minWidth: '145px', height: '42px', marginTop: '20px' }}
                  >
                    <span>{t('global.button.ok')}</span>
                  </StyledButton>
                </div>
              </CardModalContainer>
            </ModalContainer>
          )}
          <StyledPageTitleContainer margin={'10px 0 25px 0'} style={{ display: 'flex' }}>
            <MainPageTitle fontSize={20} text={t('authFuelStation.authFuelStation')} />
          </StyledPageTitleContainer>
          <div style={{ display: 'flex', flexFlow: 'wrap' }}>
            <NewAuthContainer style={{ minWidth: '650px', marginBottom: '14px', marginRight: '15px', overflowY: 'auto' }}>
              <PriceTitle>{t('authFuelStation.myAuth')}:</PriceTitle>
              <GenericTableBlueHeader
                headerItems={[t('authFuelStation.id'), t('authFuelStation.AuthCreator'), t('authFuelStation.authorizationDate'), '']}
                tableItems={myAuths.map(item => {
                  return {
                    id: item.id_autorizacao,
                    criador: item.id_pessoa_criador_autorizacao,
                    authorizationDate: item.creation_date,
                    button: (
                      <ViewButton
                        type="button"
                        onClick={e => {
                          e.preventDefault();
                          window.location.href = `https://servicos.frisia.coop.br/termosAutorizacao/api/PDF/BaixarExistente?cdPessoaAutorizador=${getUserRegistration}&dsTipoAutorizacao=CONVENIO_POSTO`;
                        }}
                        style={{ width: '95px', height: '35px' }}
                      >
                        {t('global.button.toView')}
                      </ViewButton>
                    ),
                  };
                })}
                dataColor="#787878"
                isInvertLineColor
                isDontHaveMaximumWidth
              />
            </NewAuthContainer>
            <NewAuthContainer style={{ maxWidth: '350px', minWidth: '200px', margin: '14px' }}>
              <PriceTitle>{t('authFuelStation.newAuth')}:</PriceTitle>
              <div style={{ width: '100%' }}>
                <StyledButton
                  color="frisiaPrimary"
                  style={{ maxWidth: '100%', maxHeight: '50px', fontSize: '10px', marginBottom: '8px' }}
                  type="button"
                  onClick={() => setOpenThermesModal(true)}
                >
                  <span>{t('authFuelStation.clickHere')}</span>
                </StyledButton>
              </div>
              <Checkbox
                disabled={!SawTerme ? true : false}
                inputName="onlyFeed"
                handleChecked={() => setAccepted(!accepted)}
                text={t('authFuelStation.accept')}
                style={{ width: '300px' }}
              />
              <StyledErrorMessage style={{ position: 'initial', transform: 'translateY(-19px)' }}>
                {methods.formState.errors.accepted?.message}
              </StyledErrorMessage>

              <div style={{ width: '100%' }}>
                <StyledButton disabled={disableButton} color="frisiaPrimary" style={{ maxWidth: '150px', maxHeight: '50px' }} type="submit">
                  <span>{t('authFuelStation.auth')}</span>
                </StyledButton>
              </div>
            </NewAuthContainer>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const NewAuthContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  padding: 23px 16px;
  display: flex;
  flex-direction: column;
  text-align: start;
  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 146px;
    padding-left: 6px;
  }
`;

const PriceTitle = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold};
  letter-spacing: 0.01px;
  color: #2a5299;
  opacity: 1;
  margin-bottom: 10px;
`;

const CardModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  min-width: 445px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  b {
    align-self: self-start;
    margin: 20px 0px;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
`;

const TitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const SubtitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 15px;
  letter-spacing: 0px;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratRegular};
`;

const ViewButton = styled.button`
  width: 245px;
  height: 34px;
  font-size: 14px;
  letter-spacing: 0.02px;
  color: #d4a518;
  text-transform: uppercase;
  opacity: 1;
  border: #d4a518 2px solid;
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  cursor: pointer;
`;

const StyledInputText = styled(TextField)`
  div {
    font-family: ${({ theme }) => theme.font.montserratRegular};
    height: 53px;
    min-width: 200px;
    fieldset {
      border: 2px solid #4e6ba7 !important;
    }
  }
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary} !important;
    letter-spacing: 0.4px;
    font-size: 13.2px;
    line-height: 2.2em;
    padding-left: 7px;
  }
  input {
    font-size: 16px;
    color: #656565;
    &::placeholder {
      opacity: 1;
    }
  }
`;
export const WarningCard = styled.div`
  top: 302px;
  left: 379px;
  width: 350px;
  height: 130px;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  margin: 5px;
  padding: 52px 44px;
  text-align: left;
  line-height: normal;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  letter-spacing: 0px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 15px;
    width: 402px;
    min-height: 78px;
  }
  .text-blue {
    color: #2a5299;
  }
`;
export default withTranslation()(AuthorizationFuelStation);
