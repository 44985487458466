import registrationFormApi from 'api/registration-form-api';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'config/i18n';
import HttpStatus from 'model/enums/http-status';
import { IReturnMessage } from 'model/financial';
import {
  IBankData,
  IChildren,
  IContact,
  ICooperatorAreas,
  ICooperatorMachines,
  ICooperatorProperties,
  IEnvInfo,
  IHeaderCooperative,
  IHeaderProperty,
  IMyProperty,
  IPersonalData,
  IPlantelGroup,
  IPropertiesAreas,
  IRegistrationForm,
  IRelationship,
  ISprings,
} from 'model/registration-form';
import toastUtils from 'shared/util/toast-utils';

export const registrationFormService = () => {
  const myProperties = async (registration: number): Promise<IMyProperty[]> => {
    try {
      const result = await registrationFormApi.myProperties(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAreasCooperative = async (registration: number): Promise<ICooperatorAreas[]> => {
    try {
      const result = await registrationFormApi.getAreasCooperative(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const personalData = async (registration: number): Promise<IPersonalData> => {
    try {
      const result = await registrationFormApi.personalData(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data[0]);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getBankData = async (registration: number): Promise<IBankData> => {
    try {
      const result = await registrationFormApi.getBankData(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data[0]);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getRelationshipData = async (partyNumber: string): Promise<IRelationship> => {
    try {
      const result = await registrationFormApi.getRelationshipData(partyNumber);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const updateRegistrationData = async (data: IRegistrationForm): Promise<IRegistrationForm> => {
    try {
      const result = await registrationFormApi.updateRegistrationData(data);
      if (result.status === HttpStatus.OK && result.data != null) {
        toastUtils.emitSuccessToast(i18n.t('global.successMessage.updateData'));
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.updateData'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.updateData'));
      return Promise.reject(response);
    }
  };

  const getProperties = async (registration: number): Promise<ICooperatorProperties[]> => {
    try {
      const result = await registrationFormApi.getProperties(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getMachines = async (registration: number): Promise<ICooperatorMachines[]> => {
    try {
      const result = await registrationFormApi.getMachines(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getEnvInfo = async (registration: number, property: number): Promise<IEnvInfo[]> => {
    try {
      const result = await registrationFormApi.getEnvInfo(registration, property);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getSprings = async (property: number): Promise<ISprings[]> => {
    try {
      const result = await registrationFormApi.getSprings(property);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const updateEnvInfo = async (
    register: string,
    property: number,
    tankType: string,
    tankCapacity: string,
    maintenanceShed: string,
    concreteFloorMaintenance: string,
    ie_manutencao_embalagem: string,
    ie_lavador_trapiche: string,
    ie_lavador_caixa: string,
    abastecimento_pulv_rio: string,
    abastecimento_pulv_caixa: string,
    abastecimento_pulv_movel: string,
    ie_captacao_agua_rio: string,
    ie_captacao_agua_poco: string,
    ie_captacao_agua_vertente: string,
    ie_agrot_emb_cheia_prop: string,
    agrot_emb_vazia_prop: string,
    agrot_emb_cheia_arm: string,
    ie_agrot_emb_vazia_arm: string,
    ie_unidade_grao: string,
    ie_unidade_certificada: string,
    ie_tratam_dejeto_suino: string,
    ie_tratam_dej_sui_lic: string,
    ie_tratam_dejeto_bovino: string,
    ie_tratam_dej_bov_trat: string,
    ie_biodigestor: string,
    ie_biodigestor_energia: string,
    ie_biodigestor_suino: string,
    ie_biodigestor_bovino: string,
    ie_composteira: string,
    qt_composteira: string,
    ie_possui_rio: string,
    nm_rio: string
  ): Promise<IReturnMessage> => {
    try {
      const result = await registrationFormApi.updateEnvInfo(
        register,
        property,
        tankType,
        tankCapacity,
        maintenanceShed,
        concreteFloorMaintenance,
        ie_manutencao_embalagem,
        ie_lavador_trapiche,
        ie_lavador_caixa,
        abastecimento_pulv_rio,
        abastecimento_pulv_caixa,
        abastecimento_pulv_movel,
        ie_captacao_agua_rio,
        ie_captacao_agua_poco,
        ie_captacao_agua_vertente,
        ie_agrot_emb_cheia_prop,
        agrot_emb_vazia_prop,
        agrot_emb_cheia_arm,
        ie_agrot_emb_vazia_arm,
        ie_unidade_grao,
        ie_unidade_certificada,
        ie_tratam_dejeto_suino,
        ie_tratam_dej_sui_lic,
        ie_tratam_dejeto_bovino,
        ie_tratam_dej_bov_trat,
        ie_biodigestor,
        ie_biodigestor_energia,
        ie_biodigestor_suino,
        ie_biodigestor_bovino,
        ie_composteira,
        qt_composteira,
        ie_possui_rio,
        nm_rio
      );
      if (result.status === HttpStatus.OK && result.data != null) {
        toastUtils.emitSuccessToast(i18n.t('global.successMessage.updateData'));
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.updateData'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.updateData'));
      return Promise.reject(response);
    }
  };

  const getHeaderCooperativeMember = async (register: string, property: number): Promise<IHeaderCooperative[]> => {
    try {
      const result = await registrationFormApi.getHeaderCooperativeMember(register, property);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getHeaderProperties = async (register: string, property: number): Promise<IHeaderProperty[]> => {
    try {
      const result = await registrationFormApi.getHeaderProperties(register, property);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getPropertiesAreas = async (register: string, property: number): Promise<IPropertiesAreas[]> => {
    try {
      const result = await registrationFormApi.getPropertiesAreas(register, property);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const updateSpring = async (property: number, sequency: number, springQuantity: number, reserve: string): Promise<IReturnMessage> => {
    try {
      const result = await registrationFormApi.updateSpring(property, sequency, springQuantity, reserve);
      if (result.status === HttpStatus.OK && result.data != null) {
        toastUtils.emitSuccessToast(i18n.t('global.successMessage.updateData'));
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.updateData'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.updateData'));
      return Promise.reject(response);
    }
  };

  const getChildren = async (registration: string): Promise<IChildren[]> => {
    try {
      const result = await registrationFormApi.getChildren(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getContact = async (registration: string): Promise<IContact[]> => {
    try {
      const result = await registrationFormApi.getContact(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const updateContact = async (data: IContact): Promise<IReturnMessage> => {
    try {
      const result = await registrationFormApi.updateContact(data);
      if (result.status === HttpStatus.OK && result.data != null) {
        toastUtils.emitSuccessToast(i18n.t('global.successMessage.updateData'));
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.updateData'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.updateData'));
      return Promise.reject(response);
    }
  };

  const getPlantelGroup = async (registration: string): Promise<IPlantelGroup[]> => {
    try {
      const result = await registrationFormApi.getPlantelGroup(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    myProperties,
    getAreasCooperative,
    personalData,
    getBankData,
    getRelationshipData,
    updateRegistrationData,
    getProperties,
    getMachines,
    getEnvInfo,
    getSprings,
    updateEnvInfo,
    getHeaderCooperativeMember,
    getHeaderProperties,
    getPropertiesAreas,
    updateSpring,
    getChildren,
    getContact,
    updateContact,
    getPlantelGroup,
  };
};

export default registrationFormService();
