import packingApi from 'api/agricultural/packing-api';
import { AxiosError, AxiosResponse } from 'axios';
import bcrypt from 'bcryptjs';
import i18n from 'config/i18n';
import md5 from 'md5';
import HttpStatus from 'model/enums/http-status';
import IPacking, { IItens, IPackingBody } from 'model/packing';
import StringUtils from 'shared/util/string-utils';
import toastUtils from 'shared/util/toast-utils';

export const packingService = () => {
  const getPackagingControlData = async (registration: number) => {
    try {
      const result: AxiosResponse<IPacking[]> = await packingApi.getPackagingControlData(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const giveBackPackages = async (packages: IItens): Promise<IPacking[]> => {
    try {
      const result: AxiosResponse<IPacking[]> = await packingApi.giveBackPackages(packages);
      if (result.status === HttpStatus.OK && result.data != null) {
        toastUtils.emitSuccessToast(i18n.t('global.successMessage.package'));
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.package'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.package'));
      console.error(response);
      return Promise.reject(response);
    }
  };

  const generateReport = (registration: number) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/DAT/DAT0191&cd_pessoa=${registration}`;

    const urlConvertCharcode = StringUtils.convertToCharCode(path);
    const md5EncryptedUrl = md5(urlConvertCharcode);
    const bcryptEncrypted = bcrypt.hashSync(md5EncryptedUrl);
    const charCodeUrlReportAuth = StringUtils.convertToCharCode(bcryptEncrypted);

    return `https://servicos.frisia.coop.br/RPT/Report/ReportTemplate?ReportId=${urlConvertCharcode}&ReportAuth=${charCodeUrlReportAuth}`;
  };

  return {
    getPackagingControlData,
    giveBackPackages,
    generateReport,
  };
};

export default packingService();
