import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import weatherService from 'services/weather-service';
import styled from 'styled-components';
import { ReactComponent as DownIconSvg } from '../images/frisia/icons/red-down-arrow-icon.svg';
import { ReactComponent as UpIconSvg } from '../images/frisia/icons/yellow-up-arrow-icon.svg';
import { WeatherIcons } from 'model/weather-icons';
import { TextField } from '@material-ui/core';
import debounce from 'debounce-promise';
import LoadingContainerScreen from './loading-container-screen/loading-container-screen';

interface mappedType {
  pop: number;
  humidity: number;
  icon: string;
  temperature: number;
  timezone: number;
  dt_txt: string;
  max: number;
  min: number;
}

const WeatherTable = ({ t }: WithTranslation) => {
  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');
  const [searching, setSearching] = useState(false);
  const [city, setCity] = useState('Carambeí');
  const [forecast, setForecast] = useState<mappedType[]>([]);
  const [resultList, setResultList] = useState<mappedType[]>([]);
  const [todaysForecast, setTodaysForecast] = useState<mappedType>();

  function getLocation() {
    if ('geolocation' in navigator) {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      const success = (pos: any) => {
        const crd = pos.coords;
      };

      const error = (err: any) => {
        console.warn(`ERROR '(${err.code})': ${err.message}`);
      };

      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      alert('Sinto muito, mas o seu navegador não suporta os serviços de geolocalização.');
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }

  function showPosition(position) {
    setLat(position.coords.latitude);
    setLon(position.coords.longitude);
  }

  function mapResponseProperties(data) {
    let mapped = {
      pop: data.pop,
      humidity: data.main.humidity,
      icon: data.weather[0].icon,
      temperature: Math.round(data.main.temp),
      timezone: data.timezone / 3600,
      dt_txt: '',
      max: data.main.temp_max,
      min: data.main.temp_min,
    };
    if (data.dt_txt) {
      mapped.dt_txt = data.dt_txt;
    }
    Object.entries(mapped).map(([key, value]) => value === undefined && delete mapped[key]);

    return mapped;
  }

  const getWeather = async (cityChosen: string) => {
    return await weatherService.weatherByCity(cityChosen).then(resultOfCity => {
      weatherService.forecast6days(resultOfCity.coord.lat.toString(), resultOfCity.coord.lon.toString()).then(result => {
        if (result?.list && Object.entries(result).length) {
          setResultList(result.list.map(mapResponseProperties));
          setForecast(result.list.map(mapResponseProperties));
          setTodaysForecast(result.list.map(mapResponseProperties)[0]);
          setCity(`${result.city.name}, ${result.city.country}`);
          setSearching(false);
        }
      });
    });
  };

  useEffect(() => {
    setForecast(
      resultList
        .map(item => {
          let maxArray: number[] = [];
          let minArray: number[] = [];
          for (let i = 0; i < resultList.length; i++) {
            if (dayjs(resultList[i]?.dt_txt).format('DD/MM') === dayjs(item?.dt_txt).format('DD/MM')) {
              maxArray.push(resultList[i].max);
              minArray.push(resultList[i].min);
            }
          }
          return { ...item, max: Math.round(Math.max(...maxArray)), min: Math.round(Math.min(...minArray)) };
        })
        .filter(f => f.dt_txt.match(/09:00:00/))
    );
  }, [resultList]);

  const saveCycles = debounce(getWeather, 1550, { leading: true });

  const getWeatherByLatLon = async () => {
    const result = await weatherService.forecast6days(lat, lon);
    if (result?.list && Object.entries(result).length) {
      setResultList(result.list.map(mapResponseProperties));
      setForecast(result.list.map(mapResponseProperties));
      setTodaysForecast(result.list.map(mapResponseProperties)[0]);
      setCity(result.city.name);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (lat !== '' && lon !== '') {
      getWeatherByLatLon();
    }
  }, [lat, lon]);

  return (
    <StyledContainer>
      <StyledHeader>
        <section>
          <div>
            {todaysForecast?.temperature}°{todaysForecast?.icon && WeatherIcons[todaysForecast.icon]}&nbsp;
          </div>
          <div>
            <b>{city}</b>
          </div>
          <div>
            <span>
              {dayjs(todaysForecast?.dt_txt).format('DD/MM')},&nbsp;
              {dayjs(todaysForecast?.dt_txt).format('dddd')}
            </span>
          </div>
        </section>
        <div style={{ flex: '0.8', background: 'aliceblue', borderRadius: '12px' }}>
          <TextField
            label={t('global.button.city')}
            onChange={e => {
              setSearching(true);
              saveCycles(e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
            }}
            variant="filled"
            color="primary"
            type={'text'}
          />
        </div>
      </StyledHeader>
      <LoadingContainerScreen isLoading={searching}>
        <StyledTable>
          <tbody>
            {forecast.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {WeatherIcons[item.icon]}&nbsp;
                    {dayjs(item.dt_txt).date()},&nbsp;
                    {dayjs(item.dt_txt).format('dddd')}
                  </td>
                  <td>
                    <DownIconSvg />
                    {item.min}°&nbsp;
                    <UpIconSvg />
                    {item.max}°
                  </td>
                  <td>
                    💧{item.humidity}%&nbsp;☔{Math.round(item.pop * 100)}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </LoadingContainerScreen>
    </StyledContainer>
  );
};

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;
  td,
  th {
    padding: 8px;
    text-align: left;
    padding-left: 23px;
    height: 48px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  tr:hover {
    background-color: #ddd;
  }
`;

const StyledContainer = styled.div`
  height: 372px;
  min-width: 448px;
  width: 560px;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
`;

const Warning = styled.span`
  color: #ffffff;
  font-size: 27px;
  text-align: center;
  margin: auto;
`;

const StyledHeader = styled.div`
  justify-content: space-around;
  display: flex;
  align-items: center;
  height: 135px;
  background-color: ${({ theme }) => theme.color.frisiaPrimary};
  border-radius: 21px 21px 0 0;
  color: #ffffff;
  font-family: normal normal 300 12px/15px;
  font-size: 16px;
  letter-spacing: 0.01px;
  padding: 9px;
  text-align: start;
  padding: 5px 19px 11px;
  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  div:first-child {
    font-family: ${({ theme }) => theme.font.montserratMedium};
    font-size: 49px;
    width: 100%;
  }
`;

export default withTranslation()(WeatherTable);
