import GenericDropdown from 'components/GenericDropdown';
import GenericInputToTable from 'components/GenericInputToTable';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import { IHistoryAuthData, IHistoryAuthListed } from 'model/agricultural-sales-authorization';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import salesAuthorizationService from 'services/agricultural/sales-authorization--service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface IForm {
  motivation: string;
}

const ConsultSalesAuthorization = ({ t }: WithTranslation) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [chosenYear, setChosenYear] = useState<string>();
  const [lisOfAuth, setLisOfAuth] = useState<IHistoryAuthListed[]>([]);
  const [lisOfAuthByHarvest, setLisOfAuthByHarvest] = useState<IHistoryAuthData[]>([]);

  const [chosenAuth, setChosenAuth] = useState<IHistoryAuthListed>();

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getHistoryOfSalesAuth = async () => {
    await salesAuthorizationService.getHistoryOfSalesAuth(getUserRegistration).then(response => setLisOfAuthByHarvest(response));
  };

  useEffect(() => {
    getHistoryOfSalesAuth();
  }, []);

  useEffect(() => {
    if (chosenYear && lisOfAuthByHarvest) {
      setLisOfAuth(lisOfAuthByHarvest?.find(item => item.CD_SAFRA_TEXT === chosenYear)?.LISTA ?? []);
    }
  }, [chosenYear, lisOfAuthByHarvest]);

  const schema = yup.object({
    motivation: yup.string().required(),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  const handleSubmit = async data => {
    if (chosenAuth?.NR_OPCAO_VENDA && chosenAuth.NR_VINCULACAO_OPCAO) {
      await salesAuthorizationService
        .cancelSalesAuth(getUserRegistration, chosenAuth.NR_OPCAO_VENDA, chosenAuth.NR_VINCULACAO_OPCAO, data.motivation)
        .then(response => {
          setOpenModal(false);
          getHistoryOfSalesAuth();
        });
    }
  };

  return (
    <>
      {openModal && (
        <ModalContainer>
          <ModalShadow onClick={() => setOpenModal(false)} />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <CardModalContainer>
                <div style={{ padding: '10px' }}>
                  <TitleStyled>
                    {t('salesAuth.confirmCancel')} {chosenAuth?.NR_OPCAO_VENDA} - {chosenAuth?.NM_REDUZIDO}?
                  </TitleStyled>
                  <GenericInputToTable
                    inputName="motivation"
                    label={t('salesAuth.motivation')}
                    placeholder={t('salesAuth.describeMotivation')}
                    errorText={methods.formState.errors.motivation?.message}
                  />
                  <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <CancelButton
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      style={{ width: '65px', height: '40px' }}
                    >
                      {t('salesAuth.no')}
                    </CancelButton>
                    <StyledButton style={{ height: '47px', maxWidth: 'none' }} color="frisiaPrimary" type="submit">
                      <span>{t('salesAuth.yes')}</span>
                    </StyledButton>
                  </div>
                </div>
              </CardModalContainer>
            </form>
          </FormProvider>
        </ModalContainer>
      )}
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={20} text={t('card.consultSalesAuthorization')} />
      </StyledPageTitleContainer>
      <StyledFilterContainer filterWidth={300}>
        <GenericDropdown
          label={t('agricultural.harvest')}
          placeholder={t('agricultural.selectHarvest')}
          listShowDropdown={lisOfAuthByHarvest.map(item => item.CD_SAFRA_TEXT)}
          getSelectedValue={value => setChosenYear(value)}
        />
      </StyledFilterContainer>
      <StyledContainer>
        {lisOfAuth?.map((item, index) => (
          <StyledCard key={index}>
            <StyledTitle>
              <div>
                {t('salesAuth.authorization')} {item.NR_OPCAO_VENDA}
              </div>
              <div>
                {t('salesAuth.date')}: {item.DT_OPCAO_VENDA}
              </div>
            </StyledTitle>
            <div style={{ height: '2px', width: '100%', backgroundColor: '#D7CC27', margin: '5px auto' }} />
            <StyledTable>
              <tbody>
                <tr>
                  <th>
                    {t('salesAuth.type')}: {item.DS_TIPO_OPCAO_VENDA}
                  </th>
                </tr>
                <tr>
                  <th>
                    {t('global.field.product')}: {item.DS_PRODUTO_COMPLETA}
                  </th>
                </tr>
                <tr>
                  <td>{t('agricultural.quantity')}:</td>
                  <td>{item.QT_ITEM_OPCAO.toLocaleString('pt-BR')} KG</td>
                </tr>
                <tr>
                  <td>{t('agricultural.value')}:</td>
                  <td>R$ {item.VL_OPCAO_VENDA.toLocaleString('pt-BR')}</td>
                </tr>
                <tr>
                  <td>{t('agricultural.branch')}:</td>
                  <td>
                    {item.CD_FILIAL} - {item.NM_REDUZIDO}
                  </td>
                </tr>
                <tr>
                  <td>{t('agricultural.mode')}:</td>
                  <td>{item.DS_TIPO_ROMANEIO}</td>
                </tr>
                <tr>
                  <td>{t('support.situation')}:</td>
                  <td>{item.DS_SITUACAO}</td>
                </tr>
                <tr>
                  <td>{t('financial.Origin')}:</td>
                  <td>{item.DS_SISTEMA}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </StyledTable>
            {item.IE_PERMITE_CANCELAMENTO === 'S' && (
              <StyledButton
                style={{ height: '47px', position: 'relative', maxWidth: 'none' }}
                color="orange"
                type="button"
                onClick={() => {
                  setOpenModal(true);
                  setChosenAuth(item);
                }}
              >
                <span>{t('salesAuth.cancelAuth')}</span>
              </StyledButton>
            )}
          </StyledCard>
        ))}
      </StyledContainer>
    </>
  );
};

const StyledCard = styled.div`
  top: 302px;
  left: 379px;
  width: 500px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  margin: 5px;
  padding: 30px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
`;

const StyledTitle = styled.div`
  text-align: left;
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.montserratBold};
  letter-spacing: 0.01px;
  color: #032762;
  opacity: 1;
  display: flex;
  justify-content: space-between;
`;

export const StyledTable = styled.table`
  font-family: ${({ theme }) => theme.font.montserratMedium};
  width: 100%;
  font-size: 16px;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
  td,
  th {
    padding: 2px;
    text-align: right;
  }
  td {
    min-width: 154px;
  }
  th {
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: #2a5299;
    opacity: 1;
    width: 100%;
  }
  tr > th {
    width: 100%;
  }
  td:first-child,
  th:first-child {
    text-align: left;
    color: #2a5299;
  }
  tr:hover {
    background-color: #ddd;
  }
  svg {
    vertical-align: initial;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 150vh;
  width: 150vw;
  transform: translate(-25%, -33%);
`;

const CardModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  min-width: 445px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  b {
    align-self: self-start;
    margin: 20px 0px;
  }
`;

const TitleStyled = styled.div`
  text-align: left;
  margin-bottom: 16px;
  font-size: 20px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.color.frisiaPrimaryDark};
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const CancelButton = styled.button`
  width: 245px;
  height: 34px;
  font-size: 14px;
  letter-spacing: 0.02px;
  color: #d4a518;
  text-transform: uppercase;
  opacity: 1;
  border: #d4a518 2px solid;
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  cursor: pointer;
`;

export default withTranslation()(ConsultSalesAuthorization);
