import SalesOrderStatus from 'model/enums/sales-order-status';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IProps extends WithTranslation {
  status: string;
  applyAnimation?: boolean;
}

interface IStyled {
  backgroundColor: string;
  applyAnimation?: boolean;
}

const getStatusColor = (status: string) => {
  const color = {
    FATURADO: '#61B2E8',
    CANCELADO: '#D96767',
    APROVADO: '#32D175',
    PENDENTE: '#D7CC27',
    DEFAULT: '#D7CC27',
  };

  return color[status] || color.DEFAULT;
};

const FeedOrderStatus = ({ status, applyAnimation, t }: IProps) => {
  return (
    <StyledContainer backgroundColor={getStatusColor(status)} applyAnimation={applyAnimation}>
      {status}
    </StyledContainer>
  );
};

const StyledContainer = styled.span<IStyled>`
  @keyframes status_animation {
    from {
      left: 30px;
    }
    to {
      left: 220px;
    }
  }
  position: absolute;
  top: -9px;
  left: ${({ applyAnimation }) => (applyAnimation ? '220px' : '30px')};
  width: 100px;
  height: 19px;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #032762;
  font-size: 12px;
  border-radius: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  animation: ${({ applyAnimation }) => (applyAnimation ? 'status_animation 0.3s 0s' : '')};
`;

export default withTranslation()(FeedOrderStatus);
