import loadApi from 'api/load-api';
import swineApi from 'api/swine-api.';
import { AxiosError, AxiosResponse } from 'axios';
import { OptionsSafraProductsResponse } from 'model/agriculturalSalesStatement';
import HttpStatus from 'model/enums/http-status';

export const swineService = () => {
  const getPrevSummary = async (registration: number) => {
    try {
      const result: AxiosResponse<any[]> = await swineApi.getPrevSummary(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getPrevSummary,
  };
};

export default swineService();
