import { AxiosResponse } from 'axios';
import { QuoteOptions } from 'model/enums/quote-options';
import { sendLog } from 'shared/util/api-utils';
import { apiCepea } from './api';

export const cepeaApi = () => {
  const getWidget = (quoteType: QuoteOptions): Promise<AxiosResponse<string>> => {
    sendLog('Consultando dados de cotação Cepea', `&id_indicador%5B%5D=12&id_indicador%5B%5D=92`, JSON.stringify([]), 'get');

    switch (quoteType) {
      case QuoteOptions.SOY:
        return apiCepea.get<string>('&id_indicador%5B%5D=12&id_indicador%5B%5D=92');
      case QuoteOptions.WHEAT:
        return apiCepea.get<string>('&id_indicador%5B%5D=178&id_indicador%5B%5D=179');
      case QuoteOptions.CORN:
        return apiCepea.get<string>('&id_indicador%5B%5D=77');
      case QuoteOptions.PIG:
        return apiCepea.get<string>(
          '&id_indicador%5B%5D=129-10&id_indicador%5B%5D=129-6&id_indicador%5B%5D=129-4&id_indicador%5B%5D=129-5&id_indicador%5B%5D=129-1&id_indicador%5B%5D=124'
        );
      case QuoteOptions.MILK:
        return apiCepea.get<string>('&id_indicador%5B%5D=leitep');
      default:
        break;
    }
    return apiCepea.get<string>('&id_indicador%5B%5D=12&id_indicador%5B%5D=92');
  };

  return {
    getWidget,
  };
};

export default cepeaApi();
