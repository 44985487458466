import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as LeftIconSvg } from '../images/frisia/icons/left-arrow-icon.svg';
import { ReactComponent as RightIconSvg } from '../images/frisia/icons/right-arrow-icon.svg';
import contentfulService from 'services/contentful-service';
import moment from 'moment';
import { ICategoriesList, IContentTypeCategories } from 'model/contentful-results';

const StyledTablesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 37px;
  margin-top: 10px;
  @media (max-height: 1000px), (max-width: 1400px) {
    zoom: 1;
  }
  @media (max-height: 800px), (max-width: 1369px) {
    zoom: 78%;
  }
  @media (max-height: 600px), (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledControls = styled.div`
  max-width: 1050px;
  width: 100%;
  opacity: 1;
  padding: 10px;
  margin: 10px;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1d1d1d29;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
`;

const ButtonsStyled = styled.div`
  display: flex;
  align-self: center;
  button {
    width: 63px;
    height: 63px;
    border: none;
    cursor: pointer;
    margin-top: 14px;
    background: #e6eaf2 0% 0% no-repeat padding-box;
  }
`;

const BarStyled = styled.div`
  opacity: 1;
  border: 0.5px solid #637d90;
  height: 75px;
  background-color: #d7cc27;
  opacity: 1;
  width: 6px;
`;

const TitleStyled = styled.div`
  width: 340px;
  text-align: left;
  font-size: 15px;
  align-items: center;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  place-self: flex-start;
  display: flex;
  justify-content: space-between;
  align-self: center;
  div {
    margin: 0px 18px;
  }
`;

const PlayerVideo = ({ t }: WithTranslation) => {
  const history = useHistory();
  const [contentVideos, setContentVideos] = useState<any[]>([]);

  let { id } = useParams<{ id: string }>();
  const [videoUrl, setVideoUrl] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [videoCategory, setVideoCategory] = useState('');
  const [videoCategoryId, setVideoCategoryId] = useState('');
  const [categories, setCategories] = useState<ICategoriesList[]>([]);

  const getCategories = async () => {
    const result: IContentTypeCategories = await contentfulService.getAllCategories();
    setCategories(
      result.items.map(category => {
        return { name: category.fields.title, id: category.sys.id };
      })
    );
  };

  const getContent = async () => {
    const videoId = await contentfulService.getbyId(id);
    setVideoUrl(videoId.items[0].fields.link);
    setVideoTitle(videoId.items[0].fields.title);
    setCreatedAt(videoId.items[0].sys.createdAt);
    setVideoCategoryId(videoId.items[0].fields.category.sys.id);
    const videosResult = await contentfulService.getAllVideos();
    setContentVideos(videosResult.items);
  };

  useEffect(() => {
    getContent();
    getCategories();
  }, [id]);

  useEffect(() => {
    categories.map(item => {
      if (item.id === videoCategoryId) {
        setVideoCategory(item.name);
      }
    });
  }, [categories, videoCategoryId]);

  const pushFoward = async () => {
    const indexVideo = contentVideos.findIndex(item => {
      return item.sys.id === id;
    });
    let location;
    if (indexVideo + 1 === contentVideos.length) {
      location = {
        pathname: `/canal-frisia/play/${contentVideos[0].sys.id}`,
      };
    } else {
      location = {
        pathname: `/canal-frisia/play/${contentVideos[indexVideo + 1].sys.id}`,
      };
    }
    history.push(location);
  };

  const pushBack = async () => {
    const indexVideo = contentVideos.findIndex(item => {
      return item.sys.id === id;
    });
    let location;
    if (indexVideo - 1 < 0) {
      location = {
        pathname: `/canal-frisia/play/${contentVideos[contentVideos.length - 1].sys.id}`,
      };
    } else {
      location = {
        pathname: `/canal-frisia/play/${contentVideos[indexVideo - 1].sys.id}`,
      };
    }
    history.push(location);
  };

  return (
    <>
      <StyledTablesContainer>
        {videoUrl && <iframe id="myFrame" width="1047px" height="589px" src={videoUrl}></iframe>}
        <StyledControls>
          <TitleStyled>
            <div>
              <b>{videoTitle}</b>
            </div>
            <div>
              {t('channel.posted')} <b>{`${moment(createdAt).format('DD.MM')} às ${moment(createdAt).format('HH')}h`}</b>
            </div>
          </TitleStyled>
          <ButtonsStyled>
            <button onClick={pushBack}>
              <LeftIconSvg />
            </button>
            <BarStyled />
            <button onClick={pushFoward}>
              <RightIconSvg />
            </button>
          </ButtonsStyled>
          <TitleStyled>
            <div></div>
            <div>
              <div>
                {t('channel.category')}
                <b>{videoCategory}</b>
              </div>
            </div>
          </TitleStyled>
        </StyledControls>
      </StyledTablesContainer>
    </>
  );
};

export default withTranslation()(PlayerVideo);
