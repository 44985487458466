import React, { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation, WithTranslation } from 'react-i18next';
import { api, apiCore, apiOracle } from '../../api/api';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { StyledRegistrationFormBackground } from 'styled-components/StyledRegistrationForm';
import { ReactComponent as ContactsIconSvg } from '../../images/frisia/icons/blue_contacts_icon.svg';
import { ReactComponent as EditCircleIconSvg } from '../../images/frisia/icons/blue-edit-circle-icon.svg';
import { ReactComponent as TrashCircleIconSvg } from '../../images/frisia/icons/red-trash-circle-icon.svg';
import { ReactComponent as SaveIconSvg } from '../../images/frisia/icons/blue-floppy-icon.svg';
import { ReactComponent as DiscardIconSvg } from '../../images/frisia/icons/blue-discard-changes-icon.svg';
import { ReactComponent as AddIconSvg } from '../../images/frisia/icons/blue-circle-add-icon.svg';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import GenericDropdown from 'components/GenericDropdown2';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomAlert from 'styled-components/CustomAlert';
import CustomConfirm from 'styled-components/CustomConfirm';

interface IForm {
  property: string;
}

interface IContato {
  cd_pessoa: number;
  contact_point_id: number | null;
  party_site_id: number;
  contact_point_type: string;
  phone_type: string;
  contact_point_purpose: string;
  ds_contato: string;
  ie_contact_point_principal: 'N' | 'Y';
}

interface ITiposContato {
  contact_point_type: string;
  contact_point_translate: string;
}

interface IFinalidadesContato {
  contact_point_purpose: string;
  contact_point_translate: string;
}

interface IPropriedades {
  party_site_id: number;
  ds_complemento: string;
}

const ContactList = ({ t }: WithTranslation) => {
  const [propertyOptions, setPropertyOptions] = useState<IPropriedades[]>([]);
  const [chosenProperty, setChosenProperty] = useState<IPropriedades | undefined>(undefined);
  const [contatos, setContatos] = useState<IContato[]>([]);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [originalContato, setOriginalContato] = useState<IContato | null>(null);
  const [tiposDisponiveis, setTiposDisponiveis] = useState<ITiposContato[]>([]);
  const [finalidadesDisponiveis, setFinalidadesDisponiveis] = useState<IFinalidadesContato[]>([]);
  const [addingNew, setAddingNew] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<string>('');
  const [resolveConfirm, setResolveConfirm] = useState<(isConfirmed: boolean) => void>(() => {});

  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertMessage('');
  };

  const handleConfirmClose = (isConfirmed: boolean) => {
    setShowConfirm(false);
    resolveConfirm(isConfirmed);
  };

  const confirmDialog = (message: string): Promise<boolean> => {
    setConfirmMessage(message);
    setShowConfirm(true);

    return new Promise(resolve => {
      setResolveConfirm(() => resolve);
    });
  };

  const userRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa ?? 0;
  });
  const schema = yup.object({
    harvest: yup.string().test('test-harvest', t('global.errorMessage.required'), () => chosenProperty != null),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchPropriedades = async () => {
      try {
        const response = await apiCore.get<IPropriedades[]>(`/Cadastro/GetPropriedadesContato?cd_pessoa=${userRegistration}`);
        setPropertyOptions(response.data);
      } catch (error) {
        console.error('Erro ao buscar propriedades:', error);
      }
    };

    fetchPropriedades();

    const fetchTipos = async () => {
      try {
        const response = await apiCore.get<ITiposContato[]>(`/Cadastro/GetTiposContato`);
        setTiposDisponiveis(response.data);
      } catch (error) {
        console.error('Erro ao buscar tipos de contato:', error);
      }
    };

    fetchTipos();

    const fetchFinalidades = async () => {
      try {
        const response = await apiCore.get<IFinalidadesContato[]>(`/Cadastro/GetFinalidadesContato`);
        setFinalidadesDisponiveis(response.data);
      } catch (error) {
        console.error('Erro ao buscar finalidades de contato:', error);
      }
    };

    fetchFinalidades();

    const updateContainerHeight = () => {
      const windowHeight = window.innerHeight;
      const maxHeight = windowHeight * 0.55;
      setContainerHeight(maxHeight);
    };

    updateContainerHeight();
    window.addEventListener('resize', updateContainerHeight);

    return () => {
      window.removeEventListener('resize', updateContainerHeight);
    };
  }, [userRegistration]);

  useEffect(() => {
    if (chosenProperty) {
      handlePropriedadeChange(chosenProperty.party_site_id);
    }
  }, [chosenProperty]);

  const isValidEmails = (emails: string): boolean => {
    const emailArray = emails.split(';');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailArray.every(email => emailRegex.test(email.trim()));
  };

  const isValidPhoneNumber = (phoneNumber: string): boolean => {
    // Verifica se a string contém apenas números de 0 a 9
    const isNumeric = /^\d+$/.test(phoneNumber);

    // Verifica o comprimento da string
    const isValidLength = phoneNumber.length === 10 || phoneNumber.length === 11;

    // Retorna true se ambas as condições forem verdadeiras, caso contrário, retorna false
    return isNumeric && isValidLength;
  };

  const handlePropriedadeChange = async (party_site_id: number) => {
    setEditingIndex(null);
    try {
      const response = await apiCore.get<IContato[]>(
        `/Cadastro/GetDadosContato?party_site_id=${party_site_id}&cd_pessoa=${userRegistration}`
      );
      setContatos(response.data);
    } catch (error) {
      console.error('Erro ao buscar contatos:', error);
    }
  };

  const handleEditClick = (index: number) => {
    setAddingNew(false);
    setEditingIndex(index);
    setOriginalContato({ ...contatos[index] });
  };

  const handleSaveClick = async (editedContato: IContato, acao: string) => {
    // Verifica se os e-mails e o telefone são válidos
    if (editedContato.contact_point_type === 'EMAIL') {
      const isEmailValid = isValidEmails(editedContato.ds_contato);
      if (!isEmailValid) {
        alert('Por favor, insira um ou mais emails válidos. Os emails devem estar separados por ponto e vírgula.'); // Ou utilize um componente de alerta mais elegante
        return; // Retorna sem salvar
      }
    }
    if (editedContato.contact_point_type === 'PHONE') {
      const isPhoneValid = isValidPhoneNumber(editedContato.ds_contato);
      if (!isPhoneValid) {
        toast.error('Por favor, insira um telefone válido.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        return; // Retorna sem salvar
      }
    }

    if (acao === 'INATIVAR') {
      // Verifica se o contato que está tentando inativar é principal
      const contatoPrincipal = contatos.find(
        contato => contato.contact_point_id === editedContato.contact_point_id && contato.ie_contact_point_principal === 'Y'
      );

      if (contatoPrincipal) {
        setAlertMessage(
          'Não é possível excluir este contato, pois ele é o contato principal. Por favor, altere o contato principal antes de excluí-lo.'
        );
        setShowAlert(true);
        return;
      }
      const confirmSave = await confirmDialog('Deseja realmente excluir esta linha? Esta operação não poderá ser desfeita.');

      if (!confirmSave) {
        return; // Retorna sem salvar
      }
    }

    // Verifica se já existe um contato principal com o mesmo tipo
    const existingContato = contatos.find(
      contato => contato.contact_point_type === editedContato.contact_point_type && contato.ie_contact_point_principal === 'Y'
    );

    if (!existingContato && acao !== 'INATIVAR') {
      // Se não existir, mantém o ie_contact_point_principal = 'Y' para o contato que está sendo editado
      editedContato.ie_contact_point_principal = 'Y';
    }
    await saveContato(editedContato, acao);
    handlePropriedadeChange(editedContato.party_site_id); // recarrega contatos
  };

  const saveContato = async (contato: IContato, acao: string) => {
    try {
      const response = await apiCore.post(`/Cadastro/AlterarContato?acao=${acao}`, contato);

      if (response.data.sucesso) {
        toast.success(response.data.mensagem, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });

        const updatedContatos = [...contatos];
        updatedContatos[editingIndex as number] = { ...contato };
        setContatos(updatedContatos);
        setEditingIndex(null);
        setOriginalContato(null);
      } else {
        toast.error(response.data.mensagem, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error('Erro ao salvar contato.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      console.error('Erro ao salvar contato:', error);
    }
  };

  // Função para lidar com o clique em descartar alterações
  const handleDiscardClick = () => {
    if (originalContato && editingIndex !== null) {
      const updatedContatos = [...contatos];
      updatedContatos[editingIndex] = { ...originalContato };
      setContatos(updatedContatos);
    } else if (addingNew) {
      // Se estiver adicionando novo, remove o último contato adicionado
      setContatos(prevContatos => prevContatos.slice(0, -1));
    }
    setEditingIndex(null);
    setOriginalContato(null);
  };

  // No retorno do componente, ajuste a renderização da linha para verificar se o contato está na lista atual
  {
    contatos.map((contato, index) => (
      <StyledTableRow key={index} style={{ display: contatos.includes(contato) ? 'table-row' : 'none' }}>
        {/* Renderização do conteúdo da linha aqui */}
      </StyledTableRow>
    ));
  }

  // Função para lidar com a mudança de input (texto)
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, field: keyof IContato) => {
    if (editingIndex !== null) {
      const updatedContatos = [...contatos];
      let value: any = e.target.value;

      updatedContatos[editingIndex] = {
        ...updatedContatos[editingIndex],
        [field]: value,
      };
      setContatos(updatedContatos);
    }
  };

  // Função para lidar com a mudança no dropdown (marca)
  const handleTipoChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (editingIndex !== null) {
      const updatedContatos = [...contatos];
      updatedContatos[editingIndex].contact_point_type = e.target.value; // Converte para número
      if (e.target.value === 'PHONE') {
        updatedContatos[editingIndex].contact_point_purpose = 'BUSINESS';
      }
      setContatos(updatedContatos);
    }
  };

  // Função para lidar com a mudança no dropdown (marca)
  const handleFinalidadeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (editingIndex !== null) {
      const updatedContatos = [...contatos];
      updatedContatos[editingIndex].contact_point_purpose = e.target.value; // Converte para número
      setContatos(updatedContatos);
    }
  };

  // Função para lidar com a mudança no dropdown (booleano)
  const handleDropdownChange = (value: boolean, field: keyof IContato) => {
    if (editingIndex !== null) {
      const updatedContatos = [...contatos];
      // Utilize o type assertion para informar ao TypeScript que field é uma chave válida de IContato
      updatedContatos[editingIndex] = {
        ...updatedContatos[editingIndex],
        [field]: value ? 'Y' : 'N',
      };
      setContatos(updatedContatos);
    }
  };

  // Função para lidar com o clique em adicionar novo contato
  const handleAddClick = () => {
    setAddingNew(true);

    // Lógica para adicionar um novo contato ao estado de contato
    const newContato: IContato = {
      cd_pessoa: userRegistration,
      contact_point_id: null,
      party_site_id: chosenProperty?.party_site_id ?? 0,
      contact_point_type: 'EMAIL',
      phone_type: '',
      contact_point_purpose: 'BUSINESS',
      ds_contato: '',
      ie_contact_point_principal: 'N',
    };
    setContatos([...contatos, newContato]);
    setEditingIndex(contatos.length); // Foca na última linha para edição
    setOriginalContato(null);
  };

  return (
    <>
      <StyledFilterContainer filterWidth={500}>
        <GenericDropdown<IPropriedades>
          label={t('registrationForm.selectProperty')}
          placeholder={t('registrationForm.selectProperty')}
          listShowDropdown={propertyOptions} // Passa a lista de objetos
          displayKey="ds_complemento" // Usa `ds_complemento` para exibição
          getSelectedValue={selectedItem => {
            setChosenProperty(selectedItem); // Recebe o objeto selecionado
          }}
          errorText={methods.formState.errors.property?.message}
        />
      </StyledFilterContainer>
      <StyledRegistrationFormBackground>
        <StyledHeaderForm>
          <StyledTitleContainer>
            <ContactsIconSvg />
            <span>{t('registrationForm.contactData')}</span>
          </StyledTitleContainer>
          {/* Ícone de adicionar somente é exibido quando o editingIndex for null */}
          {editingIndex === null && chosenProperty?.party_site_id != undefined && chosenProperty?.party_site_id != null && (
            <IconWrapper title="Adicionar novo contato" onClick={handleAddClick}>
              <AddIconSvg />
              <span>{t('registrationForm.addContact')}</span>
            </IconWrapper>
          )}
        </StyledHeaderForm>
        <StyledContainer style={{ maxHeight: `${containerHeight}px` }}>
          <StyledTable>
            <thead>
              <StyledTableHeaderRow>
                <StyledTableHeaderCell style={{ width: '110px' }}>CÓDIGO</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '150px' }}>TIPO</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '150px' }}>FINALIDADE</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '500px' }}>CONTATO</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '130px' }}>PRINCIPAL</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '120px' }}>AÇÕES</StyledTableHeaderCell>
              </StyledTableHeaderRow>
            </thead>
            <tbody>
              {contatos.map((contato, index) => (
                <StyledTableRow key={index} style={{ display: editingIndex !== null && editingIndex !== index ? 'none' : 'table-row' }}>
                  <StyledTableCell>{contato.contact_point_id}</StyledTableCell>
                  <StyledTableCell>
                    {editingIndex === index ? (
                      <select
                        value={contato.contact_point_type}
                        onChange={handleTipoChange}
                        disabled={!addingNew} // Desabilita se addingNew for false
                      >
                        {tiposDisponiveis.map(tipo => (
                          <option key={tipo.contact_point_type} value={tipo.contact_point_type}>
                            {tipo.contact_point_translate}
                          </option>
                        ))}
                      </select>
                    ) : (
                      tiposDisponiveis.find(tipo => tipo.contact_point_type === contato.contact_point_type)?.contact_point_translate
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {editingIndex === index ? (
                      <select
                        value={contato.contact_point_purpose}
                        onChange={handleFinalidadeChange}
                        disabled={contato.contact_point_type === 'PHONE' || !addingNew} // Desabilita se addingNew for false ou se for 'PHONE'
                      >
                        {finalidadesDisponiveis.map(finalidade => (
                          <option key={finalidade.contact_point_purpose} value={finalidade.contact_point_purpose}>
                            {finalidade.contact_point_translate}
                          </option>
                        ))}
                      </select>
                    ) : (
                      finalidadesDisponiveis.find(finalidade => finalidade.contact_point_purpose === contato.contact_point_purpose)
                        ?.contact_point_translate
                    )}
                  </StyledTableCell>

                  <StyledEditableTableCell editing={editingIndex === index}>
                    {editingIndex === index ? (
                      // Modo de edição
                      contato.contact_point_type === 'PHONE' ? (
                        <input
                          type="text"
                          value={contato.ds_contato}
                          onChange={e => handleInputChange(e, 'ds_contato')}
                          onBlur={e => {
                            if (!isValidPhoneNumber(e.target.value)) {
                              toast.error('Por favor, insira um telefone válido', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 5000,
                              });
                            }
                          }}
                          placeholder="Digite aqui"
                        />
                      ) : (
                        <input
                          type="text"
                          value={contato.ds_contato}
                          onChange={e => handleInputChange(e, 'ds_contato')}
                          onBlur={e => {
                            if (!isValidEmails(e.target.value)) {
                              toast.error('Por favor, insira e-mails válidos, separados por ponto e vírgula.', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 5000,
                              });
                            }
                          }}
                          placeholder="Digite aqui"
                        />
                      )
                    ) : // Exibição quando não está em edição
                    contato.contact_point_type === 'PHONE' ? (
                      contato.phone_type === 'FIXO' ? (
                        <InputMask
                          mask="(99) 9999-9999"
                          value={contato.ds_contato}
                          readOnly // Definindo como somente leitura
                        >
                          {inputProps => <input {...inputProps} type="text" readOnly />}
                        </InputMask>
                      ) : contato.phone_type === 'MOVEL' ? (
                        <InputMask
                          mask="(99) 99999-9999"
                          value={contato.ds_contato}
                          readOnly // Definindo como somente leitura
                        >
                          {inputProps => <input {...inputProps} type="text" readOnly />}
                        </InputMask>
                      ) : (
                        <span>{contato.ds_contato}</span> // Exibe diretamente para outros tipos
                      )
                    ) : (
                      <span>{contato.ds_contato}</span> // Exibe diretamente se não for PHONE
                    )}
                  </StyledEditableTableCell>

                  <StyledEditableTableCell editing={editingIndex === index}>
                    {editingIndex === index ? (
                      <select
                        value={contato.ie_contact_point_principal}
                        onChange={e => handleDropdownChange(e.target.value === 'Y', 'ie_contact_point_principal')}
                      >
                        <option value="Y">SIM</option>
                        <option value="N">NÃO</option>
                      </select>
                    ) : contato.ie_contact_point_principal === 'Y' ? (
                      'SIM'
                    ) : (
                      'NÃO'
                    )}
                  </StyledEditableTableCell>
                  <StyledTableIconCell>
                    {editingIndex === null && (
                      <IconWrapper title="Editar linha" onClick={() => handleEditClick(index)}>
                        <EditCircleIconSvg />
                      </IconWrapper>
                    )}

                    {/* Ícone de salvar */}
                    {editingIndex === index && (
                      <IconWrapper title="Salvar alterações" onClick={() => handleSaveClick(contato, addingNew ? 'INCLUIR' : 'ATUALIZAR')}>
                        <SaveIconSvg />
                      </IconWrapper>
                    )}

                    {/* Ícone de descartar */}
                    {editingIndex === index && (
                      <IconWrapper title="Descartar alterações" onClick={handleDiscardClick}>
                        <DiscardIconSvg />
                      </IconWrapper>
                    )}

                    {/* Ícone de excluir */}
                    {editingIndex === null && (
                      <IconWrapper title="Excluir linha" onClick={() => handleSaveClick(contato, 'INATIVAR')}>
                        <TrashCircleIconSvg />
                      </IconWrapper>
                    )}

                    {showAlert && <CustomAlert message={alertMessage} onClose={handleAlertClose} />}

                    {showConfirm && (
                      <CustomConfirm
                        message={confirmMessage}
                        onConfirm={() => handleConfirmClose(true)}
                        onCancel={() => handleConfirmClose(false)}
                      />
                    )}

                    {showAlert && <CustomAlert message={alertMessage} onClose={handleAlertClose} />}
                  </StyledTableIconCell>
                </StyledTableRow>
              ))}
            </tbody>
          </StyledTable>
        </StyledContainer>
      </StyledRegistrationFormBackground>{' '}
    </>
  );
};

const StyledHeaderForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 16px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  span {
    margin-bottom: -1px;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  border: 1px solid #ddd;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const StyledTableHeaderRow = styled.tr`
  background: #f1f1f1;
`;

const StyledTableHeaderCell = styled.th`
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  position: sticky;
  top: 0;
  background-color: #f1f1f1; /* Cor de fundo para o cabeçalho fixo */
`;

const StyledTableRow = styled.tr`
  &:nth-child(even) {
    background: #f9f9f9;
  }
`;

const StyledTableCell = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;

const StyledTableIconCell = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

const StyledEditableTableCell = styled(StyledTableCell)<{ editing: boolean }>`
  padding: 8px;
  border: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;

  input,
  select {
    width: 100%;
    border: none;
    background: ${({ editing }) => (editing ? '#f0f8ff' : 'transparent')}; /* Cor de fundo para indicar edição */
    font-family: inherit;
    font-size: inherit;
    padding: 4px 8px;
    box-sizing: border-box;
  }
`;

const IconWrapper = styled.div`
  display: inline-block; /* Torna os ícones em linha */
  cursor: pointer;
  margin-right: 5px;

  svg {
    width: 40px; /* Ajusta o tamanho dos ícones */
    height: 40px;
    fill: #007bff;
  }

  &:hover {
    svg {
      fill: #0056b3;
    }
  }
`;

export default withTranslation()(ContactList);
