import styled from 'styled-components';

interface IStyledProps {
  isActive: boolean;
}
interface IProps extends IStyledProps {
  text: string;
  onClick: () => void;
}

const FilterCard = ({ text, isActive, onClick }: IProps) => {
  return (
    <StyledCard onClick={() => onClick()} isActive={isActive}>
      <div>{text}</div>
    </StyledCard>
  );
};

const StyledCard = styled.div<IStyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-around;

  height: 65px;
  width: 235px;
  padding: 0 25px;
  background-color: #fcfcfc;
  background-color: ${({ theme, isActive }) => (isActive ? `${theme.color.frisiaPrimary}` : '#fcfcfc')};
  font-family: ${({ theme }) => theme.font.arialBold};
  color: ${({ theme, isActive }) => (isActive ? '#fcfcfc' : `${theme.color.frisiaPrimary}`)};
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0px 5px 10px #1d1d1d33;
  font-size: 17px;
  line-height: 1.3;

  svg {
    height: 65px;
  }
`;

export default FilterCard;
