import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

interface IStyledProp {
  width: number;
}

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  textButton: string;
  width: number;
}

const GenericButtonWhiteWithYellow = ({ textButton, width, ...rest }: IProps) => {
  return (
    <StyledButton width={width} {...rest}>
      {textButton}
    </StyledButton>
  );
};

const StyledButton = styled.button<IStyledProp>`
  height: 42.797px;
  width: ${({ width }) => `${width}px`};
  min-width: 115px;
  background-color: white;
  color: #d4a518;
  border: 1px solid #d4a518;
  border-radius: 6px;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  font-size: 14px;
`;
export default GenericButtonWhiteWithYellow;
