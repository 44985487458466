import { apiCore } from 'api/api';
import { AxiosResponse } from 'axios';
import bcrypt from 'bcryptjs';
import md5 from 'md5';
import { ITypeCampaigns, ITypeCampaignsFilter } from 'model/bovine-drug-schedule';
import { sendLog } from 'shared/util/api-utils';
import StringUtils from 'shared/util/string-utils';

export const drugScheduleApi = () => {
  const getCampaignsFilter = (registration: number): Promise<AxiosResponse<ITypeCampaignsFilter[]>> => {
    sendLog('Consulta de campanhas de farmacos', `/Farmacos/GetCampanhas/?cd_pessoa=${registration}`, JSON.stringify([]), 'get');
    return apiCore.get<ITypeCampaignsFilter[]>(`/Farmacos/GetCampanhas/?cd_pessoa=${registration}`);
  };

  const getCampaigns = (campaignId: number, registration: number): Promise<AxiosResponse<ITypeCampaigns[]>> => {
    sendLog(
      'Consulta saldo de farmacos por campanha',
      `/Farmacos/GetSaldoFarmacos/?cd_pessoa=${registration}&cd_campanha=${campaignId}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<ITypeCampaigns[]>(`/Farmacos/GetSaldoFarmacos/?cd_pessoa=${registration}&cd_campanha=${campaignId}`);
  };

  const generateReport = (campaignId: number, registration: number) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/EBS/PFM/XXFRPFM0015&p_id_campanha=${campaignId}&p_nr_matricula=${registration}`;

    sendLog('Consulta relatorio de farmacos', path, JSON.stringify([]), 'get');

    const urlConvertCharcode = StringUtils.convertToCharCode(path);
    const md5EncryptedUrl = md5(urlConvertCharcode);
    const bcryptEncrypted = bcrypt.hashSync(md5EncryptedUrl);
    const charCodeUrlReportAuth = StringUtils.convertToCharCode(bcryptEncrypted);

    return `https://servicos.frisia.coop.br/RPT/Report/ReportTemplate?ReportId=${urlConvertCharcode}&ReportAuth=${charCodeUrlReportAuth}`;
  };

  return {
    getCampaignsFilter,
    getCampaigns,
    generateReport,
  };
};

export default drugScheduleApi();
