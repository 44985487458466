import { CategoryScale, Chart as ChartJS, ChartData, ChartOptions, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ICollect } from 'model/milk-analysis';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, ChartDataLabels);

interface IProps extends WithTranslation {
  collectionData: ICollect[];
  collectionDataCompare?: ICollect[];
  title: string;
}

interface IStyledProps {
  changePosition: boolean;
}

const MilkAnalysisChart = ({ collectionData, collectionDataCompare, title, t }: IProps) => {
  const theme = useTheme();

  const options: ChartOptions<'line'> = {
    plugins: {
      legend: { display: false },
      datalabels: {
        align: 'top',
      },
      tooltip: {
        backgroundColor: '#fff',
        titleColor: '#000',
        bodyColor: '#000',
        multiKeyBackground: theme.color.frisiaPrimary,
        caretSize: 5,
        borderWidth: 1,
        borderColor: theme.color.frisiaPrimary,
        usePointStyle: true,
        yAlign: 'bottom',
        padding: 10,
        boxPadding: 5,
        boxHeight: 10,
      },
    },
    scales: {
      x: {
        ticks: {
          callback: (context, ctx) => {
            const xAxisData = [
              collectionData[context]?.DT_COLETA ?? '',
              '',
              collectionData[context]?.DT_ANALISE ?? '',
              '',
              collectionData[context]?.NR_TANQUE ?? '',
              '',
            ];

            if (collectionDataCompare != null && collectionDataCompare[context] != null) {
              return xAxisData.concat([
                '',
                collectionDataCompare[context]?.DT_COLETA ?? '',
                '',
                collectionDataCompare[context].DT_ANALISE ?? '',
                '',
                collectionDataCompare[context].NR_TANQUE ?? '',
              ]);
            }

            return xAxisData;
          },
          color: '#656565',
          padding: 12,
        },
        grid: {
          color: '#EAF0F4',
          lineWidth: 1.5,
        },
      },
      y: {
        suggestedMin: 0,
        suggestedMax: 6,
        grid: {
          color: '#EAF0F4',
          lineWidth: 1.5,
        },
        ticks: {
          color: '#656565',
        },
      },
    },
    layout: {
      padding: {
        right: 10,
        top: 20,
      },
    },
  };

  let labels: string[] = [];
  if (collectionDataCompare?.length) {
    labels =
      collectionData.length > collectionDataCompare.length ? collectionData.map(() => title) : collectionDataCompare?.map(() => title);
  } else if (collectionData.length > 0) {
    labels = collectionData?.map(() => title);
  }

  const data: ChartData<'line'> = {
    labels,
    datasets: [
      {
        data: collectionData.map(collect => Number(collect.RESULTADO)),
        borderColor: theme.color.frisiaPrimary,
        backgroundColor: '#FFF',
        pointRadius: 4,
        datalabels: {
          color: theme.color.frisiaPrimary,
        },
      },
      {
        data: collectionDataCompare?.map(collectCompare => Number(collectCompare.RESULTADO)) ?? [],
        borderColor: '#d4a518',
        backgroundColor: '#FFF',
        pointRadius: 4,
        datalabels: {
          color: '#d4a518',
        },
      },
    ],
  };

  return (
    <StyledContainer>
      <StyledGraphicContainer>
        <Line options={options} data={data} />
        <StyledFooter changePosition={collectionDataCompare != null && collectionDataCompare?.length > 0}>
          <StyledCollectContainer>
            <span className="field-identification">{t('livestock.collect')}</span>
            <span className="field-identification">{t('livestock.analysis')}</span>
            <span className="field-identification">{t('livestock.tank')}</span>
          </StyledCollectContainer>

          {collectionDataCompare != null && collectionDataCompare?.length > 0 && (
            <StyledCollectContainer style={{ marginTop: '14px' }}>
              <span className="field-identification" style={{ color: '#d4a518' }}>
                {t('livestock.collect')}
              </span>
              <span className="field-identification" style={{ color: '#d4a518' }}>
                {t('livestock.analysis')}
              </span>
              <span className="field-identification" style={{ color: '#d4a518' }}>
                {t('livestock.tank')}
              </span>
            </StyledCollectContainer>
          )}
        </StyledFooter>
      </StyledGraphicContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledGraphicContainer = styled.div`
  margin-left: 51px;
  width: 90%;
  min-width: 1074px;
  position: relative;
`;

const StyledFooter = styled.div<IStyledProps>`
  position: absolute;
  bottom: ${({ changePosition }) => (changePosition ? '13px' : '26px')};
  left: -50px;
`;

const StyledCollectContainer = styled.div`
  display: flex;
  flex-direction: column;

  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 11px;

  .field-identification {
    text-align: right;
    width: 56px;
    margin-top: 12px;
  }
`;

export default withTranslation()(MilkAnalysisChart);
