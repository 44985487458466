import FinancialApi from 'api/financial-api';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'config/i18n';
import IDocument from 'model/document';
import HttpStatus from 'model/enums/http-status';
import {
  IBankAccountData,
  IInvoice,
  INewTransferToAccountRequest,
  IReturnMessage,
  ITranseferHistorybyRegister,
  Organizations,
  Providers,
  SuportCfgs,
  TranseferHistory,
  TransferRequest,
  TransferToAccountRequest,
} from 'model/financial';
import { IBankData } from 'model/registration-form';
import toastUtils from 'shared/util/toast-utils';

export const FinancialService = () => {
  const getOrganizations = async (): Promise<Organizations[]> => {
    try {
      const result = await FinancialApi.getOrganizations();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getProviders = async (): Promise<Providers[]> => {
    try {
      const result = await FinancialApi.getProviders();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getTaxCalendarYear = async () => {
    try {
      const result: AxiosResponse<SuportCfgs[]> = await FinancialApi.getTaxCalendarYear();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getTitleAgoFrisia = async () => {
    try {
      const result: AxiosResponse<SuportCfgs[]> = await FinancialApi.getTitleAgoFrisia();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getCapitalAccountPDF = async (year: string, register: string) => {
    try {
      const result: AxiosResponse<IDocument> = await FinancialApi.getCapitalAccountPDF(year, register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getTransferHistory = async (register: string) => {
    try {
      const result: AxiosResponse<TranseferHistory[]> = await FinancialApi.getTransferHistory(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const makeTransfer = async (transactionData: TransferRequest) => {
    try {
      const result: AxiosResponse<IReturnMessage> = await FinancialApi.makeTransfer(transactionData);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getSeedBonusReport = async (year: string, register: string) => {
    try {
      const result: AxiosResponse<IDocument> = await FinancialApi.getSeedBonusReport(year, register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getMilkAdvancedPayment = async (year: string, register: string) => {
    try {
      const result: AxiosResponse<IDocument> = await FinancialApi.getMilkAdvancedPayment(year, register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getBankAccounts = async (register: string) => {
    try {
      const result: AxiosResponse<IBankAccountData[]> = await FinancialApi.getBankAccounts(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const transferToAccount = async (transactionData: INewTransferToAccountRequest) => {
    try {
      const result: AxiosResponse<IReturnMessage> = await FinancialApi.transferToAccount(transactionData);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const cancelAccountTransfer = async (register: string, trasnferNumber: string) => {
    try {
      const result: AxiosResponse<string> = await FinancialApi.cancelAccountTransfer(register, trasnferNumber);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast(result.data);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.Message);
      return Promise.reject(response);
    }
  };

  const getTransferHistoryBetweenRegisters = async (register: string) => {
    try {
      const result: AxiosResponse<ITranseferHistorybyRegister[]> = await FinancialApi.getTransferHistoryBetweenRegisters(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getInvoices = async (initDay: string, endDay: string, register: string) => {
    try {
      const result: AxiosResponse<IInvoice[]> = await FinancialApi.getInvoices(initDay, endDay, register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const cancelTransferRegister = async (register: string, trasnferNumber: string) => {
    try {
      const result: AxiosResponse<string> = await FinancialApi.cancelTransferRegister(register, trasnferNumber);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast(result.data);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.salesAuth'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.Message);
      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getOrganizations,
    getProviders,
    getTaxCalendarYear,
    getTitleAgoFrisia,
    getCapitalAccountPDF,
    getTransferHistory,
    makeTransfer,
    getSeedBonusReport,
    getMilkAdvancedPayment,
    getBankAccounts,
    transferToAccount,
    cancelAccountTransfer,
    getTransferHistoryBetweenRegisters,
    getInvoices,
    cancelTransferRegister,
  };
};

export default FinancialService();
