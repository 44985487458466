import { apiCore } from 'api/api';
import { AxiosResponse } from 'axios';
import bcrypt from 'bcryptjs';
import md5 from 'md5';
import { IForestData, ITypeFilter } from 'model/forest';
import { sendLog } from 'shared/util/api-utils';
import StringUtils from 'shared/util/string-utils';

export const forestApi = () => {
  const getDataFilters = (registration: number): Promise<AxiosResponse<ITypeFilter[]>> => {
    sendLog(
      'Consulta carga florestal de produtos',
      `/FlorestalCargaCarga/FlorestalCargaProdutos?cd_pessoa=${registration}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<ITypeFilter[]>(`/FlorestalCargaCarga/FlorestalCargaProdutos?cd_pessoa=${registration}`);
  };

  const getForestData = (
    registration: number,
    harvestCode: string,
    productCode: number,
    plateCode: string
  ): Promise<AxiosResponse<IForestData>> => {
    sendLog(
      'Consulta carga a carga resumo de produtos e placas',
      `/AgricolaCargaCarga/Dados/?cd_pessoa=${registration}&cd_safra=${harvestCode}&cd_produto=${productCode}&cd_placa=${plateCode}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IForestData>(
      `/AgricolaCargaCarga/Dados/?cd_pessoa=${registration}&cd_safra=${harvestCode}&cd_produto=${productCode}&cd_placa=${plateCode}`
    );
  };

  const getForestReport = (registration: number, harvestCode: string, productCode: number, plateCode: string) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/EGR/XXFREGR8582R&CD_PESSOA=${registration}&CD_SAFRA=${harvestCode}&CD_PRODUTO=${productCode}&CD_PLACA=${plateCode}`;
    sendLog('Consulta relatorio carga a carga', path, JSON.stringify([]), 'get');
    const urlConvertCharcode = StringUtils.convertToCharCode(path);
    const md5EncryptedUrl = md5(urlConvertCharcode);
    const bcryptEncrypted = bcrypt.hashSync(md5EncryptedUrl);
    const charCodeUrlReportAuth = StringUtils.convertToCharCode(bcryptEncrypted);

    return `https://servicos.frisia.coop.br/RPT/Report/ReportTemplate?ReportId=${urlConvertCharcode}&ReportAuth=${charCodeUrlReportAuth}`;
  };

  return {
    getDataFilters,
    getForestData,
    getForestReport,
  };
};

export default forestApi();
