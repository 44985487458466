import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  textButton: string;
}

const GenericButtonOragenNoBorder = ({ textButton, ...rest }: IProps) => {
  return <StyledButton {...rest}>{textButton}</StyledButton>;
};

const StyledButton = styled.button`
  height: 45px;
  min-width: 115px;
  color: #d4a518;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  font-size: 14px;
  border: none;
  background-color: inherit;
`;
export default GenericButtonOragenNoBorder;
