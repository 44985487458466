import { ContractData } from 'model/agriculturalSalesStatement';
import { IInspection, IReception, ISummaries, ITypefication } from 'model/slaughter-report';
import { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/white-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/white-plus-round-icon.svg';
import Table2HeadersLines from './Table2HeadersLines';
import TableMultipleHeaders from './TableMultipleHeaders';

interface IProps extends WithTranslation {
  sumItem: ISummaries;
  sumIndex: number;
  receptionList: IReception[];
  inspectionList: IInspection[];
  typefication: ITypefication[];
}

const CollapsibleTatto = ({ sumItem, sumIndex, receptionList, inspectionList, typefication, t }: IProps) => {
  const [isShowGraphic, setIsShowGraphic] = useState<boolean>(false);

  return (
    <div key={`sumIndex-${sumIndex}`}>
      <StyledTatooTitle>
        {isShowGraphic ? (
          <MinusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
        ) : (
          <PlusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
        )}{' '}
        {`${t('slaughter.tatto')}: ${sumItem.NR_TATUAGEM}`}
      </StyledTatooTitle>
      {isShowGraphic && (
        <>
          <StyledTatooTitle>{t('slaughter.reception')}</StyledTatooTitle>
          <TableMultipleHeaders
            isDontHaveMaximumWidth
            arrayOfHeaders={['Quant', 'Peso(Kg)', 'Destino', 'Tipo', 'Causa', 'Peso Médio']}
            arrayOfData={receptionList
              .filter(item => item.NR_TATUAGEM === sumItem.NR_TATUAGEM)
              .map(recepItem => {
                return [
                  recepItem.QT_RECEBIDO_REC.toLocaleString('pt-BR'),
                  recepItem.QT_PESO_REC.toLocaleString('pt-BR'),
                  recepItem.DS_DESTINO_REC,
                  recepItem.DS_TIPO_REC,
                  recepItem.DS_CAUSA_REC,
                  recepItem.QT_PESO_MD_REC.toLocaleString('pt-BR'),
                ];
              })}
          />
          <br />
          <TableMultipleHeaders
            isDontHaveMaximumWidth
            arrayOfHeaders={[t('slaughter.summary'), '']}
            arrayOfData={[
              ['Nro. Suínos:', sumItem.QT_SUINO_REC_TOT.toLocaleString('pt-BR')],
              ['Peso total:', sumItem.QT_PESO_REC_TOT.toLocaleString('pt-BR')],
              ['Peso médio:', sumItem.QT_PESO_MD_REC_TOT.toLocaleString('pt-BR')],
              ['% sequestro:', sumItem.PC_SEQUESTRO_REC_TOT.toLocaleString('pt-BR')],
            ]}
          />
          <StyledTatooTitle>{t('slaughter.inspection')}</StyledTatooTitle>
          <TableMultipleHeaders
            isDontHaveMaximumWidth
            arrayOfHeaders={['Nr.', 'Doença', 'Destino', 'Entrada', 'Saída', 'Diferença', 'Desc.']}
            arrayOfData={inspectionList
              .filter(item => item.NR_TATUAGEM === sumItem.NR_TATUAGEM)
              .map(inspItem => {
                return [
                  inspItem.NR_SEQ_DIF.toLocaleString('pt-BR'),
                  inspItem.DS_DOENCA_DIF,
                  inspItem.DS_DESTINO_DIF,
                  inspItem.QT_PESO_ENTRADA_DIF.toLocaleString('pt-BR'),
                  inspItem.QT_PESO_SAIDA_DIF.toLocaleString('pt-BR'),
                  inspItem.QT_PESO_DIFERENCA_DIF.toLocaleString('pt-BR'),
                  inspItem.DS_CAMPO_INDUSTRIA_DIF,
                ];
              })}
          />
          <br />
          <Table2HeadersLines
            isDontHaveMaximumWidth
            colsplan={2}
            arrayOfHeaders={[
              ['', '', 'Graxaria(KG)', 'Embutidos/Conserva(Kg)'],
              ['Resumo', 'Nr° Suínos', 'Peso Total Entrada', 'Peso Médio S/cab', 'Campo', 'Indústria', 'Campo', 'Indústria'],
            ]}
            arrayOfData={[
              [
                'Nr° Liberados',
                sumItem.QT_SUINO_LIB_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_ENTRADA_LIB_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_MEDIO_LIB_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_COND_CAMPO_LIB_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_COND_IND_LIB_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_CONS_CAMPO_LIB_DIF_TOT?.toLocaleString('pt-BR') ?? 0,
                sumItem?.QT_PESO_CONS_IND_LIB_DIF_TOT?.toLocaleString('pt-BR') ?? 0,
              ],
              [
                'Nr° Embut. Cozido',
                sumItem.QT_SUINO_EMB_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_ENTRADA_EMB_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_MEDIO_EMB_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_COND_CAMPO_EMB_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_COND_IND_EMB_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_CONS_CAMPO_EMB_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_CONS_IND_EMB_DIF_TOT?.toLocaleString('pt-BR') ?? 0,
              ],
              [
                'Nr° Graxaria',
                sumItem.QT_SUINO_GRA_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_ENTRADA_GRA_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_MEDIO_GRA_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_COND_CAMPO_GRA_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_COND_IND_GRA_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_CONS_CAMPO_GRA_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_CONS_IND_GRA_DIF_TOT.toLocaleString('pt-BR'),
              ],
              [
                'TOTAL',
                sumItem.QT_SUINO_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_ENTRADA_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_MEDIO_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_COND_CAMPO_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_COND_IND_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_CONS_CAMPO_DIF_TOT.toLocaleString('pt-BR'),
                sumItem.QT_PESO_CONS_IND_DIF_TOT?.toLocaleString('pt-BR') ?? 0,
              ],
            ]}
          />
          <br />
          <StyledTatooTitle>{`Tipificação`}</StyledTatooTitle>
          <TableMultipleHeaders
            isDontHaveMaximumWidth
            arrayOfHeaders={['Nr.', 'PV', 'Peso', 'Tc', 'Ind peso', 'Ind TC', 'Ind Total NE']}
            arrayOfData={typefication
              .filter(item => item.NR_TATUAGEM === sumItem.NR_TATUAGEM)
              .map(inspItem => {
                return [
                  inspItem.NR_SEQ_TIP.toLocaleString('pt-BR'),
                  inspItem.QT_PESO_VIVO_TIP.toLocaleString('pt-BR'),
                  inspItem.TX_PESO_TIP.toLocaleString('pt-BR'),
                  inspItem.TX_GORDURA_TIP.toLocaleString('pt-BR'),
                  inspItem.PC_PESO_TIP.toLocaleString('pt-BR'),
                  inspItem.PC_GORDURA_TIP.toLocaleString('pt-BR'),
                  inspItem.PC_TOTAL_TIP.toLocaleString('pt-BR'),
                ];
              })}
          />
          <br />
          <TableMultipleHeaders
            isDontHaveMaximumWidth
            arrayOfHeaders={[t('slaughter.summary'), '']}
            arrayOfData={[
              ['Qtd. Suínos:', sumItem.QT_SUINO_TIP_TOT.toLocaleString('pt-BR')],
              ['MÉDIAS - Peso:', sumItem.QT_PESO_MD_TIP_TOT.toLocaleString('pt-BR')],
              ['Gordura:', sumItem.QT_GORDURA_MD_TIP_TOT.toLocaleString('pt-BR')],
              ['%NE:', sumItem.PC_NE_TIP_TOT.toLocaleString('pt-BR')],
              ['Bonif. Peso:', sumItem.PC_PESO_TIP_TOT.toLocaleString('pt-BR')],
              ['Bonif. Toucinho:', sumItem.PC_GORDURA_TIP_TOT.toLocaleString('pt-BR')],
              ['Bonif. Carcaça:', sumItem.PC_BONIF_CARCACA_TIP_TOT.toLocaleString('pt-BR')],
            ]}
          />
        </>
      )}
    </div>
  );
};
const StyledTatooTitle = styled.div`
  margin-top: 25px;
  background: #103879;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  text-align: left;
  padding-left: 20px;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

export default withTranslation()(CollapsibleTatto);
