import styled from 'styled-components/macro';

export default styled.div`
  width: 100%;
  padding: 10px;
  color: #4a4a4a;
  font-size: 13px;
  margin-top: -2px;
  text-align: center;
  position: relative;
  align-items: center;
  align-content: center;
  letter-spacing: 0.54px;
  background-color: #ffffff;
  border: solid 1px #e8ebec;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;
