export enum QuoteOptions {
  SOY = 'soy',
  WHEAT = 'wheat',
  CORN = 'corn',
  MILK = 'milk',
  PIG = 'pig',
  BARLEY = 'barley',
  BEAN = 'bean',
  OAT = 'oat',
  SORGHUM = 'sorghum',
}
