import FeedOrderCard from 'components/FeedOrderCard';
import LinkedCards from 'components/LinkedCards';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import CardNames from 'model/enums/card-names';
import { IHystoryFeedOrder } from 'model/feed-orders';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import feedOrdersService from 'services/cattle/feed-order-service';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const FeedOrder = ({ t }: WithTranslation) => {
  const [myFeedOrders, setMyFeedOrders] = useState<IHystoryFeedOrder[]>();
  const myUrl = window.location.href;

  const userRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getFeedOrders = () => {
    setMyFeedOrders([]);
    feedOrdersService.getMyFeedOrders(userRegistration).then(response => {
      setMyFeedOrders(response);
    });
  };

  useEffect(() => {
    getFeedOrders();
  }, []);

  const cardName = myUrl.includes('suino') ? CardNames.NEW_RATION_ORDER_PORCINE : CardNames.NEW_RATION_ORDER_CATTLE;

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('livestock.feedOrder')} />
      </StyledPageTitleContainer>

      <LinkedCards listCardNames={[cardName]} spacingBetweenCards={37} />

      <StyledPageTitleContainer margin={'25px 0 14px 0'}>
        <MainPageTitle fontSize={16} text={t('livestock.myFeedOrders')} />
      </StyledPageTitleContainer>

      <StyledMyOrdersContainer>
        <LoadingContainerScreen isLoading={!myFeedOrders || myFeedOrders.length < 1}>
          {myFeedOrders?.map(order => (
            <FeedOrderCard
              data={order}
              key={`order-${order.ID_PEDIDO}`}
              feedOrderHistory={getFeedOrders}
              isCardForPig={myUrl.includes('suino')}
            />
          ))}
        </LoadingContainerScreen>
      </StyledMyOrdersContainer>
    </>
  );
};

const StyledMyOrdersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 56.8px;
  row-gap: 30px;
`;

export default withTranslation()(FeedOrder);
