import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { User } from 'model/user';
import { IRootState } from 'reducer';
import { logoutRequest } from 'reducer/authentication/actions';
import { HomeContainer } from './StyledComponents';

export interface HomeProps extends RouteComponentProps<{}> {
  isAuthenticated?: boolean;
  logout: () => void;
  account?: User;
  t: any;
}

export interface HomeState {
  categoryHover?: string;
  isLoaded?: boolean;
}
export class Home extends React.Component<HomeProps, HomeState> {
  constructor(props: Readonly<HomeProps>, context?: any) {
    super(props, context);

    this.state = {};
  }
  componentDidMount() {
    if (this.props.isAuthenticated === true) {
      this.setState({ isLoaded: true });
    }
  }

  componentWillReceiveProps(props: HomeProps) {
    if (!props.isAuthenticated) {
      this.logoutFunction();
    }
    if (!this.state.isLoaded) {
      this.setState({
        isLoaded: true,
      });
    }
  }

  logoutFunction = () => {
    this.props.logout();
    this.props.history.push('/');
  };
  render() {
    const { isLoaded } = this.state;
    if (!isLoaded) {
      return <div />;
    }
    return <HomeContainer>Olá</HomeContainer>;
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  account: authentication.account,
  logout: logoutRequest,
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Home) as React.ComponentType<any>;
