enum FilterCardType {
  MALES = 'MACHOS',
  COWS = 'VACAS',
  HEIFERS = 'NOVILHAS',
  NEWBORNS = 'RECEM NASCIDOS',
  NEW = 'NOVO',
  ALL = 'TODOS',
  SOY = 'SOJA',
  CORN = 'MILHO',
  BARLEY = 'CEVADA',
  FORAGERS = 'FORRAGEIRAS',
  BEAN = 'FEIJÃO',
  OAT = 'AVEIA',
  WHEAT = 'TRIGO',
  WHEATGRASS = 'TRIGUILHO',
  SORGHUM = 'SORGO',
}

export default FilterCardType;
