import MainPageTitle from 'components/MainPageTitle';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import backgroundImageDefault from 'images/frisia/img/large-frisia-building.svg';
import { StyledButton } from 'components/styled-button/StyledButton';
import { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import GenericInputToTable from 'components/GenericInputToTable';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputDate from 'components/InputDate';
import moment from 'moment';

interface IForm {
  memberName: string;
  registrationNumber: string;
  contact: string;
  valueOffer: string;
  payday: string;
}

const adDetails = ({ t }: WithTranslation) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const [selectedDate, setSelectedDate] = useState<string>(moment().format('DD/MM/YYYY'));

  const ScrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const schema = yup.object({
    memberName: yup.string().required(),
    registrationNumber: yup.string().required(),
    contact: yup.string().required(),
    valueOffer: yup.string().required(),
    payday: yup.string().required(),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <StyledContainer>
          <MainPageTitle fontSize={30} text={t('Trator Case II')} />
          <PrincipalCardContainer>
            <img src={backgroundImageDefault} />
            <div>
              <div>Trator Case II</div>
              <div>R$ 220.000,00 Ano 2017</div>
            </div>
            <StyledButton
              onClick={() => ScrollToBottom()}
              color="frisiaPrimary"
              style={{ minWidth: '120px', alignSelf: 'center', whiteSpace: 'nowrap' }}
            >
              <span>{t('COMPRAR')}</span>
            </StyledButton>
          </PrincipalCardContainer>
          <PhotosContainer>
            {Array.from({ length: 5 }).map((item, index) => (
              <img src={backgroundImageDefault} />
            ))}
          </PhotosContainer>
          <InformationContainer>
            <Grid container>
              <Grid item xs={12} md={6} spacing={20}>
                <StyledTitle>{t('classified.moreInformation')}</StyledTitle>
                <StyledField>
                  <Trans i18nKey="classified.moreInformationModel">
                    <span className="text-value">{{ text: 'Case II PU01' }}</span>
                  </Trans>
                </StyledField>
                <StyledField>
                  <Trans i18nKey="classified.brand">
                    <span className="text-value">{{ text: 'case' }}</span>
                  </Trans>
                </StyledField>
                <StyledField>
                  <Trans i18nKey="classified.moreInformationYear">
                    <span className="text-value">{{ text: '2017' }}</span>
                  </Trans>
                </StyledField>
                <StyledField style={{ margin: '30px 30px 30px 0' }}>
                  <Trans i18nKey="classified.moreInformationGeneral">
                    <span className="text-value">
                      {{
                        text: 'Lorem ipsum dolor sit amet, consectetur Cras luctus mi eunibh auctor pretium',
                      }}
                    </span>
                  </Trans>
                </StyledField>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTitle>{t('classified.sendProposal')}</StyledTitle>

                <FormProvider {...methods}>
                  <form
                    onSubmit={methods.handleSubmit(data => {
                      console.log('data', data);
                    })}
                  >
                    <GenericInputToTable
                      inputName="memberName"
                      label={t('classified.memberName')}
                      errorText={methods.formState.errors.memberName?.message}
                    />
                    <GenericInputToTable
                      inputName="registrationNumber"
                      label={t('classified.registrationNumber')}
                      errorText={methods.formState.errors.registrationNumber?.message}
                    />
                    <GenericInputToTable
                      inputName="contact"
                      label={t('classified.contact')}
                      mask="+55 (99) 99999-9999"
                      errorText={methods.formState.errors.contact?.message}
                    />
                    <GenericInputToTable
                      inputName="valueOffer"
                      label={t('classified.valueOffer')}
                      mask={`R$ 999.999,99`}
                      errorText={methods.formState.errors.valueOffer?.message}
                    />
                    <GenericInputToTable
                      inputName="payday"
                      label={t('classified.payday')}
                      errorText={methods.formState.errors.payday?.message}
                    />
                    <StyledDateOptions>
                      <InputDate defaultValue={new Date()} disableWeekends label={t('classified.payday')} getDate={setSelectedDate} />
                    </StyledDateOptions>

                    <StyledButton
                      color="frisiaPrimary"
                      type="submit"
                      style={{ minWidth: '200px', alignSelf: 'center', whiteSpace: 'nowrap' }}
                    >
                      <span ref={bottomRef}>{t('classified.sendProposal')}</span>
                    </StyledButton>
                  </form>
                </FormProvider>
              </Grid>
            </Grid>
          </InformationContainer>
          <div />
        </StyledContainer>
      </StyledPageTitleContainer>
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitle = styled.div`
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 20px;
  text-align: left;
  margin-bottom: 20px;
`;

const InformationContainer = styled.div`
  width: 934px;
  margin-top: 28px;
  margin-bottom: 20px;
`;

const PhotosContainer = styled.div`
  width: '100%';
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  img {
    max-width: 182px;
    opacity: 1;
    margin-right: 5px;
    border-radius: 25px;
  }
`;

const PrincipalCardContainer = styled.div`
  width: 934px;
  height: 336px;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1d1d1d29;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  margin: 5px;
  justify-content: space-around;
  flex-flow: wrap;
  img {
    width: 934px;
    height: 254px;
    object-fit: cover;
  }
  div {
    font-size: 18px;
    letter-spacing: 0px;
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary};
    text-align: left;
  }
`;
const StyledDateOptions = styled.div`
  padding: auto;
  margin-top: 1px;
  margin-bottom: 11px;
  div {
    padding-top: 1px;
    max-width: 100%;
    width: 100%;
    margin: auto;
    justify-content: end;
  }
`;

const StyledAdCardContainer = styled.div`
  width: 344px;
  height: 239px;
  background: var(--surface) 0% 0% no-repeat padding-box;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1d1d1d29;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  margin: 5px;
  justify-content: space-around;
  flex-flow: wrap;
  img {
    width: 344px;
    height: 152px;
    object-fit: cover;
  }
  div:first-child {
    width: 129px;
    height: 22px;
    font-size: 18px;
    letter-spacing: 0px;
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: ${({ theme }) => theme.color.frisiaPrimary};
  }
  div {
    text-align: left;
  }
`;

const StyledField = styled.div`
  max-width: 470px;

  font-family: ${({ theme }) => theme.font.montserratBold};
  color: #404040;
  font-size: 16px;
  text-align: left;

  .text-value {
    font-family: ${({ theme }) => theme.font.montserratRegular};
  }
`;

export default withTranslation()(adDetails);
