import bullApi from 'api/cattle/bull-api';
import { AxiosError, AxiosResponse } from 'axios';
import { IBull } from 'model/bull';

export const bullCatalogService = () => {
  const getListBulls = async () => {
    try {
      const result: AxiosResponse<IBull[]> = await bullApi.getListBulls();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getBull = async (bullId: number) => {
    try {
      const result: AxiosResponse<IBull> = await bullApi.getBull(bullId);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getListBulls,
    getBull,
  };
};

export default bullCatalogService();
