import { ChangeEventHandler, CSSProperties, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';
import InputMask from 'react-input-mask';
interface IProps {
  inputName: string;
  label: string;
  placeholder?: string;
  errorText?: string;
  isKilogram?: boolean;
  isResetValue?: boolean;
  style?: CSSProperties;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  type?: string;
  mask?: string | string[] | RegExp[];
}

const GenericInputToTable = ({
  inputName,
  label,
  placeholder,
  errorText,
  isKilogram,
  isResetValue,
  style,
  mask,
  onChange,
  type,
}: IProps) => {
  const { register } = useFormContext();

  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (isResetValue) {
      console.log('isResetValue: ' + isResetValue);
      setValue('');
    }
  }, [isResetValue]);

  return (
    <StyledContainer style={style}>
      <StyledInnerContainer>
        <StyledLabel htmlFor={`inputId${inputName}`}>{label}</StyledLabel>
        {mask ? (
          <StyledInputMasked
            {...register(inputName)}
            mask={mask}
            placeholder={placeholder}
            type={type ?? 'text'}
            id={`inputId${inputName}`}
            value={value}
            onChange={e => {
              setValue(isKilogram ? StringUtils.maskNumbersAndComma(e.target.value) : e.target.value);
              if (onChange) {
                onChange(e);
              }
            }}
          />
        ) : (
          <StyledInput
            {...register(inputName)}
            placeholder={placeholder}
            type={type ?? 'text'}
            id={`inputId${inputName}`}
            value={value}
            maxLength={255}
            onChange={e => {
              setValue(isKilogram ? StringUtils.maskNumbersAndComma(e.target.value) : e.target.value);
              if (onChange) {
                onChange(e);
              }
            }}
          />
        )}
      </StyledInnerContainer>

      <StyledErrorMessage>{errorText}</StyledErrorMessage>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  height: 70px;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 53px;
  border: 2px solid #4e6ba7;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f1f1f1;
  padding: 5px 10px 0 10px;
`;

const StyledLabel = styled.label`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
`;

const StyledInputMasked = styled(InputMask)`
  height: 100%;
  border: none;
  background-color: #f1f1f1;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #656565;
  font-size: 16px;
  padding: 0 0 6px 0;

  ::placeholder {
    font-size: 12px;
    opacity: 0.5;
  }
`;

const StyledInput = styled.input`
  height: 100%;
  border: none;
  background-color: #f1f1f1;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #656565;
  font-size: 16px;
  padding: 0 0 6px 0;

  ::placeholder {
    font-size: 12px;
    opacity: 0.5;
  }
`;

export default GenericInputToTable;
