import { apiCore } from 'api/api';
import { AxiosResponse } from 'axios';
import {
  IBonification,
  ICollectPoint,
  IDateOption,
  IEconomy,
  IGeneralParams,
  IInspection,
  IMedians,
  IReception,
  ISlaughterReportData,
  ISlaughterReportFilterType,
  ISlaughterResult,
  ISummaries,
  ITypefication,
} from 'model/slaughter-report';
import { sendLog } from 'shared/util/api-utils';

export const slaughterReportApi = () => {
  const getDataFilters = (registration: number): Promise<AxiosResponse<ISlaughterReportFilterType[]>> => {
    sendLog(
      'Consultando filtros do relatorio de abate',
      `/PecuariaSuino/Param_Rel_Abate/?cd_pessoa=${registration}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ISlaughterReportFilterType[]>(`/PecuariaSuino/Param_Rel_Abate/?cd_pessoa=${registration}`);
  };

  const getCollectPoints = (registration: number): Promise<AxiosResponse<ICollectPoint[]>> => {
    sendLog('Consultando pontos de coleta', `/PecuariaSuino/GetPontosColeta?CD_PESSOA=${registration}`, JSON.stringify({}), 'get');
    return apiCore.get<ICollectPoint[]>(`/PecuariaSuino/GetPontosColeta?CD_PESSOA=${registration}`);
  };

  const getDataReport = (
    registration: number,
    collectionPoint: number,
    slaughterDate: string,
    tattoo: number
  ): Promise<AxiosResponse<ISlaughterReportData>> => {
    sendLog(
      'Consultando relatório de abate',
      `/PecuariaSuino/Relatorio_AbateV4/?cd_pessoa=${registration}&nr_ponto_coleta=${collectionPoint}&dt_abate=${slaughterDate}&nr_tatuagem=${tattoo}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ISlaughterReportData>(
      `/PecuariaSuino/Relatorio_AbateV4/?cd_pessoa=${registration}&nr_ponto_coleta=${collectionPoint}&dt_abate=${slaughterDate}&nr_tatuagem=${tattoo}`
    );
  };

  const getDatesOptions = (registration: number, collectionPoint: string): Promise<AxiosResponse<IDateOption[]>> => {
    sendLog(
      'Consultando datas de abate',
      `/PecuariaSuino/GetDatasDeAbate?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IDateOption[]>(`/PecuariaSuino/GetDatasDeAbate?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}`);
  };

  const getGeneralParams = (
    registration: number,
    collectionPoint: string,
    initialDate: string,
    finalDate: string
  ): Promise<AxiosResponse<IGeneralParams[]>> => {
    sendLog(
      'Consultando parametros alegra',
      `/PecuariaSuino/GetParametrosGeraisAlegra?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IGeneralParams[]>(
      `/PecuariaSuino/GetParametrosGeraisAlegra?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`
    );
  };

  const getSlaughterResult = (
    registration: number,
    collectionPoint: string,
    initialDate: string,
    finalDate: string
  ): Promise<AxiosResponse<ISlaughterResult[]>> => {
    sendLog(
      'Consultando resultados de abate suínos',
      `/PecuariaSuino/GetResultadosAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ISlaughterResult[]>(
      `/PecuariaSuino/GetResultadosAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`
    );
  };

  const getBonification = (
    registration: number,
    collectionPoint: string,
    initialDate: string,
    finalDate: string
  ): Promise<AxiosResponse<IBonification[]>> => {
    sendLog(
      'Consultando bonificações abate suínos',
      `/PecuariaSuino/GetBonificacoesAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IBonification[]>(
      `/PecuariaSuino/GetBonificacoesAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`
    );
  };

  const getEconomy = (
    registration: number,
    collectionPoint: string,
    initialDate: string,
    finalDate: string
  ): Promise<AxiosResponse<IEconomy[]>> => {
    sendLog(
      'Consultando economico de abate suínos',
      `/PecuariaSuino/GetEconomicoAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IEconomy[]>(
      `/PecuariaSuino/GetEconomicoAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`
    );
  };

  const getReception = (
    registration: number,
    collectionPoint: string,
    initialDate: string,
    finalDate: string
  ): Promise<AxiosResponse<IReception[]>> => {
    sendLog(
      'Consultando recepção Abate Suínos',
      `/PecuariaSuino/GetRecepcaoAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IReception[]>(
      `/PecuariaSuino/GetRecepcaoAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`
    );
  };

  const getInspection = (
    registration: number,
    collectionPoint: string,
    initialDate: string,
    finalDate: string
  ): Promise<AxiosResponse<IInspection[]>> => {
    sendLog(
      'Consultando Inspecao DIF Abate suínos',
      `/PecuariaSuino/GetInspecaoDIFAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IInspection[]>(
      `/PecuariaSuino/GetInspecaoDIFAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`
    );
  };

  const getSummaries = (
    registration: number,
    collectionPoint: string,
    initialDate: string,
    finalDate: string
  ): Promise<AxiosResponse<ISummaries[]>> => {
    sendLog(
      'Consultando informação resumida de abate de suinos',
      `/PecuariaSuino/GetResumosAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ISummaries[]>(
      `/PecuariaSuino/GetResumosAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`
    );
  };

  const getTypefication = (
    registration: number,
    collectionPoint: string,
    initialDate: string,
    finalDate: string
  ): Promise<AxiosResponse<ITypefication[]>> => {
    sendLog(
      'Consultando tipificação de abate suínos',
      `/PecuariaSuino/GetTipificacaoAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ITypefication[]>(
      `/PecuariaSuino/GetTipificacaoAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`
    );
  };

  const getMedians = (
    registration: number,
    collectionPoint: string,
    initialDate: string,
    finalDate: string
  ): Promise<AxiosResponse<IMedians[]>> => {
    sendLog(
      'Consultando médias de abate suinos',
      `/PecuariaSuino/GetMediasAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IMedians[]>(
      `/PecuariaSuino/GetMediasAbateSuinos?CD_PESSOA=${registration}&FULL_PONTO_COLETA=${collectionPoint}&DT_INICIAL=${initialDate}&DT_FINAL=${finalDate}`
    );
  };

  return {
    getDataFilters,
    getCollectPoints,
    getDataReport,
    getDatesOptions,
    getGeneralParams,
    getSlaughterResult,
    getBonification,
    getEconomy,
    getReception,
    getInspection,
    getSummaries,
    getTypefication,
    getMedians,
  };
};

export default slaughterReportApi();
