import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as GraphicIconSvg } from '../images/frisia/icons/white-graphic-line-icon.svg';
import { ReactComponent as UpIconSvg } from '../images/frisia/icons/yellow-up-arrow-icon.svg';
import { ReactComponent as DownIconSvg } from '../images/frisia/icons/red-down-arrow-icon.svg';
import { useEffect, useState } from 'react';
import cepeaApi from 'api/cepea-api';
import ReactHtmlParser from 'react-html-parser';
import currencyApi from 'api/currency-api';
import { QuoteOptions } from 'model/enums/quote-options';
import frisiaQuotationApi from 'api/frisia-cotation-api';
import InputDate from './InputDate';
import moment from 'moment';
import LoadingContainerScreen from './loading-container-screen/loading-container-screen';
import { CurrencyProps, LocationType } from 'model/quotation-info';

const QuoteDashboardTable = ({ t }: WithTranslation) => {
  const [html, setHtml] = useState<string>('');
  const [chosenQuotation, setChosenQuotation] = useState<QuoteOptions>(QuoteOptions.SOY);
  const [usd, setUsd] = useState<CurrencyProps>({});
  const [ibov, setIbov] = useState<CurrencyProps>({});
  const [eur, setEur] = useState<CurrencyProps>({});
  const [apiFrisiaInfo, setapiFrisiaInfo] = useState<LocationType[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(moment().format('DD/MM/YYYY'));
  const [onLoading, setOnLoading] = useState(false);

  useEffect(() => {
    async function callAsync() {
      await cleanHtmlString();
      await getCurrencies();
      await getFrisiaQuotation();
    }
    callAsync();
  }, [chosenQuotation]);

  useEffect(() => {
    setOnLoading(true);
    async function callAsync() {
      await getFrisiaQuotation();
    }
    callAsync();
  }, [selectedDate]);

  const cleanHtmlString = async () => {
    if (
      chosenQuotation === QuoteOptions.SORGHUM ||
      chosenQuotation === QuoteOptions.BARLEY ||
      chosenQuotation === QuoteOptions.BEAN ||
      chosenQuotation === QuoteOptions.OAT
    ) {
      return setHtml('');
    }
    var cepeaResult = await cepeaApi.getWidget(chosenQuotation);
    var removedScript = cepeaResult.data.replace("document.write('", '').slice(0, -2);
    var styleEnd = removedScript.indexOf('</style>', 100);
    setHtml(removedScript.substring(styleEnd, cepeaResult.data.length - 1).replace('</style>', ''));
  };

  const processingCurrency = item => {
    const pastValue = item.exchangesRate[0].locations[0].historicos.slice(-2)[0].valor;
    const value = item.exchangesRate[0].locations[0].valor;
    return {
      bid: value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).toString(),
      pctChange: ((value * 100) / pastValue - 100).toFixed(2).toString(),
    };
  };

  const processingIBOV = item => {
    const value = item.exchangesRate[0].locations[0].valor;
    const percentage = item.exchangesRate[0].locations[0].indicador;

    var numero = value.toFixed(2).split('.');
    numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.');
    return {
      bid: numero.join(',').toLocaleString('pt-BR'),
      pctChange: percentage,
    };
  };

  const getFrisiaQuotation = async () => {
    setapiFrisiaInfo([]);
    const frisiaResult = await frisiaQuotationApi.getQuotation(selectedDate);

    setOnLoading(false);

    if (frisiaResult.data.currencies.length > 0) {
      frisiaResult.data.currencies.map(item => {
        if (item.name === 'COTAÇÃO DÓLAR') {
          setUsd(processingCurrency(item));
        }
        if (item.name === 'IBOVESPA') {
          setIbov(processingIBOV(item));
        }
      });
    } else {
      return setUsd({});
    }

    const commoditiesNames = {
      [QuoteOptions.SOY]: 'SOJA',
      [QuoteOptions.WHEAT]: 'TRIGO',
      [QuoteOptions.CORN]: 'MILHO',
      [QuoteOptions.MILK]: 'LEITE',
      [QuoteOptions.PIG]: 'PORCO',
      [QuoteOptions.BARLEY]: 'CEVADA',
      [QuoteOptions.BEAN]: 'FEIJÃO',
      [QuoteOptions.OAT]: 'AVEIA',
      [QuoteOptions.SORGHUM]: 'SORGO',
    };

    var newArray: any[] = [];

    frisiaResult.data.commodities.map(item => {
      if (item.name === commoditiesNames[chosenQuotation]) {
        newArray.push(...item.agriculturalQuotes);
      }
    });

    newArray.map(item => {
      if (item.title === 'CHICAGO') {
        setapiFrisiaInfo(item.locations);
      }
    });
  };

  const getCurrencies = async () => {
    var currencyResult = await currencyApi.getCurrencies();
    setEur(currencyResult.data['EURBRL']);
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <GraphicIconSvg style={{ margin: '0 9px 0 23px' }} />
        {t('table.quotes')}
      </StyledHeader>
      <StyledDateOptions>
        <InputDate defaultValue={new Date()} disableWeekends label={t('dashboard.filterDate')} getDate={setSelectedDate} />
      </StyledDateOptions>
      <LoadingContainerScreen isLoading={onLoading}>
        {usd.bid ? (
          <>
            <Subtitle>
              1 dólar americano igual a <div>{usd.bid}</div>
            </Subtitle>
            <StyledTable>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    {parseFloat(usd.pctChange ?? '0') > 0 ? <UpIconSvg /> : <DownIconSvg />}
                    {usd.pctChange}%
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td>Euro</td>
                  <td>
                    R${' '}
                    {parseFloat(eur.bid ?? '0')
                      .toFixed(2)
                      .toString()
                      .replace('.', ',')}
                  </td>
                  <td>
                    {parseFloat(eur.pctChange ?? '0') > 0 ? <UpIconSvg /> : <DownIconSvg />}
                    {eur.pctChange}%
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td>Ibovespa</td>
                  <td>{ibov.bid}</td>
                  <td>
                    {parseFloat(ibov.pctChange?.replace(',', '.') ?? '0') > 0 ? <UpIconSvg /> : <DownIconSvg />}
                    {ibov.pctChange}
                  </td>
                </tr>
              </tbody>
            </StyledTable>
          </>
        ) : (
          <WarningStyled>Cotação de moedas estrangeiras nesta data não disponível</WarningStyled>
        )}
      </LoadingContainerScreen>
      <div style={{ justifyContent: 'space-evenly', display: 'flex', margin: '20px', flexFlow: 'wrap', height: '73px' }}>
        <StyledQuoteButton equalQuotation={chosenQuotation === QuoteOptions.SOY} onClick={() => setChosenQuotation(QuoteOptions.SOY)}>
          {t('quotes.soy')}
        </StyledQuoteButton>
        <StyledQuoteButton equalQuotation={chosenQuotation === QuoteOptions.WHEAT} onClick={() => setChosenQuotation(QuoteOptions.WHEAT)}>
          {t('quotes.wheat')}
        </StyledQuoteButton>
        <StyledQuoteButton equalQuotation={chosenQuotation === QuoteOptions.CORN} onClick={() => setChosenQuotation(QuoteOptions.CORN)}>
          {t('quotes.corn')}
        </StyledQuoteButton>
        <StyledQuoteButton equalQuotation={chosenQuotation === QuoteOptions.MILK} onClick={() => setChosenQuotation(QuoteOptions.MILK)}>
          {t('quotes.milk')}
        </StyledQuoteButton>
        <StyledQuoteButton equalQuotation={chosenQuotation === QuoteOptions.PIG} onClick={() => setChosenQuotation(QuoteOptions.PIG)}>
          {t('quotes.pig')}
        </StyledQuoteButton>
        <StyledQuoteButton equalQuotation={chosenQuotation === QuoteOptions.BARLEY} onClick={() => setChosenQuotation(QuoteOptions.BARLEY)}>
          {t('quotes.barley')}
        </StyledQuoteButton>
        <StyledQuoteButton equalQuotation={chosenQuotation === QuoteOptions.BEAN} onClick={() => setChosenQuotation(QuoteOptions.BEAN)}>
          {t('quotes.bean')}
        </StyledQuoteButton>
        <StyledQuoteButton equalQuotation={chosenQuotation === QuoteOptions.OAT} onClick={() => setChosenQuotation(QuoteOptions.OAT)}>
          {t('quotes.oat')}
        </StyledQuoteButton>
        <StyledQuoteButton
          equalQuotation={chosenQuotation === QuoteOptions.SORGHUM}
          onClick={() => setChosenQuotation(QuoteOptions.SORGHUM)}
        >
          {t('quotes.sorghum')}
        </StyledQuoteButton>
      </div>
      {chosenQuotation === QuoteOptions.MILK && <span style={{ margin: '20px', color: 'red' }}>{t('quotes.warning')}</span>}

      <FrisiaTable>
        <table>
          <thead>
            {apiFrisiaInfo.length > 0 && (
              <tr>
                <th>Local</th>
                <th>Embalagem</th>
                <th>Valor</th>
                <th>Tipo</th>
              </tr>
            )}
          </thead>
          <tbody>
            {apiFrisiaInfo.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.local}</td>
                  <td>{item.embalagem}</td>
                  <td>
                    {item.moeda} {item.valor}
                  </td>
                  <td>{item.titulo}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {apiFrisiaInfo.length > 0 && (
              <tr>
                <td>Fonte: Frísia</td>
              </tr>
            )}
          </tfoot>
        </table>
      </FrisiaTable>
      <CepeaTable>{html !== '' && ReactHtmlParser(html)}</CepeaTable>
    </StyledContainer>
  );
};

export const CepeaTable = styled.div`
  table {
    width: 100%;
    & > :nth-child(1) {
      display: none;
    }
    td,
    th {
      padding: 8px;
      text-align: left;
      height: 48px;
      display: flex;
      align-items: center;
    }
    th {
      background-color: ${({ theme }) => theme.color.frisiaPrimary};
      font-family: ${({ theme }) => theme.font.montserratSemiBold};
      color: #ffffff;
    }
    tr:hover {
      background-color: #ddd;
    }
    td {
      display: flex;
      justify-content: space-around;
      flex-basis: 100%;
    }
    tr {
      padding: 0px 10px;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
    thead > tr:last-child {
      background-color: ${({ theme }) => theme.color.frisiaPrimary};
    }
    tbody > tr > td:nth-child(2) {
      flex-basis: 200%;
    }
    tfoot > tr > td {
      justify-content: left;
      margin-bottom: 15px;
    }
  }
`;

export const FrisiaTable = styled.div`
  table {
    width: 100%;
    td,
    th {
      padding: 8px;
      text-align: left;
      height: 48px;
      display: flex;
      align-items: center;
      flex-basis: 100%;
      max-width: 112px;
    }
    th {
      background-color: ${({ theme }) => theme.color.frisiaPrimary};
      font-family: ${({ theme }) => theme.font.montserratSemiBold};
      color: #ffffff;
    }
    tr:hover {
      background-color: #ddd;
    }
    tr {
      width: 100%;
      padding: 0px 10px;
      display: flex;
      justify-content: space-around;
      flex: 1 1 0px;
    }
    thead > tr:last-child {
      background-color: ${({ theme }) => theme.color.frisiaPrimary};
    }
    tbody > tr > td:last-child {
      max-height: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    tfoot > tr > td {
      max-width: 100%;
    }
  }
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 17px;
  td,
  th {
    padding: 8px;
    text-align: left;
    padding-left: 23px;
    height: 45px;
  }
  th {
    background-color: ${({ theme }) => theme.color.frisiaPrimary};
    font-family: ${({ theme }) => theme.font.montserratSemiBold};
    color: #ffffff;
  }
  tr:hover {
    background-color: #ddd;
  }
`;

export const Subtitle = styled.div`
  margin: 9px 9px 9px 19px;
  margin-bottom: 29px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #29579c;
  width: 174px;
  height: 18px;
  color: var(--600-234b90);
  text-align: left;
  letter-spacing: 0px;
  color: #234b90;
  opacity: 1;
  div {
    width: 171px;
    height: 61px;
    text-align: left;
    font-size: 43px;
    font-family: ${({ theme }) => theme.font.montserratSemiBold};
    letter-spacing: 0.01px;
    color: #234b90;
    text-transform: uppercase;
    opacity: 1;
  }
`;

export const WarningStyled = styled.div`
  text-align: center;
  height: 61px;
  padding-top: 10px;
  font-size: 17px;
  font-family: ${({ theme }) => theme.font.montserratSemiBold};
  letter-spacing: 0.01px;
  color: #234b90;
  text-transform: uppercase;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledContainer = styled.div`
  text-align: left;
  min-width: 448px;
  width: 501px;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
`;

const StyledDateOptions = styled.div`
  padding: auto;
  margin-top: 15px;
  margin-bottom: -11px;
  div {
    padding-top: 1px;
    max-width: 316px;
    margin: auto;
  }
`;

interface StyledButtonProps {
  equalQuotation?: boolean;
}

const StyledQuoteButton = styled.button<StyledButtonProps>`
  width: 75px;
  margin: 5px;
  height: 31px;
  border: 1px solid #2a529961;
  border-radius: 21px;
  opacity: 1;
  font-size: 14px;
  letter-spacing: 0px;
  background: ${props => {
    if (props.equalQuotation) {
      return '#2a5299';
    } else {
      return '#none';
    }
  }};
  color: ${props => {
    if (props.equalQuotation) {
      return 'white';
    } else {
      return '#2a5299';
    }
  }};
  :hover {
    color: white;
    background: #2a5299;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  background-color: ${({ theme }) => theme.color.frisiaPrimary};
  border-radius: 21px 21px 0 0;
  font-family: ${({ theme }) => theme.font.montserratSemiBold};
  color: #ffffff;
`;

export default withTranslation()(QuoteDashboardTable);
