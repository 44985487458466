import styled from 'styled-components';

export const StyledRegistrationFormBackground = styled.div`
  width: 100%;
  min-width: 645px;
  margin-top: 8px;

  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 55px;
  padding: 30px;
`;

export const StyledRegistrationFormSubtitle = styled.span`
  display: block;
  margin-top: 25px;

  font-family: ${({ theme }) => theme.font.montserratSemiBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 23px;
  text-align: left;
`;

export const StyledPropertySubtitle = styled.span`
  display: block;
  margin-top: 25px;

  font-family: ${({ theme }) => theme.font.montserratSemiBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 15px;
  text-align: left;
`;

export const StyledRegistrationFormMainTitleContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-bottom: 20px;
`;

export const StyledRegistrationFormTitle = styled.span`
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: ${({ theme }) => theme.color.frisiaPrimary};
`;
