import CollapsibleCostTable from 'components/CollapsibleCostTable';
import GenericDropdown from 'components/GenericDropdown';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import { IGroupsCost, IHarvestCost, IInfoCost } from 'model/agricultural-costs';
import { useEffect, useState } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import costsService from 'services/agricultural/costs-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { ReactComponent as ExclamationIcon } from '../../images/frisia/icons/gray-Icon-exclamation-circle.svg';

const CostOptions = ({ t }: WithTranslation) => {
  const [chosenYear, setChosenYear] = useState<IHarvestCost>();
  const [harvestOptions, setHarvestOptions] = useState<IHarvestCost[]>([]);
  const [costsInfo, setCostsInfo] = useState<IInfoCost>();

  const [groupsList, setGroupsList] = useState<IGroupsCost[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  useEffect(() => {
    costsService.getCrop().then(setHarvestOptions);
  }, []);

  useEffect(() => {
    if (chosenYear) {
      setIsLoading(true);
      costsService.getInfo(getUserRegistration, chosenYear.NM_SAFRA.replace('/', '')).then(response => {
        setIsLoading(false);
        setCostsInfo(response);
      });
      costsService.getGroups(getUserRegistration, chosenYear.NM_SAFRA.replace('/', '')).then(setGroupsList);
    }
  }, [chosenYear]);

  const headerTableList = [
    '  ',
    t('cost.culture'),
    t('cost.harvestType'),
    t('cost.purpose'),
    t('cost.property'),
    t('cost.city'),
    t('cost.area'),
    t('cost.MO'),
    t('cost.inputs'),
    t('cost.total'),
    t('cost.costOption'),
  ];

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={20} text={t('agricultural.costOptions')} />
      </StyledPageTitleContainer>

      <StyledFiltersContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('agricultural.harvest')}
            placeholder={t('agricultural.selectHarvest')}
            listShowDropdown={harvestOptions}
            getSelectedValue={value => setChosenYear(value)}
            keyToShowInDropdown="NM_SAFRA"
          />
        </StyledFilterContainer>
      </StyledFiltersContainer>

      <LoadingContainerScreen isLoading={isLoading}>
        <StyledContainer>
          {costsInfo && (
            <StyledCard>
              <StyledTitle>
                <div>{t('cost.workingCapital')}</div>
              </StyledTitle>
              <div style={{ height: '2px', width: '100%', backgroundColor: '#D7CC27', margin: '5px auto' }} />
              <StyledTable>
                <tbody>
                  <tr>
                    <th>{t('cost.title')}</th>
                  </tr>
                  <tr>
                    <th>{t('cost.capitalAccount')} </th>
                  </tr>
                  <tr>
                    <td>{t('cost.workingCapital')}:</td>
                    <td>R$ {costsInfo.VL_TOTAL.toLocaleString('pt-br')}</td>
                  </tr>
                  <tr>
                    <td>{t('cost.balance')}:</td>
                    <td>R$ {costsInfo.VL_SALDO.toLocaleString('pt-br')}</td>
                  </tr>
                </tbody>
              </StyledTable>
            </StyledCard>
          )}
          <WarningCard>
            <ExclamationIcon />
            <p>
              <Trans i18nKey="cost.warningMessage">
                <span className="text-blue">{{ value: t('cost.phoneNumber') }}</span>
              </Trans>
            </p>
          </WarningCard>
        </StyledContainer>
        {costsInfo && (
          <StyledContainerTable>
            <StyledTitle>{t('cost.budgetOfInputs')}</StyledTitle>
            <table style={{ width: '100%', marginTop: '15px' }}>
              <thead style={{ width: '100%' }}>
                <tr style={{ width: '100%', display: 'flex' }}>
                  {headerTableList.map((item, index) => (
                    <th key={`th-${index}`}>{item}</th>
                  ))}
                </tr>
              </thead>
            </table>
            {groupsList.map((item, index) => (
              <CollapsibleCostTable key={`costTable-${index}`} groupCost={item} />
            ))}
          </StyledContainerTable>
        )}
      </LoadingContainerScreen>
    </>
  );
};

const StyledContainerTable = styled.div`
  margin-top: 20px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 26px;
  opacity: 1;
  padding: 37px 19px;
  div {
    text-align: left;
    font-family: ${({ theme }) => theme.font.montserratBold};
    letter-spacing: 0px;
    opacity: 1;
  }
  th {
    width: 8.53%;
    text-align: left;
    font-size: 14px;
  }
  tr {
    display: flex;
    justify-content: space-between;
  }
  @media screen and (max-width: 1550px) {
    th {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 1250px) {
    th {
      font-size: 10px;
    }
    td {
      font-size: 9px;
      word-break: break-all;
    }
  }
  @media screen and (max-width: 950px) {
    th {
      font-size: 10px;
      word-break: break-all;
    }
    td {
      font-size: 9px;
      word-break: break-all;
    }
  }
`;

const StyledTable = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  font-size: 16px;
  td,
  th {
    padding: 2px;
    text-align: right;
  }
  td:first-child,
  th:first-child {
    text-align: left;
    color: #032762;
  }
  tr:hover {
    background-color: #ddd;
  }
  svg {
    vertical-align: initial;
  }
`;

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
`;

const StyledCard = styled.div`
  top: 302px;
  left: 379px;
  min-width: 503px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  margin: 5px;
  padding: 30px;
`;

export const WarningCard = styled.div`
  top: 302px;
  left: 379px;
  width: 350px;
  height: 130px;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  margin: 5px;
  padding: 52px 44px;
  text-align: left;
  line-height: normal;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  letter-spacing: 0px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 15px;
    width: 402px;
    min-height: 78px;
  }
  .text-blue {
    color: #2a5299;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
`;

const StyledTitle = styled.div`
  text-align: left;
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.montserratBold};
  letter-spacing: 0.01px;
  color: #032762;
  opacity: 1;
  display: flex;
  justify-content: space-between;
`;

export default withTranslation()(CostOptions);
