import styled from 'styled-components';

interface IStyledTableProps {
  isInvertLineColor?: boolean;
  isDontHaveMaximumWidth?: boolean;
}

export const StyledTable = styled.table<IStyledTableProps>`
  min-width: 375px;
  width: 100%;
  max-width: ${({ isDontHaveMaximumWidth }) => (isDontHaveMaximumWidth ? '100%' : '570px')};
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 14px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  background-color: ${({ isInvertLineColor }) => (isInvertLineColor ? 'white' : '#f1f1f1')};

  tr:nth-child(2n) {
    background-color: ${({ isInvertLineColor }) => (isInvertLineColor ? '#f1f1f1' : 'white')};
  }
`;

export const StyledTableRow = styled.tr`
  height: 40px;
  color: #4e6ba7;

  td {
    text-align: left;
    padding-left: 19px;
  }

  td:nth-child(2) {
    text-align: center;
    padding-left: 0;
  }

  td:nth-child(1) {
    color: #404040;
    font-family: ${({ theme }) => theme.font.montserratRegular};
    font-size: 16px;
  }

  td:nth-last-child(1) {
    text-align: right;
    padding-right: 20px;
    padding-left: 2px;
  }

  &.last-line {
    td:nth-child(1) {
      border-radius: 0 0 0 16px;
    }

    td:nth-last-child(1) {
      border-radius: 0 0 16px 0;
    }
  }
`;

export const StyledBreakTableRow = styled.tr`
  border: 1px black solid;
  height: 0px;
  td {
  }
`;
