import { AxiosResponse } from 'axios';
import bcrypt from 'bcryptjs';
import md5 from 'md5';
import ICollectPoint from 'model/collectPoint';
import { IMilkMap } from 'model/milkMap';
import { sendLog } from 'shared/util/api-utils';
import StringUtils from 'shared/util/string-utils';
import { apiCore } from '../api';

export const milkMapApi = () => {
  const getCollectPoint = (registration: number): Promise<AxiosResponse<ICollectPoint[]>> => {
    sendLog('Consultando pontos de coleta do leite', `PecuariaLeite/Ponto_Coletas/?cd_pessoa=${registration}`, JSON.stringify({}), 'get');
    return apiCore.get<ICollectPoint[]>(`/PecuariaLeite/Ponto_Coletas/?cd_pessoa=${registration}`);
  };

  const getMilkMap = (collectionPointFilter: number, filterDate: string, registration: number) => {
    sendLog(
      'Consultando mapa do Leite',
      `/PecuariaLeite/Mapa_Leite/?cd_pessoa=${registration}&nr_ponto_coleta=${collectionPointFilter}&dt_mes_ano=${filterDate}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IMilkMap>(
      `/PecuariaLeite/Mapa_Leite/?cd_pessoa=${registration}&nr_ponto_coleta=${collectionPointFilter}&dt_mes_ano=${filterDate}`
    );
  };

  const getMilkMapReport = (collectionPointFilter: number, filterDate: string, registration: number) => {
    const path = `http://reports/ReportServer?/SIF/LEI/LEI0101&cd_empresa_pool=2&cd_pessoa=${registration}&dt_mes_ano=${filterDate}&nr_ponto_coleta=${collectionPointFilter}`;

    sendLog('Consultando relatorio mapa do Leite', path, JSON.stringify({}), 'get');

    const urlConvertCharcode = StringUtils.convertToCharCode(path);
    const md5EncryptedUrl = md5(urlConvertCharcode);
    const bcryptEncrypted = bcrypt.hashSync(md5EncryptedUrl);
    const charCodeUrlReportAuth = StringUtils.convertToCharCode(bcryptEncrypted);

    return `https://servicos.frisia.coop.br/RPT/Report/ReportTemplate?ReportId=${urlConvertCharcode}&ReportAuth=${charCodeUrlReportAuth}`;
  };

  return {
    getCollectPoint,
    getMilkMap,
    getMilkMapReport,
  };
};

export default milkMapApi();
