import { ITypeTable } from 'model/type-table';
import styled from 'styled-components';
import { StyledTable } from 'styled-components/StyledForTables';

interface IProps extends ITypeTable {
  footer?: JSX.Element;
  dataColor?: string;
  isAlignLastColumnToLeft?: boolean;
}

interface IStyledProps {
  dataColor?: string;
  moreThanTwoColumns?: boolean;
  isAlignLastColumnToLeft?: boolean;
}

const GenericTableBlueHeader = ({
  headerItems,
  tableItems,
  isInvertLineColor,
  isDontHaveMaximumWidth,
  isAlignLastColumnToLeft,
  footer,
  dataColor,
  style,
}: IProps) => {
  const getValues = tableItems.map(row => Object.values(row));

  return (
    <StyledTable isInvertLineColor={isInvertLineColor} isDontHaveMaximumWidth={isDontHaveMaximumWidth} style={style}>
      {headerItems != null && (
        <thead>
          <StyledTableRowHeader moreThanTwoColumns={getValues[0]?.length > 2}>
            {headerItems.map((headerItem, index) => (
              <th key={`header-item-${index}`}>{headerItem}</th>
            ))}
          </StyledTableRowHeader>
        </thead>
      )}

      <tbody>
        {getValues.map((tableRow, indexRow) => (
          <StyledTableRow
            key={`row-${indexRow}`}
            dataColor={dataColor}
            moreThanTwoColumns={getValues[0]?.length > 2}
            isAlignLastColumnToLeft={isAlignLastColumnToLeft}
          >
            {tableRow.map((tableItem, indexItem) => (
              <td key={`item-${indexItem}`}>{tableItem}</td>
            ))}
          </StyledTableRow>
        ))}
      </tbody>
      {footer && (
        <StyledTableFooter>
          <StyleTableRowFooter>{footer}</StyleTableRowFooter>
        </StyledTableFooter>
      )}
    </StyledTable>
  );
};

const StyledTableRowHeader = styled.tr<IStyledProps>`
  height: 50px;
  font-family: ${({ theme }) => theme.font.montserratSemiBold};
  background-color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 13px;
  color: white;

  th {
    text-align: center;
  }

  th:nth-child(1) {
    text-align: left;
    padding-left: 20px;
    border-radius: 16px 0 0 0;
  }

  th:nth-child(2) {
    text-align: ${({ moreThanTwoColumns }) => (moreThanTwoColumns ? 'center' : 'left')};
  }

  th:nth-last-child(1) {
    padding-right: 20px;
    border-radius: 0 16px 0 0;
  }
`;

const StyledTableRow = styled.tr<IStyledProps>`
  height: 40px;
  color: ${({ theme, dataColor }) => dataColor ?? theme.color.frisiaPrimary};
  font-family: ${({ theme }) => theme.font.montserratMedium};
  font-size: 14px;

  td {
    /* text-align: center; */
    text-align: ${({ isAlignLastColumnToLeft }) => (isAlignLastColumnToLeft ? 'left' : 'center')};

    max-width: 500px;
    padding-left: ${({ isAlignLastColumnToLeft }) => (isAlignLastColumnToLeft ? '20px' : '0')};
    padding-top: 4px;
    word-break: break-word;
  }

  td:nth-child(1) {
    text-align: left;
    padding-left: 20px;
  }

  td:nth-child(2) {
    text-align: ${({ moreThanTwoColumns }) => (moreThanTwoColumns ? 'center' : 'left')};
  }

  td:nth-last-child(1) {
    padding-right: 20px;
  }
`;

const StyledTableFooter = styled.tfoot`
  background-color: white;
`;

const StyleTableRowFooter = styled.tr`
  height: 70px;

  td:nth-child(1) {
    border-radius: 0 0 16px 16px;
  }

  td:nth-last-child(1) {
    border-radius: 0 0 16px 16px;
  }
`;

export default GenericTableBlueHeader;
