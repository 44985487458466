import { AxiosError, AxiosResponse } from 'axios';
import { AccountType } from 'model/account';
import searchAccountApi from 'api/seach-account-api';
import { AccountPages } from 'model/account-allow-pages';

export const SearchAccountService = () => {
  const byName = async (name: string) => {
    try {
      const result: AxiosResponse<AccountType[]> = await searchAccountApi.byName(name);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const byRegister = async (register: string) => {
    try {
      const result: AxiosResponse<AccountType[]> = await searchAccountApi.byRegister(register);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const accountPagesList = async (array: string[]) => {
    try {
      const result: AxiosResponse<AccountPages> = await searchAccountApi.accountPagesList(array);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    byName,
    byRegister,
    accountPagesList,
  };
};

export default SearchAccountService();
