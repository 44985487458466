import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import FinancialService from 'services/financial-service';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const CapitalAccount = ({ t }: WithTranslation) => {
  const [yearAgo, setYearAgo] = useState<number>();
  const [base64Document, setBase64Document] = useState<string>('');

  const getReport = async () => {
    const result = await FinancialService.getTaxCalendarYear();
    setYearAgo(result.filter(item => item.NM_CONFIGURACAO === 'ANO_AGO')[0]?.IE_PARAMETRO_INT);
  };

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  useEffect(() => {
    getReport();
  }, []);

  const handleDownloadDocument = () => {
    if (base64Document && yearAgo) {
      try {
        // Decodifica o conteúdo base64
        const byteCharacters = atob(base64Document);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Determina o tipo MIME do arquivo
        const contentType = 'application/pdf';

        // Cria um Blob com o conteúdo decodificado
        const blob = new Blob([byteArray], { type: contentType });

        // Cria uma URL para o Blob e cria um link de download
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = `AGO ${yearAgo}.pdf`;

        // Simula o clique no link para iniciar o download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        // Limpa a URL criada para o Blob
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Erro ao processar o arquivo:', error);
      }
    }
  };

  const getDocument = (yearAgo: number) => {
    FinancialService.getCapitalAccountPDF(yearAgo.toString(), getUserRegistration.toString())
      .then(response => {
        if (typeof response === 'string') {
          setBase64Document(response); // Assumindo que response é diretamente o conteúdo base64 do documento
        } else {
          console.error('Resposta da API não contém conteúdo base64 esperado:', response);
        }
      })
      .catch(error => {
        console.error('Erro ao obter documento:', error);
      });
  };

  useEffect(() => {
    if (yearAgo) {
      getDocument(yearAgo);
    }
  }, [yearAgo]);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('card.distributionOfLeftovers')} />
      </StyledPageTitleContainer>
      <div>
        {yearAgo && base64Document ? (
          <StyledButton color="frisiaPrimary" onClick={handleDownloadDocument}>
            <span>{`${t('financial.download')} ${yearAgo}`}</span>
          </StyledButton>
        ) : (
          <span>{t('financial.docNotAvailable')}</span>
        )}
      </div>
    </>
  );
};

export default withTranslation()(CapitalAccount);
