import { withTranslation, WithTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { StyledButton } from './styled-button/StyledButton';

interface IProps extends WithTranslation {
  id: string;
  image: string;
  name: string;
  description: string;
  isCardSmall?: boolean;
}

interface IStyledProp {
  isCardSmall?: boolean;
}

const BullCard = ({ id, image, name, description, isCardSmall, t }: IProps) => {
  const history = useHistory();

  return (
    <StyledContainer isCardSmall={isCardSmall}>
      <StyledImg src={image} alt={`imagem touro ${name}`} />
      <StyledDescription>
        <div>
          <StyledName>{name}</StyledName>
          <StyledRace>{description}</StyledRace>
        </div>
        <StyledButton
          color="frisiaPrimary"
          onClick={() => history.push('/pecuaria-bovinos/catalogo-touros/detalhes', { bullId: id })}
          style={{ minWidth: '120px', alignSelf: 'center', whiteSpace: 'nowrap' }}
        >
          <span>{t('global.button.viewMore')}</span>
        </StyledButton>
      </StyledDescription>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<IStyledProp>`
  width: ${({ isCardSmall }) => (isCardSmall ? '340px' : '87%')};
  height: 240px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 5px 10px #1d1d1d29;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 150px;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
`;

const StyledDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 90px;
  width: 100%;
  padding: 0 15px;
`;

const StyledName = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 18px;
  text-align: left;
`;

const StyledRace = styled.div`
  /* width: 150px; */

  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #404040;
  font-size: 16px;
  text-align: left;
`;

export default withTranslation()(BullCard);
