import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import shorcutApi from 'api/shortcuts-api';
import { AccountPages, IFavorite, IMessage } from 'model/account-allow-pages';

export const shorcutService = () => {
  const getFavorite = async (registration: number) => {
    try {
      const result: AxiosResponse<IFavorite[]> = await shorcutApi.getFavorite(registration);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const addFavorite = async (registration: number, favorite: string) => {
    try {
      const result: AxiosResponse<IMessage> = await shorcutApi.addFavorite(registration, favorite);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const deleteFavorite = async (registration: number, menuNumber: string) => {
    try {
      const result: AxiosResponse = await shorcutApi.deleteFavorite(registration, menuNumber);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getFavorite,
    addFavorite,
    deleteFavorite,
  };
};

export default shorcutService();
