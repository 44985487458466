import FinancialApi from 'api/financial-api';
import ButtonDownload from 'components/ButtonDownload';
import GenericDropdown from 'components/GenericDropdown';
import InputDateWithSetting from 'components/InputDateWithSetting';
import MainPageTitle from 'components/MainPageTitle';
import dayjs from 'dayjs';
import { Providers } from 'model/financial';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import FinancialService from 'services/financial-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const MonthlyTransactionByProvider = ({ t }: WithTranslation) => {
  const [showWarning, setShowWarning] = useState(false);

  const [selectedStartDateFilter, setSelectedStartDateFilter] = useState<Date | null>(null);
  const [selectedEndDateFilter, setSelectedEndDateFilter] = useState<Date | null>(null);
  const [provider, setProvider] = useState<string>('All');
  const [providerList, setProviderList] = useState<Providers[]>([]);

  const [reportUrl, setReportUrl] = useState<string>();

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  useEffect(() => {
    if (selectedStartDateFilter && selectedEndDateFilter) {
      let start = moment(selectedStartDateFilter);
      let end = moment(selectedEndDateFilter);
      if (end.diff(start, 'days') > 59) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
  }, [selectedStartDateFilter, selectedEndDateFilter]);

  useEffect(() => {
    if (selectedStartDateFilter !== null && selectedEndDateFilter !== null && provider !== 'SELECIONE') {
      let start = dayjs(selectedStartDateFilter).format('YYYY-MM-DD');
      let end = dayjs(selectedEndDateFilter).format('YYYY-MM-DD');
      let providerKey = providerList.find(item => provider === item.NM_FORNECEDOR)?.KEY_FORNECEDOR_CONTA;
      if (providerKey) {
        setReportUrl(
          FinancialApi.getMonthlyMovimentReportbyProvider(start.toString(), end.toString(), getUserRegistration.toString(), providerKey)
        );
      }
    }
  }, [selectedStartDateFilter, selectedEndDateFilter, provider]);

  useEffect(() => {
    FinancialService.getProviders().then(response => {
      setProviderList(response);
    });
  }, []);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('financial.monthlyTransactionByProvider')} />
      </StyledPageTitleContainer>
      <FiltersContainer>
        <StyledFiltersDateContainer>
          <InputDateWithSetting date={selectedStartDateFilter} label={t('livestock.startFilter')} setDate={setSelectedStartDateFilter} />
          <InputDateWithSetting date={selectedEndDateFilter} label={t('livestock.endFilter')} setDate={setSelectedEndDateFilter} />
        </StyledFiltersDateContainer>
        <InputsContainer>
          <StyledFilterContainer filterWidth={400}>
            <GenericDropdown
              label={t('financial.provider')}
              defaultValue="SELECIONE"
              listShowDropdown={['SELECIONE', ...providerList.map(item => item.NM_FORNECEDOR)]}
              getSelectedValue={value => setProvider(value)}
            />
          </StyledFilterContainer>
        </InputsContainer>
      </FiltersContainer>
      {showWarning && <p>{t('financial.dateWarning')}</p>}
      {reportUrl && (
        <>
          <ButtonDownload style={{ marginLeft: 'auto' }} href={reportUrl} />
          <iframe
            sandbox="allow-scripts allow-popups allow-forms allow-top-navigation"
            style={{ width: '100%', height: '800px' }}
            src={reportUrl}
          />
        </>
      )}
      {!reportUrl && (
        <WarningContainer>
          <div>!</div>
          {t('financial.filterWarning')}
        </WarningContainer>
      )}
    </>
  );
};

const StyledFiltersDateContainer = styled.div`
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  max-width: 830px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InputsContainer = styled.div`
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  max-width: 830px;
  height: 65px;
  width: 400px;
`;

const WarningContainer = styled.div`
  top: 444px;
  left: 602px;
  height: 39px;
  text-align: left;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  font-size: 32px;
  letter-spacing: 0px;
  color: #656565;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
  z-index: -1;
  div {
    border-radius: 50%;
    top: 427px;
    left: 502px;
    width: 72px;
    height: 72px;
    border: 3px solid #707070;
    font-size: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }
`;

export default withTranslation()(MonthlyTransactionByProvider);
