import { IDetails } from 'model/schedule-balance';
import { Fragment, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/blue-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/blue-plus-round-icon.svg';

interface IProps extends WithTranslation {
  title: string;
  quantity: string;
  details: IDetails[];
}

const CollapsibleScheduleBalance = ({ title, quantity, details, t }: IProps) => {
  const [isShowGraphic, setIsShowGraphic] = useState<boolean>(false);

  return (
    <StyledContainer>
      <StyledHeader>
        {isShowGraphic ? (
          <MinusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
        ) : (
          <PlusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
        )}
        {title}
        <div style={{ marginLeft: 'auto' }}>{quantity}</div>
      </StyledHeader>
      {isShowGraphic && (
        <StyledGraphicContainer>
          <TableContainer>
            <tbody>
              {details.map((item, index) => (
                <Fragment key={`row-${index}`}>
                  <tr>
                    <StyledTableData>{item.NM_PROPRIEDADE}</StyledTableData>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.productCode')}</td>
                    <td>{item.CD_PRODUTO}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.product')}</td>
                    <td>{item.DS_PRODUTO}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.branch')}</td>
                    <td>{item.NM_REDUZIDO}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.culture')}</td>
                    <td>{item.NM_CULTURA}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.blocked')}</td>
                    <td>{`${StringUtils.maskAmount(item.QT_BLOQUEADO)} ${item.CD_UNIDADE_PADRAO}`}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.available')}</td>
                    <td>{`${StringUtils.maskAmount(item.SD_DISPONIVEL)} ${item.CD_UNIDADE_PADRAO}`}</td>
                  </tr>
                  <tr>
                    <td>{t('agricultural.value')}</td>
                    <td>{`R$ ${StringUtils.maskAmount(item.VALOR)}`}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </TableContainer>
        </StyledGraphicContainer>
      )}
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  height: 60px;
  padding: 0 20px;
  border-radius: 20px;
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 16px;
  color: #4c70aa;
  height: 55px;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
`;

const StyledContainer = styled.div`
  width: 100%;
  min-width: 800px;
  border-radius: 20px;
  background-color: white;
  margin: 15px 0px;
`;

const StyledGraphicContainer = styled.div`
  height: auto;
`;

const TableContainer = styled.table`
  top: 304px;
  left: 895px;
  z-index: 1;
  border-collapse: collapse;
  font-size: 13px;
  width: 100%;
  td,
  th {
    padding: 8px;
    text-align: left;
    padding-left: 23px;
    height: 48px;
  }
  tr:hover {
    background-color: #ddd;
  }
  tbody {
    width: 100%;
    margin-top: 60px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  td:last-child {
    text-align: right;
    padding-right: 23px;
  }
`;

const StyledTableData = styled.td`
  font-family: ${({ theme }) => theme.font.montserratBold} !important;
  color: ${({ theme }) => theme.color.frisiaPrimaryDark} !important;
  text-align: left !important;
`;

export default withTranslation()(CollapsibleScheduleBalance);
