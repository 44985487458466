import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/white-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/white-plus-round-icon.svg';
import { CategoryScale, Chart as ChartJS, ChartOptions, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ReactComponent as ArrowDownSvg } from '../images/frisia/icons/yellow-icon-arrow-down.svg';
import { LocationType } from 'model/quotation-info';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, ChartDataLabels);

interface IProps extends WithTranslation {
  quotationInfo: LocationType;
}

const CollapsibleQuotationGraphic = ({ quotationInfo, t }: IProps) => {
  const [isShowGraphic, setIsShowGraphic] = useState<boolean>(false);
  const theme = useTheme();

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: { display: false },
      datalabels: {
        align: 'top',
      },
      tooltip: {
        backgroundColor: '#fff',
        titleColor: '#000',
        bodyColor: '#000',
        multiKeyBackground: theme.color.frisiaPrimary,
        caretSize: 5,
        borderWidth: 1,
        borderColor: theme.color.frisiaPrimary,
        usePointStyle: true,
        yAlign: 'bottom',
        padding: 10,
        boxPadding: 5,
        boxHeight: 10,
      },
    },
    layout: {
      padding: {
        right: 10,
        top: 22,
      },
    },
  };

  const labels = quotationInfo.historicos.map(item => item.dataF);

  const data = {
    labels,
    datasets: [
      {
        data: quotationInfo.historicos.map(item => item.valor),
        borderColor: 'rgba(11, 05, 134)',
        backgroundColor: 'rgba(11, 05, 134)',
      },
    ],
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <div>
          {isShowGraphic ? (
            <MinusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer', marginRight: '6px' }} />
          ) : (
            <PlusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer', marginRight: '6px' }} />
          )}
          {` ${quotationInfo.titulo}`}
        </div>
        <div style={{ display: 'flex', minWidth: '310px', justifyContent: 'space-between' }}>
          {`${quotationInfo.embalagem} ${quotationInfo.moeda} ${
            quotationInfo.moeda === 'R$' ? quotationInfo.valor.toFixed(2).replace('.', ',') : quotationInfo.valor.toFixed(2)
          }`}
          <StyledRadioButtonContainer onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }}>
            {t('quotes.graph')}
            <StyledIconContainer className={`${isShowGraphic ? 'rotate' : ''}`}>
              <ArrowDownSvg />
            </StyledIconContainer>
          </StyledRadioButtonContainer>
        </div>
      </StyledHeader>
      {isShowGraphic && (
        <StyledGraphicContainer>
          <br />
          <Line options={options} data={data} />
        </StyledGraphicContainer>
      )}
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 60px;
  padding: 0 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.frisiaPrimaryDark};
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 16px;
  color: #d7cc27;
  display: flex;
  justify-content: space-between;
  div {
    align-items: center;
    display: flex;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  min-width: 800px;
  border-radius: 20px;
  background-color: white;
  margin: 10px 0px;
`;

const StyledGraphicContainer = styled.div`
  padding: 23px;
`;

const StyledRadioButtonContainer = styled.div`
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 12px;
  width: 110px;
  height: 32px;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1d1d1d33;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const StyledIconContainer = styled.div`
  @keyframes arrow_animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  &.rotate {
    animation: arrow_animation 0.3s 0s;
    transform: rotate(180deg);
  }
`;

export default withTranslation()(CollapsibleQuotationGraphic);
