import { encryptReport, sendLog } from 'shared/util/api-utils';

export const mySquadApi = () => {
  const getMySquadReport = (register: string) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/PEC/PEC0310A&CD_EMPRESA=2&CD_CRIADOR=${register}`;
    sendLog('Relatorio meu plantel', path, JSON.stringify([]), 'get');
    return encryptReport(path);
  };

  return {
    getMySquadReport,
  };
};

export default mySquadApi();
