import purchasingAuthorizationApi from 'api/purchasingAuthorization-api';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'config/i18n';
import HttpStatus from 'model/enums/http-status';
import { IReturnMessage } from 'model/financial';
import { IAuthorization, ILocals, INewAuth, IPayments, IPerson } from 'model/purchasingAuthorization';
import { sendLog } from 'shared/util/api-utils';
import toastUtils from 'shared/util/toast-utils';

export const purchasingAuthorizationService = () => {
  const getAll = async (registration: string) => {
    try {
      const result: AxiosResponse<IAuthorization[]> = await purchasingAuthorizationApi.getAll(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getVig = async (registration: string) => {
    try {
      const result: AxiosResponse<IAuthorization[]> = await purchasingAuthorizationApi.getVig(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const cancelAuth = async (register: string, auth: string) => {
    try {
      const result: AxiosResponse<IReturnMessage> = await purchasingAuthorizationApi.cancelAuth(register, auth);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast('Autorização cancelada com sucesso!');
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.Message);
      console.error(response);
      return Promise.reject(response);
    }
  };

  const sendNewAuth = async (newAuth: INewAuth) => {
    try {
      const result: AxiosResponse<IReturnMessage> = await purchasingAuthorizationApi.sendNewAuth(newAuth);
      if (result.status === 200 && result.data != null) {
        toastUtils.emitSuccessToast(i18n.t('global.successMessage.authCreated'));
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(response?.data.ExceptionMessage);
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getPaymentForms = async () => {
    try {
      const result: AxiosResponse<IPayments[]> = await purchasingAuthorizationApi.getPaymentForms();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getLocals = async () => {
    try {
      const result: AxiosResponse<ILocals[]> = await purchasingAuthorizationApi.getLocals();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getPersonsByName = async (name: string) => {
    try {
      const result: AxiosResponse<IPerson[]> = await purchasingAuthorizationApi.getPersonsByName(name);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getPersonsByExactCPF = async (cpf: string) => {
    try {
      const result: AxiosResponse<IPerson[]> = await purchasingAuthorizationApi.getPersonsByExactCPF(cpf);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getAll,
    getVig,
    cancelAuth,
    sendNewAuth,
    getPaymentForms,
    getLocals,
    getPersonsByName,
    getPersonsByExactCPF,
  };
};

export default purchasingAuthorizationService();
