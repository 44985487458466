import styled from 'styled-components';

const StyledDocumentsContainer = styled.ul`
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  list-style: none;
  padding: 0;
  background-color: white;

  li:nth-child(2n) {
    background-color: #f1f1f1;
  }
`;

export default StyledDocumentsContainer;
