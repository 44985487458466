import React from 'react';
import './CustomAlert.css'; // Reutilize o mesmo CSS ou crie um novo, conforme suas necessidades

const CustomConfirm = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert">
        <p>{message}</p>
        <button onClick={onConfirm}>Sim</button>
        <button onClick={onCancel}>Não</button>
      </div>
    </div>
  );
};

export default CustomConfirm;
