import { trrOrderApi } from 'api/trr-order-api';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'config/i18n';
import HttpStatus from 'model/enums/http-status';
import { IReturnMessage } from 'model/financial';
import {
  IAreaType,
  IBalanceCrop,
  ICropType,
  ICultureType,
  IHarvest,
  IItensTrr,
  ILotsTrr,
  IOrder,
  IPaymentConditions,
  IPropertyType,
  IPurposeType,
  ITrrOrder,
  IHistoryTrr,
} from 'model/trr-order';
import toastUtils from 'shared/util/toast-utils';

export const trrOrderService = () => {
  const getTrrOrder = async (registration: number): Promise<ITrrOrder> => {
    try {
      const result = await trrOrderApi().getTrrOrder(registration);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const sendTrrOrder = async (order: ITrrOrder): Promise<ITrrOrder> => {
    try {
      const result = await trrOrderApi().sendTrrOrder(order);
      if (result.status === HttpStatus.CREATED && result.data != null) {
        toastUtils.emitSuccessToast(i18n.t('global.successMessage.orderCreated'));
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.orderCreated'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.orderCreated'));
      return Promise.reject(response);
    }
  };

  const getProperties = async (register: string): Promise<IPropertyType[]> => {
    try {
      const result = await trrOrderApi().getProperties(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getHarvestOptions = async (): Promise<IHarvest[]> => {
    try {
      const result = await trrOrderApi().getHarvestOptions();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getCropTypesOptions = async (property: string): Promise<ICropType[]> => {
    try {
      const result = await trrOrderApi().getCropTypesOptions(property);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getCultureOptions = async (): Promise<ICultureType[]> => {
    try {
      const result = await trrOrderApi().getCultureOptions();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getPurposeOptions = async (culture: string): Promise<IPurposeType[]> => {
    try {
      const result = await trrOrderApi().getPurposeOptions(culture);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAreas = async (register: string): Promise<IAreaType[]> => {
    try {
      const result = await trrOrderApi().getAreas(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getPaymentConditions = async (register: string, crop: string, activityType: string): Promise<IPaymentConditions[]> => {
    try {
      const result = await trrOrderApi().getPaymentConditions(register, crop, activityType);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getBalanceCrop = async (register: string): Promise<IBalanceCrop[]> => {
    try {
      const result = await trrOrderApi().getBalanceCrop(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getLotsTRR = async (register: string, property: string): Promise<ILotsTrr[]> => {
    try {
      const result = await trrOrderApi().getLotsTRR(register, property);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getItensTRR = async (): Promise<IItensTrr[]> => {
    try {
      const result = await trrOrderApi().getItensTRR();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const sendOrder = async (order: IOrder): Promise<IReturnMessage> => {
    try {
      const result = await trrOrderApi().sendOrder(order);
      if (result.status === HttpStatus.OK && result.data != null) {
        toastUtils.emitSuccessToast(result.data.Message);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.orderCreated'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.orderCreated'));
      return Promise.reject(response);
    }
  };

  const getHistory = async (register: string): Promise<IHistoryTrr[]> => {
    try {
      const result = await trrOrderApi().getHistory(register);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const cancelTrrOrder = async (orderId: string): Promise<IReturnMessage> => {
    try {
      const result = await trrOrderApi().cancelTrrOrder(orderId);
      if (result.status === HttpStatus.OK && result.data != null) {
        toastUtils.emitSuccessToast(result.data.Message);
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.orderCreated'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.orderCreated'));
      return Promise.reject(response);
    }
  };

  return {
    getTrrOrder,
    sendTrrOrder,
    getHarvestOptions,
    getProperties,
    getCropTypesOptions,
    getCultureOptions,
    getPurposeOptions,
    getAreas,
    getPaymentConditions,
    getBalanceCrop,
    getLotsTRR,
    getItensTRR,
    sendOrder,
    getHistory,
    cancelTrrOrder,
  };
};

export default trrOrderService();
