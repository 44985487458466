import { AxiosResponse } from 'axios';
import { IReturnMessage } from 'model/financial';
import {
  IHistoryTrr,
  IAreaType,
  IBalanceCrop,
  ICropType,
  ICultureType,
  IHarvest,
  IItensTrr,
  ILotsTrr,
  IOrder,
  IPaymentConditions,
  IPropertyType,
  IPurposeType,
  ITrrOrder,
} from 'model/trr-order';
import { sendLog } from 'shared/util/api-utils';
import { api, apiCore } from './api';

export const trrOrderApi = () => {
  const getTrrOrder = (registration: number): Promise<AxiosResponse<ITrrOrder>> => {
    sendLog('Consulta ordens trr', `/EBS/api/Vendas/Ordens/getAll/${registration}/137`, JSON.stringify([]), 'get');
    return api.get<ITrrOrder>(`/EBS/api/Vendas/Ordens/getAll/${registration}/137`);
  };

  const sendTrrOrder = (order: ITrrOrder): Promise<AxiosResponse<ITrrOrder>> => {
    sendLog('Enviar Ordem TRR', `/EBS/api/Vendas`, JSON.stringify([]), 'post');
    return api.post<ITrrOrder>('/EBS/api/Vendas');
  };

  const getProperties = (register: string): Promise<AxiosResponse<IPropertyType[]>> => {
    sendLog(
      'Consulta propriedades por matricula',
      `/Prop/GetPropriedadesByCoopMatricula?nr_matricula=${register}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IPropertyType[]>(`/Prop/GetPropriedadesByCoopMatricula?nr_matricula=${register}`);
  };

  const getHarvestOptions = (): Promise<AxiosResponse<IHarvest[]>> => {
    sendLog('Consulta safras', `/TRR/GetSafrasSelecionaveis`, JSON.stringify([]), 'get');
    return apiCore.get<IHarvest[]>(`/TRR/GetSafrasSelecionaveis`);
  };

  const getCropTypesOptions = (property: string): Promise<AxiosResponse<ICropType[]>> => {
    sendLog('Consulta tipos e safras', `/TRR/GetTiposSafras?CD_ESTADO=${property}`, JSON.stringify([]), 'get');
    return apiCore.get<ICropType[]>(`/TRR/GetTiposSafras?CD_ESTADO=${property}`);
  };

  const getCultureOptions = (): Promise<AxiosResponse<ICultureType[]>> => {
    sendLog('Consulta culturas', `/TRR/GetCulturas`, JSON.stringify([]), 'get');
    return apiCore.get<ICultureType[]>(`/TRR/GetCulturas`);
  };

  const getPurposeOptions = (culture: string): Promise<AxiosResponse<IPurposeType[]>> => {
    sendLog('Consulta propositos', `/TRR/GetPropositos?CD_CULTURA=${culture}`, JSON.stringify([]), 'get');
    return apiCore.get<IPurposeType[]>(`/TRR/GetPropositos?CD_CULTURA=${culture}`);
  };

  const getAreas = (register: string): Promise<AxiosResponse<IAreaType[]>> => {
    sendLog('Consulta areas do cooperado', `/Area/GetAreasCooperado?nr_matricula=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<IAreaType[]>(`/Area/GetAreasCooperado?nr_matricula=${register}`);
  };

  const getPaymentConditions = (register: string, crop: string, activityType: string): Promise<AxiosResponse<IPaymentConditions[]>> => {
    sendLog(
      'Consulta condições de pagamento',
      `/TRR/GetCondicoesPagamento?CD_PESSOA=${register}&CD_SAFRA=${crop}&CD_TIPO_ATIVIDADE=${activityType}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IPaymentConditions[]>(
      `/TRR/GetCondicoesPagamento?CD_PESSOA=${register}&CD_SAFRA=${crop}&CD_TIPO_ATIVIDADE=${activityType}`
    );
  };

  const getBalanceCrop = (register: string): Promise<AxiosResponse<IBalanceCrop[]>> => {
    sendLog('Consulta saldos de safra diesel', `/TRR/GetSaldosDieselSafra?CD_PESSOA=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<IBalanceCrop[]>(`/TRR/GetSaldosDieselSafra?CD_PESSOA=${register}`);
  };

  const getLotsTRR = (register: string, property: string): Promise<AxiosResponse<ILotsTrr[]>> => {
    sendLog('Consulta lotes trr', `/TRR/GetLotesTRR?CD_PESSOA=${register}&NR_PROPRIEDADE=${property}`, JSON.stringify([]), 'get');
    return apiCore.get<ILotsTrr[]>(`/TRR/GetLotesTRR?CD_PESSOA=${register}&NR_PROPRIEDADE=${property}`);
  };

  const getItensTRR = (): Promise<AxiosResponse<IItensTrr[]>> => {
    sendLog('Consulta itens trr', `/TRR/GetItensTRR`, JSON.stringify([]), 'get');
    return apiCore.get<IItensTrr[]>(`/TRR/GetItensTRR`);
  };

  const sendOrder = (order: IOrder): Promise<AxiosResponse<IReturnMessage>> => {
    sendLog('Envio de pedido trr', `/TRR/EnviarPedido`, JSON.stringify(order), 'post');
    return apiCore.post<IReturnMessage>(`/TRR/EnviarPedido`, order);
  };

  const getHistory = (register: string): Promise<AxiosResponse<IHistoryTrr[]>> => {
    sendLog('Consulta historico pedidos trr', `/TRR/GetPedidosTRR?CD_PESSOA=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<IHistoryTrr[]>(`/TRR/GetPedidosTRR?CD_PESSOA=${register}`);
  };

  const cancelTrrOrder = (orderId: string): Promise<AxiosResponse<IReturnMessage>> => {
    sendLog('Cancelar ordens trr', `/TRR/CancelaPedidoTRR?ID_PEDIDO=${orderId}`, JSON.stringify([]), 'put');
    return apiCore.put<IReturnMessage>(`/TRR/CancelaPedidoTRR?ID_PEDIDO=${orderId}`);
  };

  return {
    getTrrOrder,
    sendTrrOrder,
    getHarvestOptions,
    getCultureOptions,
    getPurposeOptions,
    getCropTypesOptions,
    getAreas,
    getPaymentConditions,
    getBalanceCrop,
    getLotsTRR,
    getItensTRR,
    sendOrder,
    getProperties,
    getHistory,
    cancelTrrOrder,
  };
};

export default trrOrderApi();
