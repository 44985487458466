import i18n from 'config/i18n';
import { isEmpty } from 'lodash';
import CardInfo from 'model/card-info';
import { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import { ReactComponent as ArrowIconSvg } from '../images/frisia/icons/gray-arrow-icon.svg';

const BreadCrumbs = () => {
  const { pathname } = useLocation();
  const fragmentedPath = pathname.split('/');

  const sizeShowBreadCrumb = 1;
  const breadCrumbs: CardInfo[] = [];

  fragmentedPath.map((path, indexFragmentedPath) => {
    let partMountedPath = '';

    for (let index = 0; index <= indexFragmentedPath; index++) {
      if (!isEmpty(fragmentedPath[index])) {
        partMountedPath += `/${fragmentedPath[index]}`;
      }
    }

    const routeData = StringUtils.getRoute(partMountedPath);

    if (routeData != null) {
      breadCrumbs.push(routeData);
    }
  });

  return (
    <StyledBreadCrumbsContainer>
      <StyledUl>
        {breadCrumbs.map(
          (crumb, index) =>
            breadCrumbs.length > sizeShowBreadCrumb && (
              <Fragment key={`path-${index}`}>
                {index !== 0 && <ArrowIconSvg style={{ margin: '0 8px' }} />}
                <StyledItem>
                  <Link to={crumb.pathRedirected}>{i18n.t(crumb.i18nText)}</Link>
                </StyledItem>
              </Fragment>
            )
        )}
      </StyledUl>
    </StyledBreadCrumbsContainer>
  );
};

const StyledBreadCrumbsContainer = styled.nav`
  min-height: 20px;
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  margin-top: 10px;
`;

const StyledUl = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  li:last-child {
    pointer-events: none;

    a {
      color: #656565;
    }
  }
`;

const StyledItem = styled.li`
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background-color: #d8deeb;
  }
`;

export default BreadCrumbs;
