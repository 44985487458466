import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from 'components/Checkbox';
import GenericDropdown from 'components/GenericDropdown';
import GenericInputTextAreaToTable from 'components/GenericInputTextAreaToTable';
import GenericInputToTable from 'components/GenericInputToTable';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import InputDateWithSetting from 'components/InputDateWithSetting';
import MainPageTitle from 'components/MainPageTitle';
import RadioButton from 'components/RadioButton';
import { StyledButton } from 'components/styled-button/StyledButton';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { feedOrdersService } from 'services/cattle/feed-order-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import * as yup from 'yup';
import { IFeedOrder, TypeActivities, TypeFeedProducts, TypeFeedStockProducts, TypePayments, TypeProperties } from '../../model/feed-orders';
import { ILotsTrr } from '../../model/trr-order';
import { makeStyles } from '@mui/styles';
import { ReactComponent as ArrowDownSvg } from '../../images/frisia/icons/yellow-icon-arrow-down.svg';
import trrOrderService from 'services/trr-order-service';

interface IForm {
  invoice: string;
  delivery: string;
  orderType: OrderType;
  product: string;
  activity: string;
  payment: string;
  amount: string;
  deliveryDate: string;
  complementDelivery: string;
  onlyProductsFromLast: boolean;
  observation: string;
  schedule?: string;
}

enum OrderType {
  BULK = 'BULK',
  BAGGED = 'BAGGED',
}

const useStyles = makeStyles((theme?: any) => ({
  paper: {
    backgroundColor: '#e6eaf2 !important',
    fontSize: '18px !important',
    transition: '2s all ease !important',
  },
}));

const NewFeedOrder = ({ t }: WithTranslation) => {
  const classes = useStyles();
  const [isEnsacado, setIsEnsacado] = useState<boolean>(false);

  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);

  const NUMBERACTIVITYPEBOVINE = '6';
  const NUMBERACTIVITYPESWINE = '2';

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isResetInputValue, setIsResetInputValue] = useState<boolean>(false);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);

  const [invoiceList, setInvoiceList] = useState<TypeProperties[]>([]);
  const [chosenProperty, setChosenProperty] = useState<TypeProperties>();
  const [delivery, setDelivery] = useState<TypeProperties>();
  const [productsList, setProductsList] = useState<TypeFeedProducts[]>([]);
  const [chosenProduct, setChosenProduct] = useState<TypeFeedProducts>();
  const [activitiesList, setActivitiesList] = useState<TypeActivities[]>([]);
  const [paymentsList, setPaymentsList] = useState<TypePayments[]>([]);
  const [chosenPayment, setChosenPayment] = useState<TypePayments>();
  const [chosenActivity, setChosenActivity] = useState<TypeActivities>();
  const [formsSubmit, setFormsSubmit] = useState<IForm>();
  const [feedStockProduct, setfeedStockProduct] = useState<TypeFeedStockProducts>();

  const [onlyFeed, setOnlyFeed] = useState<boolean>(false);
  const [lastProducts, setLastProducts] = useState<boolean>(false);
  const [bagged, setBagged] = useState<boolean>(false);
  const myUrl = window.location.href;
  const [firstTypeActivity, setFirstTypeActivity] = useState<string>(
    myUrl.includes('bovino') ? NUMBERACTIVITYPEBOVINE : myUrl.includes('suino') ? NUMBERACTIVITYPESWINE : '0'
  );
  const secondTypeActivity = '0';

  const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
  const [openTransferModalMp, setOpenTransferModalMp] = useState<boolean>(false);
  const [feedOrderList, setFeedOrderList] = useState<IFeedOrder[]>([]);

  const [lotsOptions, setLotsOptions] = useState<ILotsTrr[]>([]);
  const [chosenLot, setChosenLot] = useState<ILotsTrr>();
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const bottomRef = useRef<HTMLDivElement>(null);

  const schema = yup.object({
    invoice: yup.string().test('test-invoice', t('global.errorMessage.required'), () => chosenProperty != null),
    product: yup.string().test('test-invoice', t('global.errorMessage.required'), () => chosenProduct != null),
    delivery: yup.string().test('test-delivery', t('global.errorMessage.required'), () => delivery != null),
    activity: yup.string().test('test-activity', t('global.errorMessage.required'), () => chosenActivity != null),
    payment: yup.string().test('test-payment', t('global.errorMessage.required'), () => chosenPayment != null),
    deliveryDate: yup.string().test('test-deliveryDate', t('global.errorMessage.required'), () => selectedDate != null),
    amount: yup
      .string()
      .required()
      .test('test-deliveryDate', t('global.errorMessage.productError'), value => {
        if (value && !bagged && !isEnsacado) {
          return parseFloat(value) >= 2000;
        } else {
          return true;
        }
      }),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const userRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  useEffect(() => {
    feedOrdersService().getPropertiesOptions(userRegistration).then(setInvoiceList);
    feedOrdersService().getActivities(userRegistration).then(setActivitiesList);
  }, []);

  useEffect(() => {
    if (chosenProperty && userRegistration != null) {
      trrOrderService.getLotsTRR(userRegistration, chosenProperty.NR_PROPRIEDADE.toString()).then(setLotsOptions);
    }
  }, [chosenProperty]);

  useEffect(() => {
    if (lastProducts) {
      setProductsList([]);
      setLoadingProducts(true);
      feedOrdersService()
        .getLastProducts(userRegistration)
        .then(response => {
          setLoadingProducts(false);
          setProductsList(response);
        });
    } else if (onlyFeed) {
      setProductsList([]);
      setLoadingProducts(true);
      feedOrdersService()
        .getOnlyFeedProducts(bagged ? 'S' : 'N', firstTypeActivity, secondTypeActivity)
        .then(response => {
          setLoadingProducts(false);
          setProductsList(response);
        });
    } else {
      setProductsList([]);
      setLoadingProducts(true);
      feedOrdersService()
        .getFeedProducts(bagged ? 'S' : 'N', firstTypeActivity, secondTypeActivity)
        .then(response => {
          setLoadingProducts(false);
          setProductsList(response);
        });
    }
  }, [bagged, firstTypeActivity, secondTypeActivity, lastProducts, onlyFeed]);

  useEffect(() => {
    if (chosenActivity) {
      feedOrdersService().getPayments(userRegistration, chosenActivity.CD_TIPO_ATIVIDADE).then(setPaymentsList);
    }
  }, [chosenActivity]);

  const tableHeader = [
    t('feedOrder.iten'),
    t('feedOrder.product'),
    t('feedOrder.activity'),
    t('feedOrder.paymentTerms'),
    t('feedOrder.deliveryDate'),
    t('feedOrder.amount'),
    t('feedOrder.transportScheduling'),
    '',
  ];

  const handleSubmit = (data: IForm) => {
    setFormsSubmit(data);
    if (chosenProduct && chosenProperty && delivery && chosenPayment && selectedDate && chosenActivity) {
      feedOrdersService()
        .getFeedStockProduct(userRegistration, chosenProduct?.CD_ITEM ?? '', dayjs(selectedDate).format('DD/MM/YYYY'))
        .then(response => {
          if (response.NM_CAMPANHA != null) {
            setfeedStockProduct(response);
            setOpenTransferModalMp(true);
          } else {
            setfeedStockProduct(undefined);
            addItem();
          }
        });
    }
  };

  const addItem = () => {
    if (chosenProduct && chosenProperty && delivery && chosenPayment && selectedDate && chosenActivity && formsSubmit) {
      const orderItem: IFeedOrder = {
        CD_MATRICULA: parseFloat(userRegistration),
        DT_REGISTRO: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        CD_SISTEMA_ORIGEM: 'EXTRANET.PEDIDO_RACAO',
        CD_PROPRIEDADE_FATURA: chosenProperty?.NR_PROPRIEDADE.toString(),
        CD_PROPRIEDADE_ENTREGA: delivery?.NR_PROPRIEDADE.toString(),
        CD_CONDICAO_PAGAMENTO: chosenPayment?.CD_CONDICAO_PAGAMENTO,
        DS_COMPLEM_ENTREGA: formsSubmit.complementDelivery,
        CD_PRODUTO: parseFloat(chosenProduct.CD_ITEM),
        QUANTIDADE: formsSubmit.amount,
        DT_PREVISAO_ENVIO: dayjs(selectedDate).format('YYYY-MM-DD 00:00:00'),
        CD_SAFRA: null,
        CD_ATIVIDADE: chosenActivity.CD_TIPO_ATIVIDADE,
        IE_AGENDA_TRANSPORTE: formsSubmit.schedule ? 'SIM' : 'NAO',
        CD_CULTURA: null,
        CD_PROPOSITO: null,
        DS_TIPO_SAFRA: null,
        DS_STATUS_PROCESSO: 'string',
        DT_MUDANCA_STATUS: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        CD_LOTE_SUINO: chosenLot?.ID_LOTE.toString() ?? null,
        CD_FASE_TERM_SUINOS: chosenLot?.IE_TIPO_LOTE.toString() ?? null,
      };
      setFeedOrderList(oldList => [...oldList, orderItem]);
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      setOpenTransferModalMp(false);
    }
  };

  const deleteInList = index => {
    const NewArray = feedOrderList;
    NewArray.splice(index, 1);
    setFeedOrderList(oldList => [...NewArray]);
  };

  const sendList = () => {
    setDisableButton(true);
    setIsResetInputValue(true);
    feedOrdersService()
      .sendOrder({
        itens: feedOrderList,
      })
      .then(response => {
        setOpenTransferModal(false);
        setFeedOrderList([]);
        setIsResetInputValue(false);
      })
      .finally(() => {
        setDisableButton(false);
      });
  };

  const renderList = () => {
    return (
      <TableContainer>
        <GenericTableBlueHeader
          headerItems={tableHeader}
          tableItems={feedOrderList.map((item, index) => ({
            [t('feedOrder.iten')]: index + 1,
            [t('feedOrder.product')]: item.CD_PRODUTO,
            [t('feedOrder.activity')]: item.CD_ATIVIDADE,
            [t('feedOrder.paymentTerms')]: item.CD_CONDICAO_PAGAMENTO,
            [t('feedOrder.deliveryDate')]: moment(item.DT_PREVISAO_ENVIO, 'YYYY-MM-DD')
              ? moment(item.DT_PREVISAO_ENVIO, 'YYYY-MM-DD').format('DD/MM/YY')
              : item.DT_PREVISAO_ENVIO,
            [t('feedOrder.amount')]: item.QUANTIDADE,
            [t('feedOrder.transportScheduling')]: item.IE_AGENDA_TRANSPORTE ?? 'Não',
            [t('feedOrder.actions')]: (
              <StyledButton
                style={{ padding: '6px', lineHeight: '11px' }}
                color="orange"
                type="button"
                onClick={() => {
                  deleteInList(index);
                }}
              >
                <span style={{ fontSize: '10px' }}>{t('global.button.remove')}</span>
              </StyledButton>
            ),
          }))}
          dataColor="#787878"
          isInvertLineColor
          isDontHaveMaximumWidth
          isAlignLastColumnToLeft
        />
        <StyledButton
          onClick={() => setOpenTransferModal(true)}
          color="frisiaPrimary"
          type="button"
          style={{ minWidth: '400px', alignSelf: 'center', marginTop: '16px' }}
        >
          <span ref={bottomRef}>{t('global.button.sendOrder')}</span>
        </StyledButton>
      </TableContainer>
    );
  };

  return (
    <>
      {openTransferModal && (
        <ModalContainer>
          <ModalShadow onClick={() => setOpenTransferModal(false)} />
          <CardContainer>
            <div style={{ padding: '10px' }}>
              <SubtitleStyled>{t('global.field.confirmOrder')}</SubtitleStyled>
              <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                <CancelButton
                  onClick={() => {
                    setOpenTransferModal(false);
                  }}
                  style={{ width: '145px', height: '40px' }}
                >
                  {t('financial.cancel')}
                </CancelButton>
                <StyledButton
                  color="frisiaPrimary"
                  onClick={sendList}
                  disabled={disableButton}
                  type="submit"
                  style={{ color: 'white', minWidth: '145px', height: '42px' }}
                >
                  <span>{t('financial.confirm')}</span>
                </StyledButton>
              </div>
            </div>
          </CardContainer>
        </ModalContainer>
      )}
      {openTransferModalMp && (
        <ModalContainer>
          <ModalShadow onClick={() => setOpenTransferModalMp(false)} />
          <CardContainer>
            <div style={{ padding: '10px' }}>
              <SubtitleStyled>{t('livestock.ValidationItemFeedstock')}</SubtitleStyled>
              <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                <CancelButton
                  onClick={() => {
                    setOpenTransferModalMp(false);
                  }}
                  style={{ width: '145px', height: '40px' }}
                >
                  {t('financial.cancel')}
                </CancelButton>
                <StyledButton
                  color="frisiaPrimary"
                  onClick={addItem}
                  disabled={disableButton}
                  type="submit"
                  style={{ color: 'white', minWidth: '145px', height: '42px' }}
                >
                  <span>{t('financial.confirm')}</span>
                </StyledButton>
              </div>
            </div>
          </CardContainer>
        </ModalContainer>
      )}
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('livestock.newRationOrder')} />
      </StyledPageTitleContainer>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Grid container columnSpacing="17px">
            <StyledPageTitleContainer margin={'25px 0 14px 17px'} style={{ width: '100%' }}>
              <MainPageTitle fontSize={16} text={t('livestock.properties')} />
            </StyledPageTitleContainer>
            <Grid item xs={12}>
              <Grid container columnSpacing="17px" spacing="2px">
                <Grid item xs={12} lg={6}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('livestock.invoice')}
                      placeholder={t('livestock.selectOption')}
                      listShowDropdown={invoiceList.length > 0 ? invoiceList : [{ FULL_PROPRIEDADE: t('global.field.loading') }]}
                      keyToShowInDropdown="FULL_PROPRIEDADE"
                      isResetValue={isResetInputValue}
                      getSelectedValue={setChosenProperty}
                      errorText={methods.formState.errors.invoice?.message}
                    />
                  </StyledFilterContainer>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('livestock.delivery')}
                      placeholder={t('livestock.selectDestination')}
                      listShowDropdown={invoiceList.length > 0 ? invoiceList : [{ FULL_PROPRIEDADE: t('global.field.loading') }]}
                      keyToShowInDropdown="FULL_PROPRIEDADE"
                      getSelectedValue={setDelivery}
                      isResetValue={isResetInputValue}
                      errorText={methods.formState.errors.delivery?.message}
                    />
                  </StyledFilterContainer>
                </Grid>
              </Grid>
            </Grid>

            <StyledPageTitleContainer margin={'25px 0 14px 17px'} style={{ width: '100%' }}>
              <MainPageTitle fontSize={16} text={t('livestock.products')} />
            </StyledPageTitleContainer>

            <Grid item xs={12}>
              <Grid container spacing="2px">
                <Grid item xs={12} lg={12}>
                  <StyledCheckBoxContainer>
                    <Checkbox
                      inputName="onlyFeed"
                      handleChecked={setOnlyFeed}
                      text={t('feedOrder.onlyFeed')}
                      style={{ width: '300px', visibility: !lastProducts ? 'visible' : 'hidden' }}
                    />
                    <Checkbox inputName="lastProducts" handleChecked={setLastProducts} text={t('feedOrder.lastProducts')} />
                  </StyledCheckBoxContainer>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <StyledProductContainer>
                <StyledRadioButtonsContainer>
                  <StyledText style={{ visibility: !lastProducts ? 'visible' : 'hidden' }}>{t('livestock.orderType')}</StyledText>
                  <StyledContainer style={{ visibility: !lastProducts ? 'visible' : 'hidden' }}>
                    <RadioButton
                      isDefaultChecked={!bagged}
                      name="orderType"
                      value={String(t('livestock.bulk'))}
                      id="bulk"
                      handleClick={() => {
                        setBagged(false);
                        setIsEnsacado(false);
                      }}
                      style={{ display: 'inline-flex', marginRight: '40px', marginBottom: '0' }}
                    />
                    <RadioButton
                      name="orderType"
                      value={String(t('livestock.bagged'))}
                      id="bagged"
                      handleClick={() => setBagged(true)}
                      style={{ display: 'inline-flex', marginBottom: '0' }}
                    />
                  </StyledContainer>
                </StyledRadioButtonsContainer>
                {(isEnsacado || bagged) && (
                  <Checkbox
                    style={{
                      marginTop: '4px',
                      justifyContent: 'right',
                      flexDirection: 'row-reverse',
                    }}
                    inputName="schedule"
                    text={t('feedOrder.schedule')}
                  />
                )}
              </StyledProductContainer>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid item xs={12}>
                <StyledFilterContainer>
                  <GenericDropdown
                    label={t('livestock.activity')}
                    placeholder={t('livestock.selectActivity')}
                    keyToShowInDropdown="FULL_TIPO_ATIVIDADE"
                    listShowDropdown={activitiesList.length > 0 ? activitiesList : [{ FULL_TIPO_ATIVIDADE: t('global.field.loading') }]}
                    getSelectedValue={value => {
                      if (value.CD_TIPO_ATIVIDADE === NUMBERACTIVITYPEBOVINE) {
                        setFirstTypeActivity(NUMBERACTIVITYPEBOVINE);
                      } else if (value.CD_TIPO_ATIVIDADE === NUMBERACTIVITYPESWINE) {
                        setFirstTypeActivity(NUMBERACTIVITYPESWINE);
                      } else {
                        setFirstTypeActivity('0');
                      }
                      setChosenActivity(value);
                    }}
                    isResetValue={isResetInputValue}
                    errorText={methods.formState.errors.activity?.message}
                  />
                </StyledFilterContainer>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container spacing="2px">
                <Grid item xs={12} style={{ marginBottom: '15px' }}>
                  <StyledFilterContainer>
                    <StyledSearch>
                      <Autocomplete
                        style={{ flex: '1' }}
                        id="custom-input-demo"
                        autoComplete
                        options={productsList.map(item => item.FULL_ITEM)}
                        classes={{ paper: classes.paper }}
                        defaultChecked
                        onFocus={e => setIsShowDropdown(true)}
                        onBlur={e => setIsShowDropdown(false)}
                        onChange={(e, value) => {
                          value && setChosenProduct(productsList.find(item => item.FULL_ITEM === value));
                          const chosenProduct = productsList.find(item => item.FULL_ITEM === value);
                          setIsEnsacado(chosenProduct?.IE_ENSACADO === 'S' ? true : false);
                          console.log(isEnsacado);
                        }}
                        noOptionsText={loadingProducts ? t('global.field.loading') : t('global.field.noOptionsParams')}
                        renderInput={params => (
                          <TextField
                            label={t('livestock.product')}
                            variant="standard"
                            focused
                            {...params}
                            placeholder={t('agricultural.selectProduct')}
                          />
                        )}
                      />
                      <StyledIconContainer className={`${isShowDropdown ? 'rotate' : ''}`}>
                        <ArrowDownSvg />
                      </StyledIconContainer>
                    </StyledSearch>
                  </StyledFilterContainer>
                  <StyledErrorMessage>{methods.formState.errors.product?.message}</StyledErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <GenericInputToTable
                    isKilogram
                    inputName="amount"
                    label={`${t('livestock.amount')} ${!lastProducts ? (!bagged ? ' (Kg)' : ' (Sc)') : !isEnsacado ? ' (Kg)' : ' (Sc)'}`}
                    placeholder={`${t('livestock.setAnyAmount')} ${
                      !lastProducts ? (!bagged ? '(Kg)' : '(Sc)') : !isEnsacado ? '(Kg)' : '(Sc)'
                    }`}
                    isResetValue={isResetInputValue}
                    errorText={methods.formState.errors.amount?.message}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container spacing="2px">
                <Grid item xs={12}>
                  <StyledFilterContainer>
                    <GenericDropdown
                      label={t('livestock.payment')}
                      placeholder={t('livestock.selectPaymentTerms')}
                      listShowDropdown={paymentsList.length > 0 ? paymentsList : [{ DS_CONDICAO_PAGAMENTO: t('global.field.loading') }]}
                      keyToShowInDropdown="DS_CONDICAO_PAGAMENTO"
                      getSelectedValue={setChosenPayment}
                      isResetValue={isResetInputValue}
                      errorText={methods.formState.errors.payment?.message}
                    />
                  </StyledFilterContainer>
                </Grid>
                <Grid item xs={12}>
                  <StyledFilterContainer>
                    <StyledDateOptions>
                      <InputDateWithSetting
                        errorText={methods.formState.errors.deliveryDate?.message}
                        label={t('livestock.deliveryDate')}
                        setDate={setSelectedDate}
                        date={selectedDate}
                      />
                    </StyledDateOptions>
                  </StyledFilterContainer>
                </Grid>
              </Grid>
            </Grid>
            {firstTypeActivity === NUMBERACTIVITYPESWINE && (
              <Grid item xs={12} lg={6}>
                <StyledFilterContainer>
                  <GenericDropdown
                    label={t('livestock.lots')}
                    placeholder={t('livestock.selectLots')}
                    listShowDropdown={lotsOptions.length > 0 ? lotsOptions : ['Sem opções']}
                    keyToShowInDropdown={lotsOptions.length > 0 ? 'LOTE' : undefined}
                    getSelectedValue={setChosenLot}
                  />
                </StyledFilterContainer>
              </Grid>
            )}

            <Grid item xs={12}>
              <GenericInputTextAreaToTable
                inputName="complementDelivery"
                label={t('livestock.complement')}
                placeholder={t('livestock.enterAddon')}
                isResetValue={isResetInputValue}
              />
            </Grid>
          </Grid>
          <StyledButton color="frisiaPrimary" type="submit" style={{ minWidth: '400px', alignSelf: 'center', marginTop: '16px' }}>
            <span>{t('global.button.addItem')}</span>
          </StyledButton>
          {feedOrderList.length > 0 && renderList()}
        </form>
      </FormProvider>
    </>
  );
};

const StyledProductContainer = styled.div`
  text-align: left;
  height: 100%;
  margin-bottom: 16px;
`;

const StyledText = styled.span`
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #656565;
  font-size: 16px;
`;

const TableContainer = styled.div`
  th {
    font-size: 12px;
    padding-right: 10px;
  }
  td {
    font-size: 12px;
    padding-right: 10px;
  }
  margin-top: 15px;
`;

const StyledRadioButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const StyledCheckBoxContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  min-height: 53px;
  max-height: 190px;
`;

const StyledDateOptions = styled.div`
  padding: auto;
  margin-left: -13px;
  div {
    max-width: 316px;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
`;

const CancelButton = styled.button`
  width: 245px;
  height: 34px;
  font-size: 14px;
  letter-spacing: 0.02px;
  color: #d4a518;
  text-transform: uppercase;
  opacity: 1;
  border: #d4a518 2px solid;
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  cursor: pointer;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  min-width: 445px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  b {
    align-self: self-start;
    margin: 20px 0px;
  }
`;

const SubtitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const StyledSearch = styled.div`
  display: flex;
  position: absolute;
  min-height: 53px;
  min-width: 100%;
  text-align: left;
  border: 2px solid #4e6ba7;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f1f1f1;
  padding-left: 9px;
  label {
    transform: translate(0, 3.5px) scale(0.75);
    font-family: ${({ theme }) => theme.font.montserratBold};
    color: #2a5299 !important;
  }
  button {
    margin-right: -37px;
    margin-top: -19px;
    height: 52px;
    border-radius: 0px;
    width: 45px;
  }
  .MuiAutocomplete-root {
    svg {
      visibility: hidden;
      z-index: 10;
    }
  }
`;

export const StyledIconContainer = styled.div`
  margin-right: 9px;
  align-self: center;
  margin-left: 3px;
  @keyframes arrow_animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  &.rotate {
    animation: arrow_animation 0.3s 0s;
    transform: rotate(180deg);
  }
`;

const StyledErrorMessage = styled.span`
  position: relative;
  left: 1px;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 12px;
  float: left;
  color: red;
`;

export default withTranslation()(NewFeedOrder);
