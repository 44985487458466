import { apiCore } from 'api/api';
import { AxiosResponse } from 'axios';
import { IMessage, IPassword } from 'model/account-allow-pages';
import { IAuths, IAuthsTherme, IConsults, IFunctions, INewConsult, ICreateConsult } from 'model/consult-authorization';
import { sendLog } from 'shared/util/api-utils';

export const consultAuthorizationApi = () => {
  const getMyAuths = (register: string): Promise<AxiosResponse<IAuths[]>> => {
    sendLog('Consultando autorizações existentes', `/Cooperado/GetAutorizacoesVigentes?CD_PESSOA=${register}`, JSON.stringify({}), 'get');
    return apiCore.get<IAuths[]>(`/Cooperado/GetAutorizacoesVigentes?CD_PESSOA=${register}`);
  };

  const getFunctionsOptions = (): Promise<AxiosResponse<IFunctions[]>> => {
    sendLog('Consultando opções de funcoes autorizaveis', `/Cooperado/GetFuncAutorizaveis`, JSON.stringify({}), 'get');
    return apiCore.get<IFunctions[]>(`/Cooperado/GetFuncAutorizaveis`);
  };

  const getConsults = (register: string): Promise<AxiosResponse<IConsults[]>> => {
    sendLog(
      'Consultando opções de copnsultores disponiveis',
      `/Cooperado/GetConsultoresCoops?CD_PESSOA=${register}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IConsults[]>(`/Cooperado/GetConsultoresCoops?CD_PESSOA=${register}`);
  };

  const getAcepetTherme = (): Promise<AxiosResponse<IAuthsTherme[]>> => {
    sendLog('Consultando opções de copnsultores disponiveis', `/Cooperado/GetTemoDeAceiteAutConsultor`, JSON.stringify({}), 'get');
    return apiCore.get<IAuthsTherme[]>(`/Cooperado/GetTemoDeAceiteAutConsultor`);
  };

  const authNewConsult = (newConsult: INewConsult): Promise<AxiosResponse<IMessage>> => {
    sendLog('Autorizando novo Consultor', `/Cooperado/CriarAutorizacaoConsultor`, JSON.stringify({}), 'post');
    return apiCore.post<IMessage>(`/Cooperado/CriarAutorizacaoConsultor`, newConsult);
  };

  const deleteAuth = (authId: string, cd_pessoa: string): Promise<AxiosResponse<IMessage>> => {
    sendLog('Deletar autorização', `/Cooperado/ExcluirAutorizacaoConsultor?ID=${authId}`, JSON.stringify({}), 'delete');
    return apiCore.delete<IMessage>(`/Cooperado/ExcluirAutorizacaoConsultor?ID=${authId}&CD_PESSOA=${cd_pessoa}`);
  };

  const recConPwd = (SamAccountName: string, cd_pessoa: string): Promise<AxiosResponse<IPassword>> => {
    return apiCore.get<IPassword>(`/ActiveDirectory/RecuperaSenhaConsultor?SamAccountName=${SamAccountName}&CD_PESSOA=${cd_pessoa}`);
  };

  const createConslt = (createCons: ICreateConsult): Promise<AxiosResponse<string>> => {
    return apiCore.post<string>(`/ActiveDirectory/criaConsultorCooperado`, createCons);
  };

  const deleteConslt = (SamAccountName: string, cd_pessoa: string): Promise<AxiosResponse<string>> => {
    return apiCore.get<string>(`/ActiveDirectory/deleteConsultorCooperado?SAMACCOUNTNAME=${SamAccountName}&CD_PESSOA=${cd_pessoa}`);
  };

  return {
    getMyAuths,
    getFunctionsOptions,
    getConsults,
    getAcepetTherme,
    authNewConsult,
    deleteAuth,
    createConslt,
    recConPwd,
    deleteConslt,
  };
};

export default consultAuthorizationApi();
