import contentfulApi from 'api/contentful-api';
import { AxiosError, AxiosResponse } from 'axios';
import { ContentfulFrisiaResult, ContentfulResult, IContentTypeCategories, IspeechBubble, IUnreadContent } from 'model/contentful-results';
import { IReturnMessage } from 'model/financial';

export const contentfulService = () => {
  const getContentfulNews = async () => {
    try {
      const result: AxiosResponse<ContentfulResult> = await contentfulApi.getAllVideos();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getNewsAnnouncements = async () => {
    try {
      const result: AxiosResponse<ContentfulFrisiaResult[]> = await contentfulApi.getNewsAnnouncements();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getContentInFocusLimited = async () => {
    try {
      const result: AxiosResponse<ContentfulResult> = await contentfulApi.getContentInFocusLimited();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAllVideos = async () => {
    try {
      const result: AxiosResponse<ContentfulResult> = await contentfulApi.getAllVideos();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAllAudios = async () => {
    try {
      const result: AxiosResponse<any> = await contentfulApi.getAllAudios();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getLimitedAudios = async () => {
    try {
      const result: AxiosResponse<any> = await contentfulApi.getLimitedAudios();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getbyId = async (id: string) => {
    try {
      const result: AxiosResponse<any> = await contentfulApi.getbyId(id);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getNotReadAnnouncements = async (register: string) => {
    try {
      const result: AxiosResponse<IUnreadContent[]> = await contentfulApi.getNotReadAnnouncements(register);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getReadAnnouncements = async (register: string) => {
    try {
      const result: AxiosResponse<IUnreadContent[]> = await contentfulApi.getReadAnnouncements(register);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const markAsRead = async (register: string, messageId: string) => {
    try {
      const result: AxiosResponse<IReturnMessage> = await contentfulApi.markAsRead(register, messageId);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const speechBubble = async (register: string) => {
    try {
      const result: AxiosResponse<IspeechBubble[]> = await contentfulApi.speechBubble(register);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAllCategories = async () => {
    try {
      const result: AxiosResponse<IContentTypeCategories> = await contentfulApi.getAllCategories();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getContentfulNews,
    getContentInFocusLimited,
    getAllVideos,
    getLimitedAudios,
    getAllAudios,
    getbyId,
    getNewsAnnouncements,
    getNotReadAnnouncements,
    getReadAnnouncements,
    markAsRead,
    speechBubble,
    getAllCategories,
  };
};

export default contentfulService();
