import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z } from 'config/constants';

export const convertDateTimeFromServer = (
  date: string | number | void | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | undefined
) => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = (
  date: string | number | void | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | undefined
) => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const dateList = (returnFromThePreviousMonth?: boolean) => {
  dayjs.extend(customParseFormat);
  let getCurrentDate = dayjs();

  if (returnFromThePreviousMonth) {
    getCurrentDate = getCurrentDate.subtract(1, 'month');
  }

  const currentDateConvertedToString = getCurrentDate.format('MM/YYYY');
  let newDate = dayjs()
    .subtract(returnFromThePreviousMonth ? 13 : 12, 'month')
    .format('MM/YYYY');
  const dates: string[] = [newDate];

  do {
    newDate = dayjs(newDate, 'MM/YYYY').add(1, 'month').format('MM/YYYY');
    dates.push(newDate);
  } while (newDate !== currentDateConvertedToString);

  // tslint:disable-next-line: no-assign-mutated-array
  return dates.reverse();
};

export const yearList = () => {
  dayjs.extend(customParseFormat);

  const currentDate = dayjs().format('YYYY');
  let newDate = '2015';
  const dates: string[] = [newDate];

  do {
    newDate = dayjs(newDate, 'YYYY').add(1, 'year').format('YYYY');
    dates.push(newDate);
  } while (newDate !== currentDate);

  // tslint:disable-next-line: no-assign-mutated-array
  return dates.reverse();
};

export const dateIsGreaterThanToday = (date: string | Date) => new Date(date).getTime() > new Date().getTime();
