import { AxiosResponse } from 'axios';
import { IGraphicinfo } from 'model/milk-collections';
import { sendLog } from 'shared/util/api-utils';
import { apiCore } from '../api';

export const milkGraphicApi = () => {
  const getGraphic = (): Promise<AxiosResponse<IGraphicinfo[]>> => {
    sendLog('Consultando cotação do Leite', `/PecuariaLeite/CotacaoLeite`, JSON.stringify({}), 'get');
    return apiCore.get<IGraphicinfo[]>(`/PecuariaLeite/CotacaoLeite`);
  };
  return {
    getGraphic,
  };
};

export default milkGraphicApi();
