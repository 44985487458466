import styled from 'styled-components';

interface IStyledProps {
  verticalPosition?: string;
}

const StyledErrorMessage = styled.span<IStyledProps>`
  position: absolute;
  bottom: ${({ verticalPosition }) => verticalPosition ?? 0};
  left: 1px;

  font-family: ${({ theme }) => theme.font.montserratRegular};
  font-size: 12px;
  color: red;
`;

export default StyledErrorMessage;
