import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import { IAuthRequest, IBalance, ICurrency, IDataProductsSalesAuthorization } from 'model/agricultural-sales-authorization';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import salesAuthorizationService from 'services/agricultural/sales-authorization--service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';
import GenericInputToTable from 'components/GenericInputToTable';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';

interface IForm {
  harvest: string;
  product: string;
  paymentDate: string;
  currency: string;
  observation: string;
  amount: number;
  stocksWithAmount: { [x: number]: IAuthRequest };
}

const SalesAuthorization = ({ t }: WithTranslation) => {
  const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
  const history = useHistory();
  const [options, setOptions] = useState<IDataProductsSalesAuthorization[]>([]);
  const [harvestOptions, setHarvestOptions] = useState<string[]>([]);
  const [chosenHarvest, setChosenHarvest] = useState<string | null>(null);

  const [productOptions, setProductOptions] = useState<string[]>([]);
  const [chosenProduct, setChosenProduct] = useState<string | null>(null);

  const [authTypeOptions, setAuthTypeOptions] = useState<string[]>([]);
  const [authType, setAuthType] = useState<string | null>(null);

  const [currencyOptions, setCurrencyOptions] = useState<ICurrency[]>([]);
  const [chosenCurrency, setChosenCurrency] = useState<string>();

  const [desiredPaymentDateOptions, setDesiredPaymentDateOptions] = useState<string[]>([]);
  const [chosenPaymentDate, setChosenPaymentDate] = useState<string>();

  const [available, setAvailable] = useState<number>();
  const [dspackage, setPackage] = useState<string>();

  const [balances, setBalances] = useState<IBalance[]>([]);
  const [stocksWithAmount, setStocksWithAmount] = useState<{ [x: number]: IAuthRequest } | null>(null);

  const [min, setMin] = useState<number>(1);
  const [max, setMax] = useState<number>(10000);

  const [amount, setAmount] = useState<string>();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [totalBalanceExceeded, setTotalBalanceExceeded] = useState(false);
  const [isResetInputValue, setIsResetInputValue] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getProducts = async () => {
    salesAuthorizationService.getProductsOptions(getUserRegistration).then(response => {
      setHarvestOptions(response.map(item => item.CD_SAFRA_TEXT));
      setOptions(response);
    });
  };

  const getDesiredPaymentDates = async (cd_safra: string, tp_autorizacao) => {
    salesAuthorizationService.getMonths2(cd_safra, tp_autorizacao).then(response => {
      const months = response.map(item => item.MES_ANO);
      setDesiredPaymentDateOptions(months);
      setChosenPaymentDate(months[0]);
    });
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    const element = document.getElementById('frmm');
    if (element) {
      element.scrollIntoView({ block: 'start' });
    }
  }, []);

  useEffect(() => {
    if (chosenHarvest) {
      setStocksWithAmount(null);
      setAuthTypeOptions([]);
      setDesiredPaymentDateOptions([]);
      const products = options.find(item => item.CD_SAFRA_TEXT === chosenHarvest)?.PRODUTOS?.map(item => item.DS_PRODUTO) ?? [];
      const cropValue = options.find(item => item.CD_SAFRA_TEXT === chosenHarvest)?.CD_SAFRA;
      setProductOptions(products);
    }
    setIsResetInputValue(false);
  }, [chosenHarvest]);

  useEffect(() => {
    setStocksWithAmount(null);
    if (balances?.length > 0) {
      clenBalanceFields(balances);
    }
    if (chosenProduct) {
      const balanceOptions =
        options.find(item => item.CD_SAFRA_TEXT === chosenHarvest)?.PRODUTOS?.find(item => item.DS_PRODUTO === chosenProduct)?.SALDOS ?? [];
      setBalances(balanceOptions);

      const typeOptions =
        balanceOptions.map(item => item.TIPO_AUTORIZACAO).filter((value, index, self) => self.indexOf(value) === index) ?? [];
      setAuthTypeOptions(typeOptions);

      const ava = options
        .find(item => item.CD_SAFRA_TEXT === chosenHarvest)
        ?.PRODUTOS?.find(item => item.DS_PRODUTO === chosenProduct)?.DISP;
      setAvailable(ava);

      const currencies =
        options.find(item => item.CD_SAFRA_TEXT === chosenHarvest)?.PRODUTOS?.find(item => item.DS_PRODUTO === chosenProduct)?.MOEDAS ?? [];
      setCurrencyOptions(currencies);

      const packageType = options
        .find(item => item.CD_SAFRA_TEXT === chosenHarvest)
        ?.PRODUTOS?.find(item => item.DS_PRODUTO === chosenProduct)?.DS_EMBALAGEM;
      setPackage(packageType);
    }
    setIsResetInputValue(false);
  }, [chosenProduct]);

  useEffect(() => {
    if (chosenCurrency && currencyOptions) {
      setMax(parseFloat(currencyOptions.find(item => item.NM_MOEDA === chosenCurrency)?.VL_PRECO_MAXIMO ?? '10000'));
      setMin(parseFloat(currencyOptions.find(item => item.NM_MOEDA === chosenCurrency)?.VL_PRECO_MINIMO ?? '1'));
    }
  }, [chosenCurrency, currencyOptions]);

  const clenBalanceFields = (selectedBalances: IBalance[]) => {
    Object.values(selectedBalances).forEach(value => {
      const input = document.getElementById(`amount-${value.CD_FILIAL}-${value.CD_TIPO_ROMANEIO}`) as HTMLInputElement | null;
      if (input) {
        input.value = '';
      }
    });
  };

  const schema = yup.object({
    harvest: yup.string().test('test-harvest', t('global.errorMessage.required'), () => chosenHarvest != null),
    product: yup.string().test('test-product', t('global.errorMessage.required'), () => chosenProduct != null),
    paymentDate: yup.string().test('test-paymentDate', t('global.errorMessage.required'), () => chosenPaymentDate != null),
    currency: yup.string().test('test-currency', t('global.errorMessage.required'), () => chosenCurrency != null),
    stocksWithAmount: yup.object().test('test-stocksWithAmount', t('global.errorMessage.required'), () => stocksWithAmount != null),
    observation: yup.string(),
    amount: yup
      .string()
      .test('test-amountExist', t('global.errorMessage.required'), () => amount != null)
      .test(
        'test-amountBeteweenValues',
        `${t('salesAuth.amountBeteweenValues')} ${currencyOptions.find(item => item.NM_MOEDA === chosenCurrency)?.SG_MOEDA} ${min} e ${
          currencyOptions.find(item => item.NM_MOEDA === chosenCurrency)?.SG_MOEDA
        } ${max}`,
        () => {
          if (amount && min && max) {
            if (parseFloat(amount) > max || parseFloat(amount) < min) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        }
      ),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  const handleSubmit = async data => {
    if (totalBalanceExceeded) {
      return;
    }
    const CDcurrency = currencyOptions.find(item => item.NM_MOEDA === chosenCurrency)?.CD_MOEDA ?? '1';
    const DateForRequest = chosenPaymentDate;
    if (Object.values(stocksWithAmount ?? []).length > 0 && stocksWithAmount && amount && openTransferModal) {
      setDisableButton(true);
      setIsLoading(true);
      const arrayOfStocks = Object.values(stocksWithAmount).filter(item => item.QT_AUTORIZADO && item.QT_AUTORIZADO > 0);
      await salesAuthorizationService
        .sendSalesAuthorization(
          arrayOfStocks.map(item => {
            return {
              ...item,
              CD_SAFRA: options.find(item => item.CD_SAFRA_TEXT === chosenHarvest)?.CD_SAFRA,
              CD_MOEDA: CDcurrency,
              VL_PRECO: parseFloat(amount),
              OBSERVACAO: data.observation,
              DT_PAGAMENTO_PRETENDIDO: DateForRequest,
            };
          })
        )
        .finally(() => {
          setDisableButton(false);
          setOpenTransferModal(false);
          setIsLoading(false);
          history.push('/agricola');
        });
    } else if (Object.values(stocksWithAmount ?? []).length > 0) {
      setOpenTransferModal(true);
    } else {
      methods.setError('stocksWithAmount', { type: 'focus' }, { shouldFocus: true });
    }
  };

  const verifyProductInHarvest = (ds_product: string) => {
    const products = options.find(item => item.CD_SAFRA_TEXT === chosenHarvest)?.PRODUTOS?.map(item => item.DS_PRODUTO) ?? [];

    if (products.includes(ds_product)) {
      setChosenProduct(ds_product);
    } else {
      setChosenProduct(null);
    }
  };

  const handleChangeAuthAmount = (inputValue: number, item: IBalance) => {
    if (Number.isNaN(inputValue)) {
      return;
    }
    if (balances) {
      let total = 0;
      Object.values(balances).forEach(value => {
        const input = document.getElementById(`amount-${value.CD_FILIAL}-${value.CD_TIPO_ROMANEIO}`) as HTMLInputElement | null;
        if (input?.value) {
          total = total + (Number(input?.value) ?? 0);
        }
      });
      if (total > (available ?? 0)) {
        setTotalBalanceExceeded(true);
      } else {
        setTotalBalanceExceeded(false);
      }
      if (inputValue > 0) {
        setStocksWithAmount({
          ...stocksWithAmount,
          [item.CD_FILIAL]: {
            CD_PESSOA: item.CD_PESSOA,
            CD_PRODUTO: item.CD_PRODUTO,
            CD_EMPRESA: item.CD_EMPRESA,
            CD_PADRAO: item.CD_PADRAO,
            CD_TIPAGEM: item.CD_TIPAGEM,
            CD_CULTURA: item.CD_CULTURA,
            QT_EMBALAGEM: item.QT_EMBALAGEM,
            CD_FILIAL: item.CD_FILIAL,
            CD_LOCAL_ARMAZENAGEM: item.CD_LOCAL_ARMAZENAGEM,
            CD_MUNICIPIO: item.CD_MUNICIPIO,
            NM_REDUZIDO: item.NM_REDUZIDO,
            CD_TIPO_ROMANEIO: item.CD_TIPO_ROMANEIO,
            DS_TIPO_ROMANEIO: item.DS_TIPO_ROMANEIO,
            QT_SALDO: item.QT_SALDO,
            TIPO_AUTORIZACAO: item.TIPO_AUTORIZACAO,
            QT_AUTORIZADO: inputValue,
          } as IAuthRequest,
        });
      } else {
        delete stocksWithAmount?.[item.CD_FILIAL];
      }
    }
  };

  const handleChangeAuthAmountAvailabe = (inputValue: number, item: IBalance) => {
    if (Number.isNaN(inputValue)) {
      return;
    }
    if (item.QT_SALDO < 0) {
      return setTotalBalanceExceeded(false);
    }
    if (inputValue > item.QT_SALDO) {
      setTotalBalanceExceeded(true);
    } else {
      setTotalBalanceExceeded(false);
    }
    if (inputValue > 0) {
      setStocksWithAmount({
        ...stocksWithAmount,
        [item.CD_FILIAL]: {
          CD_PESSOA: item.CD_PESSOA,
          CD_PRODUTO: item.CD_PRODUTO,
          CD_EMPRESA: item.CD_EMPRESA,
          CD_PADRAO: item.CD_PADRAO,
          CD_TIPAGEM: item.CD_TIPAGEM,
          CD_CULTURA: item.CD_CULTURA,
          QT_EMBALAGEM: item.QT_EMBALAGEM,
          CD_FILIAL: item.CD_FILIAL,
          CD_LOCAL_ARMAZENAGEM: item.CD_LOCAL_ARMAZENAGEM,
          CD_MUNICIPIO: item.CD_MUNICIPIO,
          NM_REDUZIDO: item.NM_REDUZIDO,
          CD_TIPO_ROMANEIO: item.CD_TIPO_ROMANEIO,
          DS_TIPO_ROMANEIO: item.DS_TIPO_ROMANEIO,
          QT_SALDO: item.QT_SALDO,
          TIPO_AUTORIZACAO: item.TIPO_AUTORIZACAO,
          QT_AUTORIZADO: inputValue,
        } as IAuthRequest,
      });
    } else {
      delete stocksWithAmount?.[item.CD_FILIAL];
    }
  };

  useEffect(() => {
    if (authTypeOptions) {
      setAuthType(authTypeOptions[0]);
    }
  }, [authTypeOptions]);

  useEffect(() => {
    if (isResetInputValue) {
      setAmount('');
      methods.clearErrors('amount');
    }
  }, [isResetInputValue]);

  useEffect(() => {
    if (authType && chosenHarvest) {
      getDesiredPaymentDates(chosenHarvest.replace('/', ''), authType);
    }
  }, [authType]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} id="frmm">
          {openTransferModal && (
            <ModalContainer>
              <ModalShadow onClick={() => setOpenTransferModal(false)} />
              <CardModalContainer>
                <div style={{ padding: '10px' }}>
                  <TitleStyled>{t('salesAuth.confirmAuth', { AuthType: authType?.toUpperCase() })}</TitleStyled>
                  <SubtitleStyled>{t('salesAuth.subtitle')}</SubtitleStyled>
                  <div style={{ padding: '10px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <StyledTableSummary>
                      <tbody>
                        <tr>
                          <td>{t('agricultural.crop')}:</td>
                          <td>{chosenHarvest}</td>
                        </tr>
                        <tr>
                          <td>{t('agricultural.product')}:</td>
                          <td>{chosenProduct}</td>
                        </tr>
                        <tr>
                          <td>{t('agricultural.coin')}:</td>
                          <td>{chosenCurrency}</td>
                        </tr>
                        <tr>
                          <td>{t('salesAuth.price')}:</td>
                          <td>
                            {chosenCurrency == 'REAL' ? (
                              <>
                                {Number(amount ?? 0)
                                  ?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                  .toString()}
                              </>
                            ) : (
                              <>
                                {Number(amount ?? 0)
                                  ?.toLocaleString('pt-BR', { style: 'currency', currency: 'USD' })
                                  .toString()}
                              </>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </StyledTableSummary>
                    <StyledTableSummary>
                      <thead>
                        <tr>
                          <th>{t('salesAuth.branch')}</th>
                          <th>{t('agricultural.quantity')} - KG</th>
                        </tr>
                      </thead>
                      {stocksWithAmount && (
                        <tbody>
                          {Object.values(stocksWithAmount)?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.NM_REDUZIDO}</td>
                              <td>{item.QT_AUTORIZADO?.toLocaleString('pt-BR')}</td>
                            </tr>
                          ))}
                          <tr>
                            <td>
                              <b>{t('salesAuth.total')}:</b>
                            </td>
                            <td>
                              <b>
                                {Object.values(stocksWithAmount)
                                  ?.filter(item => item.QT_AUTORIZADO && item.QT_AUTORIZADO > 0)
                                  ?.map(item => item.QT_AUTORIZADO)
                                  .reduce((acc, value) => {
                                    let sum = (acc ?? 0) + (value ?? 0);
                                    return sum;
                                  }, 0)
                                  ?.toLocaleString('pt-BR')}
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </StyledTableSummary>
                  </div>
                  <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <CancelButton
                      onClick={() => {
                        setOpenTransferModal(false);
                      }}
                      style={{ width: '145px', height: '40px' }}
                    >
                      {t('financial.cancel')}
                    </CancelButton>
                    {isLoading ? (
                      <div style={{ height: '10px', width: '10px', marginRight: '50px' }}>
                        <LoadingContainerScreen isLoading={isLoading}>
                          <></>
                        </LoadingContainerScreen>
                      </div>
                    ) : (
                      <>
                        <StyledButton
                          color="frisiaPrimary"
                          disabled={disableButton}
                          type="submit"
                          style={{ color: 'white', minWidth: '145px', height: '42px' }}
                        >
                          <span>{t('financial.confirm')}</span>
                        </StyledButton>
                      </>
                    )}
                  </div>
                </div>
              </CardModalContainer>
            </ModalContainer>
          )}
          <StyledPageTitleContainer margin={'10px 0 25px 0'} style={{ display: 'flex' }}>
            <MainPageTitle fontSize={20} text={t('agricultural.salesAuthorization')} />
            <StyledButton
              style={{ height: '42px', position: 'relative' }}
              color="orange"
              type="button"
              onClick={() => history.push('/agricola/consulta-autorizacao-de-vendas')}
            >
              <span>{t('agricultural.history')}</span>
            </StyledButton>
          </StyledPageTitleContainer>

          <StyledFiltersContainer>
            <StyledFilterContainer filterWidth={180}>
              <GenericDropdown
                label={t('agricultural.harvest')}
                placeholder={t('agricultural.selectHarvest')}
                listShowDropdown={harvestOptions}
                getSelectedValue={value => {
                  setChosenHarvest(value);
                  setIsResetInputValue(true);
                }}
                errorText={methods.formState.errors.harvest?.message}
              />
            </StyledFilterContainer>
            <StyledFilterContainer filterWidth={550}>
              <GenericDropdown
                label={t('agricultural.product')}
                placeholder={t('agricultural.selectProduct')}
                listShowDropdown={productOptions}
                getSelectedValue={value => {
                  verifyProductInHarvest(value);
                  setIsResetInputValue(true);
                }}
                errorText={methods.formState.errors.product?.message}
                reset={chosenHarvest}
              />
            </StyledFilterContainer>
            <StyledFilterContainer filterWidth={300}>
              <GenericDropdown
                label={t('salesAuth.authType')}
                placeholder={t('salesAuth.select')}
                listShowDropdown={authTypeOptions}
                getSelectedValue={value => setAuthType(value)}
                reset={chosenHarvest}
                defaultValue={authTypeOptions[0]}
              />
            </StyledFilterContainer>
          </StyledFiltersContainer>

          <WarningContainer>{t('salesAuth.warningSalesAuth')}</WarningContainer>

          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around' }}>
            <div>
              {chosenProduct && authType && (
                <>
                  <MainPageTitle fontSize={14} text={t('agricultural.product')} />
                  <StyledTable style={{ width: '390px' }}>
                    <tbody>
                      <tr>
                        <td>{t('agricultural.cropValue')}:</td>
                        <td>{chosenHarvest}</td>
                      </tr>
                      <tr>
                        <td>{t('agricultural.product')}:</td>
                        <td>{chosenProduct}</td>
                      </tr>
                      <tr>
                        <td>{t('salesAuth.available')}:</td>
                        <td>{available?.toLocaleString('pt-BR')} KG</td>
                      </tr>
                    </tbody>
                  </StyledTable>
                </>
              )}
              <PriceContainer>
                <PriceTitle>{t('salesAuth.values')}:</PriceTitle>
                <GenericDropdown
                  label={t('agricultural.coin')}
                  placeholder={t('agricultural.selectHere')}
                  listShowDropdown={currencyOptions.map(item => item.NM_MOEDA)}
                  getSelectedValue={value => setChosenCurrency(value)}
                  errorText={methods.formState.errors.currency?.message}
                  isResetValue={isResetInputValue}
                />
                <div style={{ margin: '18px 0px 0px 0px' }}>
                  <GenericDropdown
                    label={t('salesAuth.paymentDay')}
                    placeholder={t('agricultural.selectHere')}
                    defaultValue={desiredPaymentDateOptions[0]}
                    listShowDropdown={desiredPaymentDateOptions}
                    getSelectedValue={value => setChosenPaymentDate(value)}
                    errorText={methods.formState.errors.paymentDate?.message}
                    reset={chosenHarvest}
                  />
                </div>
                <GenericInputToTable
                  inputName="amount"
                  label={dspackage == undefined ? 'PREÇO' : 'PREÇO POR ' + dspackage}
                  placeholder={
                    currencyOptions.find(item => item.NM_MOEDA === chosenCurrency)?.CD_MOEDA == '1'
                      ? t('agricultural.currencyBRL')
                      : t('agricultural.currencyUSD')
                  }
                  type={'number'}
                  onChange={e => setAmount(e.target.value)}
                  isResetValue={isResetInputValue}
                />
                <StyledErrorMessage style={{ position: 'initial' }}>{methods.formState.errors.amount?.message}</StyledErrorMessage>
                <div style={{ margin: '10px 0px' }}>{t('salesAuth.observation')} :</div>
                <StyledTextArea {...methods.register('observation')} />
              </PriceContainer>
            </div>

            <CardContainer>
              {balances.length > 0 && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <MainPageTitle fontSize={14} style={{ width: '322px' }} text={t('agricultural.stocks')} />
                </div>
              )}
              {authType &&
                chosenProduct &&
                balances.map((item, index) => (
                  <StyledTable key={index}>
                    <tbody>
                      <tr>
                        <td>{t('salesAuth.branch')}:</td>
                        <td>
                          {item.CD_FILIAL} - {item.NM_REDUZIDO}
                        </td>
                      </tr>
                      <tr>
                        <td>{t('salesAuth.modality')}:</td>
                        <td>{item.DS_TIPO_ROMANEIO}</td>
                      </tr>
                      <tr>
                        <td>{t('salesAuth.balance')}:</td>
                        <td style={item.QT_SALDO <= 0 ? { color: '#D96767' } : {}}>{item.QT_SALDO?.toLocaleString('pt-BR')} KG</td>
                      </tr>
                      <tr>
                        <td></td>
                        <StyledButton
                          style={{
                            height: '55px',
                            position: 'relative',
                            marginLeft: '20px',
                            marginRight: '20px',
                            fontSize: '15px',
                            width: '150px',
                            lineHeight: '20px',
                          }}
                          color="frisiaSecondary"
                          type="button"
                          onClick={() => {
                            if (item.QT_SALDO < 0 && authType !== 'FUTURA') return;
                            var elemento = document.getElementById(`amount-${item.CD_FILIAL}-${item.CD_TIPO_ROMANEIO}`) as HTMLInputElement;
                            if (elemento) {
                              elemento.value = authType === 'FUTURA' ? available?.toString() ?? '' : item.QT_SALDO.toString();
                            }
                            if (authType === 'FUTURA') {
                              handleChangeAuthAmount(Number(available), item);
                            } else {
                              handleChangeAuthAmountAvailabe(Number(item.QT_SALDO), item);
                            }
                          }}
                        >
                          <span>{t('salesAuth.totalAuth')}</span>
                        </StyledButton>
                      </tr>
                      <tr>
                        <td>{t('agricultural.quantity')} - KG:</td>
                        <td>
                          <StyledInputInTable
                            type="number"
                            id={`amount-${item.CD_FILIAL}-${item.CD_TIPO_ROMANEIO}`}
                            max={authType === 'FUTURA' ? available : item.QT_SALDO}
                            onChange={e => {
                              if (authType === 'FUTURA') {
                                handleChangeAuthAmount(Number(e.target.value), item);
                              } else {
                                handleChangeAuthAmountAvailabe(Number(e.target.value), item);
                              }
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </StyledTable>
                ))}
              {totalBalanceExceeded ? (
                <StyledErrorMessage
                  style={{
                    position: 'initial',
                    width: '100%',
                  }}
                >
                  {t('salesAuth.notBalanceAvailable')}
                </StyledErrorMessage>
              ) : (
                <></>
              )}
              {methods.formState.errors.stocksWithAmount && (
                <StyledErrorMessage style={{ position: 'initial', width: '100%' }}>{t('salesAuth.reportQuantity')}</StyledErrorMessage>
              )}
              <div style={{ width: '100%' }}>
                <StyledButton color="frisiaPrimary" style={{ maxWidth: '150px', maxHeight: '50px' }} type="submit">
                  <span>{t('agricultural.confirm')}</span>
                </StyledButton>
              </div>
            </CardContainer>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export const StyledTable = styled.table`
  text-align: left;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 20px 0px;
  margin-right: 20px;
  padding: 6px;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 300px;
  font-size: 16px;

  text-align: right;
  font-family: ${({ theme }) => theme.font.montserratBold};
  letter-spacing: 0px;
  color: #4e6ba7;
  opacity: 1;
  td,
  th {
    height: 41px;
    padding: 12px;
    text-align: center;
    padding-left: 23px;
  }
  td:first-child {
    text-align: left;
    font: normal normal normal 16px/19px Montserrat;
    font-family: ${({ theme }) => theme.font.montserratRegular};
    letter-spacing: 0.01px;
    color: #404040;
    opacity: 1;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  tr:hover {
    background-color: #ddd;
  }
  svg {
    vertical-align: initial;
  }
  thead > tr {
    color: #2a5299;
    font-size: 17px;
    text-transform: uppercase;
  }
`;

export const StyledTableSummary = styled.table`
  text-align: left;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 20px 0px;
  margin-right: 20px;
  padding: 6px;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  font-size: 16px;

  text-align: right;
  letter-spacing: 0px;
  opacity: 1;
  td,
  th {
    height: 41px;
    padding: 12px;
    text-align: center;
    padding-left: 23px;
  }
  tr:hover {
    background-color: #ddd;
  }
  svg {
    vertical-align: initial;
  }
  thead > tr {
    color: #2a5299;
    font-size: 17px;
    text-transform: uppercase;
  }
`;

const PriceContainer = styled.div`
  width: 390px;
  height: 500px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: start;
`;

const PriceTitle = styled.div`
  font-family: ${({ theme }) => theme.font.montserratBold};
  letter-spacing: 0.01px;
  color: #2a5299;
  opacity: 1;
  margin-bottom: 10px;
`;

const CardContainer = styled.div`
  justify-self: stretch;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  justify-content: space-evenly;
`;

const CardModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  min-width: 445px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  b {
    align-self: self-start;
    margin: 20px 0px;
  }
`;

const StyledTextArea = styled.textarea`
  width: 350px;
  height: 186px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
`;

const StyledInputInTable = styled.input`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 4px;
  opacity: 1;
  /* border: 1px solid #5A5A5A; */
  border-radius: 4px;
  opacity: 1;
  width: 153px;
  height: 42px;
  background: none;
  text-align: center;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  justify-content: space-between;
`;

const WarningContainer = styled.div`
  margin: 10px 20px 20px 20px;
  padding: 5px;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
`;

const ModalContainer = styled.div`
  background-color: #2a5299;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
`;

const TitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const SubtitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const CancelButton = styled.button`
  width: 245px;
  height: 34px;
  font-size: 14px;
  letter-spacing: 0.02px;
  color: #d4a518;
  text-transform: uppercase;
  opacity: 1;
  border: #d4a518 2px solid;
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  cursor: pointer;
`;

const MyStyledInput = styled.div`
  input {
    height: 53px;
    border: 2px solid #4e6ba7;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f1f1f1;
    padding: 5px 10px 0 10px;
    font-family: ${({ theme }) => theme.font.montserratRegular};
    height: 53px;
    width: 100%;
    fieldset {
      border: 2px solid #4e6ba7 !important;
    }
    label {
      z-index: 0;
    }
  }
`;

const StyledLabel = styled.label`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
`;

const StyledContainer = styled.div`
  position: relative;
  height: 70px;
  margin-top: -5px;
`;

export default withTranslation()(SalesAuthorization);
