import CalendarApi from 'api/calendar-oracle-api';
import { AxiosResponse } from 'axios';
import { IAccountResponse, ICalendarResponse } from 'model/calendar';
import HttpStatus from 'model/enums/http-status';

export const calendarService = () => {
  const getActivitiesByPartyId = async (PartyId: number): Promise<ICalendarResponse> => {
    try {
      const result: AxiosResponse<ICalendarResponse> = await CalendarApi.getActivitiesByPartyId(PartyId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getAccountBycdAndName = async (cd_pessoa: string, nm_pessoa: string): Promise<IAccountResponse> => {
    try {
      const result: AxiosResponse<IAccountResponse> = await CalendarApi.getAccountBycdAndName(cd_pessoa, nm_pessoa);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getGeneralEventsActivities = async (): Promise<ICalendarResponse> => {
    try {
      const result: AxiosResponse<ICalendarResponse> = await CalendarApi.getGeneralEventsActivities();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getActivitiesByPartyId,
    getAccountBycdAndName,
    getGeneralEventsActivities,
  };
};

export default calendarService();
