import LinkedCards from 'components/LinkedCards';
import QuoteComponentsTable from 'components/QuoteComponentsTable';
import CategoryNames from 'model/enums/categorys-names';
import { Line } from 'react-chartjs-2';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getCardsByCategory } from 'shared/util/card';
import styled, { useTheme } from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import MainPageTitle from '../../../components/MainPageTitle';
import { ChartOptions } from 'chart.js';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import milkGraphicService from 'services/cattle/milk-graphic-service';
import { ReactComponent as GraphicIconSvg } from '../../../images/frisia/icons/white-graphic-line-icon.svg';

const Cattle = ({ t }: WithTranslation) => {
  const theme = useTheme();
  const [graphicInfo, setGraphicInfo] = useState<any[]>([]);

  const getGraphicInfo = async () => {
    await milkGraphicService.getGraphic().then(response => {
      setGraphicInfo(
        response
          .filter((item, index) => index < 6)
          .sort((a, b) => {
            return new Date(a.DT_VIGENCIA).getTime() - new Date(b.DT_VIGENCIA).getTime();
          })
      );
    });
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: { display: false },
      datalabels: {
        align: 'top',
        formatter: function (value, context) {
          return parseFloat(value).toFixed(2).toString().replace('.', ',');
        },
      },
      tooltip: {
        backgroundColor: '#fff',
        titleColor: '#000',
        bodyColor: '#000',
        multiKeyBackground: theme.color.frisiaPrimary,
        caretSize: 5,
        borderWidth: 1,
        borderColor: theme.color.frisiaPrimary,
        usePointStyle: true,
        yAlign: 'bottom',
        padding: 10,
        boxPadding: 5,
        boxHeight: 10,
        callbacks: {
          label: function (tooltipItems) {
            return 'R$ ' + tooltipItems.raw;
          },
        },
      },
    },
    layout: {
      padding: {
        right: 10,
        top: 20,
      },
    },
    elements: {
      line: {
        tension: 0.6,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const labels = graphicInfo.map(item => dayjs(item.DT_VIGENCIA).format('MM/YY'));

  const data = {
    labels,
    datasets: [
      {
        data: graphicInfo.map(item => item.VL_PRECO_BASE.toFixed(2)),
        borderColor: 'rgba(11, 05, 134)',
        backgroundColor: 'rgba(11, 05, 134)',
      },
    ],
  };

  useEffect(() => {
    getGraphicInfo();
  }, []);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('livestock.livestockCattle')} />
      </StyledPageTitleContainer>

      <StyledTablesContainer>
        <QuoteComponentsTable />
        <StyledGraphicContainer>
          <StyledHeader>
            <GraphicIconSvg style={{ margin: '0 9px 0 23px' }} />
            {t('quotes.milk').toUpperCase()} - {t('livestock.frisiaMilk').toUpperCase()} (R$)
          </StyledHeader>
          <br />
          <div style={{ margin: '12px', marginTop: '-12px' }}>
            <Line options={options} data={data} />
          </div>
        </StyledGraphicContainer>
      </StyledTablesContainer>

      <StyledCardsContainer>
        <LinkedCards listCardNames={getCardsByCategory(CategoryNames.CATTLE)} spacingBetweenCards={37} />
      </StyledCardsContainer>
    </>
  );
};

const StyledTablesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 37px;
  align-items: start;

  @media (max-width: 1380.98px) {
    flex-direction: column-reverse;
  }
`;

const StyledGraphicContainer = styled.div`
  min-width: 500px;
  width: 50%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.poppinsSemiBold};
  letter-spacing: 0.1px;
  color: #5a607f;
  opacity: 1;
`;

const StyledCardsContainer = styled.div`
  max-width: 1130px;
  margin: 37px auto 0 auto;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  background-color: ${({ theme }) => theme.color.frisiaPrimary};
  border-radius: 21px 21px 0 0;
  font-family: ${({ theme }) => theme.font.montserratSemiBold};
  color: #ffffff;
  font-size: 14px;
`;

export default withTranslation()(Cattle);
