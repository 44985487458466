import { HttpRequestStatus } from 'model/enums/http-request-status';

export type ApiResponse = Record<string, any>;

export enum RegisterActionTypes {
  REGISTER_REQUEST = '@@register/REGISTER_REQUEST',
  REGISTER_SUCCESS = '@@register/REGISTER_SUCCESS',
  REGISTER_ERROR = '@@register/REGISTER_ERROR',
}

export interface RegisterState {
  readonly registerStatus: HttpRequestStatus;
  readonly error?: string;
}
