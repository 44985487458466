import { AxiosResponse } from 'axios';
import { ContentfulFrisiaResult, ContentfulResult, IContentTypeCategories, IspeechBubble, IUnreadContent } from 'model/contentful-results';
import { IReturnMessage } from 'model/financial';
import { sendLog } from 'shared/util/api-utils';
import { apiContentful, apiContentfulFrisia, apiCore } from './api';

export const contentfulApi = () => {
  const getContentHomeBanner = (): Promise<AxiosResponse<ContentfulResult>> => {
    sendLog(
      'Consultando dados de banner da dashboard no contentful',
      'entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=homeBanner&limit=6',
      JSON.stringify([]),
      'get'
    );
    return apiContentful.get<ContentfulResult>(
      'entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=homeBanner&limit=6'
    );
  };
  const getContentInFocusLimited = (): Promise<AxiosResponse<ContentfulResult>> => {
    sendLog(
      'Consultando dados de inFocus no contentful',
      `entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=inFocus&limit=6`,
      JSON.stringify([]),
      'get'
    );
    return apiContentful.get<ContentfulResult>(
      'entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=inFocus&limit=6'
    );
  };
  const getContentInFocus = (): Promise<AxiosResponse<ContentfulResult>> => {
    sendLog(
      'Consultando dados de inFocus no contentful',
      `entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=inFocus`,
      JSON.stringify([]),
      'get'
    );
    return apiContentful.get<ContentfulResult>('entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=inFocus');
  };
  const getAllVideos = (): Promise<AxiosResponse<ContentfulResult>> => {
    sendLog(
      'Consultando videos no contentful',
      `entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=playlist_videos`,
      JSON.stringify([]),
      'get'
    );
    return apiContentful.get<ContentfulResult>(
      'entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=playlist_videos'
    );
  };
  const getAllAudios = (): Promise<AxiosResponse<ContentfulResult>> => {
    sendLog(
      'Consultando audios do contentiful',
      `entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=podcast`,
      JSON.stringify([]),
      'get'
    );
    return apiContentful.get<ContentfulResult>('entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=podcast');
  };
  const getLimitedAudios = (): Promise<AxiosResponse<ContentfulResult>> => {
    sendLog(
      'Consultando audios do contentiful',
      `entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=podcastv`,
      JSON.stringify([]),
      'get'
    );
    return apiContentful.get<ContentfulResult>(
      'entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=podcast&limit=3'
    );
  };

  const getbyId = (id: string): Promise<AxiosResponse<ContentfulResult>> => {
    sendLog(
      'Consulta de conteudo do contentiful por id',
      `entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&sys.id=${id}`,
      JSON.stringify([]),
      'get'
    );
    return apiContentful.get<ContentfulResult>(`entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&sys.id=${id}`);
  };

  const getNewsAnnouncements = (): Promise<AxiosResponse<ContentfulFrisiaResult[]>> => {
    sendLog('Consultando comunicados Frisia', `Communications/takeRecordsFor/10/10`, JSON.stringify([]), 'get');
    return apiContentfulFrisia.get<ContentfulFrisiaResult[]>(`Communications/takeRecordsFor/10/10`);
  };

  const getNotReadAnnouncements = (register: string): Promise<AxiosResponse<IUnreadContent[]>> => {
    sendLog('Consultando comunicados não lidos', `/Contentful/GetNaoLidas?CD_PESSOA=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<IUnreadContent[]>(`/Contentful/GetNaoLidas?CD_PESSOA=${register}`);
  };

  const getReadAnnouncements = (register: string): Promise<AxiosResponse<IUnreadContent[]>> => {
    sendLog('Consultando comunicados já lidos', `/Contentful/GetJaLidas?CD_PESSOA=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<IUnreadContent[]>(`/Contentful/GetJaLidas?CD_PESSOA=${register}`);
  };

  const markAsRead = (register: string, messageId: string): Promise<AxiosResponse<IReturnMessage>> => {
    sendLog('Marcar como lido', `/Contentful/MarkAsRead?CD_PESSOA=${register}&ID_MESSAGE=${messageId}`, JSON.stringify([]), 'post');
    return apiCore.post<IReturnMessage>(`/Contentful/MarkAsRead?CD_PESSOA=${register}&ID_MESSAGE=${messageId}`);
  };

  const speechBubble = (register: string): Promise<AxiosResponse<IspeechBubble[]>> => {
    sendLog('Consultando alertas', `/Cooperado/GetSinoAlertas?CD_PESSOA=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<IspeechBubble[]>(`/Cooperado/GetSinoAlertas?CD_PESSOA=${register}`);
  };

  const getAllCategories = (): Promise<AxiosResponse<IContentTypeCategories>> => {
    sendLog(
      'Consultando categorias existentes no contentful',
      `/entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=categories`,
      JSON.stringify([]),
      'get'
    );
    return apiContentful.get<IContentTypeCategories>(
      '/entries?access_token=KPn9KYAbB-kcntIaKRD2JR4golYL0g-MnsDth5DscB8&content_type=categories'
    );
  };

  return {
    getContentHomeBanner,
    getContentInFocus,
    getContentInFocusLimited,
    getAllVideos,
    getLimitedAudios,
    getAllAudios,
    getbyId,
    getNewsAnnouncements,
    getNotReadAnnouncements,
    getReadAnnouncements,
    markAsRead,
    speechBubble,
    getAllCategories,
  };
};

export default contentfulApi();
