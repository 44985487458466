import Tooltip from '@mui/material/Tooltip';
import FinancialApi from 'api/financial-api';
import FinancialPositionApi from 'api/financial-position-api';
import ButtonDownload from 'components/ButtonDownload';
import GenericDropdown from 'components/GenericDropdown';
import GenericGroupingDropdown from 'components/GenericGroupingDropdown';
import GenericTableFinancialPosition from 'components/GenericTableFinancialPosition';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import { IDateDataBase, INewCodeIdType, INewReportPosition, IReportOption } from 'model/financial';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import FinancialPositionService from 'services/financial-position-service';
import { encryptReport } from 'shared/util/api-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { ReactComponent as ExclamationIcon } from '../../images/frisia/icons/noun-information-817952.svg';

export interface IPositionTableItems {
  financialAccount: string;
  balanceUntil: string;
  debit: string;
  credit: string;
  block: string;
  withheld: string;
  toWithhold: string;
  loanUntil: string;
  loan: string;
  financialBalance: string;
  inputsToBeWithdrawn?: string;
  toBeSold?: string;
  frisiaBalance?: string;
}

const FinancialPosition = ({ t }: WithTranslation) => {
  const [reportUrl, setReportUrl] = useState<string>();
  const [reportPDFUrl, setReportPDFUrl] = useState<string>();
  const [reportXLSUrl, setReportXLSUrl] = useState<string>();
  const [newReportInfo, setNewReportInfo] = useState<INewReportPosition>();
  const [periodlist, setPeriodlist] = useState<IDateDataBase[]>([]);
  const [reportOptions, serReportOptions] = useState<IReportOption[]>([
    {
      CD_REPORT: 'XXFRAR0100R',
      DS_REPORT: 'Posição Financeira Geral',
      DS_REPORT_GROUP: 'Consulta Completa por Período',
      IE_SHOW_DT_INI: 'Y',
      IE_SHOW_DT_END: 'N',
    },
    {
      CD_REPORT: 'XXFRAR0102R',
      DS_REPORT: 'Débitos Futuros',
      DS_REPORT_GROUP: 'Consultas Gerais',
      IE_SHOW_DT_INI: 'N',
      IE_SHOW_DT_END: 'N',
    },
    {
      CD_REPORT: 'XXFRAR0103R',
      DS_REPORT: 'Créditos Futuros',
      DS_REPORT_GROUP: 'Consultas Gerais',
      IE_SHOW_DT_INI: 'N',
      IE_SHOW_DT_END: 'N',
    },
    {
      CD_REPORT: 'XXFRAR0105R',
      DS_REPORT: 'Custeios e Valores Retidos',
      DS_REPORT_GROUP: 'Consultas Gerais',
      IE_SHOW_DT_INI: 'N',
      IE_SHOW_DT_END: 'N',
    },
    {
      CD_REPORT: 'XXFRAR0106R',
      DS_REPORT: 'Empréstimos Curto Prazo',
      DS_REPORT_GROUP: 'Consultas Gerais',
      IE_SHOW_DT_INI: 'N',
      IE_SHOW_DT_END: 'N',
    },
    {
      CD_REPORT: 'XXFRAR0107R',
      DS_REPORT: 'Empréstimos Longo Prazo',
      DS_REPORT_GROUP: 'Consultas Gerais',
      IE_SHOW_DT_INI: 'N',
      IE_SHOW_DT_END: 'N',
    },
    {
      CD_REPORT: 'XXFRAR0109R',
      DS_REPORT: 'Insumos a Retirar',
      DS_REPORT_GROUP: 'Consultas Gerais',
      IE_SHOW_DT_INI: 'N',
      IE_SHOW_DT_END: 'N',
    },
    {
      CD_REPORT: 'XXFRAR0110R',
      DS_REPORT: 'Estoque Agrícola a Comercializar',
      DS_REPORT_GROUP: 'Consultas Gerais',
      IE_SHOW_DT_INI: 'N',
      IE_SHOW_DT_END: 'N',
    },
    {
      CD_REPORT: 'XXFRAR0108R',
      DS_REPORT: 'Saldo da Conta Financeira',
      DS_REPORT_GROUP: 'Outras Consultas por Período',
      IE_SHOW_DT_INI: 'Y',
      IE_SHOW_DT_END: 'N',
    },
    {
      CD_REPORT: 'XXFRAR0104R',
      DS_REPORT: 'Saldo Bloqueado',
      DS_REPORT_GROUP: 'Outras Consultas por Período',
      IE_SHOW_DT_INI: 'Y',
      IE_SHOW_DT_END: 'Y',
    },
    {
      CD_REPORT: 'XXFRAR0101R',
      DS_REPORT: 'Extrato Movimentação da Conta',
      DS_REPORT_GROUP: 'Outras Consultas por Período',
      IE_SHOW_DT_INI: 'Y',
      IE_SHOW_DT_END: 'Y',
    },
  ]);
  const [chosenPeriod, setChosenPeriod] = useState<IDateDataBase>();
  const [chosenFinalPeriod, setChosenFinalPeriod] = useState<IDateDataBase>();
  const [chosenReport, setChosenReport] = useState<IReportOption>();

  const [tableItems, setTableItems] = useState<IPositionTableItems[]>([]);
  const [showOnScreen, setShowOnScreen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [codeAndIds, setCodeAndIds] = useState<INewCodeIdType[]>([]);

  const [reportCGP, setReportCGP] = useState<string>();
  const [reportCCS, setReportCCS] = useState<string>();
  const [reportALL, setReportALL] = useState<string>();

  const tableHeader = [
    ' ',
    `${t('financial.balanceUntil')} ${moment(newReportInfo?.extrato?.dataDoSaldo, 'DD/MM/YYYY 00:00:00').format('DD/MM/YY')} (R$)`,
    `${t('financial.futureDebts')} (R$)`,
    `${t('financial.futureCredits')} (R$)`,
    `${t('financial.blockedValues')} (R$)`,
    `${t('financial.amountsRetained')} (R$)`,
    `${t('financial.balanceRetainable')} (R$)`,
    `${t('financial.loansUntil')} ${newReportInfo?.extrato.emprestimoAPagarAte} (R$)`,
    `${t('financial.loans')} ${newReportInfo?.extrato.emprestimoAPagarAPartir} (R$)`,
    `${t('financial.financialBalance')} (R$)`,
    `${t('financial.inputsToBeWithdrawn')} (R$)`,
    `${t('financial.toBeSold')} (R$)`,
    `${t('financial.frisiaBalance')} (R$)`,
  ];

  const headerInfos = [
    '',
    t('financialPosition.infoAbout2'),
    t('financialPosition.infoAbout3'),
    t('financialPosition.infoAbout4'),
    t('financialPosition.infoAbout5'),
    t('financialPosition.infoAbout6'),
    t('financialPosition.infoAbout7'),
    t('financialPosition.infoAbout8'),
    t('financialPosition.infoAbout9'),
    t('financialPosition.infoAbout10'),
    t('financialPosition.infoAbout11'),
    t('financialPosition.infoAbout12'),
    t('financialPosition.infoAbout13'),
  ];

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const changeToBrNumber = (value: string) => {
    const value2 = parseFloat(value.replaceAll(',', '.'));
    return parseFloat(value2.toFixed(2)).toLocaleString('pt-br', { minimumFractionDigits: 2 });
  };

  useEffect(() => {
    FinancialPositionService.getDataBase().then(setPeriodlist);
    FinancialPositionService.getReportOptions().then(response => {
      serReportOptions(response);
      console.log(response);
    });
  }, []);

  useEffect(() => {
    if (chosenReport) {
      if ((chosenReport?.CD_REPORT === 'XXFRAR0101R' || chosenReport?.CD_REPORT === 'XXFRAR0104R') && chosenPeriod && chosenFinalPeriod) {
        setReportUrl(
          FinancialApi.getFinancialPositionReport(
            '',
            getUserRegistration,
            chosenReport.CD_REPORT,
            chosenPeriod.CD_DATA,
            chosenFinalPeriod.CD_DATA
          )
        );
        setReportPDFUrl(
          FinancialApi.getFinancialPositionReport(
            '&dld_fmt=pdf',
            getUserRegistration,
            chosenReport.CD_REPORT,
            chosenPeriod.CD_DATA,
            chosenFinalPeriod.CD_DATA
          )
        );
        setReportXLSUrl(
          FinancialApi.getFinancialPositionReport(
            '&dld_fmt=xls',
            getUserRegistration,
            chosenReport.CD_REPORT,
            chosenPeriod.CD_DATA,
            chosenFinalPeriod.CD_DATA
          )
        );
      } else if (chosenPeriod) {
        setReportUrl(FinancialApi.getFinancialPositionReport('', getUserRegistration, chosenReport.CD_REPORT, chosenPeriod.CD_DATA));
        setReportPDFUrl(
          FinancialApi.getFinancialPositionReport('&dld_fmt=pdf', getUserRegistration, chosenReport.CD_REPORT, chosenPeriod.CD_DATA)
        );
        setReportXLSUrl(
          FinancialApi.getFinancialPositionReport('&dld_fmt=xls', getUserRegistration, chosenReport.CD_REPORT, chosenPeriod.CD_DATA)
        );
      } else {
        setReportUrl(FinancialApi.getFinancialPositionReport('', getUserRegistration, chosenReport.CD_REPORT));
        setReportPDFUrl(FinancialApi.getFinancialPositionReport('&dld_fmt=pdf', getUserRegistration, chosenReport.CD_REPORT));
        setReportXLSUrl(FinancialApi.getFinancialPositionReport('&dld_fmt=xls', getUserRegistration, chosenReport.CD_REPORT));
      }
    }
    if (chosenPeriod) {
      FinancialPositionApi.getReportByAccountType(getUserRegistration, chosenPeriod.CD_DATA, 'CGP').then(response =>
        setReportCGP(encryptReport(response.data.url_report))
      );
      FinancialPositionApi.getReportByAccountType(getUserRegistration, chosenPeriod.CD_DATA, 'CCS').then(response =>
        setReportCCS(encryptReport(response.data.url_report))
      );
      FinancialPositionApi.getReportByAccountType(getUserRegistration, chosenPeriod.CD_DATA, 'ALL').then(response =>
        setReportALL(encryptReport(response.data.url_report))
      );
    }
  }, [chosenReport, chosenPeriod, chosenFinalPeriod]);

  useEffect(() => {
    if (showOnScreen && chosenReport?.CD_REPORT === 'XXFRAR0100R') {
      getInfos();
    }
  }, [showOnScreen, chosenPeriod]);

  const getInfos = () => {
    if (chosenPeriod) {
      setTableItems([]);
      setIsLoading(true);

      FinancialPositionService.newGetReport(getUserRegistration, chosenPeriod.CD_DATA).then(response => {
        setIsLoading(false);
        setNewReportInfo(response);
        setCodeAndIds(
          response.extrato.contas.map(account => ({
            codigo: account.codigo,
            idContaFinanceira: account.idContaFinanceira,
            nome: account.nome,
          }))
        );
        const accountExtrat = response.extrato.contas.map(account => ({
          financialAccount: account.nome,
          codigo: parseInt(account.codigo),
          balanceUntil: changeToBrNumber(account.financeiro.saldoAte),
          debit: changeToBrNumber(account.financeiro.debitoFuturo),
          credit: changeToBrNumber(account.financeiro.creditoFuturo),
          block: changeToBrNumber(account.financeiro.bloqueadoInsumoMO),
          withheld: changeToBrNumber(account.financeiro.custeioRetido),
          toWithhold: changeToBrNumber(account.financeiro.custeioAReter),
          loanUntil: changeToBrNumber(account.financeiro.emprestimoAPagarAte),
          loan: changeToBrNumber(account.financeiro.emprestimoAPagarAPartir),
          financialBalance: changeToBrNumber(account.saldoFinanceiro),
          inputsToBeWithdrawn: changeToBrNumber(account.agricola.insumosARetirar),
          toBeSold: changeToBrNumber(account.agricola.estoqueAComercializar ?? 0),
          frisiaBalance: changeToBrNumber(account.saldoFinal),
        }));
        const sortedAccontExtra = accountExtrat
          .sort((a, b) => a.codigo - b.codigo)
          .map(obj => {
            // Create a new object without the id property
            let newObj = {
              financialAccount: obj.financialAccount,
              balanceUntil: obj.balanceUntil,
              debit: obj.debit,
              credit: obj.credit,
              block: obj.block,
              withheld: obj.withheld,
              toWithhold: obj.toWithhold,
              loanUntil: obj.loanUntil,
              loan: obj.loan,
              financialBalance: obj.financialBalance,
              inputsToBeWithdrawn: obj.inputsToBeWithdrawn,
              toBeSold: obj.toBeSold,
              frisiaBalance: obj.frisiaBalance,
            };
            return newObj;
          });
        console.log(sortedAccontExtra);
        setTableItems(sortedAccontExtra);
      });
    }
  };

  const renderReportOnScreen = useCallback(() => {
    if (chosenReport?.CD_REPORT === 'XXFRAR0100R' && newReportInfo && tableItems) {
      return (
        <StyledRenderReport>
          <StyledInfoContainer>
            <StyledFirstInfo>
              <div>
                {t('login.registration')}:{' '}
                <StyledText>
                  {newReportInfo?.cooperado.matricula} - {newReportInfo?.cooperado.nome}{' '}
                </StyledText>
              </div>
              <div>
                {t('financial.admissionDate')}:{' '}
                <StyledText>{moment(newReportInfo?.cooperado.admissao, 'DD/MM/YYYY 00:00:00').format('DD/MM/YYYY')}</StyledText>
              </div>
            </StyledFirstInfo>
            <StyledSecondInfo>
              <tbody>
                <tr>
                  <td>
                    {t('financial.ownCapital')}
                    <Tooltip
                      title={<div style={{ whiteSpace: 'pre' }}>{`${t('financialPosition.infoOwnCapital')}`}</div>}
                      arrow
                      placement="top"
                    >
                      <ExclamationIcon
                        style={{
                          alignSelf: 'self-start',
                          minHeight: '18px',
                          minWidth: '18px',
                          height: '18px',
                          width: '18px',
                          transform: 'translate(0px, -4px)',
                        }}
                      />
                    </Tooltip>
                  </td>
                  <td className="gray-first">
                    <a href={reportCGP} target="_blank" rel="noopener noreferrer">
                      R$ {changeToBrNumber(newReportInfo.extrato.capitalGiroProprio)}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('financial.socialCapital')}
                    <Tooltip
                      title={<div style={{ whiteSpace: 'pre' }}>{`${t('financialPosition.infoSocialCapital')}`}</div>}
                      arrow
                      placement="top"
                    >
                      <ExclamationIcon
                        style={{
                          alignSelf: 'self-start',
                          minHeight: '18px',
                          minWidth: '18px',
                          height: '18px',
                          width: '18px',
                          transform: 'translate(0px, -4px)',
                        }}
                      />
                    </Tooltip>
                  </td>
                  <td>
                    <a href={reportCCS} target="_blank" rel="noopener noreferrer">
                      R$ {changeToBrNumber(newReportInfo.extrato.capitalSocial)}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('financial.totalCapital')}
                    <Tooltip
                      title={<div style={{ whiteSpace: 'pre' }}>{`${t('financialPosition.infoTotalCapital')}`}</div>}
                      arrow
                      placement="top"
                    >
                      <ExclamationIcon
                        style={{
                          alignSelf: 'self-start',
                          minHeight: '18px',
                          minWidth: '18px',
                          height: '18px',
                          width: '18px',
                          transform: 'translate(0px, -4px)',
                        }}
                      />
                    </Tooltip>
                  </td>
                  <td className="gray-second">
                    <a href={reportALL} target="_blank" rel="noopener noreferrer">
                      R$ {changeToBrNumber(newReportInfo.extrato.capitalTotal)}
                    </a>
                  </td>
                </tr>
              </tbody>
            </StyledSecondInfo>
          </StyledInfoContainer>
          <GenericTableFinancialPosition
            codeAndIds={codeAndIds}
            chosenPeriod={chosenPeriod?.CD_DATA}
            headerItems={tableHeader}
            headerInfos={headerInfos}
            isDontHaveMaximumWidth
            tableItems={tableItems}
            isInvertLineColor
          />
          <StyledFooter>
            {t('financial.allValuesInReal')} {t('financial.issueDate')}:{' '}
            <StyledText>{moment(newReportInfo?.extrato.emissao, 'DD/MM/YYYY 00:00:00').format('DD/MM/YYYY')}</StyledText>
          </StyledFooter>
          <div style={{ margin: '15px' }} />
        </StyledRenderReport>
      );
    } else if (reportUrl) {
      return (
        <>
          <iframe
            sandbox="allow-scripts allow-popups allow-forms allow-top-navigation"
            style={{ width: '100%', height: '800px' }}
            src={reportUrl}
          />
        </>
      );
    }
  }, [chosenReport, tableItems, reportUrl]);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('financial.financialPosition')} />
      </StyledPageTitleContainer>
      <StyledFiltersContainer>
        <StyledFilterContainer filterWidth={350}>
          <GenericGroupingDropdown
            label={t('financial.options')}
            placeholder={t('financial.selectOption')}
            listShowDropdown={reportOptions}
            keyToShowInDropdown="DS_REPORT"
            keyGroup="DS_REPORT_GROUP"
            getSelectedValue={value => {
              setShowOnScreen(false);
              setChosenReport(value);
            }}
          />
        </StyledFilterContainer>
        {chosenReport?.IE_SHOW_DT_INI === 'Y' && (
          <StyledFilterContainer filterWidth={150}>
            <GenericDropdown
              label={t('financial.basePeriod')}
              placeholder={t('financial.selectPeriod')}
              listShowDropdown={periodlist}
              keyToShowInDropdown="CD_DATA"
              getSelectedValue={value => setChosenPeriod(value)}
              defaultValue={periodlist[0]}
            />
          </StyledFilterContainer>
        )}
        {chosenReport?.IE_SHOW_DT_END === 'Y' && (
          <StyledFilterContainer filterWidth={150}>
            <GenericDropdown
              label={t('financial.finalPeriod')}
              placeholder={t('financial.selectPeriod')}
              listShowDropdown={periodlist}
              keyToShowInDropdown="CD_DATA"
              getSelectedValue={value => setChosenFinalPeriod(value)}
              defaultValue={periodlist[0]}
            />
          </StyledFilterContainer>
        )}

        <div style={{ height: '61px' }}>
          <StyledButton
            onClick={() => setShowOnScreen(true)}
            color="frisiaPrimary"
            style={{ maxWidth: '210px', alignSelf: 'center', whiteSpace: 'nowrap', fontSize: '11px' }}
          >
            <span>{t('financial.seeOnScreen')}</span>
          </StyledButton>
        </div>
        {reportUrl && (
          <>
            <StyledAButton href={reportPDFUrl} target="_blank">
              {'PDF'}
            </StyledAButton>
            <StyledAButton href={reportXLSUrl} target="_blank">
              {'Excel'}
            </StyledAButton>
            <ButtonDownload style={{ marginLeft: 'auto', marginBottom: '15px' }} href={reportUrl} />
          </>
        )}
      </StyledFiltersContainer>

      {showOnScreen && (
        <div style={{ marginTop: isLoading ? '70px' : '0px' }}>
          <LoadingContainerScreen isLoading={isLoading}>
            <div />
            {renderReportOnScreen()}
          </LoadingContainerScreen>
        </div>
      )}
    </>
  );
};

const StyledAButton = styled.a`
  font-family: ${({ theme }) => theme.font.montserratMedium};
  font-size: 14px;
  transition: 250ms;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  background: #2a5299;
  color: #e6eaf2;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  line-height: 2.625rem;
  padding: 0 20px;
  max-width: 172px;
  margin-bottom: 16px;

  &:hover {
    background: whitesmoke;
  }
`;

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  align-items: center;
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  @media screen and (min-width: 1920px) {
    max-width: 1490px;
  }
`;

const StyledRenderReport = styled.div``;

const StyledInfoContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  div {
    display: flex;
  }

  @media screen and (min-width: 1920px) {
    max-width: 1490px;
  }
`;

const StyledFirstInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledSecondInfo = styled.table`
  width: 280px;
  height: 82px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  opacity: 1;
  font-size: 13px;
  tbody {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    tr {
      display: flex;
      justify-content: space-between;
      line-height: 26px;
      td {
        text-align: left;
        min-width: 110px;
        padding: 6px;
      }
    }
    tr:nth-child(odd) {
      height: 100%;
      .gray-first {
        background-color: #f2f2f2;
        border-top-right-radius: 12px;
      }
      .gray-second {
        background-color: #f2f2f2;
        border-bottom-right-radius: 12px;
      }
    }
  }
`;

const StyledText = styled.div`
  font-size: 13px;
  font-family: ${({ theme }) => theme.font.montserratBold};
  letter-spacing: 0px;
  color: #656565;
  opacity: 1;
  margin: 0px 15px;
  align-items: center;
`;

export default withTranslation()(FinancialPosition);
