import styled from 'styled-components/macro';

export const SearchContainer = styled.div`
  min-height: 82vh;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 36px;
  padding-bottom: 35px;
`;

export const SearchContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ResultsTitle = styled.div`
  font-size: 22px;
  line-height: 0.73;
  letter-spacing: 0.37px;
  color: $brownish-grey;
`;

export const ResultsTitleBold = styled(ResultsTitle)`
  font-size: 24px;
  font-weight: 500;
  color: $greyish-brown;
`;

export const AllResults = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NoResultsTitle = styled.div`
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: $brown-grey-two;
`;

export const NoResultsButton = styled.a`
  width: 199px;
  height: 28px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  cursor: pointer;
`;

export const NoResultsButtonText = styled.span`
  font-family: 'Raleway';
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: $pumpkin;
`;
