import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import backgroundImageDefault from 'images/frisia/img/large-frisia-building.svg';
import RoundArrowSvg from 'images/frisia/icons/white-round-arrow-icon.svg';

interface NewsContainerProps {
  backgroundImage?: string;
}

const NewsContainer = styled.div<NewsContainerProps>`
  width: 501px;
  height: 254px;
  min-width: 445px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  box-shadow: 0px 3px 6px #00000029;
  background: ${props =>
    props.backgroundImage
      ? `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.6)), url(${props.backgroundImage})`
      : `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.6)), url('${backgroundImageDefault}`};
  background-size: cover;
  font: normal normal medium 22px/25px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding-bottom: 17px;
  border-radius: 22px;
  margin-right: 22px;
  a {
    max-width: min-content;
    margin-left: 440px;
  }

  padding-left: 17px;
  text-align: left;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #ffffff;
  height: 75px;
  flex-flow: column;
  display: flex;
  text-align: left;
  font-size: 24px;
  letter-spacing: 0px;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: flex-start;
  flex-flow: nowrap;
`;

const Styledcontent = styled.div`
  width: 436px;
  height: 35px;
  text-align: left;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 16px;
  margin-top: 13px;
  opacity: 1;
`;

const StyledStatus = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  margin-right: 100%;
  font-size: 10px;
  margin-top: 13px;
  width: fit-content;
  padding: 0px 17px;
  height: 18px;
  background: #d7cc27 0% 0% no-repeat padding-box;
  border: 1px solid #f5f5f500;
  border-radius: 9px;
  opacity: 1;
  margin-bottom: 60px;
`;

const StyledArrow = styled.img`
  width: 30px;
  height: 30px;
  min-width: 30px;
  background: none;
  border: unset;
  cursor: pointer;
`;

interface IProps extends WithTranslation {
  title?: string;
  summary?: string;
  backgroundImage?: string;
  link?: string;
  subtitle?: string;
}

const NewsTable = ({ title, summary, backgroundImage, link, subtitle, t }: IProps) => {
  return (
    <div>
      <NewsContainer backgroundImage={backgroundImage}>
        <StyledStatus>{subtitle}</StyledStatus>
        <StyledHeader>{title ? title : 'Frisia'}</StyledHeader>
        <Styledcontent>{summary && (summary.length > 143 ? `${summary?.substring(0, 143)} ...` : summary)}</Styledcontent>
        <a target="_blank" href={link}>
          <StyledArrow src={RoundArrowSvg} />
        </a>
      </NewsContainer>
    </div>
  );
};

export default withTranslation()(NewsTable);
