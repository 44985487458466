import { AxiosResponse } from 'axios';
import IDocument from 'model/document';
import { sendLog } from 'shared/util/api-utils';
import { apiExtranetFiles } from './api';
import { EnvironmentalRestrictFile } from 'model/enrironmental-restrict-files';

export const documentsApi = () => {
  const getDocuments = (): Promise<AxiosResponse<IDocument>> => {
    sendLog('Consulta de documentos', `/filelist`, JSON.stringify([]), 'get');
    return apiExtranetFiles.get<IDocument>('/filelist');
  };

  const generateLinkDownloadDocument = (fileUrl: string): Promise<AxiosResponse<string>> => {
    sendLog('Gerando link de download de documento', `/publicbase64file?filepath=${fileUrl}`, JSON.stringify([]), 'get');
    return apiExtranetFiles.get<string>(`/publicbase64file?filepath=${fileUrl}`);
  };
  const getRestrictFilesBase64 = (folder: string, registration: string): Promise<AxiosResponse<EnvironmentalRestrictFile[]>> => {
    sendLog('Arquivos Restritos', `/restrictPdfFilesBase64?cd_pessoa=${registration}`, JSON.stringify([]), 'get');
    return apiExtranetFiles.get<EnvironmentalRestrictFile[]>(`/restrictPdfFilesBase64?pasta=${folder}&cd_pessoa=${registration}`);
  };

  return {
    getDocuments,
    generateLinkDownloadDocument,
    getRestrictFilesBase64,
  };
};

export default documentsApi();
