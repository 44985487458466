import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReactComponent as BarleyIconSvg } from '../../../../images/frisia/icons/blue-barley-icon.svg';
import { ReactComponent as BeanIconSvg } from '../../../../images/frisia/icons/bean-svgrepo-com.svg';
import { ReactComponent as CornIconSvg } from '../../../../images/frisia/icons/blue-corn-icon.svg';
import { ReactComponent as ForagerIconSvg } from '../../../../images/frisia/icons/forrageiras.svg';
import { ReactComponent as SoyIconSvg } from '../../../../images/frisia/icons/blue-soy-icon.svg';
import { ReactComponent as OneDotIconSvg } from '../../../../images/frisia/icons/blue-checked-checkbox-icon.svg';
import { ReactComponent as OatIconSvg } from '../../../../images/frisia/icons/oat.svg';
import { ReactComponent as SorghumIconSvg } from '../../../../images/frisia/icons/sorghum.svg';
import { ReactComponent as WheatIconSvg } from '../../../../images/frisia/icons/trigo.svg';
import { ReactComponent as CowIcon } from '../../../../images/frisia/icons/blue-small-cow-head-icon.svg';
import { ReactComponent as BiomassIcon } from '../../../../images/frisia/icons/blue-package-icon.svg';
import { ReactComponent as SwineIcon } from '../../../../images/frisia/icons/blue-small-pig-head-icon.svg';
import { ReactComponent as MovimentIcon } from '../../../../images/frisia/icons/movimento.svg';
import { ReactComponent as LoanIcon } from '../../../../images/frisia/icons/emprestimo.svg';

enum IconsType {
  MALES = 'MACHOS',
  COWS = 'VACAS',
  MILK = 'LEITE',
  HEIFERS = 'NOVILHAS',
  NEWBORNS = 'RECEM NASCIDOS',
  NEW = 'NOVO',
  ALL = 'TODOS',
  SOY = 'SOJA',
  CORN = 'MILHO',
  BARLEY = 'CEVADA',
  FORAGERS = 'FORRAGEIRAS',
  BEAN = 'FEIJAO',
  OAT = 'AVEIA',
  WHEAT = 'TRIGO',
  WHEATGRASS = 'TRIGUILHO',
  SORGHUM = 'SORGO',
  BIOMASS = 'BIOMASSA',
  SWINE = 'SUINOS',
  MOVIMENT = 'MOVIMENTO',
  LOAN = 'EMPRESTIMO',
}

interface IProps extends WithTranslation {
  name: string;
}

const IconBasedOnString = ({ name, t }: IProps) => {
  const iconStyles = { minWidth: '30px', minHeight: '30px', maxWidth: '30px', maxHeight: '30px', marginRight: '10px' };
  if (name.toLowerCase().includes(IconsType.LOAN.toLowerCase())) {
    return <LoanIcon style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.MOVIMENT.toLowerCase())) {
    return <MovimentIcon style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.SWINE.toLowerCase())) {
    return <SwineIcon style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.BIOMASS.toLowerCase())) {
    return <BiomassIcon style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.MILK.toLowerCase())) {
    return <CowIcon style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.BEAN.toLowerCase())) {
    return <BeanIconSvg style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.SOY.toLowerCase())) {
    return <SoyIconSvg style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.CORN.toLowerCase())) {
    return <CornIconSvg style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.BARLEY.toLowerCase())) {
    return <BarleyIconSvg style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.FORAGERS.toLowerCase())) {
    return <ForagerIconSvg style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.BEAN.toLowerCase())) {
    return <BeanIconSvg style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.OAT.toLowerCase())) {
    return <OatIconSvg style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.WHEAT.toLowerCase())) {
    return <WheatIconSvg style={iconStyles} />;
  }
  if (name.toLowerCase().includes(IconsType.SORGHUM.toLowerCase())) {
    return <SorghumIconSvg style={iconStyles} />;
  }
  return <OneDotIconSvg style={iconStyles} />;
};

export default withTranslation()(IconBasedOnString);
