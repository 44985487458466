import weatherApi from 'api/weather-api';
import { AxiosError, AxiosResponse } from 'axios';
import { ForecastResponse, WeatherResponse } from 'model/weather-response';

export const weatherService = () => {
  const forecast6days = async (lat: string, lon: string) => {
    try {
      const result: AxiosResponse<ForecastResponse> = await weatherApi.forecast6days(lat, lon);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const weatherToday = async (lat: string, lon: string) => {
    try {
      const result: AxiosResponse<WeatherResponse> = await weatherApi.weatherToday(lat, lon);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  const weatherByCity = async (city: string) => {
    try {
      const result: AxiosResponse<WeatherResponse> = await weatherApi.weatherByCity(city);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    forecast6days,
    weatherToday,
    weatherByCity,
  };
};

export default weatherService();
