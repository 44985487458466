import BreadCrumbs from 'components/BreadCrumbs';
import IsaChat from 'components/IsaChat';
import ComunicationModal from 'components/ComunicationModal';
import { AccountType } from 'model/account';
import ITypeIconsWithLinks from 'model/type-icons-with-links';
import { useEffect, useState } from 'react';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'reducer';
import { setSelectedUser } from 'reducer/authentication/actions';
import { compose } from 'redux';
import SearchAccountService from 'services/search-account-service';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as BellIconSvg } from '../../images/frisia/icons/blue-bell-icon.svg';
import { ReactComponent as GlassIconSvg } from '../../images/frisia/icons/blue-magnifying-glass-icon.svg';
import BurgerImg from '../../images/frisia/icons/burger-menu.svg';
import { ReactComponent as ArrowIconSvg } from '../../images/frisia/icons/white-arrow-icon.svg';
import { ReactComponent as ExitIconSvg } from '../../images/frisia/icons/white-exit-icon.svg';
import { ReactComponent as FacebookIconSvg } from '../../images/frisia/icons/white-facebook-icon.svg';
import { ReactComponent as InstagramIconSvg } from '../../images/frisia/icons/white-instagram-icon.svg';
import { ReactComponent as YoutubeIconSvg } from '../../images/frisia/icons/white-youtube-icon.svg';
import { ReactComponent as CalendarIconSvg } from '../../images/frisia/icons/yellow-calendar-icon.svg';
import { ReactComponent as CowIconSvg } from '../../images/frisia/icons/yellow-cow-icon.svg';
import { ReactComponent as DollarIconSvg } from '../../images/frisia/icons/yellow-dollar-sign-icon.svg';
import { ReactComponent as EnrollmentRegistrationSheetIconSvg } from '../../images/frisia/icons/yellow-enrollment-registration-sheet-icon.svg';
import { ReactComponent as GearIconSvg } from '../../images/frisia/icons/yellow-gears-icon.svg';
import { ReactComponent as GraphicIconSvg } from '../../images/frisia/icons/yellow-graphic-line-icon.svg';
import { ReactComponent as HouseIconSvg } from '../../images/frisia/icons/yellow-house-icon.svg';
import { ReactComponent as LeavesIconSvg } from '../../images/frisia/icons/yellow-leaves-icon.svg';
import { ReactComponent as MicrophoneIconSvg } from '../../images/frisia/icons/yellow-microphone-icon.svg';
import { ReactComponent as FolderIconSvg } from '../../images/frisia/icons/open-folder-svgrepo-com.svg';
import { ReactComponent as PencilSvg } from '../../images/empty-pencil.svg';
import { ReactComponent as PigIconSvg } from '../../images/frisia/icons/yellow-pig-icon.svg';
import { ReactComponent as PineIconSvg } from '../../images/frisia/icons/yellow-pine-icon.svg';
import { ReactComponent as PlantIconSvg } from '../../images/frisia/icons/yellow-plant-icon.svg';
import { ReactComponent as SupportIconSvg } from '../../images/frisia/icons/yellow-suport-icon.svg';
import { ReactComponent as SupermarketIconSvg } from '../../images/frisia/icons/yellow-supermarket-trolley-icon.svg';
import LogoWhite from '../../images/frisia/img/frisia-white-logo.svg';
import { ReactComponent as LogoFundacaoAbc } from '../../images/frisia/img/logo-no-background-fundacao-abc.svg';
import {
  StyledContainer,
  StyledContainerArrow,
  StyledContainerHeaderAndPage,
  StyledContainerIcon,
  StyledContainerLogout,
  StyledContainerPage,
  StyledContainerSocialAndLogout,
  StyledContainerSocialIcons,
  StyledContainerUser,
  StyledHeader,
  StyledList,
  StyledListItem,
  StyledListItemFooter,
  StyledLogo,
  StyledLogoContainer,
  StyledMenuContainer,
  StyledMenuFooter,
  StyledNumberNotifications,
  StyledProfileChosen,
  StyledSearch,
  StyledText,
  StyledUserName,
  StyledWelcomeText,
  StyleInnerPageContainer,
} from './styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { card } from 'shared/util/card';
import CategoryNames from 'model/enums/categorys-names';
import { makeStyles, createStyles } from '@mui/styles';
import fundacaoService from 'services/fundacao-service';
import contentfulService from 'services/contentful-service';
import { IspeechBubble, IUnreadContent } from 'model/contentful-results';
import { dateIsGreaterThanToday } from 'shared/util/date-utils';
import { ReactComponent as BalloonIconSvg } from '../../images/frisia/icons/blue-balloon-icon.svg';
import ComunicationBubbleModal from 'components/ComunicationBubbleModal';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';

interface StyledProps {
  isFlipped: boolean;
}

interface AutocompleteOptions {
  path: string;
  label: string;
}

const BurgerMenu = styled.div<StyledProps>`
  height: 68px;
  display: flex;
  margin-top: -30px;
  margin-left: -9px;
  img {
    width: 49px;
    cursor: pointer;
  }
  ${props =>
    props.isFlipped
      ? css`
          img {
            transform: scaleX(-1);
          }
        `
      : ''};
`;

const useStyles = makeStyles((theme?: any) => ({
  paper: {
    backgroundColor: '#e6eaf2 !important',
    fontSize: '18px !important',
    transition: '2s all ease !important',
  },
}));

const MenuAndHeader = props => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [loadingBallon, setLoadingBallon] = useState<boolean>(false);

  const [userName, setUserName] = useState('');
  const currentPath = useLocation();
  const cardValues = Object.values(card);
  const [selected, setSelected] = useState<AccountType>();
  const [closeMenu, setCloseMenu] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<ITypeIconsWithLinks[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openBubbleModal, setOpenBubbleModal] = useState<boolean>(false);
  const [fundationToken, setFundationToken] = useState<string>('');
  const [base64Register, setBase64Register] = useState<string>('');
  const [newsAnnouncements, setNewsAnnouncements] = useState<IUnreadContent[]>([]);
  const [bubbleAlerts, setBubbleAlerts] = useState<IspeechBubble[]>([]);

  const SUPPORT = 3;

  const [listOfPageNames, setListOfPageNames] = useState<(AutocompleteOptions | undefined)[]>(
    cardValues
      .map(item => {
        const pathList = item.pathRedirected.split('/');
        const firstPath = item.pathRedirected.split('/')[1];
        let name = t(item.i18nText);
        if (item.category === CategoryNames.DETAILED) {
          return;
        }
        if (pathList.length > 2) {
          return { path: item.pathRedirected, label: `${firstPath.toUpperCase()} - ${name}` };
        } else {
          return { path: item.pathRedirected, label: name };
        }
      })
      .filter(n => n)
  );

  const allMenuItems: ITypeIconsWithLinks[] = [
    { icon: <HouseIconSvg />, text: t('mainMenu.homeScreen'), pathRedirected: '/inicial' },
    { icon: <DollarIconSvg />, text: t('mainMenu.financial'), pathRedirected: '/financeiro', name: 'Financeiro' },
    { icon: <PlantIconSvg />, text: t('mainMenu.agricultural'), pathRedirected: '/agricola', name: 'Agrícola' },
    { icon: <CowIconSvg />, text: t('mainMenu.livestockCattle'), pathRedirected: '/pecuaria-bovinos', name: 'Pecuária Leite' },
    { icon: <PigIconSvg />, text: t('mainMenu.livestockPigs'), pathRedirected: '/pecuaria-suinos', name: 'Pecuária Suínos' },
    { icon: <GraphicIconSvg />, text: t('mainMenu.agriculturalQuotes'), pathRedirected: '/cotacoes-agricola', name: 'Cotação' },
    {
      icon: <SupermarketIconSvg />,
      text: t('mainMenu.purchases'),
      pathRedirected: '/autorizacao-compras',
    },
    { icon: <PineIconSvg />, text: t('mainMenu.forestry'), pathRedirected: '/florestal' },
    { icon: <LeavesIconSvg />, text: t('mainMenu.environmental'), pathRedirected: '/ambiental', name: 'Ambiental' },
    { icon: <EnrollmentRegistrationSheetIconSvg />, text: t('mainMenu.register'), pathRedirected: '/ficha-cadastral' },
    { icon: <MicrophoneIconSvg />, text: t('mainMenu.channel'), pathRedirected: '/canal-frisia' },
    // { icon: <CalendarIconSvg />, text: t('mainMenu.schedule'), pathRedirected: '/agenda' },
    { icon: <FolderIconSvg />, text: t('mainMenu.cooperativeRoom'), pathRedirected: '/sala-do-cooperado' },
    { icon: <PencilSvg />, text: t('mainMenu.authorizations'), pathRedirected: '/autorization-form' },
  ];

  const PushItensInMenu = () => {
    const filtered = props.coreInfo.menu_app.map(e => e.nm_menu);
    setMenuItems([]);
    if (
      props.coreInfo.cd_perfil === SUPPORT &&
      !allMenuItems.includes({ icon: <SupportIconSvg />, text: t('mainMenu.support'), pathRedirected: '/suporte' })
    ) {
      allMenuItems.push({ icon: <SupportIconSvg />, text: t('mainMenu.support'), pathRedirected: '/suporte' });
    }
    for (let i = 0; i < allMenuItems.length; i++) {
      if (filtered.includes(allMenuItems[i].name) || !allMenuItems[i].name) {
        setMenuItems(menuItems => [...menuItems, allMenuItems[i]]);
      }
    }
  };

  const pushSelectedItensInMenu = () => {
    const filtered = props.listOfPages.map(e => e.nm_menu);
    setMenuItems([]);
    if (
      props.coreInfo.cd_perfil === SUPPORT &&
      !allMenuItems.includes({ icon: <SupportIconSvg />, text: t('mainMenu.support'), pathRedirected: '/suporte' })
    ) {
      allMenuItems.push({ icon: <SupportIconSvg />, text: t('mainMenu.support'), pathRedirected: '/suporte' });
    }
    for (let i = 0; i < allMenuItems.length; i++) {
      if (filtered.includes(allMenuItems[i].name) || !allMenuItems[i].name) {
        setMenuItems(menuItems => [...menuItems, allMenuItems[i]]);
      }
    }
  };

  const updateListOfPagesInMenu = () => {
    const principalAllowPages = props.listOfPages.map(e => e.nm_menu);
    const newList = cardValues.map(item => {
      const pathList = item.pathRedirected.split('/');
      const firstPath = item.pathRedirected.split('/')[1];
      let name = t(item.i18nText);
      if (item.category === CategoryNames.DETAILED) {
        return;
      }
      if (pathList.length > 2) {
        const fatherPage = cardValues.find(item => item.pathRedirected === `/${firstPath}`);
        const nameOfFather = t(fatherPage?.i18nText ?? '');
        const fatherOfSubPages = props.listOfPages.find(element => element.nm_menu === nameOfFather);
        const childAllowPages = fatherOfSubPages?.telas.map(e => e.nm_tela);

        if (childAllowPages?.includes(name)) {
          return { path: item.pathRedirected, label: `${nameOfFather} - ${name}` };
        } else if (item.category === CategoryNames.FINANCIAL) {
          return { path: item.pathRedirected, label: `${nameOfFather} - ${name}` };
        }
      } else {
        if (principalAllowPages?.includes(name)) {
          return { path: item.pathRedirected, label: name };
        } else if (item.category === CategoryNames.INSTITUTIONAL) {
          return { path: item.pathRedirected, label: name };
        }
      }
    });

    setListOfPageNames(newList.filter(n => n));
  };

  const setDefaultAsSelected = async () => {
    const response = await SearchAccountService.byRegister(props.coreInfo.cd_pessoa);
    props.setSelected(response[0]);
  };

  useEffect(() => {
    if (props.coreInfo) {
      setUserName(props.coreInfo?.nm_usuario);
    }
  }, [props.coreInfo]);

  useEffect(() => {
    getFundationToken();
    if (props.selectedInfo) {
      setSelected(props.selectedInfo);
    } else {
      setDefaultAsSelected();
    }
  }, [props.selectedInfo]);

  useEffect(() => {
    if (props.listOfPages?.length > 0) {
      pushSelectedItensInMenu();
      updateListOfPagesInMenu();
    } else if (props.coreInfo?.menu_app?.length > 0) {
      PushItensInMenu();
    }
  }, [props.listOfPages, props.coreInfo]);

  const getUserRegistration = (): string => {
    return props.selectedInfo?.CD_PESSOA ?? props.coreInfo?.cd_pessoa;
  };

  const getFundationToken = async () => {
    const register = getUserRegistration();
    if (register != null) {
      const result = await fundacaoService.getToken(register);
      setBase64Register(btoa(register));
      setFundationToken(result.Data.token);
    }
  };

  const getUnReadMessages = async () => {
    const register = getUserRegistration();
    setLoadingBallon(true);
    const notReadresult = await contentfulService.getNotReadAnnouncements(register);
    const alerts = await contentfulService.speechBubble(register);
    setLoadingBallon(false);
    setBubbleAlerts(alerts);
    setNewsAnnouncements(
      notReadresult.filter(item => {
        if (item.fields.finish) {
          return dateIsGreaterThanToday(item.fields.finish);
        } else {
          return true;
        }
      })
    );
  };

  useEffect(() => {
    if (!closeMenu && pathname.includes('posicao-financeira')) {
      setCloseMenu(!closeMenu);
    }
  }, [pathname]);

  useEffect(() => {
    getUnReadMessages();
    const interval = setInterval(() => getUnReadMessages(), 300000);
    return () => clearInterval(interval);
  }, []);

  return (
    <StyledContainer>
      <StyledMenuContainer style={closeMenu ? { minWidth: '80px', width: '80px' } : { minWidth: '350px', width: '350px' }}>
        <input type="checkbox" onChange={() => setCloseMenu(!closeMenu)} className="openSidebarMenu" id="openSidebarMenu" />
        <label htmlFor="openSidebarMenu" className="sidebarIconToggle" style={closeMenu ? {} : { marginLeft: '283px' }}>
          <BurgerMenu isFlipped={closeMenu}>
            <img src={BurgerImg} alt={'burger-menu'} />
          </BurgerMenu>
        </label>

        <div id="sidebarMenu" style={closeMenu ? {} : { width: '350px' }}>
          <StyledLogoContainer>
            <StyledLogo style={closeMenu ? { display: 'none' } : {}}>
              <img src={LogoWhite} alt="Logo frísia menu" />
            </StyledLogo>
            {props.coreInfo?.cd_perfil === SUPPORT && (
              <GearIconSvg
                onClick={e => {
                  history.push('/suporte');
                }}
                style={closeMenu ? { marginBottom: '-17px', marginTop: '16px', marginRight: '32px' } : { marginRight: '32px' }}
              />
            )}
          </StyledLogoContainer>

          <nav>
            <StyledList>
              {menuItems.map((item, index) => (
                <StyledListItem
                  key={`menu-item-${index}`}
                  onClick={() => history.push(item.pathRedirected)}
                  style={closeMenu ? { width: '80px' } : { width: '350px' }}
                  isActive={currentPath.pathname.match(item.pathRedirected) != null}
                >
                  {item.icon}
                  <StyledText style={closeMenu ? { display: 'none' } : {}}>{item.text}</StyledText>
                  <StyledContainerArrow style={closeMenu ? { display: 'none' } : {}}>
                    <ArrowIconSvg style={{ marginRight: '40px', minWidth: '13px', minHeight: '13px' }} />
                  </StyledContainerArrow>
                </StyledListItem>
              ))}
            </StyledList>
          </nav>

          <StyledMenuFooter>
            <nav>
              <StyledList>
                <a
                  target="_blank"
                  href={`https://servicos.frisia.coop.br/Core/Home/FundacaoABC?matricula=${base64Register}&token=${fundationToken}`}
                >
                  <StyledListItemFooter>
                    <LogoFundacaoAbc style={{ marginLeft: '8px', marginRight: '13px' }} />
                    <StyledText style={closeMenu ? { display: 'none' } : { marginLeft: '10px', color: 'white' }}>
                      {t('mainMenu.fundacaoAbc')}
                    </StyledText>
                  </StyledListItemFooter>
                </a>
              </StyledList>
            </nav>

            <StyledContainerSocialAndLogout>
              <StyledContainerSocialIcons>
                <a target="_blank" style={closeMenu ? { display: 'none' } : {}} href="https://www.youtube.com/c/Fr%C3%ADsiaCooperativa">
                  <YoutubeIconSvg style={{ cursor: 'pointer' }} />
                </a>
                <a target="_blank" style={closeMenu ? { display: 'none' } : {}} href="https://www.instagram.com/cooperativafrisia/">
                  <InstagramIconSvg style={{ cursor: 'pointer' }} />
                </a>
                <a target="_blank" style={closeMenu ? { display: 'none' } : {}} href="https://www.facebook.com/cooperativafrisia/">
                  <FacebookIconSvg style={{ cursor: 'pointer' }} />
                </a>
              </StyledContainerSocialIcons>
              <StyledContainerLogout onClick={() => history.push('/logout')}>
                <span style={closeMenu ? { display: 'none' } : {}}>{t('global.button.exit')}</span>
                <ExitIconSvg />
              </StyledContainerLogout>
            </StyledContainerSocialAndLogout>
          </StyledMenuFooter>
        </div>
      </StyledMenuContainer>

      <StyledContainerHeaderAndPage style={closeMenu ? { maxWidth: 'calc(100vw - 50px)' } : {}}>
        <StyledHeader>
          <StyledSearch>
            <Autocomplete
              id="custom-input-demo"
              options={listOfPageNames}
              classes={{ paper: classes.paper }}
              onChange={(e, value) => {
                value && history.push(value?.path);
              }}
              noOptionsText={t('global.field.noOptions')}
              renderInput={params => <TextField variant="standard" {...params} placeholder={t('global.field.search')} />}
            />
            <GlassIconSvg style={{ cursor: 'pointer' }} />
          </StyledSearch>

          <StyledContainerUser>
            {loadingBallon ? (
              <div style={{ height: '10px', width: '10px', marginRight: '50px' }}>
                <LoadingContainerScreen isLoading={loadingBallon}>
                  <></>
                </LoadingContainerScreen>
              </div>
            ) : (
              <>
                <StyledContainerIcon onClick={() => setOpenBubbleModal(!openBubbleModal)}>
                  {bubbleAlerts.length > 0 && <StyledNumberNotifications>{bubbleAlerts.length}</StyledNumberNotifications>}
                  <BalloonIconSvg />
                  {openBubbleModal && (
                    <ComunicationBubbleModal
                      bubbleAlerts={bubbleAlerts}
                      numberUnread={bubbleAlerts.length}
                      onClick={() => setOpenModal(false)}
                    />
                  )}
                </StyledContainerIcon>

                <StyledContainerIcon onClick={() => setOpenModal(!openModal)}>
                  {newsAnnouncements.length > 0 && <StyledNumberNotifications>{newsAnnouncements.length}</StyledNumberNotifications>}
                  <BellIconSvg />
                  {openModal && <ComunicationModal numberUnread={newsAnnouncements.length} onClick={() => setOpenModal(false)} />}
                </StyledContainerIcon>
              </>
            )}
            <StyledWelcomeText>
              <Trans i18nKey="global.field.welcome">
                <StyledUserName>{{ userName }}</StyledUserName>
              </Trans>
              {props.coreInfo?.cd_perfil === SUPPORT && (
                <StyledProfileChosen onClick={() => history.push('/suporte')}>
                  {selected && `${selected.CD_PESSOA} - ${selected.NM_PESSOA}`}
                </StyledProfileChosen>
              )}
            </StyledWelcomeText>
          </StyledContainerUser>
        </StyledHeader>

        <StyledContainerPage>
          <StyleInnerPageContainer>
            <BreadCrumbs />
            {props.children}
          </StyleInnerPageContainer>
        </StyledContainerPage>
      </StyledContainerHeaderAndPage>

      {/* <IsaChat /> */}
    </StyledContainer>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  accountInfo: authentication.accountInfo,
  coreInfo: authentication.coreInfo,
  selectedInfo: authentication.selectedInfo,
  listOfPages: authentication.listOfPages,
});

const mapDispatchToProps = {
  setSelected: setSelectedUser,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(MenuAndHeader) as React.ComponentType<any>;
