import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { Search, SearchType } from 'model/enums/search';
import { SearchResult } from 'model/search-result';
import { User } from 'model/user';
import { IRootState } from 'reducer';
import { openFeedbackModal, openPaidDiseaseModal } from 'reducer/globalModal/actions';
import { SearchState } from 'reducer/search/types';
import SearchService from 'services/search-service';
import { ResultItem } from 'features/menu/menu-components';
import {
  AllResults,
  NoResults,
  NoResultsButton,
  NoResultsButtonText,
  NoResultsTitle,
  ResultsTitle,
  ResultsTitleBold,
  SearchContainer,
  SearchContent,
} from './StyledComponents';

export interface SearchScreenProps extends RouteComponentProps<{}> {
  t: any;
  history: any;
  searchState: SearchState;
  account: User;
  openPaidDiseaseModal: () => void;
  openFeedbackModal: () => void;
}

export interface SearchScreenState {
  results: SearchResult[];
  search?: Search;
  hasLoaded?: boolean;
}

export class SearchScreen extends React.Component<SearchScreenProps, SearchScreenState> {
  constructor(props: Readonly<SearchScreenProps>, context?: any) {
    super(props, context);

    this.state = {
      results: [],
    };
  }
  // Component Life Cycle
  async componentDidMount(): Promise<void> {
    let results: SearchResult[] = [];
    if (this.props.searchState.search) {
      this.setState({
        search: this.props.searchState.search,
      });
      const search: Search = {
        ...this.props.searchState.search,
      };
      delete search.limit;
      results = await SearchService.search(search);
    }
    this.setState({
      results,
    });
  }
  async componentWillReceiveProps(newProps: SearchScreenProps): Promise<void> {
    if (this.props.searchState.search !== newProps.searchState.search && newProps.searchState.search != null) {
      const search: Search = {
        ...newProps.searchState.search,
      };
      delete search.limit;
      const results: SearchResult[] = await SearchService.search(search);
      this.setState({
        results,
        search: newProps.searchState.search,
        hasLoaded: true,
      });
    }
  }

  // Handlers
  private onClickItem = (item: SearchResult): void => {
    switch (item.type) {
      case SearchType.SPECIALTY:
        this.props.history.push(`/disease/${item.id}`);
        break;
      default:
        this.props.history.push('/construction');
    }
  };
  private onPressNoResultsBtn = () => {
    this.props.openFeedbackModal();
  };

  // Renders
  render() {
    const { results, search, hasLoaded } = this.state;
    const hasResults: boolean = results && results.length > 0 && search != null;
    return (
      <SearchContainer>
        <SearchContent style={hasResults ? {} : hasLoaded ? { alignItems: 'center' } : {}}>
          {search != null && (
            <>
              <ResultsTitle>{this.props.t('specialty.resultsTitle1')}</ResultsTitle>
              <ResultsTitleBold>{`“${search.search}”`}</ResultsTitleBold>
              <ResultsTitle>{this.props.t(`specialty.resultsTitle${_.upperFirst(_.camelCase(search.type))}`)}</ResultsTitle>
            </>
          )}
          {hasResults ? (
            <AllResults>
              {results.map((it, index) => (
                <ResultItem key={index} result={it} index={index} onClick={this.onClickItem} hasSubscription />
              ))}
            </AllResults>
          ) : (
            hasLoaded && (
              <NoResults>
                <div className="icon-nome-da-imagem" />
                <NoResultsTitle>{this.props.t('specialty.emptyResults')}</NoResultsTitle>
                <NoResultsButton onClick={this.onPressNoResultsBtn}>
                  <NoResultsButtonText>{this.props.t('specialty.emptyResultsBtnLabel')}</NoResultsButtonText>
                </NoResultsButton>
              </NoResults>
            )
          )}
        </SearchContent>
      </SearchContainer>
    );
  }
}

const mapStateToProps = ({ search, authentication }: IRootState) => ({
  searchState: search,
  account: authentication.account,
});

const mapDispatchToProps = {
  openPaidDiseaseModal,
  openFeedbackModal,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SearchScreen) as React.ComponentType<any>;
