import { useEffect, useState, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';
import { ReactComponent as ArrowDownSvg } from '../images/frisia/icons/yellow-icon-arrow-down.svg';

interface IProps {
  label?: string;
  placeholder?: string;
  listShowDropdown: any[];
  errorText?: FieldError | string;
  isResetValue?: boolean;
  keyToShowInDropdown?: string;
  className?: string;
  disabled?: boolean;
  defaultValue?: any;
  getSelectedValue: (value: any) => void;
  white?: boolean;
  reset?: any;
}

interface IStyledContainerProps {
  isShowDropdown: boolean;
  white?: boolean;
}

const GenericDropdown = ({
  label,
  placeholder,
  listShowDropdown,
  errorText,
  isResetValue,
  keyToShowInDropdown,
  className,
  disabled,
  defaultValue,
  getSelectedValue,
  white,
  reset,
}: IProps) => {
  const [selectedItem, setSelectedItem] = useState<any>(defaultValue || placeholder || '');
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Fechar o dropdown ao clicar fora dele
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        handleDropdownClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Atualiza o item selecionado ao alterar o defaultValue ou o isResetValue
  useEffect(() => {
    if (isResetValue) {
      setSelectedItem(placeholder || '');
    } else if (defaultValue != null) {
      setSelectedItem(defaultValue);
    }
  }, [isResetValue, defaultValue]);

  // Envia o valor selecionado para o callback
  useEffect(() => {
    if (selectedItem !== placeholder) {
      getSelectedValue(selectedItem);
    }
  }, [selectedItem, placeholder, getSelectedValue, defaultValue]);

  useEffect(() => {
    if (reset) {
      setSelectedItem(placeholder);
    }
  }, [reset]);

  const handleItemClick = (item: any) => {
    setSelectedItem(item);
    handleDropdownClose(); // Fecha o dropdown e reseta o item selecionado
  };

  const handleDropdownClose = () => {
    setIsShowDropdown(false);
  };

  const handleDropdownToggle = () => {
    if (!disabled) {
      setIsShowDropdown(!isShowDropdown);
    }
  };

  return (
    <StyledContainer ref={containerRef} className={className}>
      <StyledDiv white={white} onClick={handleDropdownToggle} isShowDropdown={isShowDropdown} tabIndex={0}>
        <StyledInnerContainer hasLabel={!!label}>
          {label && <StyledLabel>{label}</StyledLabel>}
          <StyledText isPlaceholder={selectedItem === placeholder}>
            {keyToShowInDropdown && selectedItem !== placeholder ? selectedItem[keyToShowInDropdown] : selectedItem}
          </StyledText>
          <StyledIconContainer isShowDropdown={isShowDropdown}>
            <ArrowDownSvg />
          </StyledIconContainer>
        </StyledInnerContainer>

        <div ref={dropdownRef}>
          {isShowDropdown && (
            <StyledList isShowDropdown={isShowDropdown}>
              {listShowDropdown.map((item, index) => (
                <StyledItem key={`${item}-${index}`} onClick={() => handleItemClick(item)}>
                  {keyToShowInDropdown ? item[keyToShowInDropdown] : item}
                </StyledItem>
              ))}
            </StyledList>
          )}
        </div>
      </StyledDiv>

      {errorText && <StyledErrorMessage>{errorText}</StyledErrorMessage>}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative; /* Garante que o z-index funcione para os filhos */
  height: 70px;
`;

const StyledDiv = styled.div<{ isShowDropdown: boolean; white?: boolean }>`
  position: relative;
  min-height: 53px;
  width: 100%;
  text-align: left;
  border: 2px solid #4e6ba7;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ white }) => (white ? 'white' : '#f1f1f1')};
  z-index: ${({ isShowDropdown }) => (isShowDropdown ? 1 : 0)}; /* z-index para o container do dropdown */
`;

const StyledInnerContainer = styled.div<{ hasLabel: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ hasLabel }) => (hasLabel ? '20px 10px 0 10px' : '13px 10px 0 10px')};
`;

const StyledLabel = styled.span`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 2;
`;

const StyledText = styled.div<{ isPlaceholder: boolean }>`
  overflow-y: auto;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: ${({ isPlaceholder }) => (isPlaceholder ? '#656565' : '#000')};
  font-size: ${({ isPlaceholder }) => (isPlaceholder ? '12px' : '16px')};
  opacity: ${({ isPlaceholder }) => (isPlaceholder ? 0.75 : 1)};
  margin-top: ${({ isPlaceholder }) => (isPlaceholder ? '10px' : '0')};
`;

const StyledIconContainer = styled.div<{ isShowDropdown: boolean }>`
  margin-left: 3px;
  transition: transform 0.3s;
  transform: ${({ isShowDropdown }) => (isShowDropdown ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const StyledList = styled.ul<{ isShowDropdown: boolean }>`
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #656565;
  font-size: 16px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  z-index: ${({ isShowDropdown }) => (isShowDropdown ? 9999 : 0)}; /* z-index do dropdown */
  max-height: 200px;
  border: 1px solid #4e6ba7;
  border-top: none;
  position: absolute;
  width: 100%;
  top: 100%;
  background-color: white;
`;

const StyledItem = styled.li`
  max-width: 100%;
  border-bottom: 1px solid #4e6ba7;
  padding: 8px 10px;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const StyledErrorMessage = styled.span`
  position: absolute;
  bottom: 1;
  left: 1px;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: red;
  font-size: 12px;
`;

export default GenericDropdown;
