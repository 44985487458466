import BullCard from 'components/BullCard';
import GenericDropdown from 'components/GenericDropdown';
import MainPageTitle from 'components/MainPageTitle';
import { IBull } from 'model/bull';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import bullCatalogService from 'services/cattle/bull-catalog-service';
import { yearList } from 'shared/util/date-utils';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const BullCatalog = ({ t }: WithTranslation) => {
  const [bullCatalog, setBullCatalog] = useState<IBull[]>();

  const dates = yearList();

  useEffect(() => {
    bullCatalogService.getListBulls().then(setBullCatalog);
  }, []);

  return (
    <>
      <StyledPageTitleContainer margin={'30px 0 15px 0'}>
        <MainPageTitle fontSize={30} text={t('livestock.bullCatalog')} />
      </StyledPageTitleContainer>

      <StyledFiltersContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('livestock.filterRace')}
            placeholder={t('global.field.selectHere')}
            listShowDropdown={dates}
            getSelectedValue={value => {
              /* empty */
            }}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={350}>
          <GenericDropdown
            label={t('livestock.filterAge')}
            placeholder={t('global.field.selectHere')}
            listShowDropdown={dates}
            getSelectedValue={value => {
              /* empty */
            }}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('livestock.filterPublication')}
            placeholder={t('global.field.selectHere')}
            listShowDropdown={dates}
            getSelectedValue={value => {
              /* empty */
            }}
          />
        </StyledFilterContainer>
      </StyledFiltersContainer>

      <StyleCardsContainer>
        {bullCatalog?.map(bull => (
          <BullCard
            isCardSmall
            id={bull.id}
            image={bull.images[0]}
            name={bull.name}
            description={`${t('livestock.race')}${bull.race}`}
            key={`bull-id-${bull.id}`}
          />
        ))}
      </StyleCardsContainer>
    </>
  );
};

const StyledFiltersContainer = styled.div`
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

const StyleCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
`;

export default withTranslation()(BullCatalog);
