import { apiCore } from 'api/api';
import { AxiosResponse } from 'axios';
import { ICampaignsType, IRawMaterials } from 'model/material-schedule';
import { encryptReport, sendLog } from 'shared/util/api-utils';

export const rawMaterialScheduleApi = () => {
  const getCampaigns = (registration: number): Promise<AxiosResponse<ICampaignsType[]>> => {
    sendLog('Consultando campanhas de farmacos', `/Farmacos/GetCampanhas/?cd_pessoa=${registration}`, JSON.stringify({}), 'get');
    return apiCore.get<ICampaignsType[]>(`/Farmacos/GetCampanhas/?cd_pessoa=${registration}`);
  };

  const getRawMaterials = (registration: number, campaign: number): Promise<AxiosResponse<IRawMaterials[]>> => {
    sendLog(
      'Consultando materia prima de farmacos',
      `/Farmacos/GetMateriasPrima/?cd_pessoa=${registration}&cd_campanha=${campaign}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IRawMaterials[]>(`/Farmacos/GetMateriasPrima/?cd_pessoa=${registration}&cd_campanha=${campaign}`);
  };

  const getReport = (registration: number, campaign: number): string => {
    const path = `https://reports.frisia.coop.br/ReportServer?/EBS/PFM/XXFRPFM0016&p_id_campanha=${campaign}&p_nr_matricula=${registration}`;
    sendLog('Consultando relatorio de materia prima de farmacos', path, JSON.stringify({}), 'get');
    return encryptReport(path);
  };

  return {
    getCampaigns,
    getRawMaterials,
    getReport,
  };
};

export default rawMaterialScheduleApi();
