import { AxiosResponse } from 'axios';
import { IFavorite, IMessage } from 'model/account-allow-pages';
import { sendLog } from 'shared/util/api-utils';
import { apiCore } from './api';

export const shorcutApi = () => {
  const getFavorite = (registration: number): Promise<AxiosResponse<IFavorite[]>> => {
    sendLog('Consultar favoritos', `/Cooperado/GetFavoritos?CD_PESSOA=${registration}`, JSON.stringify([]), 'get');
    return apiCore.get<IFavorite[]>(`/Cooperado/GetFavoritos?CD_PESSOA=${registration}`);
  };

  const addFavorite = (registration: number, favorite: string): Promise<AxiosResponse<IMessage>> => {
    sendLog(
      'Adicionar favorito',
      `/Cooperado/CreateFavorito?CD_PESSOA=${registration}&DS_FAVORITO=${favorite}`,
      JSON.stringify([]),
      'post'
    );
    return apiCore.post<IMessage>(`/Cooperado/CreateFavorito?CD_PESSOA=${registration}&DS_FAVORITO=${favorite}`);
  };

  const deleteFavorite = (registration: number, favorite: string): Promise<AxiosResponse<IMessage>> => {
    sendLog('deletar favorito', `Cooperado/DeleteFavorito?CD_PESSOA=${registration}&DS_FAVORITO=${favorite}`, JSON.stringify([]), 'delete');
    return apiCore.delete(`/Cooperado/DeleteFavorito?CD_PESSOA=${registration}&DS_FAVORITO=${favorite}`);
  };

  return {
    getFavorite,
    addFavorite,
    deleteFavorite,
  };
};

export default shorcutApi();
