import { AxiosResponse } from 'axios';
import bcrypt from 'bcryptjs';
import md5 from 'md5';
import { IReturnMessage } from 'model/financial';
import {
  IBankData,
  IChildren,
  IContact,
  ICooperatorAreas,
  ICooperatorMachines,
  ICooperatorProperties,
  IEnvInfo,
  IHeaderCooperative,
  IHeaderProperty,
  IMyProperty,
  IPersonalData,
  IPlantelGroup,
  IPropertiesAreas,
  IRegistrationForm,
  IRelationship,
  ISprings,
} from 'model/registration-form';
import { encryptReport, sendLog } from 'shared/util/api-utils';
import StringUtils from 'shared/util/string-utils';
import { api, apiCore, apiOracle } from './api';

export const registrationFormApi = () => {
  const getEnvironmentalReport = (propertyId: number, registration: number) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/DAT/DAT2192&va_nr_matricula=${registration}&va_cd_filial=1&va_nr_propriedade=${propertyId}`;
    sendLog('Consulta relatorio ambiental', path, JSON.stringify([]), 'get');
    const urlConvertCharcode = StringUtils.convertToCharCode(path);
    const md5EncryptedUrl = md5(urlConvertCharcode);
    const bcryptEncrypted = bcrypt.hashSync(md5EncryptedUrl);
    const charCodeUrlReportAuth = StringUtils.convertToCharCode(bcryptEncrypted);

    return `https://servicos.frisia.coop.br/RPT/Report/ReportTemplate?ReportId=${urlConvertCharcode}&ReportAuth=${charCodeUrlReportAuth}`;
  };

  const myProperties = (registration: number) => {
    sendLog(
      'Consulta propriedades do cooperado',
      `/Prop/GetPropriedadesByCoopMatricula?nr_matricula=${registration}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IMyProperty[]>(`/Prop/GetPropriedadesByCoopMatricula?nr_matricula=${registration}`);
  };

  const getAreasCooperative = (registration: number) => {
    sendLog('Consulta areas do cooperado', `/Area/GetAreasCooperado?nr_matricula=${registration}`, JSON.stringify([]), 'get');
    return apiCore.get<ICooperatorAreas[]>(`/Area/GetAreasCooperado?nr_matricula=${registration}`);
  };

  const personalData = (registration: number) => {
    sendLog('Consulta ficha cadastral', `/Extranet/GetFichaCadastral?matricula=${registration}`, JSON.stringify([]), 'get');
    return apiCore.get<IPersonalData[]>(`/Extranet/GetFichaCadastral?matricula=${registration}`);
  };

  const getBankData = (registration: number) => {
    sendLog('Consulta dados bancarios', `/Extranet/GetContaBancaria?matricula=${registration}`, JSON.stringify([]), 'get');
    return apiCore.get<IBankData[]>(`/Extranet/GetContaBancaria?matricula=${registration}`);
  };

  const getRelationshipData = (partyNumber: string) => {
    sendLog('Consulta dados de relações', `accounts/${partyNumber}/child/Relationship`, JSON.stringify([]), 'get');
    return apiOracle.get<IRelationship>(`accounts/${partyNumber}/child/Relationship`);
  };

  const createNewRelationship = (partyNumber: string) => {
    sendLog('Criar nova relação', `accounts/${partyNumber}/child/Relationship`, JSON.stringify([]), 'post');
    return apiOracle.post<IRelationship>(`accounts/${partyNumber}/child/Relationship`);
  };

  const updateRegistrationData = (data: IRegistrationForm): Promise<AxiosResponse<IRegistrationForm>> => {
    sendLog('Atualizzaação de registro', `/`, JSON.stringify(data), 'put');
    return api.put<IRegistrationForm>('/', data);
  };

  const getProperties = (registration: number) => {
    sendLog(
      'Consulta propriedades do cooperado',
      `/Prop/GetPropriedadesByCoopMatricula?nr_matricula=${registration}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<ICooperatorProperties[]>(`/Prop/GetPropriedadesByCoopMatricula?nr_matricula=${registration}`);
  };

  const getMachines = (registration: number) => {
    sendLog('Consulta maquinas e equipamentos', `/Cooperado/GetMaqEquipsCoop?nr_matricula=${registration}`, JSON.stringify([]), 'get');
    return apiCore.get<ICooperatorMachines[]>(`/Cooperado/GetMaqEquipsCoop?nr_matricula=${registration}`);
  };

  const getPlantelReport = (register: string) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/SIF/PEC/PEC0310A&CD_EMPRESA=2&CD_CRIADOR=${register}`;
    sendLog('Consulta relatorio de plantel', path, JSON.stringify([]), 'get');

    return encryptReport(path);
  };

  const getEnvInfo = (registration: number, property: number) => {
    sendLog(
      'Consulta historico posição financeira',
      `/Cooperado/GetInfosAmbientaisPropriedade?CD_PESSOA=${registration}&NR_PROPRIEDADE=${property}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IEnvInfo[]>(`/Cooperado/GetInfosAmbientaisPropriedade?CD_PESSOA=${registration}&NR_PROPRIEDADE=${property}`);
  };

  const getSprings = (property: number) => {
    sendLog(
      'Consulta informações de nascentes',
      `/Cooperado/GetInfosNascentesPropriedade?NR_PROPRIEDADE=${property}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<ISprings[]>(`/Cooperado/GetInfosNascentesPropriedade?NR_PROPRIEDADE=${property}`);
  };

  const updateEnvInfo = (
    register: string,
    property: number,
    tankType: string,
    tankCapacity: string,
    maintenanceShed: string,
    concreteFloorMaintenance: string,
    ie_manutencao_embalagem: string,
    ie_lavador_trapiche: string,
    ie_lavador_caixa: string,
    abastecimento_pulv_rio: string,
    abastecimento_pulv_caixa: string,
    abastecimento_pulv_movel: string,
    ie_captacao_agua_rio: string,
    ie_captacao_agua_poco: string,
    ie_captacao_agua_vertente: string,
    ie_agrot_emb_cheia_prop: string,
    agrot_emb_vazia_prop: string,
    agrot_emb_cheia_arm: string,
    ie_agrot_emb_vazia_arm: string,
    ie_unidade_grao: string,
    ie_unidade_certificada: string,
    ie_tratam_dejeto_suino: string,
    ie_tratam_dej_sui_lic: string,
    ie_tratam_dejeto_bovino: string,
    ie_tratam_dej_bov_trat: string,
    ie_biodigestor: string,
    ie_biodigestor_energia: string,
    ie_biodigestor_suino: string,
    ie_biodigestor_bovino: string,
    ie_composteira: string,
    qt_composteira: string,
    ie_possui_rio: string,
    nm_rio: string
  ) => {
    sendLog(
      'Atualização de propriedades ambientais',
      `Cooperado/UpdateInfosAmbientaisPropriedade?cd_pessoa=${register}&nr_propriedade=${property}&ie_tanque_tipo=${tankType}&qt_tanque_capacidade=${tankCapacity}&ie_manutencao_galpao=${maintenanceShed}&ie_manutencao_piso_concreto=${concreteFloorMaintenance}&ie_manutencao_embalagem=${ie_manutencao_embalagem}&ie_lavador_trapiche=${ie_lavador_trapiche}&ie_lavador_caixa=${ie_lavador_caixa}&ie_abastecimento_pulv_rio=${abastecimento_pulv_rio}&ie_abastecimento_pulv_caixa=${abastecimento_pulv_caixa}&ie_abastecimento_pulv_movel=${abastecimento_pulv_movel}&ie_captacao_agua_rio=${ie_captacao_agua_rio}&ie_captacao_agua_poco=${ie_captacao_agua_poco}&ie_captacao_agua_vertente=${ie_captacao_agua_vertente}&ie_agrot_emb_cheia_prop=${ie_agrot_emb_cheia_prop}&ie_agrot_emb_vazia_prop=${agrot_emb_vazia_prop}&ie_agrot_emb_cheia_arm=${agrot_emb_cheia_arm}&ie_agrot_emb_vazia_arm=${ie_agrot_emb_vazia_arm}&ie_unidade_grao=${ie_unidade_grao}&ie_unidade_certificada=${ie_unidade_certificada}&ie_tratam_dejeto_suino=${ie_tratam_dejeto_suino}&ie_tratam_dej_sui_lic=${ie_tratam_dej_sui_lic}&ie_tratam_dejeto_bovino=${ie_tratam_dejeto_bovino}&ie_tratam_dej_bov_trat=${ie_tratam_dej_bov_trat}&ie_biodigestor=${ie_biodigestor}&ie_biodigestor_energia=${ie_biodigestor_energia}&ie_biodigestor_suino=${ie_biodigestor_suino}&ie_biodigestor_bovino=${ie_biodigestor_bovino}&ie_composteira=${ie_composteira}&qt_composteira=${qt_composteira}&ie_possui_rio=${ie_possui_rio}&nm_rio=${nm_rio}`,
      JSON.stringify([]),
      'put'
    );
    return apiCore.put<IReturnMessage>(
      `Cooperado/UpdateInfosAmbientaisPropriedade?cd_pessoa=${register}&nr_propriedade=${property}&ie_tanque_tipo=${tankType}&qt_tanque_capacidade=${tankCapacity}&ie_manutencao_galpao=${maintenanceShed}&ie_manutencao_piso_concreto=${concreteFloorMaintenance}&ie_manutencao_embalagem=${ie_manutencao_embalagem}&ie_lavador_trapiche=${ie_lavador_trapiche}&ie_lavador_caixa=${ie_lavador_caixa}&ie_abastecimento_pulv_rio=${abastecimento_pulv_rio}&ie_abastecimento_pulv_caixa=${abastecimento_pulv_caixa}&ie_abastecimento_pulv_movel=${abastecimento_pulv_movel}&ie_captacao_agua_rio=${ie_captacao_agua_rio}&ie_captacao_agua_poco=${ie_captacao_agua_poco}&ie_captacao_agua_vertente=${ie_captacao_agua_vertente}&ie_agrot_emb_cheia_prop=${ie_agrot_emb_cheia_prop}&ie_agrot_emb_vazia_prop=${agrot_emb_vazia_prop}&ie_agrot_emb_cheia_arm=${agrot_emb_cheia_arm}&ie_agrot_emb_vazia_arm=${ie_agrot_emb_vazia_arm}&ie_unidade_grao=${ie_unidade_grao}&ie_unidade_certificada=${ie_unidade_certificada}&ie_tratam_dejeto_suino=${ie_tratam_dejeto_suino}&ie_tratam_dej_sui_lic=${ie_tratam_dej_sui_lic}&ie_tratam_dejeto_bovino=${ie_tratam_dejeto_bovino}&ie_tratam_dej_bov_trat=${ie_tratam_dej_bov_trat}&ie_biodigestor=${ie_biodigestor}&ie_biodigestor_energia=${ie_biodigestor_energia}&ie_biodigestor_suino=${ie_biodigestor_suino}&ie_biodigestor_bovino=${ie_biodigestor_bovino}&ie_composteira=${ie_composteira}&qt_composteira=${qt_composteira}&ie_possui_rio=${ie_possui_rio}&nm_rio=${nm_rio}`
    );
  };

  const getHeaderCooperativeMember = (register: string, property: number) => {
    sendLog(
      'Consulta informações de cabeçalho do cooperado',
      `/Cooperado/CooperadoCabecalho?CD_PESSOA=${register}&NR_PROPRIEDADE=${property}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IHeaderCooperative[]>(`/Cooperado/CooperadoCabecalho?CD_PESSOA=${register}&NR_PROPRIEDADE=${property}`);
  };

  const getHeaderProperties = (register: string, property: number) => {
    sendLog(
      'Consulta propriedades de cabeçalho',
      `/Cooperado/PropriedadesCabecalho?CD_PESSOA=${register}&NR_PROPRIEDADE=${property}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IHeaderProperty[]>(`/Cooperado/PropriedadesCabecalho?CD_PESSOA=${register}&NR_PROPRIEDADE=${property}`);
  };

  const getPropertiesAreas = (register: string, property: number) => {
    sendLog(
      'Consulta informações de propriedades',
      `/Cooperado/PropriedadesAreas?CD_PESSOA=${register}&NR_PROPRIEDADE=${property}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IPropertiesAreas[]>(`/Cooperado/PropriedadesAreas?CD_PESSOA=${register}&NR_PROPRIEDADE=${property}`);
  };

  const getImprovements = (register: string, property: number) => {
    sendLog(
      'Consulta benfeitorias',
      `/Cooperado/PropriedadeBenfeitorias?CD_PESSOA=${register}&NR_PROPRIEDADE=${property}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.get<IHeaderProperty[]>(`/Cooperado/PropriedadeBenfeitorias?CD_PESSOA=${register}&NR_PROPRIEDADE=${property}`);
  };

  const updateSpring = (property: number, sequency: number, springQuantity: number, reserve: string) => {
    sendLog(
      'Atualizar innformação de propriedades e nascentes',
      `/Cooperado/UpdatePropriedadeNascentes?nr_propriedade=${property}&nr_sequencia=${sequency}&qt_nascentes=${springQuantity}&ie_reserva_legal_averbada=${reserve}`,
      JSON.stringify([]),
      'get'
    );
    return apiCore.put<IReturnMessage>(
      `/Cooperado/UpdatePropriedadeNascentes?nr_propriedade=${property}&nr_sequencia=${sequency}&qt_nascentes=${springQuantity}&ie_reserva_legal_averbada=${reserve}`
    );
  };

  const getChildren = (register: string) => {
    sendLog('Consulta filhos', `/Cooperado/GetFilhos?CD_PESSOA=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<IChildren[]>(`/Cooperado/GetFilhos?CD_PESSOA=${register}`);
  };

  const getContact = (register: string) => {
    sendLog('Consulta contatos', `/Cooperado/GetContato?CD_PESSOA=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<IContact[]>(`/Cooperado/GetContato?CD_PESSOA=${register}`);
  };

  const updateContact = (contact: IContact) => {
    sendLog('Atualizar informações de contato', `/Cooperado/UpdateContato`, JSON.stringify(contact), 'put');
    return apiCore.put<IReturnMessage>(`/Cooperado/UpdateContato`, contact);
  };

  const getPlantelGroup = (register: string) => {
    sendLog('Consulta plantel grupos', `/Cooperado/GetPlantelGrupos?CD_PESSOA=${register}`, JSON.stringify([]), 'get');
    return apiCore.get<IPlantelGroup[]>(`/Cooperado/GetPlantelGrupos?CD_PESSOA=${register}`);
  };

  return {
    getEnvironmentalReport,
    myProperties,
    getAreasCooperative,
    personalData,
    getBankData,
    getRelationshipData,
    updateRegistrationData,
    getProperties,
    getMachines,
    getPlantelReport,
    getEnvInfo,
    getSprings,
    updateEnvInfo,
    getHeaderCooperativeMember,
    getHeaderProperties,
    getPropertiesAreas,
    updateSpring,
    getChildren,
    getContact,
    updateContact,
    getPlantelGroup,
  };
};

export default registrationFormApi();
