import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

const Toast = () => {
  return <StyledToast />;
};

const StyledToast = styled(ToastContainer)`
  .Toastify__progress-bar {
    color: red;
  }
`;

export default Toast;
