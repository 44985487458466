import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as PlayIconSvg } from '../../images/frisia/icons/blue-and-white-round-play-icon.svg';
import { ReactComponent as PauseIconSvg } from '../../images/frisia/icons/blue-and-white-round-pause-icon.svg';
import { ReactComponent as ClockIconSvg } from '../../images/frisia/icons/blue-clock-2-icon.svg';
import { ReactComponent as DownloadIconSvg } from '../../images/frisia/icons/blue-download-icon.svg';
import { ReactComponent as HeartIconSvg } from '../../images/frisia/icons/yellow-heart-icon.svg';
import { Component, useEffect, useState } from 'react';
import { PlayerContainer, Equalizer, TitleContainer } from './StyledComponents';
import moment from 'moment';
import React from 'react';

interface IProps extends WithTranslation {
  handlePlayerClick: (stateOfPlay: any, updateStatePlay: any, indexOfPlay: any) => void;
  song?: string;
  createdAt?: string;
  fileName?: string;
  title?: string;
  index?: number;
  checkState?: boolean;
}

const PodcastPlayer = ({ checkState, handlePlayerClick, song, createdAt, fileName, title, index, t }: IProps) => {
  const playIndex = `play-${index}`;
  const [playState, setPlayState] = useState(playIndex);
  const [audio, setAudio] = useState<HTMLAudioElement>();
  const [iconToPlay, setIconToPlay] = useState<JSX.Element>(<PlayIconSvg />);
  const audioElement: HTMLAudioElement = document.getElementById(`audio-${index}`) as HTMLAudioElement;

  useEffect(() => {
    if (!audioElement?.paused) {
      setPlayState('pause');
      setIconToPlay(<PauseIconSvg />);
    } else {
      setPlayState(playIndex);
      setIconToPlay(<PlayIconSvg />);
    }
  }, [!audioElement?.paused, checkState]);

  const [durationTime, setDurationTime] = useState('0:00');
  const [seekValue, setSeekValue] = useState(0);

  const handleDuration = (duration: number) => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    var dur = `${minutes}:${returnedSeconds}`;
    setDurationTime(dur);
  };

  return (
    <PlayerContainer key={index}>
      <section>
        <button onClick={() => handlePlayerClick(playState, setPlayState, index)} className={playState === playIndex ? 'play' : 'stop'}>
          {iconToPlay}
        </button>
        <Equalizer>
          <div id="equalizer-btn" className={playState !== playIndex ? 'active' : ''}>
            <div className="equalizer" id="equalizer-btn-first">
              <input
                readOnly
                type="range"
                style={{ animation: 'none' }}
                id="seek-slider"
                max="100"
                value={seekValue}
                onChange={data => console.log(data.currentTarget)}
              />
              <ul id={`bares-container-${index}`} className={playState !== playIndex ? 'playEqualizer' : ''}>
                <li className="first-bar"></li>
                <li className="second-bar"></li>
                <li className="third-bar "></li>
                <li className="fourth-bar"></li>
                <li className="fifth-bar"></li>
                <li className="sixth-bar"></li>
                <li className="seventh-bar"></li>
                <li className="eighth-bar"></li>
                <li className="ninth-bar"></li>
                <li className="tenth-bar"></li>
                <li className="eleventh-bar"></li>
                <li className="twelfth-bar"></li>
                <li className="thirteenth-bar"></li>
                <li className="fourteenth-bar"></li>
                <li className="fifteenth-bar"></li>
                <li className="sixteenth-bar"></li>
                <li className="seventeenth-bar"></li>
                <li className="eighteenth-bar"></li>
                <li className="nineteenth-bar"></li>
                <li className="twentieth-bar"></li>
                <li className="twenty-first-bar"></li>
                <li className="twenty-second-bar"></li>
                <li className="twenty-third-bar"></li>
                <li className="twenty-fourth-bar"></li>

                <li className="first-bar"></li>
                <li className="second-bar"></li>
                <li className="third-bar "></li>
                <li className="fourth-bar"></li>
                <li className="fifth-bar"></li>
                <li className="sixth-bar"></li>
                <li className="seventh-bar"></li>
                <li className="eighth-bar"></li>
                <li className="ninth-bar"></li>
                <li className="tenth-bar"></li>
                <li className="eleventh-bar"></li>
                <li className="twelfth-bar"></li>
                <li className="thirteenth-bar"></li>
                <li className="fourteenth-bar"></li>
                <li className="fifteenth-bar"></li>
                <li className="sixteenth-bar"></li>
                <li className="seventeenth-bar"></li>
                <li className="eighteenth-bar"></li>
                <li className="nineteenth-bar"></li>
                <li className="twentieth-bar"></li>
                <li className="twenty-first-bar"></li>
                <li className="twenty-second-bar"></li>
                <li className="twenty-third-bar"></li>
                <li className="twenty-fourth-bar"></li>

                <li className="first-bar"></li>
                <li className="second-bar"></li>
                <li className="third-bar "></li>
                <li className="fourth-bar"></li>
                <li className="fifth-bar"></li>
                <li className="sixth-bar"></li>
                <li className="seventh-bar"></li>
                <li className="eighth-bar"></li>
                <li className="ninth-bar"></li>
                <li className="tenth-bar"></li>
                <li className="eleventh-bar"></li>
                <li className="twelfth-bar"></li>
                <li className="thirteenth-bar"></li>
                <li className="fourteenth-bar"></li>
                <li className="fifteenth-bar"></li>
                <li className="sixteenth-bar"></li>
                <li className="seventeenth-bar"></li>
                <li className="eighteenth-bar"></li>
                <li className="nineteenth-bar"></li>
                <li className="twentieth-bar"></li>
                <li className="twenty-first-bar"></li>
                <li className="twenty-second-bar"></li>
                <li className="twenty-third-bar"></li>
                <li className="twenty-fourth-bar"></li>
              </ul>
            </div>
          </div>
        </Equalizer>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ClockIconSvg />
          <div style={{ margin: '5px' }} />
          <span id="duration" className="time">
            {durationTime}
          </span>
        </div>
        <audio id={`audio-${index}`} onLoadedMetadata={data => handleDuration(data.currentTarget.duration)} src={song}></audio>
      </section>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <TitleContainer>
          {title}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '4px',
              margin: '0px 4px',
            }}
          >
            🌕
          </div>
          <div>
            {t('channel.posted')} <b>{`${moment(createdAt).format('DD.MM')} às ${moment(createdAt).format('HH')}h`}</b>
          </div>
        </TitleContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <HeartIconSvg />
          <a target="_blank" type="audio/mpeg" href={song} download={fileName} style={{ margin: '0px 4px' }}>
            <DownloadIconSvg />
          </a>
        </div>
      </div>
    </PlayerContainer>
  );
};

export default withTranslation()(PodcastPlayer);
