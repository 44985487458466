import IPacking from 'model/packing';
import { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import packingService from 'services/agricultural/packing-service';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/white-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/white-plus-round-icon.svg';
import ButtonDownload from './ButtonDownload';

interface IProps extends WithTranslation {
  title: string;
  tableData: IPacking[];
  packagingToReturn: (packing: IPacking) => void;
}

const CollapsibleReports = ({ title, tableData, packagingToReturn, t }: IProps) => {
  const [isShowGraphic, setIsShowGraphic] = useState<boolean>(false);

  const userRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  return (
    <StyledContainer>
      <StyledHeader>
        {isShowGraphic ? (
          <MinusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
        ) : (
          <PlusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
        )}
        {title}
        <ButtonDownload style={{ marginLeft: 'auto' }} href={packingService.generateReport(userRegistration)} />
      </StyledHeader>
      {isShowGraphic && (
        <StyledGraphicContainer>
          <TableContainer>
            <StyledHead>
              <tr>
                <th>{t('table.type')}</th>
                <th>{t('table.packingQuantity')}</th>
                <th>{t('table.unity')}</th>
                <th>{t('table.packingWithdrawn')}</th>
                <th>{t('table.packingReturn')}</th>
                <th>{t('table.assocampos')}</th>
              </tr>
            </StyledHead>
            <tbody>
              {tableData.map(item => (
                <tr key={`table-row-packing-control-${item.ROWNUM}`}>
                  <td>{item.DS_EMBALAGEM}</td>
                  <td>{StringUtils.maskAmount(item.QT_UNIDADE)}</td>
                  <td>{item.CD_UNIDADE_PADRAO}</td>
                  <td>{item.EMBALAGENS}</td>
                  <td>{item.QT_DEVOLVER}</td>
                  <td>
                    <StyledInput
                      type="number"
                      placeholder="0000"
                      onChange={({ target }) => {
                        item.QT_DEVOLVIDA = Number(target.value);
                        item.CD_PESSOA = userRegistration;
                        packagingToReturn(item);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </TableContainer>
        </StyledGraphicContainer>
      )}
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  height: 60px;
  padding: 0 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.frisiaPrimaryDark};
  font-family: ${({ theme }) => theme.font.montserratBold};
  font-size: 16px;
  color: #d7cc27;
`;

const StyledContainer = styled.div`
  width: 100%;
  min-width: 800px;
  border-radius: 20px;
  background-color: white;
`;

const StyledGraphicContainer = styled.div`
  max-height: 662px;
  overflow-y: auto;
`;

const TableContainer = styled.table`
  top: 304px;
  left: 895px;
  height: 377px;
  z-index: 1;
  border-collapse: collapse;
  font-size: 13px;
  margin: 0 auto;

  td {
    font-size: 14px;
    color: ${({ theme }) => theme.color.textGray};
  }

  td,
  th {
    padding: 8px;
    text-align: center;
    height: 38px;
  }

  th:nth-child(1),
  td:nth-child(1) {
    text-align: left;
    padding-left: 10px;
  }
`;

const StyledHead = styled.thead`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: #032762;
`;

const StyledInput = styled.input`
  width: 77px;
  height: 32px;
  border: 1px solid #5a5a5a;
  border-radius: 5px;
  color: #656565;
  font-size: 16px;
  text-align: center;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export default withTranslation()(CollapsibleReports);
