import qs from 'qs';
import HttpStatus from 'model/enums/http-status';
import { Pageable } from 'model/pageable';
import { Predicate } from 'model/predicate';
import bcrypt from 'bcryptjs';
import md5 from 'md5';
import StringUtils from './string-utils';
import moment from 'moment';
import { apiCore } from 'api/api';
import { store } from 'index';

export const checkApiStatus = (validation: boolean, status: number, expectedStatus?: HttpStatus): boolean => {
  const compareStatus = expectedStatus || HttpStatus.OK;
  return status === compareStatus && validation;
};

export const requestParamsFormatter = (predicate: Predicate, page: Pageable, predicateParams?: object) => {
  const pageParams = qs.stringify({ ...page });
  const filterParams: string = Object.keys(predicate)
    .filter(key => predicate[key] != null)
    .map(key =>
      Array.isArray(predicate[key])
        ? `${key}=${predicate[key].map(item => item[predicateParams?.[key] || 'name']).join(',')}`
        : `${key}=${predicate[key]}`
    )
    .join('&');
  return [pageParams, filterParams];
};

export const encryptReport = (path: string) => {
  const urlConvertCharcode = StringUtils.convertToCharCode(path);
  const md5EncryptedUrl = md5(urlConvertCharcode);
  const bcryptEncrypted = bcrypt.hashSync(md5EncryptedUrl);
  const charCodeUrlReportAuth = StringUtils.convertToCharCode(bcryptEncrypted);

  return `https://servicos.frisia.coop.br/RPT/Report/ReportTemplate?ReportId=${urlConvertCharcode}&ReportAuth=${charCodeUrlReportAuth}`;
};

interface IProps {
  deescritiveFunction: string;
  query: string;
  bodyRequest: string;
  requestType: string;
}

export const sendLog = async (deescritiveFunction, query, bodyRequest, requestType) => {
  const newState = store.getState();
  const name = (await newState.authentication.selectedInfo?.NM_PESSOA) ?? (await newState.authentication.coreInfo?.nm_usuario);
  const register = (await newState.authentication.selectedInfo?.CD_PESSOA) ?? (await newState.authentication.coreInfo?.cd_pessoa);

  const bodyLog = {
    DT_REGISTRO: moment().format('YYYY-MM-DD hh:mm:ss'),
    NM_USUARIO_LOGADO: name,
    CD_COOPERADO: register,
    DS_ORIGEM: 'Extranet',
    DS_FUNCAO: deescritiveFunction,
    DS_QUERYSTRING: query,
    DS_BODY_REQUISICAO: bodyRequest,
    TP_REQ_HTTP: requestType,
    IE_CONTABILIZA: 'Y',
  };

  // await apiCore.post(`/Extranet/PostLog`, bodyLog);
};
