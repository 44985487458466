import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';

interface IProps {
  inputName: string;
  label: string;
  placeholder?: string;
  isResetValue?: boolean;
  errorText?: string;
}

const GenericInputTextAreaToTable = ({ inputName, label, placeholder, isResetValue, errorText }: IProps) => {
  const { register } = useFormContext();

  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (isResetValue) {
      setValue('');
    }
  }, [isResetValue]);

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledLabel htmlFor={`inputId${inputName}`}>{label}</StyledLabel>
        <StyledInput
          {...register(inputName)}
          placeholder={placeholder}
          id={`inputId${inputName}`}
          value={value}
          maxLength={900}
          onChange={e => setValue(e.target.value)}
        />
      </StyledInnerContainer>

      <StyledErrorMessage>{errorText}</StyledErrorMessage>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  height: 120px;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 100px;
  border: 2px solid #4e6ba7;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f1f1f1;
  padding: 5px 10px 0 10px;
`;

const StyledLabel = styled.label`
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
`;

const StyledInput = styled.textarea`
  height: 100%;
  border: none;
  background-color: #f1f1f1;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  color: #656565;
  font-size: 16px;
  padding: 5px 0 6px 0;
  resize: none;

  ::placeholder {
    font-size: 12px;
    opacity: 0.5;
  }
`;

export default GenericInputTextAreaToTable;
