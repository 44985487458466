import { LiquidatedCreditedData } from 'model/agriculturalSalesStatement';
import { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as MinusRoundIconSvg } from '../images/frisia/icons/white-minus-round-icon.svg';
import { ReactComponent as PlusRoundIconSvg } from '../images/frisia/icons/white-plus-round-icon.svg';

interface IProps extends WithTranslation {
  title?: string;
  contractData: LiquidatedCreditedData;
}

const CollapsibleLiquidatedCredited = ({ title, contractData, t }: IProps) => {
  const [isShowGraphic, setIsShowGraphic] = useState<boolean>(false);

  return (
    <StyledContainer>
      <StyledHeader>
        {isShowGraphic ? (
          <MinusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
        ) : (
          <PlusRoundIconSvg onClick={() => setIsShowGraphic(!isShowGraphic)} style={{ cursor: 'pointer' }} />
        )}
        <div>{contractData.DT_CONFIRMACAO}</div>
        <div>
          {t('agricultural.contract')}: {contractData.CD_CONTRATO}
        </div>
        <div>{contractData.DS_TIPO_OPCAO}</div>
        <b>
          {t('agricultural.NF')}: {contractData.NR_NOTA_FISCAL?.toLocaleString('pt-BR')}
        </b>
      </StyledHeader>
      {isShowGraphic && (
        <StyledGraphicContainer>
          <TableContainer>
            <tbody>
              <tr>
                <td>
                  <Subtitle>{t('agricultural.sale')}</Subtitle>
                </td>
              </tr>
              <tr>
                <td>{t('agricultural.date')}</td>
                <td>{contractData.DT_CONFIRMACAO}</td>
              </tr>
              <tr>
                <td>{t('agricultural.contract')}</td>
                <td>{contractData.CD_CONTRATO}</td>
              </tr>
              <tr>
                <td>{t('agricultural.mode')}</td>
                <td>{contractData.DS_TIPO_ROMANEIO}</td>
              </tr>
              <tr>
                <td>{t('agricultural.branch')}</td>
                <td>{contractData.NM_REDUZIDO}</td>
              </tr>
              <tr>
                <td>{t('agricultural.priceWithoutDiscount')}</td>
                <td>{contractData.VL_PRECO_SDESC_VENDA}</td>
              </tr>
              <tr>
                <td>{t('agricultural.priceWithoutDiscount')}</td>
                <td>{contractData.VL_COMISSAO.toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td>{t('agricultural.shippingR$/Sac')}</td>
                <td>{contractData.VL_FRETE}</td>
              </tr>
              <tr>
                <td>
                  <Subtitle>{t('agricultural.entryInvoice')}</Subtitle>
                </td>
              </tr>
              <tr>
                <td>{t('agricultural.property')}</td>
                <td>{contractData.NM_PROPRIEDADE}</td>
              </tr>
              <tr>
                <td>{t('agricultural.branch')}</td>
                <td>{contractData.CD_FILIAL_NF}</td>
              </tr>
              <tr>
                <td>{t('agricultural.issuance')}</td>
                <td>{contractData.DT_EMISSAO_NFE}</td>
              </tr>
              <tr>
                <td>{t('agricultural.invoice')}</td>
                <td>
                  <b>{contractData.NR_NOTA_FISCAL?.toLocaleString('pt-BR')}</b>
                </td>
              </tr>
              <tr>
                <td>{t('agricultural.weight')}</td>
                <td>
                  <b>{contractData.QT_PESO_VENDA.toLocaleString('pt-BR')}</b>
                </td>
              </tr>
              <tr>
                <td>{t('agricultural.currencyQuote')}</td>
                <td>{contractData?.VL_COTACAO_MOEDA.toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td>{t('agricultural.discountPrice')}</td>
                <td>{contractData.VL_PRECO_CDESC_VENDA.toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td>{t('agricultural.value')}</td>
                <td>
                  <b>{contractData.VL_TOTAL_NF_VENDA.toLocaleString('pt-BR')}</b>
                </td>
              </tr>
              <tr>
                <td>{t('agricultural.capital')} (-)</td>
                <td>{contractData?.VL_CAP.toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td>{t('agricultural.fei')} (-)</td>
                <td>{contractData?.VL_FEI.toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td>{t('agricultural.admin')} (-)</td>
                <td>{contractData?.VL_ADM.toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td>{t('agricultural.inss')} (-)</td>
                <td>{contractData?.VL_INSS.toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td>{t('agricultural.senar')} (-)</td>
                <td>{contractData?.VL_SENAR.toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td>{t('agricultural.otherDesc')} (-)</td>
                <td>{contractData?.VL_OUTROS_DESC.toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td>{t('agricultural.shipping')}</td>
                <td>{contractData?.VL_FRETE_NF.toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td>{t('agricultural.NetValue')} (=)</td>
                <td>
                  <b>{contractData?.VL_LIQUIDO_CREDITO?.toLocaleString('pt-BR')}</b>
                </td>
              </tr>
              <tr>
                <td>{t('agricultural.credit')}</td>
                <td>{contractData?.DT_CREDITO}</td>
              </tr>
              <tr>
                <td>{t('agricultural.option')}</td>
                <td>{contractData?.DS_TIPO_OPCAO}</td>
              </tr>
            </tbody>
          </TableContainer>
        </StyledGraphicContainer>
      )}
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  padding: 0 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.frisiaPrimaryDark};
  font-family: ${({ theme }) => theme.font.montserratRegular};
  font-size: 18px;
  color: #d7cc27;

  width: 480px;
  height: 72px;
  background: #194185 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;

  letter-spacing: 0.02px;
  color: #ffffff;
  text-transform: uppercase;
  flex-flow: column;
  align-items: flex-start;
  flex-flow: wrap;
  align-content: space-evenly;
  justify-content: space-evenly;
  svg {
    height: 22px;
    width: 22px;
  }
  div {
    height: 18px;
  }
`;

const StyledContainer = styled.div`
  border-radius: 20px;
  background-color: white;
  margin: 20px;
`;

const StyledGraphicContainer = styled.div``;

const TableContainer = styled.table`
  top: 304px;
  left: 895px;
  height: 377px;
  z-index: 1;
  border-collapse: collapse;
  font-size: 15px;
  width: 100%;
  td,
  th {
    padding: 8px;
    text-align: left;
    padding-left: 23px;
    height: 48px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  tr:hover {
    background-color: #ddd;
  }
  th {
    text-align: center;
  }
`;

const Subtitle = styled.div`
  width: 200%;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0px;
  height: 22px;
  text-align: left;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
  color: ${({ theme }) => theme.color.frisiaPrimaryDark};
`;

export default withTranslation()(CollapsibleLiquidatedCredited);
