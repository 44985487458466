import MainPageTitle from 'components/MainPageTitle';
import { ComponentType, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  MonthView,
  Appointments,
  DayView,
  WeekView,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
} from '@devexpress/dx-react-scheduler-material-ui';
import moment from 'moment';
import calendarService from 'services/calendar-oracle-service';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { IAppointmentsAnswer } from 'model/calendar';
import { StyledButton } from 'components/styled-button/StyledButton';
import { Grid } from '@mui/material';

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: min-content;
  margin: auto;
  margin-top: -36px;
  @media (max-width: 1285px) {
    zoom: 90%;
  }
  @media (max-width: 1213px) {
    zoom: 80%;
  }
  @media (max-width: 1108px) {
    zoom: 70%;
  }
  @media (max-width: 956px) {
    zoom: 60%;
  }
`;

const CardsContainer = styled.div`
  display: flex;
`;

const CalendarOptions = styled.div`
  width: 330px;
  display: flex;
  div {
    width: 108px;
  }
`;

interface StyledOptionsProps {
  whenPressed: boolean;
}

const StyledOptions = styled.div<StyledOptionsProps>`
  color: ${p => (p.whenPressed ? '#8172d5' : '#133B3A')};
  cursor: pointer;
`;

const CalendarContainer = styled.div`
  display: flex;
  width: 967px;
  height: 484px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin: auto;
  flex-flow: column;
  justify-content: space-evenly;
`;

const CalendarHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  span {
    margin-left: 40px;
    width: 238px;
    height: 27px;
    text-align: left;
    opacity: 1;
    font-size: 20px;
    letter-spacing: 0px;
    color: #133b3a;
  }
`;

const EventCard = styled.div`
  display: flex;
  flex-flow: column;
  width: 236px;
  min-height: 334px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  margin: 5px;
  justify-content: space-around;
  img {
    width: 203px;
    height: 113px;
    background: transparent url('img/pexels-photo-3821159.png') 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 15px;
  }
  div {
    margin: 10px;
    text-align: left;
    letter-spacing: 0px;
    font-weight: bold;
    color: #133b3a;
    opacity: 1;
  }
  div: last-child {
    text-align: left;
    font-size: 20px;
    letter-spacing: 0px;
    color: #2a5299;
  }
`;

const StyledAppointmentTooltipHeader = styled(AppointmentTooltip.Header)`
  min-width: 100%;
  flex-direction: row-reverse !important;
  justify-content: flex-start !important;
  padding: 0 !important;
  transform: translate(-2px, -2px) !important;
`;

const CalendarComponent = props => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentViewName, setCurrentViewName] = useState('Month');
  const [recentAppointments, setRecentAppointments] = useState<IAppointmentsAnswer[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<IAppointmentsAnswer[]>([]);

  const getUserRegistration = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getUserName = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.NM_PESSOA ?? state.authentication.coreInfo?.nm_usuario;
  });

  const getSchedule = async () => {
    setLoadingData(true);
    const userName = getUserName;
    const userRegistration = getUserRegistration;
    const resultAccount = await calendarService.getAccountBycdAndName(userRegistration, userName);
    const result = await calendarService.getActivitiesByPartyId(resultAccount.items[0]?.PartyId);
    const generalEventsResults = await calendarService.getGeneralEventsActivities();
    let newAppointments = [...result.items, ...generalEventsResults.items];

    setAppointments(newAppointments.filter((a, i) => newAppointments.findIndex(s => a.ActivityId === s.ActivityId) === i));
    setLoadingData(false);
    return '';
  };

  useEffect(() => {
    getSchedule();
  }, []);

  useEffect(() => {
    setRecentAppointments([]);
    if (appointments.length > 0) {
      const first4 = appointments
        .sort(function (a, b) {
          return new Date(b.ActivityStartDate).getTime() - new Date(a.ActivityStartDate).getTime();
        })
        .filter(function (a) {
          return new Date(a.ActivityStartDate).getTime() >= new Date().getTime();
        })
        .slice(0, 4);
      setRecentAppointments(first4.reverse());
    }
  }, [appointments]);

  const handleDownload = cardData => {
    const icsFile = `BEGIN:VCALENDAR\nVERSION:2.0\nCALSCALE:GREGORIAN\nBEGIN:VEVENT\nSUMMARY:${
      cardData.Subject
    }\nDTSTART;TZID=America/Sao_Paulo:${moment(cardData.ActivityStartDate).format(
      `YYYYMMDDTHHmmss`
    )}\nDTEND;TZID=America/Sao_Paulo:${moment(cardData.ActivityEndDate).format(`YYYYMMDDTHHmmss`)}
LOCATION:${cardData.Location}\nDESCRIPTION:${
      cardData.ActivityPartialDescription
    }\nSTATUS:CONFIRMED\nSEQUENCE:3\nBEGIN:VALARM\nTRIGGER:-PT10M\nDESCRIPTION:Pickup Reminder\nACTION:DISPLAY\nEND:VALARM\nEND:VEVENT\nEND:VCALENDAR`;
    console.log(icsFile);
    const blob = new Blob([icsFile], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'invite.ics';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const Header: ComponentType<AppointmentTooltip.HeaderProps> = ({ children, appointmentData, ...restProps }) => {
    const imageUrl = appointments.find(item => item.Subject === appointmentData?.title);
    return (
      <StyledAppointmentTooltipHeader {...restProps} appointmentData={appointmentData}>
        {imageUrl?.Link_c && <img src={imageUrl?.Link_c} alt="Imagem ilustrativa do evento" style={{ maxWidth: '80%' }} />}
      </StyledAppointmentTooltipHeader>
    );
  };

  const Content: ComponentType<AppointmentTooltip.ContentProps> = ({ children, appointmentData, ...restProps }) => (
    <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
      <Grid container alignItems="end">
        <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
          <StyledButton
            color="frisiaPrimary"
            onClick={() => {
              const modifiedData = {
                Subject: appointmentData?.title,
                ActivityStartDate: appointmentData?.startDate,
                ActivityEndDate: appointmentData?.endDate,
                Location: appointmentData?.location,
                ActivityPartialDescription: appointmentData?.descriptions,
              };
              handleDownload(modifiedData);
            }}
            style={{ padding: '0px 10px', fontSize: '12px', maxWidth: '100%', margin: '4px' }}
          >
            <span>{props.t('global.button.addCalendar')}</span>
          </StyledButton>
        </div>
      </Grid>
    </AppointmentTooltip.Content>
  );

  return (
    <StyledContainer>
      <StyledPageTitleContainer margin={'18px 0'}>
        <MainPageTitle fontSize={30} text={props.t('mainMenu.schedule')} />
      </StyledPageTitleContainer>
      <CalendarContainer>
        <CalendarHead>
          <span>{props.t('mainMenu.scheduleEvents')}</span>
          <div />
          <CalendarOptions>
            <StyledOptions onClick={() => setCurrentViewName('Month')} whenPressed={currentViewName === 'Month'}>
              {props.t('mainMenu.seeMonth')}
            </StyledOptions>
            <StyledOptions onClick={() => setCurrentViewName('Week')} whenPressed={currentViewName === 'Week'}>
              {props.t('mainMenu.seeWeek')}
            </StyledOptions>
            <StyledOptions onClick={() => setCurrentViewName('Day')} whenPressed={currentViewName === 'Day'}>
              {props.t('mainMenu.seeDay')}
            </StyledOptions>
          </CalendarOptions>
        </CalendarHead>
        <Paper
          style={{
            width: '888px',
            height: '389px',
            margin: '0px auto',
          }}
        >
          <Scheduler
            height={389}
            locale="pt-BR"
            data={appointments.map(item => {
              return {
                title: item.Subject,
                notes: `${item.ActivityType} - ${item.CalendarSubject} - ${item.ActivityPartialDescription} - ${item.Subject} - Local: ${item.Location} - Imagem: ${item.Link_c}`,
                descriptions: item.ActivityPartialDescription,
                startDate: item.ActivityStartDate,
                endDate: item.ActivityEndDate,
                location: item.Location,
              };
            })}
          >
            <ViewState
              currentDate={currentDate}
              currentViewName={currentViewName}
              onCurrentViewNameChange={setCurrentViewName}
              onCurrentDateChange={setCurrentDate}
            />
            <MonthView />
            <DayView />
            <WeekView />
            <Appointments />
            <AppointmentTooltip
              headerComponent={Header}
              contentComponent={Content}
              //commandButtonComponent={CommandButton}
              showOpenButton
            />
            <AppointmentForm readOnly />
            <Toolbar />
            <DateNavigator />
          </Scheduler>
        </Paper>
      </CalendarContainer>

      <StyledPageTitleContainer margin={'18px 0'} style={{ width: '100%' }}>
        <MainPageTitle fontSize={30} text={props.t('mainMenu.newEvents')} />
      </StyledPageTitleContainer>
      <CardsContainer>
        {loadingData && props.t('Carregando ...')}
        {recentAppointments.length === 0 && !loadingData && props.t('mainMenu.noEvents')}
        {recentAppointments.map((cardData, index) => (
          <EventCard key={`event-card-${index}`}>
            {cardData.Link_c && <img src={cardData.Link_c} alt="Imagem ilustrativa do evento" width="500" height="333" />}
            <div>{cardData.Subject}</div>
            <div>{cardData.ActivityType}</div>
            <div>Local: {cardData.Location ?? '---'}</div>
            <div>
              De: {moment(cardData.ActivityStartDate).format(`DD/MM/YY-H:mm`)}
              <br />
              Até: {moment(cardData.ActivityEndDate).format(`DD/MM/YY-H:mm`)}
            </div>
            <StyledButton
              color="frisiaPrimary"
              onClick={() => handleDownload(cardData)}
              style={{ padding: '0', fontSize: '12px', maxWidth: '100%', margin: '4px' }}
            >
              <span>{props.t('global.button.addCalendar')}</span>
            </StyledButton>
          </EventCard>
        ))}
      </CardsContainer>
    </StyledContainer>
  );
};

export default withTranslation()(CalendarComponent);
