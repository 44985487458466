import { AUTHORITIES } from 'config/constants';
import jwt_decode from 'jwt-decode';

export enum IdentificationKeyLocalStorage {
  CORE_RESPONSE = 'core',
  DEV_CORE_RESPONSE = 'devCore',
  USER_SELECTED = 'user-selected',
  INDICATORS_TOKEN = 'indicators-token',
}
class AuthUtils {
  static isAuthenticated = () => {
    return localStorage.getItem(IdentificationKeyLocalStorage.CORE_RESPONSE) !== null;
  };

  static canAccess = () => {
    const privatedPaths = [
      '/financeiro',
      '/agricola',
      '/pecuaria-bovinos',
      '/pecuaria-suinos',
      '/configuracoes',
      '/suporte',
      '/cotacoes-agricola',
      '/fale-com-a-isa',
      '/ambiental',
      '/autorizacao-compras',
    ];

    return localStorage.getItem(IdentificationKeyLocalStorage.CORE_RESPONSE) !== null;
  };

  static isUser = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.USER);
  };

  static isAdmin = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.ADMIN);
  };

  static setToken = (keyName: IdentificationKeyLocalStorage, token: string) => {
    localStorage.setItem(keyName, token);
  };

  static getToken = (keyName: IdentificationKeyLocalStorage) => {
    return localStorage.getItem(keyName);
  };

  static setCore = (infoStringfied: string) => {
    localStorage.setItem(IdentificationKeyLocalStorage.CORE_RESPONSE, infoStringfied);
  };

  static setDevCore = (infoStringfied: string) => {
    localStorage.setItem(IdentificationKeyLocalStorage.DEV_CORE_RESPONSE, infoStringfied);
  };

  static setSelectedinLocal = (token: string) => {
    localStorage.setItem(IdentificationKeyLocalStorage.USER_SELECTED, token);
  };

  static getSelectedinLocal = () => {
    return localStorage.getItem(IdentificationKeyLocalStorage.USER_SELECTED);
  };

  static removeCore = () => {
    localStorage.removeItem(IdentificationKeyLocalStorage.CORE_RESPONSE);
  };

  static removeToken = (keyName: IdentificationKeyLocalStorage) => {
    localStorage.removeItem(keyName);
  };

  static getMainPath = () => {
    return '/inicial';
  };
}

export default AuthUtils;
