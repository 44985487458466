import { StyledButton } from 'components/styled-button/StyledButton';
import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Background, LoginContainer, LogoEye, LogoImage, Message, Message2, MessageContainer, WhiteContainer } from './StyledComponents';
import { FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from '@material-ui/core';
import { getSessionRequest, loginRequest, logoutRequest } from 'reducer/authentication/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from 'reducer';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import AuthUtils from 'shared/util/auth-utils';
import authenticate from 'api/authentication-api';
import BeatLoader from 'react-spinners/BeatLoader';

const openedWhiteStyle = {
  top: '338px',
  left: '738px',
  width: '522px',
  height: '385px',
  transition: 'top 0.3s, left 0.3s, width 0.3s, height 0.3s, transform 0.3s',
  transform: 'translateX(834px)',
};

const closedWhiteStyle = {
  transition: 'top 0.3s, left 0.3s, width 0.3s, height 0.3s, transform 0.3s',
};

const openedBlueStyle = {
  top: '320px',
  left: '181px',
  width: '1031px',
  height: '420px',
  transition: 'top 0.3s, left 0.3s, width 0.3s, height 0.3s',
};

const openedLogoStyle = {
  marginTop: 'none',
  width: '141px',
  height: ' 61px',
};

const styledMessage = {
  marginLeft: '76px',
  left: '257px',
  width: '384px',
  height: '49px',
  fontSize: '36px',
};

const styledMessage2 = {
  marginLeft: '76px',
  top: '498px',
  left: '257px',
  width: '349px',
  height: '139px',
};

const Login = props => {
  const { t } = useTranslation();
  const apiAccessKey = process.env.REACT_APP_FRISIA_API_SECRET;
  const [password, setPassword] = useState('');
  const [register, setRegister] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [onLoading, setOnLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [forgot, setForgot] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const redirectToPath = () => {
    const redirectPath = AuthUtils.getMainPath();
    props.history.replace(redirectPath);
  };

  useEffect(() => {
    if (AuthUtils.isAuthenticated()) {
      redirectToPath();
    }
  }, []);

  useEffect(() => {
    if (props.getSessionStatus === HttpRequestStatus.SUCCESS) {
      setOnLoading(false);
      redirectToPath();
    }
    if (props.getSessionStatus === HttpRequestStatus.ERROR || props.loginStatus === HttpRequestStatus.ERROR) {
      setOnLoading(false);
      setErrorMessage(props.error);
    }
  }, [props.getSessionStatus, props.loginStatus]);

  const submitLogin = () => {
    setOnLoading(true);
    const cryptoPassword = btoa(`${password}${apiAccessKey}`);
    props.login({
      username: register,
      password: cryptoPassword,
      base64_password: btoa(password),
      grant_type: 'password',
    });
  };

  const submitForgotLogin = () => {
    authenticate
      .forgotPassword({
        email: email,
        username: register,
      })
      .then(resp => {
        setShowMessage(true);
        setTimeout(() => window.location.reload(), 3000);
      })
      .catch(_ => {
        setErrorMessage('forgot');
      });
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (forgot) {
        submitForgotLogin();
      } else {
        submitLogin();
      }
    }
  };

  return (
    <Background>
      <LoginContainer>
        <WhiteContainer>
          <div
            id="box"
            onSubmit={e => {
              e.preventDefault();
              forgot ? submitForgotLogin() : submitLogin();
            }}
            className="normal"
            style={forgot ? openedWhiteStyle : closedWhiteStyle}
            onKeyDown={handleKeyDown}
          >
            <LogoImage style={forgot ? openedLogoStyle : closedWhiteStyle} />
            <div style={{ marginTop: forgot ? '15px' : '40px' }} />
            <TextField label={t('login.registration')} onChange={e => setRegister(e.target.value)} variant="outlined" type={'text'} />
            {forgot && (
              <>
                <div style={{ marginTop: '15px' }} />
                <TextField label={t('login.mail')} onChange={e => setEmail(e.target.value)} variant="outlined" type={'text'} />
              </>
            )}
            {!forgot && (
              <>
                <div style={{ marginTop: '15px' }} />
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{t('login.password')}</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={e => setPassword(e.target.value)}
                    required
                    label={t('login.password')}
                    name="password"
                    endAdornment={
                      <InputAdornment position="end">
                        <LogoEye onClick={handleClickShowPassword} className="icon-eye" />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </>
            )}
            <div style={{ margin: '15px', color: 'red' }}>
              {errorMessage === 'An unknown error occured.' ? t('login.error.default') : errorMessage}
            </div>
            <div style={{ margin: '15px' }}>
              <b>{showMessage && t('login.successMessage')}</b>
            </div>
            <div onClick={() => setForgot(!forgot)} style={{ cursor: 'pointer', margin: '15px', color: '#D4A518' }}>
              {forgot ? t('login.havePassword') : t('login.form.forgotPassword')}
            </div>

            <StyledButton
              color="frisiaPrimary"
              onClick={() => {
                if (forgot) {
                  submitForgotLogin();
                } else {
                  submitLogin();
                }
              }}
              style={{ width: '172px', alignSelf: 'center' }}
            >
              {onLoading ? (
                <span style={{ verticalAlign: 'text-top' }}>
                  <BeatLoader color="#fff" />
                </span>
              ) : (
                <span>{forgot ? t('login.progress') : t('global.button.enter')}</span>
              )}
            </StyledButton>
            {!forgot && (
              <div style={{ marginTop: '45px' }}>
                {t('login.firstAccess')}
                <div onClick={() => setForgot(!forgot)} style={{ cursor: 'pointer', margin: '15px', color: '#D4A518' }}>
                  {t('login.clickHere')}
                </div>
              </div>
            )}
          </div>
        </WhiteContainer>
        <MessageContainer style={forgot ? openedBlueStyle : closedWhiteStyle}>
          {!forgot ? (
            <>
              <Message>{t('login.welcomeLogin')}</Message>
              <Message2>{t('login.loginMessage')}</Message2>
            </>
          ) : (
            <>
              <Message style={styledMessage}>{t('login.forgotPassword')}</Message>
              <Message2 style={styledMessage2}>{t('login.subtitle2')}</Message2>
            </>
          )}
        </MessageContainer>
      </LoginContainer>
    </Background>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  loginStatus: authentication.loginStatus,
  error: authentication.error,
  isAuthenticated: authentication.isAuthenticated,
  getSessionStatus: authentication.getSessionStatus,
});

const mapDispatchToProps = {
  login: loginRequest,
  logout: logoutRequest,
  getSession: getSessionRequest,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Login) as React.ComponentType<any>;
